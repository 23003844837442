import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Link,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import dayjs, { Dayjs } from "dayjs";
import { FocusEvent, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { ConnectionHandler, UploadableMap } from "relay-runtime";
import * as yup from "yup";
import { HighlightManageFormCreateMutation } from "./__generated__/HighlightManageFormCreateMutation.graphql";
import { HighlightManageFormDeleteMutation } from "./__generated__/HighlightManageFormDeleteMutation.graphql";
import { HighlightManageFormQuery } from "./__generated__/HighlightManageFormQuery.graphql";
import {
  HighlightManageFormUpdateMutation,
  MediaType,
} from "./__generated__/HighlightManageFormUpdateMutation.graphql";
import {
  AttachedNotification,
  isValidAttachedNotification,
} from "../../../../components/AttachedNotification";
import CardSection from "../../../../components/CardSection";
import CopyButton from "../../../../components/CopyButton";
import LeftRight from "../../../../components/LeftRight";
import UploadedFileCard from "../../../../components/UploadedFileCard";
import { ENGAGE_NOTIFICATIONS } from "../../../../utils/routes";
import { validateUrl } from "../../../../utils/validators";
import { EditState } from "./HighlightsCard";

const schema = yup
  .object({
    highlightDestination: yup
      .string()
      .ensure()
      .test("is-url-valid", "URL or deeplink is not valid", (value) => {
        if (value === "") return true;

        return validateUrl(value);
      }),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const updateMutation = graphql`
  mutation HighlightManageFormUpdateMutation(
    $input: GraphQLHighlightInputPartial!
  ) {
    updateHighlight(input: $input) {
      ... on GraphQLHighlight {
        id
        caption
        archivedAt
        publishedAt
        destinationUrl
        highlightAlbum {
          id
          archivedCount
          count
          coverImageUrl
        }
        media {
          type
          url
          coverImageUrl
          isProcessing
        }
        notification {
          title
          body
          ...NotificationTableRow_notification
        }
      }
    }
  }
`;

const createMutation = graphql`
  mutation HighlightManageFormCreateMutation($input: GraphQLHighlightInput!) {
    createHighlight(input: $input) {
      ... on GraphQLHighlight {
        id
        caption
        archivedAt
        publishedAt
        destinationUrl
        highlightAlbum {
          id
          coverImageUrl
        }
        media {
          type
          url
          coverImageUrl
          isProcessing
        }
        notification {
          title
          body
          id
          ...NotificationTableRow_notification
        }
      }
    }
  }
`;

const deleteMutation = graphql`
  mutation HighlightManageFormDeleteMutation($input: NodeInput!) {
    deleteHighlight(input: $input) {
      ... on GraphQLHighlight {
        id
        highlightAlbum {
          id
          coverImageUrl
        }
      }
    }
  }
`;

const query = graphql`
  query HighlightManageFormQuery($id: ID!, $skip: Boolean!) {
    highlight(id: $id) @skip(if: $skip) {
      id
      caption
      publishedAt
      destinationUrl
      notifLandingPath
      media {
        type
        url
        coverImageUrl
        isProcessing
      }
      notification {
        title
        body
        utmCampaign
      }
    }
  }
`;

/* TODO:
- look into more robust way of changing activeHighlightID
- save button states, disabled when fields missing etc
- mobile should only see the highlights that have a publish date < now
- scroll all the way right on highlight create and left right scrol
*/
const HighlightManageForm = ({
  albumID,
  brandID,
  editState,
  highlightID,
  isAutomatedAlbum,
  onEditComplete,
}: {
  albumID: string;
  brandID: string;
  editState: EditState;
  highlightID: string;
  isAutomatedAlbum: boolean | null;
  onEditComplete: () => void;
}) => {
  const navigate = useNavigate();

  const isEditing = highlightID !== "";
  const data = useLazyLoadQuery<HighlightManageFormQuery>(query, {
    id: highlightID,
    skip: !isEditing,
  });
  const highlight = data.highlight;
  const caption = highlight?.caption ?? "";
  const destinationUrl = highlight?.destinationUrl ?? "";
  const publishedAt = highlight?.publishedAt ?? null;
  const url = highlight?.media?.url ?? null;
  const mediaType = highlight?.media?.type ?? null;
  const notifLandingPath = highlight?.notifLandingPath ?? "";

  const [updateHighlight, isUpdateHighlightInFlight] =
    useMutation<HighlightManageFormUpdateMutation>(updateMutation);
  const [createHighlight, isCreateHighlightInFlight] =
    useMutation<HighlightManageFormCreateMutation>(createMutation);
  const [deleteHighlight, isDeleteHighlightInFlight] =
    useMutation<HighlightManageFormDeleteMutation>(deleteMutation);

  // Fields that need resetting on change of highlightID
  const [highlightMediaType, setHighlightMediaType] =
    useState<MediaType | null>(mediaType);
  const [highlightMediaUrl, setHighlightMediaUrl] = useState<string | null>(
    url
  );
  const [coverImageUrl, setCoverImageUrl] = useState<string | null>(
    highlight?.media?.coverImageUrl ?? null
  );
  const [muxVideoID, setMuxVideoID] = useState<string | null>(null);
  const [mediaUploadable, setMediaUploadable] = useState<UploadableMap>({});
  const [highlightCaption, setHighlightCaption] = useState<string>(caption);
  const [highlightDestinationUrl, setHighlightDestinationUrl] =
    useState<string>(destinationUrl);

  // confirmation dialog
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const onConfirmDialogClosed = () => {
    setIsConfirmDialogOpen(false);
  };

  // Publish Preferences
  const publishTime = highlight?.publishedAt ?? null;
  const isHighlightPublished =
    publishTime !== null && dayjs(publishTime).isBefore(dayjs());
  const [publishLater, setPublishLater] = useState(
    isEditing && !isHighlightPublished
  );
  const onTogglePublishLater = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPublishLater(event.target.checked);
  };

  const initialTime = publishTime ? dayjs(publishTime) : dayjs().add(1, "day");
  const [dateTime, setDateTime] = useState<Dayjs | null>(initialTime);
  const minDateTime = dayjs().add(1, "hour");
  const maxDateTime = dayjs().add(3, "months");

  const [notification, setNotification] = useState(
    highlight?.notification ?? null
  );
  const notifEnabled = notification !== null;

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const resetForm = useCallback(() => {
    setHighlightCaption(caption);
    setHighlightDestinationUrl(destinationUrl);
    setHighlightMediaUrl(url);
    setHighlightMediaType(mediaType);
    setMediaUploadable({});
    setPublishLater(dayjs(publishedAt).isAfter(dayjs()));
    setDateTime(
      publishedAt !== null ? dayjs(publishedAt) : dayjs().add(1, "day")
    );
  }, [caption, destinationUrl, url, mediaType, publishedAt]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const onConfirmDeleteHighlight = () => {
    if (!highlight?.id) {
      resetForm();
      onEditComplete();
      return;
    }

    deleteHighlight({
      variables: {
        input: {
          id: highlight.id,
        },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          console.log("Error: ", data, errors);
        } else {
          onEditComplete();
        }
      },
      updater: (store) => {
        const payload = store.getRootField("deleteHighlight");

        const highlightAlbum = store.get(albumID);
        if (highlightAlbum == null) {
          return;
        }
        const highlightsRootRecord = highlightAlbum.getLinkedRecord(
          "highlights",
          {
            filters: {
              isArchived: null,
            },
          }
        );
        const highlightsRecords =
          highlightsRootRecord?.getLinkedRecords("edges");
        if (highlightsRootRecord == null || highlightsRecords == null) {
          return;
        }

        // Delete the associated notif first if it exists
        const highlightID = payload.getDataID();
        const brand = store.get(brandID);
        const existingNotifRecord = store
          .get(highlightID)
          ?.getLinkedRecord("notification")
          ?.getDataID();
        if (existingNotifRecord != null && brand != null) {
          const notifConnection = ConnectionHandler.getConnection(
            brand,
            "NotificationsTab_notifications"
          );
          if (notifConnection != null) {
            ConnectionHandler.deleteNode(notifConnection, existingNotifRecord);
          }
        }

        highlightsRootRecord.setLinkedRecords(
          highlightsRecords.filter(
            (record) =>
              record.getLinkedRecord("node")?.getDataID() !==
              payload.getDataID()
          ),
          "edges"
        );

        const highlightCount = highlightAlbum.getValue("count");
        if (highlightCount != null) {
          highlightAlbum.setValue(Number(highlightCount) - 1, "count");
        }
      },
    });
  };

  const confirmationDialog = isConfirmDialogOpen ? (
    <Dialog open={isConfirmDialogOpen} onClose={onConfirmDialogClosed}>
      <DialogTitle>Are you sure you want to delete this highlight?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Deleting the highlight will also delete all analytics associated with
          it. Consider archiving it instead.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirmDialogClosed} color="primary">
          Cancel
        </Button>
        <Button
          disabled={isDeleteHighlightInFlight || isUpdateHighlightInFlight}
          onClick={onConfirmDeleteHighlight}
          color="error"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;

  const dateTimePicker = (
    <Stack
      direction={"row"}
      component="form"
      sx={{
        alignItems: "center",
      }}
      noValidate
      autoComplete="off"
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          sx={{
            mr: 1,
          }}
          minDateTime={minDateTime}
          maxDateTime={maxDateTime}
          value={dateTime}
          onChange={(newTime) => {
            setDateTime(newTime);
          }}
        />
      </LocalizationProvider>
      {dateTime?.format("z")}
    </Stack>
  );

  const uploadFile = (
    <UploadedFileCard
      brandID={brandID}
      onUploadVideo={(videoID: string) => {
        setMuxVideoID(videoID);
      }}
      fallbackURL={coverImageUrl ?? undefined}
      fileURL={highlightMediaUrl}
      fileType=".svg, .png, .jpg, .mp4, .mov"
      htmlLabel="highlights-card"
      mediaType={highlightMediaType ?? "PHOTO"}
      inputLabel="Photo or Video"
      inputText={"Upload a photo or video"}
      onClose={() => {
        setHighlightMediaUrl(null);
        setHighlightMediaType(null);
        setMuxVideoID(null);
        setCoverImageUrl(null);
        delete mediaUploadable["mediaUploadable"];
      }}
      onUpload={(
        event: React.ChangeEvent<HTMLInputElement>,
        mediaType?: MediaType
      ) => {
        const file = event?.target?.files?.[0];
        if (file == null) {
          return;
        }

        setHighlightMediaUrl(URL.createObjectURL(file));
        setHighlightMediaType(file.type.includes("video") ? "VIDEO" : "PHOTO");
        setCoverImageUrl(null);

        if (mediaType !== "WEB_MUX_VIDEO") {
          const newUploadables: UploadableMap = {};
          newUploadables["mediaUploadable"] = file;
          setMediaUploadable(newUploadables);
        }
      }}
    />
  );

  let publishNotifSection = (
    <CardSection
      showIsOptional
      title="Publish Preferences"
      content={
        editState === EditState.PUBLISHED ? (
          <>
            <Typography variant="body2">
              This highlight has already been published. If needed, you can
              manually send a notification for it from the{" "}
              <Link
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => navigate(ENGAGE_NOTIFICATIONS)}
              >
                Notifications Tab
              </Link>
              . Simply use the URL below as the notification's destination link.
            </Typography>
            <TextField
              margin="normal"
              id="outlined-basic"
              label={"Highlight Destination Link"}
              variant="outlined"
              value={notifLandingPath}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyButton textToCopy={notifLandingPath} />
                  </InputAdornment>
                ),
                readOnly: true,
              }}
            />
          </>
        ) : (
          <>
            <Stack spacing={2} width={"100%"}>
              <LeftRight
                expandLeft={true}
                left={
                  <Stack spacing={1}>
                    <Typography variant="subtitle1">Publish later</Typography>
                    <Typography variant="body2">
                      Schedule the highlight to publish anytime between an hour
                      from now to 3 months out
                    </Typography>
                  </Stack>
                }
                right={
                  <Switch
                    checked={publishLater}
                    onChange={onTogglePublishLater}
                  />
                }
              />
              {publishLater && dateTimePicker}

              <AttachedNotification
                notification={notification}
                onNotificationChange={setNotification}
              />
            </Stack>
          </>
        )
      }
    />
  );

  let saveDisabled = false;
  if (editState === EditState.CREATE || editState === EditState.DRAFT) {
    saveDisabled =
      highlightMediaUrl === null ||
      (publishLater &&
        (!dateTime?.isValid() || minDateTime.isAfter(dateTime))) ||
      !isValidAttachedNotification(notification);
  } else if (editState === EditState.PUBLISHED) {
    saveDisabled = highlightMediaUrl === null;
  }

  let saveButtonText = "Publish";
  if (editState === EditState.DRAFT) {
    if (publishLater) {
      saveButtonText = isUpdateHighlightInFlight ? "Saving" : "Save changes";
    } else {
      saveButtonText = isUpdateHighlightInFlight
        ? "Publishing"
        : "Publish highlight";
    }
  } else if (editState === EditState.CREATE) {
    if (publishLater) {
      saveButtonText = isCreateHighlightInFlight
        ? "Creating"
        : "Create highlight";
    } else {
      saveButtonText = isCreateHighlightInFlight
        ? "Publishing"
        : "Publish highlight";
    }
  }

  const onArchive = () => {
    if (!highlight) {
      return;
    }
    updateHighlight({
      variables: {
        input: {
          isNotifEnabled: highlight.notification !== null,
          isArchived: true,
          id: highlight.id,
        },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          console.log("Error: ", errors);
        } else {
          onEditComplete();
        }
      },
    });
  };

  const onSubmit = (data: FormData) => {
    let input = {
      caption: highlightCaption,
      destinationUrl: highlightDestinationUrl,
      isNotifEnabled: notifEnabled,
      mediaType: highlightMediaType,
      notifTitle: notification?.title,
      notifSubtitle: notification?.body,
      notifUtmCampaign: notification?.utmCampaign,
      muxVideoId: muxVideoID,
    };
    if (highlight?.id) {
      updateHighlight({
        variables: {
          input: {
            ...input,
            publishedAt: isHighlightPublished
              ? undefined
              : publishLater
              ? dateTime
              : dayjs(),
            id: highlight.id,
          },
        },
        onCompleted: (data, errors) => {
          if (errors) {
            console.log("Error: ", errors);
          } else {
            setMediaUploadable({});
            onEditComplete();
          }
        },
        uploadables: mediaUploadable,
      });
    } else {
      createHighlight({
        variables: {
          input: {
            ...input,
            publishedAt: publishLater ? dateTime : dayjs(),
            highlightAlbum: {
              id: albumID,
            },
          },
        },
        onCompleted: (data, errors) => {
          if (errors) {
            console.log("Error: ", errors);
          } else {
            setMediaUploadable({});
            onEditComplete();
          }
        },
        updater: (store) => {
          const payload = store.getRootField("createHighlight");
          const highlightAlbum = store.get(albumID);
          if (highlightAlbum == null) {
            return;
          }

          const highlightsRootRecord = highlightAlbum.getLinkedRecord(
            "highlights",
            {
              filters: {
                isArchived: null,
              },
            }
          );
          const highlightsRecords =
            highlightsRootRecord?.getLinkedRecords("edges");
          if (highlightsRootRecord == null || highlightsRecords == null) {
            return;
          }

          const edge = ConnectionHandler.createEdge(
            store,
            highlightsRootRecord,
            payload,
            "GraphQLHighlightEdge"
          );
          const firstArchivedIndex = highlightsRecords.findIndex((edge) => {
            const node = edge.getLinkedRecord("node");
            if (node == null) {
              return false;
            }
            return node.getValue("archivedAt") !== null;
          });
          const insertPosition =
            firstArchivedIndex === -1
              ? highlightsRecords.length
              : firstArchivedIndex;
          highlightsRootRecord.setLinkedRecords(
            [
              ...highlightsRecords.slice(0, insertPosition),
              edge,
              ...highlightsRecords.slice(insertPosition),
            ],
            "edges"
          );

          const highlightCount = highlightAlbum.getValue("count");
          if (highlightCount != null) {
            highlightAlbum.setValue(Number(highlightCount) + 1, "count");
          }

          // Add notification if applicable
          const brand = store.get(brandID);
          const notificationRecord = payload.getLinkedRecord("notification");

          if (notificationRecord == null || brand == null) {
            return;
          }
          const notifConnection = ConnectionHandler.getConnection(
            brand,
            "NotificationsTab_notifications"
          );
          if (notifConnection == null) {
            return;
          }
          const newNotifEdge = ConnectionHandler.createEdge(
            store,
            notifConnection,
            notificationRecord,
            "GraphQLNotificationEdge"
          );
          ConnectionHandler.insertEdgeBefore(notifConnection, newNotifEdge);
        },
        uploadables: mediaUploadable,
      });
    }
  };
  const saveButton = (
    <Button
      type="submit"
      disabled={
        saveDisabled || isUpdateHighlightInFlight || isCreateHighlightInFlight
      }
      size="large"
      variant="contained"
    >
      {saveButtonText}
    </Button>
  );

  return (
    <Container maxWidth="md">
      {confirmationDialog}
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <CardSection
          title={"Customize Highlight"}
          content={
            <Stack spacing={2} width="100%">
              <Typography variant="overline">
                1. Upload photo or video
              </Typography>
              {uploadFile}

              <Typography variant="overline">
                2. Customize Text (Optional)
              </Typography>
              <Typography variant="body2">Write a caption below</Typography>
              <TextField
                sx={{
                  flexGrow: 1,
                }}
                margin="normal"
                multiline
                label={"Write something"}
                variant="outlined"
                value={highlightCaption}
                onChange={(event: FocusEvent<HTMLInputElement>) => {
                  setHighlightCaption(event.target.value);
                }}
                inputProps={{
                  maxLength: 200,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {highlightCaption != null ? highlightCaption.length : 0}
                      /200
                    </InputAdornment>
                  ),
                }}
              />

              <Typography variant="overline">
                3. Add a destination (Optional)
              </Typography>
              <TextField
                {...register("highlightDestination", {
                  onChange: (event: FocusEvent<HTMLInputElement>) => {
                    setHighlightDestinationUrl(event.target.value);
                  },
                })}
                error={!!errors?.highlightDestination}
                helperText={errors?.highlightDestination?.message}
                sx={{
                  flexGrow: 1,
                }}
                margin="normal"
                label={"Destination Link"}
                variant="outlined"
                value={highlightDestinationUrl}
              />
            </Stack>
          }
        />
        {publishNotifSection}
        <LeftRight
          expandLeft={true}
          left={
            editState === EditState.CREATE ? null : (
              <Stack direction="row" spacing={2}>
                {!isAutomatedAlbum && (
                  <Button
                    disabled={
                      isDeleteHighlightInFlight || isUpdateHighlightInFlight
                    }
                    size="large"
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      setIsConfirmDialogOpen(true);
                    }}
                  >
                    {isDeleteHighlightInFlight
                      ? "Deleting"
                      : "Delete highlight"}
                  </Button>
                )}
                <Button
                  disabled={
                    isDeleteHighlightInFlight || isUpdateHighlightInFlight
                  }
                  size="large"
                  color="warning"
                  variant="outlined"
                  onClick={onArchive}
                >
                  Archive
                </Button>
              </Stack>
            )
          }
          right={saveButton}
        />
      </form>
    </Container>
  );
};

export default HighlightManageForm;
