import { Box, Grid, Stack, Typography } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useContext } from "react";
import { useFragment } from "react-relay";
import {
  InsightsLifetimeSection_brand$data,
  InsightsLifetimeSection_brand$key,
} from "./__generated__/InsightsLifetimeSection_brand.graphql";
import InsightsCard from "../../../components/InsightsCard";
import DebugContext from "../../../contexts/DebugContext";
import formatNumber from "../../../utils/formatNumber";
import { TimeWindowOption } from "./types/KDateRange";

const fragment = graphql`
  fragment InsightsLifetimeSection_brand on BrandType {
    currencyCode
    brandMetricSummary {
      summary {
        appSales
        appOrders
        appUsers
        appPurchasers
        appRepeatPurchasers
      }
      summary1d {
        appSales
        appOrders
        appUsers
        appPurchasers
        appRepeatPurchasers
      }
      summary7d {
        appSales
        appOrders
        appUsers
        appPurchasers
        appRepeatPurchasers
      }
      summary30d {
        appSales
        appOrders
        appUsers
        appPurchasers
        appRepeatPurchasers
      }
      latestDate
    }
  }
`;

function summaryForTimeWindow(
  timeWindow: TimeWindowOption,
  data: InsightsLifetimeSection_brand$data
) {
  if (timeWindow === "CUSTOM") {
    return null;
  }
  if (timeWindow === "ONE_DAY") {
    return data.brandMetricSummary.summary1d;
  }
  if (timeWindow === "SEVEN_DAYS") {
    return data.brandMetricSummary.summary7d;
  }
  if (timeWindow === "THIRTY_DAYS") {
    return data.brandMetricSummary.summary30d;
  }
}

const InsightsLifetimeSection = ({
  brand: brandKey,
  timeWindow,
}: {
  brand: InsightsLifetimeSection_brand$key;
  timeWindow: TimeWindowOption;
}) => {
  const { debug } = useContext(DebugContext);

  const brand = useFragment(fragment, brandKey);
  const metricSummary = brand.brandMetricSummary;
  const summary = metricSummary.summary;
  const compareSummary = summaryForTimeWindow(timeWindow, brand);

  let debugComponent = null;
  if (debug) {
    debugComponent = (
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          position: "fixed",
          p: 2,
          zIndex: 1000000,
          top: 64,
          left: 304,
          bgcolor: "rgb(20,184,166, 0.4)",
          fontSize: "8px",
        }}
      >
        <Box>
          <div>
            <b>Lifetime</b>
          </div>
          <div>
            <b>Latest Date</b>:{" "}
            {dayjs(metricSummary.latestDate).format("MMM D, YYYY")}
          </div>
          <b>Latest Summary</b>
          <div>Sales: {summary?.appSales}</div>
          <div>Orders: {summary?.appOrders}</div>
          <div>Users: {summary?.appUsers}</div>
          <div>Purchasers: {summary?.appPurchasers}</div>
          <div>Repurchasers: {summary?.appRepeatPurchasers}</div>

          <b>Compare Summary</b>
          <div>Sales: {compareSummary?.appSales}</div>
          <div>Orders: {compareSummary?.appOrders}</div>
          <div>Users: {compareSummary?.appUsers}</div>
          <div>Purchasers: {compareSummary?.appPurchasers}</div>
          <div>Repurchasers: {compareSummary?.appRepeatPurchasers}</div>
        </Box>
      </Stack>
    );
  }
  return (
    <>
      {debugComponent}
      <Typography variant="h4">Lifetime Metrics</Typography>
      <Grid
        container
        columns={12}
        spacing={2}
        mt={1}
        sx={{
          width: "100%",
        }}
      >
        <Grid item xs={12} sm={6} lg={3}>
          <InsightsCard
            title="App Revenue"
            data={formatNumber(
              summary ? summary.appSales : 0,
              "currency",
              brand.currencyCode
            )}
            change={
              summary && compareSummary
                ? (summary.appSales - compareSummary.appSales) /
                  compareSummary.appSales
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <InsightsCard
            title="App Orders"
            data={formatNumber(summary ? summary.appOrders : 0)}
            change={
              summary && compareSummary
                ? (summary.appOrders - compareSummary.appOrders) /
                  compareSummary.appOrders
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <InsightsCard
            title="App Users"
            data={formatNumber(summary ? summary.appUsers : 0)}
            change={
              summary && compareSummary
                ? (summary.appUsers - compareSummary.appUsers) /
                  compareSummary.appUsers
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <InsightsCard
            title="Repurchase Rate"
            data={formatNumber(
              summary ? summary.appRepeatPurchasers / summary.appPurchasers : 0,
              "percent"
            )}
            change={
              summary && compareSummary
                ? (summary.appRepeatPurchasers / summary.appPurchasers -
                    compareSummary.appRepeatPurchasers /
                      compareSummary.appPurchasers) /
                  (compareSummary.appRepeatPurchasers /
                    compareSummary.appPurchasers)
                : undefined
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InsightsLifetimeSection;
