import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useContext, useState } from "react";
import { ConnectionHandler, useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { BrandAdminTabDeleteMutation } from "./__generated__/BrandAdminTabDeleteMutation.graphql";
import { BrandAdminTab_brand$key } from "./__generated__/BrandAdminTab_brand.graphql";
import CurrentUserContext from "../../../../contexts/CurrentUserContext";
import SnackbarContext from "../../../../contexts/SnackbarContext";
import nullthrows from "../../../../utils/nullthrows";
import BrandAdminInviteDialog from "./BrandAdminInviteDialog";
import BrandAdminTable from "./BrandAdminTable";

const brandFragment = graphql`
  fragment BrandAdminTab_brand on BrandType {
    id
    ...BrandAdminTable_refetchableBrand
  }
`;

const deleteMutation = graphql`
  mutation BrandAdminTabDeleteMutation($input: NodeInput!) {
    deleteBrandAdmin(input: $input) {
      ... on GraphQLBrandAdminProfile {
        id
      }
    }
  }
`;

const BrandAdminTab = ({
  brand: brandKey,
}: {
  brand: BrandAdminTab_brand$key;
}) => {
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);
  const currentUserContext = useContext(CurrentUserContext);

  const brand = useFragment(brandFragment, brandKey);
  const brandID = brand.id;

  // Dialog State
  const [dialogData, setDialogData] = useState<{
    profileID: string;
    name: string;
    userID: string;
  } | null>(null);
  const isDialogOpen = dialogData !== null;
  const onDialogClose = () => setDialogData(null);

  // Invite Dialog State
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const onInviteDialogClose = () => setIsInviteDialogOpen(false);

  const [deleteProfile, isDeleting] =
    useMutation<BrandAdminTabDeleteMutation>(deleteMutation);

  const onDelete = useCallback(
    (id: string) => {
      deleteProfile({
        variables: {
          input: {
            id: id,
          },
        },
        onCompleted: (data, errors) => {
          if (errors) {
            snackbarContext?.openSnackbar("Failed to remove admin", "error");
          } else {
            snackbarContext?.openSnackbar("Admin removed", "success");
            onDialogClose();
          }
        },
        onError: (error: Error) => {
          snackbarContext?.openSnackbar("Failed to remove admin", "error");
        },
        updater: (store) => {
          const payload = store.getRootField("deleteBrandAdmin");

          const brand = store.get(brandID);
          if (brand == null) {
            return;
          }

          const adminProfiles = ConnectionHandler.getConnection(
            brand,
            "BrandAdminTable_admins"
          );
          if (adminProfiles == null) {
            return;
          }
          ConnectionHandler.deleteNode(adminProfiles, payload.getDataID());

          // Decrement total count
          const profileCount = adminProfiles.getValue("totalCount");
          if (profileCount != null) {
            adminProfiles.setValue(Number(profileCount) - 1, "totalCount");
          }
        },
      });
    },
    [deleteProfile, brandID, snackbarContext]
  );

  let dialogTitle =
    "Are you sure you want to remove " + dialogData?.name + " as an admin?";
  let dialogContent =
    " They won't be able to access the admin panel for this brand anymore.";
  let dialogButton = "Delete";
  if (currentUserContext?.userID === dialogData?.userID) {
    dialogTitle = "Are you sure you want to remove yourself as an admin?";
    dialogContent =
      "You won't be able to access the admin panel for this brand anymore.";
    dialogButton = "Leave";
  }

  return (
    <Container maxWidth="md">
      <Stack spacing={2} direction="column" alignItems="flex-end">
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          sx={{
            display: "flex",
          }}
          onClick={() => setIsInviteDialogOpen(true)}
        >
          Invite Admins
        </Button>
        <BrandAdminTable brand={brand} onSetDialogData={setDialogData} />
      </Stack>
      <BrandAdminInviteDialog
        brandID={brandID}
        isOpen={isInviteDialogOpen}
        onDialogClose={onInviteDialogClose}
      />
      <Dialog
        open={isDialogOpen}
        onClose={onDialogClose}
        aria-labelledby="link-post-deletion-dialog"
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={isDeleting}
            onClick={() => {
              onDelete(nullthrows(dialogData?.profileID));
              if (currentUserContext?.userID === dialogData?.userID) {
                navigate("/logout");
              }
            }}
            color="error"
          >
            {dialogButton}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default BrandAdminTab;
