import { Box, CircularProgress } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { Suspense, useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { Route, Routes } from "react-router-dom";
import { SetupContainerQuery } from "./__generated__/SetupContainerQuery.graphql";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { SectionType, SidepaneConfig } from "../../../utils/constants";
import GetStartedTab from "./GetStartedTab";

const query = graphql`
  query SetupContainerQuery($id: ID!) {
    user {
      isInternal
    }
    brand(id: $id) {
      ...GetStartedTab_brand
      finishedOnboarding
    }
  }
`;

const config = SidepaneConfig.find(
  (config) => config.sectionType === SectionType.SETUP
);

const SetupContainer = () => {
  const currentUserContext = useContext(CurrentUserContext);
  const data = useLazyLoadQuery<SetupContainerQuery>(query, {
    id: currentUserContext?.activeBrandID ?? "",
  });
  const brand = data.brand;
  const user = data.user;
  const brandFinishedOnboarding = data.brand.finishedOnboarding === true;

  return (
    <Box>
      <Routes>
        {config?.sections
          .filter((config) => {
            return config.isPublic || user?.isInternal === true;
          })
          .map((config, index) => {
            if (config.isDisabledPreOnboarded && !brandFinishedOnboarding) {
              return null;
            }

            let element = null;

            switch (config.pathname) {
              case "/get-started":
                element = <GetStartedTab brand={brand} />;
                break;
            }
            return (
              <Route
                path={config.pathname + "/*"}
                element={
                  <Suspense
                    fallback={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    }
                  >
                    {element}
                  </Suspense>
                }
                key={index}
              />
            );
          })}
      </Routes>
    </Box>
  );
};

export default SetupContainer;
