import { createContext } from "react";
import { NavigationItem } from "../navigation/ShortcutsCard";

interface DrawerInterface {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (open: boolean) => void;

  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  parentIndex: number | null;
  setParentIndex: (index: number | null) => void;

  title: string;
  children: ReadonlyArray<NavigationItem>;
  setTitle: (title: string) => void;
  setChildren: (children: ReadonlyArray<NavigationItem>) => void;

  enableSaveButton: () => void;
}

export default createContext<DrawerInterface>({
  isDrawerOpen: false,
  setIsDrawerOpen: () => {},
  currentIndex: 0,
  setCurrentIndex: () => {},
  enableSaveButton: () => {},
  parentIndex: null,
  setParentIndex: () => {},
  title: "",
  children: [],
  setTitle: () => {},
  setChildren: () => {},
});
