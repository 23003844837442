import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { FocusEvent, useCallback, useContext } from "react";
import LeftRight from "../../../../components/LeftRight";
import RighthandDrawer from "../../../../components/RighthandDrawer";
import {
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../../contexts/MobilePreviewContext";
import DrawerContext from "../contexts/DrawerContext";
import { NavigationItem } from "./ShortcutsCard";

const parentDroppable = "shortcuts-drawer-droppable";

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  paddingTop: 12,

  // change background colour if dragging
  background: "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const ShortcutsCardDrawer = ({
  shortcuts,
  setShortcuts,
}: {
  shortcuts: ReadonlyArray<NavigationItem>;
  setShortcuts: (shortcuts: ReadonlyArray<NavigationItem>) => void;
}) => {
  const dispatch = useMobilePreviewDispatch();

  const {
    isDrawerOpen,
    setIsDrawerOpen,
    parentIndex,
    currentIndex,
    enableSaveButton,
    setChildren,
    title,
    children,
  } = useContext(DrawerContext);

  const isToplevel = parentIndex === null;

  const onClose = useCallback(() => {
    setChildren([]);
    setIsDrawerOpen(false);
  }, [setIsDrawerOpen, setChildren]);

  const onSave = () => {
    const newArray = Array.from(shortcuts);

    if (isToplevel) {
      newArray[currentIndex] = {
        ...newArray[currentIndex],
      };
    } else {
      const parent = newArray[parentIndex];
      const newChildren = Array.from(parent.children);
      newChildren[currentIndex] = {
        ...newChildren[currentIndex],
        children,
      };
      newArray[parentIndex] = {
        ...newArray[parentIndex],
        children: newChildren,
      };
    }

    dispatch({
      type: ReducerAction.UPDATE_NAVIGATION,
      payload: {
        navigation: {
          shortcutsPills: newArray,
        },
      },
    });
    setShortcuts(newArray);
    enableSaveButton();
    onClose();
  };

  return (
    <DragDropContext
      onDragEnd={(result) => {
        const { destination, source } = result;

        if (!destination) {
          return;
        }

        if (
          destination.droppableId !== source.droppableId ||
          destination.index === source.index
        ) {
          return;
        }

        const newChildren = Array.from(children);
        const [removed] = newChildren.splice(source.index, 1);
        newChildren.splice(destination.index, 0, removed);
        setChildren(newChildren);
      }}
    >
      <RighthandDrawer
        isOpen={isDrawerOpen}
        title="Edit Shortcut"
        onClose={onClose}
      >
        <Stack
          sx={{
            padding: 2,
          }}
          spacing={2}
        >
          <Typography variant="h6">{title}</Typography>
          {!isToplevel && (
            <Droppable droppableId={parentDroppable}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {children.map((child, index) => {
                    const onTitleChange = (
                      event: FocusEvent<HTMLInputElement>
                    ) => {
                      const newValue = {
                        ...child,
                        label: event.target.value,
                      };
                      const newArray = Array.from(children);
                      newArray[index] = newValue;

                      setChildren(newArray);
                    };

                    const onUrlChange = (
                      event: FocusEvent<HTMLInputElement>
                    ) => {
                      const newValue = {
                        ...child,
                        url: event.target.value,
                      };
                      const newArray = Array.from(children);
                      newArray[index] = newValue;

                      setChildren(newArray);
                    };

                    const onDelete = () => {
                      const newChildren = Array.from(children);
                      newChildren.splice(index, 1);
                      setChildren(newChildren);
                    };

                    return (
                      <Draggable
                        key={index}
                        draggableId={"shortcuts-card-draggable-" + index}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <LeftRight
                              left={<DragIndicatorIcon />}
                              right={
                                <Typography variant="overline">
                                  Nested Shortcut
                                </Typography>
                              }
                            />
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems="center"
                              paddingTop={2}
                            >
                              <TextField
                                sx={{
                                  flexGrow: 1,
                                }}
                                inputProps={{
                                  maxLength: 255,
                                }}
                                id="outlined-basic"
                                label={"Shortcut Title"}
                                variant="outlined"
                                value={child.label}
                                onChange={onTitleChange}
                              />
                              <TextField
                                sx={{
                                  flexGrow: 1,
                                  "& .MuiInputBase-input": {
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  },
                                }}
                                id="outlined-basic"
                                label="Link"
                                variant="outlined"
                                value={child.url}
                                onChange={onUrlChange}
                              />
                              <Button
                                size="medium"
                                variant="text"
                                color="error"
                                onClick={onDelete}
                              >
                                Delete
                              </Button>
                            </Stack>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
          {!isToplevel && (
            <Button
              disabled={false}
              variant="contained"
              color="secondaryLight"
              onClick={() => {
                setChildren(
                  children.concat([
                    {
                      label: "",
                      url: "",
                      children: [],
                    },
                  ])
                );
              }}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} />
              Add Nested Shortcut
            </Button>
          )}
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: 2,
          }}
        >
          <Button
            disabled={false}
            size="large"
            variant="outlined"
            color="error"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={
              children.find((child) => child.label === "") !== undefined
            }
            size="large"
            variant="contained"
            onClick={onSave}
          >
            Done
          </Button>
        </Stack>
      </RighthandDrawer>
    </DragDropContext>
  );
};

export default ShortcutsCardDrawer;
