import {
  Autocomplete,
  Chip,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { ChangeEvent, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { UploadableMap } from "relay-runtime";
import {
  AppListingTab_brand$key,
  AppStoreCategory,
} from "./__generated__/AppListingTab_brand.graphql";
import CardSection from "../../../components/CardSection";
import SaveButton, { SavedState } from "../../../components/SaveButton";
import UploadedFileCard from "../../../components/UploadedFileCard";
import useBrandAppConfigUpdateMutation from "../../../mutations/useBrandAppConfigUpdateMutation";
import AppDistributionCard from "./AppDistributionCard";
import ScreenshotsCard from "./ScreenshotsCard";

const KEYWORD_CHAR_LIMIT = 100;

const brandFragment = graphql`
  fragment AppListingTab_brand on BrandType {
    ...ScreenshotsCard_brand
    ...AppDistributionCard_brand
    appConfig {
      id
      appDescription
      appIconUrl
      appName
      appSubtitle
      appStoreCategories
      keywords
      primaryCategory
      secondaryCategory
      privacyPolicyUrl
      promotionalText
      supportUrl
      marketingUrl
    }
  }
`;

const AppListingTab = ({
  brand: brandKey,
}: {
  brand: AppListingTab_brand$key;
}) => {
  const brand = useFragment(brandFragment, brandKey);
  const appConfig = brand.appConfig;
  const initAppDescription = appConfig.appDescription ?? "";
  const initAppName = appConfig.appName ?? "";
  const initAppSubtitle = appConfig.appSubtitle ?? "";
  const initPrivacyPolicyUrl = appConfig.privacyPolicyUrl;
  const initSupportUrl = appConfig.supportUrl;
  const initMarketingUrl = appConfig.marketingUrl;
  const iconUrl = appConfig.appIconUrl ?? null;
  const isEmpty = initAppName === "" && initAppSubtitle === "";

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    isEmpty ? SavedState.DISABLED : SavedState.SAVED
  );
  const [mutationErrors, setMutationErrors] = useState<
    Array<{
      field: string;
      message: string;
    }>
  >([]);

  // App Info
  const [appName, setAppName] = useState<string>(initAppName);
  const [appSubtitle, setAppSubtitle] = useState<string>(initAppSubtitle);
  const [appDescription, setAppDescription] =
    useState<string>(initAppDescription);
  const [promotionalText, setPromotionalText] = useState<string>(
    appConfig.promotionalText
  );
  // Categories
  const [primaryCategory, setPrimaryCategory] = useState<AppStoreCategory | "">(
    appConfig.primaryCategory ?? ""
  );
  const [secondaryCategory, setSecondaryCategory] = useState<
    AppStoreCategory | ""
  >(appConfig.secondaryCategory ?? "");
  const [keywords, setKeywords] = useState<string>(appConfig.keywords);
  const [keywordInput, setKeywordInput] = useState<string>("");

  // Urls
  const [privacyPolicyUrl, setPrivacyPolicyUrl] =
    useState<string>(initPrivacyPolicyUrl);
  const [supportUrl, setSupportUrl] = useState<string>(initSupportUrl);
  const [marketingUrl, setMarketingUrl] = useState<string>(initMarketingUrl);

  const [appIconUrl, setAppIconUrl] = useState<string | null>(iconUrl);
  const [mediaUploadable, setMediaUploadable] = useState<UploadableMap>({});

  const defaultOptions: Array<string> = useMemo(() => [], []);
  const keywordsArr =
    keywords.length === 0 ? defaultOptions : keywords.split(",");
  const keywordsComponent = (
    <>
      <div>
        <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
          Keywords
        </Typography>
        <Typography sx={{ paddingRight: "32px" }} variant="body2">
          Choose keywords based on words you think your audience will use to
          find an app like yours. Commas are automatically added to the end of
          each word and will count towards the character limit
        </Typography>
      </div>
      <Autocomplete
        multiple
        options={defaultOptions}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => {
            return (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            );
          })
        }
        renderInput={(params) => {
          const supposedLength = keywords.length + keywordInput.length;
          return (
            <TextField
              {...params}
              label={"Keywords " + supposedLength + "/" + KEYWORD_CHAR_LIMIT}
            />
          );
        }}
        inputValue={keywordInput}
        onInputChange={(event, value, reason) => {
          // Enter is only use in onChange
          if (reason === "input" && value === "\n") {
            return;
          }
          // Have to account for comma if there is more that 1 keyword
          const supposedLength =
            keywords.length === 0
              ? keywordInput.length
              : keywords.length + 1 + keywordInput.length;

          // If deleting, always update
          if (value.length < keywordInput.length) {
            setKeywordInput(value);
          } else if (reason === "reset") {
            setKeywordInput("");
          } else if (
            // Don't allow commas and make sure we apply char limit
            supposedLength < KEYWORD_CHAR_LIMIT &&
            reason === "input" &&
            !value.includes(",")
          ) {
            setKeywordInput(value);
          }

          saveButtonState !== SavedState.ENABLED &&
            setSaveButtonState(SavedState.ENABLED);
        }}
        value={keywordsArr}
        onChange={(event, newInputValue) => {
          const combinedKeywords = newInputValue.join(",");
          if (combinedKeywords.length <= KEYWORD_CHAR_LIMIT) {
            setKeywords(combinedKeywords);
          } else {
            event.preventDefault();
          }
          saveButtonState !== SavedState.ENABLED &&
            setSaveButtonState(SavedState.ENABLED);
        }}
      />
    </>
  );

  const [saveMutation, isMutationInFlight] = useBrandAppConfigUpdateMutation(
    {
      id: brand.appConfig.id,
      appDescription,
      appName,
      appSubtitle,
      appIcon: appIconUrl === null ? null : undefined,
      primaryCategory: primaryCategory === "" ? undefined : primaryCategory,
      secondaryCategory:
        secondaryCategory === "" ? undefined : secondaryCategory,
      privacyPolicyUrl,
      marketingUrl,
      supportUrl,
      keywords,
      promotionalText,
    },
    () => {
      setSaveButtonState(SavedState.SAVED);
    },
    mediaUploadable,
    setMutationErrors
  );

  return (
    <Container maxWidth="md">
      <CardSection
        actions={
          <SaveButton
            savedState={
              isMutationInFlight ? SavedState.DISABLED : saveButtonState
            }
            onClick={saveMutation}
          />
        }
        title={"App Listing"}
        content={
          <Stack spacing={2} width="100%">
            <TextField
              fullWidth
              value={appName}
              label="App Name"
              variant="outlined"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setAppName(event.target.value);
                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
              inputProps={{
                maxLength: 30,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {appName.length} / 30
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              value={appSubtitle}
              label="App Subtitle"
              variant="outlined"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setAppSubtitle(event.target.value);
                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
              inputProps={{
                maxLength: 30,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {appSubtitle.length} / 30
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              value={promotionalText}
              label="Promotional Text"
              variant="outlined"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setPromotionalText(event.target.value);
                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
              inputProps={{
                maxLength: 170,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {promotionalText.length} / 170
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              value={appDescription}
              label="App Description"
              variant="outlined"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setAppDescription(event.target.value);
                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
            />
            <UploadedFileCard
              error={
                mutationErrors?.find((error) => error.field === "appIcon")
                  ?.message ?? undefined
              }
              fileURL={appIconUrl}
              fileType={".png, .jpg"}
              htmlLabel={"app-icon-upload"}
              inputLabel={"App Icon"}
              inputText={"Upload a .png or .jpg"}
              mediaType="PHOTO"
              onClose={() => {
                setAppIconUrl(null);
                delete mediaUploadable["appIcon"];

                setMutationErrors(
                  mutationErrors?.filter((error) => {
                    return error.field !== "appIcon";
                  })
                );

                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
              onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
                const file = event?.target?.files?.[0];
                if (file == null) {
                  return;
                }

                const url = URL.createObjectURL(file);
                setAppIconUrl(url);
                const newUploadables: UploadableMap = {};
                newUploadables["appIcon"] = file;
                setMediaUploadable(newUploadables);

                setMutationErrors(
                  mutationErrors?.filter((error) => {
                    return error.field !== "appIcon";
                  })
                );

                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
            />
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <FormControl fullWidth>
                <InputLabel>Primary Category</InputLabel>
                <Select
                  value={primaryCategory}
                  label="Primary Category"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        height: "200px",
                      },
                    },
                  }}
                  onChange={(event: SelectChangeEvent) => {
                    const value = event.target.value as AppStoreCategory;
                    setPrimaryCategory(value);

                    saveButtonState !== SavedState.ENABLED &&
                      setSaveButtonState(SavedState.ENABLED);
                  }}
                >
                  {appConfig.appStoreCategories
                    .filter((el) => el !== secondaryCategory)
                    .map((category, index) => {
                      const cleaned = category.replaceAll("_", " ");
                      return (
                        <MenuItem key={index} value={category}>
                          {cleaned.substring(0, 1) +
                            cleaned.substring(1).toLowerCase()}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>Secondary Category</InputLabel>
                <Select
                  value={secondaryCategory}
                  label="Secondary Category"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        height: "200px",
                      },
                    },
                  }}
                  onChange={(event: SelectChangeEvent) => {
                    const value = event.target.value as AppStoreCategory;
                    setSecondaryCategory(value);

                    saveButtonState !== SavedState.ENABLED &&
                      setSaveButtonState(SavedState.ENABLED);
                  }}
                >
                  {appConfig.appStoreCategories
                    .filter((el) => el !== primaryCategory)
                    .map((category, index) => {
                      const cleaned = category.replaceAll("_", " ");
                      return (
                        <MenuItem key={index} value={category}>
                          {cleaned.substring(0, 1) +
                            cleaned.substring(1).toLowerCase()}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Stack>
            {keywordsComponent}
            <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
              Business Information
            </Typography>
            <TextField
              fullWidth
              value={privacyPolicyUrl}
              label="Privacy Policy URL"
              variant="outlined"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setPrivacyPolicyUrl(event.target.value);
                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
            />
            <TextField
              fullWidth
              value={marketingUrl}
              label="Marketing URL"
              variant="outlined"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setMarketingUrl(event.target.value);
                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
            />
            <TextField
              fullWidth
              value={supportUrl}
              label="Support URL"
              variant="outlined"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSupportUrl(event.target.value);
                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
            />
          </Stack>
        }
      />
      <ScreenshotsCard brand={brand} />
      <AppDistributionCard brand={brand} />
    </Container>
  );
};

export default AppListingTab;
