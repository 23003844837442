import Cookies from "js-cookie";
import { CacheConfig, RequestParameters, UploadableMap } from "relay-runtime";

// Taken from https://github.com/facebook/relay/issues/1844#issuecomment-316893590
async function fetchGraphQL(
  params: RequestParameters,
  variables: object | null,
  cacheConfig: CacheConfig,
  uploadables?: UploadableMap | null
): Promise<{ data: any }> {
  const request: { [key: string]: any } = {
    method: "POST",
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken") ?? "",
    },
  };

  if (uploadables) {
    if (!window.FormData) {
      throw new Error("Uploading files without `FormData` not supported.");
    }

    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({
        query: params.text,
        variables,
        operationName: params.name,
      })
    );

    const map: { [key: string]: any } = {};
    Object.keys(uploadables).forEach((key, index) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(key, uploadables[key]);
      }

      // https://strawberry.rocks/docs/guides/file-upload#sending-file-upload-requests
      // You have to pass down a "map" of input fields to the uploadable filename
      map[key] = ["variables.input." + key];
    });

    formData.append("map", JSON.stringify(map));

    request["body"] = formData;
  } else {
    request.headers["Content-Type"] = "application/json";
    request.body = JSON.stringify({
      query: params.text,
      variables,
      operationName: params.name,
    });
  }

  return fetch("/graphql", request)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }

      // HTTP errors
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
}

export default fetchGraphQL;
