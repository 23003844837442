import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import {
  Avatar,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useMemo } from "react";
import { useFragment } from "react-relay";
import { useLocation, useNavigate } from "react-router-dom";
import { InterstitialNotificationTableRow_notification$key } from "./__generated__/InterstitialNotificationTableRow_notification.graphql";
import {
  LandingPath,
  TRIGGER_PATHS,
} from "../../../../../components/AppDestinationLink";
import CDPAudienceInfoTableCell from "../../../../../components/CDPAudienceInfoTableCell";
import UTMCampaignTableCell from "../../../../../components/UTMCampaignTableCell";

const notifFragment = graphql`
  fragment InterstitialNotificationTableRow_notification on GraphQLInterstitialNotification {
    id
    audiences {
      audienceName
    }
    callToActionTitle
    callToActionDestination
    frequencyCap
    isArchived
    triggerType
    triggerPath
    triggerAppViewType
    triggerDelaySeconds
    utmCampaign
    audienceLanguages
    thumbnailUrl
  }
`;

const secondsToShortDurationString = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours ? `${hours}h ` : ""}${
    hours || minutes ? `${minutes}m ` : ""
  }${remainingSeconds}s`;
};

export default function InterstitialNotificationTableRow({
  notification: notificationKey,
  onArchive,
}: {
  notification: InterstitialNotificationTableRow_notification$key;
  onArchive: () => void;
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const notification = useFragment(notifFragment, notificationKey);
  const isNew = false;
  const isArchived = notification.isArchived;

  const title = (
    <ListItem>
      <ListItemAvatar>
        <Avatar
          alt=""
          src={notification.thumbnailUrl ?? ""}
          variant="rounded"
          sx={{ bgcolor: theme.palette.info.main, color: "black" }}
        >
          <InsertPhotoIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <Typography
            variant="body2"
            color={"black"}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {notification.callToActionTitle}
          </Typography>
        }
      />
    </ListItem>
  );

  const trigger = useMemo(() => {
    switch (notification.triggerType) {
      case "PAGE_TRIGGER":
        return (
          <Tooltip
            title={`${
              notification.triggerAppViewType
                ? TRIGGER_PATHS[notification.triggerAppViewType as LandingPath]
                    ?.label
                : notification.triggerPath
            }`}
            placement="top"
          >
            <ListItemText
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
              primary={
                <Typography variant="body2" color="black">
                  {`page hit`}
                </Typography>
              }
              onClick={() => {
                if (!notification.triggerPath) return;
                window.open(notification.triggerPath, "_blank");
              }}
            />
          </Tooltip>
        );
      case "SESSION_DELAY":
        return (
          <Typography variant="body2" color="black">
            {`after ${secondsToShortDurationString(
              notification.triggerDelaySeconds
            )}`}
          </Typography>
        );
      default:
        return "";
    }
  }, [notification]);

  const editButton = !isArchived ? (
    <Button
      size="small"
      variant="text"
      color="primary"
      onClick={() => navigate(location.pathname + `/edit/${notification.id}`)}
    >
      Edit
    </Button>
  ) : null;

  const archiveButton = !isArchived ? (
    <Button size="small" variant="text" color="error" onClick={onArchive}>
      Archive
    </Button>
  ) : null;

  return (
    <TableRow
      sx={{
        background: isNew ? "rgba(0,0,0, 0.08)" : "white",
        transition: "background .2s ease-in",
      }}
    >
      <TableCell>{title}</TableCell>
      <TableCell align="center">{trigger}</TableCell>
      <CDPAudienceInfoTableCell item={notification} />
      <UTMCampaignTableCell utmCampaign={notification.utmCampaign} />
      <TableCell align="center">{notification.frequencyCap}</TableCell>
      <TableCell align="center">
        {isArchived && (
          <Typography variant="body2" color="GrayText">
            Archived
          </Typography>
        )}
        {!isArchived && (
          <Stack direction="row" justifyContent={"center"}>
            {editButton}
            {archiveButton}
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
}
