/**
 * @generated SignedSource<<47465ef38334d0024283a41f8d3b5c05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CDPAudienceType = "LIST" | "SEGMENT" | "%future added value";
export type CDPType = "KINN" | "KLAVIYO" | "OMETRIA" | "SHOPIFY" | "%future added value";
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
export type GraphQLHighlightAlbumInputPartial = {
  audienceLanguages?: ReadonlyArray<KinnLanguageCode> | null;
  audiences?: ReadonlyArray<GraphQLCDPAudienceInput> | null;
  id: string;
  index?: number | null;
  isArchived?: boolean | null;
  title?: string | null;
};
export type GraphQLCDPAudienceInput = {
  audienceId: string;
  audienceName: string;
  audienceType: CDPAudienceType;
  cdpType: CDPType;
};
export type HighlightsTabUpdateAlbumMutation$variables = {
  input: GraphQLHighlightAlbumInputPartial;
};
export type HighlightsTabUpdateAlbumMutation$data = {
  readonly updateHighlightAlbum: {
    readonly archivedCount: number;
    readonly audienceLanguages: ReadonlyArray<KinnLanguageCode> | null;
    readonly audiences: ReadonlyArray<{
      readonly audienceId: string;
    }>;
    readonly count: number;
    readonly coverImageUrl: string | null;
    readonly createdAt: any;
    readonly id: string;
    readonly isArchived: boolean;
    readonly title: string;
  };
};
export type HighlightsTabUpdateAlbumMutation = {
  response: HighlightsTabUpdateAlbumMutation$data;
  variables: HighlightsTabUpdateAlbumMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archivedCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "audienceLanguages",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverImageUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArchived",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "audienceId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HighlightsTabUpdateAlbumMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLHighlightAlbum",
        "kind": "LinkedField",
        "name": "updateHighlightAlbum",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLCDPAudience",
            "kind": "LinkedField",
            "name": "audiences",
            "plural": true,
            "selections": [
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HighlightsTabUpdateAlbumMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLHighlightAlbum",
        "kind": "LinkedField",
        "name": "updateHighlightAlbum",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLCDPAudience",
            "kind": "LinkedField",
            "name": "audiences",
            "plural": true,
            "selections": [
              (v10/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3b0bc3da4e015c4f1341026a6e0b7c40",
    "id": null,
    "metadata": {},
    "name": "HighlightsTabUpdateAlbumMutation",
    "operationKind": "mutation",
    "text": "mutation HighlightsTabUpdateAlbumMutation(\n  $input: GraphQLHighlightAlbumInputPartial!\n) {\n  updateHighlightAlbum(input: $input) {\n    id\n    archivedCount\n    audienceLanguages\n    count\n    coverImageUrl\n    createdAt\n    isArchived\n    title\n    audiences {\n      audienceId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f190d8081280fc10641dd4805465aa54";

export default node;
