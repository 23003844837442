/**
 * @generated SignedSource<<5bec9787d0302ae2aac405367c75d96c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BrandAdminTableRow_brandAdminProfile$data = {
  readonly id: string;
  readonly isOnboarded: boolean;
  readonly user: {
    readonly email: string;
    readonly firstName: string;
    readonly id: string;
    readonly lastName: string;
  };
  readonly " $fragmentType": "BrandAdminTableRow_brandAdminProfile";
};
export type BrandAdminTableRow_brandAdminProfile$key = {
  readonly " $data"?: BrandAdminTableRow_brandAdminProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrandAdminTableRow_brandAdminProfile">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrandAdminTableRow_brandAdminProfile",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOnboarded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserType",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GraphQLBrandAdminProfile",
  "abstractKey": null
};
})();

(node as any).hash = "72bafa7aacc1d11a52a151f4d26c1992";

export default node;
