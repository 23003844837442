const delete_cookie = (name: String) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/";
};

export const deleteFacebookCookies = () => {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    if (cookies[i].split("=")[0].indexOf("fblo_") !== -1)
      delete_cookie(cookies[i].split("=")[0]);
  }
};
