import { IconProps } from "react-feather";


type CustomIconProps = IconProps & {
  SVG: React.FC<React.SVGProps<SVGSVGElement>>;
};

const CustomIcon: React.FC<CustomIconProps> = ({
  color = "currentColor",
  size = 24,
  SVG,
  ...props
}) => {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      {...props}
    />
  );
};

export default CustomIcon;