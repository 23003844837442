import {
  Autocomplete,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { useFragment } from "react-relay";
import {
  CDPAudienceFilters_brand$key,
  CDPType,
} from "./__generated__/CDPAudienceFilters_brand.graphql";
import { getCDPTypeNameMapping } from "../utils/cdpUtils";
import { KinnLanguageCode } from "../utils/languageMap";
import { CDPAudienceLanguageSelector } from "./CDPAudienceLanguageSelector";

const brandFragment = graphql`
  fragment CDPAudienceFilters_brand on BrandType {
    ...CDPAudienceLanguageSelector_brand
    cdps {
      cdpType
      audiences {
        id
        name
        type
      }
    }
  }
`;

export type CDPAudienceFiltersProps = {
  audienceLanguages: KinnLanguageCode[];
  brand: CDPAudienceFilters_brand$key;
  onAudienceChange: (ids: string[]) => void;
  onLanguageFiltersChange: (languages: KinnLanguageCode[]) => void;
};

const CDPAudienceFilters = ({
  audienceLanguages,
  brand: brandKey,
  onAudienceChange,
  onLanguageFiltersChange,
}: CDPAudienceFiltersProps) => {
  const brand = useFragment(brandFragment, brandKey);
  const cdps = brand.cdps;

  const [open, setOpen] = useState<boolean>(false);

  const [tab, setTab] = useState<CDPType>("KINN");
  const onTabChange = (e: React.SyntheticEvent, newValue: string) => {
    setTab(newValue as CDPType);
  };

  const cdpAudiences = cdps.map((cdp) => {
    return cdp.audiences.map((audience) => ({
      ...audience,
      providerType: cdp.cdpType,
    }));
  });
  const filterAudience = (
    <Autocomplete
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      limitTags={2}
      multiple
      options={[
        {
          id: "",
          name: "All Customers",
          type: "ALL CUSTOMERS",
          providerType: tab,
        },
        ...cdpAudiences.flat().sort((a, b) => -b.type.localeCompare(a.type)),
      ]}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      size="small"
      sx={{ width: "400px" }}
      groupBy={(option) => option.type}
      getLimitTagsText={(more) => "+" + more + " audiences"}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            sx: { fontSize: "12px" },
          }}
          label={<Typography variant="body2">Filter by Audience</Typography>}
          placeholder="Search"
        />
      )}
      renderOption={(props, option) => {
        if (option.providerType !== tab) {
          return null;
        }
        return (
          <li {...props}>
            <Typography variant="body2" color="black">
              {option.name}
            </Typography>
          </li>
        );
      }}
      PaperComponent={(props) => {
        const { children, ...other } = props;
        return (
          <Paper
            {...other}
            onMouseDown={(event) => {
              // Prevent blur
              event.preventDefault();
            }}
          >
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={onTabChange}
              centered
            >
              {cdps.map((cdp, index) => (
                <Tab
                  key={index}
                  label={getCDPTypeNameMapping(cdp.cdpType)}
                  value={cdp.cdpType}
                />
              ))}
            </Tabs>
            {children}
          </Paper>
        );
      }}
      onChange={(event, newInputValue) => {
        onAudienceChange(newInputValue.map((input) => input.id));
      }}
    />
  );
  const filterLanguage = (
    <CDPAudienceLanguageSelector
      audienceLanguages={audienceLanguages}
      brand={brand}
      onLanguageFiltersChange={onLanguageFiltersChange}
      size="small"
      sx={{ minWidth: "200px" }}
    />
  );
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
      sx={{
        padding: "16px 16px",
      }}
    >
      {filterAudience}
      {filterLanguage}
    </Stack>
  );
};

export default CDPAudienceFilters;
