import {
  Box,
  CssBaseline,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { Suspense, useContext, useMemo } from "react";
import { useLazyLoadQuery } from "react-relay";
import { MobilePreviewQuery } from "./__generated__/MobilePreviewQuery.graphql";
import CurrentUserContext from "../contexts/CurrentUserContext";
import {
  PreviewType,
  useMobilePreviewState,
} from "../contexts/MobilePreviewContext";
import getColorMapping from "../utils/getColorMapping";
import CarouselPreview from "./mobile_preview/CarouselPreview";
import DailyDealPreview from "./mobile_preview/DailyDealPreview";
import InterstitialNotificationPreview from "./mobile_preview/InterstitialNotificationPreview";
import NavigationPreview from "./mobile_preview/NavigationPreview";
import NotificationPreview from "./mobile_preview/NotificationPreview";
import PollPreview from "./mobile_preview/PollPreview";
import SinglePostPreview from "./mobile_preview/SinglePostPreview";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    header0: React.CSSProperties;
    header1: React.CSSProperties;
    body0: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    header0?: React.CSSProperties;
    header1?: React.CSSProperties;
    body0?: React.CSSProperties;
  }

  interface Palette {
    palettePrimary: Palette["primary"];
    primaryButtonBg: Palette["primary"];
    primaryButtonFg: Palette["primary"];
    secondaryOutline: Palette["primary"];
    primaryPillBg: Palette["primary"];
    primaryPillFg: Palette["primary"];
    secondaryPillOutline: Palette["primary"];
    pillContainerBackground: Palette["primary"];
    navigationForeground: Palette["primary"];
    navigationBackground: Palette["primary"];
    onboardingBackground: Palette["primary"];
    onboardingForeground: Palette["primary"];
  }

  interface PaletteOptions {
    palettePrimary?: PaletteOptions["primary"];
    primaryButtonBg?: PaletteOptions["primary"];
    primaryButtonFg?: PaletteOptions["primary"];
    secondaryOutline?: PaletteOptions["primary"];
    primaryPillBg?: PaletteOptions["primary"];
    primaryPillFg?: PaletteOptions["primary"];
    secondaryPillOutline?: PaletteOptions["primary"];
    pillContainerBackground?: PaletteOptions["primary"];
    navigationForeground?: PaletteOptions["primary"];
    navigationBackground?: PaletteOptions["primary"];
    onboardingBackground?: PaletteOptions["primary"];
    onboardingForeground?: PaletteOptions["primary"];
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    header0: true;
    header1: true;
    body0: true;
  }
}

const query = graphql`
  query MobilePreviewQuery($id: ID!) {
    brand(id: $id) {
      appConfig {
        accent1PaletteLight
        accent2PaletteLight
        accent3PaletteLight
        primaryPaletteLight
        buildTheme {
          buttonCartIcon
          buttonCornerRadius
          buttonTextStyle
          primaryButtonBackground
          primaryButtonForeground
          secondaryButtonOutline
          primaryPillBackground
          primaryPillForeground
          secondaryPillOutline
          pillContainerBackground
          navigationBackground
          navigationForeground
          onboardingBackground
          onboardingForeground
          shouldUseHeaderFontForBody
          fonts {
            name
            type
            fontUrl
          }
        }
      }
    }
  }
`;

const MobilePreview = () => {
  const { previewType, theme: themePreview } = useMobilePreviewState();

  const mainTheme = useTheme();
  const currentUserContext = useContext(CurrentUserContext);
  const data = useLazyLoadQuery<MobilePreviewQuery>(query, {
    id: currentUserContext?.activeBrandID ?? "",
  });
  const brand = data.brand;
  const config = brand.appConfig;
  const theme = brand.appConfig.buildTheme;

  const fonts = theme.fonts;
  const useHeaderForBody = theme.shouldUseHeaderFontForBody;
  const isHeaderBoldSet =
    themePreview.fontHeadingBold !== null ||
    fonts.find((font) => font.type === "HEADING_BOLD");
  const isBodyRegularSet = fonts.find((font) => font.type === "BODY_REGULAR");
  const fontStyleOverrides = fonts.map((font) => {
    let fUrl = font.fontUrl;
    if (font.type === "HEADING_BOLD") {
      fUrl = themePreview.fontHeadingBold ?? fUrl;
    } else if (font.type === "HEADING_REGULAR") {
      fUrl = themePreview.fontHeadingRegular ?? fUrl;
    }

    if (fUrl == null) {
      return ``;
    }
    return `
      @font-face {
          font-family: "${font.type}";
          src: url("${fUrl}");
      }
    `;
  });
  const palettePrimary =
    themePreview.primaryPaletteLight ?? config.primaryPaletteLight ?? "#000000";
  const paletteA1 =
    themePreview.accent1PaletteLight ?? config.accent1PaletteLight ?? "#FFFFFF";
  const paletteA2 =
    themePreview.accent2PaletteLight ?? config.accent2PaletteLight ?? "#000000";
  const paletteA3 =
    themePreview.accent3PaletteLight ?? config.accent3PaletteLight ?? "#000000";
  const primaryButtonBg =
    getColorMapping(
      themePreview.primaryButtonBackground ?? theme.primaryButtonBackground,
      palettePrimary,
      paletteA1,
      paletteA2,
      paletteA3
    ) || "black";
  const primaryButtonFg =
    getColorMapping(
      themePreview.primaryButtonForeground ?? theme.primaryButtonForeground,
      palettePrimary,
      paletteA1,
      paletteA2,
      paletteA3
    ) || "white";
  const secondaryOutline =
    getColorMapping(
      theme.secondaryButtonOutline,
      palettePrimary,
      paletteA1,
      paletteA2,
      paletteA3
    ) || "black";
  const primaryPillBg =
    getColorMapping(
      themePreview.primaryPillBackground ?? theme.primaryPillBackground,
      palettePrimary,
      paletteA1,
      paletteA2,
      paletteA3
    ) || "black";
  const primaryPillFg =
    getColorMapping(
      themePreview.primaryPillForeground ?? theme.primaryPillForeground,
      palettePrimary,
      paletteA1,
      paletteA2,
      paletteA3
    ) || "white";
  const secondaryPillOutline =
    getColorMapping(
      themePreview.secondaryPillOutline,
      palettePrimary,
      paletteA1,
      paletteA2,
      paletteA3
    ) || "transparent";
  const pillContainerBackground =
    getColorMapping(
      themePreview.pillContainerBackground,
      palettePrimary,
      paletteA1,
      paletteA2,
      paletteA3
    ) || "transparent";
  const navigationForeground =
    getColorMapping(
      themePreview.navigationForeground ?? theme.navigationForeground,
      palettePrimary,
      paletteA1,
      paletteA2,
      paletteA3
    ) || "black";
  const navigationBackground =
    getColorMapping(
      themePreview.navigationBackground ?? theme.navigationBackground,
      palettePrimary,
      paletteA1,
      paletteA2,
      paletteA3
    ) || "white";
  const onboardingBackground =
    getColorMapping(
      themePreview.onboardingBackground ?? theme.onboardingBackground,
      palettePrimary,
      paletteA1,
      paletteA2,
      paletteA3
    ) || "white";
  const onboardingForeground =
    getColorMapping(
      themePreview.onboardingForeground ?? theme.onboardingForeground,
      palettePrimary,
      paletteA1,
      paletteA2,
      paletteA3
    ) || "black";

  const buttonRadius =
    themePreview.buttonCornerRadius ?? theme.buttonCornerRadius;
  const buttonTextStyle = themePreview.buttonTextStyle ?? theme.buttonTextStyle;

  const buttonTheme = useMemo(
    () =>
      createTheme({
        components: {
          MuiCssBaseline: {
            styleOverrides: fontStyleOverrides.join(""),
          },
          MuiButton: {
            styleOverrides: {
              root: {
                background: primaryButtonBg,
                borderRadius: buttonRadius ?? undefined,
                color: primaryButtonFg,
                "&:hover": {
                  background: primaryButtonBg,
                },
              },
            },
          },
        },
        palette: {
          palettePrimary: {
            main: palettePrimary,
          },
          primaryButtonBg: {
            main: primaryButtonBg,
            contrastText: primaryButtonFg,
          },
          primaryButtonFg: {
            main: primaryButtonFg,
            contrastText: primaryButtonBg,
          },
          secondaryOutline: {
            main: secondaryOutline,
          },
          primaryPillBg: {
            main: primaryPillBg,
            contrastText: primaryPillFg,
          },
          primaryPillFg: {
            main: primaryPillFg,
            contrastText: primaryPillBg,
          },
          secondaryPillOutline: {
            main: secondaryPillOutline,
          },
          pillContainerBackground: {
            main: pillContainerBackground,
          },
          navigationForeground: {
            main: navigationForeground,
          },
          navigationBackground: {
            main: navigationBackground,
          },
          onboardingBackground: {
            main: onboardingBackground,
          },
          onboardingForeground: {
            main: onboardingForeground,
          },
        },
        typography: {
          button: {
            fontFamily:
              isHeaderBoldSet && useHeaderForBody
                ? "HEADING_REGULAR"
                : isBodyRegularSet
                ? "BODY_REGULAR"
                : undefined,
            textTransform:
              buttonTextStyle === "UPPERCASE" ? "uppercase" : "capitalize",
            fontWeight: 700,
            lineHeight: "26px",
          },
          // These typography names are to match mobile's: https://kinn.quip.com/9qcMALX1kHUe/Preview-Spec
          header0: {
            fontFamily: isHeaderBoldSet ? "HEADING_BOLD" : undefined,
            fontSize: "20px",
            fontWeight: 700,
          },
          header1: {
            fontFamily: isHeaderBoldSet ? "HEADING_BOLD" : undefined,
            fontSize: "16px",
            fontWeight: 700,
          },
          body0: {
            fontFamily:
              isHeaderBoldSet && useHeaderForBody
                ? "HEADING_BOLD"
                : isBodyRegularSet
                ? "BODY_BOLD"
                : undefined,
            fontSize: "14px",
            fontWeight: 700,
          },
          body2: {
            fontFamily:
              isHeaderBoldSet && useHeaderForBody
                ? "HEADING_REGULAR"
                : isBodyRegularSet
                ? "BODY_REGULAR"
                : undefined,
            fontSize: "14px",
            fontWeight: 400,
          },
        },
      }),
    [
      useHeaderForBody,
      isBodyRegularSet,
      isHeaderBoldSet,
      fontStyleOverrides,
      palettePrimary,
      primaryButtonBg,
      primaryButtonFg,
      secondaryOutline,
      primaryPillBg,
      primaryPillFg,
      secondaryPillOutline,
      pillContainerBackground,
      navigationForeground,
      navigationBackground,
      onboardingBackground,
      onboardingForeground,
      buttonRadius,
      buttonTextStyle,
    ]
  );

  // TODO: check for null fonts
  let previewItem = null;
  switch (previewType) {
    case PreviewType.SINGLE_POST:
      previewItem = <SinglePostPreview />;
      break;
    case PreviewType.POLL:
      previewItem = <PollPreview />;
      break;
    case PreviewType.NAVIGATION:
      previewItem = (
        <NavigationPreview brandID={currentUserContext?.activeBrandID ?? ""} />
      );
      break;
    case PreviewType.NOTIFICATION:
      previewItem = <NotificationPreview />;
      break;
    case PreviewType.INTERSTITIAL_NOTIFICATION:
      previewItem = <InterstitialNotificationPreview />;
      break;
    case PreviewType.CAROUSEL:
      previewItem = <CarouselPreview />;
      break;
    case PreviewType.DAILY_DEAL:
      previewItem = <DailyDealPreview />;
      break;
    default:
      break;
  }
  return (
    <ThemeProvider theme={buttonTheme}>
      {/* Note to self, we need to to apply the font... https://mui.com/material-ui/customization/typography/ */}
      <CssBaseline />
      <Box
        sx={{
          [mainTheme.breakpoints.down("xl")]: {
            display: "none",
          },
          display: "block",
          width: 400,
          flexShrink: 0,
          // flex: "0 0 auto",
          background: "#F1F3F6",
          py: 12,
          px: 4,
          overflowY: "auto",
        }}
      >
        <Suspense>{previewItem}</Suspense>
      </Box>
    </ThemeProvider>
  );
};

export default MobilePreview;
