import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { useFragment } from "react-relay";
import { UploadableMap } from "relay-runtime";
import { TypographyCard_brand$key } from "./__generated__/TypographyCard_brand.graphql";
import CardSection from "../../../components/CardSection";
import LeftRight from "../../../components/LeftRight";
import SaveButton, { SavedState } from "../../../components/SaveButton";
import UploadedFileCard from "../../../components/UploadedFileCard";
import {
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../contexts/MobilePreviewContext";
import useBrandAppConfigUpdateMutation from "../../../mutations/useBrandAppConfigUpdateMutation";

const brandFragment = graphql`
  fragment TypographyCard_brand on BrandType {
    appConfig {
      id
      buildTheme {
        id
        fonts {
          name
          type
        }
        shouldUseHeaderFontForBody
      }
    }
  }
`;

type Props = {
  brand: TypographyCard_brand$key;
};

const TypographyCard = ({ brand: brandKey }: Props) => {
  const dispatch = useMobilePreviewDispatch();

  const brand = useFragment<TypographyCard_brand$key>(brandFragment, brandKey);
  const buildTheme = brand?.appConfig?.buildTheme;
  const fonts = buildTheme?.fonts ?? [];

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.SAVED
  );

  const [isToggledOn, setToggledOn] = useState<boolean>(fonts.length > 0);
  const onToggleChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setToggledOn(checked);
    saveButtonState !== SavedState.ENABLED &&
      setSaveButtonState(SavedState.ENABLED);
  };

  const [fontUploadables, setFontUploadables] = useState<UploadableMap>({});
  const [fontHeadingBold, setFontHeadingBold] = useState<string | null>(
    fonts.find((font) => {
      return font.type === "HEADING_BOLD";
    })?.name ?? null
  );
  const [fontHeadingRegular, setFontHeadingRegular] = useState<string | null>(
    fonts.find((font) => {
      return font.type === "HEADING_REGULAR";
    })?.name ?? null
  );
  const [fontBodyBold, setFontBodyBold] = useState<string | null>(
    fonts.find((font) => {
      return font.type === "BODY_BOLD";
    })?.name ?? null
  );
  const [fontBodyRegular, setFontBodyRegular] = useState<string | null>(
    fonts.find((font) => {
      return font.type === "BODY_REGULAR";
    })?.name ?? null
  );

  const [isCheckboxEnabled, setCheckboxEnabled] = useState(
    buildTheme.shouldUseHeaderFontForBody
  );

  const [saveMutation, isMutationInFlight] = useBrandAppConfigUpdateMutation(
    {
      id: brand.appConfig.id,
      buildTheme: {
        id: brand.appConfig.buildTheme.id,
        shouldUseHeaderFontForBody: isCheckboxEnabled,
        fontHeadingBold:
          !isToggledOn || fontHeadingBold === null ? null : undefined,
        fontHeadingRegular:
          !isToggledOn || fontHeadingRegular === null ? null : undefined,
        fontBodyBold: !isToggledOn || fontBodyBold === null ? null : undefined,
        fontBodyRegular:
          !isToggledOn || fontBodyRegular === null ? null : undefined,
      },
    },
    () => {
      setFontUploadables({});
      setSaveButtonState(SavedState.SAVED);
    },
    Object.keys(fontUploadables).length === 0 ? undefined : fontUploadables
  );

  const option1ExpandedSection = (
    <>
      <Typography variant="overline">HEADING FONT FILES</Typography>
      <UploadedFileCard
        filename={fontHeadingBold}
        fileType={".ttf, .otf"}
        htmlLabel={"HEADING_BOLD"}
        inputLabel={"Heading - Bold Weight"}
        inputText={"Upload a .ttf or .otf file"}
        mediaType="FILE"
        onClose={() => {
          setFontHeadingBold(null);
          delete fontUploadables["buildTheme.fontHeadingBold"];
        }}
        onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
          const file = event?.target?.files?.[0];
          if (file == null) {
            return;
          }

          dispatch({
            type: ReducerAction.UPDATE_THEME,
            payload: {
              theme: {
                fontHeadingBold: URL.createObjectURL(file),
              },
            },
          });
          setFontHeadingBold(file.name);
          const newUploadables: UploadableMap = {};
          newUploadables["buildTheme.fontHeadingBold"] = file;
          setFontUploadables({
            ...fontUploadables,
            ...newUploadables,
          });

          saveButtonState !== SavedState.ENABLED &&
            setSaveButtonState(SavedState.ENABLED);
        }}
      />

      <UploadedFileCard
        filename={fontHeadingRegular}
        fileType={".ttf, .otf"}
        htmlLabel={"HEADING_REGULAR"}
        inputLabel={"Heading - Regular Weight"}
        inputText={"Upload a .ttf or .otf file"}
        mediaType="FILE"
        onClose={() => {
          setFontHeadingRegular(null);
          delete fontUploadables["buildTheme.fontHeadingRegular"];
        }}
        onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
          const file = event?.target?.files?.[0];
          if (file == null) {
            return;
          }

          dispatch({
            type: ReducerAction.UPDATE_THEME,
            payload: {
              theme: {
                fontHeadingRegular: URL.createObjectURL(file),
              },
            },
          });
          setFontHeadingRegular(file.name);
          const newUploadables: UploadableMap = {};
          newUploadables["buildTheme.fontHeadingRegular"] = file;
          setFontUploadables({
            ...fontUploadables,
            ...newUploadables,
          });

          saveButtonState !== SavedState.ENABLED &&
            setSaveButtonState(SavedState.ENABLED);
        }}
      />

      <Typography variant="overline">BODY FONT FILES</Typography>
      <Typography variant="body2">
        Note: we use the same font file for button text as well.
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={isCheckboxEnabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const checked = event.target.checked;
              if (checked) {
                delete fontUploadables["buildTheme.fontBodyBold"];
                delete fontUploadables["buildTheme.fontBodyRegular"];

                setFontBodyBold(null);
                setFontBodyRegular(null);
              }
              setCheckboxEnabled(event.target.checked);
              saveButtonState !== SavedState.ENABLED &&
                setSaveButtonState(SavedState.ENABLED);
            }}
          />
        }
        label="Select this option if you only use one typeface across your website."
      />

      <Stack
        sx={{
          opacity: isCheckboxEnabled ? "40%" : undefined,
          pointerEvents: isCheckboxEnabled ? "none" : undefined,
        }}
        spacing={2}
      >
        <UploadedFileCard
          filename={fontBodyBold}
          fileType={".ttf, .otf"}
          htmlLabel={"BODY_BOLD"}
          inputLabel={"Body - Bold Weight"}
          inputText={"Upload a .ttf or .otf file"}
          mediaType="FILE"
          onClose={() => {
            setFontBodyBold(null);
            delete fontUploadables["buildTheme.fontBodyBold"];
          }}
          onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event?.target?.files?.[0];
            if (file == null) {
              return;
            }

            setFontBodyBold(file.name);
            const newUploadables: UploadableMap = {};
            newUploadables["buildTheme.fontBodyBold"] = file;
            setFontUploadables({
              ...fontUploadables,
              ...newUploadables,
            });

            saveButtonState !== SavedState.ENABLED &&
              setSaveButtonState(SavedState.ENABLED);
          }}
        />

        <UploadedFileCard
          filename={fontBodyRegular}
          fileType={".ttf, .otf"}
          htmlLabel={"BODY_REGULAR"}
          inputLabel={"Body - Regular Weight"}
          inputText={"Upload a .ttf or .otf file"}
          mediaType="FILE"
          onClose={() => {
            setFontBodyRegular(null);
            delete fontUploadables["buildTheme.fontBodyRegular"];
          }}
          onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event?.target?.files?.[0];
            if (file == null) {
              return;
            }

            setFontBodyRegular(file.name);
            const newUploadables: UploadableMap = {};
            newUploadables["buildTheme.fontBodyRegular"] = file;
            setFontUploadables({
              ...fontUploadables,
              ...newUploadables,
            });

            saveButtonState !== SavedState.ENABLED &&
              setSaveButtonState(SavedState.ENABLED);
          }}
        />
      </Stack>
    </>
  );

  const isTypographySet =
    (fontHeadingRegular !== null &&
      fontHeadingBold !== null &&
      fontBodyBold !== null &&
      fontBodyRegular !== null) ||
    (fontHeadingRegular !== null &&
      fontHeadingBold !== null &&
      isCheckboxEnabled);

  return (
    <CardSection
      actions={
        <SaveButton
          savedState={
            isMutationInFlight || (isToggledOn && !isTypographySet)
              ? SavedState.DISABLED
              : saveButtonState
          }
          onClick={saveMutation}
        />
      }
      title={"Typography"}
      content={
        <Stack
          spacing={2}
          sx={{
            maxWidth: "650px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <Typography variant="subtitle1">
                Upload your font files
              </Typography>
              <Typography sx={{ paddingBottom: "16px" }} variant="body2">
                Upload bold and regular font files (or equivalent) of the
                typeface you use for heading and body text on your website.
              </Typography>
            </Box>
            <Switch checked={isToggledOn} onChange={onToggleChange} />
          </Box>
          {isToggledOn && option1ExpandedSection}
          {!isToggledOn && (
            <Stack
              spacing={2}
              sx={{
                background: "rgba(209, 67, 67, 0.1)",
                borderRadius: "8px",
                color: "#D14343",
                p: 4,
              }}
            >
              <LeftRight
                left={<InfoOutlinedIcon color="error" fontSize="small" />}
                right={
                  <Typography
                    color={"error"}
                    variant="subtitle2"
                    sx={{ fontWeight: "700" }}
                  >
                    Risk
                  </Typography>
                }
              />
              <Typography color={"error"} variant="body2">
                If you are unable to upload the same fonts you use on your
                Shopify website, we will default to using system fonts (SF Pro
                for iOS and Roboto for Android). Typography will be inconsistent
                between native and web components throughout the app.
              </Typography>
            </Stack>
          )}
        </Stack>
      }
    />
  );
};

export default TypographyCard;
