import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Container, Stack, Typography } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { InternBrandAdsHealthQuery } from "./__generated__/InternBrandAdsHealthQuery.graphql";
import Circle from "../../components/Circle";
import ExpandableSection from "../../components/ExpandableSection";
import nullthrows from "../../utils/nullthrows";

const INVALID_COLOR = "#d32f2f";
const VALID_COLOR = "#2e7d32";

const query = graphql`
  query InternBrandAdsHealthQuery($id: ID!) {
    brand(id: $id) {
      id
      internAdsSummary {
        type
        title
        description
        explanation
        valid
      }
    }
  }
`;

const InternBrandAdsHealth = ({ brandID }: { brandID: string }) => {
  const data = useLazyLoadQuery<InternBrandAdsHealthQuery>(query, {
    id: nullthrows(brandID),
  });
  const adList = data.brand.internAdsSummary;

  let cards = null;
  if (adList === null) {
    cards = (
      <Box
        sx={{
          padding: 3,
          m: 2,
          borderRadius: "8px",
          border: "1px solid #c7d0d4",
          backgroundColor: "white",
        }}
      >
        <Typography color={"primary"} variant="subtitle2">
          FB Account not setup
        </Typography>
      </Box>
    );
  } else {
    cards = adList.map((ad, index) => {
      const isValid = ad.valid;

      const statusComponent = !isValid ? (
        <Circle
          color={"rgb(253, 237, 237)"}
          height="26px"
          component={
            <CancelIcon
              sx={{
                color: INVALID_COLOR,
                fontSize: "16px",
              }}
            />
          }
        />
      ) : (
        <Circle
          color={"#DCFDD7"}
          height="26px"
          component={
            <CheckIcon
              sx={{
                color: VALID_COLOR,
                fontSize: "16px",
              }}
            />
          }
        />
      );

      return (
        <Box
          sx={{
            m: 1,
            backgroundColor: isValid ? "white" : "rgb(253, 237, 237)",
            borderRadius: 2,
            overflow: "hidden",
            zIndex: 10,
          }}
        >
          <ExpandableSection
            key={index}
            color="inherit"
            isExpandedByDefault={!isValid}
            title={
              <Stack direction="row" spacing={4} alignItems={"center"}>
                {statusComponent}
                <Typography variant="h6">{ad.title}</Typography>
              </Stack>
            }
          >
            <Box
              sx={{
                padding: 3,
                mx: 2,
                borderRadius: "8px",
                border: "1px solid",
                color: isValid ? VALID_COLOR : INVALID_COLOR,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: ad.explanation }} />
            </Box>
            <Box
              sx={{
                padding: 3,
                m: 2,
                borderRadius: "8px",
                border: "1px solid #c7d0d4",
                backgroundColor: "white",
              }}
            >
              <Typography color={"primary"} variant="subtitle1">
                Why is this important?
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: ad.description }} />
            </Box>
          </ExpandableSection>
        </Box>
      );
    });
  }

  return <Container maxWidth="md">{cards}</Container>;
};

export default InternBrandAdsHealth;
