import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CircleNotificationsOutlinedIcon from "@mui/icons-material/CircleNotificationsOutlined";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import RememberMeOutlinedIcon from "@mui/icons-material/RememberMeOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SsidChartOutlinedIcon from "@mui/icons-material/SsidChartOutlined";
import { Box, Button, Grid, Stack } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import {
  Suspense,
  createRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CSVLink } from "react-csv";
import { useLazyLoadQuery } from "react-relay";
import { InsightsTabQuery } from "./__generated__/InsightsTabQuery.graphql";
import InsightsCard from "../../../components/InsightsCard";
import StackedBarChart from "../../../components/analytics/StackedBarChart";
import DebugContext from "../../../contexts/DebugContext";
import formatNumber from "../../../utils/formatNumber";
import InsightsTabPlatformBreakdown from "./InsightsTabPlatformBreakdown";
import InsightsTabTimeSeries from "./InsightsTabTimeSeries";
import { useInsightsTabQueryDateRangeData } from "./hooks/useInsightsTabQueryDateRangeData";
import {
  KDateRange,
  formatDateRange,
  getPreviousDateRangeWindow,
  getTimeWindowOptionForDateRange,
} from "./types/KDateRange";

const query = graphql`
  query InsightsTabQuery(
    $brandId: ID!
    $timeWindow: TimeWindow!
    $skipMetricsAggregateWindow: Boolean = false
    $endDate: Date!
    $startDate: Date!
    $comparisonEndDate: Date!
    $comparisonStartDate: Date!
  ) {
    brand(id: $brandId) {
      ...InsightsTabTimeSeries_brand
      ...useInsightsTabPlatformBreakdownData_brand

      currencyCode
      displayName
      metricsAggregateWindow: metricsAggregate(
        filters: {
          date: { inList: [$endDate, $comparisonEndDate] }
          timeWindow: $timeWindow
          os: { isNull: true }
        }
      ) @skip(if: $skipMetricsAggregateWindow) {
        # skip if custom time window, to avoid complex calculations
        edges {
          node {
            id
            date
            appUsers
          }
        }
      }
      metricsAggregate1D: metricsAggregate(
        filters: {
          date: { lte: $endDate, gte: $startDate }
          timeWindow: ONE_DAY
          os: { isNull: true }
        }
      ) {
        edges {
          node {
            date
            appUsers
          }
        }
      }
      metricsDaily(
        filters: {
          date: { lte: $endDate, gte: $startDate }
          os: { isNull: true }
        }
      ) {
        edges {
          node {
            date
            appOrders
            appSales
            appSessions
            loggedInSessions
            pushEnabledSessions
            newAppUsers
            orders
            sales
          }
        }
      }
      comparisonMetrics: metricsDaily(
        filters: {
          date: { lte: $comparisonEndDate, gte: $comparisonStartDate }
          os: { isNull: true }
        }
      ) {
        edges {
          node {
            date
            appOrders
            appSales
            appSessions
            loggedInSessions
            pushEnabledSessions
            newAppUsers
            orders
            sales
          }
        }
      }
    }
  }
`;

type Props = {
  brandID: string;
  dateRange: KDateRange;
};

const InsightsTab = ({ brandID, dateRange: dateRangeWindow }: Props) => {
  const { debug } = useContext(DebugContext);
  const csvLink = createRef<any>();
  const comparisonDateRange = getPreviousDateRangeWindow(dateRangeWindow);
  const comparisonEndDate = comparisonDateRange.end.format("YYYY-MM-DD");
  const comparisonStartDate = comparisonDateRange.start.format("YYYY-MM-DD");
  const timeWindow = getTimeWindowOptionForDateRange(dateRangeWindow);
  const [csvData, setCSVData] = useState<(string | number)[][] | null>(null);

  const queryData = useLazyLoadQuery<InsightsTabQuery>(query, {
    brandId: brandID,
    timeWindow: timeWindow === "CUSTOM" ? "ONE_DAY" : timeWindow,
    skipMetricsAggregateWindow: timeWindow === "CUSTOM",
    endDate: dateRangeWindow.end.format("YYYY-MM-DD"),
    startDate: dateRangeWindow.start.format("YYYY-MM-DD"),
    comparisonEndDate: comparisonEndDate,
    comparisonStartDate: comparisonStartDate,
  });
  const brand = queryData.brand;
  const appUsersCurrent = brand.metricsAggregateWindow?.edges.filter(
    (edge) => edge.node.date === dateRangeWindow.end.format("YYYY-MM-DD")
  )?.[0]?.node?.appUsers;
  const appUsersCompare = brand.metricsAggregateWindow?.edges.filter(
    (edge) => edge.node.date === comparisonEndDate
  )?.[0]?.node?.appUsers;

  const dateRange = useMemo(() => {
    const dateRange: string[] = [];
    let dateCounter = dateRangeWindow.start;
    while (!dateCounter.isAfter(dateRangeWindow.end)) {
      dateRange.push(dateCounter.format("YYYY-MM-DD"));
      dateCounter = dateCounter.add(1, "day");
    }
    return dateRange;
  }, [dateRangeWindow]);

  const {
    daily: insightsDailyData,
    aggregates: {
      appSalesSum,
      salesSum,
      ordersSum,
      appOrdersSum,
      appSessionsSum,
      newAppUsersSum,
      loggedInSessionsSum,
      pushEnabledSessionsSum,
    },
    raw: { metricsDailyHashmap, platformBreakdownData },
  } = useInsightsTabQueryDateRangeData(brand, dateRange);

  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
  useEffect(() => {
    if (!isDownloadingCSV) {
      setCSVData(null);
      return;
    }
    const data = [
      [
        "Date",
        "App Orders",
        "Total Orders",
        "App Sales",
        "Total Sales",
        "iOS Downloads",
        "Android Downloads",
        "Total Downloads",
        "iOS Sessions",
        "Android Sessions",
        "Total Sessions",
        "Returning Users",
        "New Users",
        "Total Users",
      ],
      ...dateRange.map((date) => {
        const dayData = insightsDailyData[date];
        return [
          date,
          dayData.appOrders,
          dayData.totalOrders,
          dayData.appSales,
          dayData.totalSales,
          dayData.downloads.iOS,
          dayData.downloads.android,
          dayData.downloads.total,
          dayData.sessions.iOS,
          dayData.sessions.android,
          dayData.sessions.total,
          dayData.users.returning,
          dayData.users.new,
          dayData.users.total,
        ];
      }),
    ];

    setCSVData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadingCSV]);

  useEffect(() => {
    if (!csvData) {
      return;
    }
    csvLink.current?.link?.click();
    setIsDownloadingCSV(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvData]);

  const comparisonMetricsDaily = [...brand.comparisonMetrics.edges].reverse();
  const compareData = comparisonMetricsDaily.reduce(
    (acc, obj) => {
      return {
        appOrders: obj.node.appOrders + acc.appOrders,
        appSales: obj.node.appSales + acc.appSales,
        appSessions: obj.node.appSessions + acc.appSessions,
        loggedInSessions: obj.node.loggedInSessions + acc.loggedInSessions,
        newAppUsers: obj.node.newAppUsers + acc.newAppUsers,
        orders: obj.node.orders + acc.orders,
        pushEnabledSessions:
          obj.node.pushEnabledSessions + acc.pushEnabledSessions,
        sales: obj.node.sales + acc.sales,
      };
    },
    {
      appOrders: 0,
      appSales: 0,
      appSessions: 0,
      loggedInSessions: 0,
      newAppUsers: 0,
      orders: 0,
      pushEnabledSessions: 0,
      sales: 0,
    }
  );

  const usersData = dateRange.map((date) => {
    const dayData = insightsDailyData[date].users;
    return {
      name: dayjs(date).format("MMM D"),
      data1: dayData?.returning ?? 0,
      data2: dayData?.new ?? 0,
    };
  });

  let debugComponent = null;
  if (debug) {
    debugComponent = (
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          position: "fixed",
          p: 2,
          zIndex: 1000000,
          top: 64,
          right: 24,
          bgcolor: "rgb(20,184,166, 0.4)",
          fontSize: "8px",
        }}
      >
        <Box>
          <div>
            <b>Sums for Time Window</b>: {timeWindow}
          </div>
          <div>
            <b>Dates</b>: {dateRangeWindow.start.format("YYYY-MM-DD")} -{" "}
            {dateRangeWindow.end.format("YYYY-MM-DD")}
          </div>
          <div>Sales: {salesSum} </div>
          <div>App Sales: {appSalesSum} </div>
          <div>Orders: {ordersSum} </div>
          <div>App Orders: {appOrdersSum} </div>
          <div>App Sessions: {appSessionsSum} </div>
          <div>App Users: {appUsersCurrent} </div>
          <div>New App Users: {newAppUsersSum} </div>
          <div>Logged In Users: {loggedInSessionsSum} </div>
          <div>Push Enabled Users: {pushEnabledSessionsSum} </div>
        </Box>
        <Box>
          <div>
            <b>Comparison Sums Time Window</b>: {timeWindow}
          </div>
          <div>
            <b>Dates</b>: {comparisonStartDate} - {comparisonEndDate}
          </div>
          <div>Sales: {compareData.sales} </div>
          <div>App Sales: {compareData.appSales} </div>
          <div>Orders: {compareData.orders} </div>
          <div>App Orders: {compareData.appOrders} </div>
          <div>App Sessions: {compareData.appSessions} </div>
          <div>App Users: {appUsersCompare} </div>
          <div>New App Users: {compareData.newAppUsers} </div>
          <div>Logged In Users: {compareData.loggedInSessions} </div>
          <div>Push Enabled Users: {compareData.pushEnabledSessions} </div>
        </Box>
      </Stack>
    );
  }

  return (
    <>
      {debugComponent}
      <Button
        variant="text"
        disabled={isDownloadingCSV}
        onClick={() => setIsDownloadingCSV(true)}
        size="small"
        sx={{
          float: "right",
        }}
      >
        Download CSV
      </Button>
      <CSVLink
        data={csvData ?? [[]]}
        hidden
        filename={`${brand.displayName
          .replace(/[^a-z0-9]/gi, "_")
          .toLowerCase()}_insights_${formatDateRange(
          dateRangeWindow,
          "YYYYMMDD",
          "_"
        )}`}
        ref={csvLink}
      >
        Download CSV
      </CSVLink>
      <Grid container columns={12} spacing={2} mt={1}>
        <Grid item xs={12} sm={6} lg={3}>
          <InsightsCard
            Icon={ShoppingCartOutlinedIcon}
            title="App Sales"
            data={formatNumber(appSalesSum, "currency", brand.currencyCode)}
            change={(appSalesSum - compareData.appSales) / compareData.appSales}
            title2={"Percentage of Total"}
            data2={formatNumber(appSalesSum / salesSum, "percent")}
            change2={
              (appSalesSum / salesSum -
                compareData.appSales / compareData.sales) /
              (compareData.appSales / compareData.sales)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <InsightsCard
            Icon={Inventory2OutlinedIcon}
            title="App Orders"
            data={formatNumber(appOrdersSum)}
            change={
              (appOrdersSum - compareData.appOrders) / compareData.appOrders
            }
            title2={"Percentage of Total"}
            data2={formatNumber(appOrdersSum / ordersSum, "percent")}
            change2={
              (appOrdersSum / ordersSum -
                compareData.appOrders / compareData.orders) /
              (compareData.appOrders / compareData.orders)
            }
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <InsightsCard
            Icon={AttachMoneyOutlinedIcon}
            title="Average App Order"
            data={formatNumber(
              appSalesSum / appOrdersSum,
              "currency",
              brand.currencyCode
            )}
            change={
              (appSalesSum / appOrdersSum -
                compareData.appSales / compareData.appOrders) /
              (compareData.appSales / compareData.appOrders)
            }
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <InsightsCard
            Icon={SsidChartOutlinedIcon}
            title="Order Conversion Rate"
            data={formatNumber(appOrdersSum / appSessionsSum, "percent")}
            change={
              (appOrdersSum / appSessionsSum -
                compareData.appOrders / compareData.appSessions) /
              (compareData.appOrders / compareData.appSessions)
            }
          />
        </Grid>

        <Suspense fallback={null}>
          <InsightsTabTimeSeries
            brand={brand}
            dateRange={dateRange}
            data={metricsDailyHashmap}
          />
        </Suspense>

        {/* Sessions Summary Cards */}
        <Grid item xs={6} lg={12 / 5}>
          <InsightsCard
            Icon={PersonAddAltOutlinedIcon}
            title="New Users"
            data={formatNumber(newAppUsersSum)}
            change={
              (newAppUsersSum - compareData.newAppUsers) /
              compareData.newAppUsers
            }
          />
        </Grid>
        <Grid item xs={6} lg={12 / 5}>
          <InsightsCard
            Icon={PeopleAltOutlinedIcon}
            title="Unique Users"
            data={formatNumber(appUsersCurrent)}
            change={
              appUsersCurrent && appUsersCompare
                ? (appUsersCurrent - appUsersCompare) / appUsersCompare
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} lg={12 / 5}>
          <InsightsCard
            Icon={RememberMeOutlinedIcon}
            title="Sessions Per User"
            data={formatNumber(
              appUsersCurrent ? appSessionsSum / appUsersCurrent : undefined
            )}
            change={
              appUsersCurrent && appUsersCompare
                ? (appSessionsSum / appUsersCurrent -
                    compareData.appSessions / appUsersCompare) /
                  (compareData.appSessions / appUsersCompare)
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} lg={12 / 5}>
          <InsightsCard
            Icon={FaceOutlinedIcon}
            title="Login Rate"
            data={formatNumber(loggedInSessionsSum / appSessionsSum, "percent")}
            change={
              (loggedInSessionsSum / appSessionsSum -
                compareData.loggedInSessions / compareData.appSessions) /
              (compareData.loggedInSessions / compareData.appSessions)
            }
          />
        </Grid>
        <Grid item xs={6} lg={12 / 5}>
          <InsightsCard
            Icon={CircleNotificationsOutlinedIcon}
            title="Push Opt-In Rate"
            data={formatNumber(
              pushEnabledSessionsSum / appSessionsSum,
              "percent"
            )}
            change={
              (pushEnabledSessionsSum / appSessionsSum -
                compareData.pushEnabledSessions / compareData.appSessions) /
              (compareData.pushEnabledSessions / compareData.appSessions)
            }
          />
        </Grid>

        <InsightsTabPlatformBreakdown
          data={platformBreakdownData}
          dateRange={dateRange}
        />
        <Grid item xs={12} lg={6}>
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "8px",
              p: 2,
            }}
          >
            <StackedBarChart
              data={usersData}
              title={"Users"}
              line1Name="Returning Users"
              line2Name="New App Users"
              showAverageStats
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default InsightsTab;
