import { Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { Suspense, useContext, useEffect } from "react";
import { useLazyLoadQuery } from "react-relay";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { BuildContainerQuery } from "./__generated__/BuildContainerQuery.graphql";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import {
  PreviewType,
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../contexts/MobilePreviewContext";
import { SectionType, SidepaneConfig } from "../../../utils/constants";
import nullthrows from "../../../utils/nullthrows";
import MappingTab from "./MappingTab";
import NavigationTab from "./NavigationTab";
import ThemeTab from "./ThemeTab";

const query = graphql`
  query BuildContainerQuery {
    user {
      isInternal
      isStaff
    }
  }
`;

const config = SidepaneConfig.find(
  (config) => config.sectionType === SectionType.BUILD
);

const BuildContainer = () => {
  const dispatch = useMobilePreviewDispatch();

  const currentUserContext = useContext(CurrentUserContext);
  const data = useLazyLoadQuery<BuildContainerQuery>(query, {});
  const user = data.user;
  const activeBrandID = nullthrows(currentUserContext?.activeBrandID);

  const location = useLocation();
  const navigate = useNavigate();

  const tabs = config?.sections
    .filter((config) => config.isPublic || user?.isInternal === true)
    .map((config, index) => (
      <Tab
        value={"/" + SectionType.BUILD + config.pathname}
        label={config.title}
        key={index}
      />
    ));

  useEffect(() => {
    dispatch({
      type: ReducerAction.SET_PREVIEW_TYPE,
      payload: {
        previewType: PreviewType.NAVIGATION,
      },
    });
    return () =>
      dispatch({
        type: ReducerAction.RESET,
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Typography variant="h4">Build</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider", margin: "32px 0" }}>
        <Tabs
          value={location.pathname}
          onChange={(_, value) => {
            navigate(value);
          }}
        >
          {tabs}
        </Tabs>
      </Box>
      <Routes>
        {config?.sections
          .filter((config) => {
            return config.isPublic || user?.isInternal === true;
          })
          .map((config, index) => {
            let element = null;
            switch (config.pathname) {
              case "/navigation":
                element = (
                  <NavigationTab brandID={activeBrandID} key={activeBrandID} />
                );
                break;
              case "/theme":
                element = (
                  <ThemeTab brandID={activeBrandID} key={activeBrandID} />
                );
                break;
              case "/mapping":
                element = (
                  <MappingTab
                    brandID={activeBrandID}
                    key={activeBrandID}
                    isStaff={user?.isStaff ?? false}
                  />
                );
                break;
            }
            return (
              <Route
                path={config.pathname}
                element={
                  <Suspense
                    fallback={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    }
                  >
                    {element}
                  </Suspense>
                }
                key={index}
              />
            );
          })}
      </Routes>
    </Box>
  );
};

export default BuildContainer;
