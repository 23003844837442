/**
 * @generated SignedSource<<16924304317cd69d81296e4896502cac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CDPAudienceType = "LIST" | "SEGMENT" | "%future added value";
export type CDPType = "KINN" | "KLAVIYO" | "OMETRIA" | "SHOPIFY" | "%future added value";
export type DiscountType = "ORDER" | "PRODUCT" | "%future added value";
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
export type GraphQLDailyDealInputPartial = {
  audienceLanguages?: ReadonlyArray<KinnLanguageCode> | null;
  audiences?: ReadonlyArray<GraphQLCDPAudienceInput> | null;
  blurredHeadline?: string | null;
  blurredSubheadline?: string | null;
  discountCode?: string | null;
  discountType?: DiscountType | null;
  discountUrl?: string | null;
  endsAt?: any | null;
  headline?: string | null;
  id: string;
  imageUploadable?: any | null;
  isArchived?: boolean | null;
  isNotifEnabled?: boolean | null;
  notifSubtitle?: string | null;
  notifTimestamp?: any | null;
  notifTitle?: string | null;
  notifUtmCampaign?: string | null;
  startsAt?: any | null;
  subheadline?: string | null;
  title?: string | null;
};
export type GraphQLCDPAudienceInput = {
  audienceId: string;
  audienceName: string;
  audienceType: CDPAudienceType;
  cdpType: CDPType;
};
export type DailyDealsTableUpdateArchivedMutation$variables = {
  input: GraphQLDailyDealInputPartial;
};
export type DailyDealsTableUpdateArchivedMutation$data = {
  readonly updateDailyDeal: {
    readonly archivedAt: any | null;
  };
};
export type DailyDealsTableUpdateArchivedMutation = {
  response: DailyDealsTableUpdateArchivedMutation$data;
  variables: DailyDealsTableUpdateArchivedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archivedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DailyDealsTableUpdateArchivedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLDailyDeal",
        "kind": "LinkedField",
        "name": "updateDailyDeal",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DailyDealsTableUpdateArchivedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLDailyDeal",
        "kind": "LinkedField",
        "name": "updateDailyDeal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d03ba1429f24e0bfdcb8d614d10b7310",
    "id": null,
    "metadata": {},
    "name": "DailyDealsTableUpdateArchivedMutation",
    "operationKind": "mutation",
    "text": "mutation DailyDealsTableUpdateArchivedMutation(\n  $input: GraphQLDailyDealInputPartial!\n) {\n  updateDailyDeal(input: $input) {\n    archivedAt\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9cfabda0e00e2ba3a3ba7c1b7ffa37d0";

export default node;
