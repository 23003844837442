import { Stack, Switch, TextField, Typography } from "@mui/material";
import { FocusEvent, useEffect, useState } from "react";
import LeftRight from "./LeftRight";
import { UTMTextField } from "./UTMTextField";

export type AttachedNotificationDetails = {
  readonly body: string;
  readonly title: string;
  readonly utmCampaign: string | null;
};

export const isValidAttachedNotification = (
  notification: AttachedNotificationDetails | null
) => {
  if (notification == null) {
    return true;
  }

  return (
    notification.title.trim().length > 0 && notification.body.trim().length > 0
  );
};

type AttachedNotificationProps = {
  readonly notification: AttachedNotificationDetails | null;
  shouldShowUTM?: boolean;
  onNotificationChange: (
    notification: AttachedNotificationDetails | null
  ) => void;
};

export const AttachedNotification = ({
  notification,
  onNotificationChange,
  shouldShowUTM = true,
}: AttachedNotificationProps) => {
  const [isEnabled, setEnabled] = useState<boolean>(notification != null);
  const [title, setTitle] = useState<string>(notification?.title ?? "");
  const [body, setBody] = useState<string>(notification?.body ?? "");
  const [utmCampaign, setUtmCampaign] = useState<string | null>(
    notification?.utmCampaign ?? null
  );

  useEffect(() => {
    if (isEnabled) {
      onNotificationChange({
        title,
        body,
        utmCampaign,
      });
    } else {
      onNotificationChange(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled, title, body, utmCampaign]);

  return (
    <>
      <LeftRight
        expandLeft={true}
        left={
          <Stack spacing={1}>
            <Typography variant="subtitle1">
              Send notification upon publishing
            </Typography>
            <Typography variant="body2">
              Your target audience will receive a notification to view your post
              when you publish it to the app.
            </Typography>
          </Stack>
        }
        right={
          <Switch
            checked={isEnabled}
            onChange={(event) => setEnabled(event.target.checked)}
          />
        }
      />
      {isEnabled && (
        <>
          <TextField
            sx={{
              flexGrow: 1,
            }}
            margin="normal"
            label="Notification Title"
            variant="outlined"
            value={title}
            inputProps={{
              maxLength: 50,
            }}
            onChange={(event: FocusEvent<HTMLInputElement>) => {
              setTitle(event.target.value);
            }}
          />
          <TextField
            sx={{
              flexGrow: 1,
            }}
            margin="normal"
            multiline
            label="Notification Subtitle"
            variant="outlined"
            value={body}
            inputProps={{
              maxLength: 50,
            }}
            onChange={(event: FocusEvent<HTMLInputElement>) => {
              setBody(event.target.value);
            }}
          />
          {shouldShowUTM && (
            <UTMTextField
              label="UTM Campaign"
              value={utmCampaign ?? undefined}
              onChange={setUtmCampaign}
            />
          )}
        </>
      )}
    </>
  );
};
