import CheckIcon from "@mui/icons-material/Check";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";

const CopyButton = ({
  isDisabled = false,
  isIconButton = false,
  textToCopy,
  size,
}: {
  isDisabled?: boolean;
  isIconButton?: boolean;
  textToCopy: string;
  size?: "small" | "medium" | "large";
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const onClickCopy = () => {
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const text = isCopied ? "Copied" : "Copy";
  return isIconButton ? (
    <Tooltip title={text} placement="right" arrow>
      <IconButton disabled={isDisabled} onClick={onClickCopy} size={"small"}>
        {isCopied ? (
          <CheckIcon fontSize="inherit" />
        ) : (
          <CopyAllIcon fontSize="inherit" />
        )}
      </IconButton>
    </Tooltip>
  ) : (
    <Button
      disabled={isDisabled}
      startIcon={isCopied ? <CheckIcon /> : <CopyAllIcon />}
      onClick={onClickCopy}
      size={size}
    >
      {text}
    </Button>
  );
};

export default CopyButton;
