import CloseIcon from "@mui/icons-material/Close";
import {
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { Environment, useFragment, useRelayEnvironment } from "react-relay";
import { UploadableMap, commitLocalUpdate } from "relay-runtime";
import { ScreenshotsCard_brand$key } from "./__generated__/ScreenshotsCard_brand.graphql";
import CardSection from "../../../components/CardSection";
import SaveButton, { SavedState } from "../../../components/SaveButton";
import UploadedFileCard from "../../../components/UploadedFileCard";
import useBrandAppConfigUpdateMutation from "../../../mutations/useBrandAppConfigUpdateMutation";

const brandFragment = graphql`
  fragment ScreenshotsCard_brand on BrandType {
    appConfig {
      id
      screenshots {
        id
        image {
          url
        }
      }
    }
  }
`;

function removeScreenshotFromRelayStore(
  environment: Environment,
  appConfigID: string,
  screenshotID: string
) {
  return commitLocalUpdate(environment, (store) => {
    const config = store.get(appConfigID);
    if (config == null) {
      return;
    }

    const screenshots = config.getLinkedRecords("screenshots");
    if (screenshots == null) {
      return;
    }

    const screenshotsCopy = Array.from(screenshots);
    config.setLinkedRecords(
      screenshotsCopy.filter((s) => s.getDataID() !== screenshotID),
      "screenshots"
    );
  });
}

const ScreenshotsCard = ({
  brand: brandKey,
}: {
  brand: ScreenshotsCard_brand$key;
}) => {
  const env = useRelayEnvironment();
  const brand = useFragment(brandFragment, brandKey);
  const appConfig = brand.appConfig;
  const screenshots = appConfig.screenshots;

  const [mediaUrl, setMediaUrl] = useState<string | null>(null);
  const isUploadable = mediaUrl !== null;
  const [mediaUploadables, setMediaUploadables] = useState<UploadableMap>({});

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.DISABLED
  );

  const [saveMutation, isMutationInFlight] = useBrandAppConfigUpdateMutation(
    {
      id: appConfig.id,
      screenshots: {
        add: isUploadable
          ? [
              {
                image: mediaUrl,
              },
            ]
          : undefined,
        set: isUploadable
          ? undefined
          : screenshots.map((screenshot) => ({
              id: screenshot.id,
            })),
      },
    },
    () => {
      setMediaUploadables({});
      setMediaUrl(null);
      setSaveButtonState(SavedState.SAVED);
    },
    mediaUploadables
  );

  return (
    <CardSection
      actions={
        <SaveButton
          savedState={
            isMutationInFlight ? SavedState.DISABLED : saveButtonState
          }
          onClick={saveMutation}
        />
      }
      title={"App Screenshots"}
      content={
        <Stack spacing={2} width="100%">
          <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
            Uploaded
          </Typography>
          <ImageList cols={3}>
            {screenshots.map((screenshot, key) => (
              <ImageListItem
                key={key}
                sx={{
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
                component="div"
              >
                <img alt="" src={screenshot.image?.url} loading="lazy" />
                {!isUploadable && (
                  <ImageListItemBar
                    sx={{
                      background:
                        "linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)",
                    }}
                    actionIcon={
                      <IconButton
                        sx={{ color: "white" }}
                        onClick={() => {
                          removeScreenshotFromRelayStore(
                            env,
                            appConfig.id,
                            screenshot.id
                          );
                          saveButtonState !== SavedState.ENABLED &&
                            setSaveButtonState(SavedState.ENABLED);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                    position="top"
                  />
                )}
              </ImageListItem>
            ))}
          </ImageList>

          <Divider />

          <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
            Upload
          </Typography>
          <UploadedFileCard
            fileURL={mediaUrl}
            fileType={".png, .jpg"}
            htmlLabel={"screenshot-upload"}
            inputLabel={"Screenshots"}
            inputText={"Upload a .png or .jpg"}
            mediaType="PHOTO"
            onClose={() => {
              setMediaUrl(null);
              delete mediaUploadables["screenshots.add.0.image"];

              saveButtonState !== SavedState.ENABLED &&
                setSaveButtonState(SavedState.ENABLED);
            }}
            onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
              const file = event?.target?.files?.[0];
              if (file == null) {
                return;
              }

              const url = URL.createObjectURL(file);
              setMediaUrl(url);
              const newUploadables: UploadableMap = {};
              newUploadables["screenshots.add.0.image"] = file;
              setMediaUploadables(newUploadables);

              saveButtonState !== SavedState.ENABLED &&
                setSaveButtonState(SavedState.ENABLED);
            }}
          />
        </Stack>
      }
    />
  );
};

export default ScreenshotsCard;
