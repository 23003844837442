/**
 * @generated SignedSource<<bcffd76e5ea813bace370482e627c5f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
export type MediaType = "PHOTO" | "VIDEO" | "WEB_MUX_VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeedCarouselForm_feedPost$data = {
  readonly audienceLanguages: ReadonlyArray<KinnLanguageCode> | null;
  readonly audiences: ReadonlyArray<{
    readonly audienceId: string;
  }>;
  readonly carousel: {
    readonly id: string;
    readonly items: ReadonlyArray<{
      readonly id: string;
      readonly media: {
        readonly coverImageUrl: string | null;
        readonly type: MediaType;
        readonly url: string;
      } | null;
      readonly subtitle: string | null;
      readonly title: string;
      readonly url: string;
    }>;
    readonly subtitle: string | null;
    readonly title: string;
  } | null;
  readonly id: string;
  readonly notification: {
    readonly body: string;
    readonly title: string;
    readonly utmCampaign: string | null;
  } | null;
  readonly publishedAt: any;
  readonly " $fragmentType": "FeedCarouselForm_feedPost";
};
export type FeedCarouselForm_feedPost$key = {
  readonly " $data"?: FeedCarouselForm_feedPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedCarouselForm_feedPost">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedCarouselForm_feedPost",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLCDPAudience",
      "kind": "LinkedField",
      "name": "audiences",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "audienceId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "audienceLanguages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLNotification",
      "kind": "LinkedField",
      "name": "notification",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "utmCampaign",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLCarousel",
      "kind": "LinkedField",
      "name": "carousel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLCarouselItem",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "GraphQLMedia",
              "kind": "LinkedField",
              "name": "media",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "coverImageUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GraphQLFeedPost",
  "abstractKey": null
};
})();

(node as any).hash = "238421ef99b3f79010dcb62300f3518a";

export default node;
