const validateUrl = (url: string): boolean => {
  if (/\s/.test(url)) return false;

  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

export { validateUrl };
