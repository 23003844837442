import { Stack, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { UploadableMap } from "relay-runtime";
import * as yup from "yup";
import { MediaType } from "../engage/feed/__generated__/FeedSinglePostForm_feedPost.graphql";
import {
  AppColorPalette,
  OnboardingCard_brand$key,
} from "./__generated__/OnboardingCard_brand.graphql";
import CardSection from "../../../components/CardSection";
import ColorDropdownSelector, {
  InputType,
} from "../../../components/ColorDropdownSelector";
import SaveButton, { SavedState } from "../../../components/SaveButton";
import UploadedFileCard from "../../../components/UploadedFileCard";
import useBrandAppConfigUpdate2Mutation from "../../../mutations/useBrandAppConfigUpdate2Mutation";
import nullthrows from "../../../utils/nullthrows";

const schema = yup
  .object({
    rootTitle: yup.string().max(400),
    upsellTitle: yup.string().max(400),
    upsellSubtitle: yup.string().max(400),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const brandFragment = graphql`
  fragment OnboardingCard_brand on BrandType {
    appConfig {
      id
      buildTheme {
        id
        onboardingAssetUrl
        onboardingBackground
        onboardingForeground
      }
      appContent {
        id
        onboardingRootTitle
        onboardingUpsellTitle
        onboardingUpsellSubtitle
      }
    }
  }
`;

type Props = {
  brand: OnboardingCard_brand$key;
};

const OnboardingCard = ({ brand: brandKey }: Props) => {
  const brand = useFragment<OnboardingCard_brand$key>(brandFragment, brandKey);
  const appConfig = brand.appConfig;
  const buildTheme = appConfig.buildTheme;

  const [onboardingBackground, setOnboardingBackground] =
    useState<AppColorPalette | null>(buildTheme.onboardingBackground);
  const [onboardingForeground, setOnboardingForeground] =
    useState<AppColorPalette | null>(buildTheme.onboardingForeground);
  const [assetUrl, setAssetUrl] = useState<string | null>(
    buildTheme.onboardingAssetUrl
  );
  const [mediaType, setMediaType] = useState<MediaType | undefined>(undefined);
  const [mediaUploadable, setMediaUploadable] = useState<UploadableMap>({});

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.DISABLED
  );

  const [saveMutation, isMutationInFlight] = useBrandAppConfigUpdate2Mutation();

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      rootTitle: brand.appConfig?.appContent?.onboardingRootTitle ?? "" ?? "",
      upsellTitle:
        brand.appConfig?.appContent?.onboardingUpsellTitle ?? "" ?? "",
      upsellSubtitle:
        brand.appConfig?.appContent?.onboardingUpsellSubtitle ?? "" ?? "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      saveButtonState !== SavedState.ENABLED &&
        setSaveButtonState(SavedState.ENABLED);
    });
    return () => subscription.unsubscribe();
  }, [watch, saveButtonState]);

  const onSubmit = (data: FormData) => {
    saveMutation(
      {
        id: brand.appConfig.id,
        appContent: {
          id: nullthrows(brand.appConfig?.appContent?.id),
          onboardingRootTitle: data.rootTitle,
          onboardingUpsellTitle: data.upsellTitle,
          onboardingUpsellSubtitle: data.upsellSubtitle,
        },
        buildTheme: {
          id: brand.appConfig.buildTheme.id,
          onboardingBackground,
          onboardingForeground,
          onboardingAsset: assetUrl === null ? null : undefined,
        },
      },
      () => {
        setSaveButtonState(SavedState.SAVED);
      },
      mediaUploadable
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <CardSection
        actions={
          <SaveButton
            savedState={
              isMutationInFlight ? SavedState.DISABLED : saveButtonState
            }
            useSubmit={true}
          />
        }
        title={"Onboarding"}
        content={
          <Stack
            spacing={2}
            sx={{
              maxWidth: "650px",
            }}
          >
            <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
              Onboarding Colors
            </Typography>
            <ColorDropdownSelector
              defaultPalette={"WHITE"}
              palette={onboardingBackground}
              inputType={InputType.ONBOARDING_BACKGROUND}
              onChange={(color: AppColorPalette | null) => {
                setOnboardingBackground(color);
                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
            />
            <ColorDropdownSelector
              defaultPalette={"WHITE"}
              palette={onboardingForeground}
              inputType={InputType.ONBOARDING_FOREGROUND}
              onChange={(color: AppColorPalette | null) => {
                setOnboardingForeground(color);
                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
            />
            <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
              Onboarding Asset
            </Typography>
            <UploadedFileCard
              mediaType={mediaType}
              fileURL={assetUrl}
              fileType=".png, .jpg, .gif, .mp4"
              htmlLabel={"onboarding-asset-upload"}
              inputLabel={"Onboarding Asset"}
              inputText={"Upload a .png, .jpg, .gif or .mp4"}
              onClose={() => {
                setAssetUrl(null);
                delete mediaUploadable["buildTheme.onboardingAsset"];

                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
              onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
                const file = event?.target?.files?.[0];
                if (file == null) {
                  return;
                }

                const url = URL.createObjectURL(file);
                setAssetUrl(url);
                setMediaType(file.type.includes("video") ? "VIDEO" : "PHOTO");

                const newUploadables: UploadableMap = {};
                newUploadables["buildTheme.onboardingAsset"] = file;
                setMediaUploadable(newUploadables);

                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
            />
            <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
              Onboarding Text
            </Typography>
            <TextField
              {...register("rootTitle")}
              error={!!errors?.rootTitle}
              helperText={errors?.rootTitle?.message}
              sx={{
                flexGrow: 1,
              }}
              margin="normal"
              id="outlined-basic"
              label={"Root Title"}
              variant="outlined"
            />
            <TextField
              {...register("upsellTitle")}
              error={!!errors?.upsellTitle}
              helperText={errors?.upsellTitle?.message}
              sx={{
                flexGrow: 1,
              }}
              margin="normal"
              id="outlined-basic"
              label={"Upsell Title"}
              variant="outlined"
            />
            <TextField
              {...register("upsellSubtitle")}
              error={!!errors?.upsellSubtitle}
              helperText={errors?.upsellSubtitle?.message}
              sx={{
                flexGrow: 1,
              }}
              margin="normal"
              id="outlined-basic"
              label={"Upsell Subtitle"}
              variant="outlined"
            />
          </Stack>
        }
      />
    </form>
  );
};

export default OnboardingCard;
