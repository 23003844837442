/**
 * @generated SignedSource<<b428d20b5b6527f8caf54c4f3a8d9b94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DiscountType = "ORDER" | "PRODUCT" | "%future added value";
export type DailyDealFormShopifyDiscountCodeQuery$variables = {
  brandId: string;
  discountCode: string;
};
export type DailyDealFormShopifyDiscountCodeQuery$data = {
  readonly shopifyDiscountCode: {
    readonly audiences: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null;
    readonly code: string;
    readonly endsAt: any | null;
    readonly shareableUrl: string;
    readonly startsAt: any;
    readonly summary: string;
    readonly title: string;
    readonly type: DiscountType;
  };
};
export type DailyDealFormShopifyDiscountCodeQuery = {
  response: DailyDealFormShopifyDiscountCodeQuery$data;
  variables: DailyDealFormShopifyDiscountCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "discountCode"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "brandId",
        "variableName": "brandId"
      },
      {
        "kind": "Variable",
        "name": "discountCode",
        "variableName": "discountCode"
      }
    ],
    "concreteType": "GraphQLShopifyDiscountCode",
    "kind": "LinkedField",
    "name": "shopifyDiscountCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GraphQLCDPAudienceType",
        "kind": "LinkedField",
        "name": "audiences",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endsAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shareableUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startsAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "summary",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DailyDealFormShopifyDiscountCodeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DailyDealFormShopifyDiscountCodeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7215ec829f6fc0206006f33694435659",
    "id": null,
    "metadata": {},
    "name": "DailyDealFormShopifyDiscountCodeQuery",
    "operationKind": "query",
    "text": "query DailyDealFormShopifyDiscountCodeQuery(\n  $brandId: ID!\n  $discountCode: String!\n) {\n  shopifyDiscountCode(brandId: $brandId, discountCode: $discountCode) {\n    audiences {\n      id\n      name\n    }\n    code\n    endsAt\n    shareableUrl\n    startsAt\n    summary\n    title\n    type\n  }\n}\n"
  }
};
})();

(node as any).hash = "cbcc5f035ccf2efb57de65e22da273b3";

export default node;
