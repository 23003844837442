import { Stack } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { useFragment } from "react-relay";
import { UploadableMap } from "relay-runtime";
import { LogoCard_brand$key } from "./__generated__/LogoCard_brand.graphql";
import CardSection from "../../../components/CardSection";
import SaveButton, { SavedState } from "../../../components/SaveButton";
import UploadedFileCard from "../../../components/UploadedFileCard";
import {
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../contexts/MobilePreviewContext";
import useBrandAppConfigUpdateMutation from "../../../mutations/useBrandAppConfigUpdateMutation";

const brandFragment = graphql`
  fragment LogoCard_brand on BrandType {
    appConfig {
      id
      buildTheme {
        id
        logoUrl
      }
    }
  }
`;

type Props = {
  brand: LogoCard_brand$key;
};

const LogoCard = ({ brand: brandKey }: Props) => {
  const dispatch = useMobilePreviewDispatch();

  const brand = useFragment<LogoCard_brand$key>(brandFragment, brandKey);
  const buildTheme = brand?.appConfig?.buildTheme;

  const [logoUploadables, setLogoUploadables] = useState<UploadableMap>({});
  const [logoURL, setLogoURL] = useState<string | null>(buildTheme?.logoUrl);
  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    logoURL !== null ? SavedState.SAVED : SavedState.DISABLED
  );

  const [saveMutation, isMutationInFlight] = useBrandAppConfigUpdateMutation(
    {
      id: brand.appConfig.id,
      buildTheme: {
        id: brand.appConfig.buildTheme.id,
      },
    },
    () => {
      setSaveButtonState(SavedState.SAVED);
      setLogoUploadables({});
    },
    Object.keys(logoUploadables).length === 0 ? undefined : logoUploadables
  );

  return (
    <CardSection
      actions={
        <SaveButton
          savedState={
            isMutationInFlight || logoURL === null
              ? SavedState.DISABLED
              : saveButtonState
          }
          onClick={saveMutation}
        />
      }
      title={"Brand Logo"}
      content={
        <Stack
          spacing={2}
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            maxWidth: "650px",
          }}
        >
          <UploadedFileCard
            fileType={".svg, .png, .jpg"}
            mediaType="PHOTO"
            fileURL={logoURL}
            htmlLabel={"brand-logo-upload"}
            inputLabel={"Brand Logo"}
            inputText={"Upload a logo"}
            onClose={() => {
              setLogoURL(null);
              delete logoUploadables["buildTheme.logo"];
            }}
            onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
              const file = event?.target?.files?.[0];
              if (file == null) {
                return;
              }

              const fileUrl = URL.createObjectURL(file);
              dispatch({
                type: ReducerAction.UPDATE_THEME,
                payload: {
                  theme: {
                    logoUrl: fileUrl,
                  },
                },
              });
              setLogoURL(fileUrl);
              const newUploadables: UploadableMap = {};
              newUploadables["buildTheme.logo"] = file;
              setLogoUploadables(newUploadables);
              saveButtonState !== SavedState.ENABLED &&
                setSaveButtonState(SavedState.ENABLED);
            }}
          />
        </Stack>
      }
    />
  );
};

export default LogoCard;
