/**
 * @generated SignedSource<<79db5c8a357085ec6226cc051a415254>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardContainerQuery$variables = {};
export type DashboardContainerQuery$data = {
  readonly user: {
    readonly activeBrand: {
      readonly id: string;
    } | null;
    readonly brandAdminProfiles: ReadonlyArray<{
      readonly brand: {
        readonly id: string;
      };
    }>;
    readonly email: string;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"DashboardContainer_user">;
  } | null;
};
export type DashboardContainerQuery = {
  response: DashboardContainerQuery$data;
  variables: DashboardContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BrandType",
  "kind": "LinkedField",
  "name": "activeBrand",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isBlocking",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLBrandAdminProfile",
            "kind": "LinkedField",
            "name": "brandAdminProfiles",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandType",
                "kind": "LinkedField",
                "name": "brand",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DashboardContainer_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLBrandAdminProfile",
            "kind": "LinkedField",
            "name": "brandAdminProfiles",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandType",
                "kind": "LinkedField",
                "name": "brand",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "showIosTosAlert",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphQLBillingConfig",
                    "kind": "LinkedField",
                    "name": "billingConfig",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "planStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GraphQLPendingBillingConfig",
                        "kind": "LinkedField",
                        "name": "pendingBillingConfig",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "interval",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "takeRate",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GraphQLOneTimePurchaseCharge",
                        "kind": "LinkedField",
                        "name": "oneTimePurchaseCharges",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          (v4/*: any*/),
                          (v7/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "finishedOnboarding",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrandAppConfigType",
                    "kind": "LinkedField",
                    "name": "appConfig",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "appState",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iosAscTeamId",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isForumEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FeatureFlagsType",
                    "kind": "LinkedField",
                    "name": "featureFlags",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dailyDealEnabled",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isStaff",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isInternal",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8302696334c74bdf324a213ba6dd4416",
    "id": null,
    "metadata": {},
    "name": "DashboardContainerQuery",
    "operationKind": "query",
    "text": "query DashboardContainerQuery {\n  user {\n    id\n    email\n    activeBrand {\n      id\n    }\n    brandAdminProfiles {\n      brand {\n        id\n      }\n      id\n    }\n    ...DashboardContainer_user\n  }\n}\n\nfragment CurrentUser_userFragment on UserType {\n  id\n  email\n  firstName\n  lastName\n  brandAdminProfiles {\n    id\n    brand {\n      id\n      displayName\n    }\n  }\n}\n\nfragment DashboardContainer_user on UserType {\n  ...TopNavBar_user\n  id\n  isStaff\n  brandAdminProfiles {\n    brand {\n      ...useBrandUpdateMutation_brand\n      id\n      showIosTosAlert\n      billingConfig {\n        id\n        planStatus\n        pendingBillingConfig {\n          isBlocking\n          price\n          interval\n          takeRate\n          currency\n          status\n          id\n        }\n        oneTimePurchaseCharges {\n          id\n          isBlocking\n          status\n          currency\n          name\n          price\n        }\n      }\n      displayName\n      finishedOnboarding\n      appConfig {\n        appState\n        iosAscTeamId\n        id\n      }\n      ...Sidepane_brandFragment\n    }\n    id\n  }\n  ...Sidepane_userFragment\n}\n\nfragment Sidepane_brandFragment on BrandType {\n  isForumEnabled\n  featureFlags {\n    dailyDealEnabled\n  }\n}\n\nfragment Sidepane_userFragment on UserType {\n  isInternal\n  isStaff\n}\n\nfragment TopNavBar_user on UserType {\n  ...CurrentUser_userFragment\n}\n\nfragment useBrandUpdateMutation_brand on BrandType {\n  id\n}\n"
  }
};
})();

(node as any).hash = "5c997ce49b0b8dbbcf772e09043fc822";

export default node;
