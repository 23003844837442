/**
 * @generated SignedSource<<7d1dfa3a491be4d1e5e75995cabb3170>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BrandAdminInviteDialogMutation$variables = {
  brandId: string;
  email: string;
};
export type BrandAdminInviteDialogMutation$data = {
  readonly inviteBrandAdmin: {
    readonly " $fragmentSpreads": FragmentRefs<"BrandAdminTableRow_brandAdminProfile">;
  };
};
export type BrandAdminInviteDialogMutation = {
  response: BrandAdminInviteDialogMutation$data;
  variables: BrandAdminInviteDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brandId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v2 = [
  {
    "kind": "Variable",
    "name": "brandId",
    "variableName": "brandId"
  },
  {
    "kind": "Variable",
    "name": "email",
    "variableName": "email"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BrandAdminInviteDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "GraphQLBrandAdminProfile",
        "kind": "LinkedField",
        "name": "inviteBrandAdmin",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BrandAdminTableRow_brandAdminProfile"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BrandAdminInviteDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "GraphQLBrandAdminProfile",
        "kind": "LinkedField",
        "name": "inviteBrandAdmin",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOnboarded",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "41f46f88e89c98d4196af9334f1fb797",
    "id": null,
    "metadata": {},
    "name": "BrandAdminInviteDialogMutation",
    "operationKind": "mutation",
    "text": "mutation BrandAdminInviteDialogMutation(\n  $email: String!\n  $brandId: ID!\n) {\n  inviteBrandAdmin(brandId: $brandId, email: $email) {\n    ...BrandAdminTableRow_brandAdminProfile\n    id\n  }\n}\n\nfragment BrandAdminTableRow_brandAdminProfile on GraphQLBrandAdminProfile {\n  id\n  isOnboarded\n  user {\n    id\n    email\n    firstName\n    lastName\n  }\n}\n"
  }
};
})();

(node as any).hash = "e2856099231b44ebac7efba96ae2e9f4";

export default node;
