import { Button } from "@mui/material";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FACEBOOK_APP_ID } from "../../utils/constants";

const CALLBACK_STATE = "kt_facebook_login";
const FACEBOOK_REDIRECT_URL_PATHS =
  process.env.REACT_APP_FACEBOOK_REDIRECT_URL_PATHS?.split(";") || [
    "/settings/meta",
  ];
/** Get a whitelisted redirect Url for the facebook login, matching current path if possible */
function getFacebookRedirectUrl() {
  const urlPath = window.location.pathname;
  const whitelistedPath =
    FACEBOOK_REDIRECT_URL_PATHS.find((path) => urlPath.startsWith(path)) ??
    FACEBOOK_REDIRECT_URL_PATHS[0];
  return `${window.location.origin}${whitelistedPath}`;
}

// TODO: maybe do this with code instead, and check it on the BE
export type FacebookSuccessResponse = {
  /** An access token for the person using the webpage. */
  accessToken: string;
};

export type FacebookFailResponse = {
  error: string;
  errorReason: string | null;
  errorDescription: string | null;
};

export type FacebookLoginProps = {
  onSuccess?: (response: FacebookSuccessResponse) => void;
  onFail?: (error: FacebookFailResponse) => void;
  isLoading?: boolean;
};

const FacebookLogin = ({
  onSuccess,
  onFail,
  isLoading,
}: FacebookLoginProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const didHandleCallback = useRef(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (didHandleCallback.current) {
      // avoid calling callbacks multiple times
      return;
    }
    didHandleCallback.current = true;

    const searchParams = new URLSearchParams(location.search);
    const state = searchParams.get("state");
    if (state === CALLBACK_STATE && searchParams.has("error")) {
      onFail?.({
        error: searchParams.get("error")!,
        errorReason: searchParams.get("error_reason"),
        errorDescription: searchParams.get("error_description"),
      });
      return;
    }

    if (!!location.hash.length) {
      const search = new URLSearchParams(location.hash.substring(1));
      const state = search.get("state");
      const accessToken = search.get("access_token");
      if (state === CALLBACK_STATE && accessToken) {
        navigate("#");
        onSuccess?.({ accessToken });
      }
    }
  }, [didHandleCallback]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFacebookLogin = () => {
    const oauthUrl =
      `https://www.facebook.com/v19.0/dialog/oauth?client_id=${FACEBOOK_APP_ID}` +
      `&redirect_uri=${getFacebookRedirectUrl()}` +
      `&auth_type=rerequest` +
      `&scope=instagram_basic,pages_show_list,pages_read_engagement,business_management,instagram_manage_insights` +
      `&state=${CALLBACK_STATE}` +
      `&response_type=token`;
    window.location.href = oauthUrl;
  };

  return (
    <Button
      sx={{ margin: "16px", width: "fit-content" }}
      variant="contained"
      size="medium"
      disabled={isLoading}
      onClick={handleFacebookLogin}
    >
      Connect Facebook Account
    </Button>
  );
};

export default FacebookLogin;
