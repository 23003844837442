/**
 * @generated SignedSource<<a2bead42fb854f1c8d6f32c066610d5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeedPollForm_feedPost$data = {
  readonly audienceLanguages: ReadonlyArray<KinnLanguageCode> | null;
  readonly audiences: ReadonlyArray<{
    readonly audienceId: string;
  }>;
  readonly id: string;
  readonly notification: {
    readonly body: string;
    readonly title: string;
    readonly utmCampaign: string | null;
  } | null;
  readonly poll: {
    readonly customResponsesEnabled: boolean;
    readonly id: string;
    readonly media: {
      readonly url: string;
    } | null;
    readonly pollOptions: ReadonlyArray<{
      readonly id: string;
      readonly isCustomerCreatedOption: boolean;
      readonly isStatic: boolean;
      readonly media: {
        readonly url: string;
      } | null;
      readonly title: string;
    }>;
    readonly title: string;
  } | null;
  readonly publishedAt: any;
  readonly " $fragmentType": "FeedPollForm_feedPost";
};
export type FeedPollForm_feedPost$key = {
  readonly " $data"?: FeedPollForm_feedPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedPollForm_feedPost">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "GraphQLMedia",
  "kind": "LinkedField",
  "name": "media",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedPollForm_feedPost",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLCDPAudience",
      "kind": "LinkedField",
      "name": "audiences",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "audienceId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "audienceLanguages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLNotification",
      "kind": "LinkedField",
      "name": "notification",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "utmCampaign",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLPoll",
      "kind": "LinkedField",
      "name": "poll",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customResponsesEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLPollOption",
          "kind": "LinkedField",
          "name": "pollOptions",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCustomerCreatedOption",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isStatic",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GraphQLFeedPost",
  "abstractKey": null
};
})();

(node as any).hash = "50f27c42767e1561b2494daf10b01ec5";

export default node;
