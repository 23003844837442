/**
 * @generated SignedSource<<c212de6a4d6248826c26de7290c2acb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ForumTab_brand$data = {
  readonly discourseBaseUrl: string | null;
  readonly " $fragmentType": "ForumTab_brand";
};
export type ForumTab_brand$key = {
  readonly " $data"?: ForumTab_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"ForumTab_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ForumTab_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discourseBaseUrl",
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "364b2ea8c32038ed218c70a2b4ca0891";

export default node;
