/**
 * @generated SignedSource<<b24b47d9a28ba2ecd1c961a40f5d2a57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AdRuleType = "OPTIMIZATION_STRATEGTY" | "TOO_MANY_ADS_IN_AD_SET" | "%future added value";
export type InternBrandAdsHealthQuery$variables = {
  id: string;
};
export type InternBrandAdsHealthQuery$data = {
  readonly brand: {
    readonly id: string;
    readonly internAdsSummary: ReadonlyArray<{
      readonly description: string;
      readonly explanation: string;
      readonly title: string;
      readonly type: AdRuleType;
      readonly valid: boolean;
    }> | null;
  };
};
export type InternBrandAdsHealthQuery = {
  response: InternBrandAdsHealthQuery$data;
  variables: InternBrandAdsHealthQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "BrandType",
    "kind": "LinkedField",
    "name": "brand",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InternAdSummary",
        "kind": "LinkedField",
        "name": "internAdsSummary",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "explanation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "valid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InternBrandAdsHealthQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InternBrandAdsHealthQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4729ee882d8c510473a4b6434acf2f08",
    "id": null,
    "metadata": {},
    "name": "InternBrandAdsHealthQuery",
    "operationKind": "query",
    "text": "query InternBrandAdsHealthQuery(\n  $id: ID!\n) {\n  brand(id: $id) {\n    id\n    internAdsSummary {\n      type\n      title\n      description\n      explanation\n      valid\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7186a340a430ff4a2b7299fa84434b76";

export default node;
