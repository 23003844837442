import dayjs, { Dayjs } from "dayjs";
import { TimeWindow } from "../__generated__/InsightsTabQuery.graphql";

export type TimeWindowOption =
  | Exclude<TimeWindow, "%future added value">
  | "CUSTOM";

export type KDateRange = {
  start: Dayjs;
  end: Dayjs;
};

export function getDaysInDateRange(dateRange: KDateRange): number {
  return dateRange.end.diff(dateRange.start, "day") + 1;
}

export function getTimeWindowOptionForDateRange(
  dateRange: KDateRange
): TimeWindowOption {
  const days = getDaysInDateRange(dateRange);
  if (days === 1) {
    return "ONE_DAY";
  } else if (days === 7) {
    return "SEVEN_DAYS";
  } else if (days === 30) {
    return "THIRTY_DAYS";
  } else {
    return "CUSTOM";
  }
}

export function dateRangeFromTimeWindowOption(
  timeWindow: TimeWindow,
  endDate: Dayjs
): KDateRange {
  return {
    start:
      timeWindow === "ONE_DAY"
        ? dayjs(endDate)
        : endDate.subtract(timeWindow === "SEVEN_DAYS" ? 6 : 29, "days"),
    end: dayjs(endDate),
  };
}

/// Returns the previous date range interval prior to the passed in date range (e.g. previous week, previous month)
export function getPreviousDateRangeWindow(dateRange: KDateRange): KDateRange {
  const daysInDateRange = getDaysInDateRange(dateRange);
  const endDate = dateRange.start.subtract(1, "day");
  const startDate = dateRange.start.subtract(daysInDateRange, "day");
  return {
    start: startDate,
    end: endDate,
  };
}

export function formatDateRange(
  dateRange: KDateRange,
  dateFormat: string = "MMM D, YYYY",
  separator: string = " - "
): string {
  const dateStart = dateRange.start.format(dateFormat);
  if (dateRange.start.isSame(dateRange.end, "day")) {
    return dateStart;
  }
  return `${dateStart}${separator}${dateRange.end.format(dateFormat)}`;
}
