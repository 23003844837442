/**
 * @generated SignedSource<<b97cb536359ca9b6e70889c9661e1fc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IntegrationsTab_brand$data = {
  readonly appsflyerDevKey: string | null;
  readonly facebookAppId: string | null;
  readonly facebookClientToken: string | null;
  readonly gorgiasId: string | null;
  readonly kinnApiToken: string;
  readonly klaviyoApiToken: string | null;
  readonly kustomerApiToken: string | null;
  readonly ometriaApiToken: string | null;
  readonly rechargeApiToken: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"CookiesCard_brand" | "DiscourseCard_brand" | "SlackCard_brand" | "useBrandUpdateMutation_brand">;
  readonly " $fragmentType": "IntegrationsTab_brand";
};
export type IntegrationsTab_brand$key = {
  readonly " $data"?: IntegrationsTab_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"IntegrationsTab_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IntegrationsTab_brand",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useBrandUpdateMutation_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CookiesCard_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DiscourseCard_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SlackCard_brand"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "facebookAppId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "facebookClientToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gorgiasId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "klaviyoApiToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kinnApiToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kustomerApiToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ometriaApiToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rechargeApiToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appsflyerDevKey",
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "d6e94073ad51d1bf99016ce1663549ca";

export default node;
