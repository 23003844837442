/**
 * @generated SignedSource<<a4cb75d3560e5d1f447f30246ef272f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MenuCard_brand$data = {
  readonly appConfig: {
    readonly id: string;
    readonly nestedMenuItems: ReadonlyArray<{
      readonly children: ReadonlyArray<{
        readonly children: ReadonlyArray<{
          readonly destinationUrl: string | null;
          readonly index: number;
          readonly title: string;
        }>;
        readonly destinationUrl: string | null;
        readonly index: number;
        readonly title: string;
      }>;
      readonly destinationUrl: string | null;
      readonly index: number;
      readonly title: string;
    }>;
    readonly shouldLockMenus: boolean;
  };
  readonly id: string;
  readonly " $fragmentType": "MenuCard_brand";
};
export type MenuCard_brand$key = {
  readonly " $data"?: MenuCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"MenuCard_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destinationUrl",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MenuCard_brand",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shouldLockMenus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NestedMenuItemType",
          "kind": "LinkedField",
          "name": "nestedMenuItems",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "NestedMenuItemType",
              "kind": "LinkedField",
              "name": "children",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NestedMenuItemType",
                  "kind": "LinkedField",
                  "name": "children",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "03eeeac031f552b6991d890dbc41743e";

export default node;
