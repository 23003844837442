/**
 * @generated SignedSource<<204e8aaee7a0c3ba00788ca7ed879455>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CDPAudienceType = "LIST" | "SEGMENT" | "%future added value";
export type CDPType = "KINN" | "KLAVIYO" | "OMETRIA" | "SHOPIFY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CDPAudienceSelector_brand$data = {
  readonly cdps: ReadonlyArray<{
    readonly audiences: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly type: CDPAudienceType;
    }>;
    readonly cdpType: CDPType;
  }>;
  readonly " $fragmentType": "CDPAudienceSelector_brand";
};
export type CDPAudienceSelector_brand$key = {
  readonly " $data"?: CDPAudienceSelector_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"CDPAudienceSelector_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CDPAudienceSelector_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLCDP",
      "kind": "LinkedField",
      "name": "cdps",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cdpType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLCDPAudienceType",
          "kind": "LinkedField",
          "name": "audiences",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "840ed7baa2358900f7605cba20b54405";

export default node;
