/**
 * @generated SignedSource<<c958e6ad825e76434fb209ef9921a94c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DiscountType = "ORDER" | "PRODUCT" | "%future added value";
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DailyDealTableRow_dailyDeal$data = {
  readonly archivedAt: any | null;
  readonly audienceLanguages: ReadonlyArray<KinnLanguageCode> | null;
  readonly audiences: ReadonlyArray<{
    readonly audienceName: string;
  }>;
  readonly blurredHeadline: string;
  readonly blurredSubheadline: string;
  readonly date: any;
  readonly discountCode: string;
  readonly discountType: DiscountType;
  readonly discountUrl: string;
  readonly endsAt: any;
  readonly headline: string;
  readonly id: string;
  readonly imageUrl: string | null;
  readonly startsAt: any;
  readonly subheadline: string;
  readonly " $fragmentType": "DailyDealTableRow_dailyDeal";
};
export type DailyDealTableRow_dailyDeal$key = {
  readonly " $data"?: DailyDealTableRow_dailyDeal$data;
  readonly " $fragmentSpreads": FragmentRefs<"DailyDealTableRow_dailyDeal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DailyDealTableRow_dailyDeal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archivedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "audienceLanguages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discountType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discountCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discountUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blurredHeadline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blurredSubheadline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subheadline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLCDPAudience",
      "kind": "LinkedField",
      "name": "audiences",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "audienceName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GraphQLDailyDeal",
  "abstractKey": null
};

(node as any).hash = "6073fbaaddb76650ed44c5e978047a0e";

export default node;
