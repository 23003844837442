import { Container, Stack, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import { FocusEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import * as yup from "yup";
import { OrganizationTab_brand$key } from "./__generated__/OrganizationTab_brand.graphql";
import CardSection from "../../../components/CardSection";
import SaveButton, { SavedState } from "../../../components/SaveButton";
import useBrandUpdateMutation from "../../../mutations/useBrandUpdateMutation";

const schema = yup
  .object({
    random: yup // NOTE: using the name 'email' causes password manager to fill in the field
      .string()
      .required("E-mail Required")
      .email("E-mail invalid")
      .max(200),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const brandFragment = graphql`
  fragment OrganizationTab_brand on BrandType {
    ...useBrandUpdateMutation_brand
    email
  }
`;

type Props = {
  brand: OrganizationTab_brand$key;
};

const OrganizationTab = ({ brand: brandKey }: Props) => {
  const brand = useFragment<OrganizationTab_brand$key>(brandFragment, brandKey);
  const email = brand.email ?? "";
  const isUnset = email === "";

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    isUnset ? SavedState.DISABLED : SavedState.SAVED
  );

  const [updateBrand, isBrandMutationInFlight] = useBrandUpdateMutation(brand);

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>({
    defaultValues: {
      random: email,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: FormData) => {
    updateBrand(
      {
        email: data.random,
      },
      (_) => {
        setSaveButtonState(SavedState.SAVED);
      }
    );
  };

  const card = (
    <CardSection
      actions={
        <SaveButton
          savedState={
            isBrandMutationInFlight ? SavedState.DISABLED : saveButtonState
          }
          useSubmit={true}
        />
      }
      title={"Settings"}
      content={
        <Stack width={"100%"}>
          <TextField
            {...register("random", {
              onChange: (event: FocusEvent<HTMLInputElement>) =>
                saveButtonState !== SavedState.ENABLED &&
                setSaveButtonState(SavedState.ENABLED),
            })}
            label="Contact E-mail"
            error={!!errors?.random}
            helperText={errors?.random?.message}
            variant="outlined"
          />
        </Stack>
      }
    />
  );

  return (
    <Container maxWidth="md">
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        {card}
      </form>
    </Container>
  );
};

export default OrganizationTab;
