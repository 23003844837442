import { Typography } from "@mui/material";
import formatNumber from "../../../../utils/formatNumber";

const FeedTabLinkDetails = ({
  taps,
  impressions,
}: {
  taps: number;
  impressions: number;
}) => {
  const conversionRate = taps / impressions;
  return (
    <Typography variant="body2" whiteSpace="nowrap">
      {`${impressions} impression${
        impressions !== 1 ? "s" : ""
      } | ${taps} click${taps !== 1 ? "s" : ""} (${formatNumber(
        conversionRate,
        "percent"
      )})`}
    </Typography>
  );
};

export default FeedTabLinkDetails;
