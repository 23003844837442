import { Box, Grid } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useFragment } from "react-relay";
import { InsightsTabTimeSeries_brand$key } from "./__generated__/InsightsTabTimeSeries_brand.graphql";
import LineChart from "../../../components/analytics/LineChart";

const brandFragment = graphql`
  fragment InsightsTabTimeSeries_brand on BrandType {
    currencyCode
  }
`;

const InsightsTabTimeSeries = ({
  brand: brandKey,
  dateRange,
  data,
}: {
  brand: InsightsTabTimeSeries_brand$key;
  dateRange: string[];
  data: {
    [key: string]: {
      appOrders: number;
      orders: number;
      appSales: number;
      sales: number;
    };
  };
}) => {
  const brand = useFragment(brandFragment, brandKey);

  // Map by date range to ensure any missing data will still be displayed
  const ordersData = dateRange.map((date) => {
    const node = data[date];
    if (!node) {
      return {
        name: dayjs(date).format("MMM D"),
        line1DataKey: 0,
        line2DataKey: 0,
      };
    }
    return {
      name: dayjs(date).format("MMM D"),
      line1DataKey: node.appOrders,
      line2DataKey: node.orders,
    };
  });
  const salesData = dateRange.map((date) => {
    const node = data[date];
    if (!node) {
      return {
        name: dayjs(date).format("MMM D"),
        line1DataKey: 0,
        line2DataKey: 0,
      };
    }
    return {
      name: dayjs(date).format("MMM D"),
      line1DataKey: node.appSales,
      line2DataKey: node.sales,
    };
  });

  return (
    <>
      {/*  Daily Orders */}
      <Grid item xs={12} lg={6}>
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            p: 2,
          }}
        >
          <LineChart
            data={ordersData}
            line1Name={"App Orders"}
            line2Name={"All Orders"}
            title="Orders"
            showAverage
          />
        </Box>
      </Grid>
      {/*  Daily Sales */}
      <Grid item xs={12} lg={6}>
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            p: 2,
          }}
        >
          <LineChart
            data={salesData}
            line1Name="App Sales"
            line2Name="All Sales"
            title="Sales"
            yAxisUnit="currency"
            currencyCode={brand.currencyCode}
            showAverage
          />
        </Box>
      </Grid>
    </>
  );
};

export default InsightsTabTimeSeries;
