/**
 * @generated SignedSource<<84ac4d9e2aff531b561e866b01ac3c6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MediaType = "PHOTO" | "VIDEO" | "WEB_MUX_VIDEO" | "%future added value";
export type HighlightManageFormQuery$variables = {
  id: string;
  skip: boolean;
};
export type HighlightManageFormQuery$data = {
  readonly highlight?: {
    readonly caption: string | null;
    readonly destinationUrl: string | null;
    readonly id: string;
    readonly media: {
      readonly coverImageUrl: string | null;
      readonly isProcessing: boolean;
      readonly type: MediaType;
      readonly url: string;
    } | null;
    readonly notifLandingPath: string;
    readonly notification: {
      readonly body: string;
      readonly title: string;
      readonly utmCampaign: string | null;
    } | null;
    readonly publishedAt: any;
  };
};
export type HighlightManageFormQuery = {
  response: HighlightManageFormQuery$data;
  variables: HighlightManageFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "caption",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destinationUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notifLandingPath",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "GraphQLMedia",
  "kind": "LinkedField",
  "name": "media",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isProcessing",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "utmCampaign",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HighlightManageFormQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GraphQLHighlight",
            "kind": "LinkedField",
            "name": "highlight",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLNotification",
                "kind": "LinkedField",
                "name": "notification",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HighlightManageFormQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GraphQLHighlight",
            "kind": "LinkedField",
            "name": "highlight",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLNotification",
                "kind": "LinkedField",
                "name": "notification",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "4df2c3c622c716a75b5404a12f1e81a7",
    "id": null,
    "metadata": {},
    "name": "HighlightManageFormQuery",
    "operationKind": "query",
    "text": "query HighlightManageFormQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  highlight(id: $id) @skip(if: $skip) {\n    id\n    caption\n    publishedAt\n    destinationUrl\n    notifLandingPath\n    media {\n      type\n      url\n      coverImageUrl\n      isProcessing\n    }\n    notification {\n      title\n      body\n      utmCampaign\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4645f7814b162b7bb0525599a9d68419";

export default node;
