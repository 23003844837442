import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useCallback, useContext } from "react";
import { useFragment, useMutation } from "react-relay";
import { ConnectionHandler, UploadableMap } from "relay-runtime";
import {
  GraphQLFeedPostInput,
  useFeedPostCreateMutation$data,
  useFeedPostCreateMutation as useFeedPostCreateMutationType,
} from "./__generated__/useFeedPostCreateMutation.graphql";
import { useFeedPostCreateMutation_brand$key } from "./__generated__/useFeedPostCreateMutation_brand.graphql";
import SnackbarContext from "../contexts/SnackbarContext";

const mutation = graphql`
  mutation useFeedPostCreateMutation($input: GraphQLFeedPostInput!) {
    createFeedPost(input: $input) {
      ... on GraphQLFeedPost {
        id
        notification {
          id
          ...NotificationTableRow_notification
        }
        ...FeedTabTableRow_feedPost
        ...FeedCarouselForm_feedPost
        ...FeedSinglePostForm_feedPost
        ...FeedPollForm_feedPost
      }
    }
  }
`;

const fragment = graphql`
  fragment useFeedPostCreateMutation_brand on BrandType {
    id
  }
`;

const useFeedPostCreateMutation = (
  brand: useFeedPostCreateMutation_brand$key
): [
  (
    input: GraphQLFeedPostInput,
    onCompleted?: (data: useFeedPostCreateMutation$data) => void,
    uploadables?: UploadableMap
  ) => void,
  boolean
] => {
  const brandData = useFragment(fragment, brand);
  const brandID = brandData.id;

  const [createMutation, isMutationInFlight] =
    useMutation<useFeedPostCreateMutationType>(mutation);
  const snackbarContext = useContext(SnackbarContext);

  const onMutate = useCallback(
    (
      input: GraphQLFeedPostInput,
      onCompleted?: (data: useFeedPostCreateMutation$data) => void,
      uploadables?: UploadableMap
    ) => {
      createMutation({
        variables: {
          input: {
            ...input,
          },
        },
        onCompleted: (data, errors) => {
          if (errors) {
            snackbarContext?.openSnackbar("Create Failed", "error");
          } else {
            onCompleted && onCompleted(data);
            snackbarContext?.openSnackbar("Feed Post Created", "success");
          }
        },
        onError: (error: Error) => {
          snackbarContext?.openSnackbar("Create Failed", "error");
        },
        updater: (store) => {
          const payload = store.getRootField("createFeedPost");
          const brand = store.get(brandID);
          if (brand == null || payload == null) {
            return;
          }

          if (brand.getValue("hasCreatedFeedPost") === false) {
            brand.setValue(true, "hasCreatedFeedPost");
          }

          const postConnection = ConnectionHandler.getConnection(
            brand,
            "FeedTabTable_feedPosts",
            {
              filters: {
                audienceIds: [],
                audienceLanguages: [],
              },
            }
          );
          if (postConnection == null) {
            return;
          }

          const newEdge = ConnectionHandler.createEdge(
            store,
            postConnection,
            payload,
            "GraphQLFeedPostEdge"
          );
          ConnectionHandler.insertEdgeBefore(postConnection, newEdge);

          // Increment total count
          const feedPostCount = postConnection.getValue("totalCount");
          if (feedPostCount != null) {
            postConnection.setValue(Number(feedPostCount) + 1, "totalCount");
          }

          const sortedEdges = postConnection
            .getLinkedRecords("edges")
            ?.sort((a, b) => {
              const aTime = dayjs(
                a.getLinkedRecord("node")?.getValue("publishedAt") + ""
              ).unix();
              const bTime = dayjs(
                b.getLinkedRecord("node")?.getValue("publishedAt") + ""
              ).unix();

              return bTime - aTime;
            });
          if (sortedEdges == null) {
            return;
          }
          postConnection.setLinkedRecords(sortedEdges, "edges");

          // Handle Notification updater
          const notificationRecord = payload.getLinkedRecord("notification");
          if (notificationRecord == null) {
            return;
          }
          const notifConnection = ConnectionHandler.getConnection(
            brand,
            "NotificationsTab_notifications"
          );
          if (notifConnection == null) {
            return;
          }
          const newNotifEdge = ConnectionHandler.createEdge(
            store,
            notifConnection,
            notificationRecord,
            "GraphQLNotificationEdge"
          );
          ConnectionHandler.insertEdgeBefore(notifConnection, newNotifEdge);

          // Increment notif count
          const notifCount = notifConnection.getValue("totalCount");
          if (notifCount != null) {
            notifConnection.setValue(Number(notifCount) + 1, "totalCount");
          }
        },
        uploadables,
      });
    },
    [snackbarContext, createMutation, brandID]
  );

  return [onMutate, isMutationInFlight];
};

export default useFeedPostCreateMutation;
