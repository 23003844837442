import {
  Avatar,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useContext } from "react";
import { useFragment } from "react-relay";
import { BrandAdminTableRow_brandAdminProfile$key } from "./__generated__/BrandAdminTableRow_brandAdminProfile.graphql";
import CurrentUserContext from "../../../../contexts/CurrentUserContext";

dayjs.extend(relativeTime);

const profileFragment = graphql`
  fragment BrandAdminTableRow_brandAdminProfile on GraphQLBrandAdminProfile {
    id
    isOnboarded
    user {
      id
      email
      firstName
      lastName
    }
  }
`;

const BrandAdminTableRow = ({
  // isNew,
  isActionButtonDisabled,
  profile: profileKey,
  onDelete,
}: {
  // isNew: boolean;
  isActionButtonDisabled: boolean;
  profile: BrandAdminTableRow_brandAdminProfile$key;
  onDelete: () => void;
}) => {
  const currentUserContext = useContext(CurrentUserContext);
  const profile = useFragment(profileFragment, profileKey);
  const user = profile.user;
  const fullName = user.firstName + " " + user.lastName;
  const initials = user.firstName.charAt(0) + user.lastName.charAt(0);
  const isOnboarded = profile.isOnboarded;

  const title = (
    <ListItem>
      <ListItemAvatar>
        {initials === "" ? <Avatar /> : <Avatar>{initials}</Avatar>}
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            variant="body2"
            color={"black"}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {fullName.trim() || user.email}
          </Typography>
        }
        secondary={
          <Typography
            variant="body2"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {user.email}
          </Typography>
        }
      />
    </ListItem>
  );

  const actionButton =
    currentUserContext?.userID === user.id ? (
      <Button
        size="small"
        variant="contained"
        color="error"
        onClick={onDelete}
        disabled={isActionButtonDisabled}
      >
        Leave
      </Button>
    ) : (
      <Button
        size="small"
        variant="text"
        color="error"
        onClick={onDelete}
        disabled={isActionButtonDisabled}
      >
        Remove
      </Button>
    );

  return (
    <TableRow
      key={user.id}
      sx={
        {
          // background: isNew ? "rgba(80, 72, 229, 0.08)" : "white",
          // transition: "background .2s ease-in",
        }
      }
    >
      <TableCell align="center">{title}</TableCell>
      <TableCell align="center">
        {isOnboarded ? "Admin" : "Invite Sent"}
      </TableCell>
      <TableCell align="center">
        <Stack direction="row" justifyContent={"flex-end"}>
          {isActionButtonDisabled ? (
            <Tooltip
              title={"There must be at least one admin"}
              placement="top-start"
              arrow
            >
              <span>{actionButton}</span>
            </Tooltip>
          ) : (
            actionButton
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default BrandAdminTableRow;
