/**
 * @generated SignedSource<<9142bb3c0849fa30a83a13bfb900ce55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CDPAudienceType = "LIST" | "SEGMENT" | "%future added value";
export type CDPType = "KINN" | "KLAVIYO" | "OMETRIA" | "SHOPIFY" | "%future added value";
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
export type GraphQLHighlightAlbumInput = {
  audienceLanguages?: ReadonlyArray<KinnLanguageCode> | null;
  audiences: ReadonlyArray<GraphQLCDPAudienceInput>;
  brand: NodeInput;
  index: number;
  title: string;
};
export type NodeInput = {
  id: string;
};
export type GraphQLCDPAudienceInput = {
  audienceId: string;
  audienceName: string;
  audienceType: CDPAudienceType;
  cdpType: CDPType;
};
export type HighlightsTabCreateAlbumMutation$variables = {
  input: GraphQLHighlightAlbumInput;
};
export type HighlightsTabCreateAlbumMutation$data = {
  readonly createHighlightAlbum: {
    readonly audienceLanguages: ReadonlyArray<KinnLanguageCode> | null;
    readonly audiences: ReadonlyArray<{
      readonly audienceId: string;
    }>;
    readonly count: number;
    readonly coverImageUrl: string | null;
    readonly createdAt: any;
    readonly id: string;
    readonly isArchived: boolean;
    readonly title: string;
  };
};
export type HighlightsTabCreateAlbumMutation = {
  response: HighlightsTabCreateAlbumMutation$data;
  variables: HighlightsTabCreateAlbumMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "audienceLanguages",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverImageUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArchived",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "audienceId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HighlightsTabCreateAlbumMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLHighlightAlbum",
        "kind": "LinkedField",
        "name": "createHighlightAlbum",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLCDPAudience",
            "kind": "LinkedField",
            "name": "audiences",
            "plural": true,
            "selections": [
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HighlightsTabCreateAlbumMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLHighlightAlbum",
        "kind": "LinkedField",
        "name": "createHighlightAlbum",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLCDPAudience",
            "kind": "LinkedField",
            "name": "audiences",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "475689b0412753852d907dcc90358aa0",
    "id": null,
    "metadata": {},
    "name": "HighlightsTabCreateAlbumMutation",
    "operationKind": "mutation",
    "text": "mutation HighlightsTabCreateAlbumMutation(\n  $input: GraphQLHighlightAlbumInput!\n) {\n  createHighlightAlbum(input: $input) {\n    id\n    audienceLanguages\n    count\n    coverImageUrl\n    createdAt\n    isArchived\n    title\n    audiences {\n      audienceId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7e87ddaa91fce3e05c74754787377cce";

export default node;
