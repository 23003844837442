/**
 * @generated SignedSource<<6fa3363136599541eeccb953eab76e5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InternBrandAndroidBuildsGeneralInfo_appConfig$data = {
  readonly androidAppBuild: string;
  readonly androidAppVersion: string;
  readonly appName: string;
  readonly id: string;
  readonly " $fragmentType": "InternBrandAndroidBuildsGeneralInfo_appConfig";
};
export type InternBrandAndroidBuildsGeneralInfo_appConfig$key = {
  readonly " $data"?: InternBrandAndroidBuildsGeneralInfo_appConfig$data;
  readonly " $fragmentSpreads": FragmentRefs<"InternBrandAndroidBuildsGeneralInfo_appConfig">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternBrandAndroidBuildsGeneralInfo_appConfig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "androidAppVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "androidAppBuild",
      "storageKey": null
    }
  ],
  "type": "BrandAppConfigType",
  "abstractKey": null
};

(node as any).hash = "ce68497975181e502b00757442db804b";

export default node;
