import { Box, Stack, SvgIcon, Typography } from "@mui/material";
import PercentageChange from "./PercentageChange";

const InsightsCard = ({
  Icon,
  title,
  data,
  change,
  title2,
  data2,
  change2,
}: {
  Icon?: typeof SvgIcon;
  title: string;
  data: string | number;
  change?: number;
  title2?: string;
  data2?: string | number;
  change2?: number;
}) => {
  const change1Decimal = change != null ? parseFloat(change.toFixed(2)) : null;
  const change2Decimal =
    change2 != null ? parseFloat(change2.toFixed(2)) : null;

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "8px",
        padding: 2,
        height: "100%",
      }}
    >
      {Icon && <Icon fontSize="small" />}
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "120%",
          }}
        >
          {title}
        </Typography>
        {title2 ? (
          <Typography
            sx={{
              color: "black",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "120%",
            }}
          >
            {title2}
          </Typography>
        ) : null}
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "100%",
            mt: 2,
          }}
        >
          {data}
        </Typography>

        {data2 ? (
          <Box>
            <Typography
              sx={{
                color: "black",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "100%",
                mt: 2,
              }}
            >
              {data2}
            </Typography>
          </Box>
        ) : null}
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {change1Decimal != null && <PercentageChange value={change1Decimal} />}
        {change2Decimal != null && <PercentageChange value={change2Decimal} />}
      </Stack>
    </Box>
  );
};

export default InsightsCard;
