import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useContext } from "react";
import { useMutation } from "react-relay";
import { UploadableMap } from "relay-runtime";
import type {
  BrandAppConfigInputPartial,
  useBrandAppConfigUpdateMutation as useBrandAppConfigUpdateMutationType,
} from "./__generated__/useBrandAppConfigUpdateMutation.graphql";
import SnackbarContext from "../contexts/SnackbarContext";

const mutation = graphql`
  mutation useBrandAppConfigUpdateMutation(
    $input: BrandAppConfigInputPartial!
  ) {
    updateBrandAppConfig(input: $input) {
      ... on BrandType {
        id
        ...ThemeTab_brand
        ...NavigationTab_brand
        ...ScreenshotsCard_brand
      }
    }
  }
`;

const useBrandAppConfigUpdateMutation = (
  input: BrandAppConfigInputPartial,
  onCompleted?: () => void,
  uploadables?: UploadableMap,
  onErrorMessages?: (
    errors: Array<{
      field: string;
      message: string;
    }>
  ) => void
): [() => void, boolean] => {
  const [saveMutation, isMutationInFlight] =
    useMutation<useBrandAppConfigUpdateMutationType>(mutation);

  const snackbarContext = useContext(SnackbarContext);

  const onMutate = useCallback(() => {
    saveMutation({
      variables: {
        input,
      },
      onCompleted: (data, errors) => {
        if (errors) {
          snackbarContext?.openSnackbar("Update Failed", "error");
        } else {
          onCompleted && onCompleted();
          snackbarContext?.openSnackbar("Update Successful", "success");
        }
        //  else if (handledErrors.length > 0) {
        //   snackbarContext?.openSnackbar("Update Failed", "error");
        //   onErrorMessages &&
        //     onErrorMessages(
        //       handledErrors.map((error) => ({
        //         field: error.field ?? "",
        //         message: error.message,
        //       }))
        //     );
        // }
      },
      onError: (error: Error) => {
        snackbarContext?.openSnackbar("Update Failed", "error");
      },
      uploadables,
    });
  }, [input, onCompleted, snackbarContext, saveMutation, uploadables]);

  return [onMutate, isMutationInFlight];
};

export default useBrandAppConfigUpdateMutation;
