import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  AlertColor,
  AlertTitle,
  Button,
  Link,
  Snackbar,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { graphql } from "babel-plugin-relay/macro";
import { Suspense, useCallback, useContext, useMemo, useState } from "react";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { Outlet, useLocation } from "react-router-dom";
import { StatsigProvider } from "statsig-react";
import { DashboardContainerQuery } from "./__generated__/DashboardContainerQuery.graphql";
import { DashboardContainer_user$key } from "./__generated__/DashboardContainer_user.graphql";
import { DashboardShopifyOneTimeChargeMutation } from "./__generated__/DashboardShopifyOneTimeChargeMutation.graphql";
import { DashboardShopifyRecurringSubscriptionMutation } from "./__generated__/DashboardShopifyRecurringSubscriptionMutation.graphql";
import MobilePreview from "../../components/MobilePreview";
import Sidepane from "../../components/layout/Sidepane";
import TopNavBar from "../../components/layout/TopNavBar";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import DebugContext from "../../contexts/DebugContext";
import { MobilePreviewProviders } from "../../contexts/MobilePreviewContext";
import SnackbarContext from "../../contexts/SnackbarContext";
import useBrandUpdateMutation from "../../mutations/useBrandUpdateMutation";
import DashboardTheme from "../../styles/DashboardTheme";
import nullthrows from "../../utils/nullthrows";
import {
  ENGAGE_AUTOMATIONS_INTERSTITIAL_NOTIFICATIONS,
  ENGAGE_DAILY_DEALS,
} from "../../utils/routes";

const recurringMutation = graphql`
  mutation DashboardShopifyRecurringSubscriptionMutation($input: NodeInput!) {
    createShopifyRecurringSubscription(input: $input)
  }
`;

const oneTimeMutation = graphql`
  mutation DashboardShopifyOneTimeChargeMutation($input: NodeInput!) {
    createShopifyOneTimeCharge(input: $input)
  }
`;

const containerQuery = graphql`
  query DashboardContainerQuery {
    user {
      id
      email
      activeBrand {
        id
      }
      brandAdminProfiles {
        brand {
          id
        }
      }
      ...DashboardContainer_user
    }
  }
`;

const fragment = graphql`
  fragment DashboardContainer_user on UserType {
    ...TopNavBar_user
    id
    isStaff
    brandAdminProfiles {
      brand {
        ...useBrandUpdateMutation_brand
        id
        showIosTosAlert
        billingConfig {
          id
          planStatus
          pendingBillingConfig {
            isBlocking
            price
            interval
            takeRate
            currency
            status
          }
          oneTimePurchaseCharges {
            id
            isBlocking
            status
            currency
            name
            price
          }
        }
        displayName
        finishedOnboarding
        appConfig {
          appState
          iosAscTeamId
        }
        ...Sidepane_brandFragment
      }
    }
    ...Sidepane_userFragment
  }
`;

const Dashboard = ({ userKey }: { userKey: DashboardContainer_user$key }) => {
  const { activeBrandID } = useContext(CurrentUserContext);
  const user = useFragment(fragment, userKey);
  const [createSubscription, isSubscriptionLoading] =
    useMutation<DashboardShopifyRecurringSubscriptionMutation>(
      recurringMutation
    );
  const [createOneTimeCharge, isOneTimeChargeLoading] =
    useMutation<DashboardShopifyOneTimeChargeMutation>(oneTimeMutation);

  const [isShopifyNavigationLoading, setIsShopifyNavigationLoading] =
    useState<boolean>(false);

  const isStaff = user.isStaff;

  const { pathname } = useLocation();
  const isMobilePreviewEnabled =
    pathname === "/engage/feed/create" ||
    pathname.includes("/engage/feed/edit") ||
    pathname === "/build/navigation" ||
    pathname === "/build/theme" ||
    pathname === "/engage/notifications/create" ||
    pathname.includes("/engage/notifications/edit") ||
    pathname.includes(
      `${ENGAGE_AUTOMATIONS_INTERSTITIAL_NOTIFICATIONS}/edit`
    ) ||
    pathname === `${ENGAGE_AUTOMATIONS_INTERSTITIAL_NOTIFICATIONS}/create` ||
    pathname.includes(`${ENGAGE_DAILY_DEALS}/edit`) ||
    pathname === `${ENGAGE_DAILY_DEALS}/create`;
  const [isDebugEnabled, setDebugEnabled] = useState<boolean>(false);
  const debugProvider = useMemo(
    () => ({ debug: isDebugEnabled, onToggleDebug: setDebugEnabled }),
    [isDebugEnabled, setDebugEnabled]
  );

  // Sidepane
  const [isXsSidepaneOpen, setIsXsSidepaneOpen] = useState<boolean>(false);
  const activeBrand = nullthrows(
    user.brandAdminProfiles.find(
      (profile) => profile.brand.id === activeBrandID
    )
  ).brand;

  const [updateBrand, isBrandMutationInFlight] =
    useBrandUpdateMutation(activeBrand);

  const oneTimePurchaseCharges =
    activeBrand.billingConfig?.oneTimePurchaseCharges ?? [];

  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("info");
  const openSnackbar = useCallback(
    (message: string, severity: AlertColor) => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
    },
    [setSnackbarMessage, setSnackbarSeverity]
  );
  const snackbar = (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={2000}
      open={snackbarMessage !== null}
      onClose={() => setSnackbarMessage(null)}
    >
      <Alert
        severity={snackbarSeverity}
        sx={{ width: "100%" }}
        variant="filled"
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
  const snackbarProvider = useMemo(() => ({ openSnackbar }), [openSnackbar]);

  const isNotVerifiedByKin =
    activeBrand.appConfig.appState === "PENDING_KINN_SETUP";
  const nonActiveOneTimeCharges = oneTimePurchaseCharges.filter(
    (charge) => charge.status !== "ACTIVE"
  );
  const hasInvalidBillingConfig = !(
    activeBrand.billingConfig?.planStatus === "NO_PLAN" ||
    activeBrand.billingConfig?.planStatus === "ACTIVE"
  );
  const pendingBillingConfig =
    activeBrand.billingConfig?.pendingBillingConfig ?? null;
  const hasPendingBillingConfig = pendingBillingConfig !== null;
  const isPendingBillingConfigBlocking =
    pendingBillingConfig?.isBlocking ?? false;
  const pendingOneTimeCharge = nonActiveOneTimeCharges[0] ?? null;
  const isOneTimeChargeBlocking = pendingOneTimeCharge?.isBlocking ?? false;
  const isDisabled =
    !isStaff &&
    (isNotVerifiedByKin ||
      hasInvalidBillingConfig ||
      (pendingOneTimeCharge != null && isOneTimeChargeBlocking) ||
      (hasPendingBillingConfig && isPendingBillingConfigBlocking));
  let lockedContent = null;
  if (isDisabled) {
    if (isNotVerifiedByKin) {
      lockedContent = (
        <Stack
          sx={{
            width: "100%",
            mt: 8,
          }}
          direction="column"
          alignItems="center"
        >
          <Alert
            severity="info"
            sx={{
              width: "60%",
            }}
          >
            <AlertTitle sx={{ fontWeight: "bold" }}>
              Welcome! We're thrilled to have you here.
            </AlertTitle>
            Please reach out to your dedicated Kinn support contact to help you
            start setting up <b>{activeBrand.displayName}</b>. If you haven't
            been assigned a support contact yet, you can get one by signing up{" "}
            <Link href="https://www.getkinn.com/waitlist">here</Link>. We look
            forward to hearing from you!
          </Alert>
        </Stack>
      );
    } else if (hasPendingBillingConfig && isPendingBillingConfigBlocking) {
      let title = null;
      let description: any = "Something went wrong. Please try again later.";
      let onClick = null;
      let severity: AlertColor = "warning";

      switch (pendingBillingConfig?.status) {
        case "PENDING":
          title = "Action Required: Your Subscription Plan Is Pending";
          description = (
            <>
              Your access to the admin dashboard is currently in a locked state
              due to a pending billing subscription plan. To continue managing
              your brand effectively, we kindly ask you to take a moment to
              review and accept the new plan:{" "}
              <b>
                {" "}
                {pendingBillingConfig.price} {pendingBillingConfig.currency}{" "}
                {pendingBillingConfig.interval
                  .toLocaleLowerCase()
                  .replaceAll("_", " ")}{" "}
                {pendingBillingConfig.takeRate != null &&
                pendingBillingConfig.takeRate > 0
                  ? `with a ${pendingBillingConfig.takeRate * 100}% take rate.`
                  : null}
              </b>
            </>
          );

          onClick = () => {
            setIsShopifyNavigationLoading(true);
            createSubscription({
              variables: {
                input: {
                  id: nullthrows(activeBrand.billingConfig?.id),
                },
              },
              onCompleted: (data, errors) => {
                if (errors) {
                  openSnackbar(
                    "Something went wrong. Please try again later.",
                    "error"
                  );
                } else {
                  window.open(data.createShopifyRecurringSubscription, "_self");
                }
              },
              onError: (error) => {
                setIsShopifyNavigationLoading(false);
                openSnackbar(
                  "Something went wrong. Please try again later.",
                  "error"
                );
              },
            });
          };
          break;
        case "CANCELLED":
        case "DECLINED":
        case "EXPIRED":
          title = "Action Required: Your Subscription Plan Is Pending";
          description = `Please reach out to your dedicated Kinn support contact to help you`;
          break;
      }

      lockedContent = (
        <Stack
          sx={{
            width: "100%",
            mt: 8,
          }}
          direction="column"
          alignItems="center"
        >
          <Alert
            severity={severity}
            sx={{
              width: "60%",
              display: "flex",
              alignItems: "center",
            }}
            action={
              onClick && (
                <LoadingButton
                  color="inherit"
                  size="medium"
                  onClick={onClick}
                  loading={isSubscriptionLoading || isShopifyNavigationLoading}
                >
                  View
                </LoadingButton>
              )
            }
          >
            <AlertTitle sx={{ fontWeight: "bold" }}>{title}</AlertTitle>
            {description}
          </Alert>
        </Stack>
      );
    } else if (hasInvalidBillingConfig) {
      let title = null;
      let description = "Something went wrong. Please try again later.";
      let onClick = null;
      let severity: AlertColor = "warning";

      switch (activeBrand.billingConfig?.planStatus) {
        case "CANCELLED":
        case "DECLINED":
        case "EXPIRED":
          title = "Important: Your Subscription Plan Has Ended";
          severity = "error";
          description = `Your Kinn subscription has ended, meaning you will no
          longer have access to the dashboard's features and tools. However,
          please be assured that your brand's data and settings will be securely
          stored should you decide to reactivate your plan in the future.`;
          break;
      }

      lockedContent = (
        <Stack
          sx={{
            width: "100%",
            mt: 8,
          }}
          direction="column"
          alignItems="center"
        >
          <Alert
            severity={severity}
            sx={{
              width: "60%",
              display: "flex",
              alignItems: "center",
            }}
            action={
              onClick && (
                <LoadingButton
                  color="inherit"
                  size="medium"
                  onClick={onClick}
                  loading={isSubscriptionLoading}
                >
                  View
                </LoadingButton>
              )
            }
          >
            <AlertTitle sx={{ fontWeight: "bold" }}>{title}</AlertTitle>
            {description}
          </Alert>
        </Stack>
      );
    } else if (pendingOneTimeCharge != null && isOneTimeChargeBlocking) {
      let title = null;
      let description = null;
      let onClick = null;
      let severity: AlertColor = "warning";
      switch (pendingOneTimeCharge.status) {
        case "PENDING":
          title = "Action Required: You Have A Pending One-Time Charge";
          description = (
            <>
              Your access to the admin dashboard is currently in a locked state
              due to a pending charge. To continue managing your brand
              effectively, we kindly ask you to take a moment to review and
              accept the new charge:
              <b>
                {" "}
                {pendingOneTimeCharge.price} {pendingOneTimeCharge.currency} for{" "}
                {pendingOneTimeCharge.name}
              </b>
            </>
          );
          onClick = () => {
            setIsShopifyNavigationLoading(true);
            createOneTimeCharge({
              variables: {
                input: {
                  id: pendingOneTimeCharge.id,
                },
              },
              onCompleted: (data, errors) => {
                if (errors) {
                  openSnackbar(
                    "Something went wrong. Please try again later.",
                    "error"
                  );
                } else {
                  window.open(data.createShopifyOneTimeCharge, "_self");
                }
              },
              onError: (error) => {
                setIsShopifyNavigationLoading(false);
                openSnackbar(
                  "Something went wrong. Please try again later.",
                  "error"
                );
              },
            });
          };
          break;
        case "CANCELLED":
        case "DECLINED":
        case "EXPIRED":
          title =
            "Important: You Have A Pending One-Time Charge That Has Expired";
          severity = "error";
          description = `Your access to the admin dashboard is currently in a locked state due
          to an expired pending charge. To continue managing your
          brand effectively, please reach out to your dedicated Kinn support contact.`;
          break;
      }
      lockedContent = (
        <Stack
          sx={{
            width: "100%",
            mt: 8,
          }}
          direction="column"
          alignItems="center"
        >
          <Alert
            severity={severity}
            sx={{
              width: "60%",
              display: "flex",
              alignItems: "center",
            }}
            action={
              onClick && (
                <LoadingButton
                  color="inherit"
                  size="medium"
                  onClick={onClick}
                  loading={isOneTimeChargeLoading || isShopifyNavigationLoading}
                >
                  View
                </LoadingButton>
              )
            }
          >
            <AlertTitle sx={{ fontWeight: "bold" }}>{title}</AlertTitle>
            {description}
          </Alert>
        </Stack>
      );
    }
  }
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const showIosTosAlert = activeBrand.showIosTosAlert;
  const showPendingBillingConfigAlert =
    hasPendingBillingConfig && !isPendingBillingConfigBlocking;
  const showPendingOneTimeChargeAlert =
    pendingOneTimeCharge != null && !isOneTimeChargeBlocking;
  const showTopLevelAlert =
    showIosTosAlert ||
    showPendingBillingConfigAlert ||
    showPendingOneTimeChargeAlert;
  let alertTitle = null;
  let alertDesc = null;
  let alertButton = null;
  let alertSeverity: AlertColor = "warning";
  if (showTopLevelAlert) {
    if (showPendingBillingConfigAlert) {
      alertSeverity = "error";
      alertTitle = "Action Required: Your Subscription Plan Is Pending";
      alertDesc = (
        <>
          To continue managing your brand effectively, we kindly ask you to take
          a moment to review and accept the new plan:
          <b>
            {" "}
            {pendingBillingConfig.price} {pendingBillingConfig.currency}{" "}
            {pendingBillingConfig.interval
              .toLocaleLowerCase()
              .replaceAll("_", " ")}{" "}
            {pendingBillingConfig.takeRate != null &&
            pendingBillingConfig.takeRate > 0
              ? `with a ${pendingBillingConfig.takeRate * 100}% take rate.`
              : null}
          </b>
        </>
      );
      alertButton = (
        <LoadingButton
          color="inherit"
          size="medium"
          variant="outlined"
          onClick={() => {
            setIsShopifyNavigationLoading(true);
            createSubscription({
              variables: {
                input: {
                  id: nullthrows(activeBrand.billingConfig?.id),
                },
              },
              onCompleted: (data, errors) => {
                if (errors) {
                  openSnackbar(
                    "Something went wrong. Please try again later.",
                    "error"
                  );
                } else {
                  window.open(data.createShopifyRecurringSubscription, "_self");
                }
              },
              onError: (error) => {
                setIsShopifyNavigationLoading(false);
                openSnackbar(
                  "Something went wrong. Please try again later.",
                  "error"
                );
              },
            });
          }}
          loading={isSubscriptionLoading || isShopifyNavigationLoading}
        >
          View
        </LoadingButton>
      );
    } else if (showPendingOneTimeChargeAlert) {
      alertSeverity = "error";
      alertTitle = "Action Required: You Have A Pending One-Time Charge";
      alertDesc = (
        <>
          To continue managing your brand effectively, we kindly ask you to take
          a moment to review and accept the new charge:
          <b>
            {" "}
            {pendingOneTimeCharge.price} {pendingOneTimeCharge.currency} for{" "}
            {pendingOneTimeCharge.name}
          </b>
        </>
      );
      alertButton = (
        <LoadingButton
          color="inherit"
          size="medium"
          variant="outlined"
          onClick={() => {
            setIsShopifyNavigationLoading(true);
            createOneTimeCharge({
              variables: {
                input: {
                  id: pendingOneTimeCharge.id,
                },
              },
              onCompleted: (data, errors) => {
                if (errors) {
                  openSnackbar(
                    "Something went wrong. Please try again later.",
                    "error"
                  );
                } else {
                  window.open(data.createShopifyOneTimeCharge, "_self");
                }
              },
              onError: (error) => {
                setIsShopifyNavigationLoading(false);
                openSnackbar(
                  "Something went wrong. Please try again later.",
                  "error"
                );
              },
            });
          }}
          loading={isSubscriptionLoading || isShopifyNavigationLoading}
        >
          View
        </LoadingButton>
      );
    } else if (showIosTosAlert) {
      alertTitle = "Action Required: Accept Terms of Service Agreement";
      alertDesc = (
        <>
          Recent changes in Apple's Terms of Service (TOS) require you to accept
          before Kinn can publish new builds of your app. Please do so at this{" "}
          <Link
            href={`https://developer.apple.com/account/?teamId=${activeBrand.appConfig.iosAscTeamId}`}
            target="_blank"
          >
            link
          </Link>{" "}
          as quickly as possible
        </>
      );
      alertButton = (
        <Button
          color="inherit"
          variant="outlined"
          onClick={() => updateBrand({ showIosTosAlert: false })}
          disabled={isBrandMutationInFlight}
        >
          Dismiss
        </Button>
      );
    }
  }

  const unlockedContent = (
    <>
      <Box
        sx={{
          background: "#F9FAFC",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TopNavBar
          activeBrandID={activeBrandID}
          previewEnabled={!isDisabled}
          user={user}
          onDrawerOpen={() => setIsXsSidepaneOpen(true)}
          onDrawerClose={() => setIsXsSidepaneOpen(false)}
        />
        {showTopLevelAlert && (
          <Alert
            sx={{
              alignItems: "center",
              px: 4,
            }}
            severity={alertSeverity}
            action={alertButton}
          >
            <AlertTitle sx={{ fontWeight: "bold" }}>{alertTitle}</AlertTitle>
            {alertDesc}
          </Alert>
        )}
        <Box
          sx={{
            overflowY: "hidden",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              padding: isMobile ? 2 : 8,
              paddingBottom: isMobile ? 8 : undefined,
              flexGrow: 1,
              overflowY: "auto",
            }}
          >
            <Outlet />
          </Box>
          {isMobilePreviewEnabled && (
            <Suspense>
              <MobilePreview />
            </Suspense>
          )}
        </Box>
      </Box>
    </>
  );

  return (
    <DebugContext.Provider value={debugProvider}>
      <ThemeProvider theme={DashboardTheme}>
        <SnackbarContext.Provider value={snackbarProvider}>
          {snackbar}
          <MobilePreviewProviders>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "100vh",
                width: "100%",
              }}
            >
              <CssBaseline />
              <Sidepane
                disabled={isDisabled}
                user={user}
                brand={activeBrand}
                brandFinishedOnboarding={activeBrand.finishedOnboarding}
                isXsSidepaneOpen={isXsSidepaneOpen}
                onDrawerClose={() => setIsXsSidepaneOpen(false)}
              />
              {isDisabled ? lockedContent : unlockedContent}
            </Box>
          </MobilePreviewProviders>
        </SnackbarContext.Provider>
      </ThemeProvider>
    </DebugContext.Provider>
  );
};

const DashboardContainer = () => {
  const data = useLazyLoadQuery<DashboardContainerQuery>(containerQuery, {});
  const user = nullthrows(data.user);
  const userID = user.id;
  const adminProfiles = user.brandAdminProfiles;

  const fallbackActiveBrandID = user.brandAdminProfiles[0].brand.id;
  const initialActiveBrandID = user?.activeBrand?.id ?? fallbackActiveBrandID;

  const [activeBrandID, setActiveBrandID] =
    useState<string>(initialActiveBrandID);
  const decodedUserID = useMemo(
    () => window.atob(userID).split(":")[1],
    [userID]
  );
  const [statsigUser, setStatsigUser] = useState({
    userID: decodedUserID,
    email: user?.email ?? "",
    customIDs: {
      brandID: window.atob(initialActiveBrandID).split(":")[1],
    },
  });
  const setContextActiveBrandID = useCallback(
    (newBrandID: string) => {
      setStatsigUser({
        userID: decodedUserID,
        email: user?.email ?? "",
        customIDs: {
          brandID: window.atob(newBrandID).split(":")[1],
        },
      });
      setActiveBrandID(newBrandID);
    },
    [decodedUserID, setStatsigUser, setActiveBrandID, user]
  );
  const currentUserProvider = useMemo(
    () => ({
      activeBrandID,
      setActiveBrandID: setContextActiveBrandID,
      userID,
    }),
    [activeBrandID, setContextActiveBrandID, userID]
  );

  if (adminProfiles.length === 0) {
    return null; // TODO: add in error state
  }

  return (
    <StatsigProvider
      sdkKey={"client-TjewFs0oNSjRI2G5KfMYEyeqcUv6D8NUhDt6rqQ9U73"}
      user={statsigUser}
      // waitForInitialization={true}
    >
      <CurrentUserContext.Provider value={currentUserProvider}>
        <Dashboard userKey={user} />
      </CurrentUserContext.Provider>
    </StatsigProvider>
  );
};

export default DashboardContainer;
