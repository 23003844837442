/**
 * @generated SignedSource<<100779dc3bbe23b1200d048a3ad19585>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
export type MediaType = "PHOTO" | "VIDEO" | "WEB_MUX_VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeedSinglePostForm_feedPost$data = {
  readonly audienceLanguages: ReadonlyArray<KinnLanguageCode> | null;
  readonly audiences: ReadonlyArray<{
    readonly audienceId: string;
  }>;
  readonly id: string;
  readonly link: {
    readonly externalMediaUrl: string | null;
    readonly media: ReadonlyArray<{
      readonly coverImageUrl: string | null;
      readonly type: MediaType;
      readonly url: string;
    }>;
    readonly subtitle: string | null;
    readonly title: string;
    readonly url: string;
  } | null;
  readonly notification: {
    readonly body: string;
    readonly title: string;
    readonly utmCampaign: string | null;
  } | null;
  readonly publishedAt: any;
  readonly " $fragmentType": "FeedSinglePostForm_feedPost";
};
export type FeedSinglePostForm_feedPost$key = {
  readonly " $data"?: FeedSinglePostForm_feedPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedSinglePostForm_feedPost">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedSinglePostForm_feedPost",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLCDPAudience",
      "kind": "LinkedField",
      "name": "audiences",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "audienceId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "audienceLanguages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLLink",
      "kind": "LinkedField",
      "name": "link",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "externalMediaUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subtitle",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLMedia",
          "kind": "LinkedField",
          "name": "media",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coverImageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLNotification",
      "kind": "LinkedField",
      "name": "notification",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "utmCampaign",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GraphQLFeedPost",
  "abstractKey": null
};
})();

(node as any).hash = "52457a110e2b77db2b616c1716b5a8d5";

export default node;
