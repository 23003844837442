import { Stack, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import * as yup from "yup";
import { DiscourseCard_brand$key } from "./__generated__/DiscourseCard_brand.graphql";
import CardSection from "../../../../components/CardSection";
import SaveButton, { SavedState } from "../../../../components/SaveButton";
import useBrandUpdateMutation from "../../../../mutations/useBrandUpdateMutation";

const schema = yup
  .object({
    discourseContact: yup
      .string()
      .required("E-mail Required")
      .email("E-mail invalid")
      .max(200),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const brandFragment = graphql`
  fragment DiscourseCard_brand on BrandType {
    discourseContactEmail
    ...useBrandUpdateMutation_brand
  }
`;

const DiscourseCard = ({
  brand: brandKey,
}: {
  brand: DiscourseCard_brand$key;
}) => {
  const brand = useFragment(brandFragment, brandKey);
  const email = brand.discourseContactEmail ?? "";
  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.SAVED
  );
  const [updateBrand, isMutationInFlight] = useBrandUpdateMutation(brand);

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    watch,
  } = useForm<FormData>({
    defaultValues: {
      discourseContact: email,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      saveButtonState !== SavedState.ENABLED &&
        setSaveButtonState(SavedState.ENABLED);
    });
    return () => subscription.unsubscribe();
  }, [watch, saveButtonState]);

  const onSubmit = (data: FormData) => {
    updateBrand(
      {
        discourseContactEmail: data.discourseContact,
      },
      (_) => {
        setSaveButtonState(SavedState.SAVED);
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardSection
        actions={
          <SaveButton
            savedState={
              isMutationInFlight || !isDirty
                ? SavedState.DISABLED
                : saveButtonState
            }
            useSubmit={true}
          />
        }
        title="Forum"
        content={
          <Stack spacing={2}>
            <TextField
              {...register("discourseContact")}
              label="Contact E-mail"
              error={!!errors?.discourseContact}
              helperText={errors?.discourseContact?.message}
              variant="outlined"
              autoComplete="off"
            />
          </Stack>
        }
      />
    </form>
  );
};

export default DiscourseCard;
