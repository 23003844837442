import { Box } from "@mui/material";
import React from "react";

type Props = {
  color: string;
  height: string;
  component: React.ReactElement | string;
};

const Circle = (props: Props) => {
  const { color, height, component } = props;
  return (
    <Box
      sx={{
        backgroundColor: color,
        borderRadius: "50%",
        height: height,
        width: height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 700,
        fontSize: "18px",
        flexShrink: 0,
      }}
    >
      {component}
    </Box>
  );
};

export default Circle;
