import { TableCell, Tooltip, Typography } from "@mui/material";

export default function UTMCampaignTableCell({
  utmCampaign,
}: {
  utmCampaign: string | null;
}) {
  return (
    <TableCell
      align="center"
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <Tooltip title={utmCampaign} placement="top">
        <Typography variant="caption" color="text.secondary">
          {utmCampaign}
        </Typography>
      </Tooltip>
    </TableCell>
  );
}
