import { Card, Stack, Typography } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { InternBrandAndroidBuildsGeneralInfo_appConfig$key } from "./__generated__/InternBrandAndroidBuildsGeneralInfo_appConfig.graphql";
import CardSection from "../../components/CardSection";

const appConfigFragment = graphql`
  fragment InternBrandAndroidBuildsGeneralInfo_appConfig on BrandAppConfigType {
    id
    appName
    androidAppVersion
    androidAppBuild
  }
`;

const InternBrandAndroidBuildsGeneralInfo = ({
  appConfig: appConfigKey,
}: {
  appConfig: InternBrandAndroidBuildsGeneralInfo_appConfig$key;
}) => {
  const appConfig = useFragment(appConfigFragment, appConfigKey);

  return (
    <CardSection
      title={"General Info"}
      content={
        <Stack width={"100%"} spacing={2}>
          <Typography variant="subtitle1">App Name</Typography>
          <Card
            variant="outlined"
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "black",
              }}
            >
              <b>{appConfig.appName}</b>
            </Typography>
          </Card>
          <Typography variant="subtitle1">Current Version</Typography>
          <Card
            variant="outlined"
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "black",
              }}
            >
              <b>{appConfig.androidAppVersion}</b>
            </Typography>
          </Card>
          <Typography variant="subtitle1">Current Build</Typography>
          <Card
            variant="outlined"
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "black",
              }}
            >
              <b>{appConfig.androidAppBuild}</b>
            </Typography>
          </Card>
        </Stack>
      }
      variant="outlined"
    />
  );
};

export default InternBrandAndroidBuildsGeneralInfo;
