import { createContext, useContext, useMemo, useReducer } from "react";
import { Uploadable } from "relay-runtime";
import { AppColorPalette, ButtonTextStyleType } from "../components/__generated__/MobilePreviewQuery.graphql";
import { IconType, TabType } from "../views/dashboard/build_section/navigation/__generated__/TabsCard2_brand.graphql";
import { MediaType } from "../views/dashboard/engage/feed/__generated__/FeedSinglePostForm_feedPost.graphql";
import nullthrows from "../utils/nullthrows";
import { assertNever } from "../utils/typeUtils";
import { MenuItem } from "../views/dashboard/build_section/navigation/MenuCard";
import { NavigationItem } from "../views/dashboard/build_section/navigation/ShortcutsCard";


export enum PreviewType {
  NONE = "none",
  SINGLE_POST = "single_post",
  POLL = "poll",
  NAVIGATION = "navigation",
  NOTIFICATION = "notification",
  INTERSTITIAL_NOTIFICATION = "interstitial_notification",
  CAROUSEL = "carousel",
  DAILY_DEAL = "daily_deal",
}

export enum ReducerAction {
  SET_PREVIEW_TYPE = "set_preview_type",
  UPDATE_CAROUSEL = "update_carousel",
  UPDATE_DAILY_DEAL = "update_daily_deal",
  UPDATE_SINGLE_POST = "update_single_post",
  UPDATE_POLL = "update_poll",
  UPDATE_NAVIGATION = "update_navigation",
  UPDATE_NOTIFICATION = "update_notification",
  UPDATE_INTERSTITIAL_NOTIFICATION = "update_interstitial_notification",
  UPDATE_THEME = "update_theme",
  RESET_NAVIGATION = "reset_navigation",
  RESET_THEME = "reset_theme",
  RESET = "reset",
}

interface MobilePreviewAction {
  type: ReducerAction;
  payload?: {
    previewType?: PreviewType;
    carousel?: {
      title?: string;
      subtitle?: string;
      items?: Array<{
        id?: string;
        title: string;
        subtitle: string;
        mediaUrl: string | null;
        mediaType?: MediaType;
        fallbackMediaUrl?: string;
        file?: Uploadable | null;
      }>;
    };
    dailyDeal?: {
      title?: string;
      blurredHeadline?: string;
      blurredSubheadline?: string;
      headline?: string;
      subheadline?: string;
      imageUrl?: string;
    };
    singlePost?: {
      title?: string;
      subtitle?: string;
      media?: string;
      mediaType?: MediaType;
    };
    poll?: {
      title?: string;
      media?: string;
      customResponsesEnabled?: boolean;
      options?: Array<{
        title: string;
        media: string;
        isStatic?: boolean;
      }>;
    };
    navigation?: {
      isTitleEnabled?: boolean;
      isTopTitleEnabled?: boolean;
      tabs?: {
        customName: string;
        customUrl: string | null;
        icon: IconType;
        type: TabType;
      }[];
      nestedMenuItems?: Array<MenuItem>;
      shortcutsPills?: ReadonlyArray<NavigationItem>;
      isGorgiasEnabled?: boolean;
    };
    notification?: {
      title?: string;
      body?: string;
      media?: string;
    };
    interstitialNotification?: {
      callToActionTitle?: string;
      media?: string;
    };
    theme?: {
      accent1PaletteLight?: string;
      accent2PaletteLight?: string;
      accent3PaletteLight?: string;
      primaryPaletteLight?: string;
      navigationForeground?: AppColorPalette | null;
      navigationBackground?: AppColorPalette | null;
      onboardingBackground?: AppColorPalette | null;
      onboardingForeground?: AppColorPalette | null;
      primaryButtonBackground?: AppColorPalette | null;
      primaryButtonForeground?: AppColorPalette | null;
      primaryPillBackground?: AppColorPalette | null;
      primaryPillForeground?: AppColorPalette | null;
      secondaryPillOutline?: AppColorPalette | null;
      pillContainerBackground?: AppColorPalette | null;
      buttonCornerRadius?: number | null;
      buttonTextStyle?: ButtonTextStyleType | null;
      buttonCartIcon?: IconType | null;
      logoUrl?: string | null;
      fontHeadingBold?: string | null;
      fontHeadingRegular?: string | null;
      fontBodyBold?: string | null;
      fontBodyRegular?: string | null;
    };
  };
}

interface MobilePreviewState {
  singlePost: {
    title: string;
    subtitle: string;
    media: string;
    mediaType: MediaType;
  };
  carousel: {
    title: string;
    subtitle: string;
    items: Array<{
      title: string;
      subtitle: string;
      mediaUrl: string | null;
      mediaType?: MediaType;
      fallbackMediaUrl?: string;
    }>;
  };
  dailyDeal: {
    title?: string;
    blurredHeadline: string;
    blurredSubheadline: string;
    headline: string;
    subheadline: string;
    imageUrl: string | null;
  };
  poll: {
    title: string;
    media: string;
    customResponsesEnabled?: boolean;
    options: Array<{
      title: string;
      media: string;
      isStatic?: boolean;
    }>;
  };
  navigation: {
    isTitleEnabled: boolean | null;
    isTopTitleEnabled: boolean | null;
    tabs:
      | {
          customName: string;
          customUrl: string | null;
          icon: IconType;
          type: TabType;
        }[]
      | null;
    isGorgiasEnabled: boolean | null;
    nestedMenuItems: Array<MenuItem> | null;
    shortcutsPills: ReadonlyArray<NavigationItem> | null;
  };
  notification: {
    title: string;
    body: string;
    media: string;
  };
  interstitialNotification: {
    callToActionTitle: string;
    media: string;
  };
  theme: {
    accent1PaletteLight: string | null;
    accent2PaletteLight: string | null;
    accent3PaletteLight: string | null;
    primaryPaletteLight: string | null;
    navigationForeground: null | AppColorPalette;
    navigationBackground: null | AppColorPalette;
    onboardingBackground: null | AppColorPalette;
    onboardingForeground: null | AppColorPalette;
    primaryButtonBackground: null | AppColorPalette;
    primaryButtonForeground: null | AppColorPalette;
    primaryPillBackground: null | AppColorPalette;
    primaryPillForeground: null | AppColorPalette;
    secondaryPillOutline: null | AppColorPalette;
    pillContainerBackground: null | AppColorPalette;
    buttonCornerRadius: number | null;
    buttonTextStyle: ButtonTextStyleType | null;
    buttonCartIcon: IconType | null;
    logoUrl: string | null;
    fontHeadingBold: string | null;
    fontHeadingRegular: string | null;
    fontBodyBold: string | null;
    fontBodyRegular: string | null;
  };
  previewType: PreviewType;
}

const initialMobilePreview: MobilePreviewState = {
  singlePost: {
    title: "",
    subtitle: "",
    media: "",
    mediaType: "PHOTO",
  },
  carousel: {
    title: "",
    subtitle: "",
    items: [
      {
        title: "",
        subtitle: "",
        mediaUrl: null,
      },
      {
        title: "",
        subtitle: "",
        mediaUrl: null,
      },
    ],
  },
  dailyDeal: {
    blurredHeadline: "",
    blurredSubheadline: "",
    headline: "",
    subheadline: "",
    imageUrl: null,
  },
  poll: {
    title: "",
    media: "",
    customResponsesEnabled: false,
    options: [
      { title: "Option 1", media: "", isStatic: false },
      { title: "Option 2", media: "", isStatic: false },
    ],
  },
  navigation: {
    isTitleEnabled: null,
    isTopTitleEnabled: null,
    tabs: null,
    nestedMenuItems: null,
    shortcutsPills: null,
    isGorgiasEnabled: null,
  },
  notification: {
    title: "",
    body: "",
    media: "",
  },
  interstitialNotification: {
    callToActionTitle: "",
    media: "",
  },
  theme: {
    accent1PaletteLight: null,
    accent2PaletteLight: null,
    accent3PaletteLight: null,
    primaryPaletteLight: null,
    navigationForeground: null,
    navigationBackground: null,
    onboardingBackground: null,
    onboardingForeground: null,
    primaryButtonBackground: null,
    primaryButtonForeground: null,
    primaryPillBackground: null,
    primaryPillForeground: null,
    secondaryPillOutline: null,
    pillContainerBackground: null,
    buttonCornerRadius: null,
    buttonTextStyle: null,
    buttonCartIcon: null,
    logoUrl: null,
    fontHeadingBold: null,
    fontHeadingRegular: null,
    fontBodyBold: null,
    fontBodyRegular: null,
  },
  previewType: PreviewType.NONE,
};

function reducer(
  state: MobilePreviewState,
  action: MobilePreviewAction
): MobilePreviewState {
  switch (action.type) {
    case ReducerAction.SET_PREVIEW_TYPE:
      return {
        ...state,
        previewType: action?.payload?.previewType ?? PreviewType.NONE,
        carousel: {
          title:
            action?.payload?.carousel?.title ?? state?.carousel?.title ?? "",
          subtitle:
            action?.payload?.carousel?.subtitle ??
            state?.carousel?.subtitle ??
            "",
          items:
            action?.payload?.carousel?.items ??
            state?.carousel?.items ??
            initialMobilePreview.carousel.items,
        },
        notification: {
          title:
            action?.payload?.notification?.title ?? state?.notification?.title,
          body:
            action?.payload?.notification?.body ?? state?.notification?.body,
          media:
            action?.payload?.notification?.media ?? state?.notification?.media,
        },
        interstitialNotification: {
          callToActionTitle:
            action?.payload?.interstitialNotification?.callToActionTitle ??
            state?.interstitialNotification?.callToActionTitle,
          media:
            action?.payload?.interstitialNotification?.media ??
            state?.interstitialNotification?.media,
        },
        dailyDeal: {
          title: action?.payload?.dailyDeal?.title ?? state?.dailyDeal?.title,
          blurredHeadline:
            action?.payload?.dailyDeal?.blurredHeadline ??
            state?.dailyDeal?.blurredHeadline ??
            "",
          blurredSubheadline:
            action?.payload?.dailyDeal?.blurredSubheadline ??
            state?.dailyDeal?.blurredSubheadline ??
            "",
          headline:
            action?.payload?.dailyDeal?.headline ??
            state?.dailyDeal?.headline ??
            "",
          subheadline:
            action?.payload?.dailyDeal?.subheadline ??
            state?.dailyDeal?.subheadline ??
            "",
          imageUrl:
            action?.payload?.dailyDeal?.imageUrl ??
            state?.dailyDeal?.imageUrl ??
            "",
        },
      };
    case ReducerAction.UPDATE_CAROUSEL:
      return {
        ...state,
        carousel: {
          title:
            action?.payload?.carousel?.title ?? state?.carousel?.title ?? "",
          subtitle:
            action?.payload?.carousel?.subtitle ??
            state?.carousel?.subtitle ??
            "",
          items:
            action?.payload?.carousel?.items ??
            state?.carousel?.items ??
            initialMobilePreview.carousel.items,
        },
      };
    case ReducerAction.UPDATE_DAILY_DEAL:
      return {
        ...state,
        dailyDeal: {
          title: action?.payload?.dailyDeal?.title ?? state?.dailyDeal?.title,
          blurredHeadline:
            action?.payload?.dailyDeal?.blurredHeadline ??
            state?.dailyDeal?.blurredHeadline ??
            "",
          blurredSubheadline:
            action?.payload?.dailyDeal?.blurredSubheadline ??
            state?.dailyDeal?.blurredSubheadline ??
            "",
          headline:
            action?.payload?.dailyDeal?.headline ??
            state?.dailyDeal?.headline ??
            "",
          subheadline:
            action?.payload?.dailyDeal?.subheadline ??
            state?.dailyDeal?.subheadline ??
            "",
          imageUrl:
            action?.payload?.dailyDeal?.imageUrl ??
            state?.dailyDeal?.imageUrl ??
            "",
        },
      };
    case ReducerAction.UPDATE_SINGLE_POST:
      return {
        ...state,
        singlePost: {
          title: action?.payload?.singlePost?.title ?? state.singlePost.title,
          subtitle:
            action?.payload?.singlePost?.subtitle ?? state.singlePost.subtitle,
          media: action?.payload?.singlePost?.media ?? state.singlePost.media,
          mediaType:
            action?.payload?.singlePost?.mediaType ??
            state.singlePost.mediaType,
        },
      };
    case ReducerAction.UPDATE_POLL:
      return {
        ...state,
        poll: {
          title: action?.payload?.poll?.title ?? state.poll.title,
          media: action?.payload?.poll?.media ?? state.poll.media,
          options: action?.payload?.poll?.options ?? state.poll.options,
          customResponsesEnabled: action?.payload?.poll?.customResponsesEnabled,
        },
      };
    case ReducerAction.UPDATE_NAVIGATION:
      return {
        ...state,
        navigation: {
          isTitleEnabled:
            action?.payload?.navigation?.isTitleEnabled ??
            state?.navigation?.isTitleEnabled,
          isTopTitleEnabled:
            action?.payload?.navigation?.isTopTitleEnabled ??
            state?.navigation?.isTopTitleEnabled,
          tabs: action?.payload?.navigation?.tabs ?? state?.navigation?.tabs,
          nestedMenuItems:
            action?.payload?.navigation?.nestedMenuItems ??
            state?.navigation?.nestedMenuItems,
          shortcutsPills:
            action?.payload?.navigation?.shortcutsPills ??
            state?.navigation?.shortcutsPills,
          isGorgiasEnabled:
            action?.payload?.navigation?.isGorgiasEnabled ??
            state?.navigation?.isGorgiasEnabled,
        },
      };
    case ReducerAction.RESET_NAVIGATION:
      return {
        ...state,
        navigation: initialMobilePreview.navigation,
      };
    case ReducerAction.UPDATE_NOTIFICATION:
      return {
        ...state,
        notification: {
          title:
            action?.payload?.notification?.title ?? state?.notification?.title,
          body:
            action?.payload?.notification?.body ?? state?.notification?.body,
          media:
            action?.payload?.notification?.media ?? state?.notification?.media,
        },
      };
    case ReducerAction.UPDATE_INTERSTITIAL_NOTIFICATION:
      return {
        ...state,
        interstitialNotification: {
          callToActionTitle:
            action?.payload?.interstitialNotification?.callToActionTitle ??
            state?.interstitialNotification?.callToActionTitle,
          media:
            action?.payload?.interstitialNotification?.media ??
            state?.interstitialNotification?.media,
        },
      };
    case ReducerAction.UPDATE_THEME:
      return {
        ...state,
        theme: {
          accent1PaletteLight:
            action?.payload?.theme?.accent1PaletteLight ??
            state?.theme?.accent1PaletteLight,
          accent2PaletteLight:
            action?.payload?.theme?.accent2PaletteLight ??
            state?.theme?.accent2PaletteLight,
          accent3PaletteLight:
            action?.payload?.theme?.accent3PaletteLight ??
            state?.theme?.accent3PaletteLight,
          primaryPaletteLight:
            action?.payload?.theme?.primaryPaletteLight ??
            state?.theme?.primaryPaletteLight,
          navigationForeground:
            action?.payload?.theme?.navigationForeground ??
            state?.theme?.navigationForeground,
          navigationBackground:
            action?.payload?.theme?.navigationBackground ??
            state?.theme?.navigationBackground,
          onboardingBackground:
            action?.payload?.theme?.onboardingBackground ??
            state?.theme?.onboardingBackground,
          onboardingForeground:
            action?.payload?.theme?.onboardingForeground ??
            state?.theme?.onboardingForeground,
          primaryButtonBackground:
            action?.payload?.theme?.primaryButtonBackground ??
            state?.theme?.primaryButtonBackground,
          primaryButtonForeground:
            action?.payload?.theme?.primaryButtonForeground ??
            state?.theme?.primaryButtonForeground,
          primaryPillBackground:
            action?.payload?.theme?.primaryPillBackground ??
            state?.theme?.primaryPillBackground,
          primaryPillForeground:
            action?.payload?.theme?.primaryPillForeground ??
            state?.theme?.primaryPillForeground,
          secondaryPillOutline:
            action?.payload?.theme?.secondaryPillOutline === undefined
              ? state?.theme?.secondaryPillOutline
              : action?.payload?.theme?.secondaryPillOutline,
          pillContainerBackground:
            action?.payload?.theme?.pillContainerBackground === undefined
              ? state?.theme?.pillContainerBackground
              : action?.payload?.theme?.pillContainerBackground,
          buttonCornerRadius:
            action?.payload?.theme?.buttonCornerRadius ??
            state?.theme?.buttonCornerRadius,
          buttonTextStyle:
            action?.payload?.theme?.buttonTextStyle ??
            state?.theme?.buttonTextStyle,
          buttonCartIcon:
            action?.payload?.theme?.buttonCartIcon ??
            state?.theme?.buttonCartIcon,
          logoUrl: action?.payload?.theme?.logoUrl ?? state?.theme?.logoUrl,
          fontHeadingBold:
            action?.payload?.theme?.fontHeadingBold ??
            state?.theme?.fontHeadingBold,
          fontHeadingRegular:
            action?.payload?.theme?.fontHeadingRegular ??
            state?.theme?.fontHeadingRegular,
          fontBodyBold:
            action?.payload?.theme?.fontBodyBold ?? state?.theme?.fontBodyBold,
          fontBodyRegular:
            action?.payload?.theme?.fontBodyRegular ??
            state?.theme?.fontBodyRegular,
        },
      };
    case ReducerAction.RESET_THEME:
      return {
        ...state,
        theme: initialMobilePreview.theme,
      };
    case ReducerAction.RESET:
      return initialMobilePreview;
    default:
      assertNever(action.type, "Invalid mobile preview state");
  }
}

const StateContext = createContext<MobilePreviewState>(initialMobilePreview);
const DispatchContext =
  createContext<React.Dispatch<MobilePreviewAction> | null>(null);

export const MobilePreviewProviders = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [state, dispatch] = useReducer(reducer, initialMobilePreview);

  return (
    <DispatchContext.Provider value={useMemo(() => dispatch, [dispatch])}>
      <StateContext.Provider value={useMemo(() => state, [state])}>
        {children}
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export function useMobilePreviewState() {
  return useContext(StateContext);
}

export function useMobilePreviewDispatch(): React.Dispatch<MobilePreviewAction> {
  return nullthrows(
    useContext(DispatchContext),
    "Mobile Preview dispatch context is missing"
  );
}