import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import StorefrontIcon from "@mui/icons-material/Storefront";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  ListItem,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useContext, useRef, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { CurrentUserUpdateActiveBrandMutation } from "./__generated__/CurrentUserUpdateActiveBrandMutation.graphql";
import { CurrentUser_userFragment$key } from "./__generated__/CurrentUser_userFragment.graphql";
import CurrentUserContext from "../../contexts/CurrentUserContext";

const mutation = graphql`
  mutation CurrentUserUpdateActiveBrandMutation($input: UserTypeInputPartial!) {
    updateActiveBrandProfile(input: $input) {
      ... on UserType {
        id
        activeBrand {
          id
        }
      }
    }
  }
`;

const userQuery = graphql`
  fragment CurrentUser_userFragment on UserType {
    id
    email
    firstName
    lastName
    brandAdminProfiles {
      id
      brand {
        id
        displayName
      }
    }
  }
`;

type Props = {
  user: CurrentUser_userFragment$key;
  onDrawerClose: () => void;
};

const CurrentUser = (props: Props) => {
  const theme = useTheme();
  const { user } = props;
  const userData = useFragment(userQuery, user);
  const fullName = userData.firstName + " " + userData.lastName;
  const navigate = useNavigate();

  const email = userData.email;
  const userBrandProfiles = userData.brandAdminProfiles;

  const currentUserContext = useContext(CurrentUserContext);
  const activeBrandID = currentUserContext?.activeBrandID;
  const setActiveBrandID = currentUserContext?.setActiveBrandID;

  const [changeProfile, isProfileChanging] =
    useMutation<CurrentUserUpdateActiveBrandMutation>(mutation);

  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = () => {
    setAnchorEl(ref.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (newBrandID: string) => {
    changeProfile({
      variables: {
        input: {
          id: userData.id,
          activeBrand: {
            id: newBrandID,
          },
        },
      },
    });
    setActiveBrandID && setActiveBrandID(newBrandID);
  };
  return (
    <Box>
      <Box
        ref={ref}
        sx={{
          border: "1px solid rgba(90, 98, 184, 0.10)",
          width: "100%",
          borderRadius: "8px",
          p: 1,
          "&:hover": {
            cursor: "pointer",
          },
          display: "flex",
          alignItems: "center",
        }}
        onClick={handleClick}
      >
        <StorefrontIcon
          sx={{
            color: theme.palette.primary.main,
            mr: 1,
          }}
        />
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontWeight: 600,
            letterSpacing: "0.02857em",
            fontSize: "0.875rem",
            display: {
              xs: "none",
              sm: "block",
            },
          }}
        >
          {
            userBrandProfiles.find(
              (brand_profile) => brand_profile.brand.id === activeBrandID
            )?.brand.displayName
          }
        </Typography>
        <KeyboardArrowDownIcon />
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isProfileChanging}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <CircularProgress
            sx={{
              color: "#5048E5",
            }}
          />
        </div>
      </Backdrop>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          slotProps={{
            paper: {
              sx: {
                mt: 1,
                borderRadius: "8px",
                minWidth: "248px",
              },
            },
          }}
        >
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  color={"black"}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                    letterSpacing: "0.02857em",
                    fontSize: "0.875rem",
                    fontWeight: 600,
                  }}
                >
                  {fullName}
                </Typography>
              }
              secondary={
                <Typography
                  variant="body2"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {email}
                </Typography>
              }
            />
          </ListItem>
          <Divider sx={{ mb: 1 }} />
          {userBrandProfiles.map((brand_profile, index) => {
            const brandID = brand_profile.brand.id;
            return (
              <MenuItem
                value={brandID}
                key={index}
                selected={activeBrandID === brandID}
                onClick={() => {
                  handleChange(brandID);
                  props.onDrawerClose();
                }}
              >
                <Typography
                  sx={{
                    py: 1,
                    letterSpacing: "0.02857em",
                    fontSize: "0.875rem",
                    fontWeight: 600,
                    color:
                      activeBrandID === brandID
                        ? theme.palette.primary.main
                        : "black",
                  }}
                >
                  {brand_profile.brand.displayName}
                </Typography>
              </MenuItem>
            );
          })}
          <Divider />
          <MenuItem
            sx={{
              borderRadius: 0,
              width: "100%",
            }}
            onClick={() => navigate("/logout")}
          >
            <LogoutIcon
              sx={{
                mr: 1,
              }}
            />
            <Typography
              sx={{
                py: 1,
                letterSpacing: "0.02857em",
                fontSize: "0.875rem",
                fontWeight: 600,
              }}
            >
              Log Out
            </Typography>
          </MenuItem>
        </Popover>
      </Backdrop>
    </Box>
  );
};

export default CurrentUser;
