/**
 * @generated SignedSource<<47dd4e2506a9d05deefa4bcfab9f6448>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type InternBrandAdsAnalysisQuery$variables = {
  id: string;
};
export type InternBrandAdsAnalysisQuery$data = {
  readonly brand: {
    readonly id: string;
    readonly internAdsAnalysis: {
      readonly top: ReadonlyArray<{
        readonly clicks: string;
        readonly copy: string;
        readonly id: string;
        readonly impressions: string;
        readonly media: string | null;
        readonly mediaType: string | null;
        readonly roas: {
          readonly actionType: string;
          readonly value: string;
        } | null;
      }>;
    };
  };
};
export type InternBrandAdsAnalysisQuery = {
  response: InternBrandAdsAnalysisQuery$data;
  variables: InternBrandAdsAnalysisQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "BrandType",
    "kind": "LinkedField",
    "name": "brand",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "InternAdAnalysisContainer",
        "kind": "LinkedField",
        "name": "internAdsAnalysis",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InternAdAnalysis",
            "kind": "LinkedField",
            "name": "top",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "copy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "InternAdRoasAnalysis",
                "kind": "LinkedField",
                "name": "roas",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "actionType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "media",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mediaType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "impressions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clicks",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InternBrandAdsAnalysisQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InternBrandAdsAnalysisQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d2ca0e27ca906f47b73220ec44db42e1",
    "id": null,
    "metadata": {},
    "name": "InternBrandAdsAnalysisQuery",
    "operationKind": "query",
    "text": "query InternBrandAdsAnalysisQuery(\n  $id: ID!\n) {\n  brand(id: $id) {\n    id\n    internAdsAnalysis {\n      top {\n        id\n        copy\n        roas {\n          actionType\n          value\n        }\n        media\n        mediaType\n        impressions\n        clicks\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5e0e4dbf5e7347339282d03ada692acc";

export default node;
