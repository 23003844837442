import EggIcon from "@mui/icons-material/Egg";
import EggAltIcon from "@mui/icons-material/EggAlt";
import {
  Box,
  CardMedia,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import {
  InternBrandAdsAnalysisQuery,
  InternBrandAdsAnalysisQuery$data,
} from "./__generated__/InternBrandAdsAnalysisQuery.graphql";
import nullthrows from "../../../utils/nullthrows";

const query = graphql`
  query InternBrandAdsAnalysisQuery($id: ID!) {
    brand(id: $id) {
      id
      internAdsAnalysis {
        top {
          id
          copy
          roas {
            actionType
            value
          }
          media
          mediaType
          impressions
          clicks
        }
      }
    }
  }
`;

const InternBrandAdsAnalysisCard = ({
  analysis,
}: {
  analysis: InternBrandAdsAnalysisQuery$data["brand"]["internAdsAnalysis"]["top"][0];
}) => {
  const [isImageALoading, setIsImageALoading] = useState(false);
  const [isCopyALoading, setIsCopyALoading] = useState(false);

  const [imageAnalysis, setImageAnalysis] = useState(null);
  const [copyAnalysis, setCopyAnalysis] = useState<{
    phrase: string;
    product: string;
    tags: Array<string>;
    tone: string;
  } | null>(null);
  const mediaType = analysis.mediaType;
  const media = analysis.media;
  const text = analysis.copy;

  const mediaComp =
    mediaType === "IMAGE" ? (
      <img alt="" width={200} src={media ?? ""} loading="lazy" />
    ) : mediaType === "VIDEO" ? (
      <video key={media} width={200} controls>
        <source src={media ?? ""} />
      </video>
    ) : null;

  // const copyA = analysis.copyAnalysis;

  const onAnalyzeImage = () => {
    setIsImageALoading(true);
    if (mediaType === "IMAGE") {
      fetch("/api/v1/intern/ads/image-analysis", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: media,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setIsImageALoading(false);
          setImageAnalysis(data);
        });
    } else if (mediaType === "VIDEO") {
      // TODO
    }
  };

  const onAnalyzeCopy = () => {
    setIsCopyALoading(true);
    fetch("/api/v1/intern/ads/copy-analysis", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: text,
      }),
    })
      .catch((error) => {
        console.log(error);
        setIsCopyALoading(false);
      })
      .then((res) => res?.json())
      .then((data) => {
        console.log(data);
        setIsCopyALoading(false);
        setCopyAnalysis(data?.["analysis"]);
      });
  };

  console.log(imageAnalysis);
  console.log(copyAnalysis);

  return (
    <>
      <Stack
        sx={{
          m: 1,
          backgroundColor: "white",
          borderRadius: 2,
          overflow: "hidden",
          p: 3,
        }}
      >
        <Stack direction={"row"} spacing={2}>
          {mediaComp}
          <Stack>
            <Typography variant="subtitle2">
              <b>ID: </b> {analysis.id}
            </Typography>
            <Typography variant="subtitle2">
              <b>Copy: </b> {analysis.copy}
            </Typography>
            <Typography variant="subtitle2">
              <b>ROAS: </b> {analysis.roas?.value || "NULL"}
            </Typography>
            <Typography variant="subtitle2">
              <b>Impressions: </b> {analysis.impressions}
            </Typography>
            <Typography variant="subtitle2">
              <b>Clicks: </b> {analysis.clicks}
            </Typography>
          </Stack>
        </Stack>

        <Box
          sx={{
            padding: 3,
            m: 2,
            borderRadius: "8px",
            border: "1px solid #c7d0d4",
            backgroundColor: "white",
          }}
        >
          <Stack direction={"row"} display="flex" alignItems="center">
            <Typography variant="subtitle2">
              <b>Copy Analysis </b>
            </Typography>
            {!copyAnalysis && (
              <Tooltip title={"Click for more info"} placement="right" arrow>
                <IconButton
                  disabled={isCopyALoading}
                  onClick={onAnalyzeCopy}
                  size={"small"}
                >
                  <EggAltIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>

          {copyAnalysis && (
            <>
              <Typography variant="subtitle2">
                <b>Phrase: </b> {copyAnalysis?.["phrase"]}
              </Typography>
              <Typography variant="subtitle2">
                <b>Product: </b> {copyAnalysis?.["product"]}
              </Typography>
              <Typography variant="subtitle2">
                <b>Tags: </b> {(copyAnalysis?.["tags"] ?? []).join(", ")}
              </Typography>
              <Typography variant="subtitle2">
                <b>Tone: </b> {copyAnalysis?.["tone"]}
              </Typography>
            </>
          )}

          {isCopyALoading && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          )}
        </Box>

        {media && (
          <Box
            sx={{
              padding: 3,
              m: 2,
              borderRadius: "8px",
              border: "1px solid #c7d0d4",
              backgroundColor: "white",
            }}
          >
            <Stack direction={"row"} display="flex" alignItems="center">
              <Typography variant="subtitle2">
                <b>Image Analysis </b>{" "}
              </Typography>
              {!imageAnalysis && (
                <Tooltip title={"Click for more info"} placement="right" arrow>
                  <IconButton
                    disabled={isImageALoading}
                    onClick={onAnalyzeImage}
                    size={"small"}
                  >
                    <EggIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
            {imageAnalysis && (
              <Stack>
                <Typography variant="subtitle2">
                  <b>Summary: </b> {imageAnalysis?.["analysis"]?.["summary"]}
                </Typography>
                <Typography variant="subtitle2">
                  <b>Analysis: </b> {imageAnalysis?.["analysis"]?.["analysis"]}
                </Typography>
              </Stack>
            )}
            {isImageALoading && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            )}
          </Box>
        )}
      </Stack>
    </>
  );
};

const InternBrandAdsAnalysis = ({ brandID }: { brandID: string }) => {
  const data = useLazyLoadQuery<InternBrandAdsAnalysisQuery>(query, {
    id: nullthrows(brandID),
  });

  const topComponent = data.brand.internAdsAnalysis.top.map((t) => {
    return <InternBrandAdsAnalysisCard analysis={t} />;
  });

  return (
    <Container maxWidth="md">
      <Typography variant="h6">Top 5</Typography>
      {topComponent}
    </Container>
  );
};

export default InternBrandAdsAnalysis;
