import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button, Stack, Switch, TextField, Typography } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useFragment } from "react-relay";
import * as yup from "yup";
import { CookiesCardCustomInput_trackingConfig$key } from "./__generated__/CookiesCardCustomInput_trackingConfig.graphql";

const brandFragment = graphql`
  fragment CookiesCardCustomInput_trackingConfig on GraphQLBrandTrackingConfig {
    consentSettings {
      name
      enabled
    }
    nonConsentSettings {
      name
      enabled
    }
    consentSessionStorageSettings {
      name
      enabled
    }
    nonConsentSessionStorageSettings {
      name
      enabled
    }
    consentLocalStorageSettings {
      name
      enabled
    }
    nonConsentLocalStorageSettings {
      name
      enabled
    }
  }
`;

const schema = yup
  .object({
    customConsentSettings: yup
      .array()
      .of(
        yup.object({
          key: yup.string().required("Required").trim(),
          value: yup.boolean().required("Required"),
        })
      )
      .required(),
    customNonConsentSettings: yup
      .array()
      .of(
        yup.object({
          key: yup.string().required("Required").trim(),
          value: yup.boolean().required("Required"),
        })
      )
      .required(),
    customConsentSessionStorageSettings: yup
      .array()
      .of(
        yup.object({
          key: yup.string().required("Required").trim(),
          value: yup.boolean().required("Required"),
        })
      )
      .required(),
    customNonConsentSessionStorageSettings: yup
      .array()
      .of(
        yup.object({
          key: yup.string().required("Required").trim(),
          value: yup.boolean().required("Required"),
        })
      )
      .required(),
    customConsentLocalStorageSettings: yup
      .array()
      .of(
        yup.object({
          key: yup.string().required("Required").trim(),
          value: yup.boolean().required("Required"),
        })
      )
      .required(),
    customNonConsentLocalStorageSettings: yup
      .array()
      .of(
        yup.object({
          key: yup.string().required("Required").trim(),
          value: yup.boolean().required("Required"),
        })
      )
      .required(),
  })
  .required();
type FormValues = yup.InferType<typeof schema>;

const CookiesCardCustomInput = ({
  configFragment,
}: {
  configFragment: CookiesCardCustomInput_trackingConfig$key | null;
}) => {
  const config = useFragment(brandFragment, configFragment);
  const consentSettings = config?.consentSettings ?? [];
  const nonConsentSettings = config?.nonConsentSettings ?? [];
  const consentSessionStorageSettings =
    config?.consentSessionStorageSettings ?? [];
  const nonConsentSessionStorageSettings =
    config?.nonConsentSessionStorageSettings ?? [];
  const consentLocalStorageSettings = config?.consentLocalStorageSettings ?? [];
  const nonConsentLocalStorageSettings =
    config?.nonConsentLocalStorageSettings ?? [];

  const {
    register,
    control,
    formState: { errors },
    reset,
  } = useFormContext<FormValues>();

  const {
    fields: consentFields,
    remove: consentRemoveItem,
    append: consentAppendItem,
  } = useFieldArray({
    control,
    name: "customConsentSettings",
  });

  const {
    fields: nonConsentFields,
    remove: nonConsentRemoveItem,
    append: nonConsentAppendItem,
  } = useFieldArray({
    control,
    name: "customNonConsentSettings",
  });

  const {
    fields: consentSessionStorageFields,
    remove: consentSessionStorageRemoveItem,
    append: consentSessionStorageAppendItem,
  } = useFieldArray({
    control,
    name: "customConsentSessionStorageSettings",
  });

  const {
    fields: nonConsentSessionStorageFields,
    remove: nonConsentSessionStorageRemoveItem,
    append: nonConsentSessionStorageAppendItem,
  } = useFieldArray({
    control,
    name: "customNonConsentSessionStorageSettings",
  });

  const {
    fields: consentLocalStorageFields,
    remove: consentLocalStorageFieldsRemoveItem,
    append: consentLocalStorageFieldsAppendItem,
  } = useFieldArray({
    control,
    name: "customConsentLocalStorageSettings",
  });

  const {
    fields: nonConsentLocalStorageFields,
    remove: nonConsentLocalStorageFieldsRemoveItem,
    append: nonConsentLocalStorageFieldsAppendItem,
  } = useFieldArray({
    control,
    name: "customNonConsentLocalStorageSettings",
  });

  useEffect(() => {
    reset({
      customConsentSettings: consentSettings.map((s) => ({
        key: s.name,
        value: s.enabled,
      })),
      customNonConsentSettings: nonConsentSettings.map((s) => ({
        key: s.name,
        value: s.enabled,
      })),
      customConsentSessionStorageSettings: consentSessionStorageSettings.map(
        (s) => ({
          key: s.name,
          value: s.enabled,
        })
      ),
      customNonConsentSessionStorageSettings:
        nonConsentSessionStorageSettings.map((s) => ({
          key: s.name,
          value: s.enabled,
        })),
      customConsentLocalStorageSettings: consentLocalStorageSettings.map(
        (s) => ({
          key: s.name,
          value: s.enabled,
        })
      ),
      customNonConsentLocalStorageSettings: nonConsentLocalStorageSettings.map(
        (s) => ({
          key: s.name,
          value: s.enabled,
        })
      ),
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const consentSettingInputs = consentFields.map((item, index) => (
    <Stack
      direction="row"
      spacing={1}
      key={item.id}
      alignItems={"center"}
      mt={1}
    >
      <TextField
        fullWidth
        {...register(`customConsentSettings.${index}.key` as const)}
        defaultValue={consentSettings?.find((s) => s.name === item.key)?.name}
        label={"Setting"}
        error={!!errors?.customConsentSettings?.[index]?.key}
        helperText={errors?.customConsentSettings?.[index]?.key?.message}
      />
      <Controller
        render={(props) => (
          <Switch
            checked={props.field.value}
            onChange={(_, data) => props.field.onChange(data)}
          />
        )}
        name={`customConsentSettings.${index}.value` as const}
        control={control}
      />

      <Button
        sx={{
          maxHeight: "56px",
        }}
        variant="text"
        color="error"
        onClick={() => {
          consentRemoveItem(index);
        }}
      >
        Delete
      </Button>
    </Stack>
  ));

  const nonConsentSettingInputs = nonConsentFields.map((item, index) => (
    <Stack
      direction="row"
      spacing={1}
      key={item.id}
      alignItems={"center"}
      mt={1}
    >
      <TextField
        fullWidth
        {...register(`customNonConsentSettings.${index}.key` as const)}
        label={"Setting"}
        error={!!errors?.customNonConsentSettings?.[index]?.key}
        helperText={errors?.customNonConsentSettings?.[index]?.key?.message}
      />
      <Controller
        render={(props) => (
          <Switch
            checked={props.field.value}
            onChange={(_, data) => props.field.onChange(data)}
          />
        )}
        name={`customNonConsentSettings.${index}.value` as const}
        control={control}
      />
      <Button
        sx={{
          maxHeight: "56px",
        }}
        variant="text"
        color="error"
        onClick={() => {
          nonConsentRemoveItem(index);
        }}
      >
        Delete
      </Button>
    </Stack>
  ));

  const consentSessionStorageSettingInputs = consentSessionStorageFields.map(
    (item, index) => (
      <Stack
        direction="row"
        spacing={1}
        key={item.id}
        alignItems={"center"}
        mt={1}
      >
        <TextField
          fullWidth
          {...register(
            `customConsentSessionStorageSettings.${index}.key` as const
          )}
          defaultValue={consentSettings?.find((s) => s.name === item.key)?.name}
          label={"Setting"}
          error={!!errors?.customConsentSessionStorageSettings?.[index]?.key}
          helperText={
            errors?.customConsentSessionStorageSettings?.[index]?.key?.message
          }
        />
        <Controller
          render={(props) => (
            <Switch
              checked={props.field.value}
              onChange={(_, data) => props.field.onChange(data)}
            />
          )}
          name={`customConsentSessionStorageSettings.${index}.value` as const}
          control={control}
        />

        <Button
          sx={{
            maxHeight: "56px",
          }}
          variant="text"
          color="error"
          onClick={() => {
            consentSessionStorageRemoveItem(index);
          }}
        >
          Delete
        </Button>
      </Stack>
    )
  );

  const nonConsentSessionStorageSettingInputs =
    nonConsentSessionStorageFields.map((item, index) => (
      <Stack
        direction="row"
        spacing={1}
        key={item.id}
        alignItems={"center"}
        mt={1}
      >
        <TextField
          {...register(
            `customNonConsentSessionStorageSettings.${index}.key` as const
          )}
          fullWidth
          label={"Setting"}
          error={!!errors?.customNonConsentSessionStorageSettings?.[index]?.key}
          helperText={
            errors?.customNonConsentSessionStorageSettings?.[index]?.key
              ?.message
          }
        />
        <Controller
          render={(props) => (
            <Switch
              checked={props.field.value}
              onChange={(_, data) => props.field.onChange(data)}
            />
          )}
          name={
            `customNonConsentSessionStorageSettings.${index}.value` as const
          }
          control={control}
        />
        <Button
          sx={{
            maxHeight: "56px",
          }}
          variant="text"
          color="error"
          onClick={() => {
            nonConsentSessionStorageRemoveItem(index);
          }}
        >
          Delete
        </Button>
      </Stack>
    ));

  const consentLocalStorageSettingInputs = consentLocalStorageFields.map(
    (item, index) => (
      <Stack
        direction="row"
        spacing={1}
        key={item.id}
        alignItems={"center"}
        mt={1}
      >
        <TextField
          {...register(
            `customConsentLocalStorageSettings.${index}.key` as const
          )}
          fullWidth
          label={"Setting"}
          error={!!errors?.customConsentLocalStorageSettings?.[index]?.key}
          helperText={
            errors?.customConsentLocalStorageSettings?.[index]?.key?.message
          }
        />
        <Controller
          render={(props) => (
            <Switch
              checked={props.field.value}
              onChange={(_, data) => props.field.onChange(data)}
            />
          )}
          name={`customConsentLocalStorageSettings.${index}.value` as const}
          control={control}
        />
        <Button
          sx={{
            maxHeight: "56px",
          }}
          variant="text"
          color="error"
          onClick={() => {
            consentLocalStorageFieldsRemoveItem(index);
          }}
        >
          Delete
        </Button>
      </Stack>
    )
  );

  const nonConsentLocalStorageSettingInputs = nonConsentLocalStorageFields.map(
    (item, index) => (
      <Stack
        direction="row"
        spacing={1}
        key={item.id}
        alignItems={"center"}
        mt={1}
      >
        <TextField
          {...register(
            `customNonConsentLocalStorageSettings.${index}.key` as const
          )}
          fullWidth
          label={"Setting"}
          error={!!errors?.customNonConsentLocalStorageSettings?.[index]?.key}
          helperText={
            errors?.customNonConsentLocalStorageSettings?.[index]?.key?.message
          }
        />
        <Controller
          render={(props) => (
            <Switch
              checked={props.field.value}
              onChange={(_, data) => props.field.onChange(data)}
            />
          )}
          name={`customNonConsentLocalStorageSettings.${index}.value` as const}
          control={control}
        />
        <Button
          sx={{
            maxHeight: "56px",
          }}
          variant="text"
          color="error"
          onClick={() => {
            nonConsentLocalStorageFieldsRemoveItem(index);
          }}
        >
          Delete
        </Button>
      </Stack>
    )
  );

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
          Consent Settings
        </Typography>
        {consentSettingInputs}
        <Button
          fullWidth={true}
          variant="outlined"
          onClick={() => {
            consentAppendItem({
              key: "",
              value: false,
            });
          }}
        >
          <AddCircleOutlineIcon sx={{ mr: 1 }} />
          Add Item
        </Button>
      </Stack>
      <Stack spacing={1}>
        <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
          Non-Consent Settings
        </Typography>
        {nonConsentSettingInputs}
        <Button
          fullWidth={true}
          variant="outlined"
          onClick={() => {
            nonConsentAppendItem({
              key: "",
              value: false,
            });
          }}
        >
          <AddCircleOutlineIcon sx={{ mr: 1 }} />
          Add Item
        </Button>
      </Stack>

      <Stack spacing={1}>
        <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
          Consent Session Storage Settings
        </Typography>
        {consentSessionStorageSettingInputs}
        <Button
          fullWidth={true}
          variant="outlined"
          onClick={() => {
            consentSessionStorageAppendItem({
              key: "",
              value: false,
            });
          }}
        >
          <AddCircleOutlineIcon sx={{ mr: 1 }} />
          Add Item
        </Button>
      </Stack>
      <Stack spacing={1}>
        <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
          Non-Consent Session Storage Settings
        </Typography>
        {nonConsentSessionStorageSettingInputs}
        <Button
          fullWidth={true}
          variant="outlined"
          onClick={() => {
            nonConsentSessionStorageAppendItem({
              key: "",
              value: false,
            });
          }}
        >
          <AddCircleOutlineIcon sx={{ mr: 1 }} />
          Add Item
        </Button>
      </Stack>

      <Stack spacing={1}>
        <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
          Consent Local Storage Settings
        </Typography>
        {consentLocalStorageSettingInputs}
        <Button
          fullWidth={true}
          variant="outlined"
          onClick={() => {
            consentLocalStorageFieldsAppendItem({
              key: "",
              value: false,
            });
          }}
        >
          <AddCircleOutlineIcon sx={{ mr: 1 }} />
          Add Item
        </Button>
      </Stack>
      <Stack spacing={1}>
        <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
          Non-Consent Local Storage Settings
        </Typography>
        {nonConsentLocalStorageSettingInputs}
        <Button
          fullWidth={true}
          variant="outlined"
          onClick={() => {
            nonConsentLocalStorageFieldsAppendItem({
              key: "",
              value: false,
            });
          }}
        >
          <AddCircleOutlineIcon sx={{ mr: 1 }} />
          Add Item
        </Button>
      </Stack>
    </Stack>
  );
};

export default CookiesCardCustomInput;
