/**
 * @generated SignedSource<<3bceee143e905e972348adf71657c861>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavigationTab_brand$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CartCard_brand" | "GorgiasCard_brand" | "MenuCard_brand" | "ShortcutsCard_brand" | "TabsCard2_brand" | "WishlistCard_brand">;
  readonly " $fragmentType": "NavigationTab_brand";
};
export type NavigationTab_brand$key = {
  readonly " $data"?: NavigationTab_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavigationTab_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavigationTab_brand",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CartCard_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GorgiasCard_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MenuCard_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShortcutsCard_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TabsCard2_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WishlistCard_brand"
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "9afd07b4c6e4b52b3acf950b680d7dba";

export default node;
