import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useMobilePreviewState } from "../../contexts/MobilePreviewContext";

const NotificationPreview = () => {
  const theme = useTheme();
  const { notification } = useMobilePreviewState();

  return (
    <Box
      sx={{
        borderRadius: "12px",
        border: "3.75px solid #DBDCDD",
        justifyContent: "center",
        overflow: "hidden",
        alignContent: "center",
        height: "650px",
        alignItems: "center",
        backgroundColor: "white",
        mt: 2,
        position: "relative",
      }}
      id="drawer-container"
    >
      <Stack sx={{}}>
        <Stack
          sx={{
            backgroundColor: theme.palette.navigationBackground.main,
            color: theme.palette.navigationForeground.main,
            height: "56px",
            padding: "12px",
            width: "100%",
            borderBottom: "1px solid #F1F1F1",
          }}
          direction="row"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography variant="header1">Notifications</Typography>
          <IconButton
            sx={{
              position: "absolute",
              right: "12px",
            }}
            color="inherit"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography
          sx={{
            p: 1,
          }}
          variant="header1"
        >
          New
        </Typography>
        <ListItem
          sx={{
            borderBottom: "1px solid #F1F1F1",
          }}
        >
          {notification?.media && (
            <ListItemAvatar
              sx={{
                borderRadius: "12px",
              }}
            >
              <Avatar alt="" src={notification?.media} variant="rounded" />
            </ListItemAvatar>
          )}
          <ListItemText
            primary={
              <Typography
                color={"black"}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {notification.title || "Notification Title"}
              </Typography>
            }
            secondary={
              <>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    paddingY: "2px",
                    WebkitBoxOrient: "vertical",
                    fontSize: "14px",
                  }}
                  lineHeight={"normal"}
                >
                  {notification.body || "Notification Description"}
                </Typography>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                    fontSize: "12px",
                    color: "rgba(0,0,0,0.4)",
                  }}
                >
                  Just now
                </Typography>
              </>
            }
          />
        </ListItem>
      </Stack>
    </Box>
  );
};

export default NotificationPreview;
