/**
 * @generated SignedSource<<52aefe032740475c9df37262b485f501>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MediaType = "PHOTO" | "VIDEO" | "WEB_MUX_VIDEO" | "%future added value";
export type GraphQLHighlightInput = {
  caption?: string | null;
  destinationUrl?: string | null;
  highlightAlbum?: NodeInput | null;
  isNotifEnabled: boolean;
  mediaType?: MediaType | null;
  mediaUploadable?: any | null;
  muxVideoId?: string | null;
  notifSubtitle?: string | null;
  notifTitle?: string | null;
  notifUtmCampaign?: string | null;
  publishedAt?: any | null;
};
export type NodeInput = {
  id: string;
};
export type HighlightManageFormCreateMutation$variables = {
  input: GraphQLHighlightInput;
};
export type HighlightManageFormCreateMutation$data = {
  readonly createHighlight: {
    readonly archivedAt: any | null;
    readonly caption: string | null;
    readonly destinationUrl: string | null;
    readonly highlightAlbum: {
      readonly coverImageUrl: string | null;
      readonly id: string;
    };
    readonly id: string;
    readonly media: {
      readonly coverImageUrl: string | null;
      readonly isProcessing: boolean;
      readonly type: MediaType;
      readonly url: string;
    } | null;
    readonly notification: {
      readonly body: string;
      readonly id: string;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"NotificationTableRow_notification">;
    } | null;
    readonly publishedAt: any;
  };
};
export type HighlightManageFormCreateMutation = {
  response: HighlightManageFormCreateMutation$data;
  variables: HighlightManageFormCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "caption",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archivedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destinationUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverImageUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "GraphQLHighlightAlbum",
  "kind": "LinkedField",
  "name": "highlightAlbum",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "GraphQLMedia",
  "kind": "LinkedField",
  "name": "media",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isProcessing",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v12 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HighlightManageFormCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLHighlight",
        "kind": "LinkedField",
        "name": "createHighlight",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLNotification",
            "kind": "LinkedField",
            "name": "notification",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NotificationTableRow_notification"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HighlightManageFormCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLHighlight",
        "kind": "LinkedField",
        "name": "createHighlight",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLNotification",
            "kind": "LinkedField",
            "name": "notification",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "audienceLanguages",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "automaticArchiveTimestamp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thumbnailUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "externalThumbnailUri",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isArchived",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "landingPath",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timestamp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "utmCampaign",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLCDPAudience",
                "kind": "LinkedField",
                "name": "audiences",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "audienceName",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLDailyDeal",
                "kind": "LinkedField",
                "name": "dailyDeal",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLFeedPost",
                "kind": "LinkedField",
                "name": "feedPost",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLHighlight",
                "kind": "LinkedField",
                "name": "highlight",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphQLHighlightAlbum",
                    "kind": "LinkedField",
                    "name": "highlightAlbum",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a28f0d7a10007244e95fb29b22c0c542",
    "id": null,
    "metadata": {},
    "name": "HighlightManageFormCreateMutation",
    "operationKind": "mutation",
    "text": "mutation HighlightManageFormCreateMutation(\n  $input: GraphQLHighlightInput!\n) {\n  createHighlight(input: $input) {\n    id\n    caption\n    archivedAt\n    publishedAt\n    destinationUrl\n    highlightAlbum {\n      id\n      coverImageUrl\n    }\n    media {\n      type\n      url\n      coverImageUrl\n      isProcessing\n    }\n    notification {\n      title\n      body\n      id\n      ...NotificationTableRow_notification\n    }\n  }\n}\n\nfragment NotificationTableRow_notification on GraphQLNotification {\n  id\n  uuid\n  audienceLanguages\n  automaticArchiveTimestamp\n  body\n  thumbnailUrl\n  externalThumbnailUri\n  isArchived\n  landingPath\n  timestamp\n  title\n  utmCampaign\n  audiences {\n    audienceName\n    id\n  }\n  dailyDeal {\n    id\n  }\n  feedPost {\n    id\n  }\n  highlight {\n    id\n    highlightAlbum {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5dc4f582f995a9d15a7551440cd92e8";

export default node;
