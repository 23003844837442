import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useContext } from "react";
import { useFragment, useMutation } from "react-relay";
import {
  BrandInputPartial,
  useBrandUpdateMutation$data,
  useBrandUpdateMutation as useBrandUpdateMutationType,
} from "./__generated__/useBrandUpdateMutation.graphql";
import { useBrandUpdateMutation_brand$key } from "./__generated__/useBrandUpdateMutation_brand.graphql";
import SnackbarContext from "../contexts/SnackbarContext";

const mutation = graphql`
  mutation useBrandUpdateMutation($input: BrandInputPartial!) {
    updateBrand(input: $input) {
      ... on BrandType {
        id
        shopifyConfig {
          multipassSecret
        }
        facebookAppId
        facebookClientToken
        gorgiasId
        klaviyoApiToken
        kustomerApiToken
        rechargeApiToken
        email
        showIosTosAlert
        appsflyerDevKey
      }
    }
  }
`;

const fragment = graphql`
  fragment useBrandUpdateMutation_brand on BrandType {
    id
  }
`;

const useBrandUpdateMutation = (
  brand: useBrandUpdateMutation_brand$key
): [
  (
    input: BrandInputPartial,
    onCompleted?: (data: useBrandUpdateMutation$data) => void
  ) => void,
  boolean
] => {
  const brandData = useFragment(fragment, brand);
  const brandID = brandData.id;

  const [createMutation, isMutationInFlight] =
    useMutation<useBrandUpdateMutationType>(mutation);
  const snackbarContext = useContext(SnackbarContext);

  const onMutate = useCallback(
    (
      input: BrandInputPartial,
      onCompleted?: (data: useBrandUpdateMutation$data) => void
    ) => {
      createMutation({
        variables: {
          input: {
            id: brandID,
            ...input,
          },
        },
        onCompleted: (data, errors) => {
          if (errors) {
            snackbarContext?.openSnackbar("Update Failed", "error");
          } else {
            onCompleted && onCompleted(data);
            snackbarContext?.openSnackbar("Update Successful", "success");
          }
        },
        onError: (error: Error) => {
          snackbarContext?.openSnackbar("Update Failed", "error");
        },
      });
    },
    [snackbarContext, createMutation, brandID]
  );

  return [onMutate, isMutationInFlight];
};

export default useBrandUpdateMutation;
