import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { AppViewType } from "../views/dashboard/build_section/mapping/__generated__/AppLayoutCard_brand.graphql";
import { validateUrl } from "../utils/validators";

export enum LandingPath {
  SHOP_TAB = "SHOP_TAB",
  FOR_YOU = "FOR_YOU",
  CHECKOUT = "CHECKOUT",
  CHECKOUT_CONFIRMATION = "CHECKOUT_CONFIRMATION",
  CUSTOM = "CUSTOM",
}

export const convertLandingPathToType = (
  landingPath: string,
  deeplinkUrlScheme: string
): LandingPath | null => {
  if (landingPath === "") {
    return null;
  }
  if (landingPath === `${deeplinkUrlScheme}://shop`) {
    return LandingPath.SHOP_TAB;
  } else if (landingPath === `${deeplinkUrlScheme}://for_you`) {
    return LandingPath.FOR_YOU;
  }
  return LandingPath.CUSTOM;
};

type LandingPathDetails = {
  label: string;
  finalPath: (landingPath: string, deeplinkUrlScheme: string) => string;
};

export const LANDING_PATHS: { [key in LandingPath]?: LandingPathDetails } = {
  [LandingPath.SHOP_TAB]: {
    label: "Shop Tab",
    finalPath: (_landingPath: string, deeplinkUrlScheme: string) =>
      `${deeplinkUrlScheme}://shop`,
  },
  [LandingPath.FOR_YOU]: {
    label: "For You",
    finalPath: (_landingPath: string, deeplinkUrlScheme: string) =>
      `${deeplinkUrlScheme}://for_you`,
  },
  [LandingPath.CUSTOM]: {
    label: "Custom URL",
    finalPath: (landingPath: string, _deeplinkUrlScheme: string) => landingPath,
  },
} as const;

type TriggerPathDetails = LandingPathDetails & {
  appViewType?: AppViewType;
};

export const TRIGGER_PATHS: { [key in LandingPath]?: TriggerPathDetails } = {
  [LandingPath.CHECKOUT]: {
    label: "Checkout",
    finalPath: (_landingPath: string, _deeplinkUrlScheme: string) => "",
    appViewType: "CHECKOUT",
  },
  [LandingPath.CHECKOUT_CONFIRMATION]: {
    label: "Checkout Confirmation",
    finalPath: (_landingPath: string, _deeplinkUrlScheme: string) => "",
    appViewType: "CHECKOUT_CONFIRMATION",
  },
  [LandingPath.FOR_YOU]: {
    label: "For You",
    finalPath: (_landingPath: string, deeplinkUrlScheme: string) =>
      `${deeplinkUrlScheme}://for_you`,
    appViewType: "FOR_YOU",
  },
  [LandingPath.CUSTOM]: {
    label: "Custom URL",
    finalPath: (landingPath: string, _deeplinkUrlScheme: string) => landingPath,
  },
} as const;

export const appDestinationLinkSchemaOptional = yup.object({
  pathType: yup.string(),
  customUrl: yup.string(),
});
export const appDestinationLinkSchema = (
  required: boolean = true,
  requiredText = "Destination type is required"
) => {
  let pathType = yup.string();
  if (required) {
    pathType = pathType.required(requiredText);
  }

  return yup.object({
    pathType,
    customUrl: yup
      .string()
      .max(2083, "URL is too long")
      .when("pathType", {
        is: (pathType: string) => {
          return pathType === LandingPath.CUSTOM;
        },
        then: (schema) =>
          schema
            .required("URL is required")
            .test("is-url-valid", "URL is not valid", (value) => {
              if (!value) return false;

              return validateUrl(value);
            }),
        otherwise: (schema) => schema,
      }),
  });
};

export type AppDestinationLinkFormValues = yup.InferType<
  ReturnType<typeof appDestinationLinkSchema>
>;

export const AppDestinationLink = <Type extends LandingPathDetails>({
  destinationTypeLabel = "Destination Type",
  customUrlLabel = "URL",
  landingPaths,
  fieldGroupName,
  allowReset = false,
}: {
  destinationTypeLabel?: string;
  customUrlLabel?: string;
  landingPaths: { [key in LandingPath]?: Type };
  fieldGroupName: string;
  allowReset?: boolean;
}) => {
  const {
    register,
    control,
    watch,
    resetField,
    formState: { errors },
  } = useFormContext<{
    [k: string]: AppDestinationLinkFormValues;
  }>();

  const landingPathType = watch(`${fieldGroupName}.pathType`);

  return (
    <>
      <Controller
        render={({ field }) => {
          const { onChange, ...fieldProps } = field;
          return (
            <FormControl fullWidth error={!!errors?.[fieldGroupName]?.pathType}>
              <InputLabel>{destinationTypeLabel}</InputLabel>
              <Select
                {...fieldProps}
                displayEmpty
                label={destinationTypeLabel}
                onChange={(event) => {
                  onChange(event);
                  resetField(`${fieldGroupName}.customUrl`, {
                    defaultValue: "",
                  });
                }}
                error={!!errors?.[fieldGroupName]?.pathType}
              >
                {allowReset && !!landingPathType && (
                  <MenuItem value={""}>Reset</MenuItem>
                )}
                {Object.entries(landingPaths).map(([type, details]) => {
                  return (
                    <MenuItem key={type} value={type}>
                      {details.label}
                    </MenuItem>
                  );
                })}
              </Select>
              {!!errors?.[fieldGroupName]?.pathType && (
                <FormHelperText>
                  {errors[fieldGroupName]?.pathType?.message}
                </FormHelperText>
              )}
            </FormControl>
          );
        }}
        control={control}
        name={`${fieldGroupName}.pathType`}
      />
      <TextField
        {...register(`${fieldGroupName}.customUrl`)}
        error={!!errors?.[fieldGroupName]?.customUrl}
        helperText={errors?.[fieldGroupName]?.customUrl?.message}
        margin="normal"
        label={customUrlLabel}
        variant="outlined"
        sx={{
          display: landingPathType === LandingPath.CUSTOM ? undefined : "none",
        }}
      />
    </>
  );
};
