/**
 * @generated SignedSource<<e7f54077479c2e6e70794b7439e918d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedTab_brand$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CDPAudienceFilters_brand" | "FeedPostForm_brand" | "FeedTabTable_refetchableBrand">;
  readonly " $fragmentType": "FeedTab_brand";
};
export type FeedTab_brand$key = {
  readonly " $data"?: FeedTab_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedTab_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedTab_brand",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedPostForm_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedTabTable_refetchableBrand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CDPAudienceFilters_brand"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "aeeb45e023070636ab0fab475143199d";

export default node;
