import { AppColorPaletteTransparent } from "../components/ColorDropdownSelector";

const getColorMapping = (
  palette: AppColorPaletteTransparent | null,
  primary: string,
  accent1: string,
  accent2: string,
  accent3: string
): string => {
  switch (palette) {
    case "PRIMARY":
      return primary;
    case "ACCENT_1":
      return accent1;
    case "ACCENT_2":
      return accent2;
    case "ACCENT_3":
      return accent3;
    case "BLACK":
      return "#000000";
    case "WHITE":
      return "#FFFFFF";
    case "GRAY":
      return "#ECECEC";
    case "TRANSPARENT":
      return "transparent";
    default:
      return "";
  }
};

export default getColorMapping;
