/**
 * @generated SignedSource<<ea5ea73cf44aaa819b791fe4d0061d9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodeInput = {
  id: string;
};
export type FacebookTab_deleteFacebookClientTokenMutation$variables = {
  input: NodeInput;
};
export type FacebookTab_deleteFacebookClientTokenMutation$data = {
  readonly deleteFacebookClientToken: {
    readonly " $fragmentSpreads": FragmentRefs<"FacebookTab_brand">;
  };
};
export type FacebookTab_deleteFacebookClientTokenMutation = {
  response: FacebookTab_deleteFacebookClientTokenMutation$data;
  variables: FacebookTab_deleteFacebookClientTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FacebookTab_deleteFacebookClientTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "deleteFacebookClientToken",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FacebookTab_brand"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FacebookTab_deleteFacebookClientTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "deleteFacebookClientToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLFacebookClientConfig",
            "kind": "LinkedField",
            "name": "facebookConfig",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLFacebookPage",
                "kind": "LinkedField",
                "name": "facebookPages",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isSelected",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33156b2b86b1827d576ba15f388fc7fc",
    "id": null,
    "metadata": {},
    "name": "FacebookTab_deleteFacebookClientTokenMutation",
    "operationKind": "mutation",
    "text": "mutation FacebookTab_deleteFacebookClientTokenMutation(\n  $input: NodeInput!\n) {\n  deleteFacebookClientToken(input: $input) {\n    ...FacebookTab_brand\n    id\n  }\n}\n\nfragment FacebookTab_brand on BrandType {\n  id\n  facebookConfig {\n    id\n    facebookPages {\n      id\n      name\n      isSelected\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9ecd640c67f9183e9380ae7a2e45d099";

export default node;
