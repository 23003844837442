import {
  Autocomplete,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { useFragment } from "react-relay";
import { GraphQLCDPAudienceInput } from "../mutations/__generated__/useFeedPostCreateMutation.graphql";
import {
  CDPAudienceSelector_brand$key,
  CDPType,
} from "./__generated__/CDPAudienceSelector_brand.graphql";
import { getCDPTypeNameMapping } from "../utils/cdpUtils";

enum KinnAudienceType {
  LOGGED_IN = "Logged In",
  LOGGED_OUT = "Logged Out",
}

const brandFragment = graphql`
  fragment CDPAudienceSelector_brand on BrandType {
    cdps {
      cdpType
      audiences {
        id
        name
        type
      }
    }
  }
`;

const CDPAudienceSelector = ({
  audienceIDs,
  brand: brandKey,
  onAudiencesChange,
  disabled,
}: {
  audienceIDs: string[];
  brand: CDPAudienceSelector_brand$key;
  onAudiencesChange: (audiences: GraphQLCDPAudienceInput[]) => void;
  disabled?: boolean;
}) => {
  const brand = useFragment(brandFragment, brandKey);
  const cdps = brand.cdps;

  const cdpAudiences = cdps.map((cdp) => {
    return cdp.audiences.map((audience) => ({
      ...audience,
      providerType: cdp.cdpType,
    }));
  });

  const allOptions = [
    ...cdpAudiences.flat().sort((a, b) => -b.type.localeCompare(a.type)),
  ];

  const value = allOptions.filter((option) => audienceIDs.includes(option.id));

  const [open, setOpen] = useState<boolean>(false);
  const [tab, setTab] = useState<CDPType>(value?.[0]?.providerType || "KINN");
  const onTabChange = (e: React.SyntheticEvent, newValue: string) => {
    setTab(newValue as CDPType);
  };

  return (
    <Autocomplete
      multiple
      fullWidth
      disabled={disabled}
      isOptionEqualToValue={(option, value) => {
        return (
          option.id === value.id && option.providerType === value.providerType
        );
      }}
      value={[...value]}
      options={allOptions}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      groupBy={(option) => option.type}
      getOptionLabel={(option) => option.name}
      getOptionDisabled={(option) => {
        // Disable Logged In if any other audience is selected
        if (
          option.providerType === "KINN" &&
          option.id === KinnAudienceType.LOGGED_IN
        ) {
          return (
            value.filter((v) => v.id !== KinnAudienceType.LOGGED_IN).length > 0
          );
        } else {
          // Disable all audiences if Logged In is selected
          return (
            value.filter(
              (v) =>
                v.id === KinnAudienceType.LOGGED_IN && v.providerType === "KINN"
            ).length > 0
          );
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={value.length === 0 ? "All Customers" : "Audiences"}
          placeholder="Search"
        />
      )}
      renderOption={(props, option) => {
        if (option.providerType !== tab) {
          return null;
        }
        return (
          <li {...props}>
            <Typography variant="body2" color="black">
              {option.name}
            </Typography>
          </li>
        );
      }}
      PaperComponent={(props) => {
        const { children, ...other } = props;
        return (
          <Paper
            {...other}
            onMouseDown={(event) => {
              // Prevent blur
              event.preventDefault();
            }}
          >
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={onTabChange}
              centered
            >
              {cdps.map((cdp, index) => (
                <Tab
                  key={index}
                  label={getCDPTypeNameMapping(cdp.cdpType)}
                  value={cdp.cdpType}
                />
              ))}
            </Tabs>
            {children}
          </Paper>
        );
      }}
      onChange={(event, newInputValue) => {
        onAudiencesChange(
          newInputValue.map((input) => ({
            audienceId: input.id,
            audienceName: input.name,
            audienceType: input.type,
            cdpType: input.providerType,
          }))
        );
      }}
    />
  );
};

export default CDPAudienceSelector;
