/**
 * @generated SignedSource<<24189c6b273a800c9ea0813f086b1dd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppBuildState = "DISABLED" | "PENDING_BRAND_SETUP" | "PENDING_KINN_SETUP" | "PRE_PRODUCTION" | "PRODUCTION" | "%future added value";
export type PlanInterval = "EVERY_30_DAYS" | "EVERY_365_DAYS" | "%future added value";
export type PlanStatus = "ACTIVE" | "CANCELLED" | "DECLINED" | "EXPIRED" | "FROZEN" | "NO_PLAN" | "PENDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DashboardContainer_user$data = {
  readonly brandAdminProfiles: ReadonlyArray<{
    readonly brand: {
      readonly appConfig: {
        readonly appState: AppBuildState;
        readonly iosAscTeamId: string;
      };
      readonly billingConfig: {
        readonly id: string;
        readonly oneTimePurchaseCharges: ReadonlyArray<{
          readonly currency: string;
          readonly id: string;
          readonly isBlocking: boolean;
          readonly name: string | null;
          readonly price: any;
          readonly status: PlanStatus;
        }>;
        readonly pendingBillingConfig: {
          readonly currency: string;
          readonly interval: PlanInterval;
          readonly isBlocking: boolean;
          readonly price: any | null;
          readonly status: PlanStatus;
          readonly takeRate: any | null;
        } | null;
        readonly planStatus: PlanStatus;
      } | null;
      readonly displayName: string;
      readonly finishedOnboarding: boolean;
      readonly id: string;
      readonly showIosTosAlert: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"Sidepane_brandFragment" | "useBrandUpdateMutation_brand">;
    };
  }>;
  readonly id: string;
  readonly isStaff: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"Sidepane_userFragment" | "TopNavBar_user">;
  readonly " $fragmentType": "DashboardContainer_user";
};
export type DashboardContainer_user$key = {
  readonly " $data"?: DashboardContainer_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardContainer_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isBlocking",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardContainer_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TopNavBar_user"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isStaff",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLBrandAdminProfile",
      "kind": "LinkedField",
      "name": "brandAdminProfiles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandType",
          "kind": "LinkedField",
          "name": "brand",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useBrandUpdateMutation_brand"
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showIosTosAlert",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GraphQLBillingConfig",
              "kind": "LinkedField",
              "name": "billingConfig",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "planStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GraphQLPendingBillingConfig",
                  "kind": "LinkedField",
                  "name": "pendingBillingConfig",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "interval",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "takeRate",
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GraphQLOneTimePurchaseCharge",
                  "kind": "LinkedField",
                  "name": "oneTimePurchaseCharges",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v4/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "finishedOnboarding",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BrandAppConfigType",
              "kind": "LinkedField",
              "name": "appConfig",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "appState",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "iosAscTeamId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Sidepane_brandFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Sidepane_userFragment"
    }
  ],
  "type": "UserType",
  "abstractKey": null
};
})();

(node as any).hash = "1df8a9cf052653e5d0137b9a3cd9dec6";

export default node;
