/**
 * @generated SignedSource<<728f59a22525e3b72307cc7bb20746bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SlackCard_brand$data = {
  readonly slackUserInstalledChannels: ReadonlyArray<string>;
  readonly " $fragmentType": "SlackCard_brand";
};
export type SlackCard_brand$key = {
  readonly " $data"?: SlackCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"SlackCard_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SlackCard_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slackUserInstalledChannels",
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "6c6b62b204c1e0eef07c051fda389ca4";

export default node;
