/**
 * @generated SignedSource<<8c87b16138f35f6c8b0af954450c3c98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CDPAudienceType = "LIST" | "SEGMENT" | "%future added value";
export type CDPType = "KINN" | "KLAVIYO" | "OMETRIA" | "SHOPIFY" | "%future added value";
export type DiscountType = "ORDER" | "PRODUCT" | "%future added value";
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
export type GraphQLDailyDealInput = {
  audienceLanguages?: ReadonlyArray<KinnLanguageCode> | null;
  audiences?: ReadonlyArray<GraphQLCDPAudienceInput> | null;
  blurredHeadline: string;
  blurredSubheadline: string;
  brand: NodeInput;
  discountCode: string;
  discountType: DiscountType;
  discountUrl: string;
  endsAt: any;
  headline: string;
  imageUploadable: any;
  isNotifEnabled: boolean;
  notifSubtitle?: string | null;
  notifTimestamp?: any | null;
  notifTitle?: string | null;
  notifUtmCampaign?: string | null;
  startsAt: any;
  subheadline: string;
  title?: string | null;
};
export type NodeInput = {
  id: string;
};
export type GraphQLCDPAudienceInput = {
  audienceId: string;
  audienceName: string;
  audienceType: CDPAudienceType;
  cdpType: CDPType;
};
export type DailyDealFormCreateMutation$variables = {
  input: GraphQLDailyDealInput;
};
export type DailyDealFormCreateMutation$data = {
  readonly createDailyDeal: {
    readonly id: string;
    readonly notification: {
      readonly body: string;
      readonly timestamp: any;
      readonly title: string;
      readonly utmCampaign: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"DailyDealTableRow_dailyDeal">;
  };
};
export type DailyDealFormCreateMutation = {
  response: DailyDealFormCreateMutation$data;
  variables: DailyDealFormCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "utmCampaign",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timestamp",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DailyDealFormCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLDailyDeal",
        "kind": "LinkedField",
        "name": "createDailyDeal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DailyDealTableRow_dailyDeal"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLNotification",
            "kind": "LinkedField",
            "name": "notification",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DailyDealFormCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLDailyDeal",
        "kind": "LinkedField",
        "name": "createDailyDeal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archivedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "audienceLanguages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startsAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endsAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "blurredHeadline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "blurredSubheadline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "headline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subheadline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLCDPAudience",
            "kind": "LinkedField",
            "name": "audiences",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "audienceName",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLNotification",
            "kind": "LinkedField",
            "name": "notification",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5b367b25aa0a477f196088e2569c9de9",
    "id": null,
    "metadata": {},
    "name": "DailyDealFormCreateMutation",
    "operationKind": "mutation",
    "text": "mutation DailyDealFormCreateMutation(\n  $input: GraphQLDailyDealInput!\n) {\n  createDailyDeal(input: $input) {\n    id\n    ...DailyDealTableRow_dailyDeal\n    notification {\n      title\n      body\n      utmCampaign\n      timestamp\n      id\n    }\n  }\n}\n\nfragment DailyDealTableRow_dailyDeal on GraphQLDailyDeal {\n  id\n  archivedAt\n  audienceLanguages\n  discountType\n  discountCode\n  discountUrl\n  imageUrl\n  date\n  startsAt\n  endsAt\n  blurredHeadline\n  blurredSubheadline\n  headline\n  subheadline\n  audiences {\n    audienceName\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e5525422bd7228b679db16f2861ac3cd";

export default node;
