/**
 * @generated SignedSource<<d10bde043ab76826cd6552b08dbbc93a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppBuildState = "DISABLED" | "PENDING_BRAND_SETUP" | "PENDING_KINN_SETUP" | "PRE_PRODUCTION" | "PRODUCTION" | "%future added value";
export type InsightsContainerQuery$variables = {
  id: string;
};
export type InsightsContainerQuery$data = {
  readonly brand: {
    readonly appConfig: {
      readonly appLaunchDate: any | null;
      readonly appState: AppBuildState;
    };
    readonly brandMetricSummary: {
      readonly latestDate: string;
    };
    readonly currencyCode: string;
    readonly finishedOnboarding: boolean;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"InsightsLifetimeSection_brand">;
  };
  readonly user: {
    readonly isInternal: boolean;
  } | null;
};
export type InsightsContainerQuery = {
  response: InsightsContainerQuery$data;
  variables: InsightsContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInternal",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currencyCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appState",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appLaunchDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finishedOnboarding",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestDate",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "appSales",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "appOrders",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "appUsers",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "appPurchasers",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "appRepeatPurchasers",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InsightsContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InsightsLifetimeSection_brand"
          },
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandAppConfigType",
            "kind": "LinkedField",
            "name": "appConfig",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLBrandMetricSummary",
            "kind": "LinkedField",
            "name": "brandMetricSummary",
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InsightsContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLBrandMetricSummary",
            "kind": "LinkedField",
            "name": "brandMetricSummary",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLBrandMetricLifetime",
                "kind": "LinkedField",
                "name": "summary",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLBrandMetricLifetime",
                "kind": "LinkedField",
                "name": "summary1d",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLBrandMetricLifetime",
                "kind": "LinkedField",
                "name": "summary7d",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLBrandMetricLifetime",
                "kind": "LinkedField",
                "name": "summary30d",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandAppConfigType",
            "kind": "LinkedField",
            "name": "appConfig",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9d1a9a6764cd670885fc0070b846df0d",
    "id": null,
    "metadata": {},
    "name": "InsightsContainerQuery",
    "operationKind": "query",
    "text": "query InsightsContainerQuery(\n  $id: ID!\n) {\n  user {\n    isInternal\n    id\n  }\n  brand(id: $id) {\n    ...InsightsLifetimeSection_brand\n    id\n    currencyCode\n    appConfig {\n      appState\n      appLaunchDate\n      id\n    }\n    finishedOnboarding\n    brandMetricSummary {\n      latestDate\n    }\n  }\n}\n\nfragment InsightsLifetimeSection_brand on BrandType {\n  currencyCode\n  brandMetricSummary {\n    summary {\n      appSales\n      appOrders\n      appUsers\n      appPurchasers\n      appRepeatPurchasers\n      id\n    }\n    summary1d {\n      appSales\n      appOrders\n      appUsers\n      appPurchasers\n      appRepeatPurchasers\n      id\n    }\n    summary7d {\n      appSales\n      appOrders\n      appUsers\n      appPurchasers\n      appRepeatPurchasers\n      id\n    }\n    summary30d {\n      appSales\n      appOrders\n      appUsers\n      appPurchasers\n      appRepeatPurchasers\n      id\n    }\n    latestDate\n  }\n}\n"
  }
};
})();

(node as any).hash = "4885247b3f94e2ccef7edac548b6441a";

export default node;
