import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment, useMutation } from "react-relay";
import * as yup from "yup";
import { InternBrandBillingOneTimePurchaseCardDeleteMutation } from "./__generated__/InternBrandBillingOneTimePurchaseCardDeleteMutation.graphql";
import { InternBrandBillingOneTimePurchaseCardMutation } from "./__generated__/InternBrandBillingOneTimePurchaseCardMutation.graphql";
import { InternBrandBillingOneTimePurchaseCard_brand$key } from "./__generated__/InternBrandBillingOneTimePurchaseCard_brand.graphql";
import CardSection from "../../components/CardSection";
import LeftRight from "../../components/LeftRight";
import SnackbarContext from "../../contexts/SnackbarContext";
import nullthrows from "../../utils/nullthrows";

const mutation = graphql`
  mutation InternBrandBillingOneTimePurchaseCardMutation(
    $input: GraphQLOneTimePurchaseChargeInputPartial!
  ) {
    createOneTimePurchase(data: $input) {
      ... on GraphQLOneTimePurchaseCharge {
        id
        isBlocking
        status
        currency
        chargeId
        price
        createdAt
        name
      }
    }
  }
`;

const deleteMutation = graphql`
  mutation InternBrandBillingOneTimePurchaseCardDeleteMutation(
    $input: NodeInput!
  ) {
    deleteOneTimePurchase(data: $input) {
      ... on GraphQLOneTimePurchaseCharge {
        id
      }
    }
  }
`;

const brandFragment = graphql`
  fragment InternBrandBillingOneTimePurchaseCard_brand on BrandType {
    id
    billingConfig {
      id
      oneTimePurchaseCharges {
        id
        isBlocking
        status
        currency
        chargeId
        price
        createdAt
        name
      }
    }
  }
`;

const schema = yup
  .object({
    chargeName: yup.string().required("Charge name is required"),
    oneTimeChargeAmount: yup
      .number()
      .typeError("Amount must be a number")
      .moreThan(0.5, "Amount must be greater than 0.5")
      .required(),
    isBlocking: yup.boolean().optional().default(false),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const InternBrandBillingOneTimePurchaseCard = ({
  brand: brandKey,
}: {
  brand: InternBrandBillingOneTimePurchaseCard_brand$key;
}) => {
  const snackbarContext = useContext(SnackbarContext);
  const brand = useFragment<InternBrandBillingOneTimePurchaseCard_brand$key>(
    brandFragment,
    brandKey
  );
  const brandID = brand.id;
  const billingConfig = brand?.billingConfig ?? null;
  const charges = billingConfig?.oneTimePurchaseCharges ?? [];

  const {
    handleSubmit,
    formState: { errors, isDirty },
    register,
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const onDialogClose = () => {
    setIsDialogOpen(false);
    reset();
  };

  const [createCharge, isCreating] =
    useMutation<InternBrandBillingOneTimePurchaseCardMutation>(mutation);
  const [deleteCharge, isDeleting] =
    useMutation<InternBrandBillingOneTimePurchaseCardDeleteMutation>(
      deleteMutation
    );

  const onSubmit = (data: FormData) => {
    createCharge({
      variables: {
        input: {
          billingConfig: {
            id: nullthrows(billingConfig?.id),
          },
          price: data.oneTimeChargeAmount,
          name: data.chargeName,
          status: "PENDING",
          isBlocking: data.isBlocking,
        },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          snackbarContext?.openSnackbar("Update Failed", "error");
        } else {
          snackbarContext?.openSnackbar("Update Successful", "success");
          onDialogClose();
        }
      },
      onError: (error: Error) => {
        snackbarContext?.openSnackbar("Update Failed", "error");
      },
      updater: (store) => {
        const payload = store.getRootField("createOneTimePurchase");
        const bc = store.get(nullthrows(billingConfig?.id));
        if (bc == null || payload == null) {
          return;
        }
        bc.setLinkedRecords(
          [...(bc.getLinkedRecords("oneTimePurchaseCharges") ?? []), payload],
          "oneTimePurchaseCharges"
        );
      },
    });
  };

  const createPlanButton = (
    <Tooltip title={"Create"} placement="right" arrow>
      <IconButton
        disabled={isDeleting}
        sx={{
          flexShrink: 0,
        }}
        onClick={() => setIsDialogOpen(true)}
      >
        <AddCircleOutlineIcon />
      </IconButton>
    </Tooltip>
  );

  const existingPlan = (
    <>
      <LeftRight
        expandLeft
        left={<Typography variant="subtitle1">Pending Charges</Typography>}
        right={createPlanButton}
      />
      {charges.length > 0 ? (
        charges.map((charge, index) => {
          return (
            <Card
              key={index}
              variant="outlined"
              sx={{
                p: 2,
              }}
            >
              <LeftRight
                expandLeft
                left={
                  <Stack display={"block"} overflow={"hidden"}>
                    <Alert
                      severity={charge.isBlocking ? "error" : "warning"}
                      sx={{
                        mb: 2,
                      }}
                    >
                      <Typography variant="subtitle2">
                        <b>
                          {charge.isBlocking
                            ? "Brand will be blocked from the seller dashboard"
                            : "Brand will not be blocked from the seller dashboard"}
                        </b>
                      </Typography>
                    </Alert>
                    <Typography variant="subtitle2">
                      <b>Name:</b> {charge?.name}
                    </Typography>
                    <Typography variant="subtitle2">
                      <b>ID:</b> {charge?.chargeId}
                    </Typography>
                    {charge?.createdAt && (
                      <Typography variant="subtitle2">
                        <b>Created:</b> {dayjs(charge?.createdAt).format("LL")}
                      </Typography>
                    )}
                    <Typography variant="subtitle2">
                      <b>Status:</b> {charge?.status}
                    </Typography>
                    <Typography variant="subtitle2">
                      <b>Price:</b> ({charge?.currency}) {charge?.price}
                    </Typography>
                  </Stack>
                }
                right={
                  <Tooltip title={"Delete"} placement="right" arrow>
                    <IconButton
                      disabled={isDeleting}
                      sx={{
                        flexShrink: 0,
                      }}
                      onClick={() => {
                        deleteCharge({
                          variables: {
                            input: {
                              id: charge.id,
                            },
                          },
                          onCompleted: (data, errors) => {
                            if (errors) {
                              snackbarContext?.openSnackbar(
                                "Delete Failed",
                                "error"
                              );
                            } else {
                              snackbarContext?.openSnackbar(
                                "Delete Successful",
                                "success"
                              );
                            }
                          },
                          onError: (error: Error) => {
                            snackbarContext?.openSnackbar(
                              "Delete Failed",
                              "error"
                            );
                          },
                          updater: (store) => {
                            const root = store.getRoot();
                            const brandNode = root.getLinkedRecord("brand", {
                              id: brandID,
                            });
                            const billingConfigNode =
                              brandNode?.getLinkedRecord("billingConfig");
                            const oneTimePurchaseChargesNode =
                              billingConfigNode?.getLinkedRecords(
                                "oneTimePurchaseCharges"
                              );
                            if (
                              oneTimePurchaseChargesNode == null ||
                              billingConfigNode == null
                            ) {
                              return;
                            }
                            const newOneTimePurchaseCharges =
                              oneTimePurchaseChargesNode.filter(
                                (chargeNode) => {
                                  return chargeNode.getDataID() !== charge.id;
                                }
                              );
                            billingConfigNode.setLinkedRecords(
                              newOneTimePurchaseCharges,
                              "oneTimePurchaseCharges"
                            );
                          },
                        });
                      }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                }
              />
            </Card>
          );
        })
      ) : (
        <Card
          variant="outlined"
          sx={{
            p: 2,
          }}
        >
          <Typography variant="subtitle2">
            <b>Status:</b> No pending charges
          </Typography>
        </Card>
      )}
    </>
  );

  const createEditDialog = (
    <Dialog open={isDialogOpen} onClose={onDialogClose} fullWidth>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <DialogTitle>One Time Charge</DialogTitle>
        <DialogContent>
          <Stack>
            <TextField
              {...register("chargeName")}
              error={!!errors?.chargeName}
              helperText={errors?.chargeName?.message}
              fullWidth
              autoComplete="off"
              margin="normal"
              label={"Charge Name"}
              variant="outlined"
            />
            <TextField
              {...register("oneTimeChargeAmount")}
              error={!!errors?.oneTimeChargeAmount}
              helperText={errors?.oneTimeChargeAmount?.message}
              fullWidth
              autoComplete="off"
              margin="normal"
              label={"Charge Amount"}
              variant="outlined"
            />
            <LeftRight
              expandLeft={true}
              left={
                <Stack>
                  <Typography variant="subtitle1">
                    Should block seller dashboard
                  </Typography>
                  <Typography sx={{ paddingBottom: "16px" }} variant="body2">
                    Enabling this toggle will lock admins out of the seller
                    dashboard. By default, we will just show a banner at the
                    top, leaving them access to the rest of the dashboard.
                  </Typography>
                </Stack>
              }
              right={<Switch {...register("isBlocking")} />}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isDirty || isCreating}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return (
    <>
      {createEditDialog}
      <CardSection
        title={"One Time Charge"}
        content={
          <Grid
            container
            columns={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={2} paddingRight="4px">
              <Stack spacing={2}>{existingPlan}</Stack>
            </Grid>
          </Grid>
        }
        variant="outlined"
      />
    </>
  );
};

export default InternBrandBillingOneTimePurchaseCard;
