import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useFragment } from "react-relay";
import { useInsightsTabPlatformBreakdownData_brand$key } from "./__generated__/useInsightsTabPlatformBreakdownData_brand.graphql";

type InsightsTabPlatformBreakdownSection = {
  name: string;
  data1: number;
  data2: number;
};

export type InsightsTabPlatformBreakdownData = {
  downloads: InsightsTabPlatformBreakdownSection;
  sessions: InsightsTabPlatformBreakdownSection;
};
export type InsightsTabPlatformBreakdownDataByDate = {
  [key: string]: InsightsTabPlatformBreakdownData;
};

const brandFragment = graphql`
  fragment useInsightsTabPlatformBreakdownData_brand on BrandType {
    barchart: metricsDaily(
      filters: {
        date: { lte: $endDate, gte: $startDate }
        os: { inList: [IOS, ANDROID] }
      }
    ) {
      edges {
        node {
          date
          os
          appSessions
          newAppUsers
        }
      }
    }
  }
`;

export const useInsightsTabPlatformBreakdownData = (
  brandKey: useInsightsTabPlatformBreakdownData_brand$key,
  dateRange: string[]
): InsightsTabPlatformBreakdownDataByDate => {
  const brand = useFragment(brandFragment, brandKey);
  const metrics = brand.barchart?.edges ?? [];

  const iosMetrics = metrics.filter((m) => m.node.os === "IOS");
  const androidMetrics = metrics.filter((m) => m.node.os === "ANDROID");
  const iosData = new Map<
    string,
    {
      sessions: number;
      newUsers: number;
    }
  >();
  for (const metric of iosMetrics) {
    iosData.set(metric.node.date, {
      sessions: metric.node.appSessions,
      newUsers: metric.node.newAppUsers,
    });
  }
  const androidData = new Map<
    string,
    {
      sessions: number;
      newUsers: number;
    }
  >();
  for (const metric of androidMetrics) {
    androidData.set(metric.node.date, {
      sessions: metric.node.appSessions,
      newUsers: metric.node.newAppUsers,
    });
  }

  const data = dateRange.map((date) => {
    return {
      name: dayjs(date).format("MMM D"),
      data1: iosData.get(date)?.sessions ?? 0,
      data2: androidData.get(date)?.sessions ?? 0,
    };
  });

  const totalDownloadsData = dateRange.map((date) => {
    return {
      name: dayjs(date).format("MMM D"),
      data1: iosData.get(date)?.newUsers ?? 0,
      data2: androidData.get(date)?.newUsers ?? 0,
    };
  });

  const dataByDate: InsightsTabPlatformBreakdownDataByDate = dateRange.reduce(
    (acc, date, index) => {
      acc[date] = {
        downloads: totalDownloadsData[index],
        sessions: data[index],
      };
      return acc;
    },
    {} as InsightsTabPlatformBreakdownDataByDate
  );

  return dataByDate;
};
