/**
 * @generated SignedSource<<2822055578a5a47033d3faa6ecc43b02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Sidepane_brandFragment$data = {
  readonly featureFlags: {
    readonly dailyDealEnabled: boolean;
  };
  readonly isForumEnabled: boolean;
  readonly " $fragmentType": "Sidepane_brandFragment";
};
export type Sidepane_brandFragment$key = {
  readonly " $data"?: Sidepane_brandFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"Sidepane_brandFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Sidepane_brandFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isForumEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlagsType",
      "kind": "LinkedField",
      "name": "featureFlags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dailyDealEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "9fe1b32b85c938267a6278d15d70d472";

export default node;
