import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box, Stack, Typography } from "@mui/material";
import formatNumber from "../utils/formatNumber";

const PercentageChange = ({ value }: { value: number }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={0.5} mt={1}>
      {value < 0 ? (
        <ArrowDownwardIcon
          sx={{
            color: "red",
            fontSize: "12px",
          }}
        />
      ) : value > 0 ? (
        <ArrowUpwardIcon
          sx={{
            color: "green",
            fontSize: "12px",
          }}
        />
      ) : (
        <Box />
      )}
      <Typography
        sx={{
          color: value < 0 ? "red" : value > 0 ? "green" : "black",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "120%",
          mt: 1,
        }}
      >
        {formatNumber(Math.abs(value), "percent")}
      </Typography>
    </Stack>
  );
};

export default PercentageChange;
