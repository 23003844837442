import { Box, Grid } from "@mui/material";
import StackedBarChart from "../../../components/analytics/StackedBarChart";
import { InsightsTabPlatformBreakdownDataByDate } from "./hooks/useInsightsTabPlatformBreakdownData";

const InsightsTabPlatformBreakdown = ({
  data,
  dateRange,
}: {
  data: InsightsTabPlatformBreakdownDataByDate;
  dateRange: string[];
}) => {
  const totalDownloadsData = dateRange.map((date) => data[date].downloads);
  const sessionData = dateRange.map((date) => data[date].sessions);

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            p: 2,
          }}
        >
          <StackedBarChart
            data={totalDownloadsData}
            title={"Downloads"}
            line1Name="iOS"
            line2Name="Android"
            showTotalStats
            showAverageStats
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            p: 2,
          }}
        >
          <StackedBarChart
            data={sessionData}
            title={"Sessions"}
            line1Name="iOS"
            line2Name="Android"
            showTotalStats
            showAverageStats
          />
        </Box>
      </Grid>
    </>
  );
};

export default InsightsTabPlatformBreakdown;
