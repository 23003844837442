import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import MuxPlayer from "@mux/mux-player-react/lazy";
import * as UpChunk from "@mux/upchunk";
import Cookies from "js-cookie";
import { useContext, useState } from "react";
import { MediaType } from "../views/dashboard/engage/highlights/__generated__/HighlightManageFormUpdateMutation.graphql";
import SnackbarContext from "../contexts/SnackbarContext";
import { PHOTO_MIMETYPES, VIDEO_MIMETYPES } from "../utils/constants";

const MAX_FILE_SIZE = 10000000; // 10 MB

type Props = {
  disabled?: boolean;
  error?: string;
  filename?: string | null;
  fileType: string;
  fileURL?: string | null;
  fallbackURL?: string;
  htmlLabel: string;
  inputLabel?: string;
  inputText: string;
  mediaType?: MediaType | "FILE";
  onClose: () => void;
  onUpload: (
    e: React.ChangeEvent<HTMLInputElement>,
    mediaType?: MediaType
  ) => void;
  // Required MUX Fields
  brandID?: string; // TODO: make required?
  onUploadVideo?: (videoID: string) => void; // TODO: make required?
};

// TODO: add snackbar for errors/state updates?
const UploadedFileCard = ({
  brandID,
  disabled,
  error,
  filename,
  fileType,
  fileURL,
  fallbackURL,
  htmlLabel,
  inputText,
  inputLabel,
  mediaType,
  onClose,
  onUpload,
  onUploadVideo,
}: Props) => {
  const snackbarContext = useContext(SnackbarContext);

  const [progress, setProgress] = useState<number | null>(null);

  const isDisabled = disabled || progress != null;

  let fileContents = null;
  let type = mediaType;

  if (type == null && fileURL != null) {
    const guessMimetype = fileURL.split(".").at(-1) ?? "";
    if (VIDEO_MIMETYPES.includes(guessMimetype)) {
      type = "VIDEO";
    } else if (PHOTO_MIMETYPES.includes(guessMimetype)) {
      type = "PHOTO";
    }
  }

  fileContents =
    type === "PHOTO" ? (
      <img alt="" width={200} src={fileURL ?? ""} loading="lazy" />
    ) : type === "VIDEO" ? (
      <video key={fileURL} width={200} controls poster={fallbackURL}>
        <source src={fileURL ?? ""} />
      </video>
    ) : type === "WEB_MUX_VIDEO" ? (
      <MuxPlayer
        playbackId={fileURL?.split("?")[0]}
        tokens={{
          playback: fileURL?.split("?")[1]?.split("=")[1] ?? "",
        }}
        style={{
          aspectRatio: "16 / 9",
          maxWidth: "90%",
        }}
        poster={fallbackURL}
        streamType="on-demand"
      />
    ) : type === "FILE" ? (
      <Typography variant="body2">{filename}</Typography>
    ) : null;

  const cardLabel =
    inputLabel !== undefined ? (
      <Typography
        sx={{
          position: "absolute",
          top: "-9px",
          left: "9px",
          zIndex: 2,
          background: fileURL == null && filename == null ? undefined : "white",
          px: "4px",
        }}
        variant="caption"
      >
        {inputLabel}
      </Typography>
    ) : null;

  return fileURL == null && filename == null ? (
    <label
      htmlFor={htmlLabel}
      style={{
        position: "relative",
        opacity: isDisabled ? "40%" : undefined,
        pointerEvents: isDisabled ? "none" : undefined,
      }}
    >
      {cardLabel}
      <Button
        sx={{
          height: 60,
          width: "100%",
          background: "#F4F4F4",
          border: "1px dashed #D1D5DB",
          borderRadius: "8px",
        }}
        variant="text"
        component="span"
      >
        <Box>
          {progress == null ? inputText : "Uploading..."}
          {progress && (
            <LinearProgress variant="determinate" value={progress} />
          )}
        </Box>
        <input
          name={htmlLabel}
          accept={fileType}
          style={{ display: "none" }}
          id={htmlLabel}
          onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event?.target?.files?.[0];
            if (file == null) {
              return;
            }

            if (file.type.includes("video") && brandID && onUploadVideo) {
              const endpoint = await fetch(
                `/api/v1/media/upload-url?brand_id=${
                  window.atob(brandID).split(":")[1]
                }`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": Cookies.get("csrftoken") ?? "",
                  },
                }
              )
                .then((res) => res.json())
                .then((json) => {
                  if (json.object_id === null || json.upload_url === null) {
                    snackbarContext?.openSnackbar(
                      "Unable to upload video",
                      "error"
                    );
                    return "";
                  }
                  onUploadVideo(json.object_id);
                  return json.upload_url;
                });

              const upload = UpChunk.createUpload({
                endpoint: endpoint,
                file: file,
              });

              upload.on("error", (error) => {
                setProgress(null);
                snackbarContext?.openSnackbar(
                  "Unable to upload video",
                  "error"
                );
              });

              upload.on("progress", (progress) => {
                setProgress(progress.detail);
              });

              upload.on("success", () => {
                onUpload(event, "WEB_MUX_VIDEO");
                setProgress(null);
              });
            } else {
              if (file.size > MAX_FILE_SIZE) {
                snackbarContext?.openSnackbar(
                  "File must be under 10 MB",
                  "error"
                );
                return;
              }

              onUpload(event);
            }
          }}
          type="file"
        />
      </Button>
    </label>
  ) : (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          position: "relative",
          minHeight: "60px",
          background: "white",
          border: !!error ? "1px solid red" : "1px solid #D1D5DB",
          borderRadius: "8px",
          color: !!error ? "red" : "black",
          display: "flex",
          paddingLeft: 2,
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        {cardLabel}
        {fileContents}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {!!error && (
        <Typography
          sx={{
            mt: 1,
            color: "red",
            fontSize: "12px",
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default UploadedFileCard;
