import {
  Alert,
  CssBaseline,
  Stack,
  TextField,
  ThemeProvider,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import theme from "../../styles/Theme";
import SignInLink from "./SignInLink";
import SplitScreenView from "./SplitScreenView";

const schema = yup
  .object({
    password1: yup.string().required("Password is required"),
    password2: yup
      .string()
      .oneOf([yup.ref("password1")], "Passwords must match")
      .required("Password is required"),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const ConfirmPasswordReset = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // 0 = initial state
  // 1 = loading
  // 2 = success
  const [saveState, setSaveState] = useState(0);
  const isLoading = saveState === 1;
  const isComplete = saveState === 2;

  const [newPassword1Errors, setNewPassword1Errors] = useState("");
  const [newPassword2Errors, setNewPassword2Errors] = useState("");
  const [nonFormErrors, setNonFormErrors] = useState("");

  useEffect(() => {
    fetch("/api/v1/user/auth/verify")
      .then((res) => res.json())
      .then((data) => {
        if (data.is_authenticated) {
          navigate("/");
        }
      });
  }, [navigate]);

  const onSubmit = (data: FormData) => {
    setSaveState(1);

    const urlParams = new URLSearchParams(location.search);

    const user = {
      token: urlParams.get("token"),
      uid: urlParams.get("uid"),
      new_password1: data.password1,
      new_password2: data.password2,
    };
    fetch("/api/v1/user/auth/password/reset/confirm/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        setNewPassword1Errors("");
        setNewPassword2Errors("");
        setNonFormErrors("");

        if ("detail" in data) {
          setSaveState(2);
          setNonFormErrors(data.detail);
          setTimeout(function () {
            navigate("/login");
          }, 2000);
        } else {
          setSaveState(0);
          if ("uid" in data) {
            setNonFormErrors("Invalid reset token.");
          }
          if ("token" in data) {
            setNonFormErrors("Invalid reset token.");
          }
          if ("new_password1" in data) {
            setNewPassword1Errors(data.new_password1.join(","));
          }

          if ("new_password2" in data) {
            setNewPassword2Errors(data.new_password2.join(","));
          }

          if ("non_field_errors" in data) {
            setNonFormErrors(data.non_field_errors.join(","));
          }
        }
      });
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SplitScreenView
        rightContent={
          <Stack
            sx={{
              width: "100%",
            }}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              sx={{
                color: "black",
                fontFamily:
                  '"GT-Flexa","Roboto","Helvetica","Arial",sans-serif',
                fontWeight: 600,
                fontSize: "28px",
                marginBottom: 2,
              }}
            >
              Reset Password
            </Typography>
            {nonFormErrors ? (
              <Alert
                severity={isComplete ? "success" : "error"}
                sx={{
                  mb: 2,
                }}
              >
                {nonFormErrors}
              </Alert>
            ) : null}
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              {!isComplete && (
                <>
                  <TextField
                    {...register("password1")}
                    error={!!errors?.password1 || !!newPassword1Errors}
                    helperText={
                      errors?.password1?.message || newPassword1Errors
                    }
                    fullWidth
                    autoComplete="new-password"
                    label="New Password"
                    type="password"
                    autoFocus
                  />
                  <TextField
                    {...register("password2")}
                    error={!!errors?.password2 || !!newPassword2Errors}
                    helperText={
                      errors?.password2?.message || newPassword2Errors
                    }
                    fullWidth
                    margin="normal"
                    autoComplete="new-password"
                    type="password"
                    label="Confirm Password"
                  />
                  <Button
                    fullWidth
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    sx={{
                      mt: 1,
                      mb: 2,
                    }}
                    color="secondary"
                  >
                    {isLoading ? "Updating" : "Set New Password"}
                  </Button>
                </>
              )}
              <SignInLink />
            </Box>
          </Stack>
        }
      />
    </ThemeProvider>
  );
};

export default ConfirmPasswordReset;
