/**
 * @generated SignedSource<<58a8f382de84a69e15596687adee0227>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AutomatedNotificationType = "ABANDONED_BROWSE" | "ABANDONED_CART" | "ABANDONED_CHECKOUT" | "REENGAGEMENT" | "WELCOME_SERIES" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AutomationTab_brand$data = {
  readonly appConfig: {
    readonly automatedNotifications: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly delaySeconds: number;
          readonly id: string;
          readonly isEnabled: boolean;
          readonly landingPath: string | null;
          readonly message: string;
          readonly notifType: AutomatedNotificationType;
          readonly title: string;
        };
      }>;
    };
    readonly id: string;
    readonly postPurchaseOptInEnabled: boolean;
  };
  readonly " $fragmentType": "AutomationTab_brand";
};
export type AutomationTab_brand$key = {
  readonly " $data"?: AutomationTab_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"AutomationTab_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AutomationTab_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postPurchaseOptInEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLAutomatedNotificationConnection",
          "kind": "LinkedField",
          "name": "automatedNotifications",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GraphQLAutomatedNotificationEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GraphQLAutomatedNotification",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "notifType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "message",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isEnabled",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "delaySeconds",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "landingPath",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "c52fb2bdcfcc52e1db116371323619fa";

export default node;
