/**
 * @generated SignedSource<<a9f2ff067b71ee4b706c3c33f920adc0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OS = "ANDROID" | "IOS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useInsightsTabPlatformBreakdownData_brand$data = {
  readonly barchart: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly appSessions: number;
        readonly date: any;
        readonly newAppUsers: number;
        readonly os: OS | null;
      };
    }>;
  };
  readonly " $fragmentType": "useInsightsTabPlatformBreakdownData_brand";
};
export type useInsightsTabPlatformBreakdownData_brand$key = {
  readonly " $data"?: useInsightsTabPlatformBreakdownData_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"useInsightsTabPlatformBreakdownData_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "endDate"
    },
    {
      "kind": "RootArgument",
      "name": "startDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useInsightsTabPlatformBreakdownData_brand",
  "selections": [
    {
      "alias": "barchart",
      "args": [
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "gte",
                  "variableName": "startDate"
                },
                {
                  "kind": "Variable",
                  "name": "lte",
                  "variableName": "endDate"
                }
              ],
              "kind": "ObjectValue",
              "name": "date"
            },
            {
              "kind": "Literal",
              "name": "os",
              "value": {
                "inList": [
                  "IOS",
                  "ANDROID"
                ]
              }
            }
          ],
          "kind": "ObjectValue",
          "name": "filters"
        }
      ],
      "concreteType": "GraphQLBrandMetricDailyConnection",
      "kind": "LinkedField",
      "name": "metricsDaily",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLBrandMetricDailyEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GraphQLBrandMetricDaily",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "os",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "appSessions",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "newAppUsers",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "024aad8ba55e9720c5a852f4be7833ff";

export default node;
