import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Avatar,
  Box,
  Button,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useContext } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { NotificationTableRow_notification$key } from "./__generated__/NotificationTableRow_notification.graphql";
import CDPAudienceInfoTableCell from "../../../../components/CDPAudienceInfoTableCell";
import UTMCampaignTableCell from "../../../../components/UTMCampaignTableCell";
import DebugContext from "../../../../contexts/DebugContext";
import { ENGAGE_DAILY_DEALS } from "../../../../utils/routes";
import { DialogType } from "../NotificationsTab";

const notifFragment = graphql`
  fragment NotificationTableRow_notification on GraphQLNotification {
    id
    uuid
    audienceLanguages
    automaticArchiveTimestamp
    body
    thumbnailUrl
    externalThumbnailUri
    isArchived
    landingPath
    timestamp
    title
    utmCampaign
    audiences {
      audienceName
    }
    dailyDeal {
      id
    }
    feedPost {
      id
    }
    highlight {
      id
      highlightAlbum {
        id
      }
    }
  }
`;

const NotificationTableRow = ({
  isNew,
  notification: notificationKey,
  onOpenDialog,
}: {
  isNew: boolean;
  notification: NotificationTableRow_notification$key;
  onOpenDialog: (dialogType: DialogType) => void;
}) => {
  const { debug } = useContext(DebugContext);
  const navigate = useNavigate();

  const notification = useFragment(notifFragment, notificationKey);
  const automaticArchiveTimestamp =
    notification.automaticArchiveTimestamp ?? null;
  const publishedTime = notification.timestamp;
  const dailyDealID = notification.dailyDeal?.id;
  const feedPostID = notification.feedPost?.id;
  const highlightID = notification.highlight?.id;
  const isLinked =
    dailyDealID != null || feedPostID != null || highlightID != null;
  const isScheduled = dayjs(publishedTime).isAfter(dayjs());
  const hasAutoArchiveTime = automaticArchiveTimestamp !== null;
  const isAutoArchived =
    hasAutoArchiveTime && dayjs().isAfter(dayjs(automaticArchiveTimestamp));
  const isArchived = notification.isArchived;

  let statusTooltip = "";
  let statusLabel = "Published";
  if (isArchived) {
    statusLabel = "Archived";
    statusTooltip = "";
  } else if (isAutoArchived) {
    statusLabel = "Archived";
    statusTooltip =
      "Auto archived at " +
      dayjs(automaticArchiveTimestamp).format("llll z") +
      ", " +
      dayjs(automaticArchiveTimestamp).from(publishedTime, true) +
      " after publish time";
  } else if (isScheduled) {
    statusLabel = "Scheduled";
    statusTooltip = hasAutoArchiveTime
      ? "Will auto archive " +
        dayjs(automaticArchiveTimestamp).from(publishedTime, true) +
        " after publish time"
      : "";
  } else {
    statusLabel = "Published";
    statusTooltip = hasAutoArchiveTime
      ? "Will auto archive " +
        dayjs(automaticArchiveTimestamp).from(publishedTime)
      : "";
  }
  const status = (
    <Tooltip title={statusTooltip} placement="top" arrow>
      <Chip
        icon={
          statusTooltip === "" ? undefined : (
            <InfoOutlinedIcon sx={{ color: "white" }} fontSize="small" />
          )
        }
        label={statusLabel}
        color="info"
        sx={{
          color: "black",
          "& .MuiChip-icon": {
            color: "black",
          },
        }}
      />
    </Tooltip>
  );

  const debugComponent = (
    <Box
      sx={{
        position: "absolute",
        top: -6,
        left: -16,
        bgcolor: "rgb(20,184,166, 0.3)",
        fontSize: "8px",
      }}
    >
      <p>
        <b>PK:</b> {window.atob(notification.id).split(":")[1]}
      </p>
      <p>
        <b>UUID:</b> {notification.uuid}
      </p>
      <p>
        <b>URL:</b> {notification.landingPath}
      </p>
    </Box>
  );
  const theme = useTheme();

  const datePublished = isScheduled
    ? dayjs(publishedTime).format("llll z")
    : dayjs().to(dayjs(publishedTime));

  const notificationItem = (
    <ListItem>
      {debug && debugComponent}
      <ListItemAvatar>
        <Avatar
          alt=""
          src={
            notification?.thumbnailUrl ??
            notification?.externalThumbnailUri ??
            undefined
          }
          variant="rounded"
          sx={{ bgcolor: theme.palette.info.main, color: "black" }}
        >
          <NotificationsNoneIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            variant="body2"
            color={"black"}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {notification.title}
          </Typography>
        }
        secondary={
          <Typography
            variant="body2"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {notification.body}
          </Typography>
        }
      />
    </ListItem>
  );

  const editButton = isScheduled ? (
    <Button
      size="small"
      variant="text"
      color="primary"
      onClick={() => {
        if (dailyDealID != null) {
          navigate(`${ENGAGE_DAILY_DEALS}/edit/${dailyDealID}`);
        } else if (feedPostID != null) {
          navigate(`/engage/feed/edit/${feedPostID}`);
        } else if (highlightID != null) {
          navigate(`/engage/highlights`, {
            state: {
              albumID: notification.highlight?.highlightAlbum.id,
              highlightID: highlightID,
            },
          });
        } else {
          navigate(`/engage/notifications/edit/${notification.id}`);
        }
      }}
    >
      {feedPostID != null
        ? "Edit in Feed"
        : highlightID != null
        ? "Edit in Highlights"
        : dailyDealID != null
        ? "Edit in Daily Deals"
        : "Edit"}
    </Button>
  ) : null;
  const archiveOrDeleteButton =
    !isLinked && isScheduled ? (
      <Button
        size="small"
        variant="text"
        color="error"
        onClick={() => onOpenDialog(DialogType.DELETE)}
      >
        Delete
      </Button>
    ) : !isScheduled ? (
      <Button
        size="small"
        variant="text"
        color="error"
        onClick={() => onOpenDialog(DialogType.ARCHIVE)}
      >
        Archive
      </Button>
    ) : null;

  return (
    <TableRow
      key={notification.id}
      sx={{
        background: isNew ? "rgba(0,0,0, 0.08)" : "white",
        transition: "background .2s ease-in",
      }}
    >
      <TableCell align="center">{notificationItem}</TableCell>
      <CDPAudienceInfoTableCell item={notification} />
      <UTMCampaignTableCell utmCampaign={notification.utmCampaign} />
      <TableCell align="center">{status}</TableCell>
      <TableCell align="center">
        <Tooltip
          title={dayjs(publishedTime).format("llll z")}
          placement="top"
          arrow
        >
          <Box>{datePublished}</Box>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        {isArchived || isAutoArchived ? null : (
          <Stack direction="row" justifyContent={"center"}>
            {editButton}
            {archiveOrDeleteButton}
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
};

export default NotificationTableRow;
