/**
 * @generated SignedSource<<17bd2da0ec35f6290e4bdba76cbbed08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AppBuildEnvironmentType = "PROD" | "STAGING" | "%future added value";
export type AppBuildVersionBumpType = "AUTO" | "BUILD" | "MAJOR" | "MINOR" | "NONE" | "PATCH" | "%future added value";
export type KickoffIosAppBuildInput = {
  brandId: string;
  env: AppBuildEnvironmentType;
  versionBump: AppBuildVersionBumpType;
};
export type InternBrandiOSBuildsActionsPushBuildMutation$variables = {
  input: KickoffIosAppBuildInput;
};
export type InternBrandiOSBuildsActionsPushBuildMutation$data = {
  readonly kickoffIosAppBuild: {
    readonly message: string;
    readonly success: boolean;
  };
};
export type InternBrandiOSBuildsActionsPushBuildMutation = {
  response: InternBrandiOSBuildsActionsPushBuildMutation$data;
  variables: InternBrandiOSBuildsActionsPushBuildMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "KickoffIosAppBuildMutationPayload",
    "kind": "LinkedField",
    "name": "kickoffIosAppBuild",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InternBrandiOSBuildsActionsPushBuildMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InternBrandiOSBuildsActionsPushBuildMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bb457e5abbed2076d9df1237a306ff99",
    "id": null,
    "metadata": {},
    "name": "InternBrandiOSBuildsActionsPushBuildMutation",
    "operationKind": "mutation",
    "text": "mutation InternBrandiOSBuildsActionsPushBuildMutation(\n  $input: KickoffIosAppBuildInput!\n) {\n  kickoffIosAppBuild(input: $input) {\n    success\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "9d7a4f1bacc20249ac1e956a15891b29";

export default node;
