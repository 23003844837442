/**
 * @generated SignedSource<<b99cdb6c46ad255d5201a613f0c6f435>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MenuCardQuery$variables = {
  brandID: string;
};
export type MenuCardQuery$data = {
  readonly menuItemsImported: ReadonlyArray<{
    readonly children: ReadonlyArray<{
      readonly children: ReadonlyArray<{
        readonly destinationUrl: string | null;
        readonly index: number;
        readonly title: string;
      }>;
      readonly destinationUrl: string | null;
      readonly index: number;
      readonly title: string;
    }>;
    readonly destinationUrl: string | null;
    readonly index: number;
    readonly title: string;
  }>;
};
export type MenuCardQuery = {
  response: MenuCardQuery$data;
  variables: MenuCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destinationUrl",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "brandId",
        "variableName": "brandID"
      }
    ],
    "concreteType": "NestedMenuItemType",
    "kind": "LinkedField",
    "name": "menuItemsImported",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "NestedMenuItemType",
        "kind": "LinkedField",
        "name": "children",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NestedMenuItemType",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MenuCardQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MenuCardQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "96f8e15c07be86959138a583b11b2c32",
    "id": null,
    "metadata": {},
    "name": "MenuCardQuery",
    "operationKind": "query",
    "text": "query MenuCardQuery(\n  $brandID: ID!\n) {\n  menuItemsImported(brandId: $brandID) {\n    title\n    destinationUrl\n    index\n    children {\n      title\n      destinationUrl\n      index\n      children {\n        title\n        destinationUrl\n        index\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ab5aa7662f3fe38a6efd4c7d0b2bfca";

export default node;
