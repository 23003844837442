/**
 * @generated SignedSource<<ce172343973116abb2ef1a578cd6b66e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppBuildState = "DISABLED" | "PENDING_BRAND_SETUP" | "PENDING_KINN_SETUP" | "PRE_PRODUCTION" | "PRODUCTION" | "%future added value";
export type InternBrandQuery$variables = {
  id: string;
};
export type InternBrandQuery$data = {
  readonly brand: {
    readonly appConfig: {
      readonly appState: AppBuildState;
    };
    readonly displayName: string;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"InternBrandAndroidBuilds_brand" | "InternBrandBilling_brand" | "InternBrandiOSBuilds_brand">;
  };
};
export type InternBrandQuery = {
  response: InternBrandQuery$data;
  variables: InternBrandQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appState",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "takeRate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "takeRateTotalCap",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "takeRateMinUsage",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isBlocking",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InternBrandQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandAppConfigType",
            "kind": "LinkedField",
            "name": "appConfig",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InternBrandBilling_brand"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InternBrandiOSBuilds_brand"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InternBrandAndroidBuilds_brand"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InternBrandQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandAppConfigType",
            "kind": "LinkedField",
            "name": "appConfig",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "appName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iosAscTeamId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iosAppVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iosAppBuild",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iosAscKey",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iosAscKeyId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iosAscIssuerId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "appTrackingDialogEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "androidAppVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "androidAppBuild",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLBillingConfig",
            "kind": "LinkedField",
            "name": "billingConfig",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planCreatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planTrialDays",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planCurrency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planInterval",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLPendingBillingConfig",
                "kind": "LinkedField",
                "name": "pendingBillingConfig",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isImmediate",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "interval",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trialDays",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLShopifyBillingConfig",
                "kind": "LinkedField",
                "name": "shopifyBillingConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appSubscriptionLineItemId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLOneTimePurchaseCharge",
                "kind": "LinkedField",
                "name": "oneTimePurchaseCharges",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "chargeId",
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a50ccb3ca6d8c7210fccb8c5b1fc5ee7",
    "id": null,
    "metadata": {},
    "name": "InternBrandQuery",
    "operationKind": "query",
    "text": "query InternBrandQuery(\n  $id: ID!\n) {\n  brand(id: $id) {\n    id\n    displayName\n    appConfig {\n      appState\n      id\n    }\n    ...InternBrandBilling_brand\n    ...InternBrandiOSBuilds_brand\n    ...InternBrandAndroidBuilds_brand\n  }\n}\n\nfragment InternBrandAndroidBuildsGeneralInfo_appConfig on BrandAppConfigType {\n  id\n  appName\n  androidAppVersion\n  androidAppBuild\n}\n\nfragment InternBrandAndroidBuilds_brand on BrandType {\n  id\n  appConfig {\n    ...InternBrandAndroidBuildsGeneralInfo_appConfig\n    id\n  }\n}\n\nfragment InternBrandBillingOneTimePurchaseCard_brand on BrandType {\n  id\n  billingConfig {\n    id\n    oneTimePurchaseCharges {\n      id\n      isBlocking\n      status\n      currency\n      chargeId\n      price\n      createdAt\n      name\n    }\n  }\n}\n\nfragment InternBrandBillingRecurringSubCard_billingConfig on GraphQLBillingConfig {\n  id\n  planCreatedAt\n  planTrialDays\n  planCurrency\n  planId\n  planInterval\n  planPrice\n  planStatus\n  startDate\n  takeRate\n  takeRateTotalCap\n  takeRateMinUsage\n  pendingBillingConfig {\n    id\n    isBlocking\n    isImmediate\n    createdAt\n    currency\n    price\n    interval\n    takeRate\n    takeRateTotalCap\n    takeRateMinUsage\n    trialDays\n  }\n  shopifyBillingConfig {\n    appSubscriptionLineItemId\n    id\n  }\n}\n\nfragment InternBrandBilling_brand on BrandType {\n  id\n  billingConfig {\n    ...InternBrandBillingRecurringSubCard_billingConfig\n    id\n  }\n  ...InternBrandBillingOneTimePurchaseCard_brand\n}\n\nfragment InternBrandiOSBuildsGeneralInfo_appConfig on BrandAppConfigType {\n  id\n  appName\n  iosAscTeamId\n  iosAppVersion\n  iosAppBuild\n  iosAscKey\n  iosAscKeyId\n  iosAscIssuerId\n  appTrackingDialogEnabled\n}\n\nfragment InternBrandiOSBuilds_brand on BrandType {\n  id\n  appConfig {\n    ...InternBrandiOSBuildsGeneralInfo_appConfig\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b6d9da485184aed45b90069276b5bb60";

export default node;
