import { Box, Divider, Stack, Switch, Typography } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useState } from "react";
import { useFragment } from "react-relay";
import { UploadableMap } from "relay-runtime";
import { MediaType } from "../engage/feed/__generated__/FeedSinglePostForm_feedPost.graphql";
import { LaunchScreenCard_brand$key } from "./__generated__/LaunchScreenCard_brand.graphql";
import { AppColorPalette } from "./__generated__/ThemeTab_brand.graphql";
import CardSection from "../../../components/CardSection";
import ColorDropdownSelector, {
  InputType,
} from "../../../components/ColorDropdownSelector";
import SaveButton, { SavedState } from "../../../components/SaveButton";
import UploadedFileCard from "../../../components/UploadedFileCard";
import useBrandAppConfigUpdateMutation from "../../../mutations/useBrandAppConfigUpdateMutation";

const brandFragment = graphql`
  fragment LaunchScreenCard_brand on BrandType {
    appConfig {
      id
      buildTheme {
        id
        launchScreenOption
        launchScreenBackground
        launchScreenLogoUrl
        launchScreenAssetUrl
      }
    }
  }
`;

type Props = {
  brand: LaunchScreenCard_brand$key;
};

const LaunchScreenCard = ({ brand: brandKey }: Props) => {
  const brand = useFragment<LaunchScreenCard_brand$key>(
    brandFragment,
    brandKey
  );
  const buildTheme = brand?.appConfig?.buildTheme;
  const launchScreenOption = buildTheme?.launchScreenOption;

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    launchScreenOption !== null ? SavedState.SAVED : SavedState.DISABLED
  );
  const [launchScreenBackground, setLaunchScreenBackground] =
    useState<AppColorPalette | null>(buildTheme.launchScreenBackground);

  const [option1Uploadable, setOption1Uploadable] = useState<UploadableMap>({});
  const [option1Url, setOption1Url] = useState<string | null>(
    buildTheme?.launchScreenLogoUrl
  );

  const [option2Uploadable, setOption2Uploadable] = useState<UploadableMap>({});
  const [option2Url, setOption2Url] = useState<string | null>(
    buildTheme?.launchScreenAssetUrl
  );
  const [mediaType2, setMediaType2] = useState<MediaType | undefined>(
    undefined
  );

  const [option1Selected, setOption1Selected] = useState<boolean>(
    launchScreenOption === "SIMPLE"
  );
  const [option2Selected, setOption2Selected] = useState<boolean>(
    launchScreenOption === "CUSTOM"
  );

  const resetOption1 = useCallback(() => {
    setOption1Uploadable({});
    setOption1Url(null);
    setLaunchScreenBackground(null);
  }, []);
  const resetOption2 = useCallback(() => {
    setOption2Uploadable({});
    setOption2Url(null);
  }, []);

  const onOption1Change = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setOption1Selected(checked);
    option2Selected && setOption2Selected(false);
  };
  const onOption2Change = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setOption2Selected(checked);
    option1Selected && setOption1Selected(false);
  };

  const [saveMutation, isMutationInFlight] = useBrandAppConfigUpdateMutation(
    {
      id: brand.appConfig.id,
      buildTheme: {
        id: brand.appConfig.buildTheme.id,
        launchScreenOption: option1Selected ? "SIMPLE" : "CUSTOM",
        launchScreenBackground,
      },
    },
    () => {
      setSaveButtonState(SavedState.SAVED);
      setOption1Uploadable({});
      setOption2Uploadable({});
    },
    option1Selected
      ? option1Uploadable
      : option2Selected
      ? option2Uploadable
      : undefined
  );

  // Logo carries over from the Logo Card, but user can override it. Set it to default, but override if user sets it.
  const option1ExpandedSection = (
    <>
      <Typography variant="overline">Launch Screen Background Color</Typography>
      <ColorDropdownSelector
        defaultPalette={"WHITE"}
        palette={launchScreenBackground}
        inputType={InputType.LAUNCH_SCREEN_BACKGROUND_COLOR}
        onChange={(color: AppColorPalette | null) => {
          setLaunchScreenBackground(color);
          saveButtonState !== SavedState.ENABLED &&
            setSaveButtonState(SavedState.ENABLED);
        }}
      />
      <Typography variant="overline">Launch Screen Logo</Typography>
      <UploadedFileCard
        fileURL={option1Url}
        mediaType={"PHOTO"}
        fileType=".svg, .png, .jpg"
        htmlLabel="launch-screen-logo"
        inputLabel="Brand Logo"
        inputText={"Upload a photo"}
        onClose={() => {
          setOption1Url(null);
          delete option1Uploadable["buildTheme.launchScreenLogo"];
          saveButtonState !== SavedState.ENABLED &&
            setSaveButtonState(SavedState.ENABLED);
        }}
        onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
          const file = event?.target?.files?.[0];
          if (file == null) {
            return;
          }

          setOption1Url(URL.createObjectURL(file));

          const newUploadables: UploadableMap = {};
          newUploadables["buildTheme.launchScreenLogo"] = file;
          setOption1Uploadable(newUploadables);
          saveButtonState !== SavedState.ENABLED &&
            setSaveButtonState(SavedState.ENABLED);
          resetOption2();
        }}
      />
    </>
  );

  const option2ExpandedSection = (
    <UploadedFileCard
      fileURL={option2Url}
      mediaType={mediaType2}
      fileType={".png, .jpg, .gif, .mp4"}
      htmlLabel={"launch-screen-asset"}
      inputLabel={"Loading Background"}
      inputText={"Upload a .png, .jpg, .gif or .mp4"}
      onClose={() => {
        setOption2Url(null);
        delete option1Uploadable["buildTheme.launchScreenAsset"];
        saveButtonState !== SavedState.ENABLED &&
          setSaveButtonState(SavedState.ENABLED);
      }}
      onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0];
        if (file == null) {
          return;
        }

        setOption2Url(URL.createObjectURL(file));
        setMediaType2(file.type.includes("video") ? "VIDEO" : "PHOTO");

        const newUploadables: UploadableMap = {};
        newUploadables["buildTheme.launchScreenAsset"] = file;
        setOption2Uploadable(newUploadables);
        saveButtonState !== SavedState.ENABLED &&
          setSaveButtonState(SavedState.ENABLED);
        resetOption1();
      }}
    />
  );

  const isOption1Unset =
    option1Selected && (option1Url === null || launchScreenBackground === null);
  const isOption2Unset = option2Selected && option2Url === null;
  const isNeitherOptionSelected = !option1Selected && !option2Selected;

  return (
    <CardSection
      actions={
        <SaveButton
          savedState={
            isMutationInFlight ||
            isOption1Unset ||
            isOption2Unset ||
            isNeitherOptionSelected
              ? SavedState.DISABLED
              : saveButtonState
          }
          onClick={saveMutation}
        />
      }
      title={"Launch Screen"}
      content={
        <Stack
          spacing={2}
          sx={{
            maxWidth: "650px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <Typography variant="subtitle1">
                Option 1: Simple launch screen
              </Typography>
              <Typography sx={{ paddingBottom: "16px" }} variant="body2">
                Select a color from your color palette for your launch screen
                background.
              </Typography>
            </Box>
            <Switch checked={option1Selected} onChange={onOption1Change} />
          </Box>
          {option1Selected && option1ExpandedSection}
          <Divider />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <Typography variant="subtitle1">
                Option 2: Upload a custom launch screen
              </Typography>
              <Typography sx={{ paddingBottom: "16px" }} variant="body2">
                Upload a photo or video to use for your launch screen.
              </Typography>
            </Box>
            <Switch checked={option2Selected} onChange={onOption2Change} />
          </Box>
          {option2Selected && option2ExpandedSection}
        </Stack>
      }
    />
  );
};

export default LaunchScreenCard;
