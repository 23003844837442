import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useContext, useState } from "react";
import { ConnectionHandler, useMutation } from "react-relay";
import { BrandAdminInviteDialogMutation } from "./__generated__/BrandAdminInviteDialogMutation.graphql";
import SnackbarContext from "../../../../contexts/SnackbarContext";

const inviteMutation = graphql`
  mutation BrandAdminInviteDialogMutation($email: String!, $brandId: ID!) {
    inviteBrandAdmin(brandId: $brandId, email: $email) {
      ... on GraphQLBrandAdminProfile {
        ...BrandAdminTableRow_brandAdminProfile
      }
    }
  }
`;

const BrandAdminInviteDialog = ({
  brandID,
  isOpen,
  onDialogClose,
}: {
  brandID: string;
  isOpen: boolean;
  onDialogClose: () => void;
}) => {
  const snackbarContext = useContext(SnackbarContext);
  const [email, setEmail] = useState("");

  const onCloseDialog = useCallback(() => {
    setEmail("");
    onDialogClose();
  }, [setEmail, onDialogClose]);

  const [inviteAdmin, isInviting] =
    useMutation<BrandAdminInviteDialogMutation>(inviteMutation);
  const onInvite = useCallback(
    (email: string) => {
      inviteAdmin({
        variables: {
          email: email,
          brandId: brandID,
        },
        onError: (error: Error) => {
          snackbarContext?.openSnackbar("Failed to send", "error");
        },
        onCompleted: (data, errors) => {
          if (errors) {
            snackbarContext?.openSnackbar("Failed to send", "error");
          } else {
            snackbarContext?.openSnackbar("Invite sent", "success");
            onCloseDialog();
          }
        },
        updater: (store) => {
          const payload = store.getRootField("inviteBrandAdmin");
          const brand = store.get(brandID);
          if (brand == null) {
            return;
          }

          const adminProfiles = ConnectionHandler.getConnection(
            brand,
            "BrandAdminTable_admins"
          );
          if (adminProfiles == null) {
            return;
          }

          const maybeProfile = adminProfiles
            .getLinkedRecords("edges")
            ?.find(
              (edge) =>
                edge.getLinkedRecord("node")?.getDataID() ===
                payload.getDataID()
            );

          if (maybeProfile != null) {
            return;
          }

          const newProfile = ConnectionHandler.createEdge(
            store,
            adminProfiles,
            payload,
            "GraphQLBrandAdminProfileEdge"
          );
          ConnectionHandler.insertEdgeAfter(adminProfiles, newProfile);

          // Increment total count
          const profileCount = adminProfiles.getValue("totalCount");
          if (profileCount != null) {
            adminProfiles.setValue(Number(profileCount) + 1, "totalCount");
          }
        },
      });
    },
    [inviteAdmin, brandID, onCloseDialog, snackbarContext]
  );

  const listItem = (
    <Stack direction="row" spacing={2} alignItems="center" mt={2}>
      <TextField
        sx={{
          flexGrow: 1,
        }}
        value={email}
        id="outlined-basic"
        label={<Typography variant="body2">E-mail</Typography>}
        variant="outlined"
        size="small"
        InputProps={{
          sx: {
            "& .MuiInputBase-input": {
              color: "black",
            },
          },
        }}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Select size="small" label={"Role"} value={"admin"} disabled={true}>
        <MenuItem value={"admin"}>
          <Typography variant="body2" color={"black"}>
            Admin
          </Typography>
        </MenuItem>
      </Select>
    </Stack>
  );
  return (
    <Dialog open={isOpen} onClose={onCloseDialog} fullWidth>
      <DialogTitle>Invite New Users</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Invite new users by email to help manage your brand.
        </DialogContentText>
        {listItem}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => onInvite(email)}
          variant="contained"
          disabled={isInviting || email === ""}
        >
          Send Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BrandAdminInviteDialog;
