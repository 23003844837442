/**
 * @generated SignedSource<<f6f6b8856a3372da2475b1bc6e3a1147>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FeedSinglePostFormQuery$variables = {
  url: string;
};
export type FeedSinglePostFormQuery$data = {
  readonly opengraphImageFromUrl: string | null;
};
export type FeedSinglePostFormQuery = {
  response: FeedSinglePostFormQuery$data;
  variables: FeedSinglePostFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "url"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "url",
        "variableName": "url"
      }
    ],
    "kind": "ScalarField",
    "name": "opengraphImageFromUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedSinglePostFormQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeedSinglePostFormQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bca5750caf3329d2804a8655ed0bae7d",
    "id": null,
    "metadata": {},
    "name": "FeedSinglePostFormQuery",
    "operationKind": "query",
    "text": "query FeedSinglePostFormQuery(\n  $url: String!\n) {\n  opengraphImageFromUrl(url: $url)\n}\n"
  }
};
})();

(node as any).hash = "fe7ecb7465cb4d5447074952490caebf";

export default node;
