import { Box, Stack, Typography } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useContext, useState } from "react";
import { useFragment } from "react-relay";
import { ColorsCard_brand$key } from "./__generated__/ColorsCard_brand.graphql";
import { AppColorPalette } from "./__generated__/ThemeTab_brand.graphql";
import CardSection from "../../../components/CardSection";
import ColorDropdownSelector, {
  InputType,
} from "../../../components/ColorDropdownSelector";
import ColorPicker from "../../../components/ColorPicker";
import SaveButton, { SavedState } from "../../../components/SaveButton";
import {
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../contexts/MobilePreviewContext";
import useBrandAppConfigUpdateMutation from "../../../mutations/useBrandAppConfigUpdateMutation";
import ThemeContext from "./contexts/ThemeContext";

const brandFragment = graphql`
  fragment ColorsCard_brand on BrandType {
    appConfig {
      id
      buildTheme {
        id
      }
    }
  }
`;

type Props = {
  brand: ColorsCard_brand$key;
};

const ColorsCard = (props: Props) => {
  const dispatch = useMobilePreviewDispatch();

  const brand = useFragment<ColorsCard_brand$key>(brandFragment, props.brand);

  const {
    primaryPaletteLight,
    setPrimaryPaletteLight,
    accent1PaletteLight,
    setAccent1PaletteLight,
    accent2PaletteLight,
    setAccent2PaletteLight,
    accent3PaletteLight,
    setAccent3PaletteLight,
    primaryButtonBackground,
    primaryButtonForeground,
    secondaryButtonOutline,
    primaryPillBackground,
    primaryPillForeground,
    secondaryPillOutline,
    pillContainerBackground,
    badgeBackground,
    badgeForeground,
    navigationBackground,
    navigationForeground,
    setPrimaryButtonBackground,
    setPrimaryButtonForeground,
    setSecondaryButtonOutline,
    setPrimaryPillBackground,
    setPrimaryPillForeground,
    setSecondaryPillOutline,
    setPillContainerBackground,
    setBadgeBackground,
    setBadgeForeground,
    setNavigationBackground,
    setNavigationForeground,
  } = useContext(ThemeContext);
  const isPaletteSet =
    primaryPaletteLight !== "" &&
    accent1PaletteLight !== "" &&
    accent2PaletteLight !== "" &&
    accent3PaletteLight !== "";

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    isPaletteSet ? SavedState.SAVED : SavedState.DISABLED
  );
  const enableSaveButton = useCallback(() => {
    if (saveButtonState !== SavedState.ENABLED) {
      setSaveButtonState(SavedState.ENABLED);
    }
  }, [saveButtonState, setSaveButtonState]);
  const enableSaveButtonIfPaletteSet = useCallback(() => {
    isPaletteSet && enableSaveButton();
  }, [enableSaveButton, isPaletteSet]);

  const [saveMutation, isMutationInFlight] = useBrandAppConfigUpdateMutation(
    {
      id: brand.appConfig.id,
      accent1PaletteLight,
      accent2PaletteLight,
      accent3PaletteLight,
      primaryPaletteLight,
      buildTheme: {
        id: brand.appConfig.buildTheme.id,
        primaryButtonBackground,
        primaryButtonForeground,
        secondaryButtonOutline,
        primaryPillBackground,
        primaryPillForeground,
        secondaryPillOutline,
        pillContainerBackground,
        badgeBackground,
        badgeForeground,
        navigationBackground,
        navigationForeground,
      },
    },
    () => {
      setSaveButtonState(SavedState.SAVED);
    }
  );

  const colorsCardRight = (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <Stack spacing={2}>
        <Box>
          <Typography variant="subtitle1">Set up your color palette</Typography>
          <Typography sx={{ paddingRight: "32px" }} variant="body2">
            Select colors that you use on your website to maintain consistency.
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <ColorPicker
            color={primaryPaletteLight}
            onColorChange={(color: string) => {
              setPrimaryPaletteLight(color);
              enableSaveButton();
              dispatch({
                type: ReducerAction.UPDATE_THEME,
                payload: {
                  theme: {
                    primaryPaletteLight: color,
                  },
                },
              });
            }}
            text={"Primary"}
          />
          <ColorPicker
            color={accent1PaletteLight}
            onColorChange={(color: string) => {
              setAccent1PaletteLight(color);
              enableSaveButton();
              dispatch({
                type: ReducerAction.UPDATE_THEME,
                payload: {
                  theme: {
                    accent1PaletteLight: color,
                  },
                },
              });
            }}
            text={"Accent 1"}
          />
          <ColorPicker
            color={accent2PaletteLight}
            onColorChange={(color: string) => {
              setAccent2PaletteLight(color);
              enableSaveButton();
              dispatch({
                type: ReducerAction.UPDATE_THEME,
                payload: {
                  theme: {
                    accent2PaletteLight: color,
                  },
                },
              });
            }}
            text={"Accent 2"}
          />
          <ColorPicker
            color={accent3PaletteLight}
            onColorChange={(color: string) => {
              setAccent3PaletteLight(color);
              enableSaveButton();
              dispatch({
                type: ReducerAction.UPDATE_THEME,
                payload: {
                  theme: {
                    accent3PaletteLight: color,
                  },
                },
              });
            }}
            text={"Accent 3"}
          />
        </Stack>
        <Stack
          spacing={2}
          sx={{
            maxWidth: "650px",
          }}
        >
          <Box sx={{ paddingBottom: 1 }}>
            <Typography variant="subtitle1">Button Color</Typography>
            <Typography variant="body2">
              Set colors for primary buttons (including poll buttons and FAB
              button), secondary buttons (including icons), and notification
              list highlight colors.
            </Typography>
          </Box>
          <ColorDropdownSelector
            defaultPalette={"ACCENT_1"}
            palette={primaryButtonBackground}
            inputType={InputType.PRIMARY_BUTTON_BACKGROUND}
            onChange={(color: AppColorPalette | null) => {
              setPrimaryButtonBackground(color);
              enableSaveButtonIfPaletteSet();
              dispatch({
                type: ReducerAction.UPDATE_THEME,
                payload: {
                  theme: {
                    primaryButtonBackground: color,
                  },
                },
              });
            }}
          />
          <ColorDropdownSelector
            defaultPalette={"PRIMARY"}
            palette={primaryButtonForeground}
            inputType={InputType.PRIMARY_BUTTON_FOREGROUND}
            onChange={(color: AppColorPalette | null) => {
              setPrimaryButtonForeground(color);
              enableSaveButtonIfPaletteSet();
              dispatch({
                type: ReducerAction.UPDATE_THEME,
                payload: {
                  theme: {
                    primaryButtonForeground: color,
                  },
                },
              });
            }}
          />
          <ColorDropdownSelector
            defaultPalette={"PRIMARY"}
            palette={secondaryButtonOutline}
            inputType={InputType.SECONDARY_OUTLINE}
            onChange={(color: AppColorPalette | null) => {
              setSecondaryButtonOutline(color);
              enableSaveButtonIfPaletteSet();
            }}
          />
          <Box sx={{ paddingBottom: 1 }}>
            <Typography variant="subtitle1">Shortcuts Color</Typography>
            <Typography variant="body2">
              Set colors for your shortcuts - the items that live between the
              navigation bar and your main home screen.
            </Typography>
          </Box>
          <ColorDropdownSelector
            defaultPalette={"GRAY"}
            palette={primaryPillBackground}
            inputType={InputType.PRIMARY_PILL_BACKGROUND}
            onChange={(color: AppColorPalette | null) => {
              setPrimaryPillBackground(color);
              enableSaveButtonIfPaletteSet();
              dispatch({
                type: ReducerAction.UPDATE_THEME,
                payload: {
                  theme: {
                    primaryPillBackground: color,
                  },
                },
              });
            }}
          />
          <ColorDropdownSelector
            defaultPalette={"BLACK"}
            palette={primaryPillForeground}
            inputType={InputType.PRIMARY_PILL_FOREGROUND}
            onChange={(color: AppColorPalette | null) => {
              setPrimaryPillForeground(color);
              enableSaveButtonIfPaletteSet();
              dispatch({
                type: ReducerAction.UPDATE_THEME,
                payload: {
                  theme: {
                    primaryPillForeground: color,
                  },
                },
              });
            }}
          />
          <ColorDropdownSelector
            transparentOptionEnabled
            defaultPalette={"PRIMARY"}
            palette={secondaryPillOutline}
            inputType={InputType.SECONDARY_PILL_OUTLINE}
            onChange={(color: AppColorPalette | null) => {
              setSecondaryPillOutline(color);
              enableSaveButtonIfPaletteSet();
              dispatch({
                type: ReducerAction.UPDATE_THEME,
                payload: {
                  theme: {
                    secondaryPillOutline: color,
                  },
                },
              });
            }}
          />
          <ColorDropdownSelector
            transparentOptionEnabled
            defaultPalette={"PRIMARY"}
            palette={pillContainerBackground}
            inputType={InputType.PILL_CONTAINER_BACKGROUND}
            onChange={(color: AppColorPalette | null) => {
              setPillContainerBackground(color);
              enableSaveButtonIfPaletteSet();
              dispatch({
                type: ReducerAction.UPDATE_THEME,
                payload: {
                  theme: {
                    pillContainerBackground: color,
                  },
                },
              });
            }}
          />
          <Box sx={{ paddingBottom: 1 }}>
            <Typography variant="subtitle1">Badge Color</Typography>
            <Typography variant="body2">
              Set a color for all badges (for cart, menu, and live videos).
            </Typography>
          </Box>
          <ColorDropdownSelector
            defaultPalette={"ACCENT_2"}
            palette={badgeBackground}
            inputType={InputType.BADGE_BACKGROUND}
            onChange={(color: AppColorPalette | null) => {
              setBadgeBackground(color);
              enableSaveButtonIfPaletteSet();
            }}
          />
          <ColorDropdownSelector
            defaultPalette={"WHITE"}
            palette={badgeForeground}
            inputType={InputType.BADGE_FOREGROUND}
            onChange={(color: AppColorPalette | null) => {
              setBadgeForeground(color);
              enableSaveButtonIfPaletteSet();
            }}
          />

          <Box sx={{ paddingBottom: 1 }}>
            <Typography variant="subtitle1">Navigation Bar Color</Typography>
            <Typography variant="body2">
              Set a color for your navigation background and foreground colors.
            </Typography>
          </Box>
          <ColorDropdownSelector
            defaultPalette={"WHITE"}
            palette={navigationBackground}
            inputType={InputType.NAVIGATION_BACKGROUND}
            onChange={(color: AppColorPalette | null) => {
              setNavigationBackground(color);
              enableSaveButtonIfPaletteSet();
              dispatch({
                type: ReducerAction.UPDATE_THEME,
                payload: {
                  theme: {
                    navigationBackground: color,
                  },
                },
              });
            }}
          />
          <ColorDropdownSelector
            defaultPalette={"PRIMARY"}
            palette={navigationForeground}
            inputType={InputType.NAVIGATION_FOREGROUND}
            onChange={(color: AppColorPalette | null) => {
              setNavigationForeground(color);
              enableSaveButtonIfPaletteSet();
              dispatch({
                type: ReducerAction.UPDATE_THEME,
                payload: {
                  theme: {
                    navigationForeground: color,
                  },
                },
              });
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );

  return (
    <CardSection
      actions={
        <SaveButton
          savedState={
            isMutationInFlight || !isPaletteSet
              ? SavedState.DISABLED
              : saveButtonState
          }
          onClick={saveMutation}
        />
      }
      title={"Colors"}
      content={colorsCardRight}
    />
  );
};

export default ColorsCard;
