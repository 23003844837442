import { Card, Stack, Typography } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { InternBrandiOSBuildsGeneralInfo_appConfig$key } from "./__generated__/InternBrandiOSBuildsGeneralInfo_appConfig.graphql";
import CardSection from "../../components/CardSection";

const appConfigFragment = graphql`
  fragment InternBrandiOSBuildsGeneralInfo_appConfig on BrandAppConfigType {
    id
    appName
    iosAscTeamId
    iosAppVersion
    iosAppBuild
    iosAscKey
    iosAscKeyId
    iosAscIssuerId
    appTrackingDialogEnabled
  }
`;

const InternBrandiOSBuildsGeneralInfo = ({
  appConfig: appConfigKey,
}: {
  appConfig: InternBrandiOSBuildsGeneralInfo_appConfig$key;
}) => {
  const appConfig = useFragment(appConfigFragment, appConfigKey);

  return (
    <CardSection
      title={"General Info"}
      content={
        <Stack width={"100%"} spacing={2}>
          <Typography variant="subtitle1">App Name</Typography>
          <Card
            variant="outlined"
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "black",
              }}
            >
              <b>{appConfig.appName}</b>
            </Typography>
          </Card>
          <Typography variant="subtitle1">App Store Team ID</Typography>
          <Card
            variant="outlined"
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "black",
              }}
            >
              <b>{appConfig.iosAscTeamId}</b>
            </Typography>
          </Card>
          <Typography variant="subtitle1">Current Version</Typography>
          <Card
            variant="outlined"
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "black",
              }}
            >
              <b>{appConfig.iosAppVersion}</b>
            </Typography>
          </Card>
          <Typography variant="subtitle1">Current Build</Typography>
          <Card
            variant="outlined"
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "black",
              }}
            >
              <b>{appConfig.iosAppBuild}</b>
            </Typography>
          </Card>
          <Typography variant="subtitle1">App Store API Key</Typography>
          <Card
            variant="outlined"
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "black",
              }}
            >
              <b>{appConfig.iosAscKey}</b>
            </Typography>
          </Card>
          <Typography variant="subtitle1">App Store API Key ID</Typography>
          <Card
            variant="outlined"
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "black",
              }}
            >
              <b>{appConfig.iosAscKeyId}</b>
            </Typography>
          </Card>
          <Typography variant="subtitle1">App Store API Issuer ID</Typography>
          <Card
            variant="outlined"
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "black",
              }}
            >
              <b>{appConfig.iosAscIssuerId}</b>
            </Typography>
          </Card>
          <Typography variant="subtitle1">
            App Transparency Dialog Enabled
          </Typography>
          <Card
            variant="outlined"
            sx={{
              p: 2,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "black",
              }}
            >
              <b>{appConfig.appTrackingDialogEnabled ? "Yes" : "No"}</b>
            </Typography>
          </Card>
        </Stack>
      }
      variant="outlined"
    />
  );
};

export default InternBrandiOSBuildsGeneralInfo;
