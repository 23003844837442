/**
 * @generated SignedSource<<c1cddb84bae911fd7c2e9863ce1d3cf4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateHighlightAlbumOrderInput = {
  albumIds: ReadonlyArray<string>;
  brandId: string;
};
export type HighlightsTabUpdateAlbumOrderMutation$variables = {
  input: UpdateHighlightAlbumOrderInput;
};
export type HighlightsTabUpdateAlbumOrderMutation$data = {
  readonly updateHighlightAlbumOrder: {
    readonly highlightAlbums: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly archivedCount: number;
          readonly audiences: ReadonlyArray<{
            readonly audienceId: string;
          }>;
          readonly count: number;
          readonly coverImageUrl: string | null;
          readonly createdAt: any;
          readonly highlights: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly archivedAt: any | null;
                readonly caption: string | null;
                readonly id: string;
              };
            }>;
          };
          readonly id: string;
          readonly isArchived: boolean;
          readonly publishedAt: any;
          readonly title: string;
        };
      }>;
    };
    readonly id: string;
  };
};
export type HighlightsTabUpdateAlbumOrderMutation = {
  response: HighlightsTabUpdateAlbumOrderMutation$data;
  variables: HighlightsTabUpdateAlbumOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArchived",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archivedCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverImageUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "audienceId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "GraphQLHighlightConnection",
  "kind": "LinkedField",
  "name": "highlights",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLHighlightEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLHighlight",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "archivedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "caption",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HighlightsTabUpdateAlbumOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "updateHighlightAlbumOrder",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLHighlightAlbumConnection",
            "kind": "LinkedField",
            "name": "highlightAlbums",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLHighlightAlbumEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphQLHighlightAlbum",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GraphQLCDPAudience",
                        "kind": "LinkedField",
                        "name": "audiences",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HighlightsTabUpdateAlbumOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "updateHighlightAlbumOrder",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLHighlightAlbumConnection",
            "kind": "LinkedField",
            "name": "highlightAlbums",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLHighlightAlbumEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphQLHighlightAlbum",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GraphQLCDPAudience",
                        "kind": "LinkedField",
                        "name": "audiences",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7924720852919e338c47dbb67484f400",
    "id": null,
    "metadata": {},
    "name": "HighlightsTabUpdateAlbumOrderMutation",
    "operationKind": "mutation",
    "text": "mutation HighlightsTabUpdateAlbumOrderMutation(\n  $input: UpdateHighlightAlbumOrderInput!\n) {\n  updateHighlightAlbumOrder(input: $input) {\n    id\n    highlightAlbums {\n      edges {\n        node {\n          id\n          isArchived\n          archivedCount\n          count\n          coverImageUrl\n          createdAt\n          publishedAt\n          title\n          audiences {\n            audienceId\n            id\n          }\n          highlights {\n            edges {\n              node {\n                id\n                archivedAt\n                caption\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9a207f7816d4f613b6fb5004388d0208";

export default node;
