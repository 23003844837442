/**
 * @generated SignedSource<<b67d8509cc61394f7deccf4972dd7cc4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InternBrandiOSBuildsGeneralInfo_appConfig$data = {
  readonly appName: string;
  readonly appTrackingDialogEnabled: boolean;
  readonly id: string;
  readonly iosAppBuild: string;
  readonly iosAppVersion: string;
  readonly iosAscIssuerId: string;
  readonly iosAscKey: string;
  readonly iosAscKeyId: string;
  readonly iosAscTeamId: string;
  readonly " $fragmentType": "InternBrandiOSBuildsGeneralInfo_appConfig";
};
export type InternBrandiOSBuildsGeneralInfo_appConfig$key = {
  readonly " $data"?: InternBrandiOSBuildsGeneralInfo_appConfig$data;
  readonly " $fragmentSpreads": FragmentRefs<"InternBrandiOSBuildsGeneralInfo_appConfig">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternBrandiOSBuildsGeneralInfo_appConfig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iosAscTeamId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iosAppVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iosAppBuild",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iosAscKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iosAscKeyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iosAscIssuerId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appTrackingDialogEnabled",
      "storageKey": null
    }
  ],
  "type": "BrandAppConfigType",
  "abstractKey": null
};

(node as any).hash = "973d1bf4f99980abf02d2bb7858df5b1";

export default node;
