import { createTheme } from "@mui/material";

export const options = {
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: "10px",
          textTransform: "none" as const,
          fontSize: "18px",
          fontWeight: 600,
          borderRadius: "8px",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
            font-family: "GT-Flexa";
            src: url("https://kinntech.blob.core.windows.net/kinn-public-assets/GT-Flexa-Standard-Medium.ttf");
        }
      `,
    },
  },
  palette: {
    type: "light",
    primary: {
      main: "#FF6B6B",
      light: "#FFCFC5",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#5A62B8",
    },
  },
};

const theme = createTheme(options);

export default theme;
