/**
 * @generated SignedSource<<c174e8e668aa01e8618401d158644a9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WishlistCard_brand$data = {
  readonly appConfig: {
    readonly id: string;
    readonly wishlistUrl: string | null;
  };
  readonly " $fragmentType": "WishlistCard_brand";
};
export type WishlistCard_brand$key = {
  readonly " $data"?: WishlistCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"WishlistCard_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WishlistCard_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wishlistUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "4efeb37938e3b1b3679f072382e48155";

export default node;
