import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Container,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { FocusEvent, useCallback, useEffect, useState } from "react";
import { useFragment } from "react-relay";
import { useGateWithExposureLoggingDisabled } from "statsig-react";
import { BrandInputPartial } from "../../../mutations/__generated__/useBrandUpdateMutation.graphql";
import {
  IntegrationsTab_brand$data,
  IntegrationsTab_brand$key,
} from "./__generated__/IntegrationsTab_brand.graphql";
import CardSection from "../../../components/CardSection";
import CopyButton from "../../../components/CopyButton";
import RighthandDrawer from "../../../components/RighthandDrawer";
import SaveButton, { SavedState } from "../../../components/SaveButton";
import useBrandUpdateMutation from "../../../mutations/useBrandUpdateMutation";
import CookiesCard from "./integrations/CookiesCard";
import DiscourseCard from "./integrations/DiscourseCard";
import SlackCard from "./integrations/SlackCard";

enum FieldType {
  GORGIAS_API = "gorgias_api",
  KINN_API = "kinn_api",
  KLAVIYO_API = "klaviyo_api",
  KUSTOMER_API = "kustomer_api",
  OMETRIA_API = "ometria_api",
  RECHARGE_API = "recharge_api",
  FACEBOOK_API_APP_ID = "facebook_api_id",
  FACEBOOK_API_CLIENT_TOKEN = "facebook_api_client_token",
  APPSFLYER_DEV_KEY = "appsflyer_dev_key",
}

const brandFragment = graphql`
  fragment IntegrationsTab_brand on BrandType {
    ...useBrandUpdateMutation_brand
    ...CookiesCard_brand
    ...DiscourseCard_brand
    ...SlackCard_brand
    facebookAppId
    facebookClientToken
    gorgiasId
    klaviyoApiToken
    kinnApiToken
    kustomerApiToken
    ometriaApiToken
    rechargeApiToken
    appsflyerDevKey
  }
`;

type Props = {
  brand: IntegrationsTab_brand$key;
};

const getLabelFromFieldType = (fieldType: FieldType): string => {
  switch (fieldType) {
    case FieldType.GORGIAS_API:
      return "Gorgias Brand ID";
    case FieldType.KINN_API:
      return "Kinn API Key";
    case FieldType.KLAVIYO_API:
      return "Klaviyo API Key";
    case FieldType.KUSTOMER_API:
      return "Kustomer Brand ID";
    case FieldType.OMETRIA_API:
      return "Ometria API Key";
    case FieldType.RECHARGE_API:
      return "Recharge API Key";
    case FieldType.FACEBOOK_API_APP_ID:
      return "Facebook API App ID";
    case FieldType.FACEBOOK_API_CLIENT_TOKEN:
      return "Facebook API Customer Token";
    case FieldType.APPSFLYER_DEV_KEY:
      return "Appsflyer Dev Key";
  }
};

const getFieldValueFromFieldType = (
  fieldType: FieldType,
  brand: IntegrationsTab_brand$data
): string => {
  switch (fieldType) {
    case FieldType.GORGIAS_API:
      return brand.gorgiasId ?? "";
    case FieldType.KINN_API:
      return brand.kinnApiToken ?? "";
    case FieldType.KLAVIYO_API:
      return brand.klaviyoApiToken ?? "";
    case FieldType.KUSTOMER_API:
      return brand.kustomerApiToken ?? "";
    case FieldType.OMETRIA_API:
      return brand.ometriaApiToken ?? "";
    case FieldType.RECHARGE_API:
      return brand.rechargeApiToken ?? "";
    case FieldType.FACEBOOK_API_APP_ID:
      return brand.facebookAppId ?? "";
    case FieldType.FACEBOOK_API_CLIENT_TOKEN:
      return brand.facebookClientToken ?? "";
    case FieldType.APPSFLYER_DEV_KEY:
      return brand.appsflyerDevKey ?? "";
  }
};

const getInputForOnSaveFromFieldType = (
  fieldType: FieldType,
  value: string
): BrandInputPartial => {
  switch (fieldType) {
    case FieldType.GORGIAS_API:
      return {
        gorgiasId: value,
      };
    case FieldType.KLAVIYO_API:
      return {
        klaviyoApiToken: value,
      };
    case FieldType.KUSTOMER_API:
      return {
        kustomerApiToken: value,
      };
    case FieldType.OMETRIA_API:
      return {
        ometriaApiToken: value,
      };
    case FieldType.RECHARGE_API:
      return {
        rechargeApiToken: value,
      };
    case FieldType.KINN_API:
      throw new Error("Kinn API is not editable");
    case FieldType.FACEBOOK_API_APP_ID:
      return {
        facebookAppId: value,
      };
    case FieldType.FACEBOOK_API_CLIENT_TOKEN:
      return {
        facebookClientToken: value,
      };
    case FieldType.APPSFLYER_DEV_KEY:
      return {
        appsflyerDevKey: value,
      };
  }
};

const IntegrationsTabDrawer = ({
  brand,
  fieldType,
  isOpen,
  onClose,
}: {
  brand: IntegrationsTab_brand$data;
  fieldType: FieldType | null;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const label = fieldType ? "Edit " + getLabelFromFieldType(fieldType) : "";
  const [updateBrand, isBrandMutationInFlight] = useBrandUpdateMutation(brand);

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.DISABLED
  );
  const [textFieldValue, setTextFieldValue] = useState<string>("");

  const onCloseDrawer = useCallback(() => {
    onClose();
    setTextFieldValue("");
    setSaveButtonState(SavedState.DISABLED);
  }, [onClose, setTextFieldValue, setSaveButtonState]);

  useEffect(() => {
    setTextFieldValue(
      fieldType ? getFieldValueFromFieldType(fieldType, brand) : ""
    );
  }, [fieldType, brand]);

  return (
    <RighthandDrawer isOpen={isOpen} title={label} onClose={onCloseDrawer}>
      <Stack
        sx={{
          padding: 2,
        }}
        width={"100%"}
        spacing={2}
        alignItems={"flex-end"}
      >
        <TextField
          fullWidth
          margin="normal"
          label={label}
          variant="outlined"
          value={textFieldValue}
          onChange={(event: FocusEvent<HTMLInputElement>) => {
            setTextFieldValue(event.target.value);
            saveButtonState !== SavedState.ENABLED &&
              setSaveButtonState(SavedState.ENABLED);
          }}
        />
        <SaveButton
          savedState={
            isBrandMutationInFlight ? SavedState.DISABLED : saveButtonState
          }
          onClick={
            fieldType === null
              ? undefined
              : () => {
                  updateBrand(
                    getInputForOnSaveFromFieldType(fieldType, textFieldValue),
                    (_) => {
                      setSaveButtonState(SavedState.SAVED);
                      onCloseDrawer();
                    }
                  );
                }
          }
        />
      </Stack>
    </RighthandDrawer>
  );
};

const IntegrationsTab = ({ brand: brandKey }: Props) => {
  const brand = useFragment<IntegrationsTab_brand$key>(brandFragment, brandKey);

  const [drawerFieldType, setDrawerFieldType] = useState<FieldType | null>(
    null
  );
  const isDrawerOpen = drawerFieldType !== null;
  const onDrawerClose = useCallback(() => {
    setDrawerFieldType(null);
  }, [setDrawerFieldType]);
  const onEditClick = useCallback(
    (fieldType: FieldType) => {
      setDrawerFieldType(fieldType);
    },
    [setDrawerFieldType]
  );

  const { value: isBrandEligibleForDiscourse } =
    useGateWithExposureLoggingDisabled("eligible_brands_for_discourse");
  const { value: isSlackIntegrationEnabled } =
    useGateWithExposureLoggingDisabled("is_slack_integration_enabled");

  return (
    <Container maxWidth="md">
      <CardSection
        title={"API Keys"}
        content={
          <Stack>
            {Object.values(FieldType).map((fieldType, index) => {
              const value = getFieldValueFromFieldType(fieldType, brand);
              const isEmpty = value.length === 0;
              return (
                <TextField
                  key={index}
                  disabled={isEmpty}
                  margin="normal"
                  id="outlined-basic"
                  label={getLabelFromFieldType(fieldType)}
                  variant="outlined"
                  value={"•".repeat(value.length)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CopyButton textToCopy={value} isDisabled={isEmpty} />
                        {fieldType === FieldType.KINN_API ? null : (
                          <Button
                            startIcon={<EditIcon />}
                            onClick={() => onEditClick(fieldType)}
                          >
                            Edit
                          </Button>
                        )}
                      </InputAdornment>
                    ),
                    readOnly: true,
                  }}
                />
              );
            })}
          </Stack>
        }
      />
      {isSlackIntegrationEnabled && <SlackCard brand={brand} />}
      {isBrandEligibleForDiscourse && <DiscourseCard brand={brand} />}
      <CookiesCard brand={brand} />
      <IntegrationsTabDrawer
        brand={brand}
        fieldType={drawerFieldType}
        isOpen={isDrawerOpen}
        onClose={onDrawerClose}
      />
    </Container>
  );
};

export default IntegrationsTab;
