/**
 * @generated SignedSource<<8c6c4617e84765091459b81087b98f7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
export type FeedTabTablePaginationQuery$variables = {
  audienceFilter?: ReadonlyArray<string> | null;
  count?: number | null;
  cursor?: string | null;
  id: string;
  languageFilter?: ReadonlyArray<KinnLanguageCode> | null;
};
export type FeedTabTablePaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"FeedTabTable_refetchableBrand">;
  } | null;
};
export type FeedTabTablePaginationQuery = {
  response: FeedTabTablePaginationQuery$data;
  variables: FeedTabTablePaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "audienceFilter"
},
v1 = {
  "defaultValue": 10,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "languageFilter"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "audienceIds",
        "variableName": "audienceFilter"
      },
      {
        "kind": "Variable",
        "name": "audienceLanguages",
        "variableName": "languageFilter"
      }
    ],
    "kind": "ObjectValue",
    "name": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverImageUrl",
  "storageKey": null
},
v14 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "taps",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "impressions",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedTabTablePaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "audienceFilter",
                "variableName": "audienceFilter"
              },
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              {
                "kind": "Variable",
                "name": "languageFilter",
                "variableName": "languageFilter"
              }
            ],
            "kind": "FragmentSpread",
            "name": "FeedTabTable_refetchableBrand"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "FeedTabTablePaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "GraphQLFeedPostConnection",
                "kind": "LinkedField",
                "name": "feedPosts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphQLFeedPostEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GraphQLFeedPost",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "publishedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchived",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GraphQLCDPAudience",
                            "kind": "LinkedField",
                            "name": "audiences",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "audienceName",
                                "storageKey": null
                              },
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "audienceLanguages",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GraphQLLink",
                            "kind": "LinkedField",
                            "name": "link",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "externalMediaUrl",
                                "storageKey": null
                              },
                              (v10/*: any*/),
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GraphQLMedia",
                                "kind": "LinkedField",
                                "name": "media",
                                "plural": true,
                                "selections": [
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GraphqlAttriburedLink",
                                "kind": "LinkedField",
                                "name": "engagementData",
                                "plural": false,
                                "selections": (v14/*: any*/),
                                "storageKey": null
                              },
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GraphQLPoll",
                            "kind": "LinkedField",
                            "name": "poll",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GraphQLPollOption",
                                "kind": "LinkedField",
                                "name": "pollOptions",
                                "plural": true,
                                "selections": [
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "voteCount",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isCustomerCreatedOption",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isStatic",
                                    "storageKey": null
                                  },
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "optionsCount",
                                "storageKey": null
                              },
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "customResponsesEnabled",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GraphQLMedia",
                                "kind": "LinkedField",
                                "name": "media",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GraphQLCarousel",
                            "kind": "LinkedField",
                            "name": "carousel",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              (v10/*: any*/),
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GraphQLCarouselItem",
                                "kind": "LinkedField",
                                "name": "items",
                                "plural": true,
                                "selections": [
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GraphqlAttributedCaroutelItem",
                                    "kind": "LinkedField",
                                    "name": "engagementData",
                                    "plural": false,
                                    "selections": (v14/*: any*/),
                                    "storageKey": null
                                  },
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GraphQLNotification",
                            "kind": "LinkedField",
                            "name": "notification",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "filters": [
                  "filters"
                ],
                "handle": "connection",
                "key": "FeedTabTable_feedPosts",
                "kind": "LinkedHandle",
                "name": "feedPosts"
              }
            ],
            "type": "BrandType",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f89ce622785b937a2cc5a7c20ef349a7",
    "id": null,
    "metadata": {},
    "name": "FeedTabTablePaginationQuery",
    "operationKind": "query",
    "text": "query FeedTabTablePaginationQuery(\n  $audienceFilter: [String!] = []\n  $count: Int = 10\n  $cursor: String\n  $languageFilter: [KinnLanguageCode!] = []\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...FeedTabTable_refetchableBrand_2YBOow\n    id\n  }\n}\n\nfragment FeedTabTableRow_feedPost on GraphQLFeedPost {\n  id\n  type\n  publishedAt\n  isArchived\n  audiences {\n    audienceName\n    id\n  }\n  audienceLanguages\n  link {\n    externalMediaUrl\n    subtitle\n    title\n    media {\n      url\n      coverImageUrl\n      type\n    }\n    engagementData {\n      taps\n      impressions\n    }\n    id\n  }\n  poll {\n    pollOptions {\n      title\n      voteCount\n      isCustomerCreatedOption\n      isStatic\n      id\n    }\n    optionsCount\n    title\n    customResponsesEnabled\n    media {\n      url\n    }\n    id\n  }\n  carousel {\n    title\n    subtitle\n    coverImageUrl\n    items {\n      title\n      engagementData {\n        taps\n        impressions\n      }\n      id\n    }\n    id\n  }\n  notification {\n    id\n  }\n}\n\nfragment FeedTabTable_refetchableBrand_2YBOow on BrandType {\n  feedPosts(first: $count, after: $cursor, filters: {audienceIds: $audienceFilter, audienceLanguages: $languageFilter}) {\n    edges {\n      node {\n        id\n        ...FeedTabTableRow_feedPost\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "f345af093bc3480e756de83130877d29";

export default node;
