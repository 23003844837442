/**
 * @generated SignedSource<<d3162ef08214b5902cd9f4cedfc0cf21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BrandInputPartial = {
  appsflyerDevKey?: string | null;
  discourseContactEmail?: string | null;
  email?: string | null;
  facebookAppId?: string | null;
  facebookClientToken?: string | null;
  facebookConfig?: FacebookClientTokenInput | null;
  gorgiasId?: string | null;
  id?: string | null;
  klaviyoApiToken?: string | null;
  kustomerApiToken?: string | null;
  ometriaApiToken?: string | null;
  onboardingConfig?: BrandOnboardingConfigInputPartial | null;
  rechargeApiToken?: string | null;
  shopifyConfig?: BrandShopifyConfigInputPartial | null;
  showIosTosAlert?: boolean | null;
};
export type BrandShopifyConfigInputPartial = {
  id?: string | null;
  multipassSecret?: string | null;
};
export type BrandOnboardingConfigInputPartial = {
  id?: string | null;
  isAppApproved?: boolean | null;
  isAppListingComplete?: boolean | null;
  isAppSubmitted?: boolean | null;
  isDeveloperAccountSetup?: boolean | null;
  isIncentivesSetup?: boolean | null;
  isKinnAddedToDeveloperAccount?: boolean | null;
  isMarketingSetup?: boolean | null;
  isNotificationsSetup?: boolean | null;
  isThirdPartyIntegrationSetup?: boolean | null;
};
export type FacebookClientTokenInput = {
  appUserId?: string | null;
  id?: string | null;
  token?: string | null;
};
export type GetStartedTabMutation$variables = {
  input: BrandInputPartial;
};
export type GetStartedTabMutation$data = {
  readonly updateBrand: {
    readonly " $fragmentSpreads": FragmentRefs<"GetStartedTab_brand">;
  };
};
export type GetStartedTabMutation = {
  response: GetStartedTabMutation$data;
  variables: GetStartedTabMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetStartedTabMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "updateBrand",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GetStartedTab_brand"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetStartedTabMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "updateBrand",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "finishedOnboarding",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasCreatedFeedPost",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasCreatedNotification",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandOnboardingConfigType",
            "kind": "LinkedField",
            "name": "onboardingConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeveloperAccountSetup",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isKinnAddedToDeveloperAccount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isThirdPartyIntegrationSetup",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isNotificationsSetup",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isAppListingComplete",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isAppSubmitted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isAppApproved",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isIncentivesSetup",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isMarketingSetup",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandAppConfigType",
            "kind": "LinkedField",
            "name": "appConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accent1PaletteLight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accent2PaletteLight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accent3PaletteLight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "primaryPaletteLight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandAppConfigBuildThemeType",
                "kind": "LinkedField",
                "name": "buildTheme",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "launchScreenOption",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logoUrl",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "moreMenuItems",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NavigationItemType",
                "kind": "LinkedField",
                "name": "navigationItems",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6de9f38434a16e983c146d6f2d776e2a",
    "id": null,
    "metadata": {},
    "name": "GetStartedTabMutation",
    "operationKind": "mutation",
    "text": "mutation GetStartedTabMutation(\n  $input: BrandInputPartial!\n) {\n  updateBrand(input: $input) {\n    ...GetStartedTab_brand\n    id\n  }\n}\n\nfragment GetStartedTab_brand on BrandType {\n  id\n  finishedOnboarding\n  hasCreatedFeedPost\n  hasCreatedNotification\n  onboardingConfig {\n    isDeveloperAccountSetup\n    isKinnAddedToDeveloperAccount\n    isThirdPartyIntegrationSetup\n    isNotificationsSetup\n    isAppListingComplete\n    isAppSubmitted\n    isAppApproved\n    isIncentivesSetup\n    isMarketingSetup\n    id\n  }\n  appConfig {\n    accent1PaletteLight\n    accent2PaletteLight\n    accent3PaletteLight\n    primaryPaletteLight\n    buildTheme {\n      launchScreenOption\n      logoUrl\n      id\n    }\n    moreMenuItems {\n      __typename\n    }\n    navigationItems {\n      __typename\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a9ae21cad9f52445673656b1c2a10abb";

export default node;
