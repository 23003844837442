/**
 * @generated SignedSource<<8ef434efe2c68b2bdcb8039b8d249284>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HighlightsTab_brand$data = {
  readonly highlightAlbums: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly archivedCount: number;
        readonly audienceLanguages: ReadonlyArray<KinnLanguageCode> | null;
        readonly audiences: ReadonlyArray<{
          readonly audienceId: string;
        }>;
        readonly count: number;
        readonly coverImageUrl: string | null;
        readonly createdAt: any;
        readonly id: string;
        readonly isArchived: boolean;
        readonly isAutomatedAlbum: boolean | null;
        readonly publishedAt: any;
        readonly title: string;
      };
    }>;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CDPAudienceSelectorCard_brand">;
  readonly " $fragmentType": "HighlightsTab_brand";
};
export type HighlightsTab_brand$key = {
  readonly " $data"?: HighlightsTab_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"HighlightsTab_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "archivedFilter"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "audienceFilter"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "languageFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "highlightAlbums"
        ]
      }
    ]
  },
  "name": "HighlightsTab_brand",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CDPAudienceSelectorCard_brand"
    },
    (v0/*: any*/),
    {
      "alias": "highlightAlbums",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "audienceIds",
              "variableName": "audienceFilter"
            },
            {
              "kind": "Variable",
              "name": "audienceLanguages",
              "variableName": "languageFilter"
            },
            {
              "kind": "Variable",
              "name": "isArchived",
              "variableName": "archivedFilter"
            }
          ],
          "kind": "ObjectValue",
          "name": "filters"
        }
      ],
      "concreteType": "GraphQLHighlightAlbumConnection",
      "kind": "LinkedField",
      "name": "__HighlightsTab_highlightAlbums_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLHighlightAlbumEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GraphQLHighlightAlbum",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isArchived",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isAutomatedAlbum",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "audienceLanguages",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "archivedCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "count",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "coverImageUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "publishedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GraphQLCDPAudience",
                  "kind": "LinkedField",
                  "name": "audiences",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "audienceId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "0b528a6e3d76a9513b060c2b5fe29bdc";

export default node;
