/**
 * @generated SignedSource<<23eac5b39a46e27ebe8b35cec249a7c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppViewType = "ACCOUNT" | "CART" | "CHECKOUT" | "CHECKOUT_CONFIRMATION" | "COLLECTION" | "CREATE_ACCOUNT" | "FOR_YOU" | "HOME" | "HOME_LOGGED_IN" | "LOG_IN" | "LOG_OUT" | "PASSWORD_RESET" | "PDP" | "SEARCH" | "SHOPIFY_REDIRECTION" | "%future added value";
export type CDPAudienceType = "LIST" | "SEGMENT" | "%future added value";
export type CDPType = "KINN" | "KLAVIYO" | "OMETRIA" | "SHOPIFY" | "%future added value";
export type InterstitialNotificationTriggerType = "PAGE_TRIGGER" | "SESSION_DELAY" | "%future added value";
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
export type GraphQLInterstitialNotificationInput = {
  audienceLanguages?: ReadonlyArray<KinnLanguageCode> | null;
  audiences: ReadonlyArray<GraphQLCDPAudienceInput>;
  brand: NodeInput;
  callToActionDestination?: string | null;
  callToActionTitle: string;
  frequencyCap?: number | null;
  isArchived?: boolean | null;
  thumbnailUploadable: any;
  triggerAppViewType?: AppViewType | null;
  triggerDelaySeconds?: number | null;
  triggerPath?: string | null;
  triggerType: InterstitialNotificationTriggerType;
  utmCampaign?: string | null;
};
export type NodeInput = {
  id: string;
};
export type GraphQLCDPAudienceInput = {
  audienceId: string;
  audienceName: string;
  audienceType: CDPAudienceType;
  cdpType: CDPType;
};
export type InterstitialNotificationFormCreateMutation$variables = {
  input: GraphQLInterstitialNotificationInput;
};
export type InterstitialNotificationFormCreateMutation$data = {
  readonly createInterstitialNotification: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"InterstitialNotificationTableRow_notification">;
  };
};
export type InterstitialNotificationFormCreateMutation = {
  response: InterstitialNotificationFormCreateMutation$data;
  variables: InterstitialNotificationFormCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InterstitialNotificationFormCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLInterstitialNotification",
        "kind": "LinkedField",
        "name": "createInterstitialNotification",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InterstitialNotificationTableRow_notification"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InterstitialNotificationFormCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLInterstitialNotification",
        "kind": "LinkedField",
        "name": "createInterstitialNotification",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLCDPAudience",
            "kind": "LinkedField",
            "name": "audiences",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "audienceName",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "callToActionTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "callToActionDestination",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "frequencyCap",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isArchived",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "triggerType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "triggerPath",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "triggerAppViewType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "triggerDelaySeconds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "utmCampaign",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "audienceLanguages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbnailUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f8436c4cac8813ecbb8a684988817900",
    "id": null,
    "metadata": {},
    "name": "InterstitialNotificationFormCreateMutation",
    "operationKind": "mutation",
    "text": "mutation InterstitialNotificationFormCreateMutation(\n  $input: GraphQLInterstitialNotificationInput!\n) {\n  createInterstitialNotification(input: $input) {\n    id\n    ...InterstitialNotificationTableRow_notification\n  }\n}\n\nfragment InterstitialNotificationTableRow_notification on GraphQLInterstitialNotification {\n  id\n  audiences {\n    audienceName\n    id\n  }\n  callToActionTitle\n  callToActionDestination\n  frequencyCap\n  isArchived\n  triggerType\n  triggerPath\n  triggerAppViewType\n  triggerDelaySeconds\n  utmCampaign\n  audienceLanguages\n  thumbnailUrl\n}\n"
  }
};
})();

(node as any).hash = "ca407f2d6294f6626a69641bbe73611f";

export default node;
