import {
  Alert,
  AlertColor,
  Box,
  Container,
  List,
  ListItemButton,
  Snackbar,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { Suspense, useCallback, useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { InternContainerQuery } from "./__generated__/InternContainerQuery.graphql";
import KinternLogo from "../../components/internal/KinternLogo";
import SnackbarContext from "../../contexts/SnackbarContext";
import nullthrows from "../../utils/nullthrows";
import InternBrand from "./InternBrand";
import InternHome from "./InternHome";

const theme = createTheme({
  palette: {
    primary: {
      main: "#111827",
    },
  },
  typography: {
    h4: {
      color: "black", // TODO: Explicitly set due to Mobile Preview theme causing a weird override
      fontWeight: 700,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    subtitle1: {
      color: "#111827",
      fontWeight: 600,
    },
    body2: {
      color: "#6B7280",
      fontWeight: 400,
    },
    overline: {
      fontWeight: 600,
    },
    button: {
      textTransform: "none",
      fontWeight: 600,
      lineHeight: "26px",
    },
  },
});

const drawerWidth: number = 280;

const query = graphql`
  query InternContainerQuery {
    user {
      isStaff
      ...InternHome_user
    }
  }
`;

const SECTIONS = [
  {
    name: "Overview",
    path: "/overview",
  },
  {
    name: "Brands",
    path: "/brands",
  },
];

const InternContainer = () => {
  const data = useLazyLoadQuery<InternContainerQuery>(query, {});

  const navigate = useNavigate();
  const location = useLocation();

  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("info");
  const openSnackbar = useCallback(
    (message: string, severity: AlertColor) => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
    },
    [setSnackbarMessage, setSnackbarSeverity]
  );
  const snackbar = (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={2000}
      open={snackbarMessage !== null}
      onClose={() => setSnackbarMessage(null)}
    >
      <Alert severity={snackbarSeverity} sx={{ width: "100%" }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
  const snackbarProvider = useMemo(() => ({ openSnackbar }), [openSnackbar]);

  const isStaff = data.user?.isStaff ?? false;
  if (!isStaff) {
    return <Navigate to="/login" />;
  }

  const menuItems = (
    <List
      sx={{
        padding: "0 16px",
      }}
      component="nav"
    >
      {SECTIONS.map((section, index) => {
        const path = `/intern${section.path}`;
        return (
          <ListItemButton
            selected={location.pathname.includes(path)}
            onClick={() => navigate(path)}
            sx={{
              paddingY: 1,
              paddingX: 3,
              mb: 1,
              "&.Mui-selected": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: "8px",
              },
              "&.Mui-selected:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
              },
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
              },
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "24px",
              }}
            >
              {section.name}
            </Typography>
          </ListItemButton>
        );
      })}
    </List>
  );

  const sidepane = (
    <Box
      sx={{
        display: { xs: "none", md: "block" },
        flex: "0 0 auto",
        width: drawerWidth,
        backgroundColor: "#eee7d8",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          width: drawerWidth,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#eee7d8",
          zIndex: 1200,
        }}
      >
        <Box
          sx={{
            py: 3,
            width: "100%",
            px: 2,
            position: "relative",
            flexShrink: 0,
          }}
        >
          <Stack
            px={2}
            spacing={2}
            paddingBottom={4}
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <KinternLogo />
          </Stack>
          {menuItems}
        </Box>
      </Box>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <SnackbarContext.Provider value={snackbarProvider}>
        {snackbar}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "100vh",
            width: "100%",
          }}
        >
          {sidepane}
          <Container
            maxWidth="lg"
            sx={{
              paddingTop: 8,
            }}
          >
            <Routes>
              <Route
                path="/brands/"
                element={
                  <Suspense fallback={null}>
                    <InternHome user={nullthrows(data.user)} />{" "}
                  </Suspense>
                }
              />
              <Route
                path="/brands/:id/*"
                element={
                  <Suspense fallback={null}>
                    <InternBrand />
                  </Suspense>
                }
              />
            </Routes>
          </Container>
        </Box>
      </SnackbarContext.Provider>
    </ThemeProvider>
  );
};

export default InternContainer;
