import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import { FocusEvent, useContext, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useFragment, useMutation } from "react-relay";
import * as yup from "yup";
import { HtmlConfigCardMutation } from "./__generated__/HtmlConfigCardMutation.graphql";
import { HtmlConfigCard_brand$key } from "./__generated__/HtmlConfigCard_brand.graphql";
import CardSection from "../../../../components/CardSection";
import SaveButton, { SavedState } from "../../../../components/SaveButton";
import SnackbarContext from "../../../../contexts/SnackbarContext";

const brandFragment = graphql`
  fragment HtmlConfigCard_brand on BrandType {
    id
    appConfig {
      id
      htmlCustomAttributes {
        querySelector
        attributeKey
        attributeValue
      }
    }
  }
`;

const mutation = graphql`
  mutation HtmlConfigCardMutation($input: BrandAppConfigInputPartial!) {
    updateBrandAppConfig(input: $input) {
      ... on BrandType {
        id
        ...HtmlConfigCard_brand
      }
    }
  }
`;

const schema = yup
  .object({
    configs: yup
      .array()
      .of(
        yup.object({
          querySelector: yup.string().required("Required").trim(),
          attributeKey: yup.string().required("Required").trim(),
          attributeValue: yup.string().required("Required").trim(),
        })
      )
      .required(),
  })
  .required();
type FormValues = yup.InferType<typeof schema>;

const HtmlConfigCard = ({
  brand: brandKey,
}: {
  brand: HtmlConfigCard_brand$key;
}) => {
  const snackbarContext = useContext(SnackbarContext);

  const brand = useFragment(brandFragment, brandKey);
  const attributes = brand.appConfig.htmlCustomAttributes ?? [];

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.ENABLED
  );

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const onToggle = (enabled: boolean) => {
    setIsEditing(enabled);
  };
  const editToggle = (
    <FormControlLabel
      control={
        <Switch
          checked={isEditing}
          disabled={isEditing}
          onChange={(
            _event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
          ) => {
            onToggle(checked);
          }}
        />
      }
      label="Edit"
      labelPlacement="end"
    />
  );

  const [saveMutation, isMutationInFlight] =
    useMutation<HtmlConfigCardMutation>(mutation);

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm<FormValues>({
    defaultValues: {
      configs: attributes.map((a) => {
        return {
          querySelector: a.querySelector,
          attributeKey: a.attributeKey,
          attributeValue: a.attributeValue,
        };
      }),
    },
    resolver: yupResolver(schema),
  });
  const {
    fields,
    remove: removeItem,
    append: appendItem,
  } = useFieldArray({
    control,
    name: "configs",
  });

  const onSubmit = (data: FormValues) => {
    saveMutation({
      variables: {
        input: {
          id: brand.appConfig.id,
          htmlCustomAttributes: data.configs.map((a) => {
            return {
              querySelector: a.querySelector,
              attributeKey: a.attributeKey,
              attributeValue: a.attributeValue,
            };
          }),
        },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          snackbarContext?.openSnackbar("Update Failed", "error");
        } else {
          snackbarContext?.openSnackbar("Updated", "success");
          setIsEditing(false);
        }
        setSaveButtonState(SavedState.ENABLED);
      },
      onError: () => {
        snackbarContext?.openSnackbar("Update Failed", "error");
        setSaveButtonState(SavedState.ENABLED);
      },
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <CardSection
        actions={
          <SaveButton
            savedState={
              !isEditing || isMutationInFlight
                ? SavedState.DISABLED
                : saveButtonState
            }
            useSubmit={true}
          />
        }
        title={"Html Config"}
        content={
          <Stack spacing={2}>
            <Box justifyContent={"end"} display={"flex"}>
              {editToggle}
            </Box>
            {fields.map((field, index) => {
              return (
                <Stack direction="row" spacing={1} key={field.id}>
                  <TextField
                    {...register(`configs.${index}.querySelector` as const, {
                      disabled: !isEditing,
                      required: true,
                      onChange: (event: FocusEvent<HTMLInputElement>) => {
                        saveButtonState !== SavedState.ENABLED &&
                          setSaveButtonState(SavedState.ENABLED);
                      },
                    })}
                    label={"Selector"}
                    error={!!errors?.configs?.[index]?.querySelector}
                    helperText={
                      errors?.configs?.[index]?.querySelector?.message
                    }
                  />
                  <TextField
                    {...register(`configs.${index}.attributeKey` as const, {
                      disabled: !isEditing,
                      required: true,
                      onChange: (event: FocusEvent<HTMLInputElement>) => {
                        saveButtonState !== SavedState.ENABLED &&
                          setSaveButtonState(SavedState.ENABLED);
                      },
                    })}
                    label={"Attribute"}
                    error={!!errors?.configs?.[index]?.attributeKey}
                    helperText={errors?.configs?.[index]?.attributeKey?.message}
                  />
                  <TextField
                    {...register(`configs.${index}.attributeValue` as const, {
                      disabled: !isEditing,
                      required: true,
                      onChange: (event: FocusEvent<HTMLInputElement>) => {
                        saveButtonState !== SavedState.ENABLED &&
                          setSaveButtonState(SavedState.ENABLED);
                      },
                    })}
                    label={"Value"}
                    error={!!errors?.configs?.[index]?.attributeValue}
                    helperText={
                      errors?.configs?.[index]?.attributeValue?.message
                    }
                  />
                  {isEditing && (
                    <Button
                      sx={{
                        maxHeight: "56px",
                      }}
                      variant="text"
                      color="error"
                      onClick={() => {
                        removeItem(index);
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </Stack>
              );
            })}
            {isEditing && (
              <Button
                fullWidth={true}
                variant="outlined"
                onClick={() => {
                  appendItem({
                    querySelector: "",
                    attributeKey: "",
                    attributeValue: "",
                  });
                }}
              >
                <AddCircleOutlineIcon sx={{ mr: 1 }} />
                Add Item
              </Button>
            )}
          </Stack>
        }
      />
    </form>
  );
};

export default HtmlConfigCard;
