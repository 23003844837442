import { Alert, Stack, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CompanyLogo from "../../components/CompanyLogo";
import theme from "../../styles/Theme";

const ConfirmEmail = () => {
  const [isError, setIsError] = useState(false);

  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    const key = new URLSearchParams(location.search).get("key");

    const emailConfirmData = {
      key: key,
    };
    fetch("/api/v1/user/auth/register/verify-email/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailConfirmData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.detail === "ok") {
          navigate("/login");
        } else {
          setIsError(true);
        }
      })
      .catch((_) => {
        setIsError(true);
      });
  }, [location, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Stack
        alignItems="center"
        direction="column"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          color: "white",
          backgroundColor: "left.main",
          pt: 20,
        }}
      >
        <Stack
          sx={{
            width: "70%",
            maxWidth: "600px",
            borderRadius: "16px",
            border: "1px solid rgba(0, 0, 0, 0.05)",
            boxShadow: "0px 4px 60px 0px rgba(47, 55, 146, 0.13)",
            padding: "48px 68px",
          }}
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={8}
        >
          <CompanyLogo height="42px" />

          {isError && (
            <Alert
              severity="error"
              sx={{
                mt: 2,
              }}
            >
              There was an error confirming your email. Please try to{" "}
              <a href="/login">login</a> again or contact customer support for
              help
            </Alert>
          )}
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};

export default ConfirmEmail;
