import { Button, Container } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { ForumTabQuery } from "./__generated__/ForumTabQuery.graphql";
import { ForumTab_brand$key } from "./__generated__/ForumTab_brand.graphql";

const query = graphql`
  query ForumTabQuery($id: ID!) {
    brand(id: $id) {
      ...ForumTab_brand
    }
  }
`;

const fragment = graphql`
  fragment ForumTab_brand on BrandType {
    discourseBaseUrl
  }
`;

const ForumTab = ({ brandID }: { brandID: string }) => {
  const data = useLazyLoadQuery<ForumTabQuery>(query, {
    id: brandID,
  });
  const brand = useFragment<ForumTab_brand$key>(fragment, data.brand);
  const discourseBaseUrl = brand.discourseBaseUrl;
  return (
    <Container maxWidth="md">
      {discourseBaseUrl != null ? (
        <Button
          sx={{
            boxShadow:
              "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
            borderRadius: "8px",
            flexShrink: 0,
          }}
          color="primary"
          size="medium"
          variant="outlined"
          onClick={() => {
            window.open(discourseBaseUrl, "_blank", "rel=noopener noreferrer");
          }}
        >
          {"Visit Forum Admin"}
        </Button>
      ) : null}
    </Container>
  );
};

export default ForumTab;
