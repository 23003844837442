import { createContext } from "react";

interface AppContextInterface {
  activeBrandID: string;
  setActiveBrandID: (id: string) => void;
  userID: string;
}

export default createContext<AppContextInterface>({
  activeBrandID: "",
  setActiveBrandID: (id: string) => {},
  userID: "",
});
