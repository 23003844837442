import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Typography } from "@mui/material";

const RighthandDrawer = ({
  isOpen,
  title,
  onClose,
  children,
}: {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  return (
    <Drawer
      sx={{
        width: 600,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 600,
          background: "white",
          boxSizing: "border-box",
        },
      }}
      anchor={"right"}
      open={isOpen}
      onClose={onClose}
    >
      <Stack
        sx={{
          height: 80,
          display: "flex",
          padding: 2,
          borderBottom: "1px solid #EDEEF7",
          background: "#F9F9FC",
        }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">{title}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      {children}
    </Drawer>
  );
};

export default RighthandDrawer;
