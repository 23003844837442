const formatNumber = (
  value: number | undefined,
  style?: "currency" | "percent",
  currencyCode?: string,
  maximumFractionDigits: number = 2
): string => {
  if (value !== undefined && isFinite(value)) {
    const currency = currencyCode ?? "USD";
    return new Intl.NumberFormat("en-US", {
      style,
      currency: style === "currency" ? currency : undefined,
      currencyDisplay: style === "currency" ? "narrowSymbol" : undefined,
      maximumFractionDigits: maximumFractionDigits,
    }).format(value);
  } else {
    return "--";
  }
};

export default formatNumber;
