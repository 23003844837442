import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { CDPAudienceLanguageSelector_brand$key } from "./__generated__/CDPAudienceLanguageSelector_brand.graphql";
import { KinnLanguageCode, LANGUAGE_MAP } from "../utils/languageMap";

const brandFragment = graphql`
  fragment CDPAudienceLanguageSelector_brand on BrandType {
    appConfig {
      supportedLanguages
      enableLanguageSpecificContent
    }
  }
`;

export type CDPAudienceLanguageSelectorProps = {
  audienceLanguages: KinnLanguageCode[];
  brand: CDPAudienceLanguageSelector_brand$key;
  label?: String;
  onLanguageFiltersChange: (languages: KinnLanguageCode[]) => void;
  size?: "small" | "medium";
  disabled?: boolean;
};

export const CDPAudienceLanguageSelector = ({
  audienceLanguages,
  brand: brandKey,
  label,
  onLanguageFiltersChange,
  size,
  sx: selectSx,
  disabled,
}: CDPAudienceLanguageSelectorProps & Pick<SelectProps, "sx">) => {
  const brand = useFragment(brandFragment, brandKey);

  const brandLanguages = brand.appConfig.supportedLanguages?.map(
    (lang: string) => {
      const langCode = lang
        .split(/-|_/)[0] // split by dash (-) or underscore (_) for cases like en-US or en_US
        .toUpperCase() as KinnLanguageCode;
      return (
        LANGUAGE_MAP[langCode] ?? {
          code: langCode,
          label: lang,
        }
      );
    }
  );

  const fieldLabel =
    audienceLanguages.length === 0 ? "All Languages" : "Audience Languages";

  if (
    !brand.appConfig.enableLanguageSpecificContent ||
    !brandLanguages ||
    brandLanguages.length <= 1
  ) {
    return null;
  }

  return (
    <>
      {label && <Typography variant="body1">{label}</Typography>}
      <FormControl disabled={disabled}>
        <InputLabel
          size={size === "small" ? "small" : undefined}
          sx={
            size === "small"
              ? {
                  fontSize: "14px",
                }
              : {}
          }
        >
          {fieldLabel}
        </InputLabel>
        <Select
          label={fieldLabel}
          value={audienceLanguages}
          size={size}
          sx={{
            "& .MuiInputBase-input": {
              display: "flex",
            },
            ...selectSx,
            ...(size === "small"
              ? {
                  fontSize: "12px",
                  ".MuiOutlinedInput-notchedOutline": { fontSize: "14px" },
                }
              : {}),
          }}
          renderValue={(selected) => {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={LANGUAGE_MAP[value]?.label ?? value}
                    color="primary"
                    size="small"
                  />
                ))}
              </Box>
            );
          }}
          onChange={(event) => {
            onLanguageFiltersChange(event.target.value as KinnLanguageCode[]);
          }}
          multiple
        >
          {brandLanguages.map((language) => (
            <MenuItem key={language.code} value={language.code}>
              {LANGUAGE_MAP[language.code]?.label ?? language.code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
