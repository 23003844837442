import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useCallback, useContext } from "react";
import { useFragment, useMutation } from "react-relay";
import { ConnectionHandler, UploadableMap } from "relay-runtime";
import {
  GraphQLFeedPostInputPartial,
  useFeedPostUpdateMutation$data,
  useFeedPostUpdateMutation as useFeedPostUpdateMutationType,
} from "./__generated__/useFeedPostUpdateMutation.graphql";
import { useFeedPostUpdateMutation_brand$key } from "./__generated__/useFeedPostUpdateMutation_brand.graphql";
import SnackbarContext from "../contexts/SnackbarContext";

const mutation = graphql`
  mutation useFeedPostUpdateMutation($input: GraphQLFeedPostInputPartial!) {
    updateFeedPost(input: $input) {
      ... on GraphQLFeedPost {
        id
        notification {
          id
          ...NotificationTableRow_notification
        }
        ...FeedTabTableRow_feedPost
        ...FeedSinglePostForm_feedPost
        ...FeedPollForm_feedPost
        ...FeedCarouselForm_feedPost
      }
    }
  }
`;

const fragment = graphql`
  fragment useFeedPostUpdateMutation_brand on BrandType {
    id
  }
`;

const useFeedPostUpdateMutation = (
  brand: useFeedPostUpdateMutation_brand$key
): [
  (
    input: GraphQLFeedPostInputPartial,
    onCompleted?: (data: useFeedPostUpdateMutation$data) => void,
    uploadables?: UploadableMap
  ) => void,
  boolean
] => {
  const brandData = useFragment(fragment, brand);
  const brandID = brandData.id;

  const [updateMutation, isMutationInFlight] =
    useMutation<useFeedPostUpdateMutationType>(mutation);
  const snackbarContext = useContext(SnackbarContext);

  const onMutate = useCallback(
    (
      input: GraphQLFeedPostInputPartial,
      onCompleted?: (data: useFeedPostUpdateMutation$data) => void,
      uploadables?: UploadableMap
    ) => {
      updateMutation({
        variables: {
          input: {
            ...input,
          },
        },
        onCompleted: (data, errors) => {
          if (errors) {
            snackbarContext?.openSnackbar("Update Failed", "error");
          } else {
            onCompleted && onCompleted(data);
            snackbarContext?.openSnackbar("Feed Post Updated", "success");
          }
        },
        onError: (error: Error) => {
          snackbarContext?.openSnackbar("Update Failed", "error");
        },
        updater: (store) => {
          const brand = store.get(brandID);
          if (brand == null) {
            return;
          }
          const postConnection = ConnectionHandler.getConnection(
            brand,
            "FeedTabTable_feedPosts",
            {
              filters: {
                audienceIds: [],
                audienceLanguages: [],
              },
            }
          );
          if (postConnection == null) {
            return;
          }

          const sortedEdges = postConnection
            .getLinkedRecords("edges")
            ?.sort((a, b) => {
              const aTime = dayjs(
                a.getLinkedRecord("node")?.getValue("publishedAt") + ""
              ).unix();
              const bTime = dayjs(
                b.getLinkedRecord("node")?.getValue("publishedAt") + ""
              ).unix();

              return bTime - aTime;
            });
          if (sortedEdges == null) {
            return;
          }
          postConnection.setLinkedRecords(sortedEdges, "edges");
        },
        uploadables,
      });
    },
    [snackbarContext, updateMutation, brandID]
  );

  return [onMutate, isMutationInFlight];
};

export default useFeedPostUpdateMutation;
