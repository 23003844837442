import { TableCell, TableRow, Typography } from "@mui/material";
import { FeedTabTableRow_feedPost$data } from "./__generated__/FeedTabTableRow_feedPost.graphql";
import formatNumber from "../../../../utils/formatNumber";

const FeedTabCarouselTableRowDropdown = ({
  carousel,
  isOpen,
  isNew,
}: {
  carousel: FeedTabTableRow_feedPost$data["carousel"];
  isOpen: boolean;
  isNew: boolean;
}) => {
  const items = carousel?.items;

  return isOpen && items ? (
    <TableRow
      key={carousel?.title}
      sx={{
        background: isNew ? "rgba(0,0,0, 0.08)" : "white",
        transition: "background .2s ease-in",
      }}
    >
      <TableCell
        align="left"
        sx={{
          maxWidth: "100px",
          overflow: "visible",
          padding: "16px 0px 16px 16px",
        }}
      >
        {items.map((item) => {
          const taps = item.engagementData?.taps ?? 0;
          const impressions = item.engagementData?.impressions ?? 0;
          const conversionRate = taps / impressions;
          return (
            <div
              key={item.title}
              style={{
                width: "40vw",
                flexWrap: "wrap",
                display: "flex",
                flexDirection: "row",
                alignContent: "space-between",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  marginLeft: "16px",
                  lineHeight: "24px",
                  fontSize: "14px",
                }}
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  marginRight: "8px",
                  lineHeight: "24px",
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                }}
              >{`${impressions} impression${
                impressions !== 1 ? "s" : ""
              } | ${taps} click${taps !== 1 ? "s" : ""} (${formatNumber(
                conversionRate,
                "percent"
              )})`}</Typography>
            </div>
          );
        })}
      </TableCell>
      {Array(5).fill(<TableCell />)}
    </TableRow>
  ) : (
    <></>
  );
};
export default FeedTabCarouselTableRowDropdown;
