import { CDPType } from "../mutations/__generated__/useFeedPostCreateMutation.graphql";

export const getCDPTypeNameMapping = (type: CDPType): string => {
  switch (type) {
    case "SHOPIFY":
      return "Shopify";
    case "KLAVIYO":
      return "Klaviyo";
    case "KINN":
      return "Kinn";
    case "OMETRIA":
      return "Ometria";
    default:
      return "";
  }
};
