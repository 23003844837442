import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import {
  Avatar,
  Box,
  Button,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useContext, useState } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { FeedTabTableRow_feedPost$key } from "./__generated__/FeedTabTableRow_feedPost.graphql";
import CDPAudienceInfoTableCell from "../../../../components/CDPAudienceInfoTableCell";
import DebugContext from "../../../../contexts/DebugContext";
import FeedTabCarouselTableRowDropdown from "./FeedTabCarouselTableRowDropdown";
import FeedTabLinkDetails from "./FeedTabLinkDetails";
import FeedTabPollTableRowDropdown from "./FeedTabPollTableRowDropdown";

const feedPostFragment = graphql`
  fragment FeedTabTableRow_feedPost on GraphQLFeedPost {
    id
    type
    publishedAt
    isArchived
    audiences {
      audienceName
    }
    audienceLanguages
    link {
      externalMediaUrl
      subtitle
      title
      media {
        url
        coverImageUrl
        type
      }
      engagementData {
        taps
        impressions
      }
    }
    poll {
      pollOptions {
        title
        voteCount
        isCustomerCreatedOption
        isStatic
      }
      optionsCount
      title
      customResponsesEnabled
      media {
        url
      }
    }
    carousel {
      title
      subtitle
      coverImageUrl
      items {
        title
        engagementData {
          taps
          impressions
        }
      }
    }
    # We need these fields for the updater in FeedTab delete mutation
    # we don't actually use them here
    notification {
      id
    }
  }
`;

const FeedTabTableRow = ({
  isNew,
  feedPost: feedPostKey,
  onArchive,
  onDelete,
  onEditClick,
}: {
  isNew: boolean;
  feedPost: FeedTabTableRow_feedPost$key;
  onArchive: () => void;
  onDelete: () => void;
  onEditClick: () => void;
}) => {
  const theme = useTheme();
  const { debug } = useContext(DebugContext);
  const [openDetails, setOpenDetails] = useState(false);
  const navigate = useNavigate();
  const feedPost = useFragment(feedPostFragment, feedPostKey);
  const link = feedPost.link;
  const poll = feedPost.poll;

  let type = null;
  let titleText = null;
  let subtitleText = null;
  let thumbnail = null;
  switch (feedPost.type) {
    case "LINK":
      type = "Link";
      titleText = link?.title;
      subtitleText = link?.subtitle;
      thumbnail =
        link?.media?.[0]?.coverImageUrl ?? link?.externalMediaUrl ?? null;
      break;
    case "POLL":
      type = "Poll";
      titleText = poll?.title;
      subtitleText = poll?.optionsCount + " options";
      thumbnail = poll?.media?.url ?? null;
      break;
    case "CAROUSEL":
      type = "Carousel";
      titleText = feedPost.carousel?.title;
      subtitleText = feedPost.carousel?.subtitle;
      thumbnail = feedPost.carousel?.coverImageUrl ?? null;
      break;
  }

  const debugComponent = (
    <Box
      sx={{
        position: "absolute",
        top: -6,
        left: -16,
        bgcolor: "rgb(20,184,166, 0.3)",
        fontSize: "8px",
      }}
    >
      <div>
        <b>Feed Post: </b>
        {window.atob(feedPost.id).split(":")[1]}
      </div>
    </Box>
  );

  const title = (
    <ListItem>
      {debug && debugComponent}
      <ListItemAvatar>
        <Avatar
          alt=""
          src={thumbnail ?? ""}
          variant="rounded"
          sx={{ bgcolor: theme.palette.info.main, color: "black" }}
        >
          <InsertPhotoIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <Typography
            variant="body2"
            color={"black"}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {titleText}
          </Typography>
        }
        secondary={
          <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
                maxWidth: "12vw",
              }}
            >
              {subtitleText}
            </Typography>
            {feedPost.type === "POLL" && (
              <Typography
                variant="body2"
                sx={{
                  color: "#0066ff",
                  textDecoration: "underline",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
                onClick={() => setOpenDetails((prev) => !prev)}
              >
                {openDetails ? "Hide" : "View"} results
              </Typography>
            )}
            {feedPost.type === "CAROUSEL" && !!feedPost.carousel?.items.length && (
              <Typography
                variant="body2"
                sx={{
                  color: "#0066ff",
                  textDecoration: "underline",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
                onClick={() => setOpenDetails((prev) => !prev)}
              >
                {openDetails ? "Hide" : "View"} metrics
              </Typography>
            )}
            {feedPost.type === "LINK" &&
              !!feedPost.link?.engagementData?.impressions && (
                <FeedTabLinkDetails
                  taps={feedPost.link.engagementData.taps ?? 0}
                  impressions={feedPost.link.engagementData.impressions}
                />
              )}
          </div>
        }
      />
    </ListItem>
  );

  const publishedTime = feedPost.publishedAt;
  const isScheduled = dayjs(publishedTime).isAfter(dayjs());
  const isProcessing = link?.media?.[0]?.url === "";

  const status = feedPost.isArchived ? (
    <Chip
      label="Archived"
      color="info"
      sx={{
        color: "black",
      }}
    />
  ) : isScheduled ? (
    <Chip
      label="Scheduled"
      color="info"
      sx={{
        color: "black",
      }}
    />
  ) : isProcessing ? (
    <Chip
      label="Processing"
      color="info"
      sx={{
        color: "black",
      }}
    />
  ) : (
    <Chip
      label="Published"
      color="info"
      sx={{
        color: "black",
      }}
    />
  );
  const datePublished = isScheduled
    ? dayjs(publishedTime).format("llll z")
    : dayjs().to(dayjs(publishedTime));
  return (
    <>
      <TableRow
        key={link?.title}
        sx={{
          background: isNew ? "rgba(0,0,0, 0.08)" : "white",
          transition: "background .2s ease-in",
        }}
      >
        <TableCell align="center">{title}</TableCell>
        <TableCell align="center">{type}</TableCell>
        <CDPAudienceInfoTableCell item={feedPost} />
        <TableCell align="center">{status}</TableCell>
        <TableCell align="center">{datePublished}</TableCell>
        <TableCell align="center">
          <Stack direction="row">
            {!feedPost.isArchived && (
              <>
                <Button
                  disabled={isProcessing}
                  size="small"
                  variant="text"
                  color="primary"
                  onClick={() => {
                    onEditClick();
                    navigate(`/engage/feed/edit/${feedPost?.id}`, {
                      state: {
                        from: "feed_table",
                      },
                    });
                  }}
                >
                  Edit
                </Button>
                {isScheduled ? (
                  <Button
                    disabled={isProcessing}
                    size="small"
                    variant="text"
                    color="error"
                    onClick={onDelete}
                  >
                    Delete
                  </Button>
                ) : (
                  <Button
                    disabled={isProcessing}
                    size="small"
                    variant="text"
                    color="error"
                    onClick={onArchive}
                  >
                    Archive
                  </Button>
                )}
              </>
            )}
          </Stack>
        </TableCell>
      </TableRow>
      <FeedTabPollTableRowDropdown
        isOpen={openDetails}
        poll={poll}
        isNew={isNew}
      />
      <FeedTabCarouselTableRowDropdown
        isOpen={openDetails}
        carousel={feedPost.carousel}
        isNew={isNew}
      />
    </>
  );
};

export default FeedTabTableRow;
