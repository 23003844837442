import {
  Alert,
  CssBaseline,
  Stack,
  TextField,
  ThemeProvider,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import theme from "../../styles/Theme";
import SignInLink from "./SignInLink";
import SplitScreenView from "./SplitScreenView";

const schema = yup
  .object({
    email: yup
      .string()
      .required("E-mail is required")
      .email("E-mail is invalid"),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const PasswordReset = () => {
  // 0 = initial state
  // 1 = loading
  // 2 = success
  const [saveState, setSaveState] = useState(0);
  const [emailErrors, setEmailErrors] = useState("");
  const [nonFormErrors, setNonFormErrors] = useState("");
  const isLoading = saveState === 1;
  const isComplete = saveState === 2;

  let navigate = useNavigate();

  useEffect(() => {
    fetch("/api/v1/user/auth/verify")
      .then((res) => res.json())
      .then((data) => {
        if (data.is_authenticated) {
          navigate("/");
        }
      });
  }, [navigate]);

  const onSubmit = (data: FormData) => {
    setSaveState(1);
    const user = {
      email: data.email,
    };
    fetch("/api/v1/user/auth/password/reset/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        if ("detail" in data) {
          setNonFormErrors(
            "A password reset email has been sent if an email belonging to this account is registered with Kinn."
          );
          setSaveState(2);
        } else {
          if ("email" in data) {
            setEmailErrors(data.email.join(","));
          }

          if ("non_field_errors" in data) {
            setNonFormErrors(data.non_field_errors.join(","));
          }
          setSaveState(0);
        }
      });
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SplitScreenView
        rightContent={
          <Stack
            sx={{
              width: "100%",
            }}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              sx={{
                color: "black",
                fontFamily:
                  '"GT-Flexa","Roboto","Helvetica","Arial",sans-serif',
                fontWeight: 600,
                fontSize: "28px",
              }}
            >
              Reset Password
            </Typography>
            {nonFormErrors ? (
              <Alert
                severity={isComplete ? "success" : "error"}
                sx={{
                  mb: 2,
                }}
              >
                {nonFormErrors}
              </Alert>
            ) : null}

            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              {!isComplete && (
                <>
                  <TextField
                    {...register("email")}
                    error={!!errors?.email || !!emailErrors}
                    helperText={errors?.email?.message || emailErrors}
                    fullWidth
                    autoComplete="email"
                    label="E-mail Address"
                    autoFocus
                    margin="normal"
                  />
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    disabled={isLoading}
                    sx={{
                      mt: 1,
                      mb: 2,
                    }}
                    color="secondary"
                  >
                    {isLoading
                      ? "Sending"
                      : isComplete
                      ? "Sent"
                      : "Send E-mail"}
                  </Button>
                </>
              )}
              <Grid container>
                <Grid item xs>
                  <SignInLink />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        }
      />
    </ThemeProvider>
  );
};

export default PasswordReset;
