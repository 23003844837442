/**
 * @generated SignedSource<<4bff0486b0ca8f3286c750533ce0b9c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiscourseCard_brand$data = {
  readonly discourseContactEmail: string;
  readonly " $fragmentSpreads": FragmentRefs<"useBrandUpdateMutation_brand">;
  readonly " $fragmentType": "DiscourseCard_brand";
};
export type DiscourseCard_brand$key = {
  readonly " $data"?: DiscourseCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiscourseCard_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DiscourseCard_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discourseContactEmail",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useBrandUpdateMutation_brand"
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "73b45b49362539235bcaf64411c2895c";

export default node;
