/**
 * @generated SignedSource<<012124e5cea8ed55f24096d2390d047e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InternContainerQuery$variables = {};
export type InternContainerQuery$data = {
  readonly user: {
    readonly isStaff: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"InternHome_user">;
  } | null;
};
export type InternContainerQuery = {
  response: InternContainerQuery$data;
  variables: InternContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isStaff",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InternContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InternHome_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InternContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLBrandAdminProfile",
            "kind": "LinkedField",
            "name": "brandAdminProfiles",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandType",
                "kind": "LinkedField",
                "name": "brand",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrandAppConfigType",
                    "kind": "LinkedField",
                    "name": "appConfig",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "appState",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "685abfcf9b57b9c443738f55d6a570c0",
    "id": null,
    "metadata": {},
    "name": "InternContainerQuery",
    "operationKind": "query",
    "text": "query InternContainerQuery {\n  user {\n    isStaff\n    ...InternHome_user\n    id\n  }\n}\n\nfragment InternHome_user on UserType {\n  brandAdminProfiles {\n    brand {\n      id\n      displayName\n      appConfig {\n        appState\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f87741c6288663ffd0b0126db6e4f3f8";

export default node;
