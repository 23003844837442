/**
 * @generated SignedSource<<49dc6884ac7584aedee7f18ac93d04a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AutomatedNotificationType = "ABANDONED_BROWSE" | "ABANDONED_CART" | "ABANDONED_CHECKOUT" | "REENGAGEMENT" | "WELCOME_SERIES" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InsightsTabAutomatedNotificationsTable_brand$data = {
  readonly appConfig: {
    readonly automatedNotifications: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly delaySeconds: number;
          readonly id: string;
          readonly message: string;
          readonly notifType: AutomatedNotificationType;
          readonly title: string;
        };
      }>;
    };
  };
  readonly automationMetricsDaily: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly attributedSales: number;
        readonly notificationPosition: number;
        readonly notificationType: AutomatedNotificationType;
        readonly pushNotificationDeliveries: number;
        readonly pushNotificationTaps: number;
      };
    }>;
  };
  readonly currencyCode: string;
  readonly displayName: string;
  readonly id: string;
  readonly " $fragmentType": "InsightsTabAutomatedNotificationsTable_brand";
};
export type InsightsTabAutomatedNotificationsTable_brand$key = {
  readonly " $data"?: InsightsTabAutomatedNotificationsTable_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"InsightsTabAutomatedNotificationsTable_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "endDate"
    },
    {
      "kind": "RootArgument",
      "name": "startDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "InsightsTabAutomatedNotificationsTable_brand",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currencyCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "gte",
                  "variableName": "startDate"
                },
                {
                  "kind": "Variable",
                  "name": "lte",
                  "variableName": "endDate"
                }
              ],
              "kind": "ObjectValue",
              "name": "date"
            }
          ],
          "kind": "ObjectValue",
          "name": "filters"
        }
      ],
      "concreteType": "GraphQLAutomationMetricDailyConnection",
      "kind": "LinkedField",
      "name": "automationMetricsDaily",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLAutomationMetricDailyEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GraphQLAutomationMetricDaily",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "notificationType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "notificationPosition",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pushNotificationDeliveries",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pushNotificationTaps",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "attributedSales",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "filters",
              "value": {
                "isEnabled": true
              }
            }
          ],
          "concreteType": "GraphQLAutomatedNotificationConnection",
          "kind": "LinkedField",
          "name": "automatedNotifications",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GraphQLAutomatedNotificationEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GraphQLAutomatedNotification",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "notifType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "message",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "delaySeconds",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "automatedNotifications(filters:{\"isEnabled\":true})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "f5430c9044228f790ce0c1ed8133cae8";

export default node;
