import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import {
  FocusEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  useFragment,
  useLazyLoadQuery,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import { useNavigate, useNavigationType, useParams } from "react-router-dom";
import { ConnectionHandler, UploadableMap, fetchQuery } from "relay-runtime";
import * as yup from "yup";
import { NotificationFormAIQuery } from "./__generated__/NotificationFormAIQuery.graphql";
import { NotificationFormCreateMutation } from "./__generated__/NotificationFormCreateMutation.graphql";
import { NotificationFormQuery } from "./__generated__/NotificationFormQuery.graphql";
import {
  GraphQLCDPAudienceInput,
  NotificationFormUpdateMutation,
} from "./__generated__/NotificationFormUpdateMutation.graphql";
import { NotificationForm_brand$key } from "./__generated__/NotificationForm_brand.graphql";
import {
  AppDestinationLink,
  LANDING_PATHS,
  LandingPath,
  appDestinationLinkSchema,
  convertLandingPathToType,
} from "../../../../components/AppDestinationLink";
import { CDPAudienceSelectorCard } from "../../../../components/CDPAudienceSelectorCard";
import CardSection from "../../../../components/CardSection";
import LeftRight from "../../../../components/LeftRight";
import { UTMCard } from "../../../../components/UTMCard";
import UploadedFileCard from "../../../../components/UploadedFileCard";
import {
  PreviewType,
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../../contexts/MobilePreviewContext";
import SnackbarContext from "../../../../contexts/SnackbarContext";
import { KinnLanguageCode } from "../../../../utils/languageMap";
import nullthrows from "../../../../utils/nullthrows";
import { ENGAGE_NOTIFICATIONS } from "../../../../utils/routes";
import { validateUrl } from "../../../../utils/validators";

dayjs.extend(advancedFormat);
dayjs.extend(timezone);

const schema = yup
  .object({
    notifDescription: yup
      .string()
      .required("Notification description is required")
      .trim(),
    notifTitle: yup.string().required("Notification title is required").trim(),
    landingPath: appDestinationLinkSchema(),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const aiNotifQuery = graphql`
  query NotificationFormAIQuery($url: String!) {
    aiGenNotifData(url: $url) {
      title
      description
      imageUrl
    }
  }
`;

const createMutation = graphql`
  mutation NotificationFormCreateMutation($input: GraphQLNotificationInput!) {
    createNotification(input: $input) {
      ... on GraphQLNotification {
        id
        landingPath
        ...NotificationTableRow_notification
      }
    }
  }
`;

const updateMutation = graphql`
  mutation NotificationFormUpdateMutation(
    $input: GraphQLNotificationInputPartial!
  ) {
    updateNotification(input: $input) {
      ... on GraphQLNotification {
        id
        landingPath
        ...NotificationTableRow_notification
      }
    }
  }
`;

const brandFragment = graphql`
  fragment NotificationForm_brand on BrandType {
    id
    deeplinkUrlScheme
    ...CDPAudienceSelectorCard_brand
  }
`;

const query = graphql`
  query NotificationFormQuery($id: ID!, $skip: Boolean!) {
    notification(id: $id) @skip(if: $skip) {
      id
      body
      audiences {
        audienceId
      }
      audienceLanguages
      automaticArchiveTimestamp
      landingPath
      thumbnailUrl
      externalThumbnailUri
      timestamp
      title
      utmCampaign
    }
  }
`;

const NotificationForm = ({
  brand: brandKey,
  onUpdateComplete,
}: {
  brand: NotificationForm_brand$key;
  onUpdateComplete: (notifID: string | null) => void;
}) => {
  const environment = useRelayEnvironment();
  const dispatch = useMobilePreviewDispatch();
  const snackbarContext = useContext(SnackbarContext);

  const navType = useNavigationType();
  const navigate = useNavigate();
  const notificationID = useParams().id;
  const isEditing = notificationID != null;
  const data = useLazyLoadQuery<NotificationFormQuery>(query, {
    id: notificationID ?? "",
    skip: !isEditing,
  });
  const notification = data.notification;
  const publishTimestamp = notification?.timestamp ?? null;
  const archiveTimestamp = notification?.automaticArchiveTimestamp ?? null;
  const brand = useFragment(brandFragment, brandKey);
  const brandID = brand.id;
  const deeplinkUrlScheme = brand.deeplinkUrlScheme;

  const [createNotification, isCreateMutationInFlight] =
    useMutation<NotificationFormCreateMutation>(createMutation);
  const [updateNotification, isUpdateMutationInFlight] =
    useMutation<NotificationFormUpdateMutation>(updateMutation);

  const [notifTitle, setNotifTitle] = useState<string>(
    notification?.title ?? ""
  );
  const [notifDescription, setNotifDescription] = useState<string>(
    notification?.body ?? ""
  );

  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(
    notification?.thumbnailUrl ?? null
  );
  const [thumbnailUploadable, setThumbnailUploadable] = useState<UploadableMap>(
    {}
  );
  const [externalThumbnailUrl, setExternalThumbnailUrl] = useState<
    string | null
  >(notification?.externalThumbnailUri || null);

  const [audienceIDs, setAudienceIDs] = useState<string[]>(
    (notification?.audiences ?? []).map((audience) => audience.audienceId)
  );
  const [audienceDatas, setAudienceDatas] = useState<
    GraphQLCDPAudienceInput[] | null
  >(null);
  const onAudiencesChange = useCallback(
    (audiences: GraphQLCDPAudienceInput[]) => {
      setAudienceIDs(audiences.map((audience) => audience.audienceId));
      setAudienceDatas(
        audiences.map((audience) => ({
          audienceId: audience.audienceId,
          audienceName: audience.audienceName,
          audienceType: audience.audienceType,
          cdpType: audience.cdpType,
        }))
      );
    },
    [setAudienceIDs, setAudienceDatas]
  );

  const [audienceLanguages, setAudienceLanguages] = useState<
    KinnLanguageCode[]
  >(notification?.audienceLanguages?.slice() ?? []);
  const onLanguageFiltersChange = (languages: KinnLanguageCode[]) => {
    setAudienceLanguages(languages);
  };

  const [utmCampaign, setUtmCampaign] = useState<string | null>(
    notification?.utmCampaign ?? null
  );

  const isNotifPublished =
    publishTimestamp !== null && dayjs(publishTimestamp).isBefore(dayjs());

  const [isNotifScheduled, setIsNotifScheduled] = useState(
    isEditing && !isNotifPublished
  );
  const onToggleIsNotifScheduled = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsNotifScheduled(event.target.checked);
  };

  const [isAutoArchiveEnabled, setIsAutoArchiveEnabled] = useState(
    archiveTimestamp !== null
  );
  const onToggleAutoArchive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAutoArchiveEnabled(event.target.checked);
  };

  const initialTime = publishTimestamp
    ? dayjs(publishTimestamp)
    : dayjs().add(1, "day");
  const [publishDateTime, setPublishDateTime] = useState<Dayjs | null>(
    initialTime
  );
  const publishMinDateTime = dayjs().add(1, "hour");
  const publishMaxDateTime = dayjs().add(3, "months");

  const initialAutoArchiveTimestamp = archiveTimestamp
    ? dayjs(archiveTimestamp)
    : (publishDateTime ?? dayjs()).add(1, "day");
  const [archiveDateTime, setArchiveDateTime] = useState<Dayjs | null>(
    initialAutoArchiveTimestamp
  );
  const archiveMinDateTime = isNotifScheduled ? publishDateTime : dayjs();

  const onBackClick = () => {
    if (navType === "PUSH") {
      navigate(-1);
    } else {
      navigate(ENGAGE_NOTIFICATIONS);
    }
  };

  const [isImporting, setIsImporting] = useState(false);

  const formMethods = useForm<FormData>({
    defaultValues: {
      notifDescription: notifDescription,
      notifTitle: notifTitle,
      landingPath: {
        customUrl: notification?.landingPath ?? "",
        pathType:
          convertLandingPathToType(
            notification?.landingPath ?? "",
            deeplinkUrlScheme
          ) ?? "",
      },
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    resetField,
    watch,
  } = formMethods;
  const selectValue = watch("landingPath.pathType");
  const notifDestination = watch("landingPath.customUrl");

  useEffect(() => {
    dispatch({
      type: ReducerAction.SET_PREVIEW_TYPE,
      payload: {
        previewType: PreviewType.NOTIFICATION,
        notification: {
          title: notifTitle,
          body: notifDescription,
          media: thumbnailUrl ?? "",
        },
      },
    });
    return () => {
      dispatch({ type: ReducerAction.RESET });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createCard = (
    <CardSection
      title={"Compose your notification"}
      content={
        <Stack spacing={2} width="100%">
          <Typography variant="overline">1. Add a destination</Typography>
          <AppDestinationLink
            landingPaths={LANDING_PATHS}
            fieldGroupName="landingPath"
          />
          <Stack
            direction={"row"}
            alignContent={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="overline">2. Customize text</Typography>
            {selectValue === LandingPath.CUSTOM && (
              <Tooltip
                title={
                  validateUrl(notifDestination ?? "") === false
                    ? "Enter a valid URL to get AI-powered content"
                    : ""
                }
                placement="right"
                arrow
              >
                <span>
                  <LoadingButton
                    loading={isImporting}
                    disabled={validateUrl(notifDestination ?? "") === false}
                    onClick={() => {
                      fetchQuery<NotificationFormAIQuery>(
                        environment,
                        aiNotifQuery,
                        {
                          url: notifDestination ?? "",
                        }
                      ).subscribe({
                        start: () => {
                          setIsImporting(true);
                        },
                        complete: () => {
                          setIsImporting(false);
                        },
                        error: (_: Error) => {
                          setIsImporting(false);
                          snackbarContext?.openSnackbar(
                            "Failed to get image",
                            "error"
                          );
                        },
                        next: (data) => {
                          if (data.aiGenNotifData === null) {
                            snackbarContext?.openSnackbar(
                              "Generating notification failed, please try again later",
                              "error"
                            );
                          } else {
                            resetField("notifTitle", {
                              defaultValue: data.aiGenNotifData.title,
                            });
                            resetField("notifDescription", {
                              defaultValue: data.aiGenNotifData.description,
                            });
                            setNotifTitle(data.aiGenNotifData.title);
                            setNotifDescription(
                              data.aiGenNotifData.description
                            );
                            setExternalThumbnailUrl(
                              data.aiGenNotifData.imageUrl
                            );
                            dispatch({
                              type: ReducerAction.UPDATE_NOTIFICATION,
                              payload: {
                                notification: {
                                  title: data.aiGenNotifData.title,
                                  body: data.aiGenNotifData.description,
                                  media: data.aiGenNotifData.imageUrl ?? "",
                                },
                              },
                            });
                          }
                        },
                      });
                    }}
                    endIcon={<AutoAwesomeIcon />}
                    loadingPosition="end"
                    variant="outlined"
                  >
                    {isImporting ? "Generating" : "Generate"}
                  </LoadingButton>
                </span>
              </Tooltip>
            )}
          </Stack>

          <TextField
            {...register("notifTitle", {
              onChange: (event: FocusEvent<HTMLInputElement>) => {
                setNotifTitle(event.target.value);
                dispatch({
                  type: ReducerAction.UPDATE_NOTIFICATION,
                  payload: {
                    notification: {
                      title: event.target.value,
                    },
                  },
                });
              },
            })}
            error={!!errors?.notifTitle}
            helperText={errors?.notifTitle?.message}
            sx={{
              flexGrow: 1,
            }}
            margin="normal"
            label="Notification Title"
            variant="outlined"
            value={notifTitle}
            inputProps={{
              maxLength: 50,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {notifTitle != null ? notifTitle.length : 0}/50
                </InputAdornment>
              ),
            }}
          />

          <TextField
            {...register("notifDescription", {
              onChange: (event: FocusEvent<HTMLInputElement>) => {
                setNotifDescription(event.target.value);
                dispatch({
                  type: ReducerAction.UPDATE_NOTIFICATION,
                  payload: {
                    notification: {
                      body: event.target.value,
                    },
                  },
                });
              },
            })}
            error={!!errors?.notifDescription}
            helperText={errors?.notifDescription?.message}
            sx={{
              flexGrow: 1,
            }}
            margin="normal"
            multiline
            label="Notification Description"
            variant="outlined"
            value={notifDescription}
            inputProps={{
              maxLength: 162,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {notifDescription.length}/162
                </InputAdornment>
              ),
            }}
            rows={3}
          />

          <Stack spacing={0}>
            <Typography variant="overline">
              3. Notification Thumbnail (Optional)
            </Typography>
            <Typography variant="body2">
              Add a thumbnail to drive awareness and engagement when you notify
              your customers.
            </Typography>
          </Stack>
          <UploadedFileCard
            fileURL={thumbnailUrl || externalThumbnailUrl}
            fileType=".svg, .png, .jpg, .gif, .mp4"
            htmlLabel="feed-post-form"
            mediaType={"PHOTO"}
            inputLabel="Media Asset"
            inputText={"Upload a photo, video, or gif"}
            onClose={() => {
              setExternalThumbnailUrl(null);
              setThumbnailUrl(null);
              setThumbnailUploadable({});
              dispatch({
                type: ReducerAction.UPDATE_NOTIFICATION,
                payload: {
                  notification: {
                    media: "",
                  },
                },
              });
            }}
            onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
              const file = event?.target?.files?.[0];
              if (file == null) {
                return;
              }
              const url = URL.createObjectURL(file);
              dispatch({
                type: ReducerAction.UPDATE_NOTIFICATION,
                payload: {
                  notification: {
                    media: url,
                  },
                },
              });
              setThumbnailUrl(url);
              setExternalThumbnailUrl(null);
              const newUploadables: UploadableMap = {};
              newUploadables["thumbnailUploadable"] = file;
              setThumbnailUploadable(newUploadables);
            }}
          />
        </Stack>
      }
    />
  );

  const utmCard =
    selectValue !== LandingPath.FOR_YOU ? (
      <UTMCard campaign={utmCampaign} onChange={setUtmCampaign} />
    ) : null;

  const audienceCard = (
    <CDPAudienceSelectorCard
      audienceIDs={audienceIDs}
      brand={brand}
      subtitle={"Choose who you want to send your notification to"}
      audienceLanguages={audienceLanguages}
      onAudiencesChange={onAudiencesChange}
      onLanguageFiltersChange={onLanguageFiltersChange}
    />
  );

  const scheduleCard = (
    <CardSection
      showIsOptional
      title={"Schedule"}
      content={
        <Stack spacing={2} width={"100%"}>
          <LeftRight
            expandLeft={true}
            left={
              <Stack>
                <Typography variant="subtitle1">
                  Schedule your notification?
                </Typography>
                <Typography variant="body2">
                  Instead of sending your notification now, schedule for any
                  date/time
                </Typography>
              </Stack>
            }
            right={
              <Switch
                checked={isNotifScheduled}
                onChange={onToggleIsNotifScheduled}
              />
            }
          />
          {isNotifScheduled && (
            <Stack
              direction={"row"}
              component="form"
              sx={{
                alignItems: "center",
              }}
              noValidate
              autoComplete="off"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{
                    mr: 1,
                  }}
                  minDateTime={publishMinDateTime}
                  maxDateTime={publishMaxDateTime}
                  value={publishDateTime}
                  onChange={(newTime) => {
                    setPublishDateTime(newTime);
                  }}
                />
              </LocalizationProvider>
              {publishDateTime?.format("z")}
            </Stack>
          )}
        </Stack>
      }
    />
  );

  const archiveCard = (
    <CardSection
      showIsOptional
      title={"Auto Archive"}
      content={
        <Stack spacing={2} width={"100%"}>
          <LeftRight
            expandLeft={true}
            left={
              <Stack>
                <Typography variant="subtitle1">
                  Enable auto archive?
                </Typography>
                <Typography variant="body2">
                  Select a time when this notification will automatically
                  archive and no longer be visible within the app. If no time is
                  selected, the notification will be visible until it is
                  manually archived.
                </Typography>
              </Stack>
            }
            right={
              <Switch
                checked={isAutoArchiveEnabled}
                onChange={onToggleAutoArchive}
              />
            }
          />
          {isAutoArchiveEnabled && (
            <Stack
              direction={"row"}
              component="form"
              sx={{
                alignItems: "center",
              }}
              noValidate
              autoComplete="off"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{
                    mr: 1,
                  }}
                  minDateTime={archiveMinDateTime}
                  value={archiveDateTime}
                  onChange={(newTime) => {
                    setArchiveDateTime(newTime);
                  }}
                />
              </LocalizationProvider>
              {publishDateTime?.format("z")}
            </Stack>
          )}
        </Stack>
      }
    />
  );

  const onSaveClick = (data: FormData) => {
    const isAudienceSet = audienceIDs.length > 0;
    createNotification({
      variables: {
        input: {
          brand: {
            id: brandID,
          },
          audience: isAudienceSet ? "SEGMENTED" : "EVERYONE",
          audiences:
            audienceDatas === null
              ? []
              : audienceDatas.map((audience) => ({
                  audienceId: audience.audienceId,
                  audienceName: audience.audienceName,
                  audienceType: audience.audienceType,
                  cdpType: audience.cdpType,
                })),
          audienceLanguages: audienceLanguages,
          automaticArchiveTimestamp: isAutoArchiveEnabled
            ? archiveDateTime
            : null,
          title: notifTitle,
          body: notifDescription,
          timestamp: isNotifScheduled ? publishDateTime : dayjs(),
          landingPath: LANDING_PATHS[
            data.landingPath.pathType as LandingPath
          ]!.finalPath(data.landingPath.customUrl ?? "", deeplinkUrlScheme),
          externalThumbnailUri:
            thumbnailUrl === null || thumbnailUrl === ""
              ? externalThumbnailUrl ?? undefined
              : undefined,
          utmCampaign: utmCampaign,
        },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          snackbarContext?.openSnackbar("Something went wrong", "error");
        } else {
          setThumbnailUploadable({});
          onUpdateComplete(data.createNotification.id);
          onBackClick();
          snackbarContext?.openSnackbar(
            isNotifScheduled
              ? "Notification scheduled"
              : "Notification created",
            "success"
          );
        }
      },
      onError: (error: Error) => {
        snackbarContext?.openSnackbar("Something went wrong", "error");
      },
      updater: (store) => {
        const payload = store.getRootField("createNotification");
        const brand = store.get(brandID);
        if (brand == null) {
          return;
        }
        if (brand.getValue("hasCreatedNotification") === false) {
          brand.setValue(true, "hasCreatedNotification");
        }
        const notifConnection = ConnectionHandler.getConnection(
          brand,
          "NotificationsTab_notifications"
        );
        if (notifConnection == null) {
          return;
        }

        const newEdge = ConnectionHandler.createEdge(
          store,
          notifConnection,
          payload,
          "GraphQLNotificationEdge"
        );
        ConnectionHandler.insertEdgeBefore(notifConnection, newEdge);

        // Increment total conut
        const notifCount = notifConnection.getValue("totalCount");
        if (notifCount != null) {
          notifConnection.setValue(Number(notifCount) + 1, "totalCount");
        }

        const sortedEdges = notifConnection
          .getLinkedRecords("edges")
          ?.sort((a, b) => {
            const aTime = dayjs(
              a.getLinkedRecord("node")?.getValue("timestamp") + ""
            ).unix();
            const bTime = dayjs(
              b.getLinkedRecord("node")?.getValue("timestamp") + ""
            ).unix();

            return bTime - aTime;
          });
        if (sortedEdges == null) {
          return;
        }
        notifConnection.setLinkedRecords(sortedEdges, "edges");
      },
      uploadables: thumbnailUploadable,
    });
  };
  const onUpdateClick = (data: FormData) => {
    const isAudienceSet = audienceIDs.length > 0;
    updateNotification({
      variables: {
        input: {
          id: nullthrows(notificationID),
          audience: isAudienceSet ? "SEGMENTED" : "EVERYONE",
          audiences:
            audienceDatas === null
              ? undefined
              : audienceDatas.map((audience) => ({
                  audienceId: audience.audienceId,
                  audienceName: audience.audienceName,
                  audienceType: audience.audienceType,
                  cdpType: audience.cdpType,
                })),
          audienceLanguages: audienceLanguages,
          automaticArchiveTimestamp: isAutoArchiveEnabled
            ? archiveDateTime
            : null,
          title: notifTitle,
          body: notifDescription,
          timestamp: isNotifScheduled ? publishDateTime : dayjs(),
          landingPath: LANDING_PATHS[
            data.landingPath.pathType as LandingPath
          ]!.finalPath(data.landingPath.customUrl ?? "", deeplinkUrlScheme),
          thumbnailUploadable: thumbnailUrl === null ? null : undefined,
          externalThumbnailUri:
            thumbnailUrl === null || thumbnailUrl === ""
              ? externalThumbnailUrl ?? undefined
              : undefined,
          utmCampaign: utmCampaign,
        },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          console.log("Error: ", errors);
        } else {
          setThumbnailUploadable({});
          onUpdateComplete(data.updateNotification.id);
          onBackClick();
        }
      },
      updater: (store) => {
        const brand = store.get(brandID);
        if (brand == null) {
          return;
        }
        const notifConnection = ConnectionHandler.getConnection(
          brand,
          "NotificationsTab_notifications"
        );
        if (notifConnection == null) {
          return;
        }
        const sortedEdges = notifConnection
          .getLinkedRecords("edges")
          ?.sort((a, b) => {
            const aTime = dayjs(
              a.getLinkedRecord("node")?.getValue("timestamp") + ""
            ).unix();
            const bTime = dayjs(
              b.getLinkedRecord("node")?.getValue("timestamp") + ""
            ).unix();

            return bTime - aTime;
          });
        if (sortedEdges == null) {
          return;
        }
        notifConnection.setLinkedRecords(sortedEdges, "edges");
      },
      onError: (error: Error) => {
        console.log("Error: ", error);
      },
      uploadables: thumbnailUploadable,
    });
  };

  const isSaveDisabled =
    (isNotifScheduled &&
      (!publishDateTime?.isValid() ||
        publishMinDateTime.isAfter(publishDateTime))) ||
    (isAutoArchiveEnabled &&
      (!archiveDateTime?.isValid() ||
        !archiveDateTime?.isAfter(publishDateTime)));
  const onSubmit = (data: FormData) => {
    if (notificationID != null) {
      onUpdateClick(data);
    } else {
      onSaveClick(data);
    }
  };

  const saveButton = (
    <Box display="flex" justifyContent="flex-end">
      <Button
        type="submit"
        disabled={
          isCreateMutationInFlight || isUpdateMutationInFlight || isSaveDisabled
        }
        variant="contained"
        size="large"
        endIcon={<ArrowForwardIcon color="inherit" />}
      >
        {isNotifScheduled
          ? "Schedule Push Notification"
          : "Send Push Notification"}
      </Button>
    </Box>
  );

  return (
    <FormProvider {...formMethods}>
      <Stack
        component={"form"}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        spacing={2}
        width="100%"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box display="flex" justifyContent="flex-start">
          <Button
            startIcon={<ArrowBackIcon />}
            variant="text"
            onClick={onBackClick}
          >
            Your Notifications
          </Button>
        </Box>
        {createCard}
        {audienceCard}
        {utmCard}
        {isNotifPublished ? null : scheduleCard}
        {archiveCard}
        {saveButton}
      </Stack>
    </FormProvider>
  );
};

export default NotificationForm;
