type LogoColor = "black" | "white";

type Props = {
  color?: LogoColor;
  height?: string;
};

const CompanyLogo = ({ color = "white", height = "30px" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 60 33"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.6634 12.7453C42.0701 12.7455 38.3466 16.4751 38.3466 21.0758V22.7419C38.3466 24.2917 37.0923 25.548 35.545 25.548C33.9978 25.548 32.7435 24.2917 32.7435 22.7419V16.3406C32.7435 14.3549 31.1364 12.7453 29.1539 12.7453C27.1715 12.7453 25.5644 14.3549 25.5644 16.3406V23.0603C25.5644 25.3258 22.8298 26.4605 21.2303 24.8587L17.0958 20.7181C16.1041 19.725 16.104 18.1148 17.0956 17.1216L19.8885 14.3242C22.1502 12.0589 20.5484 8.18538 17.3498 8.18538C16.3976 8.18538 15.4843 8.56425 14.811 9.23865L11.5133 12.5417C9.91384 14.1437 7.17905 13.0091 7.17905 10.7435V3.71319C7.17905 1.72757 5.57196 0.117902 3.58952 0.117902C1.60708 0.117902 0 1.72757 0 3.71319V28.9679C0 30.9535 1.60708 32.5632 3.58952 32.5632C5.57196 32.5632 7.17905 30.9535 7.17905 28.9679V28.1075C7.17905 27.433 7.44654 26.7862 7.92268 26.3093L8.42872 25.8024C9.42024 24.8093 11.0278 24.8093 12.0193 25.8024L16.6409 30.4314C18.0037 31.7964 19.8519 32.5632 21.7792 32.5632L39.9225 32.5632L39.9599 32.563L39.9756 32.5629L39.9891 32.5631L40.0101 32.5632C43.0562 32.5632 45.5257 30.0898 45.5257 27.0387V22.7419C45.5257 20.9016 47.0152 19.4097 48.8525 19.4097C50.6899 19.4097 52.1794 20.9016 52.1794 22.7419V28.7925C52.1794 30.7782 53.7865 32.3878 55.7689 32.3878C57.7514 32.3878 59.3585 30.7782 59.3585 28.7925V21.0758C59.3585 16.5299 55.7229 12.8343 51.2055 12.7469L46.6634 12.7453ZM25.4769 3.71319C25.4769 5.74725 27.1231 7.39617 29.1539 7.39617C31.1847 7.39617 32.831 5.74725 32.831 3.71319C32.831 1.67914 31.1847 0.0302124 29.1539 0.0302124C27.1231 0.0302124 25.4769 1.67914 25.4769 3.71319Z"
        fill="#FF6B6B"
      />
    </svg>
  );
};
export default CompanyLogo;
