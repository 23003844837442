import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Card,
  CardMedia,
  Checkbox,
  LinearProgress,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useMobilePreviewState } from "../../contexts/MobilePreviewContext";

const PollOption = ({
  isChecked,
  index,
  isResultShown,
  option,
  result,
  onVote,
}: {
  isChecked: boolean;
  index: number;
  isResultShown: boolean;
  option: {
    title: string;
    media: string;
  };
  result: number;
  onVote: (show: boolean) => void;
}) => {
  const theme = useTheme();

  const image =
    option.media === "" ? (
      <div
        style={{
          background: "#C4C4C4",
          borderRadius: "50%",
          height: "28px",
          width: "28px",
        }}
      />
    ) : (
      <img
        alt=""
        style={{
          borderRadius: "50%",
          height: "28px",
          width: "28px",
        }}
        src={option.media}
      />
    );
  const unchecked = (
    <Box
      sx={{
        width: "24px",
        height: "24px",
        border: "1px solid",
        borderRadius: "4px",
      }}
    />
  );
  const checked = (
    <CheckIcon
      sx={{
        width: "24px",
        height: "24px",
        background: theme.palette.primaryButtonBg.main,
        borderRadius: "4px",
        color: theme.palette.primaryButtonFg.main,
      }}
    />
  );
  return (
    <ListItem>
      <Box flexShrink={0}>{image}</Box>
      <ListItemText
        sx={{ marginLeft: 2, marginRight: "4px" }}
        primary={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography
              variant="body2"
              sx={{
                marginBottom: "4px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              {option.title || "Option " + (index + 1)}
            </Typography>
            {isResultShown ? (
              <Typography variant="body2" flexShrink={0}>
                {result + " %"}
              </Typography>
            ) : undefined}
          </Stack>
        }
        secondary={
          isResultShown ? (
            <LinearProgress
              variant="determinate"
              value={result}
              sx={{
                borderRadius: "4px",
                "&.MuiLinearProgress-root": {
                  background: "white",
                },
                "& .MuiLinearProgress-bar": {
                  backgroundColor: theme.palette.palettePrimary.main,
                },
              }}
            />
          ) : undefined
        }
      />
      <Checkbox
        checked={isChecked}
        onChange={(_) => {
          onVote(!isChecked);
        }}
        checkedIcon={checked}
        icon={unchecked}
      />
    </ListItem>
  );
};

const PollPreview = () => {
  const { poll } = useMobilePreviewState();

  const [checkedState, setCheckedState] = useState<Array<boolean>>(
    new Array(poll.options.length).fill(false)
  );

  const pollOptions = poll.options.map((option, index) => {
    const result = Math.floor(
      ((checkedState[index] ? 1 : 0) / checkedState.filter(Boolean).length) *
        100
    );
    return (
      <PollOption
        index={index}
        key={index}
        isChecked={checkedState[index]}
        isResultShown={checkedState.includes(true)}
        option={option}
        result={result}
        onVote={(selected: boolean) => {
          let newState = Array.from(checkedState);
          newState[index] = selected;
          setCheckedState(newState);
        }}
      />
    );
  });

  return (
    <Card
      sx={{
        borderRadius: "12px",
        position: "relative",
        textAlign: "center",
      }}
    >
      {poll.media === "" ? (
        <div
          style={{
            background: "#C4C4C4",
            aspectRatio: "16 / 9",
          }}
        />
      ) : (
        <CardMedia
          sx={{
            background: "#C4C4C4",
            aspectRatio: "16 / 9",
          }}
          component="img"
          image={poll.media}
        />
      )}
      <Typography
        variant="header0"
        component={"div"}
        marginTop={"12px"}
        sx={{
          paddingX: 2,
          lineHeight: "24px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        }}
      >
        {poll.title || "Poll title that can wrap to two lines maximum"}
      </Typography>
      {pollOptions}
    </Card>
  );
};

export default PollPreview;
