import { Box, Stack } from "@mui/material";

type Props = {
  expandLeft?: boolean;
  left: React.ReactNode;
  right: React.ReactNode;
};

const LeftRight = ({ expandLeft = false, left, right }: Props) => {
  return (
    <Stack direction={"row"} spacing={2} display="flex" alignItems="center">
      {expandLeft ? <Box flexGrow={1}>{left}</Box> : left}
      {right}
    </Stack>
  );
};

export default LeftRight;
