import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useEffect, useState } from "react";
import { useMutation, usePaginationFragment } from "react-relay";
import { DailyDealsTablePaginationQuery } from "./__generated__/DailyDealsTablePaginationQuery.graphql";
import { DailyDealsTableUpdateArchivedMutation } from "./__generated__/DailyDealsTableUpdateArchivedMutation.graphql";
import { DailyDealsTable_refetchableBrand$key } from "./__generated__/DailyDealsTable_refetchableBrand.graphql";
import { KinnLanguageCode } from "../../../../utils/languageMap";
import nullthrows from "../../../../utils/nullthrows";
import DailyDealTableRow from "./DailyDealTableRow";

const brandFragment = graphql`
  fragment DailyDealsTable_refetchableBrand on BrandType
  @refetchable(queryName: "DailyDealsTablePaginationQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 10 }
    cursor: { type: "String" }
    audienceFilter: { type: "[String!]", defaultValue: [] }
    languageFilter: { type: "[KinnLanguageCode!]", defaultValue: [] }
  ) {
    dailyDeals(
      first: $count
      after: $cursor
      filters: {
        audienceIds: $audienceFilter
        audienceLanguages: $languageFilter
      }
    ) @connection(key: "DailyDealsTable_dailyDeals") {
      edges {
        node {
          id
          ...DailyDealTableRow_dailyDeal
        }
      }
      totalCount
    }
  }
`;

const archiveMutation = graphql`
  mutation DailyDealsTableUpdateArchivedMutation(
    $input: GraphQLDailyDealInputPartial!
  ) {
    updateDailyDeal(input: $input) {
      ... on GraphQLDailyDeal {
        archivedAt
      }
    }
  }
`;

export default function DailyDealsTable({
  brandFragment: brandKey,
  tableSearchAudiences,
  tableSearchLanguages,
}: {
  brandFragment: DailyDealsTable_refetchableBrand$key;
  tableSearchAudiences: string[];
  tableSearchLanguages: KinnLanguageCode[];
}) {
  const {
    data: refetchableBrand,
    loadNext,
    isLoadingNext,
    refetch,
  } = usePaginationFragment<
    DailyDealsTablePaginationQuery,
    DailyDealsTable_refetchableBrand$key
  >(brandFragment, brandKey);
  const dailyDeals = refetchableBrand.dailyDeals.edges ?? [];

  const [tablePage, setTablePage] = useState(0);
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
  const handleChangePage = (_event: unknown, newPage: number) => {
    if (newPage > tablePage) {
      loadNext(tableRowsPerPage);
    }
    setTablePage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    refetch({
      count: +event.target.value,
      audienceFilter: tableSearchAudiences,
      languageFilter: tableSearchLanguages,
    });
    setTableRowsPerPage(+event.target.value);
    setTablePage(0);
  };

  useEffect(() => {
    refetch({
      audienceFilter: tableSearchAudiences,
      languageFilter: tableSearchLanguages,
    });
    setTablePage(0);
  }, [refetch, tableSearchAudiences, tableSearchLanguages]);

  // - ARCHIVING
  const [archiveDailyDeal, isArchiving] =
    useMutation<DailyDealsTableUpdateArchivedMutation>(archiveMutation);
  const [dialogRowId, setDialogRowId] = useState<string | null>(null);
  const isDialogOpen = dialogRowId !== null;
  const onDialogClose = () => {
    setDialogRowId(null);
  };
  const onArchive = useCallback(
    (id: string, archive: boolean) => {
      archiveDailyDeal({
        variables: {
          input: {
            id,
            isArchived: archive,
          },
        },
        onCompleted: (data, errors) => {
          if (errors) {
            console.log("Error: ", data, errors);
          } else {
            onDialogClose();
          }
        },
      });
    },
    [archiveDailyDeal]
  );

  const dialogView = isDialogOpen ? (
    <Dialog open={isDialogOpen} onClose={onDialogClose}>
      <DialogTitle>Are you sure you want to archive this?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The daily deal will no longer be visible to customers.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={isArchiving}
          onClick={() => onArchive(nullthrows(dialogRowId), true)}
          color="error"
        >
          {isArchiving ? "Archiving" : "Archive"}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, tableLayout: "fixed" }}
          aria-label="simple table"
          size="small"
        >
          <TableHead
            sx={{
              background: "#F3F4F6",
            }}
          >
            <TableRow>
              <TableCell width="30%">
                <Typography variant="overline">Deal</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="overline">Type</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="overline">Audience</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="overline">Interval</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="overline">Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dailyDeals
              .slice(
                tablePage * tableRowsPerPage,
                tablePage * tableRowsPerPage + tableRowsPerPage
              )
              .map((deal) => (
                <DailyDealTableRow
                  key={deal.node.id}
                  dailyDeal={deal.node}
                  onArchive={() => {
                    setDialogRowId(deal.node.id);
                  }}
                  onUnArchive={() => {
                    onArchive(deal.node.id, false);
                  }}
                />
              ))}
            {isLoadingNext &&
              dailyDeals.length <
                (refetchableBrand.dailyDeals.totalCount ?? 0) &&
              dailyDeals.length <= (tablePage + 1) * tableRowsPerPage && (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    align="center"
                    sx={{
                      padding: "24px 0",
                    }}
                  >
                    <CircularProgress color="primary" size={32} />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={5}
                align="center"
                rowsPerPageOptions={[10, 25]}
                count={refetchableBrand.dailyDeals.totalCount ?? 0}
                rowsPerPage={tableRowsPerPage}
                page={tablePage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {dialogView}
    </>
  );
}
