/**
 * @generated SignedSource<<082e76bc7cafd48306d1bbd91d5f9808>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlanInterval = "EVERY_30_DAYS" | "EVERY_365_DAYS" | "%future added value";
export type GraphQLPendingBillingConfigInputPartial = {
  createdAt?: any | null;
  id?: string | null;
  interval: PlanInterval;
  isBlocking?: boolean | null;
  isImmediate?: boolean | null;
  price?: any | null;
  takeRate?: any | null;
  takeRateMinUsage?: any | null;
  takeRateTotalCap?: any | null;
  trialDays?: number | null;
};
export type InternBrandBillingRecurringSubCardMutation$variables = {
  bcId: string;
  input: GraphQLPendingBillingConfigInputPartial;
};
export type InternBrandBillingRecurringSubCardMutation$data = {
  readonly createOrUpdatePendingBillingConfig: {
    readonly billingConfig: {
      readonly " $fragmentSpreads": FragmentRefs<"InternBrandBillingRecurringSubCard_billingConfig">;
    };
  };
};
export type InternBrandBillingRecurringSubCardMutation = {
  response: InternBrandBillingRecurringSubCardMutation$data;
  variables: InternBrandBillingRecurringSubCardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bcId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "bcId",
    "variableName": "bcId"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "takeRate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "takeRateTotalCap",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "takeRateMinUsage",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InternBrandBillingRecurringSubCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLPendingBillingConfig",
        "kind": "LinkedField",
        "name": "createOrUpdatePendingBillingConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLBillingConfig",
            "kind": "LinkedField",
            "name": "billingConfig",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InternBrandBillingRecurringSubCard_billingConfig"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InternBrandBillingRecurringSubCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLPendingBillingConfig",
        "kind": "LinkedField",
        "name": "createOrUpdatePendingBillingConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLBillingConfig",
            "kind": "LinkedField",
            "name": "billingConfig",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planCreatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planTrialDays",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planCurrency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planInterval",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLPendingBillingConfig",
                "kind": "LinkedField",
                "name": "pendingBillingConfig",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isBlocking",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isImmediate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "interval",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trialDays",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLShopifyBillingConfig",
                "kind": "LinkedField",
                "name": "shopifyBillingConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appSubscriptionLineItemId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e7b22c89dbbee9650887480e6e7703cc",
    "id": null,
    "metadata": {},
    "name": "InternBrandBillingRecurringSubCardMutation",
    "operationKind": "mutation",
    "text": "mutation InternBrandBillingRecurringSubCardMutation(\n  $bcId: ID!\n  $input: GraphQLPendingBillingConfigInputPartial!\n) {\n  createOrUpdatePendingBillingConfig(bcId: $bcId, input: $input) {\n    billingConfig {\n      ...InternBrandBillingRecurringSubCard_billingConfig\n      id\n    }\n    id\n  }\n}\n\nfragment InternBrandBillingRecurringSubCard_billingConfig on GraphQLBillingConfig {\n  id\n  planCreatedAt\n  planTrialDays\n  planCurrency\n  planId\n  planInterval\n  planPrice\n  planStatus\n  startDate\n  takeRate\n  takeRateTotalCap\n  takeRateMinUsage\n  pendingBillingConfig {\n    id\n    isBlocking\n    isImmediate\n    createdAt\n    currency\n    price\n    interval\n    takeRate\n    takeRateTotalCap\n    takeRateMinUsage\n    trialDays\n  }\n  shopifyBillingConfig {\n    appSubscriptionLineItemId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ca56a6dab1deb99c68f2ea2d5403a0b8";

export default node;
