import CheckIcon from "@mui/icons-material/Check";
import { Button, useTheme } from "@mui/material";

export enum SavedState {
  DISABLED = "disabled",
  ENABLED = "enabled",
  SAVED = "saved",
  SAVING = "saving",
}

type Props = {
  onClick?: () => void;
  savedState: SavedState;
  useSubmit?: boolean;
};

const SaveButton = ({ savedState, onClick, useSubmit = false }: Props) => {
  const theme = useTheme();

  if (savedState === SavedState.SAVING) {
    return (
      <Button disabled={true} variant="contained">
        Saving...
      </Button>
    );
  } else if (savedState === SavedState.SAVED) {
    return (
      <Button
        sx={{
          "&.Mui-disabled": {
            color: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
          },
        }}
        disabled={true}
        startIcon={<CheckIcon />}
        variant="outlined"
        onClick={onClick}
      >
        Saved
      </Button>
    );
  } else if (savedState === SavedState.ENABLED) {
    return (
      <Button
        variant="contained"
        onClick={useSubmit ? undefined : onClick}
        type={useSubmit ? "submit" : undefined}
      >
        Save
      </Button>
    );
  }

  return (
    <Button disabled={true} variant="contained" onClick={onClick}>
      Save
    </Button>
  );
};

export default SaveButton;
