import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { MenuItem } from "../../views/dashboard/build_section/navigation/MenuCard";
import ExpandableSection from "../ExpandableSection";

const NestedMenuItem = ({ item, level }: { item: MenuItem; level: number }) => {
  const theme = useTheme();
  if (item.items == null || item.items?.length === 0) {
    return (
      <Box
        sx={{
          borderBottom: level < 2 ? "1px solid rgba(0,0,0,0.2)" : "none",
          backgroundColor: "transparent",
          alignContent: "center",
          alignItems: "center",
          height: level < 2 ? "48px" : "32px",
          display: "flex",
        }}
      >
        <Typography
          sx={{
            color: theme.palette.navigationForeground.main,
            fontSize: level === 0 ? "14px" : "12px",
            position: "relative",
            display: "flex",
            pl: 1,
            left: level * (6 * 8) + 8 + "px",
            borderLeft: level === 2 ? "1px solid rgba(0,0,0,0.2)" : undefined,
          }}
          variant={level === 2 ? "body2" : "body0"}
        >
          {item.title || "--"}
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        borderBottom: level === 2 ? "none" : "1px solid rgba(0,0,0,0.1)",
        fontSize: "14px",
        backgroundColor:
          level === 0 ? theme.palette.navigationBackground.main : "transparent",
        position: "relative",
      }}
    >
      <ExpandableSection
        title={
          <Typography
            sx={{
              color: theme.palette.navigationForeground.main,
              position: "relative",
              left: level * 48 + "px",
              fontSize: level === 0 ? "14px" : "12px",
            }}
            variant="body0"
          >
            {item.title || "--"}
          </Typography>
        }
        color={"inherit"}
        expandIcon={
          <ExpandMoreIcon
            sx={{ color: theme.palette.navigationForeground.main }}
          />
        }
      >
        <Box
          sx={{
            backgroundColor: level === 0 ? "rgba(0,0,0,0.1)" : "transparent",
            fontSize: "14px",
            // position: "relative",
          }}
        >
          {item.items.map((child, index) => {
            return (
              <NestedMenuItem key={index} item={child} level={level + 1} />
            );
          })}
        </Box>
      </ExpandableSection>
    </Box>
  );
};

const MobilePreviewSidepane = ({
  items,
  isOpen,
  isFirstRender,
  onClose,
}: {
  items: MenuItem[];
  isOpen: boolean;
  isFirstRender: boolean;
  onClose: () => void;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "absolute",
        right: "100%",
        width: "100%",
        height: "100%",
        color: theme.palette.navigationForeground.main,
        backgroundColor: theme.palette.navigationBackground.main,
        zIndex: 100,
        animation: isFirstRender
          ? undefined
          : isOpen
          ? "showSidepane 0.3s ease-in-out forwards"
          : "hideSidepane 0.3s ease-in-out forwards",
        overflowY: "scroll",
        // overflow: isOpen ? "visible" : "hidden",

        "@keyframes showSidepane": {
          from: { right: "100%" },
          to: { right: "0" },
        },
        "@keyframes hideSidepane": {
          from: { right: "0" },
          to: { right: "100%" },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
          position: "relative",
        }}
      >
        <IconButton onClick={onClose} color="inherit">
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box
        sx={{
          py: "20px",
          px: 2,
          mx: 2,
          my: 1,
          backgroundColor:
            theme.palette.navigationBackground.main === "#FFFFFF"
              ? "rgba(0,0,0,0.05)"
              : "rgba(255, 255, 255, 0.5)",
          borderRadius: "8px",
          fontWeight: "bold",
        }}
      >
        Notifications
      </Box>
      <Box
        sx={{
          position: "relative",
        }}
      >
        {items.map((item, index) => {
          return <NestedMenuItem key={index} item={item} level={0} />;
        })}
      </Box>
    </Box>
  );
};

export default MobilePreviewSidepane;
