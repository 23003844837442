import { Link, Stack, Typography } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { AppDistributionCard_brand$key } from "./__generated__/AppDistributionCard_brand.graphql";
import CardSection from "../../../components/CardSection";
import CopyButton from "../../../components/CopyButton";
import { SETTINGS_SHOPIFY } from "../../../utils/routes";

const brandFragment = graphql`
  fragment AppDistributionCard_brand on BrandType {
    id
    slug
    finishedOnboarding
    appConfig {
      iosAppStoreLink
      androidAppStoreLink
    }
  }
`;

const AppDistributionCard = ({
  brand: brandKey,
}: {
  brand: AppDistributionCard_brand$key;
}) => {
  const navigate = useNavigate();
  const brand = useFragment(brandFragment, brandKey);
  const slug = brand.slug || null;
  const universalUrl = slug
    ? "https://app.getkinn.com/app-download?id=" + brand.slug
    : "";
  const iosLink = brand.appConfig?.iosAppStoreLink || "";
  const androidLink = brand.appConfig?.androidAppStoreLink || "";
  if (iosLink === "" || androidLink === "") {
    return null;
  }
  return (
    <CardSection
      title={"App Distribution"}
      content={
        <Stack spacing={2} width="100%">
          <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
            Universal Link
          </Typography>
          <Typography variant="body2">
            <CopyButton
              isDisabled={universalUrl === ""}
              textToCopy={universalUrl}
            />{" "}
            and share this universal app distribution link
          </Typography>
          <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
            Shopify Banner
          </Typography>
          <Typography variant="body2">
            Enable a banner upsell on your Shopify store to promote your new app
            to mobile site users on the{" "}
            <Link underline="always" onClick={() => navigate(SETTINGS_SHOPIFY)}>
              Integrations Tab
            </Link>{" "}
            tab
          </Typography>
        </Stack>
      }
    />
  );
};

export default AppDistributionCard;
