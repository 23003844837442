/**
 * @generated SignedSource<<496e37e1c6c663df91152494729b6c85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NodeInput = {
  id: string;
};
export type DashboardShopifyOneTimeChargeMutation$variables = {
  input: NodeInput;
};
export type DashboardShopifyOneTimeChargeMutation$data = {
  readonly createShopifyOneTimeCharge: string;
};
export type DashboardShopifyOneTimeChargeMutation = {
  response: DashboardShopifyOneTimeChargeMutation$data;
  variables: DashboardShopifyOneTimeChargeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "createShopifyOneTimeCharge",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardShopifyOneTimeChargeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DashboardShopifyOneTimeChargeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6c984e8a3989d0590cd45801033b71e8",
    "id": null,
    "metadata": {},
    "name": "DashboardShopifyOneTimeChargeMutation",
    "operationKind": "mutation",
    "text": "mutation DashboardShopifyOneTimeChargeMutation(\n  $input: NodeInput!\n) {\n  createShopifyOneTimeCharge(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "2dfec61783ff3505633069d822f9dcb7";

export default node;
