/**
 * @generated SignedSource<<91d780fe9757de349cf0ac8dad789660>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavigationTabQuery$variables = {
  id: string;
};
export type NavigationTabQuery$data = {
  readonly brand: {
    readonly " $fragmentSpreads": FragmentRefs<"NavigationTab_brand">;
  };
};
export type NavigationTabQuery = {
  response: NavigationTabQuery$data;
  variables: NavigationTabQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destinationUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NavigationTab_brand"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NavigationTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandAppConfigType",
            "kind": "LinkedField",
            "name": "appConfig",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cartIgnoredProductIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "openCartAfterUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandAppConfigBuildNavigationType",
                "kind": "LinkedField",
                "name": "buildNavigation",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isGorgiasEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isTabBarTitleEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isTopTitleEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "eligibleTabTypes",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shouldLockMenus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NestedMenuItemType",
                "kind": "LinkedField",
                "name": "nestedMenuItems",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NestedMenuItemType",
                    "kind": "LinkedField",
                    "name": "children",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NestedMenuItemType",
                        "kind": "LinkedField",
                        "name": "children",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NavigationItemType",
                "kind": "LinkedField",
                "name": "navigationItems",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NavigationItemType",
                    "kind": "LinkedField",
                    "name": "children",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NavigationItemType",
                        "kind": "LinkedField",
                        "name": "children",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLBrandAppTabConfig",
                "kind": "LinkedField",
                "name": "tabs",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "icon",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wishlistUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gorgiasId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandShopifyConfigType",
            "kind": "LinkedField",
            "name": "shopifyConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isConnected",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "domain",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a61e0d0f11e88fa29ecbc1ab57d7a3c4",
    "id": null,
    "metadata": {},
    "name": "NavigationTabQuery",
    "operationKind": "query",
    "text": "query NavigationTabQuery(\n  $id: ID!\n) {\n  brand(id: $id) {\n    ...NavigationTab_brand\n    id\n  }\n}\n\nfragment CartCard_brand on BrandType {\n  appConfig {\n    id\n    cartIgnoredProductIds\n    openCartAfterUpdate\n  }\n}\n\nfragment GorgiasCard_brand on BrandType {\n  appConfig {\n    id\n    buildNavigation {\n      id\n      isGorgiasEnabled\n    }\n  }\n  gorgiasId\n}\n\nfragment MenuCard_brand on BrandType {\n  id\n  appConfig {\n    id\n    shouldLockMenus\n    nestedMenuItems {\n      title\n      destinationUrl\n      index\n      children {\n        title\n        destinationUrl\n        index\n        children {\n          title\n          destinationUrl\n          index\n        }\n      }\n    }\n  }\n}\n\nfragment NavigationTab_brand on BrandType {\n  ...CartCard_brand\n  ...GorgiasCard_brand\n  ...MenuCard_brand\n  ...ShortcutsCard_brand\n  ...TabsCard2_brand\n  ...WishlistCard_brand\n}\n\nfragment ShortcutsCard_brand on BrandType {\n  id\n  appConfig {\n    id\n    shouldLockMenus\n    navigationItems {\n      index\n      label\n      url\n      children {\n        index\n        label\n        url\n        children {\n          index\n          label\n          url\n        }\n      }\n    }\n  }\n  shopifyConfig {\n    isConnected\n    id\n  }\n}\n\nfragment TabsCard2_brand on BrandType {\n  id\n  domain\n  appConfig {\n    id\n    buildNavigation {\n      id\n      isTabBarTitleEnabled\n      isTopTitleEnabled\n      eligibleTabTypes\n    }\n    tabs {\n      id\n      type\n      customName\n      customUrl\n      icon\n    }\n  }\n}\n\nfragment WishlistCard_brand on BrandType {\n  appConfig {\n    id\n    wishlistUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "caee1e6be2758d06b0a708adcb3d9d48";

export default node;
