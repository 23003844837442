/**
 * @generated SignedSource<<8762e79345990e5a73c0ebe5d8e75d8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BrandAdminTab_brand$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"BrandAdminTable_refetchableBrand">;
  readonly " $fragmentType": "BrandAdminTab_brand";
};
export type BrandAdminTab_brand$key = {
  readonly " $data"?: BrandAdminTab_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrandAdminTab_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrandAdminTab_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BrandAdminTable_refetchableBrand"
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "0ffe5df85ac770adbc6c6f83cb33e27a";

export default node;
