/**
 * @generated SignedSource<<d2287b2e498551dd161563b558b31b68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LaunchAppContainerQuery$variables = {
  id: string;
};
export type LaunchAppContainerQuery$data = {
  readonly brand: {
    readonly finishedOnboarding: boolean;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"AppListingTab_brand">;
  };
  readonly user: {
    readonly isInternal: boolean;
  } | null;
};
export type LaunchAppContainerQuery = {
  response: LaunchAppContainerQuery$data;
  variables: LaunchAppContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInternal",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finishedOnboarding",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LaunchAppContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AppListingTab_brand"
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LaunchAppContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandAppConfigType",
            "kind": "LinkedField",
            "name": "appConfig",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandAppConfigScreenshotType",
                "kind": "LinkedField",
                "name": "screenshots",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DjangoImageType",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iosAppStoreLink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "androidAppStoreLink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "appDescription",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "appIconUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "appName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "appSubtitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "appStoreCategories",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "keywords",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "primaryCategory",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondaryCategory",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "privacyPolicyUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "promotionalText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "supportUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "marketingUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14d40a3101dc43abbc150a90a0b21e61",
    "id": null,
    "metadata": {},
    "name": "LaunchAppContainerQuery",
    "operationKind": "query",
    "text": "query LaunchAppContainerQuery(\n  $id: ID!\n) {\n  user {\n    isInternal\n    id\n  }\n  brand(id: $id) {\n    ...AppListingTab_brand\n    id\n    finishedOnboarding\n  }\n}\n\nfragment AppDistributionCard_brand on BrandType {\n  id\n  slug\n  finishedOnboarding\n  appConfig {\n    iosAppStoreLink\n    androidAppStoreLink\n    id\n  }\n}\n\nfragment AppListingTab_brand on BrandType {\n  ...ScreenshotsCard_brand\n  ...AppDistributionCard_brand\n  appConfig {\n    id\n    appDescription\n    appIconUrl\n    appName\n    appSubtitle\n    appStoreCategories\n    keywords\n    primaryCategory\n    secondaryCategory\n    privacyPolicyUrl\n    promotionalText\n    supportUrl\n    marketingUrl\n  }\n}\n\nfragment ScreenshotsCard_brand on BrandType {\n  appConfig {\n    id\n    screenshots {\n      id\n      image {\n        url\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "292da02e01c0012af7ff882a9f5b3be1";

export default node;
