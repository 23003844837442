import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  FeedPostAttachmentType,
  FeedPostFormQuery,
} from "./__generated__/FeedPostFormQuery.graphql";
import { FeedPostForm_brand$key } from "./__generated__/FeedPostForm_brand.graphql";
import CardSection from "../../../../components/CardSection";
import {
  PreviewType,
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../../contexts/MobilePreviewContext";
import { ENGAGE_FEED } from "../../../../utils/routes";
import FeedCarouselForm from "./FeedCarouselForm";
import FeedPollForm from "./FeedPollForm";
import FeedSinglePostForm from "./FeedSinglePostForm";

const brandFragment = graphql`
  fragment FeedPostForm_brand on BrandType {
    ...FeedSinglePostForm_brand
    ...FeedPollForm_brand
    ...FeedCarouselForm_brand
  }
`;

const query = graphql`
  query FeedPostFormQuery($id: ID!, $skip: Boolean!) {
    feedPost(id: $id) @skip(if: $skip) {
      id
      type
      ...FeedSinglePostForm_feedPost
      ...FeedPollForm_feedPost
      ...FeedCarouselForm_feedPost
    }
  }
`;

const getRadioOptionStyle = (isSelected: boolean, theme: Theme): SxProps => {
  const radioOptionStyle = {
    p: 3,
    marginBottom: 2,
    // background: "white",
    boxShadow: "inset 0px 0px 0px 1px #E6E8F0",
    mx: 0,
    borderRadius: "8px",
  };

  if (!isSelected) {
    return radioOptionStyle;
  }

  return {
    ...radioOptionStyle,
    background: theme.palette.primary.main + "0D",
    border: "2px solid " + theme.palette.primary.main,
    // boxShadow: "inset 0px 0px 0px 1px " + theme.palette.primary.main,
    // borderRadius: "8px",
  };
};

type State = {
  from: string;
};

/* TODO
  - add basic validation...
  */
const FeedPostForm = ({
  brand: brandKey,
  onUpdateComplete,
}: {
  brand: FeedPostForm_brand$key;
  onUpdateComplete: (postID?: string) => void;
}) => {
  const theme = useTheme();
  const dispatch = useMobilePreviewDispatch();
  useEffect(() => {
    return () => {
      dispatch({ type: ReducerAction.RESET });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Routing
  const location = useLocation();
  const navigate = useNavigate();
  const postID = useParams().id;
  const isEditing = postID != null;
  const onBackClick = () => {
    dispatch({ type: ReducerAction.RESET });
    const state = location?.state as State;
    if (state?.from === "feed_tab") {
      navigate(-1);
    } else {
      navigate(ENGAGE_FEED);
    }
  };

  // Data Loading
  const data = useLazyLoadQuery<FeedPostFormQuery>(query, {
    id: postID ?? "",
    skip: !isEditing,
  });
  const brand = useFragment(brandFragment, brandKey);
  const feedPost = data.feedPost ?? null;

  const [postType, setPostType] = useState<FeedPostAttachmentType | null>(
    feedPost?.type ?? null
  );
  const onChangePostType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = event.target.value as FeedPostAttachmentType;
    dispatch({
      type: ReducerAction.RESET,
    });
    dispatch({
      type: ReducerAction.SET_PREVIEW_TYPE,
      payload: {
        previewType:
          type === "LINK"
            ? PreviewType.SINGLE_POST
            : type === "POLL"
            ? PreviewType.POLL
            : PreviewType.NONE,
      },
    });

    setPostType(event.target.value as FeedPostAttachmentType);
  };

  const eligibleAttachmentTypes = ["LINK", "POLL", "CAROUSEL"];

  const radioGroup = (
    <RadioGroup
      aria-labelledby="radio-buttons"
      name="radio-buttons-group"
      value={postType}
      onChange={onChangePostType}
    >
      {eligibleAttachmentTypes.map((type) => {
        const previewType =
          type === "LINK"
            ? PreviewType.SINGLE_POST
            : type === "POLL"
            ? PreviewType.POLL
            : PreviewType.CAROUSEL;
        let title = "";
        let description = "";
        switch (type) {
          case "LINK":
            title = "Single Post";
            description =
              "Highlight a single product, collection, or an external link post (e.g. article).";
            break;
          case "POLL":
            title = "Poll";
            description =
              "Allow your customers to vote from a suite of options that relate to a specific question or topic (e.g. “What should we launch next?”).";
            break;
          case "CAROUSEL":
            title = "Carousel";
            description =
              "Showcase multiple products or collections in a single post.";
            break;
          default:
            break;
        }
        return (
          <FormControlLabel
            disabled={isEditing}
            key={type}
            sx={getRadioOptionStyle(type === postType, theme)}
            value={type}
            control={<Radio />}
            label={
              <Stack ml={2}>
                <Typography variant="subtitle1" color="black" fontWeight={500}>
                  {title}
                </Typography>
                <Typography variant="body2">{description}</Typography>
              </Stack>
            }
            onMouseOver={(
              event: React.MouseEvent<HTMLLabelElement, MouseEvent>
            ) => {
              if (postType === null) {
                dispatch({
                  type: ReducerAction.RESET,
                });
                dispatch({
                  type: ReducerAction.SET_PREVIEW_TYPE,
                  payload: {
                    previewType: previewType,
                  },
                });
              }
            }}
            onMouseLeave={(
              event: React.MouseEvent<HTMLLabelElement, MouseEvent>
            ) => {
              if (postType === null) {
                dispatch({
                  type: ReducerAction.RESET,
                });
                dispatch({
                  type: ReducerAction.SET_PREVIEW_TYPE,
                  payload: {
                    previewType: PreviewType.NONE,
                  },
                });
              }
            }}
          />
        );
      })}
    </RadioGroup>
  );

  let form = null;
  switch (postType) {
    case "LINK":
      form = (
        <FeedSinglePostForm
          brand={brand}
          feedPost={feedPost}
          onBackClick={onBackClick}
          onUpdateComplete={onUpdateComplete}
        />
      );
      break;
    case "POLL":
      form = (
        <FeedPollForm
          brand={brand}
          feedPost={feedPost}
          onBackClick={onBackClick}
          onUpdateComplete={onUpdateComplete}
        />
      );
      break;
    case "CAROUSEL":
      form = (
        <FeedCarouselForm
          brand={brand}
          feedPost={feedPost}
          onBackClick={onBackClick}
          onUpdateComplete={onUpdateComplete}
        />
      );
      break;
    default:
      break;
  }

  return (
    <Stack spacing={2}>
      <Box display="flex" justifyContent="flex-start">
        <Button
          startIcon={<ArrowBackIcon />}
          variant="text"
          onClick={onBackClick}
          color="primary"
        >
          Your Feed Posts
        </Button>
      </Box>
      <CardSection
        title={"Choose format"}
        content={<FormControl>{radioGroup}</FormControl>}
      />
      {form}
    </Stack>
  );
};

export default FeedPostForm;
