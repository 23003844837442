import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  TableCell,
  TableRow,
} from "@mui/material";

const TableRowPlaceholder = () => {
  const item = (
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant="rounded" width={45} height={45} />
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton width={"50%"} />}
        secondary={<Skeleton width={"50%"} />}
      />
    </ListItem>
  );
  return (
    <TableRow>
      <TableCell width={"50%"} align="left">
        {item}
      </TableCell>
      <TableCell align="right">
        <ListItemText
          primary={<Skeleton width={"100px"} />}
          secondary={<Skeleton width={"100px"} />}
        />
      </TableCell>
      <TableCell width={"10%"} align="right">
        <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
      </TableCell>
    </TableRow>
  );
};

export default TableRowPlaceholder;
