import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import ShopifyEmbedded from "./ShopifyEmbedded";

const ShopifyInstall = () => {
  let location = useLocation();
  const embedded = new URLSearchParams(location.search).get("embedded");

  useEffect(() => {
    const embedded = new URLSearchParams(location.search).get("embedded");
    if (embedded == null || embedded !== "1") {
      fetch("/api/v1/shopify/install" + location.search)
        .then((res) => res.json())
        .then((data) => {
          const redirect_url = data.redirect_url;
          if (redirect_url) {
            window.location.href = redirect_url;
          }
        });
    }
  }, [location]);

  if (embedded) {
    return <ShopifyEmbedded />;
  }
  return <></>;
};

export default ShopifyInstall;
