/**
 * @generated SignedSource<<7088c291bc55fbd3c1d2da5917d118c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GraphQLFacebookPageInput = {
  facebookPageId: string;
  id: string;
};
export type FacebookTab_updateChosenFacebookPageMutation$variables = {
  input: GraphQLFacebookPageInput;
};
export type FacebookTab_updateChosenFacebookPageMutation$data = {
  readonly updateChosenFacebookPage: {
    readonly " $fragmentSpreads": FragmentRefs<"FacebookTab_brand">;
  };
};
export type FacebookTab_updateChosenFacebookPageMutation = {
  response: FacebookTab_updateChosenFacebookPageMutation$data;
  variables: FacebookTab_updateChosenFacebookPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FacebookTab_updateChosenFacebookPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "updateChosenFacebookPage",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FacebookTab_brand"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FacebookTab_updateChosenFacebookPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "updateChosenFacebookPage",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLFacebookClientConfig",
            "kind": "LinkedField",
            "name": "facebookConfig",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLFacebookPage",
                "kind": "LinkedField",
                "name": "facebookPages",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isSelected",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "17a7018fc690a07edf87d93b6a10956b",
    "id": null,
    "metadata": {},
    "name": "FacebookTab_updateChosenFacebookPageMutation",
    "operationKind": "mutation",
    "text": "mutation FacebookTab_updateChosenFacebookPageMutation(\n  $input: GraphQLFacebookPageInput!\n) {\n  updateChosenFacebookPage(input: $input) {\n    ...FacebookTab_brand\n    id\n  }\n}\n\nfragment FacebookTab_brand on BrandType {\n  id\n  facebookConfig {\n    id\n    facebookPages {\n      id\n      name\n      isSelected\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1258027e8dfa64c8a53badc67c5349c4";

export default node;
