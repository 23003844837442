/**
 * @generated SignedSource<<22b5bd68d2cbb4abfebdc3bb1ec32e71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BrandInputPartial = {
  appsflyerDevKey?: string | null;
  discourseContactEmail?: string | null;
  email?: string | null;
  facebookAppId?: string | null;
  facebookClientToken?: string | null;
  facebookConfig?: FacebookClientTokenInput | null;
  gorgiasId?: string | null;
  id?: string | null;
  klaviyoApiToken?: string | null;
  kustomerApiToken?: string | null;
  ometriaApiToken?: string | null;
  onboardingConfig?: BrandOnboardingConfigInputPartial | null;
  rechargeApiToken?: string | null;
  shopifyConfig?: BrandShopifyConfigInputPartial | null;
  showIosTosAlert?: boolean | null;
};
export type BrandShopifyConfigInputPartial = {
  id?: string | null;
  multipassSecret?: string | null;
};
export type BrandOnboardingConfigInputPartial = {
  id?: string | null;
  isAppApproved?: boolean | null;
  isAppListingComplete?: boolean | null;
  isAppSubmitted?: boolean | null;
  isDeveloperAccountSetup?: boolean | null;
  isIncentivesSetup?: boolean | null;
  isKinnAddedToDeveloperAccount?: boolean | null;
  isMarketingSetup?: boolean | null;
  isNotificationsSetup?: boolean | null;
  isThirdPartyIntegrationSetup?: boolean | null;
};
export type FacebookClientTokenInput = {
  appUserId?: string | null;
  id?: string | null;
  token?: string | null;
};
export type useBrandUpdateMutation$variables = {
  input: BrandInputPartial;
};
export type useBrandUpdateMutation$data = {
  readonly updateBrand: {
    readonly appsflyerDevKey: string | null;
    readonly email: string;
    readonly facebookAppId: string | null;
    readonly facebookClientToken: string | null;
    readonly gorgiasId: string | null;
    readonly id: string;
    readonly klaviyoApiToken: string | null;
    readonly kustomerApiToken: string | null;
    readonly rechargeApiToken: string | null;
    readonly shopifyConfig: {
      readonly multipassSecret: string | null;
    } | null;
    readonly showIosTosAlert: boolean;
  };
};
export type useBrandUpdateMutation = {
  response: useBrandUpdateMutation$data;
  variables: useBrandUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "multipassSecret",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "facebookAppId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "facebookClientToken",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gorgiasId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "klaviyoApiToken",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kustomerApiToken",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rechargeApiToken",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showIosTosAlert",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appsflyerDevKey",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useBrandUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "updateBrand",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandShopifyConfigType",
            "kind": "LinkedField",
            "name": "shopifyConfig",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useBrandUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "updateBrand",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandShopifyConfigType",
            "kind": "LinkedField",
            "name": "shopifyConfig",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "49ae4312f438e15b8182c7f0a8b0c15b",
    "id": null,
    "metadata": {},
    "name": "useBrandUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation useBrandUpdateMutation(\n  $input: BrandInputPartial!\n) {\n  updateBrand(input: $input) {\n    id\n    shopifyConfig {\n      multipassSecret\n      id\n    }\n    facebookAppId\n    facebookClientToken\n    gorgiasId\n    klaviyoApiToken\n    kustomerApiToken\n    rechargeApiToken\n    email\n    showIosTosAlert\n    appsflyerDevKey\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd924cabe4d31a344151bc5ba1ed6cac";

export default node;
