/**
 * @generated SignedSource<<ae9412fea548cddfcd2f25761eda0e31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DailyDealsTab_brand$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CDPAudienceFilters_brand" | "DailyDealForm_brand" | "DailyDealsTable_refetchableBrand">;
  readonly " $fragmentType": "DailyDealsTab_brand";
};
export type DailyDealsTab_brand$key = {
  readonly " $data"?: DailyDealsTab_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"DailyDealsTab_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DailyDealsTab_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CDPAudienceFilters_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DailyDealsTable_refetchableBrand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DailyDealForm_brand"
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "78028295d9be7bbc5f8b17fd5abb6cfb";

export default node;
