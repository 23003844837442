import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment, useMutation } from "react-relay";
import * as yup from "yup";
import { InternBrandBillingRecurringSubCardDeleteMutation } from "./__generated__/InternBrandBillingRecurringSubCardDeleteMutation.graphql";
import { InternBrandBillingRecurringSubCardMutation } from "./__generated__/InternBrandBillingRecurringSubCardMutation.graphql";
import {
  InternBrandBillingRecurringSubCard_billingConfig$key,
  PlanInterval,
} from "./__generated__/InternBrandBillingRecurringSubCard_billingConfig.graphql";
import CardSection from "../../components/CardSection";
import LeftRight from "../../components/LeftRight";
import { SavedState } from "../../components/SaveButton";
import SnackbarContext from "../../contexts/SnackbarContext";
import nullthrows from "../../utils/nullthrows";

const INTERVALS: PlanInterval[] = ["EVERY_30_DAYS", "EVERY_365_DAYS"];

const schema = yup
  .object()
  .shape(
    {
      planPrice: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional()
        .nullable()
        .when("takeRate", {
          is: (takeRate: number | null | undefined) => {
            return takeRate == null || isNaN(takeRate);
          },
          then: (schema) =>
            schema
              .typeError("Amount must be a number")
              .required("Subscription amount is required")
              .min(1, "Must be greater than 1 if there is no take rate")
              .test("maxDigitsAfterDecimal", "Max 2 decimal places", (number) =>
                /^\d+(\.\d{1,2})?$/.test(number + "")
              ),
          otherwise: (schema) =>
            schema
              .typeError("Amount must be a number")
              .required("Subscription amount is required")
              .min(0, "Must be greater than 0")
              .test("maxDigitsAfterDecimal", "Max 2 decimal places", (number) =>
                /^\d+(\.\d{1,2})?$/.test(number + "")
              ),
        }),
      trialDays: yup.number().default(0).typeError("Amount must be a number"),
      isBlocking: yup.boolean().optional().default(false),
      shouldTakeEffectImmediately: yup.boolean().optional().default(false),
      takeRate: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional()
        .nullable()
        .when("takeRateTotalCap", {
          is: (takeRateTotalCap: number | null | undefined) => {
            return takeRateTotalCap != null && !isNaN(takeRateTotalCap);
          },
          then: (schema) =>
            schema
              .typeError("Amount must be a number")
              .lessThan(1, "Must be less than 1")
              .moreThan(0, "Must be greater than 0")
              .required("Take rate is required if there is a cap set")
              .test("maxDigitsAfterDecimal", "Max 4 decimal places", (number) =>
                /^\d+(\.\d{1,4})?$/.test(number + "")
              ),
          otherwise: (schema) => schema,
        }),
      takeRateTotalCap: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .optional()
        .nullable()
        .when("takeRate", {
          is: (takeRate: number | null | undefined) => {
            return takeRate != null && !isNaN(takeRate);
          },
          then: (schema) =>
            schema
              .typeError("Amount must be a number")
              .required("Plan cap is required if there is a take rate set")
              .test("maxDigitsAfterDecimal", "Max 2 decimal places", (number) =>
                /^\d+(\.\d{1,2})?$/.test(number + "")
              ),
          otherwise: (schema) => schema,
        }),
      takeRateMinUsage: yup
        .number()
        .default(0)
        .typeError("Amount must be a number")
        .min(0, "Must be greater than 0"),
    },
    [["takeRateTotalCap", "takeRate"]]
  )
  .required();
type FormData = yup.InferType<typeof schema>;

const mutation = graphql`
  mutation InternBrandBillingRecurringSubCardMutation(
    $bcId: ID!
    $input: GraphQLPendingBillingConfigInputPartial!
  ) {
    createOrUpdatePendingBillingConfig(bcId: $bcId, input: $input) {
      ... on GraphQLPendingBillingConfig {
        billingConfig {
          ...InternBrandBillingRecurringSubCard_billingConfig
        }
      }
    }
  }
`;

const deleteMutation = graphql`
  mutation InternBrandBillingRecurringSubCardDeleteMutation(
    $input: NodeInput!
  ) {
    deletePendingBillingConfig(data: $input) {
      ... on GraphQLPendingBillingConfig {
        id
      }
    }
  }
`;

const fragment = graphql`
  fragment InternBrandBillingRecurringSubCard_billingConfig on GraphQLBillingConfig {
    id
    planCreatedAt
    planTrialDays
    planCurrency
    planId
    planInterval
    planPrice
    planStatus
    startDate
    takeRate
    takeRateTotalCap
    takeRateMinUsage
    pendingBillingConfig {
      id
      isBlocking
      isImmediate
      createdAt
      currency
      price
      interval
      takeRate
      takeRateTotalCap
      takeRateMinUsage
      trialDays
    }
    shopifyBillingConfig {
      appSubscriptionLineItemId
    }
  }
`;

const InternBrandBillingRecurringSubCard = ({
  brandID,
  billingConfig: billingConfigKey,
}: {
  brandID: string;
  billingConfig: InternBrandBillingRecurringSubCard_billingConfig$key;
}) => {
  const snackbarContext = useContext(SnackbarContext);
  const billingConfig = useFragment(fragment, billingConfigKey);
  const shopifyBillingConfig = billingConfig?.shopifyBillingConfig ?? null;
  const pendingBillingConfig = billingConfig?.pendingBillingConfig ?? null;
  const isCurrentPlanActive = billingConfig?.planStatus === "ACTIVE";

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.ENABLED
  );

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const [selectInputValue, setSelectInputValue] = useState<PlanInterval>(
    (pendingBillingConfig?.interval as PlanInterval) ?? "EVERY_30_DAYS"
  );
  const onSelectInputChange = (event: SelectChangeEvent) => {
    setSelectInputValue(event.target.value as PlanInterval);
    saveButtonState !== SavedState.ENABLED &&
      setSaveButtonState(SavedState.ENABLED);
  };

  const [createCharge, isUpdating] =
    useMutation<InternBrandBillingRecurringSubCardMutation>(mutation);
  const [deletePendingBc, isDeleting] =
    useMutation<InternBrandBillingRecurringSubCardDeleteMutation>(
      deleteMutation
    );

  const {
    handleSubmit,
    formState: { errors, isDirty },
    register,
    watch,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      planPrice: pendingBillingConfig?.price ?? "",
      trialDays: pendingBillingConfig?.trialDays ?? 0,
      takeRate: pendingBillingConfig?.takeRate ?? "",
      takeRateTotalCap: pendingBillingConfig?.takeRateTotalCap ?? "",
      takeRateMinUsage: pendingBillingConfig?.takeRateMinUsage ?? 0,
    },
    resolver: yupResolver(schema),
  });

  const onDialogClose = () => {
    setIsDialogOpen(false);
    reset({});
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      saveButtonState !== SavedState.ENABLED &&
        setSaveButtonState(SavedState.ENABLED);
    });
    return () => subscription.unsubscribe();
  }, [watch, saveButtonState, setSaveButtonState]);

  const onSubmit = (data: FormData) => {
    createCharge({
      variables: {
        bcId: nullthrows(billingConfig?.id),
        input: {
          id: billingConfig?.pendingBillingConfig?.id ?? null,
          isBlocking: data.isBlocking,
          isImmediate: data.shouldTakeEffectImmediately,
          price: data.planPrice,
          interval: selectInputValue,
          trialDays: data.trialDays ?? 0,
          takeRate: data.takeRate ?? null,
          takeRateTotalCap: data.takeRateTotalCap ?? null,
          takeRateMinUsage: data.takeRateMinUsage ?? 0,
        },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          snackbarContext?.openSnackbar("Update Failed", "error");
          setSaveButtonState(SavedState.ENABLED);
        } else {
          snackbarContext?.openSnackbar("Update Successful", "success");
          setSaveButtonState(SavedState.SAVED);
          setIsDialogOpen(false);
          reset({}, { keepValues: true });
        }
      },
      onError: (error: Error) => {
        snackbarContext?.openSnackbar("Update Failed", "error");
        setSaveButtonState(SavedState.ENABLED);
      },
    });
  };

  const pendingPlanDeleteButton = (
    <Tooltip title={"Delete"} placement="right" arrow>
      <IconButton
        disabled={isDeleting}
        sx={{
          flexShrink: 0,
        }}
        onClick={() => {
          deletePendingBc({
            variables: {
              input: {
                id: nullthrows(pendingBillingConfig?.id),
              },
            },
            onCompleted: (data, errors) => {
              console.log(errors);
              if (errors) {
                snackbarContext?.openSnackbar("Delete Failed", "error");
              } else {
                snackbarContext?.openSnackbar("Delete Successful", "success");
                reset();
              }
            },
            onError: (error: Error) => {
              console.log(error);
              snackbarContext?.openSnackbar("Delete Failed", "error");
            },
            updater: (store) => {
              store.delete(nullthrows(pendingBillingConfig?.id));
            },
          });
        }}
      >
        <DeleteForeverIcon />
      </IconButton>
    </Tooltip>
  );

  const updatePlanButton = (
    <Tooltip title={"Update"} placement="right" arrow>
      <IconButton
        disabled={isDeleting}
        sx={{
          flexShrink: 0,
        }}
        onClick={() => setIsDialogOpen(true)}
      >
        <CreateIcon />
      </IconButton>
    </Tooltip>
  );

  // pendingBillingConfig
  const pendingPlan = (
    <Card
      variant="outlined"
      sx={{
        p: 2,
      }}
    >
      {pendingBillingConfig ? (
        <LeftRight
          expandLeft
          left={
            <>
              <Alert
                severity={pendingBillingConfig.isBlocking ? "error" : "warning"}
                sx={{
                  mb: 2,
                }}
              >
                <Typography variant="subtitle2">
                  <b>
                    {pendingBillingConfig.isBlocking
                      ? "Brand will be blocked from the seller dashboard"
                      : "Brand will not be blocked from the seller dashboard"}
                  </b>
                </Typography>
              </Alert>
              <Typography variant="subtitle2">
                <b>
                  New plan will start{" "}
                  {!isCurrentPlanActive || pendingBillingConfig.isImmediate
                    ? " immediately:"
                    : " on the next app billing cycle:"}
                </b>
              </Typography>
              <Typography variant="subtitle2">
                <b>Trial Days:</b> {pendingBillingConfig.trialDays}
              </Typography>
              <Typography variant="subtitle2">
                <b>Price: </b>
                {pendingBillingConfig.price} {pendingBillingConfig.currency}
              </Typography>
              <Typography variant="subtitle2">
                <b>Interval:</b> {pendingBillingConfig.interval}
              </Typography>
              {pendingBillingConfig?.takeRate && <Divider sx={{ my: 2 }} />}
              {pendingBillingConfig?.takeRate && (
                <>
                  <Typography variant="subtitle2">
                    <b>Take Rate: </b> {pendingBillingConfig?.takeRate * 100}%
                  </Typography>
                  <Typography variant="subtitle2">
                    <b>Take Rate Cap: </b>
                    {pendingBillingConfig?.takeRateTotalCap}
                  </Typography>
                  <Typography variant="subtitle2">
                    <b>Take Rate Min Usage: </b>
                    {pendingBillingConfig?.takeRateMinUsage}{" "}
                    {pendingBillingConfig.currency}
                  </Typography>
                </>
              )}
            </>
          }
          right={
            <Stack>
              {updatePlanButton}
              {pendingPlanDeleteButton}
            </Stack>
          }
        />
      ) : (
        <LeftRight
          expandLeft
          left={
            <Typography
              variant="subtitle2"
              sx={{
                color: "black",
              }}
            >
              <b>Status:</b> No Pending Plan
            </Typography>
          }
          right={updatePlanButton}
        />
      )}
    </Card>
  );

  // billingConfig
  const existingPlan = (
    <Card
      variant="outlined"
      sx={{
        p: 2,
      }}
    >
      {!isCurrentPlanActive ? (
        <Typography
          variant="subtitle2"
          sx={{
            color: "black",
          }}
        >
          <b>Status:</b> No Active Plan, Create One Below
        </Typography>
      ) : (
        <>
          <Typography
            variant="subtitle2"
            sx={{
              color: "black",
            }}
          >
            <b>ID:</b> {billingConfig?.planId}
          </Typography>
          <Typography variant="subtitle2">
            <b>Trial Days:</b> {billingConfig.planTrialDays}
          </Typography>
          {billingConfig?.planCreatedAt && (
            <Typography variant="subtitle2">
              <b>Created:</b> {dayjs(billingConfig?.planCreatedAt).format("LL")}
            </Typography>
          )}
          <Typography
            variant="subtitle2"
            sx={{
              color: "black",
            }}
          >
            <b>Status:</b> {billingConfig?.planStatus}
          </Typography>
          <Typography variant="subtitle2">
            <b>Price:</b> ({billingConfig?.planCurrency}){" "}
            {billingConfig?.planPrice}
          </Typography>
          <Typography variant="subtitle2">
            <b>Interval:</b> {billingConfig?.planInterval}
          </Typography>
          {billingConfig?.takeRate && <Divider sx={{ my: 2 }} />}
          {billingConfig?.takeRate && (
            <>
              <Typography variant="subtitle2">
                <b>Take Rate:</b> {billingConfig?.takeRate * 100}%
              </Typography>
              <Typography variant="subtitle2">
                <b>Take Rate Cap:</b> ({billingConfig?.planCurrency}){" "}
                {billingConfig?.takeRateTotalCap}
              </Typography>
              <Typography variant="subtitle2">
                <b>Take Rate Min Usage:</b> ({billingConfig.planCurrency})
                {billingConfig?.takeRateMinUsage}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "black",
                }}
              >
                <b>App Line Item ID:</b>{" "}
                {shopifyBillingConfig?.appSubscriptionLineItemId}
              </Typography>
            </>
          )}
        </>
      )}
    </Card>
  );

  const createNewPlan = (
    <Dialog open={isDialogOpen} onClose={onDialogClose} fullWidth>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <DialogTitle>Update Plan</DialogTitle>
        <DialogContent>
          <Stack
            sx={{
              marginY: 1,
            }}
          >
            <FormControl fullWidth>
              <InputLabel>Plan Interval</InputLabel>
              <Select
                label="Plan Interval"
                onChange={onSelectInputChange}
                value={selectInputValue}
              >
                {INTERVALS.map((interval: PlanInterval, index: number) => {
                  return (
                    <MenuItem key={index} value={interval}>
                      {interval}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              {...register("planPrice")}
              error={!!errors?.planPrice}
              helperText={errors?.planPrice?.message}
              fullWidth
              autoComplete="off"
              margin="normal"
              label={"Plan Amount"}
              variant="outlined"
            />
            {!isCurrentPlanActive && (
              <TextField
                {...register("trialDays")}
                error={!!errors?.trialDays}
                helperText={errors?.trialDays?.message}
                fullWidth
                autoComplete="off"
                margin="normal"
                label={"Trial Days"}
                variant="outlined"
              />
            )}
            {selectInputValue === "EVERY_30_DAYS" && (
              <>
                <Typography variant="overline">
                  Usage Plan (Optional)
                </Typography>
                <TextField
                  {...register("takeRate")}
                  error={!!errors?.takeRate}
                  helperText={errors?.takeRate?.message}
                  fullWidth
                  autoComplete="off"
                  margin="normal"
                  label={"Take Rate"}
                  variant="outlined"
                />
                <TextField
                  {...register("takeRateTotalCap")}
                  error={!!errors?.takeRateTotalCap}
                  helperText={errors?.takeRateTotalCap?.message}
                  fullWidth
                  autoComplete="off"
                  margin="normal"
                  label={"Plan Cap"}
                  variant="outlined"
                />
                <TextField
                  {...register("takeRateMinUsage")}
                  error={!!errors?.takeRateMinUsage}
                  helperText={errors?.takeRateMinUsage?.message}
                  fullWidth
                  autoComplete="off"
                  margin="normal"
                  label={"Usage Minimum"}
                  variant="outlined"
                />
              </>
            )}
            <LeftRight
              expandLeft={true}
              left={
                <Stack>
                  <Typography variant="subtitle1">
                    Should block seller dashboard
                  </Typography>
                  <Typography sx={{ paddingBottom: "16px" }} variant="body2">
                    Enabling this toggle will lock admins out of the seller
                    dashboard. By default, we will just show a banner at the
                    top, leaving them access to the rest of the dashboard.
                  </Typography>
                </Stack>
              }
              right={
                <Switch
                  {...register("isBlocking")}
                  defaultChecked={pendingBillingConfig?.isBlocking ?? false}
                />
              }
            />
            <LeftRight
              expandLeft={true}
              left={
                <Stack>
                  <Typography variant="subtitle1">
                    Should replace immediately
                  </Typography>
                  <Typography sx={{ paddingBottom: "16px" }} variant="body2">
                    Enabling this toggle will start the new plan immediately.
                    Otherwise by default, the new plan will start on the next
                    app billing cycle
                  </Typography>
                </Stack>
              }
              right={
                <Switch
                  {...register("shouldTakeEffectImmediately")}
                  defaultChecked={pendingBillingConfig?.isImmediate ?? false}
                />
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isDirty || isUpdating}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return (
    <>
      {createNewPlan}
      <CardSection
        title={"Create Recurring Charge"}
        content={
          <Stack width={"100%"} spacing={2}>
            <Typography variant="subtitle1">Current Plan</Typography>
            {existingPlan}
            <Typography variant="subtitle1">Pending Plan</Typography>

            {pendingPlan}
          </Stack>
        }
        variant="outlined"
      />
    </>
  );
};

export default InternBrandBillingRecurringSubCard;
