import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Fade,
  IconButton,
  ListItemButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { NavigationItem } from "../../views/dashboard/build_section/navigation/ShortcutsCard";

const MobilePreviewBottomSheet = ({
  activeShortcut,
  isOpen,
  onClose,
  isFirstRender,
}: {
  activeShortcut: NavigationItem;
  isOpen: boolean;
  isFirstRender: boolean;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const shortcut = activeShortcut;
  const [nestedIndex, setNestedIndex] = useState(-1);
  const isNestedView = nestedIndex !== -1;

  let content = (
    <>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 3,
          position: "relative",
        }}
        variant="header1"
      >
        {shortcut?.label}
      </Typography>
      <Stack spacing={1} px={2}>
        {shortcut?.children?.map((item, index) => {
          const shortcut = item;
          const hasChildren = shortcut?.children?.length > 0;
          return (
            <ListItemButton
              key={index}
              sx={{
                borderRadius: "12px",
              }}
              onClick={() => {
                hasChildren && setNestedIndex(index);
              }}
            >
              <Stack
                justifyContent={"space-between"}
                direction={"row"}
                alignItems="center"
                alignContent={"center"}
                spacing={1}
                sx={{
                  width: "100%",
                }}
              >
                <Typography
                  key={index}
                  variant="body0"
                  alignContent={"center"}
                  alignSelf={"center"}
                  alignItems={"center"}
                >
                  {item.label}
                </Typography>
                {hasChildren && (
                  <ArrowForwardIosIcon sx={{ fontSize: "14px" }} />
                )}
              </Stack>
            </ListItemButton>
          );
        })}
      </Stack>
    </>
  );

  if (isNestedView) {
    const nestedShortcut = shortcut?.children?.[nestedIndex];
    content = (
      <>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 3,
            position: "relative",
          }}
          variant="header1"
        >
          {nestedShortcut?.label}
        </Typography>
        <Stack spacing={1} px={2}>
          {nestedShortcut?.children?.map((item, index) => {
            return (
              <ListItemButton
                key={index}
                sx={{
                  borderRadius: "12px",
                }}
              >
                <Typography key={index} variant="body0">
                  {item.label}
                </Typography>
              </ListItemButton>
            );
          })}
        </Stack>
      </>
    );
  }

  return (
    <>
      <Fade in={isOpen}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 99,
            backgroundColor: "rgba(0,0,0,0.5)",
            transition: "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          }}
        />
      </Fade>
      <Box
        sx={{
          pt: 8,
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 100,
          top: "100%",
          animation: isFirstRender
            ? undefined
            : isOpen
            ? "slideIn 0.2s ease-in-out forwards"
            : "slideOut 0.2s ease-in-out forwards",
          overflowY: "scroll",
          "@keyframes slideIn": {
            from: { top: "100%" },
            to: { top: "0%" },
          },
          "@keyframes slideOut": {
            from: { top: "0" },
            to: { top: "100%" },
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            color: theme.palette.navigationForeground.main,
            backgroundColor: theme.palette.navigationBackground.main,
            bottom: 0,
            width: "100%",
            borderRadius: "24px 24px 0 0",
            pb: 2,
          }}
        >
          {content}
          {isNestedView && (
            <IconButton
              onClick={() => {
                setNestedIndex(-1);
              }}
              sx={{
                left: "18px",
                top: "18px",
                position: "absolute",
              }}
              color="inherit"
            >
              <ArrowBackIcon fontSize="small" />
            </IconButton>
          )}
          <IconButton
            onClick={() => {
              setNestedIndex(-1);
              onClose();
            }}
            disableRipple
            sx={{
              right: "18px",
              top: "18px",
              position: "absolute",
            }}
            color="inherit"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default MobilePreviewBottomSheet;
