import { Stack, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { UploadableMap } from "relay-runtime";
import * as yup from "yup";
import { NotificationsCard_brand$key } from "./__generated__/NotificationsCard_brand.graphql";
import CardSection from "../../../../components/CardSection";
import SaveButton, { SavedState } from "../../../../components/SaveButton";
import UploadedFileCard from "../../../../components/UploadedFileCard";
import useBrandAppConfigUpdate2Mutation from "../../../../mutations/useBrandAppConfigUpdate2Mutation";
import nullthrows from "../../../../utils/nullthrows";

const schema = yup
  .object({
    notificationsOptInTitle: yup.string().max(400),
    notificationsOptInSubtitle: yup.string().max(400),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const brandFragment = graphql`
  fragment NotificationsCard_brand on BrandType {
    appConfig {
      id
      appContent {
        id
        notificationsOptInTitle
        notificationsOptInSubtitle
      }
      buildTheme {
        id
        notificationOptInAssetUrl
      }
    }
  }
`;

type Props = {
  brand: NotificationsCard_brand$key;
};

const NotificationsCard = ({ brand: brandKey }: Props) => {
  const brand = useFragment<NotificationsCard_brand$key>(
    brandFragment,
    brandKey
  );
  const appConfig = brand.appConfig;
  const buildTheme = appConfig.buildTheme;
  const appContent = appConfig.appContent ?? null;

  const [assetUrl, setAssetUrl] = useState<string | null>(
    buildTheme.notificationOptInAssetUrl
  );
  const [mediaUploadable, setMediaUploadable] = useState<UploadableMap>({});

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.DISABLED
  );

  const [saveMutation, isMutationInFlight] = useBrandAppConfigUpdate2Mutation();

  const {
    handleSubmit,
    formState: { errors, isDirty },
    register,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      notificationsOptInTitle: appContent?.notificationsOptInTitle ?? "",
      notificationsOptInSubtitle: appContent?.notificationsOptInSubtitle ?? "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: FormData) => {
    saveMutation(
      {
        id: brand.appConfig.id,
        appContent: {
          id: nullthrows(brand.appConfig?.appContent?.id),
          notificationsOptInTitle: data.notificationsOptInTitle,
          notificationsOptInSubtitle: data.notificationsOptInSubtitle,
        },
        buildTheme: {
          id: brand.appConfig.buildTheme.id,
          notificationOptInAsset: assetUrl === null ? null : undefined,
        },
      },
      () => {
        setSaveButtonState(SavedState.SAVED);
        reset({}, { keepValues: true });
      },
      mediaUploadable
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <CardSection
        actions={
          <SaveButton
            savedState={
              isDirty
                ? SavedState.ENABLED
                : isMutationInFlight
                ? SavedState.DISABLED
                : saveButtonState
            }
            useSubmit={true}
          />
        }
        title={"Notifications"}
        content={
          <Stack
            spacing={2}
            sx={{
              maxWidth: "650px",
            }}
          >
            <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
              Opt In Asset
            </Typography>
            <UploadedFileCard
              mediaType={"PHOTO"}
              fileURL={assetUrl}
              fileType={".png, .jpg, .gif"}
              htmlLabel={"asset-upload"}
              inputLabel={"Notification Opt-in Asset"}
              inputText={"Upload an image"}
              onClose={() => {
                setAssetUrl(null);
                delete mediaUploadable["buildTheme.notificationOptInAsset"];

                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
              onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
                const file = event?.target?.files?.[0];
                if (file == null) {
                  return;
                }

                const url = URL.createObjectURL(file);
                setAssetUrl(url);

                const newUploadables: UploadableMap = {};
                newUploadables["buildTheme.notificationOptInAsset"] = file;
                setMediaUploadable(newUploadables);

                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
            />
            <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
              Opt In Text
            </Typography>
            <TextField
              {...register("notificationsOptInTitle")}
              error={!!errors?.notificationsOptInTitle}
              helperText={errors?.notificationsOptInTitle?.message}
              autoComplete="off"
              sx={{
                flexGrow: 1,
              }}
              margin="normal"
              id="outlined-basic"
              label={"Notifications Opt In Title"}
              variant="outlined"
            />
            <TextField
              {...register("notificationsOptInSubtitle")}
              error={!!errors?.notificationsOptInSubtitle}
              helperText={errors?.notificationsOptInSubtitle?.message}
              autoComplete="off"
              sx={{
                flexGrow: 1,
              }}
              margin="normal"
              id="outlined-basic"
              label={"Notifications Opt In Subtitle"}
              variant="outlined"
            />
          </Stack>
        }
      />
    </form>
  );
};

export default NotificationsCard;
