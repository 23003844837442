import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { CircularProgress, Grid } from "@mui/material";

const debounce = (func: () => void, timeout = 300): (() => void) => {
  let timer: any;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

const checkIfGladlyChatInit = () => {
  const body = document.body;
  const element = document.getElementById("gladlyChat_container");

  if (!body || !element) return false;

  return Boolean(element);
};

export const waitForGladlyChat = () => {
  const waitElementPromise = new Promise((resolve) => {
    const isGladlyChatInit = checkIfGladlyChatInit();

    if (isGladlyChatInit) return resolve(isGladlyChatInit);

    const observer = new MutationObserver(
      debounce(() => {
        const isGladlyChatInit = checkIfGladlyChatInit();

        if (!isGladlyChatInit) return;

        resolve(isGladlyChatInit);
        observer.disconnect();
      }, 100)
    );

    observer.observe(document.body, {
      childList: true,
      attributes: true,
    });
  });

  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error("Timeout"));
    }, 10000);
  });

  return Promise.race([waitElementPromise, timeoutPromise]);
};

const Gladly = () => {
  const { search } = useLocation();
  const gladlyID = new URLSearchParams(search).get("token");

  useEffect(() => {
    if (gladlyID != null) {
      const baselineGladly = document.createElement("script");
      baselineGladly.innerHTML = `(function(c,n,i,t){var p;var s=[];var l;if(t==="PROD"||!t){l="https://cdn.gladly.com/chat-sdk/widget.js"}else if(t==="STAGING"){l="https://cdn.gladly.qa/gladly/chat-sdk/widget.js"}else{l=t}c[i]={init:function(){p=arguments;var e={then:function(t){s.push({type:"t",next:t});return e},catch:function(t){s.push({type:"c",next:t});return e}};return e}};function e(){try{var t=n.getElementsByTagName("script")[0];var e=n.createElement("script");e.async=true;e.src=l+"?q="+(new Date).getTime();t.parentNode.insertBefore(e,t)}catch(t){}}c.__onHelpAppHostReady__=function t(e){delete c.__onHelpAppHostReady__;c[i]=e;e.loaderCdn=l;if(p){var n=e.init.apply(e,p);for(var a=0;a<s.length;a++){var r=s[a];if(r.type==="t"){n=n.then(r.next)}else{n=n.catch(r.next)}}}};e()})
      (window,document,'GladlyHelpApp','PROD');`;
      baselineGladly.async = false;
      baselineGladly.defer = true;
      document.body.appendChild(baselineGladly);

      GladlyHelpApp?.init({
        appId: gladlyID,
      })
        .then(() => {
          (async () => {
            try {
              await waitForGladlyChat();
              GladlyHelpApp.on("sidekick:opened", (source: string) => {
                setTimeout(() => {
                  const closeButton = document.querySelectorAll<HTMLElement>("[class^=CloseButton]")
                  if (closeButton) {
                    closeButton[0].remove();
                  }
                }, 1000)

              });
              GladlyHelpApp.show();


            } catch (error: any) {
              console.error("Error waiting for gladly chat element", error);
            }
          })();
        })
        .catch((error: any) => {
          console.error("Error initializing gladly", error);
        });
    }
    // Only want to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default Gladly;
