/**
 * @generated SignedSource<<a8787494bd6ce4eebf4ba154be1a4012>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedCarouselForm_brand$data = {
  readonly feed: {
    readonly id: string;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CDPAudienceSelectorCard_brand" | "useFeedPostCreateMutation_brand" | "useFeedPostUpdateMutation_brand">;
  readonly " $fragmentType": "FeedCarouselForm_brand";
};
export type FeedCarouselForm_brand$key = {
  readonly " $data"?: FeedCarouselForm_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedCarouselForm_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedCarouselForm_brand",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLFeed",
      "kind": "LinkedField",
      "name": "feed",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFeedPostCreateMutation_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFeedPostUpdateMutation_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CDPAudienceSelectorCard_brand"
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "6cf20445542dada7c3819352aa772fb4";

export default node;
