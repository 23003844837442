import { Stack, Typography } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { ReactNode } from "react";
import { useFragment } from "react-relay";
import { GraphQLCDPAudienceInput } from "../mutations/__generated__/useFeedPostCreateMutation.graphql";
import { CDPAudienceSelectorCard_brand$key } from "./__generated__/CDPAudienceSelectorCard_brand.graphql";
import {
  CDPAudienceLanguageSelector,
  CDPAudienceLanguageSelectorProps,
} from "./CDPAudienceLanguageSelector";
import CDPAudienceSelector from "./CDPAudienceSelector";
import CardSection from "./CardSection";

const brandFragment = graphql`
  fragment CDPAudienceSelectorCard_brand on BrandType {
    ...CDPAudienceSelector_brand
    ...CDPAudienceLanguageSelector_brand
  }
`;

type CDPAudienceSelectorCardCommonProps = {
  audienceIDs: string[];
  brand: CDPAudienceSelectorCard_brand$key;
  onAudiencesChange: (audiences: GraphQLCDPAudienceInput[]) => void;
  disabled?: boolean;
} & Pick<
  CDPAudienceLanguageSelectorProps,
  "audienceLanguages" | "onLanguageFiltersChange"
>;
type CDPAudienceSelectorCardMinimalProps = {
  isCompact: true;
} & CDPAudienceSelectorCardCommonProps;
type CDPAudienceSelectorCardFullProps = {
  isCompact?: false;
  title?: string;
  subtitle: string;
} & CDPAudienceSelectorCardCommonProps;
type CDPAudienceSelectorCardProps =
  | CDPAudienceSelectorCardMinimalProps
  | CDPAudienceSelectorCardFullProps;

export const CDPAudienceSelectorCard = (
  props: CDPAudienceSelectorCardProps
) => {
  const {
    audienceIDs,
    brand: brandKey,
    isCompact = false,
    disabled,
    onAudiencesChange,
    audienceLanguages,
    onLanguageFiltersChange,
  } = props;
  const brand = useFragment(brandFragment, brandKey);
  return (
    <CDPAudienceSelectorCardWrapper {...props} disabled={disabled}>
      <CDPAudienceSelector
        audienceIDs={audienceIDs}
        brand={brand}
        onAudiencesChange={onAudiencesChange}
        disabled={disabled}
      />
      <CDPAudienceLanguageSelector
        audienceLanguages={audienceLanguages}
        brand={brand}
        label={
          isCompact ? undefined : "Limit to devices using following languages"
        }
        onLanguageFiltersChange={onLanguageFiltersChange}
        disabled={disabled}
      />
    </CDPAudienceSelectorCardWrapper>
  );
};

const CDPAudienceSelectorCardWrapper = (
  props: CDPAudienceSelectorCardProps & { children: ReactNode }
) => {
  if (props.isCompact) {
    return <>{props.children}</>;
  }
  const { title = "Choose your audience", subtitle } = props;
  return (
    <CardSection
      showIsOptional
      title={title}
      content={
        <Stack spacing={2} width={"100%"}>
          <Typography variant="body1">{subtitle}</Typography>
          {props.children}
        </Stack>
      }
    />
  );
};
