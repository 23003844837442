import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useFragment } from "react-relay";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DailyDealTableRow_dailyDeal$key,
  DiscountType,
} from "./__generated__/DailyDealTableRow_dailyDeal.graphql";
import CDPAudienceInfoTableCell from "../../../../components/CDPAudienceInfoTableCell";
import { KDateRange } from "../../analytics/types/KDateRange";

const dailyDealFragment = graphql`
  fragment DailyDealTableRow_dailyDeal on GraphQLDailyDeal {
    id
    archivedAt
    audienceLanguages
    discountType
    discountCode
    discountUrl
    imageUrl
    date
    startsAt
    endsAt
    blurredHeadline
    blurredSubheadline
    headline
    subheadline
    audiences {
      audienceName
    }
  }
`;

type DailyDealTypeInfo = {
  label: string;
  description: string;
};

export const DAILY_DEAL_TYPES_INFO_MAP: {
  [key in DiscountType]: DailyDealTypeInfo;
} = {
  PRODUCT: {
    label: "Product",
    description: "Applies to specific products and/or collections",
  },
  ORDER: {
    label: "Order",
    description: "Applies on the entire order",
  },
  "%future added value": {
    label: "Unknown",
    description: "Unknown",
  },
} as const;

const DailyDealTableRow = ({
  dailyDeal: dailyDealKey,
  onArchive,
  onUnArchive,
}: {
  dailyDeal: DailyDealTableRow_dailyDeal$key;
  onArchive: () => void;
  onUnArchive: () => void;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dailyDeal = useFragment(dailyDealFragment, dailyDealKey);
  const isNew = false;
  const isArchived = dailyDeal.archivedAt !== null;

  const titleColumn = (
    <ListItem>
      <ListItemAvatar>
        <Avatar
          alt=""
          src={dailyDeal.imageUrl ?? ""}
          variant="rounded"
          sx={{ bgcolor: theme.palette.info.main, color: "black" }}
        >
          <InsertPhotoIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <Typography
            variant="body2"
            color={"black"}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {dailyDeal.discountCode}
          </Typography>
        }
      />
    </ListItem>
  );

  const dateRange: KDateRange = {
    start: dayjs(dailyDeal.startsAt),
    end: dayjs(dailyDeal.endsAt),
  };
  const dateColumn = (
    <Box
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
      }}
    >
      {dateRange.start.format("MMM D, YYYY H:mm")}
      <Box>
        <Typography variant="caption" color="text.secondary">
          {dateRange.end.format("MMM D, YYYY H:mm")}
        </Typography>
      </Box>
    </Box>
  );

  const editButton = !isArchived ? (
    <Button
      size="small"
      variant="text"
      color="primary"
      onClick={() => navigate(location.pathname + `/edit/${dailyDeal.id}`)}
    >
      Edit
    </Button>
  ) : null;

  const archiveButton = !isArchived ? (
    <Button size="small" variant="text" color="error" onClick={onArchive}>
      Archive
    </Button>
  ) : null;

  const unarchiveButton = isArchived ? (
    <Button size="small" variant="text" color="primary" onClick={onUnArchive}>
      Unarchive
    </Button>
  ) : null;

  return (
    <TableRow
      sx={{
        background: isNew ? "rgba(0,0,0, 0.08)" : "white",
        transition: "background .2s ease-in",
        ...(isArchived ? { filter: "opacity(0.5)" } : {}),
      }}
    >
      <TableCell>{titleColumn}</TableCell>
      <TableCell align="center">
        {DAILY_DEAL_TYPES_INFO_MAP[dailyDeal.discountType].label}
      </TableCell>
      <CDPAudienceInfoTableCell item={dailyDeal} />
      <TableCell align="center">{dateColumn}</TableCell>
      <TableCell align="center">
        {isArchived && (
          <Stack direction="row" justifyContent={"center"}>
            {unarchiveButton}
          </Stack>
        )}
        {!isArchived && (
          <Stack direction="row" justifyContent={"center"}>
            {editButton}
            {archiveButton}
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
};

export default DailyDealTableRow;
