import { Container } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { InternBrandAndroidBuilds_brand$key } from "./__generated__/InternBrandAndroidBuilds_brand.graphql"
import nullthrows from "../../utils/nullthrows";
import InternBrandAndroidBuildsGeneralInfo from "./InternBrandAndroidBuildsGeneralInfo";
import InternBrandAndroidBuildsActions from "./InternBrandAndroidBuildsActions"

const brandFragment = graphql`
  fragment InternBrandAndroidBuilds_brand on BrandType {
    id
    appConfig {
      ...InternBrandAndroidBuildsGeneralInfo_appConfig
    }
  }
`;

const InternBrandAndroidBuilds = ({
  brand: brandKey,
}: {
  brand: InternBrandAndroidBuilds_brand$key;
}) => {
  const brand = useFragment(brandFragment, brandKey);
  const appConfig = nullthrows(brand.appConfig);

  return (
    <Container maxWidth="md">
      <InternBrandAndroidBuildsActions brandID={brand.id} />
      <InternBrandAndroidBuildsGeneralInfo appConfig={appConfig} />
    </Container>
  );
};

export default InternBrandAndroidBuilds;
