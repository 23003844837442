/**
 * @generated SignedSource<<ffbecf24e78987e1301411d877327fed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppColorPalette = "ACCENT_1" | "ACCENT_2" | "ACCENT_3" | "BACKGROUND_1" | "BACKGROUND_2" | "BLACK" | "GRAY" | "PRIMARY" | "WHITE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OnboardingCard_brand$data = {
  readonly appConfig: {
    readonly appContent: {
      readonly id: string;
      readonly onboardingRootTitle: string;
      readonly onboardingUpsellSubtitle: string;
      readonly onboardingUpsellTitle: string;
    } | null;
    readonly buildTheme: {
      readonly id: string;
      readonly onboardingAssetUrl: string | null;
      readonly onboardingBackground: AppColorPalette | null;
      readonly onboardingForeground: AppColorPalette | null;
    };
    readonly id: string;
  };
  readonly " $fragmentType": "OnboardingCard_brand";
};
export type OnboardingCard_brand$key = {
  readonly " $data"?: OnboardingCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingCard_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnboardingCard_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandAppConfigBuildThemeType",
          "kind": "LinkedField",
          "name": "buildTheme",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onboardingAssetUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onboardingBackground",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onboardingForeground",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandAppConfigContentType",
          "kind": "LinkedField",
          "name": "appContent",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onboardingRootTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onboardingUpsellTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onboardingUpsellSubtitle",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "a0e49d7ff970f3d72d47e740add1966f";

export default node;
