/**
 * @generated SignedSource<<01660fe8402579676d6c12d19689a4f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppColorPalette = "ACCENT_1" | "ACCENT_2" | "ACCENT_3" | "BACKGROUND_1" | "BACKGROUND_2" | "BLACK" | "GRAY" | "PRIMARY" | "WHITE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ThemeTab_brand$data = {
  readonly appConfig: {
    readonly accent1PaletteLight: string;
    readonly accent2PaletteLight: string;
    readonly accent3PaletteLight: string;
    readonly buildTheme: {
      readonly badgeBackground: AppColorPalette | null;
      readonly badgeForeground: AppColorPalette | null;
      readonly navigationBackground: AppColorPalette | null;
      readonly navigationForeground: AppColorPalette | null;
      readonly pillContainerBackground: AppColorPalette | null;
      readonly primaryButtonBackground: AppColorPalette | null;
      readonly primaryButtonForeground: AppColorPalette | null;
      readonly primaryPillBackground: AppColorPalette | null;
      readonly primaryPillForeground: AppColorPalette | null;
      readonly secondaryButtonOutline: AppColorPalette | null;
      readonly secondaryPillOutline: AppColorPalette | null;
    };
    readonly id: string;
    readonly primaryPaletteLight: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AccountCard_brand" | "ButtonStyleCard_brand" | "ColorsCard_brand" | "LaunchScreenCard_brand" | "LogoCard_brand" | "NotificationsCard_brand" | "OnboardingCard_brand" | "TypographyCard_brand">;
  readonly " $fragmentType": "ThemeTab_brand";
};
export type ThemeTab_brand$key = {
  readonly " $data"?: ThemeTab_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"ThemeTab_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ThemeTab_brand",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountCard_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ButtonStyleCard_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ColorsCard_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LaunchScreenCard_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnboardingCard_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationsCard_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LogoCard_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TypographyCard_brand"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accent1PaletteLight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accent2PaletteLight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accent3PaletteLight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "primaryPaletteLight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandAppConfigBuildThemeType",
          "kind": "LinkedField",
          "name": "buildTheme",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "primaryButtonBackground",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "primaryButtonForeground",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "secondaryButtonOutline",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "badgeBackground",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "badgeForeground",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "navigationBackground",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "navigationForeground",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "primaryPillBackground",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "primaryPillForeground",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "secondaryPillOutline",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pillContainerBackground",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "43ef10782db3d51920eb51dca032ff2e";

export default node;
