/**
 * @generated SignedSource<<aa575170f733baf58c2c6b234434a168>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShortcutsCard_brand$data = {
  readonly appConfig: {
    readonly id: string;
    readonly navigationItems: ReadonlyArray<{
      readonly children: ReadonlyArray<{
        readonly children: ReadonlyArray<{
          readonly index: number;
          readonly label: string;
          readonly url: string;
        }>;
        readonly index: number;
        readonly label: string;
        readonly url: string;
      }>;
      readonly index: number;
      readonly label: string;
      readonly url: string;
    }>;
    readonly shouldLockMenus: boolean;
  };
  readonly id: string;
  readonly shopifyConfig: {
    readonly isConnected: boolean;
  } | null;
  readonly " $fragmentType": "ShortcutsCard_brand";
};
export type ShortcutsCard_brand$key = {
  readonly " $data"?: ShortcutsCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShortcutsCard_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShortcutsCard_brand",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shouldLockMenus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NavigationItemType",
          "kind": "LinkedField",
          "name": "navigationItems",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "NavigationItemType",
              "kind": "LinkedField",
              "name": "children",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NavigationItemType",
                  "kind": "LinkedField",
                  "name": "children",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandShopifyConfigType",
      "kind": "LinkedField",
      "name": "shopifyConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isConnected",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "ce81bfa2807f689b659ed13fc8ab59ec";

export default node;
