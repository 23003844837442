/**
 * @generated SignedSource<<69c925ecb14bd65d817d445a4df8ff84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedPollForm_brand$data = {
  readonly feed: {
    readonly id: string;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CDPAudienceSelectorCard_brand" | "useFeedPostCreateMutation_brand" | "useFeedPostUpdateMutation_brand">;
  readonly " $fragmentType": "FeedPollForm_brand";
};
export type FeedPollForm_brand$key = {
  readonly " $data"?: FeedPollForm_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedPollForm_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedPollForm_brand",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLFeed",
      "kind": "LinkedField",
      "name": "feed",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFeedPostCreateMutation_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useFeedPostUpdateMutation_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CDPAudienceSelectorCard_brand"
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "01f891fc15b6f0943de891c2890fe2e5";

export default node;
