/**
 * @generated SignedSource<<9441b7508c49b0143c2885e870ba26ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MappingTab_brand$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AppLayoutCard_brand" | "HtmlConfigCard_brand">;
  readonly " $fragmentType": "MappingTab_brand";
};
export type MappingTab_brand$key = {
  readonly " $data"?: MappingTab_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"MappingTab_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "skip"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "MappingTab_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HtmlConfigCard_brand"
    },
    {
      "condition": "skip",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AppLayoutCard_brand"
        }
      ]
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "177ba3026bc72fd661af069eb0db63e3";

export default node;
