import {
  Alert,
  AlertTitle,
  Box,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import { FocusEvent, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment, useMutation } from "react-relay";
import * as yup from "yup";
import { AppLayoutCardUpdateMutation } from "./__generated__/AppLayoutCardUpdateMutation.graphql";
import { AppLayoutCard_brand$key } from "./__generated__/AppLayoutCard_brand.graphql";
import CardSection from "../../../../components/CardSection";
import SaveButton, { SavedState } from "../../../../components/SaveButton";
import SnackbarContext from "../../../../contexts/SnackbarContext";
import nullthrows from "../../../../utils/nullthrows";
import { validateUrl } from "../../../../utils/validators";

const brandFragment = graphql`
  fragment AppLayoutCard_brand on BrandType {
    id
    domain
    appConfig {
      appViewToUrlMappings {
        id
        appViewType
        regex
        url
      }
    }
  }
`;

const updateMutation = graphql`
  mutation AppLayoutCardUpdateMutation(
    $brandId: ID!
    $mappingInputs: [GraphQLBrandAppViewToURLMappingInputPartial!]!
  ) {
    updateUrlRegexMappings(brandId: $brandId, mappingInputs: $mappingInputs) {
      ... on BrandType {
        ...AppLayoutCard_brand
      }
    }
  }
`;

const schema = yup
  .object({
    mappings: yup.lazy((obj) => {
      return yup.object(
        Object.keys(obj).reduce(
          (acc, curr) => {
            acc[curr] = yup.object({
              url: yup
                .string()
                .required("URL is required")
                .test("is-url-valid", "URL is not valid", (value) => {
                  return validateUrl(value);
                }),
              regex: yup.string().required("Regex is required"),
            });
            return acc;
          },
          {} as Record<
            string,
            yup.ObjectSchema<{
              url: string;
              regex: string;
            }>
          >
        )
      );
    }),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

const AppLayoutCard = ({
  brand: brandKey,
}: {
  brand: AppLayoutCard_brand$key;
}) => {
  const snackbarContext = useContext(SnackbarContext);

  const brand = useFragment(brandFragment, brandKey);
  const mappings = brand.appConfig.appViewToUrlMappings;

  const [updateUrlRegexMappings, isUpdating] =
    useMutation<AppLayoutCardUpdateMutation>(updateMutation);

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.ENABLED
  );

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const onToggle = (enabled: boolean) => {
    setIsEditing(enabled);
  };
  const editToggle = (
    <FormControlLabel
      control={
        <Switch
          checked={isEditing}
          disabled={isEditing}
          onChange={(
            _event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
          ) => {
            onToggle(checked);
          }}
        />
      }
      label="Edit"
      labelPlacement="end"
    />
  );

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: FormData) => {
    updateUrlRegexMappings({
      variables: {
        brandId: brand.id,
        mappingInputs: mappings.map((mapping, layoutIndex) => {
          return {
            id: mapping.id,
            url: nullthrows(data.mappings[mapping.appViewType].url),
            regex: nullthrows(data.mappings[mapping.appViewType].regex),
          };
        }),
      },
      onCompleted: (data, errors) => {
        if (errors) {
          snackbarContext?.openSnackbar("Update Failed", "error");
        } else {
          snackbarContext?.openSnackbar("Updated", "success");
          setIsEditing(false);
        }
        setSaveButtonState(SavedState.ENABLED);
      },
      onError: (error: Error) => {
        snackbarContext?.openSnackbar("Update Failed", "error");
        setSaveButtonState(SavedState.ENABLED);
      },
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <CardSection
        actions={
          <SaveButton
            savedState={
              mappings.length === 0 || isUpdating || !isEditing
                ? SavedState.DISABLED
                : saveButtonState
            }
            useSubmit={true}
          />
        }
        title={"App Layout Mapping"}
        subtitle="Map each layout type to a path to your storefront and setup a regex that can handle all variations of that path."
        content={
          <Stack spacing={1}>
            <Alert
              severity="warning"
              sx={{
                width: "100%",
              }}
              action={
                <Box justifyContent={"end"} display={"flex"}>
                  {editToggle}
                </Box>
              }
            >
              <AlertTitle
                sx={{
                  fontWeight: "bold",
                }}
              >
                Important
              </AlertTitle>
              Editing and saving changes to these mappings will affect the
              navigation behavior of the app
            </Alert>
            {mappings.map((mapping, layoutIndex) => {
              const appViewType = mapping.appViewType;
              return (
                <Box key={layoutIndex}>
                  <Typography variant="overline">
                    {appViewType.replace("_", " ")}
                  </Typography>
                  <Stack spacing={2} mt={1}>
                    <TextField
                      {...register(
                        `mappings.${mapping.appViewType}.url` as const,
                        {
                          onChange: (event: FocusEvent<HTMLInputElement>) => {
                            saveButtonState !== SavedState.ENABLED &&
                              setSaveButtonState(SavedState.ENABLED);
                          },
                          disabled: !isEditing,
                        }
                      )}
                      multiline // NOTE: This is a hack to prevent password managers from autofilling the field
                      type="text"
                      defaultValue={mapping.url}
                      label={"Urls"}
                      fullWidth
                      error={
                        !!errors?.mappings?.[mapping.appViewType]?.url?.message
                      }
                      helperText={
                        errors?.mappings?.[mapping.appViewType]?.url?.message
                      }
                      variant="outlined"
                    />
                    <TextField
                      {...register(`mappings.${appViewType}.regex` as const, {
                        onChange: (event: FocusEvent<HTMLInputElement>) => {
                          saveButtonState !== SavedState.ENABLED &&
                            setSaveButtonState(SavedState.ENABLED);
                        },
                        disabled: !isEditing,
                      })}
                      defaultValue={mapping.regex}
                      label="Regex Mapping"
                      fullWidth
                      multiline
                      error={!!errors?.mappings?.[appViewType]?.regex?.message}
                      helperText={
                        errors?.mappings?.[appViewType]?.regex?.message
                      }
                      variant="outlined"
                    />
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        }
      />
    </form>
  );
};

export default AppLayoutCard;
