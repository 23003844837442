/**
 * @generated SignedSource<<adda0b56d91affb1a608e8a18d0cdaeb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DailyDealsTabQuery$variables = {
  id: string;
};
export type DailyDealsTabQuery$data = {
  readonly brand: {
    readonly " $fragmentSpreads": FragmentRefs<"DailyDealsTab_brand">;
  };
};
export type DailyDealsTabQuery = {
  response: DailyDealsTabQuery$data;
  variables: DailyDealsTabQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "audienceIds",
        "value": ([]/*: any*/)
      },
      {
        "kind": "Literal",
        "name": "audienceLanguages",
        "value": ([]/*: any*/)
      }
    ],
    "kind": "ObjectValue",
    "name": "filters"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DailyDealsTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DailyDealsTab_brand"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DailyDealsTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandAppConfigType",
            "kind": "LinkedField",
            "name": "appConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "supportedLanguages",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enableLanguageSpecificContent",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLCDP",
            "kind": "LinkedField",
            "name": "cdps",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cdpType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLCDPAudienceType",
                "kind": "LinkedField",
                "name": "audiences",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "GraphQLDailyDealConnection",
            "kind": "LinkedField",
            "name": "dailyDeals",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLDailyDealEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphQLDailyDeal",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "archivedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "audienceLanguages",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "discountType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "discountCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "discountUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "date",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startsAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endsAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "blurredHeadline",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "blurredSubheadline",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "headline",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subheadline",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GraphQLCDPAudience",
                        "kind": "LinkedField",
                        "name": "audiences",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "audienceName",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "dailyDeals(filters:{\"audienceIds\":[],\"audienceLanguages\":[]},first:10)"
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": [
              "filters"
            ],
            "handle": "connection",
            "key": "DailyDealsTable_dailyDeals",
            "kind": "LinkedHandle",
            "name": "dailyDeals"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "domain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandShopifyConfigType",
            "kind": "LinkedField",
            "name": "shopifyConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shopifyUrl",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14db99aef97b40b4b24582e02fef14f5",
    "id": null,
    "metadata": {},
    "name": "DailyDealsTabQuery",
    "operationKind": "query",
    "text": "query DailyDealsTabQuery(\n  $id: ID!\n) {\n  brand(id: $id) {\n    ...DailyDealsTab_brand\n    id\n  }\n}\n\nfragment CDPAudienceFilters_brand on BrandType {\n  ...CDPAudienceLanguageSelector_brand\n  cdps {\n    cdpType\n    audiences {\n      id\n      name\n      type\n    }\n    id\n  }\n}\n\nfragment CDPAudienceLanguageSelector_brand on BrandType {\n  appConfig {\n    supportedLanguages\n    enableLanguageSpecificContent\n    id\n  }\n}\n\nfragment CDPAudienceSelectorCard_brand on BrandType {\n  ...CDPAudienceSelector_brand\n  ...CDPAudienceLanguageSelector_brand\n}\n\nfragment CDPAudienceSelector_brand on BrandType {\n  cdps {\n    cdpType\n    audiences {\n      id\n      name\n      type\n    }\n    id\n  }\n}\n\nfragment DailyDealForm_brand on BrandType {\n  id\n  domain\n  shopifyConfig {\n    shopifyUrl\n    id\n  }\n  ...CDPAudienceSelectorCard_brand\n}\n\nfragment DailyDealTableRow_dailyDeal on GraphQLDailyDeal {\n  id\n  archivedAt\n  audienceLanguages\n  discountType\n  discountCode\n  discountUrl\n  imageUrl\n  date\n  startsAt\n  endsAt\n  blurredHeadline\n  blurredSubheadline\n  headline\n  subheadline\n  audiences {\n    audienceName\n    id\n  }\n}\n\nfragment DailyDealsTab_brand on BrandType {\n  id\n  ...CDPAudienceFilters_brand\n  ...DailyDealsTable_refetchableBrand\n  ...DailyDealForm_brand\n}\n\nfragment DailyDealsTable_refetchableBrand on BrandType {\n  dailyDeals(first: 10, filters: {audienceIds: [], audienceLanguages: []}) {\n    edges {\n      node {\n        id\n        ...DailyDealTableRow_dailyDeal\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "28c93ccc316fad04af6983fce1516bee";

export default node;
