/**
 * @generated SignedSource<<47d9a21698cd50957d8bb25bb1b6aff9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationsTabQuery$variables = {
  id: string;
};
export type NotificationsTabQuery$data = {
  readonly brand: {
    readonly " $fragmentSpreads": FragmentRefs<"NotificationsTab_brand">;
  };
};
export type NotificationsTabQuery = {
  response: NotificationsTabQuery$data;
  variables: NotificationsTabQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "after",
    "value": ""
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v4 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NotificationsTab_brand"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationsTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deeplinkUrlScheme",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLCDP",
            "kind": "LinkedField",
            "name": "cdps",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cdpType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLCDPAudienceType",
                "kind": "LinkedField",
                "name": "audiences",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandAppConfigType",
            "kind": "LinkedField",
            "name": "appConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "supportedLanguages",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enableLanguageSpecificContent",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "GraphQLNotificationConnection",
            "kind": "LinkedField",
            "name": "notifications",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLNotificationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphQLNotification",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "uuid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "audienceLanguages",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "automaticArchiveTimestamp",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbnailUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "externalThumbnailUri",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isArchived",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "landingPath",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timestamp",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "utmCampaign",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GraphQLCDPAudience",
                        "kind": "LinkedField",
                        "name": "audiences",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "audienceName",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GraphQLDailyDeal",
                        "kind": "LinkedField",
                        "name": "dailyDeal",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GraphQLFeedPost",
                        "kind": "LinkedField",
                        "name": "feedPost",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GraphQLHighlight",
                        "kind": "LinkedField",
                        "name": "highlight",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GraphQLHighlightAlbum",
                            "kind": "LinkedField",
                            "name": "highlightAlbum",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "notifications(after:\"\",first:10)"
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "NotificationsTab_notifications",
            "kind": "LinkedHandle",
            "name": "notifications"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3542f53c04b9cac57f88e60e66afd7eb",
    "id": null,
    "metadata": {},
    "name": "NotificationsTabQuery",
    "operationKind": "query",
    "text": "query NotificationsTabQuery(\n  $id: ID!\n) {\n  brand(id: $id) {\n    ...NotificationsTab_brand\n    id\n  }\n}\n\nfragment CDPAudienceLanguageSelector_brand on BrandType {\n  appConfig {\n    supportedLanguages\n    enableLanguageSpecificContent\n    id\n  }\n}\n\nfragment CDPAudienceSelectorCard_brand on BrandType {\n  ...CDPAudienceSelector_brand\n  ...CDPAudienceLanguageSelector_brand\n}\n\nfragment CDPAudienceSelector_brand on BrandType {\n  cdps {\n    cdpType\n    audiences {\n      id\n      name\n      type\n    }\n    id\n  }\n}\n\nfragment NotificationForm_brand on BrandType {\n  id\n  deeplinkUrlScheme\n  ...CDPAudienceSelectorCard_brand\n}\n\nfragment NotificationTableRow_notification on GraphQLNotification {\n  id\n  uuid\n  audienceLanguages\n  automaticArchiveTimestamp\n  body\n  thumbnailUrl\n  externalThumbnailUri\n  isArchived\n  landingPath\n  timestamp\n  title\n  utmCampaign\n  audiences {\n    audienceName\n    id\n  }\n  dailyDeal {\n    id\n  }\n  feedPost {\n    id\n  }\n  highlight {\n    id\n    highlightAlbum {\n      id\n    }\n  }\n}\n\nfragment NotificationsTab_brand on BrandType {\n  ...NotificationForm_brand\n  id\n  notifications(first: 10, after: \"\") {\n    edges {\n      node {\n        id\n        ...NotificationTableRow_notification\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d91f8ded341b32f0fbb8b7741f48bdd4";

export default node;
