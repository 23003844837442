import { Box, Stack, Typography } from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import formatNumber from "../../utils/formatNumber";

const CustomizedXTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fontSize={12} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedYTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <Typography>{formatNumber(payload.value).split(".")[0]}</Typography>
      <text
        x={0}
        y={0}
        dx={-36}
        dy={4}
        fontSize={12}
        textAnchor="middle"
        fill="#666"
      >
        {formatNumber(payload.value).split(".")[0]}
      </text>
    </g>
  );
};

const CustomTooltip = ({
  active,
  payload,
  label,
}: {
  active?: boolean;
  payload?: any[];
  label?: string;
}) => {
  if (active && payload && payload.length) {
    const line1 = payload[0];
    const line2 = payload[1];

    const line1Info = (
      <Box>
        {line1.name}: {formatNumber(line1.value)}
      </Box>
    );
    const line2Info = line2 && (
      <Box>
        {line2.name}: {formatNumber(line2.value)}
      </Box>
    );
    const total = (
      <Box>Total: {line2 ? line1.value + line2.value : line1.value}</Box>
    );
    return (
      <Stack
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          p: 2,
          color: "black",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "120%",
        }}
        spacing={1}
      >
        <Box>
          <b>{line1.payload.name}</b>
        </Box>
        {line2Info}
        {line1Info}
        {total}
      </Stack>
    );
  }

  return null;
};

const StackedBarChart = ({
  data,
  line1Name,
  line2Name,
  title,
  showTotalStats,
  showAverageStats,
}: {
  data: {
    name: string;
    data1: number;
    data2: number;
  }[];
  line1Name: string;
  line2Name: string;
  title?: string;
  showTotalStats?: boolean;
  showAverageStats?: boolean;
}) => {
  const total = data.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.data1 + currentValue.data2,
    0
  );
  const average = total
    ? formatNumber(total / data.length, undefined, undefined, 0)
    : 0;
  return (
    <>
      {title && (
        <Typography
          sx={{
            color: "black",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "24px",
            pb: 1,
          }}
        >
          {title}
        </Typography>
      )}
      {(showTotalStats || showAverageStats) && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {showTotalStats && (
            <Typography>
              Total {title?.toLowerCase()}: {total}
            </Typography>
          )}
          {showAverageStats && (
            <Typography marginLeft="auto">Average: {average}</Typography>
          )}
        </Box>
      )}
      <Box
        sx={{
          height: 300,
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" tick={CustomizedXTick} />
            <YAxis tick={CustomizedYTick} />
            <Tooltip content={(params) => <CustomTooltip {...params} />} />
            <Legend
              iconSize={12}
              formatter={(value, entry, index) => {
                return (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "120%",
                    }}
                  >
                    {value}
                  </Typography>
                );
              }}
            />
            <Bar
              name={line1Name}
              dataKey="data1"
              stackId="a"
              fill="#8884d8"
              isAnimationActive={false}
            />
            <Bar
              name={line2Name}
              dataKey="data2"
              stackId="a"
              fill="#82ca9d"
              isAnimationActive={false}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
};

export default StackedBarChart;
