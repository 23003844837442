/**
 * @generated SignedSource<<85c84cf67d8e14a4cd5eaf38c7e09358>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LaunchScreenOption = "CUSTOM" | "SIMPLE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GetStartedTab_brand$data = {
  readonly appConfig: {
    readonly accent1PaletteLight: string;
    readonly accent2PaletteLight: string;
    readonly accent3PaletteLight: string;
    readonly buildTheme: {
      readonly launchScreenOption: LaunchScreenOption | null;
      readonly logoUrl: string | null;
    };
    readonly moreMenuItems: ReadonlyArray<{
      readonly __typename: string;
    }>;
    readonly navigationItems: ReadonlyArray<{
      readonly __typename: "NavigationItemType";
    }>;
    readonly primaryPaletteLight: string;
  };
  readonly finishedOnboarding: boolean;
  readonly hasCreatedFeedPost: boolean;
  readonly hasCreatedNotification: boolean;
  readonly id: string;
  readonly onboardingConfig: {
    readonly isAppApproved: boolean;
    readonly isAppListingComplete: boolean;
    readonly isAppSubmitted: boolean;
    readonly isDeveloperAccountSetup: boolean;
    readonly isIncentivesSetup: boolean;
    readonly isKinnAddedToDeveloperAccount: boolean;
    readonly isMarketingSetup: boolean;
    readonly isNotificationsSetup: boolean;
    readonly isThirdPartyIntegrationSetup: boolean;
  } | null;
  readonly " $fragmentType": "GetStartedTab_brand";
};
export type GetStartedTab_brand$key = {
  readonly " $data"?: GetStartedTab_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetStartedTab_brand">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GetStartedTab_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finishedOnboarding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCreatedFeedPost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCreatedNotification",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandOnboardingConfigType",
      "kind": "LinkedField",
      "name": "onboardingConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDeveloperAccountSetup",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isKinnAddedToDeveloperAccount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isThirdPartyIntegrationSetup",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isNotificationsSetup",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAppListingComplete",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAppSubmitted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAppApproved",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isIncentivesSetup",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isMarketingSetup",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accent1PaletteLight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accent2PaletteLight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accent3PaletteLight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "primaryPaletteLight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandAppConfigBuildThemeType",
          "kind": "LinkedField",
          "name": "buildTheme",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "launchScreenOption",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "logoUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "moreMenuItems",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NavigationItemType",
          "kind": "LinkedField",
          "name": "navigationItems",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "770c0281ca741cba07bdcbd1648acdc4";

export default node;
