import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { FacebookTab_brand$key } from "./__generated__/FacebookTab_brand.graphql";
import { FacebookTab_deleteFacebookClientTokenMutation } from "./__generated__/FacebookTab_deleteFacebookClientTokenMutation.graphql";
import { FacebookTab_updateChosenFacebookPageMutation } from "./__generated__/FacebookTab_updateChosenFacebookPageMutation.graphql";
import { FacebookTab_updateFacebookClientTokenMutation } from "./__generated__/FacebookTab_updateFacebookClientTokenMutation.graphql";
import CardSection from "../../../components/CardSection";
import SaveButton, { SavedState } from "../../../components/SaveButton";
import FacebookLogin, {
  FacebookSuccessResponse,
} from "../../../components/facebook/FacebookLogin";
import { FACEBOOK_APP_ID } from "../../../utils/constants";
import { deleteFacebookCookies } from "../../../utils/deleteCookies";

const updateFacebookConfigMutation = graphql`
  mutation FacebookTab_updateFacebookClientTokenMutation(
    $input: GraphQLFacebookConfigInput!
  ) {
    updateFacebookClientToken(input: $input) {
      ...FacebookTab_brand
    }
  }
`;

const updateFacebookPageMutation = graphql`
  mutation FacebookTab_updateChosenFacebookPageMutation(
    $input: GraphQLFacebookPageInput!
  ) {
    updateChosenFacebookPage(input: $input) {
      ...FacebookTab_brand
    }
  }
`;

const deleteFacebookConfigMutation = graphql`
  mutation FacebookTab_deleteFacebookClientTokenMutation($input: NodeInput!) {
    deleteFacebookClientToken(input: $input) {
      ...FacebookTab_brand
    }
  }
`;

const brandFragment = graphql`
  fragment FacebookTab_brand on BrandType {
    id
    facebookConfig {
      id
      facebookPages {
        id
        name
        isSelected
      }
    }
  }
`;

type Props = {
  brand: FacebookTab_brand$key;
};
const FacebookTab = ({ brand: brandKey }: Props) => {
  const [addToken, isTokenMutationInFlight] =
    useMutation<FacebookTab_updateFacebookClientTokenMutation>(
      updateFacebookConfigMutation
    );
  const [updateChosenPage, isMutationInFlight] =
    useMutation<FacebookTab_updateChosenFacebookPageMutation>(
      updateFacebookPageMutation
    );
  const [deleteToken] =
    useMutation<FacebookTab_deleteFacebookClientTokenMutation>(
      deleteFacebookConfigMutation
    );
  const brand = useFragment(brandFragment, brandKey);
  const facebookPages = useMemo(
    () => brand.facebookConfig?.facebookPages,
    [brand.facebookConfig]
  );

  const existingFacebookConfigId = brand.facebookConfig?.id;
  const [selectedPage, setSelectedPage] = useState(
    brand.facebookConfig?.facebookPages?.[0]?.id
  );
  useEffect(() => {
    const selectedPage = brand.facebookConfig?.facebookPages?.filter(
      (page) => page.isSelected
    )[0];
    setSelectedPage(selectedPage?.id);
  }, [brand.facebookConfig?.facebookPages]);
  const defaultPage = useMemo(
    () =>
      brand.facebookConfig?.facebookPages?.filter((page) => page.isSelected)[0],
    [brand.facebookConfig]
  );
  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.DISABLED
  );
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const onConnect = useCallback(
    (response: FacebookSuccessResponse) =>
      addToken({
        variables: {
          input: {
            id: brand.id,
            token: response.accessToken,
          },
        },
        onError: (error) => {
          setLoginErrorMessage(error.message);
        },
      }),
    [addToken, brand.id]
  );
  const onDelete = useCallback(() => {
    deleteToken({
      variables: {
        input: {
          id: existingFacebookConfigId ?? "",
        },
      },
    });
    setLoginErrorMessage("");
    deleteFacebookCookies();
  }, [deleteToken, existingFacebookConfigId]);
  const updateSelectedPage = useCallback(
    (pageId: string) =>
      updateChosenPage({
        variables: {
          input: {
            id: brand.id,
            facebookPageId: pageId,
          },
        },
        onCompleted() {
          setSaveButtonState(SavedState.SAVED);
        },
      }),
    [updateChosenPage, brand.id]
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const onToggle = (enabled: boolean) => {
    setIsEditing(enabled);
  };
  const onSelectInputChange = useCallback((event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedPage(value);
    setSaveButtonState(SavedState.ENABLED);
  }, []);
  const editToggle = (
    <FormControlLabel
      control={
        <Switch
          checked={isEditing}
          disabled={isEditing}
          onChange={(
            _event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
          ) => {
            onToggle(checked);
          }}
        />
      }
      label="Edit"
      labelPlacement="end"
    />
  );

  useEffect(() => {
    if (!brand.facebookConfig) {
      setSaveButtonState(SavedState.DISABLED);
      setIsEditing(false);
    }
  }, [brand.facebookConfig]);

  return (
    <Container maxWidth="md">
      <Stack spacing={2}>
        <CardSection
          title={"Connect"}
          content={
            <Stack spacing={1}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {isTokenMutationInFlight ? (
                  <Box marginTop="18px">
                    <CircularProgress size={35} />
                  </Box>
                ) : !!brand?.facebookConfig?.id ? (
                  <>
                    <Button
                      sx={{ margin: "16px", width: "fit-content" }}
                      variant="contained"
                      size="medium"
                      onClick={onDelete}
                    >
                      Disconnect
                    </Button>
                    <Alert severity="success">
                      <AlertTitle
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        Your stories will now be synced hourly
                      </AlertTitle>
                    </Alert>
                  </>
                ) : (
                  FACEBOOK_APP_ID && (
                    <FacebookLogin
                      onSuccess={(response) => {
                        !existingFacebookConfigId &&
                          !isTokenMutationInFlight &&
                          onConnect(response);
                      }}
                      onFail={(error) => {
                        setLoginErrorMessage(error.error);
                      }}
                      isLoading={isTokenMutationInFlight}
                    />
                  )
                )}
                {!!loginErrorMessage && (
                  <Alert severity="warning">
                    <AlertTitle
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Login failed: {loginErrorMessage}
                    </AlertTitle>
                  </Alert>
                )}
              </Box>
            </Stack>
          }
        />
      </Stack>
      {!!facebookPages && (
        <CardSection
          actions={
            <SaveButton
              savedState={
                isMutationInFlight || !saveButtonState
                  ? SavedState.DISABLED
                  : saveButtonState
              }
              onClick={() => updateSelectedPage(selectedPage)}
            />
          }
          title={"Select Page"}
          content={
            <Stack spacing={1}>
              <Alert
                severity="warning"
                sx={{
                  width: "100%",
                }}
                action={
                  <Box justifyContent={"end"} display={"flex"}>
                    {editToggle}
                  </Box>
                }
              >
                <AlertTitle
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Important
                </AlertTitle>
                Editing and saving changes to these options will delete your
                current synced highlights and add the ones from the newly
                selected page
              </Alert>
              <Box sx={{ display: "flex" }}></Box>
              <Select
                disabled={!isEditing}
                label="Select your page"
                onChange={onSelectInputChange}
                value={selectedPage}
                defaultValue={defaultPage?.id}
              >
                {facebookPages?.map((facebookPage, index) => (
                  <MenuItem value={facebookPage.id} key={index}>
                    {facebookPage.name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          }
        />
      )}
    </Container>
  );
};

export default FacebookTab;
