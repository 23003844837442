import {
  CircularProgress,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { usePaginationFragment } from "react-relay";
import { FeedTabTablePaginationQuery } from "./__generated__/FeedTabTablePaginationQuery.graphql";
import { FeedTabTable_refetchableBrand$key } from "./__generated__/FeedTabTable_refetchableBrand.graphql";
import { KinnLanguageCode } from "../../../../utils/languageMap";
import FeedTabTableRow from "./FeedTabTableRow";

const refetchableFragment = graphql`
  fragment FeedTabTable_refetchableBrand on BrandType
  @refetchable(queryName: "FeedTabTablePaginationQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 10 }
    cursor: { type: "String" }
    audienceFilter: { type: "[String!]", defaultValue: [] }
    languageFilter: { type: "[KinnLanguageCode!]", defaultValue: [] }
  ) {
    feedPosts(
      first: $count
      after: $cursor
      filters: {
        audienceIds: $audienceFilter
        audienceLanguages: $languageFilter
      }
    ) @connection(key: "FeedTabTable_feedPosts") {
      edges {
        node {
          id
          ...FeedTabTableRow_feedPost
        }
      }
      totalCount
    }
  }
`;

const FeedTabTable = ({
  refetchableBrand: refetchableBrandKey,
  justPublishedPostID,
  tableSearchAudiences,
  tableSearchLanguages,
  setArchiveDialogPostID,
  setDeleteDialogPostID,
  onEditClick,
}: {
  refetchableBrand: FeedTabTable_refetchableBrand$key;
  justPublishedPostID: string | null;
  tableSearchAudiences: string[];
  tableSearchLanguages: KinnLanguageCode[];
  setArchiveDialogPostID: (postID: string | null) => void;
  setDeleteDialogPostID: (postID: string | null) => void;
  onEditClick: () => void;
}) => {
  const {
    data: refetchableBrand,
    loadNext,
    isLoadingNext,
    refetch,
  } = usePaginationFragment<
    FeedTabTablePaginationQuery,
    FeedTabTable_refetchableBrand$key
  >(refetchableFragment, refetchableBrandKey);
  const posts = refetchableBrand.feedPosts.edges ?? [];

  const [tablePage, setTablePage] = useState(0);
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
  const handleChangePage = (_event: unknown, newPage: number) => {
    if (newPage > tablePage) {
      loadNext(tableRowsPerPage);
    }
    setTablePage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    refetch({
      count: +event.target.value,
      audienceFilter: tableSearchAudiences,
      languageFilter: tableSearchLanguages,
    });
    setTableRowsPerPage(+event.target.value);
    setTablePage(0);
  };

  useEffect(() => {
    refetch({
      audienceFilter: tableSearchAudiences,
      languageFilter: tableSearchLanguages,
    });
    setTablePage(0);
  }, [refetch, tableSearchAudiences, tableSearchLanguages]);

  return (
    <>
      <TableBody>
        {posts
          .slice(
            tablePage * tableRowsPerPage,
            tablePage * tableRowsPerPage + tableRowsPerPage
          )
          .map((post) => (
            <FeedTabTableRow
              key={post.node.id}
              isNew={justPublishedPostID === post.node.id}
              feedPost={post.node}
              onArchive={() => setArchiveDialogPostID(post.node.id)}
              onDelete={() => setDeleteDialogPostID(post.node.id)}
              onEditClick={onEditClick}
            />
          ))}
        {isLoadingNext &&
          posts.length < (refetchableBrand.feedPosts.totalCount ?? 0) &&
          posts.length <= (tablePage + 1) * tableRowsPerPage && (
            <TableCell
              colSpan={6}
              align="center"
              sx={{
                padding: "128px 0",
              }}
            >
              <CircularProgress color="primary" size={32} />
            </TableCell>
          )}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            colSpan={6}
            align="center"
            rowsPerPageOptions={[10, 25]}
            count={refetchableBrand.feedPosts.totalCount ?? 0}
            rowsPerPage={tableRowsPerPage}
            page={tablePage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </>
  );
};
export default FeedTabTable;
