import {
  Alert,
  Button,
  CssBaseline,
  Grid,
  Link,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import theme from "../../styles/Theme";
import SplitScreenView from "./SplitScreenView";

const schema = yup
  .object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup
      .string()
      .required("E-mail is required")
      .email("E-mail is invalid"),
    password1: yup.string().required("Password is required"),
    password2: yup
      .string()
      .oneOf([yup.ref("password1")], "Passwords must match")
      .required("Password is required"),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const Signup = () => {
  const [firstNameErrors, setFirstNameErrors] = useState("");
  const [lastNameErrors, setLastNameErrors] = useState("");
  const [emailErrors, setEmailErrors] = useState("");
  const [password1Errors, setPassword1Errors] = useState("");
  const [password2Errors, setPassword2Errors] = useState("");
  const [nonFormErrors, setNonFormErrors] = useState("");

  let navigate = useNavigate();
  const search = useLocation().search;

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    fetch("/api/v1/user/auth/verify")
      .then((res) => res.json())
      .then((data) => {
        if (data.is_authenticated) {
          navigate("/");
        }
      });
  }, [navigate]);

  const onSubmit = (data: FormData) => {
    const signupData = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      password1: data.password1,
      password2: data.password2,
      registration_token: new URLSearchParams(search).get("register-token"),
    };

    fetch("/api/v1/user/auth/register/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signupData),
    })
      .then((res) => res.json())
      .then((data) => {
        setFirstNameErrors("");
        setLastNameErrors("");
        setEmailErrors("");
        setPassword1Errors("");
        setPassword2Errors("");
        setNonFormErrors("");

        if ("detail" in data) {
          setNonFormErrors(data.detail);
          setTimeout(function () {
            navigate("/login");
          }, 2000);
        } else {
          if ("first_name" in data) {
            setFirstNameErrors(data.first_name.join(","));
          }

          if ("last_name" in data) {
            setLastNameErrors(data.last_name.join(","));
          }

          if ("email" in data) {
            setEmailErrors(data.email.join(","));
          }

          if ("password1" in data) {
            setPassword1Errors(data.password1.join(","));
          }

          if ("password2" in data) {
            setPassword2Errors(data.password2.join(","));
          }

          if ("non_field_errors" in data) {
            setNonFormErrors(data.non_field_errors.join(","));
          }

          if ("registration_token" in data) {
            setNonFormErrors("Invalid registration token.");
          }
        }
      });
  };

  const openLogin = () => {
    navigate("/login");
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <SplitScreenView
          rightContent={
            <Stack
              sx={{
                width: "100%",
              }}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                sx={{
                  color: "black",
                  fontFamily:
                    '"GT-Flexa","Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: 600,
                  fontSize: "28px",
                  marginBottom: 2,
                }}
              >
                Sign Up
              </Typography>
              {nonFormErrors ? (
                <Alert
                  severity="error"
                  sx={{
                    mb: 2,
                  }}
                >
                  {nonFormErrors}
                </Alert>
              ) : null}
              <Grid
                container
                columns={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={1} paddingRight="4px">
                  <TextField
                    {...register("firstName")}
                    error={!!errors?.firstName || !!firstNameErrors}
                    helperText={errors?.firstName?.message || firstNameErrors}
                    fullWidth
                    margin="dense"
                    autoComplete="given-name"
                    label="First Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={1} paddingLeft="4px">
                  <TextField
                    {...register("lastName")}
                    error={!!errors?.lastName || !!lastNameErrors}
                    helperText={errors?.lastName?.message || lastNameErrors}
                    fullWidth
                    margin="dense"
                    label="Last Name"
                    autoComplete="family-name"
                  />
                </Grid>
                <TextField
                  {...register("email")}
                  error={!!errors?.email || !!emailErrors}
                  helperText={errors?.email?.message || emailErrors}
                  margin="dense"
                  fullWidth
                  label="Email Address"
                  autoComplete="email"
                />
                <TextField
                  {...register("password1")}
                  error={!!errors?.password1 || !!password1Errors}
                  helperText={errors?.password1?.message || password1Errors}
                  margin="dense"
                  fullWidth
                  label="Password"
                  type="password"
                  autoComplete="new-password-1"
                />
                <TextField
                  {...register("password2")}
                  error={!!errors?.password2 || !!password2Errors}
                  helperText={errors?.password2?.message || password2Errors}
                  margin="dense"
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  autoComplete="new-password-2"
                />
                <Typography
                  sx={{
                    mt: 2,
                    color: "#5A62B8",
                    textDecorationColor: "#5A62B8",
                  }}
                  variant="body2"
                  align="center"
                >
                  By signing up, you agree to the{" "}
                  <Link
                    sx={{
                      cursor: "pointer",
                      color: "inherit",
                      textDecorationColor: "inherit",
                    }}
                    onClick={() => {}} // TODO: Open T&C
                    variant="body2"
                  >
                    Terms & Conditions
                  </Link>{" "}
                  and{" "}
                  <Link
                    sx={{
                      cursor: "pointer",
                      color: "#5A62B8",
                      textDecorationColor: "#5A62B8",
                    }}
                    onClick={() => {}} // TODO: Open PP
                    variant="body2"
                  >
                    Privacy Policy
                  </Link>
                </Typography>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 2,
                    mb: 2,
                  }}
                  color="secondary"
                >
                  Sign Up
                </Button>
                <Link
                  sx={{
                    color: "#5A62B8",
                    textDecorationColor: "#5A62B8",
                  }}
                  onClick={openLogin}
                  variant="body2"
                  component="button"
                >
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Stack>
          }
        />
      </form>
    </ThemeProvider>
  );
};

export default Signup;
