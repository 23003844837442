import { Stack, Typography } from "@mui/material";
import CardSection from "./CardSection";
import { UTMTextField } from "./UTMTextField";

export const UTMCard = ({
  campaign,
  onChange,
}: {
  campaign: string | null;
  onChange: (campaign: string | null) => void;
}) => {
  return (
    <CardSection
      showIsOptional
      title={"UTM"}
      content={
        <Stack spacing={2} width={"100%"}>
          <Typography variant="body1">
            Set a UTM campaign for this notification
          </Typography>
          <UTMTextField
            value={campaign ?? undefined}
            label="Campaign"
            onChange={onChange}
          />
        </Stack>
      }
    />
  );
};
