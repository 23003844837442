import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import {
  FocusEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useFragment, useRelayEnvironment } from "react-relay";
import { useParams } from "react-router-dom";
import { UploadableMap, fetchQuery } from "relay-runtime";
import * as yup from "yup";
import { MediaType } from "../../../../mutations/__generated__/useFeedPostCreateMutation.graphql";
import { GraphQLCDPAudienceInput } from "../__generated__/HighlightsTabCreateAlbumMutation.graphql";
import { FeedSinglePostFormQuery } from "./__generated__/FeedSinglePostFormQuery.graphql";
import { FeedSinglePostForm_brand$key } from "./__generated__/FeedSinglePostForm_brand.graphql";
import { FeedSinglePostForm_feedPost$key } from "./__generated__/FeedSinglePostForm_feedPost.graphql";
import {
  AttachedNotification,
  isValidAttachedNotification,
} from "../../../../components/AttachedNotification";
import { CDPAudienceSelectorCard } from "../../../../components/CDPAudienceSelectorCard";
import CardSection from "../../../../components/CardSection";
import LeftRight from "../../../../components/LeftRight";
import UploadedFileCard from "../../../../components/UploadedFileCard";
import {
  PreviewType,
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../../contexts/MobilePreviewContext";
import SnackbarContext from "../../../../contexts/SnackbarContext";
import useFeedPostCreateMutation from "../../../../mutations/useFeedPostCreateMutation";
import useFeedPostUpdateMutation from "../../../../mutations/useFeedPostUpdateMutation";
import { KinnLanguageCode } from "../../../../utils/languageMap";
import { validateUrl } from "../../../../utils/validators";

dayjs.extend(advancedFormat);
dayjs.extend(timezone);

const schema = yup
  .object({
    caption: yup.string().trim(),
    destinationLink: yup
      .string()
      .required("Destination link is required")
      .test("is-url-valid", "URL or deeplink is not valid", (value) => {
        if (!value) return false;

        return validateUrl(value);
      }),
    postTitle: yup.string().required("Post title is required").trim(),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const imageOGQuery = graphql`
  query FeedSinglePostFormQuery($url: String!) {
    opengraphImageFromUrl(url: $url)
  }
`;

const brandFragment = graphql`
  fragment FeedSinglePostForm_brand on BrandType {
    id
    feed {
      id
    }
    ...useFeedPostCreateMutation_brand
    ...useFeedPostUpdateMutation_brand
    ...CDPAudienceSelectorCard_brand
  }
`;

const feedPostFragment = graphql`
  fragment FeedSinglePostForm_feedPost on GraphQLFeedPost {
    id
    audiences {
      audienceId
    }
    audienceLanguages
    publishedAt
    link {
      externalMediaUrl
      subtitle
      title
      url
      media {
        url
        coverImageUrl
        type
      }
    }
    notification {
      title
      body
      utmCampaign
    }
  }
`;

const FeedSinglePostForm = ({
  brand: brandKey,
  feedPost: feedPostKey,
  onBackClick,
  onUpdateComplete,
}: {
  brand: FeedSinglePostForm_brand$key;
  feedPost: FeedSinglePostForm_feedPost$key | null;
  onBackClick: () => void;
  onUpdateComplete: (postID?: string) => void;
}) => {
  const snackbarContext = useContext(SnackbarContext);
  const dispatch = useMobilePreviewDispatch();

  const postID = useParams().id;
  const isEditing = postID != null;

  // Data Loading
  const brand = useFragment(brandFragment, brandKey);
  const feedPost = useFragment(feedPostFragment, feedPostKey);
  const brandID = brand.id;
  const feedID = brand?.feed?.id ?? null;
  const desintationLinkDefault = feedPost?.link?.url ?? "";
  const postTitleDefault = feedPost?.link?.title ?? "";
  const captionDefault = feedPost?.link?.subtitle ?? "";
  const media = feedPost?.link?.media?.[0];

  const [isImporting, setIsImporting] = useState(false);

  const [externalMediaUrl, setExternalMediaUrl] = useState<string | null>(
    feedPost?.link?.externalMediaUrl ?? null
  );
  const [mediaType, setMediaType] = useState<MediaType | null>(
    media?.type ?? null
  );
  const [mediaUrl, setMediaUrl] = useState<string | null>(
    media?.url ?? feedPost?.link?.externalMediaUrl ?? null
  );
  const [coverImageUrl, setCoverImageUrl] = useState<string | null>(
    media?.coverImageUrl ?? null
  );
  const [mediaUploadable, setMediaUploadable] = useState<UploadableMap>({});

  const [muxVideoID, setMuxVideoID] = useState<string | null>(null);
  const isMediaSet =
    externalMediaUrl !== null || mediaUrl !== null || muxVideoID !== null;

  useEffect(() => {
    if (isEditing) {
      dispatch({
        type: ReducerAction.RESET,
      });
      dispatch({
        type: ReducerAction.SET_PREVIEW_TYPE,
        payload: {
          previewType: PreviewType.SINGLE_POST,
        },
      });
      dispatch({
        type: ReducerAction.UPDATE_SINGLE_POST,
        payload: {
          singlePost: {
            title: postTitleDefault,
            subtitle: captionDefault,
            mediaType: "PHOTO",
            media: coverImageUrl ?? externalMediaUrl ?? "",
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [audienceIDs, setAudienceIDs] = useState<string[]>(
    (feedPost?.audiences ?? []).map((audience) => audience.audienceId)
  );
  const [audienceDatas, setAudienceDatas] = useState<
    GraphQLCDPAudienceInput[] | null
  >(null);
  const onAudiencesChange = useCallback(
    (audiences: GraphQLCDPAudienceInput[]) => {
      setAudienceIDs(audiences.map((audience) => audience.audienceId));
      setAudienceDatas(
        audiences.map((audience) => ({
          audienceId: audience.audienceId,
          audienceName: audience.audienceName,
          audienceType: audience.audienceType,
          cdpType: audience.cdpType,
        }))
      );
    },
    [setAudienceIDs, setAudienceDatas]
  );
  const [audienceLanguages, setAudienceLanguages] = useState<
    KinnLanguageCode[]
  >(feedPost?.audienceLanguages?.slice() ?? []);
  const onLanguageFiltersChange = useCallback(
    (languages: KinnLanguageCode[]) => {
      setAudienceLanguages(languages);
    },
    [setAudienceLanguages]
  );

  // Publish Preferences
  const publishTime = feedPost?.publishedAt ?? null;
  const isPostPublished =
    publishTime !== null && dayjs(publishTime).isBefore(dayjs());
  const [publishLater, setPublishLater] = useState(
    isEditing && !isPostPublished
  );
  const onTogglePublishLater = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPublishLater(event.target.checked);
  };

  const initialTime = publishTime ? dayjs(publishTime) : dayjs().add(1, "day");
  const [dateTime, setDateTime] = useState<Dayjs | null>(initialTime);
  const minDateTime = dayjs().add(1, "hour");
  const maxDateTime = dayjs().add(3, "months");

  const [notification, setNotification] = useState(
    feedPost?.notification ?? null
  );
  const notifEnabled = notification !== null;

  const [createFeedPost, isCreateFeedPostInFlight] =
    useFeedPostCreateMutation(brand);
  const [updateFeedPost, isUpdateFeedPostInFlight] =
    useFeedPostUpdateMutation(brand);
  const isMutationInFlight =
    isCreateFeedPostInFlight || isUpdateFeedPostInFlight;
  const environment = useRelayEnvironment();

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      caption: captionDefault,
      destinationLink: desintationLinkDefault,
      postTitle: postTitleDefault,
    },
    resolver: yupResolver(schema),
  });
  const watchDestinationLink = watch("destinationLink");
  const watchPostTitle = watch("postTitle");
  const watchCaption = watch("caption") ?? "";

  const onSubmit = (data: FormData) => {
    const input = {
      audienceLanguages,
      isNotifEnabled: notifEnabled,
      notifTitle: notification?.title,
      notifSubtitle: notification?.body,
      notifUtmCampaign: notification?.utmCampaign,
    };
    if (isEditing) {
      updateFeedPost(
        {
          ...input,
          id: postID,
          publishedAt: isPostPublished
            ? undefined
            : publishLater
            ? dateTime
            : dayjs(),
          audiences:
            audienceDatas === null
              ? undefined
              : audienceDatas.map((audience) => ({
                  audienceId: audience.audienceId,
                  audienceName: audience.audienceName,
                  audienceType: audience.audienceType,
                  cdpType: audience.cdpType,
                })),
          link: {
            brand: {
              id: brandID,
            },
            mediaType: mediaType,
            subtitle: data.caption,
            title: data.postTitle,
            url: data.destinationLink,
            externalMediaUrl: externalMediaUrl,
            muxVideoId: muxVideoID,
            // set null to remove, undefined to default to uploadables below
            mediaUploadable:
              mediaUrl === null ||
              externalMediaUrl !== null ||
              muxVideoID !== null
                ? null
                : undefined,
          },
        },
        (data) => {
          setMediaUploadable({});
          onUpdateComplete(data.updateFeedPost.id);
          onBackClick();
        },
        mediaUploadable
      );
    } else {
      createFeedPost(
        {
          ...input,
          publishedAt: publishLater ? dateTime : dayjs(),
          audiences:
            audienceDatas === null
              ? []
              : audienceDatas.map((audience) => ({
                  audienceId: audience.audienceId,
                  audienceName: audience.audienceName,
                  audienceType: audience.audienceType,
                  cdpType: audience.cdpType,
                })),
          feed: {
            id: feedID,
          },
          link: {
            brand: {
              id: brandID,
            },
            mediaType: mediaType,
            subtitle: data.caption,
            title: data.postTitle,
            url: data.destinationLink,
            externalMediaUrl: externalMediaUrl,
            muxVideoId: muxVideoID ?? undefined,
          },
        },
        (data) => {
          setMediaUploadable({});
          onUpdateComplete(data.createFeedPost.id);
          onBackClick();
        },
        mediaUploadable
      );
    }
  };

  const customizeCard = (
    <CardSection
      title={"Customize post"}
      content={
        <Stack spacing={2} width="100%">
          <Typography variant="overline">1. Add a destination</Typography>
          <TextField
            {...register("destinationLink")}
            error={!!errors?.destinationLink}
            helperText={errors?.destinationLink?.message}
            margin="normal"
            label={"Destination Link"}
            variant="outlined"
          />

          <Typography variant="overline">2. Customize text</Typography>
          <TextField
            {...register("postTitle", {
              maxLength: 80,
              onChange: (event: FocusEvent<HTMLInputElement>) => {
                dispatch({
                  type: ReducerAction.UPDATE_SINGLE_POST,
                  payload: {
                    singlePost: {
                      title: event.target.value,
                    },
                  },
                });
              },
            })}
            error={!!errors?.postTitle}
            helperText={errors?.postTitle?.message}
            sx={{
              flexGrow: 1,
            }}
            margin="normal"
            label="Post Title"
            variant="outlined"
            inputProps={{
              maxLength: 80,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {watchPostTitle.length}/80
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="subtitle2">
            Write a caption below (Optional)
          </Typography>
          <TextField
            {...register("caption", {
              maxLength: 200,
              onChange: (event: FocusEvent<HTMLInputElement>) => {
                dispatch({
                  type: ReducerAction.UPDATE_SINGLE_POST,
                  payload: {
                    singlePost: {
                      subtitle: event.target.value,
                    },
                  },
                });
              },
            })}
            error={!!errors?.caption}
            helperText={errors?.caption?.message}
            sx={{
              flexGrow: 1,
            }}
            margin="normal"
            multiline
            label={"Write something"}
            variant="outlined"
            inputProps={{
              maxLength: 200,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {watchCaption.length}/200
                </InputAdornment>
              ),
            }}
          />

          <Typography variant="overline">3. Upload media (Optional)</Typography>
          <Stack spacing={2}>
            <LeftRight
              expandLeft={true}
              left={
                <Typography variant="body2">
                  You can either import the image from your destination link
                  above or upload your own photo
                </Typography>
              }
              right={
                <Button
                  disabled={isImporting || isMediaSet}
                  variant="outlined"
                  onClick={() => {
                    fetchQuery<FeedSinglePostFormQuery>(
                      environment,
                      imageOGQuery,
                      {
                        url: watchDestinationLink,
                      }
                    ).subscribe({
                      start: () => {
                        setIsImporting(true);
                      },
                      complete: () => {
                        setIsImporting(false);
                      },
                      error: (_: Error) => {
                        setIsImporting(false);
                        snackbarContext?.openSnackbar(
                          "Failed to get image",
                          "error"
                        );
                      },
                      next: (data) => {
                        setMediaType(null);
                        delete mediaUploadable["link.mediaUploadable"];

                        if (data.opengraphImageFromUrl === null) {
                          snackbarContext?.openSnackbar(
                            "Failed to get image",
                            "error"
                          );
                        } else {
                          dispatch({
                            type: ReducerAction.UPDATE_SINGLE_POST,
                            payload: {
                              singlePost: {
                                media: data.opengraphImageFromUrl,
                                mediaType: "PHOTO",
                              },
                            },
                          });
                          setExternalMediaUrl(data.opengraphImageFromUrl);
                          setMediaUrl(data.opengraphImageFromUrl);
                          setMediaType(null);
                          setMuxVideoID(null);
                          setCoverImageUrl(null);
                        }
                      },
                    });
                  }}
                >
                  {isImporting ? "Importing" : "Import"}
                </Button>
              }
            />
            <UploadedFileCard
              brandID={brandID}
              onUploadVideo={(videoID: string) => {
                setMuxVideoID(videoID);
              }}
              disabled={isImporting}
              fallbackURL={coverImageUrl ?? undefined}
              fileURL={mediaUrl || externalMediaUrl}
              fileType=".svg, .png, .jpg, .mp4, .mov"
              htmlLabel="feed-post-form"
              mediaType={mediaType ?? "PHOTO"}
              inputLabel="Media Asset"
              inputText={"Upload a photo or video"}
              onClose={() => {
                setMediaUrl(null);
                setMediaType(null);
                setExternalMediaUrl(null);
                setMuxVideoID(null);
                setCoverImageUrl(null);
                delete mediaUploadable["link.mediaUploadable"];

                // TODO: move dispatch to inside UploadedFileCard
                dispatch({
                  type: ReducerAction.UPDATE_SINGLE_POST,
                  payload: {
                    singlePost: {
                      media: "",
                      mediaType: "PHOTO",
                    },
                  },
                });
              }}
              onUpload={(
                event: React.ChangeEvent<HTMLInputElement>,
                mediaType?: MediaType // TODO: make this arg required
              ) => {
                const file = event?.target?.files?.[0];
                if (file == null) {
                  return;
                }

                const url = URL.createObjectURL(file);
                const mt = file.type.includes("video") ? "VIDEO" : "PHOTO";
                setMediaUrl(url);
                setCoverImageUrl(null);
                setMediaType(mt);

                if (mediaType !== "WEB_MUX_VIDEO") {
                  const newUploadables: UploadableMap = {};
                  newUploadables["link.mediaUploadable"] = file;
                  setMediaUploadable(newUploadables);
                }

                dispatch({
                  type: ReducerAction.UPDATE_SINGLE_POST,
                  payload: {
                    singlePost: {
                      media: url,
                      mediaType: mt,
                    },
                  },
                });
              }}
            />
          </Stack>
        </Stack>
      }
    />
  );

  const audienceCard = (
    <CDPAudienceSelectorCard
      audienceIDs={audienceIDs}
      brand={brand}
      subtitle={"Choose an audience to share your post with:"}
      audienceLanguages={audienceLanguages}
      onAudiencesChange={onAudiencesChange}
      onLanguageFiltersChange={onLanguageFiltersChange}
    />
  );

  const notifAndPublishCard = (
    <CardSection
      showIsOptional
      title={"Set publish preferences"}
      content={
        <Stack spacing={2} width={"100%"}>
          <LeftRight
            expandLeft={true}
            left={
              <Stack spacing={1}>
                <Typography variant="subtitle1">Publish later</Typography>
                <Typography variant="body2">
                  Schedule the highlight to publish anytime between an hour from
                  now to 3 months out
                </Typography>
              </Stack>
            }
            right={
              <Switch checked={publishLater} onChange={onTogglePublishLater} />
            }
          />
          {publishLater && (
            <Stack
              direction={"row"}
              component="form"
              sx={{
                alignItems: "center",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{
                    mr: 1,
                  }}
                  minDateTime={minDateTime}
                  maxDateTime={maxDateTime}
                  value={dateTime}
                  onChange={(newTime) => {
                    setDateTime(newTime);
                  }}
                />
              </LocalizationProvider>
              {dateTime?.format("z")}
            </Stack>
          )}

          <AttachedNotification
            notification={notification}
            onNotificationChange={setNotification}
          />
        </Stack>
      }
    />
  );

  let saveButtonText = "Publish";
  if (isEditing) {
    saveButtonText = isUpdateFeedPostInFlight ? "Saving" : "Save";
  } else {
    saveButtonText = isCreateFeedPostInFlight ? "Publishing" : "Publish";
  }
  const isPublishButtonDisabled =
    (publishLater && (!dateTime?.isValid() || minDateTime.isAfter(dateTime))) ||
    !isValidAttachedNotification(notification) ||
    (muxVideoID !== null && mediaUrl === null);
  const publishButton = (
    <Box display="flex" justifyContent="flex-end">
      <Button
        type="submit"
        disabled={isMutationInFlight || isPublishButtonDisabled}
        variant="contained"
        startIcon={
          isMutationInFlight ? (
            <CircularProgress color="inherit" size={16} />
          ) : undefined
        }
      >
        {saveButtonText}
      </Button>
    </Box>
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      {customizeCard}
      {audienceCard}
      {isPostPublished ? null : notifAndPublishCard}
      {publishButton}
    </form>
  );
};

export default FeedSinglePostForm;
