import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Link, Stack, Switch, Typography, useTheme } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { GorgiasCard_brand$key } from "./__generated__/GorgiasCard_brand.graphql";
import CardSection from "../../../../components/CardSection";
import LeftRight from "../../../../components/LeftRight";
import SaveButton, { SavedState } from "../../../../components/SaveButton";
import {
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../../contexts/MobilePreviewContext";
import useBrandAppConfigUpdateMutation from "../../../../mutations/useBrandAppConfigUpdateMutation";
import { SETTINGS_INTEGRATIONS } from "../../../../utils/routes";

const brandFragment = graphql`
  fragment GorgiasCard_brand on BrandType {
    appConfig {
      id
      buildNavigation {
        id
        isGorgiasEnabled
      }
    }
    gorgiasId
  }
`;

type Props = {
  brand: GorgiasCard_brand$key;
};

const GorgiasCard = ({ brand: brandKey }: Props) => {
  const dispatch = useMobilePreviewDispatch();

  const theme = useTheme();
  const brand = useFragment<GorgiasCard_brand$key>(brandFragment, brandKey);
  const appConfig = brand.appConfig;
  const buildNavigation = brand.appConfig.buildNavigation;
  const isGorgiasIdSet = brand.gorgiasId !== null && brand.gorgiasId !== "";

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    !isGorgiasIdSet ? SavedState.DISABLED : SavedState.SAVED
  );

  const [isToggleEnabled, setIsToggleEnabled] = useState<boolean>(
    buildNavigation.isGorgiasEnabled && isGorgiasIdSet
  );
  const onToggleChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setIsToggleEnabled(checked);
    saveButtonState !== SavedState.ENABLED &&
      setSaveButtonState(SavedState.ENABLED);
    dispatch({
      type: ReducerAction.UPDATE_NAVIGATION,
      payload: {
        navigation: {
          isGorgiasEnabled: checked,
        },
      },
    });
  };

  const navigate = useNavigate();
  const [saveMutation, isMutationInFlight] = useBrandAppConfigUpdateMutation(
    {
      id: appConfig.id,
      buildNavigation: {
        id: buildNavigation.id,
        isGorgiasEnabled: isToggleEnabled,
      },
    },
    () => {
      setSaveButtonState(SavedState.SAVED);
    }
  );

  return (
    <CardSection
      actions={
        <SaveButton
          savedState={
            isMutationInFlight ? SavedState.DISABLED : saveButtonState
          }
          onClick={saveMutation}
        />
      }
      title={"Customer Service Chat"}
      subtitle="Enable Customer Service Chat"
      showIsOptional
      content={
        <Stack
          spacing={2}
          sx={{
            maxWidth: "650px",
          }}
        >
          <LeftRight
            expandLeft={true}
            left={
              <Stack spacing={1}>
                <Typography variant="subtitle1">
                  Enable Customer Service Chat
                </Typography>
                <Typography variant="body2">
                  Enable customer service chat so that your customers can
                  receive on-demand help while shopping on your app. The chat
                  button will appear persistently throughout the app.
                </Typography>
              </Stack>
            }
            right={
              <Switch
                disabled={!isGorgiasIdSet}
                checked={isToggleEnabled}
                onChange={onToggleChange}
              />
            }
          />
          <Box
            sx={{
              background: theme.palette.primary.main + "1D",
              borderRadius: "8px",
              p: 3,
            }}
          >
            <LeftRight
              left={<InfoOutlinedIcon color="primary" fontSize="small" />}
              right={
                <Typography color={"primary"} variant="subtitle2">
                  Note: Customer Service Chat will only be enabled when you
                  connect your Gorgias Account in the{" "}
                  <Link
                    underline="always"
                    onClick={() => navigate(SETTINGS_INTEGRATIONS)}
                  >
                    Integrations Tab
                  </Link>
                  .
                </Typography>
              }
            />
          </Box>
        </Stack>
      }
    />
  );
};

export default GorgiasCard;
