import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useState } from "react";

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  expandIcon?: React.ReactNode;
  isExpandedByDefault?: boolean;
  title: React.ReactNode;
  color?: "white" | "black" | "inherit";
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
}));

const ExpandableSection = ({
  children,
  disabled = false,
  expandIcon,
  isExpandedByDefault = false,
  title,
  color,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(!!isExpandedByDefault);

  return (
    <Accordion
      sx={{
        "&.MuiAccordion-root:before": {
          backgroundColor: color,
        },
        backgroundColor: color,
      }}
      disabled={disabled}
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <MuiAccordionSummary
        expandIcon={expandIcon ?? <ExpandMoreIcon sx={{ color: "#9CA3AF" }} />}
      >
        {title}
      </MuiAccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0",
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpandableSection;
