import {
  Box,
  Card,
  CardActionArea,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { SketchPicker } from "react-color";

type Props = {
  color: string | null;
  text: string;
  onColorChange: (color: string) => void;
};

const ColorPicker = (props: Props) => {
  const theme = useTheme();
  const { color: colorProp, onColorChange, text } = props;
  const color = colorProp || "#FFFFFF";
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Card
      sx={{
        display: "flex",
        height: "92px",
        flexGrow: 1,
        // width: "140px",
        boxShadow: open
          ? "inset 0px 0px 0px 2px " + theme.palette.primary.main
          : "inset 0px 0px 0px 1px #E6E8F0",
        borderRadius: "8px",
      }}
      variant="outlined"
    >
      <CardActionArea
        sx={{
          height: "100%",
          width: "100%",
        }}
        onClick={handleClick}
      >
        <Stack
          direction="row"
          p={"12px"}
          height={"100%"}
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
            <Typography variant="subtitle1">{text}</Typography>
            <Typography variant="body2">{color.toUpperCase()}</Typography>
          </Box>
          <Box
            sx={{
              bgcolor: color,
              border: color === "#FFFFFF" ? "0.5px dashed #000000" : undefined,
              borderRadius: "4px",
              mb: 1,
              width: "50%",
              height: "100%",
            }}
          />
        </Stack>
      </CardActionArea>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <SketchPicker
          color={color ?? "#FFFFFF"}
          disableAlpha={true}
          onChange={(newColor) => {
            onColorChange(newColor.hex);
          }}
        />
      </Popover>
    </Card>
  );
};

export default ColorPicker;
