import {
  Box,
  Container,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useFragment, useLazyLoadQuery } from "react-relay";
import * as yup from "yup";
import { AutomationTabQuery } from "./__generated__/AutomationTabQuery.graphql";
import { AutomationTab_brand$key } from "./__generated__/AutomationTab_brand.graphql";
import CardSection from "../../../components/CardSection";
import SaveButton, { SavedState } from "../../../components/SaveButton";
import useBrandAppConfigUpdate2Mutation from "../../../mutations/useBrandAppConfigUpdate2Mutation";
import { validateUrl } from "../../../utils/validators";

const notifSchema = yup
  .object({
    title: yup
      .string()
      .max(150)
      .when("isEnabled", {
        is: (isEnabled: boolean) => isEnabled === true,
        then: (schema) => schema.required("Required if enabled"),
        otherwise: (schema) => schema,
      }),
    message: yup
      .string()
      .max(250)
      .when("isEnabled", {
        is: (isEnabled: boolean) => isEnabled === true,
        then: (schema) => schema.required("Required if enabled"),
        otherwise: (schema) => schema,
      }),
    delayMinutes: yup
      .number()
      .typeError("Must be a number")
      .when("isEnabled", {
        is: (isEnabled: boolean) => isEnabled === true,
        then: (schema) =>
          schema
            .min(1, "Must be greater than or equal to 1")
            .max(262980, "Must be less than 6 months")
            .required("Required"),
        otherwise: (schema) => schema,
      }),
    landingPath: yup
      .string()
      .max(2083)
      .optional()
      .when({
        is: (exists: boolean) => exists !== undefined,
        then: (schema) =>
          schema.when("isEnabled", {
            is: (isEnabled: boolean) => {
              return isEnabled === true;
            },
            then: (schema) =>
              schema
                .required("Required if enabled")
                .test("is-url-valid", "URL is not valid", (value) => {
                  return validateUrl(value);
                }),
            otherwise: (schema) => schema,
          }),
      }),
    isEnabled: yup.boolean().required(),
  })
  .required();

const schema = yup
  .object({
    abandonedCart: yup.array().of(notifSchema),
    abandonedBrowse: yup.array().of(notifSchema),
    abandonedCheckout: yup.array().of(notifSchema),
    welcomeSeries: yup.array().of(notifSchema),
    reengagement: yup.array().of(notifSchema),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const query = graphql`
  query AutomationTabQuery($id: ID!) {
    brand(id: $id) {
      ...AutomationTab_brand
    }
  }
`;

const brandFragment = graphql`
  fragment AutomationTab_brand on BrandType {
    appConfig {
      id
      postPurchaseOptInEnabled
      automatedNotifications {
        edges {
          node {
            id
            notifType
            title
            message
            isEnabled
            delaySeconds
            landingPath
          }
        }
      }
    }
  }
`;

const AutomatedUpsellSection = ({ brandID }: { brandID: string }) => {
  const data = useLazyLoadQuery<AutomationTabQuery>(query, {
    id: brandID,
  });
  const brand = useFragment<AutomationTab_brand$key>(brandFragment, data.brand);
  const appConfig = brand.appConfig;

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.DISABLED
  );
  const [isDirty, setIsDirty] = useState(false);
  const [saveMutation, isMutationInFlight] = useBrandAppConfigUpdate2Mutation();
  const [isPostPurchaseUpsellEnabled, setIsPostPurchaseUpsellEnabled] =
    useState(appConfig.postPurchaseOptInEnabled);
  const onSubmit = () => {
    saveMutation(
      {
        id: brand.appConfig.id,
        postPurchaseOptInEnabled: isPostPurchaseUpsellEnabled,
      },
      () => {
        setIsDirty(false);
        setSaveButtonState(SavedState.SAVED);
      }
    );
  };
  return (
    <CardSection
      actions={
        <SaveButton
          savedState={
            isDirty
              ? SavedState.ENABLED
              : isMutationInFlight
              ? SavedState.DISABLED
              : saveButtonState
          }
          onClick={() => onSubmit()}
        />
      }
      title={"Upsell"}
      content={
        <Stack spacing={2} width="100%">
          <Box>
            <Typography variant="body2">
              Use the left toggle and save to enable or disable post-purchase
              notification opt-in upsell
            </Typography>
          </Box>
          <Box
            sx={{
              border: "1px solid #E0E0E0",
              borderRadius: "8px",
            }}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <Switch
                checked={isPostPurchaseUpsellEnabled}
                onChange={(_, data) => {
                  setIsPostPurchaseUpsellEnabled(data);
                  setIsDirty(true);
                }}
              />
              <Box
                sx={{
                  px: 2,
                  py: 1,
                }}
              >
                <Typography sx={{ paddingRight: "32px" }} variant="overline">
                  {"Post-purchase notification upsell"}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
      }
    />
  );
};

const AutomatedNotificationCard = ({
  title,
  formName,
  register,
  errors,
  control,
  watch,
  showLandingPath = false,
}: {
  title: string;
  formName: string;
  register: any;
  errors: any;
  control: any;
  watch: any;
  showLandingPath?: boolean;
}) => {
  return (
    <Box
      sx={{
        border: "1px solid #E0E0E0",
        borderRadius: "8px",
      }}
    >
      <Stack direction={"row"} alignItems={"center"}>
        <Controller
          render={(props) => (
            <Switch
              checked={props.field.value}
              onChange={(_, data) => props.field.onChange(data)}
            />
          )}
          name={formName + ".isEnabled"}
          control={control}
        />
        <Box
          sx={{
            px: 2,
            py: 1,
            opacity: !watch(formName + ".isEnabled") ? "70%" : undefined,
            pointerEvents: !watch(formName + ".isEnabled") ? "none" : undefined,
          }}
        >
          <Typography sx={{ paddingRight: "32px" }} variant="overline">
            {title}
          </Typography>
          <TextField
            {...register(formName + ".title")}
            error={!!errors?.title?.message}
            helperText={errors?.title?.message}
            fullWidth
            autoComplete="off"
            sx={{
              flexGrow: 1,
            }}
            margin="dense"
            id="outlined-basic"
            label={"Notification Title"}
            variant="outlined"
          />
          <TextField
            {...register(formName + ".message")}
            error={!!errors?.message?.message}
            helperText={errors?.message?.message}
            fullWidth
            autoComplete="off"
            sx={{
              flexGrow: 1,
            }}
            margin="dense"
            id="outlined-basic"
            label={"Notification Message"}
            variant="outlined"
          />
          <TextField
            {...register(formName + ".delayMinutes")}
            error={!!errors?.delayMinutes?.message}
            helperText={errors?.delayMinutes?.message}
            fullWidth
            autoComplete="off"
            sx={{
              flexGrow: 1,
            }}
            margin="dense"
            id="outlined-basic"
            label={"Notification Delay Minutes"}
            variant="outlined"
          />
          {showLandingPath && (
            <TextField
              {...register(formName + ".landingPath")}
              error={!!errors?.landingPath?.message}
              helperText={errors?.landingPath?.message}
              fullWidth
              autoComplete="off"
              sx={{
                flexGrow: 1,
              }}
              margin="dense"
              id="outlined-basic"
              label={"Notification Landing Path"}
              variant="outlined"
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
};

const AutomationTab = ({ brandID }: { brandID: string }) => {
  const data = useLazyLoadQuery<AutomationTabQuery>(query, {
    id: brandID,
  });
  const brand = useFragment<AutomationTab_brand$key>(brandFragment, data.brand);
  const appConfig = brand.appConfig;
  const automatedNotifications = appConfig.automatedNotifications.edges;
  const abandonedCart = automatedNotifications.filter(
    (notif) => notif.node.notifType === "ABANDONED_CART"
  );
  const abandonedBrowse = automatedNotifications.filter(
    (notif) => notif.node.notifType === "ABANDONED_BROWSE"
  );
  const abandonedCheckout = automatedNotifications.filter(
    (notif) => notif.node.notifType === "ABANDONED_CHECKOUT"
  );
  const welcomeSeries = automatedNotifications.filter(
    (notif) => notif.node.notifType === "WELCOME_SERIES"
  );
  const reengagement = automatedNotifications.filter(
    (notif) => notif.node.notifType === "REENGAGEMENT"
  );

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.DISABLED
  );

  const [saveMutation, isMutationInFlight] = useBrandAppConfigUpdate2Mutation();

  const {
    handleSubmit,
    formState: { errors, isDirty },
    register,
    reset,
    control,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      abandonedCart: abandonedCart.map((notif) => ({
        title: notif.node.title ?? "",
        message: notif.node.message ?? "",
        delayMinutes: notif.node.delaySeconds / 60,
        landingPath: undefined,
        isEnabled: notif.node.isEnabled ?? false,
      })),
      abandonedBrowse: abandonedBrowse.map((notif) => ({
        title: notif.node.title ?? "",
        message: notif.node.message ?? "",
        delayMinutes: notif.node.delaySeconds / 60,
        landingPath: undefined,
        isEnabled: notif.node.isEnabled ?? false,
      })),
      abandonedCheckout: abandonedCheckout.map((notif) => ({
        title: notif.node.title ?? "",
        message: notif.node.message ?? "",
        delayMinutes: notif.node.delaySeconds / 60,
        landingPath: undefined,
        isEnabled: notif.node.isEnabled ?? false,
      })),
      welcomeSeries: welcomeSeries.map((notif) => ({
        title: notif.node.title,
        message: notif.node.message,
        delayMinutes: notif.node.delaySeconds / 60,
        landingPath: notif.node.landingPath ?? "",
        isEnabled: notif.node.isEnabled,
      })),
      reengagement: reengagement.map((notif) => ({
        title: notif.node.title,
        message: notif.node.message,
        delayMinutes: notif.node.delaySeconds / 60,
        landingPath: notif.node.landingPath ?? "",
        isEnabled: notif.node.isEnabled,
      })),
    },
    resolver: yupResolver(schema),
  });

  const { fields: cartFields } = useFieldArray({
    control,
    name: "abandonedCart",
  });
  const { fields: browseFields } = useFieldArray({
    control,
    name: "abandonedBrowse",
  });
  const { fields: checkoutFields } = useFieldArray({
    control,
    name: "abandonedCheckout",
  });
  const { fields: welcomeFields } = useFieldArray({
    control,
    name: "welcomeSeries",
  });
  const { fields: reengagementFields } = useFieldArray({
    control,
    name: "reengagement",
  });

  const onSubmit = (data: FormData) => {
    const automatedNotifs = [];
    for (let i = 0; i < abandonedCart.length; i++) {
      if (data.abandonedCart?.[i] == null) {
        continue;
      }
      automatedNotifs.push({
        id: abandonedCart[i].node.id,
        title: data.abandonedCart[i]?.title ?? "",
        message: data.abandonedCart[i]?.message ?? "",
        delaySeconds: (data.abandonedCart[i]?.delayMinutes ?? 0) * 60,
        isEnabled: data.abandonedCart[i].isEnabled ?? false,
      });
    }
    for (let i = 0; i < abandonedBrowse.length; i++) {
      if (data.abandonedBrowse?.[i] == null) {
        continue;
      }
      automatedNotifs.push({
        id: abandonedBrowse[i].node.id,
        title: data.abandonedBrowse[i]?.title ?? "",
        message: data.abandonedBrowse[i]?.message ?? "",
        delaySeconds: (data.abandonedBrowse[i]?.delayMinutes ?? 0) * 60,
        isEnabled: data.abandonedBrowse[i].isEnabled ?? false,
      });
    }
    for (let i = 0; i < abandonedCheckout.length; i++) {
      if (data.abandonedCheckout?.[i] == null) {
        continue;
      }
      automatedNotifs.push({
        id: abandonedCheckout[i].node.id,
        title: data.abandonedCheckout[i]?.title ?? "",
        message: data.abandonedCheckout[i]?.message ?? "",
        delaySeconds: (data.abandonedCheckout[i]?.delayMinutes ?? 0) * 60,
        isEnabled: data.abandonedCheckout[i].isEnabled ?? false,
      });
    }
    for (let i = 0; i < welcomeSeries.length; i++) {
      if (data.welcomeSeries?.[i] == null) {
        continue;
      }
      automatedNotifs.push({
        id: welcomeSeries[i].node.id,
        title: data.welcomeSeries[i]?.title ?? "",
        message: data.welcomeSeries[i]?.message ?? "",
        delaySeconds: (data.welcomeSeries[i]?.delayMinutes ?? 0) * 60,
        landingPath: data.welcomeSeries[i].landingPath,
        isEnabled: data.welcomeSeries[i].isEnabled ?? false,
      });
    }
    for (let i = 0; i < reengagement.length; i++) {
      if (data.reengagement?.[i] == null) {
        continue;
      }
      automatedNotifs.push({
        id: reengagement[i].node.id,
        title: data.reengagement[i]?.title ?? "",
        message: data.reengagement[i]?.message ?? "",
        delaySeconds: (data.reengagement[i]?.delayMinutes ?? 0) * 60,
        landingPath: data.reengagement[i].landingPath,
        isEnabled: data.reengagement[i].isEnabled,
      });
    }
    saveMutation(
      {
        id: brand.appConfig.id,
        automatedNotifications: automatedNotifs,
      },
      () => {
        setSaveButtonState(SavedState.SAVED);
        reset({}, { keepValues: true });
      }
    );
  };

  return (
    <Container maxWidth="md">
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <AutomatedUpsellSection brandID={brandID} />
        <CardSection
          actions={
            <SaveButton
              savedState={
                isDirty
                  ? SavedState.ENABLED
                  : isMutationInFlight
                  ? SavedState.DISABLED
                  : saveButtonState
              }
              useSubmit={true}
            />
          }
          title={"Push Notifications"}
          content={
            <Stack spacing={2} width="100%">
              <Box>
                <Typography variant="body2">
                  Use the left toggles and save to enable or disable automated
                  notifications.
                </Typography>
              </Box>
              {cartFields.map((field, index) => (
                <AutomatedNotificationCard
                  key={index}
                  title={"Abandoned Cart " + (index + 1)}
                  formName={"abandonedCart." + index}
                  register={register}
                  errors={errors?.["abandonedCart"]?.[index]}
                  control={control}
                  watch={watch}
                />
              ))}
              {browseFields.map((field, index) => (
                <AutomatedNotificationCard
                  key={index}
                  title={"Abandoned Browse " + (index + 1)}
                  formName={"abandonedBrowse." + index}
                  register={register}
                  errors={errors?.["abandonedBrowse"]?.[index]}
                  control={control}
                  watch={watch}
                />
              ))}
              {checkoutFields.map((field, index) => (
                <AutomatedNotificationCard
                  key={index}
                  title={"Abandoned Checkout " + (index + 1)}
                  formName={"abandonedCheckout." + index}
                  register={register}
                  errors={errors?.["abandonedCheckout"]?.[index]}
                  control={control}
                  watch={watch}
                />
              ))}
              {welcomeFields.map((field, index) => (
                <AutomatedNotificationCard
                  key={index}
                  title={"Welcome Series " + (index + 1)}
                  formName={"welcomeSeries." + index}
                  register={register}
                  errors={errors?.["welcomeSeries"]?.[index]}
                  control={control}
                  watch={watch}
                  showLandingPath={true}
                />
              ))}
              {reengagementFields.map((field, index) => (
                <AutomatedNotificationCard
                  key={index}
                  title={"Reengagement " + (index + 1)}
                  formName={"reengagement." + index}
                  register={register}
                  errors={errors?.["reengagement"]?.[index]}
                  control={control}
                  watch={watch}
                  showLandingPath={true}
                />
              ))}
            </Stack>
          }
        />
      </form>
    </Container>
  );
};

export default AutomationTab;
