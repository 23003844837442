import { SectionType } from "./constants";

export const ENGAGE_FEED = `/${SectionType.ENGAGE}/feed`;
export const ENGAGE_NOTIFICATIONS = `/${SectionType.ENGAGE}/notifications`;
export const ENGAGE_AUTOMATIONS = `/${SectionType.ENGAGE}/automation`;
export const ENGAGE_AUTOMATIONS_INTERSTITIAL_NOTIFICATIONS = `${ENGAGE_AUTOMATIONS}/interstitial-notifications`;
export const ENGAGE_DAILY_DEALS = `/${SectionType.ENGAGE}/daily-deals`;
export const SETTINGS_INTEGRATIONS = `/${SectionType.SETTINGS}/integrations`;
export const SETTINGS_SHOPIFY = `/${SectionType.SETTINGS}/shopify`;
export const BUILD_THEME = `/${SectionType.BUILD}/theme`;
export const BUILD_NAVIGATION = `/${SectionType.BUILD}/navigation`;
