import { Container } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { InternBrandiOSBuilds_brand$key } from "./__generated__/InternBrandiOSBuilds_brand.graphql"
import nullthrows from "../../utils/nullthrows";
import InternBrandiOSBuildsGeneralInfo from "./InternBrandiOSBuildsGeneralInfo";
import InternBrandiOSBuildsActions from "./InternBrandiOSBuildsActions"

const brandFragment = graphql`
  fragment InternBrandiOSBuilds_brand on BrandType {
    id
    appConfig {
      ...InternBrandiOSBuildsGeneralInfo_appConfig
    }
  }
`;

const InternBrandiOSBuilds = ({
  brand: brandKey,
}: {
  brand: InternBrandiOSBuilds_brand$key;
}) => {
  const brand = useFragment(brandFragment, brandKey);
  const appConfig = nullthrows(brand.appConfig);

  return (
    <Container maxWidth="md">
      <InternBrandiOSBuildsActions brandID={brand.id} />
      <InternBrandiOSBuildsGeneralInfo appConfig={appConfig} />
    </Container>
  );
};

export default InternBrandiOSBuilds;
