/**
 * @generated SignedSource<<a3e8c685a5258ca360f0c045bc616a93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AppViewType = "ACCOUNT" | "CART" | "CHECKOUT" | "CHECKOUT_CONFIRMATION" | "COLLECTION" | "CREATE_ACCOUNT" | "FOR_YOU" | "HOME" | "HOME_LOGGED_IN" | "LOG_IN" | "LOG_OUT" | "PASSWORD_RESET" | "PDP" | "SEARCH" | "SHOPIFY_REDIRECTION" | "%future added value";
export type InterstitialNotificationTriggerType = "PAGE_TRIGGER" | "SESSION_DELAY" | "%future added value";
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
export type InterstitialNotificationFormQuery$variables = {
  id: string;
  skip: boolean;
};
export type InterstitialNotificationFormQuery$data = {
  readonly interstitialNotification?: {
    readonly audienceLanguages: ReadonlyArray<KinnLanguageCode> | null;
    readonly audiences: ReadonlyArray<{
      readonly audienceId: string;
    }>;
    readonly callToActionDestination: string | null;
    readonly callToActionTitle: string;
    readonly frequencyCap: number;
    readonly id: string;
    readonly thumbnailUrl: string | null;
    readonly triggerAppViewType: AppViewType | null;
    readonly triggerDelaySeconds: number;
    readonly triggerPath: string | null;
    readonly triggerType: InterstitialNotificationTriggerType;
    readonly utmCampaign: string | null;
  };
};
export type InterstitialNotificationFormQuery = {
  response: InterstitialNotificationFormQuery$data;
  variables: InterstitialNotificationFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "callToActionTitle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "callToActionDestination",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "frequencyCap",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "triggerType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "triggerPath",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "triggerAppViewType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "triggerDelaySeconds",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "utmCampaign",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "audienceLanguages",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "audienceId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InterstitialNotificationFormQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GraphQLInterstitialNotification",
            "kind": "LinkedField",
            "name": "interstitialNotification",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLCDPAudience",
                "kind": "LinkedField",
                "name": "audiences",
                "plural": true,
                "selections": [
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InterstitialNotificationFormQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GraphQLInterstitialNotification",
            "kind": "LinkedField",
            "name": "interstitialNotification",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLCDPAudience",
                "kind": "LinkedField",
                "name": "audiences",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "4c7ddf3967696fd1b32182219fdeff4e",
    "id": null,
    "metadata": {},
    "name": "InterstitialNotificationFormQuery",
    "operationKind": "query",
    "text": "query InterstitialNotificationFormQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  interstitialNotification(id: $id) @skip(if: $skip) {\n    id\n    callToActionTitle\n    callToActionDestination\n    frequencyCap\n    triggerType\n    triggerPath\n    triggerAppViewType\n    triggerDelaySeconds\n    utmCampaign\n    audienceLanguages\n    thumbnailUrl\n    audiences {\n      audienceId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bf1ab75dd73540a09b96dc27a67789af";

export default node;
