/**
 * @generated SignedSource<<552eb440c2c629dc56ebc49f17341722>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MappingTabQuery$variables = {
  id: string;
  skip: boolean;
};
export type MappingTabQuery$data = {
  readonly brand: {
    readonly " $fragmentSpreads": FragmentRefs<"MappingTab_brand">;
  };
};
export type MappingTabQuery = {
  response: MappingTabQuery$data;
  variables: MappingTabQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MappingTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MappingTab_brand"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MappingTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "brand",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandAppConfigType",
            "kind": "LinkedField",
            "name": "appConfig",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "HTMLCustomAttribute",
                "kind": "LinkedField",
                "name": "htmlCustomAttributes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "querySelector",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "attributeKey",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "attributeValue",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "condition": "skip",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "domain",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandAppConfigType",
                "kind": "LinkedField",
                "name": "appConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphQLBrandAppViewToURLMapping",
                    "kind": "LinkedField",
                    "name": "appViewToUrlMappings",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "appViewType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "regex",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b101d1866aa7eddb5391521bfffb7ee5",
    "id": null,
    "metadata": {},
    "name": "MappingTabQuery",
    "operationKind": "query",
    "text": "query MappingTabQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  brand(id: $id) {\n    ...MappingTab_brand\n    id\n  }\n}\n\nfragment AppLayoutCard_brand on BrandType {\n  id\n  domain\n  appConfig {\n    appViewToUrlMappings {\n      id\n      appViewType\n      regex\n      url\n    }\n    id\n  }\n}\n\nfragment HtmlConfigCard_brand on BrandType {\n  id\n  appConfig {\n    id\n    htmlCustomAttributes {\n      querySelector\n      attributeKey\n      attributeValue\n    }\n  }\n}\n\nfragment MappingTab_brand on BrandType {\n  id\n  ...HtmlConfigCard_brand\n  ...AppLayoutCard_brand @skip(if: $skip)\n}\n"
  }
};
})();

(node as any).hash = "6b43d243cbc1de582afe48cd8bb1bee6";

export default node;
