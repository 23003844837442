/**
 * @generated SignedSource<<38e0ad9847015c3c5acc5b65c25e4819>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GraphQLBrandAppViewToURLMappingInputPartial = {
  id: string;
  regex: string;
  url: string;
};
export type AppLayoutCardUpdateMutation$variables = {
  brandId: string;
  mappingInputs: ReadonlyArray<GraphQLBrandAppViewToURLMappingInputPartial>;
};
export type AppLayoutCardUpdateMutation$data = {
  readonly updateUrlRegexMappings: {
    readonly " $fragmentSpreads": FragmentRefs<"AppLayoutCard_brand">;
  };
};
export type AppLayoutCardUpdateMutation = {
  response: AppLayoutCardUpdateMutation$data;
  variables: AppLayoutCardUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mappingInputs"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "brandId",
    "variableName": "brandId"
  },
  {
    "kind": "Variable",
    "name": "mappingInputs",
    "variableName": "mappingInputs"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppLayoutCardUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "updateUrlRegexMappings",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AppLayoutCard_brand"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppLayoutCardUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "updateUrlRegexMappings",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "domain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandAppConfigType",
            "kind": "LinkedField",
            "name": "appConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLBrandAppViewToURLMapping",
                "kind": "LinkedField",
                "name": "appViewToUrlMappings",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appViewType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "regex",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3aaa3811ebc6674bbba8d7958730a2af",
    "id": null,
    "metadata": {},
    "name": "AppLayoutCardUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation AppLayoutCardUpdateMutation(\n  $brandId: ID!\n  $mappingInputs: [GraphQLBrandAppViewToURLMappingInputPartial!]!\n) {\n  updateUrlRegexMappings(brandId: $brandId, mappingInputs: $mappingInputs) {\n    ...AppLayoutCard_brand\n    id\n  }\n}\n\nfragment AppLayoutCard_brand on BrandType {\n  id\n  domain\n  appConfig {\n    appViewToUrlMappings {\n      id\n      appViewType\n      regex\n      url\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a39ad1098d09b7779efd43bc289abb0e";

export default node;
