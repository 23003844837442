import { Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { Suspense, useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { LaunchAppContainerQuery } from "./__generated__/LaunchAppContainerQuery.graphql";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { SectionType, SidepaneConfig } from "../../../utils/constants";
import AppListingTab from "./AppListingTab";

const query = graphql`
  query LaunchAppContainerQuery($id: ID!) {
    user {
      isInternal
    }
    brand(id: $id) {
      ...AppListingTab_brand
      id
      finishedOnboarding
    }
  }
`;

const config = SidepaneConfig.find(
  (config) => config.sectionType === SectionType.LAUNCH_APP
);

const LaunchAppContainer = () => {
  const currentUserContext = useContext(CurrentUserContext);
  const data = useLazyLoadQuery<LaunchAppContainerQuery>(query, {
    id: currentUserContext?.activeBrandID ?? "",
  });
  const brand = data.brand;
  const user = data.user;
  const brandFinishedOnboarding = data.brand.finishedOnboarding === true;

  const location = useLocation();
  const navigate = useNavigate();

  const tabs = config?.sections
    .filter((config) => config.isPublic || user?.isInternal === true)
    .map((config, index) => (
      <Tab
        disabled={config.isDisabledPreOnboarded && !brandFinishedOnboarding}
        value={"/" + SectionType.LAUNCH_APP + config.pathname}
        label={config.title}
        key={index}
      />
    ));

  const pathSplit = location.pathname.split("/");
  return (
    <Box>
      <Typography variant="h4">{config?.sectionName}</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider", margin: "32px 0" }}>
        <Tabs
          value={"/" + pathSplit[1] + "/" + pathSplit[2]}
          onChange={(_, value) => {
            navigate(value);
          }}
        >
          {tabs}
        </Tabs>
      </Box>
      <Routes>
        {config?.sections
          .filter((config) => {
            return config.isPublic || user?.isInternal === true;
          })
          .map((config, index) => {
            if (config.isDisabledPreOnboarded && !brandFinishedOnboarding) {
              return null;
            }

            let element = null;

            switch (config.pathname) {
              case "/app-listing":
                element = (
                  <AppListingTab
                    brand={brand}
                    key={currentUserContext?.activeBrandID}
                  />
                );
                break;
            }
            return (
              <Route
                path={config.pathname + "/*"}
                element={
                  <Suspense
                    fallback={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    }
                  >
                    {element}
                  </Suspense>
                }
                key={index}
              />
            );
          })}
      </Routes>
    </Box>
  );
};

export default LaunchAppContainer;
