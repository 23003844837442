/**
 * @generated SignedSource<<c4c7ac489f322fadb2f3b78d57afbeb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FeedPostAttachmentType = "CAROUSEL" | "LINK" | "MEDIA" | "NONE" | "POLL" | "%future added value";
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
export type MediaType = "PHOTO" | "VIDEO" | "WEB_MUX_VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeedTabTableRow_feedPost$data = {
  readonly audienceLanguages: ReadonlyArray<KinnLanguageCode> | null;
  readonly audiences: ReadonlyArray<{
    readonly audienceName: string;
  }>;
  readonly carousel: {
    readonly coverImageUrl: string | null;
    readonly items: ReadonlyArray<{
      readonly engagementData: {
        readonly impressions: number | null;
        readonly taps: number | null;
      } | null;
      readonly title: string;
    }>;
    readonly subtitle: string | null;
    readonly title: string;
  } | null;
  readonly id: string;
  readonly isArchived: boolean;
  readonly link: {
    readonly engagementData: {
      readonly impressions: number | null;
      readonly taps: number | null;
    } | null;
    readonly externalMediaUrl: string | null;
    readonly media: ReadonlyArray<{
      readonly coverImageUrl: string | null;
      readonly type: MediaType;
      readonly url: string;
    }>;
    readonly subtitle: string | null;
    readonly title: string;
  } | null;
  readonly notification: {
    readonly id: string;
  } | null;
  readonly poll: {
    readonly customResponsesEnabled: boolean;
    readonly media: {
      readonly url: string;
    } | null;
    readonly optionsCount: number;
    readonly pollOptions: ReadonlyArray<{
      readonly isCustomerCreatedOption: boolean;
      readonly isStatic: boolean;
      readonly title: string;
      readonly voteCount: number;
    }>;
    readonly title: string;
  } | null;
  readonly publishedAt: any;
  readonly type: FeedPostAttachmentType;
  readonly " $fragmentType": "FeedTabTableRow_feedPost";
};
export type FeedTabTableRow_feedPost$key = {
  readonly " $data"?: FeedTabTableRow_feedPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedTabTableRow_feedPost">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverImageUrl",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "taps",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "impressions",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedTabTableRow_feedPost",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLCDPAudience",
      "kind": "LinkedField",
      "name": "audiences",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "audienceName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "audienceLanguages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLLink",
      "kind": "LinkedField",
      "name": "link",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "externalMediaUrl",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLMedia",
          "kind": "LinkedField",
          "name": "media",
          "plural": true,
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphqlAttriburedLink",
          "kind": "LinkedField",
          "name": "engagementData",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLPoll",
      "kind": "LinkedField",
      "name": "poll",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLPollOption",
          "kind": "LinkedField",
          "name": "pollOptions",
          "plural": true,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voteCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCustomerCreatedOption",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isStatic",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "optionsCount",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customResponsesEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLMedia",
          "kind": "LinkedField",
          "name": "media",
          "plural": false,
          "selections": [
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLCarousel",
      "kind": "LinkedField",
      "name": "carousel",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v2/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLCarouselItem",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "GraphqlAttributedCaroutelItem",
              "kind": "LinkedField",
              "name": "engagementData",
              "plural": false,
              "selections": (v6/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLNotification",
      "kind": "LinkedField",
      "name": "notification",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "GraphQLFeedPost",
  "abstractKey": null
};
})();

(node as any).hash = "41e014a5550816dbf39b22a054942a77";

export default node;
