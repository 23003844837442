import { Box, Link, Typography } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useContext } from "react";
import { useFragment } from "react-relay";
import { SlackCard_brand$key } from "./__generated__/SlackCard_brand.graphql";
import CardSection from "../../../../components/CardSection";
import LeftRight from "../../../../components/LeftRight";
import CurrentUserContext from "../../../../contexts/CurrentUserContext";
import SnackbarContext from "../../../../contexts/SnackbarContext";

const brandFragment = graphql`
  fragment SlackCard_brand on BrandType {
    slackUserInstalledChannels
  }
`;

const SlackCard = ({ brand: brandKey }: { brand: SlackCard_brand$key }) => {
  const brand = useFragment(brandFragment, brandKey);
  const currentUserContext = useContext(CurrentUserContext);
  const snackbarContext = useContext(SnackbarContext);

  const onSlackSubmit = () => {
    var newWindow = window.open();
    fetch("/api/v1/slack/init", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        brand_id: window.atob(currentUserContext?.activeBrandID).split(":")[1],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        const redirect = data.redirect_url;
        if (redirect) {
          if (newWindow) {
            newWindow.location = redirect;
          } else {
            snackbarContext?.openSnackbar(
              "Shopify integration failed, missing redirect URL",
              "error"
            );
          }
        } else {
          newWindow?.close();
          snackbarContext?.openSnackbar(
            "Shopify integration failed, missing redirect URL",
            "error"
          );
        }
      });
  };
  return (
    <CardSection
      title={"Slack"}
      content={
        <Box>
          <LeftRight
            expandLeft
            left={
              <Typography>
                Supercharge your team's productivity with our Slack Bot. Receive
                daily analytics summaries, inquire about your metrics, and
                seamlessly integrate with your existing workflows.
              </Typography>
            }
            right={
              <Link
                component={"button"}
                onClick={onSlackSubmit}
                display={"flex"}
              >
                <img
                  alt="Add to Slack"
                  onClick={onSlackSubmit}
                  height="40"
                  width="139"
                  src="https://platform.slack-edge.com/img/add_to_slack.png"
                  srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                />
              </Link>
            }
          />

          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              fontStyle: "italic",
            }}
          >
            {brand.slackUserInstalledChannels.length === 0
              ? "You're not connected to any channels."
              : "Your connected channels: " +
                brand.slackUserInstalledChannels.join(", ")}
          </Typography>
        </Box>
      }
    />
  );
};

export default SlackCard;
