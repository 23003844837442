/**
 * @generated SignedSource<<89ba6cbd9f2f556e13bb4d727bad28c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScreenshotsCard_brand$data = {
  readonly appConfig: {
    readonly id: string;
    readonly screenshots: ReadonlyArray<{
      readonly id: string;
      readonly image: {
        readonly url: string;
      };
    }>;
  };
  readonly " $fragmentType": "ScreenshotsCard_brand";
};
export type ScreenshotsCard_brand$key = {
  readonly " $data"?: ScreenshotsCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScreenshotsCard_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScreenshotsCard_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandAppConfigScreenshotType",
          "kind": "LinkedField",
          "name": "screenshots",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "DjangoImageType",
              "kind": "LinkedField",
              "name": "image",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "095a5bc11ab4d0552ad3b9e7a737c6ad";

export default node;
