import { Container, Stack } from "@mui/material";
import CardSection from "../../../../components/CardSection";
import SaveButton, { SavedState } from "../../../../components/SaveButton";

const BillingTab = () => {
  return (
    <Container maxWidth="md">
      <Stack spacing={2}>
        <CardSection
          actions={
            <SaveButton savedState={SavedState.DISABLED} onClick={() => {}} />
          }
          title={"Subscription"}
          content={<></>}
        />
        <CardSection
          actions={
            <SaveButton savedState={SavedState.DISABLED} onClick={() => {}} />
          }
          title={"Details"}
          content={<></>}
        />
        <CardSection
          actions={
            <SaveButton savedState={SavedState.DISABLED} onClick={() => {}} />
          }
          title={"Usage"}
          content={<></>}
        />
        <CardSection
          actions={
            <SaveButton savedState={SavedState.DISABLED} onClick={() => {}} />
          }
          title={"History"}
          content={<></>}
        />
      </Stack>
    </Container>
  );
};

export default BillingTab;
