import { Box, CircularProgress } from "@mui/material";
import { Suspense, useEffect, useMemo, useState } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import RelayEnvironment from "./RelayEnvironment";
import PrivateRoute from "./components/auth/PrivateRoute";
import ConfirmEmail from "./views/auth/ConfirmEmail";
import ConfirmPasswordReset from "./views/auth/ConfirmPasswordReset";
import Login from "./views/auth/Login";
import Logout from "./views/auth/Logout";
import PasswordReset from "./views/auth/PasswordReset";
import Signup from "./views/auth/Signup";
import AppDownloadRouter from "./views/core/AppDownloadRouter";
import DashboardContainer from "./views/dashboard/Dashboard";
import HomePageContainer from "./views/dashboard/analytics/HomePageContainer";
import InsightsContainer from "./views/dashboard/analytics/InsightsContainer";
import BuildContainer from "./views/dashboard/build_section/BuildContainer";
import EngageContainer from "./views/dashboard/engage/EngageContainer";
import LaunchAppContainer from "./views/dashboard/launch-app/LaunchAppContainer";
import SettingsContainer from "./views/dashboard/settings/SettingsContainer";
import SetupContainer from "./views/dashboard/setup/SetupContainer";
import Gladly from "./views/integrations/Gladly";
import Gorgias from "./views/integrations/Gorgias";
import Kustomer from "./views/integrations/Kustomer";
import InternContainer from "./views/intern/InternContainer";
import ShopifyInstall from "./views/shopify/ShopifyInstall";

function App() {
  const location = useLocation();
  const locationParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const shopifyChargeID = locationParams.get("charge_id");

  const [isLoading, setIsLoading] = useState(shopifyChargeID != null);

  // We do this to make sure the Shopify webhook has time to fire
  useEffect(() => {
    if (shopifyChargeID != null) {
      const id = setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return () => clearTimeout(id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <DashboardContainer />
            </PrivateRoute>
          }
        >
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Suspense fallback={null}>
                  <HomePageContainer />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/analytics/*"
            element={
              <PrivateRoute>
                <Suspense fallback={null}>
                  <InsightsContainer />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/setup/*"
            element={
              <PrivateRoute>
                <Suspense fallback={null}>
                  <SetupContainer />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/build/*"
            element={
              <PrivateRoute>
                <Suspense fallback={null}>
                  <BuildContainer />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/engage/*"
            element={
              <PrivateRoute>
                <Suspense fallback={null}>
                  <EngageContainer />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/launch-app/*"
            element={
              <PrivateRoute>
                <Suspense fallback={null}>
                  <LaunchAppContainer />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/*"
            element={
              <PrivateRoute>
                <Suspense fallback={null}>
                  <SettingsContainer />
                </Suspense>
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="/shopify/install" element={<ShopifyInstall />} />

        {/* TODO: Make sure they all use the same theme */}
        <Route path="/login" element={<Login />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route
          path="/confirm-password-reset"
          element={<ConfirmPasswordReset />}
        />
        <Route path="/create-account" element={<Signup />} />
        <Route path="/confirm-email" element={<ConfirmEmail />} />

        <Route
          path="/logout"
          element={
            <PrivateRoute>
              <Logout />
            </PrivateRoute>
          }
        />

        <Route path="/gorgias" element={<Gorgias />} />
        <Route path="/kustomer" element={<Kustomer />} />
        <Route path="/gladly" element={<Gladly />} />
        <Route path="/app-download" element={<AppDownloadRouter />} />

        <Route
          path="/intern/*"
          element={
            <PrivateRoute>
              <InternContainer />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

function AppRoot() {
  return (
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      <Suspense
        fallback={
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <CircularProgress />
          </Box>
        }
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </RelayEnvironmentProvider>
  );
}

export default AppRoot;
