/**
 * @generated SignedSource<<72b4d1e636a13b6641652a3d39e31e85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppStoreCategory = "BOOKS" | "BUSINESS" | "DEVELOPER_TOOLS" | "EDUCATION" | "ENTERTAINMENT" | "FINANCE" | "FOOD_AND_DRINK" | "GAMES" | "GRAPHICS_AND_DESIGN" | "HEALTH_AND_FITNESS" | "LIFESTYLE" | "MAGAZINES_AND_NEWSPAPERS" | "MEDICAL" | "MUSIC" | "NAVIGATION" | "NEWS" | "PHOTO_AND_VIDEO" | "PRODUCTIVITY" | "REFERENCE" | "SHOPPING" | "SOCIAL_NETWORKING" | "SPORTS" | "STICKERS" | "TRAVEL" | "UTILITIES" | "WEATHER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AppListingTab_brand$data = {
  readonly appConfig: {
    readonly appDescription: string;
    readonly appIconUrl: string | null;
    readonly appName: string;
    readonly appStoreCategories: ReadonlyArray<AppStoreCategory>;
    readonly appSubtitle: string;
    readonly id: string;
    readonly keywords: string;
    readonly marketingUrl: string;
    readonly primaryCategory: AppStoreCategory | null;
    readonly privacyPolicyUrl: string;
    readonly promotionalText: string;
    readonly secondaryCategory: AppStoreCategory | null;
    readonly supportUrl: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AppDistributionCard_brand" | "ScreenshotsCard_brand">;
  readonly " $fragmentType": "AppListingTab_brand";
};
export type AppListingTab_brand$key = {
  readonly " $data"?: AppListingTab_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppListingTab_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppListingTab_brand",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScreenshotsCard_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppDistributionCard_brand"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appDescription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appIconUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appSubtitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appStoreCategories",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "keywords",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "primaryCategory",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondaryCategory",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "privacyPolicyUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "promotionalText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supportUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "marketingUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "f3377be539ea33935579fc34e756e213";

export default node;
