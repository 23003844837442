/**
 * @generated SignedSource<<4a5952cfbade08d0544d8bb83947dfdc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppViewType = "ACCOUNT" | "CART" | "CHECKOUT" | "CHECKOUT_CONFIRMATION" | "COLLECTION" | "CREATE_ACCOUNT" | "FOR_YOU" | "HOME" | "HOME_LOGGED_IN" | "LOG_IN" | "LOG_OUT" | "PASSWORD_RESET" | "PDP" | "SEARCH" | "SHOPIFY_REDIRECTION" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AppLayoutCard_brand$data = {
  readonly appConfig: {
    readonly appViewToUrlMappings: ReadonlyArray<{
      readonly appViewType: AppViewType;
      readonly id: string;
      readonly regex: string;
      readonly url: string;
    }>;
  };
  readonly domain: string;
  readonly id: string;
  readonly " $fragmentType": "AppLayoutCard_brand";
};
export type AppLayoutCard_brand$key = {
  readonly " $data"?: AppLayoutCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppLayoutCard_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppLayoutCard_brand",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domain",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLBrandAppViewToURLMapping",
          "kind": "LinkedField",
          "name": "appViewToUrlMappings",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "appViewType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "regex",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "f79d8278a421a1e98699a739dfca58e1";

export default node;
