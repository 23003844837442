import MenuIcon from "@mui/icons-material/Menu";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import QRCode from "react-qr-code";
import { useFragment } from "react-relay";
import { TopNavBar_user$key } from "./__generated__/TopNavBar_user.graphql";
import LeftRight from "../LeftRight";
import CurrentUser from "../auth/CurrentUser";

const userFragment = graphql`
  fragment TopNavBar_user on UserType {
    ...CurrentUser_userFragment
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const TopNavBar = ({
  previewEnabled,
  user: userKey,
  activeBrandID,
  onDrawerOpen,
  onDrawerClose,
}: {
  previewEnabled: boolean;
  user: TopNavBar_user$key;
  activeBrandID: string;
  onDrawerOpen: () => void;
  onDrawerClose: () => void;
}) => {
  const user = useFragment(userFragment, userKey);
  const [isDownloadDemoModalOpen, setIsDownloadDemoModalOpen] = useState(false);
  const handleOpenDownloadDemoModal = () => setIsDownloadDemoModalOpen(true);
  const handleCloseDownloadDemoModal = () => setIsDownloadDemoModalOpen(false);

  const [activeTab, setActiveTab] = useState(0);

  const handlePreviewAppTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setActiveTab(newValue);
  };

  const decodedBrandId = window.atob(activeBrandID).split(":")[1];
  const previewAppSection = (
    <div>
      <Button
        variant="contained"
        endIcon={<SmartphoneIcon />}
        onClick={handleOpenDownloadDemoModal}
        disabled={!previewEnabled}
      >
        Preview App
      </Button>
      <Dialog
        open={isDownloadDemoModalOpen}
        onClose={handleCloseDownloadDemoModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "500px",
              height: "415px",
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Preview in Kin Studio"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Scan the QR code inside Kin Studio to preview your app.
          </DialogContentText>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={activeTab}
              onChange={handlePreviewAppTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Preview App" />
              <Tab label="Download Kin Studio" />
            </Tabs>
          </Box>
          <CustomTabPanel value={activeTab} index={0}>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={16} style={{ textAlign: "center" }}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "50%", width: "50%" }}
                  value={"kinstudio://load-brand?id=" + decodedBrandId}
                  viewBox={`0 0 256 256`}
                />
              </Grid>
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={1}>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={8} style={{ textAlign: "center" }}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "85%", width: "85%" }}
                  value={
                    "https://apps.apple.com/us/app/kin-studio/id6455128364"
                  }
                  viewBox={`0 0 256 256`}
                />
              </Grid>
              <Grid item xs={8} style={{ textAlign: "center" }}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "85%", width: "85%" }}
                  value={
                    "https://play.google.com/store/apps/details?id=com.kin.studio"
                  }
                  viewBox={`0 0 256 256`}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={8} style={{ textAlign: "center" }}>
                iOS
              </Grid>
              <Grid item xs={8} style={{ textAlign: "center" }}>
                Android
              </Grid>
            </Grid>
          </CustomTabPanel>
        </DialogContent>
      </Dialog>
    </div>
  );

  return (
    <Box
      sx={{
        width: "100%",
        px: 2,
        py: "12px",
        zIndex: 1100,
        flexShrink: 0,
        background: "white",
        boxShadow:
          "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
      }}
    >
      <LeftRight
        expandLeft={true}
        left={
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onDrawerOpen}
            onMouseOver={(event) => {
              onDrawerOpen();
            }}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        }
        right={
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <CurrentUser user={user} onDrawerClose={onDrawerClose} />
            <Tooltip
              title={
                previewEnabled
                  ? ""
                  : "You'll only be able to preview your app once you've set the theme."
              }
              arrow
            >
              <span>{previewAppSection}</span>
            </Tooltip>
          </Stack>
        }
      />
    </Box>
  );
};

export default TopNavBar;
