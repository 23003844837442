/**
 * @generated SignedSource<<8bd8be05ee5d0a68b5cc282a8934d1ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InterstitialNotificationForm_brand$data = {
  readonly deeplinkUrlScheme: string;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CDPAudienceSelectorCard_brand">;
  readonly " $fragmentType": "InterstitialNotificationForm_brand";
};
export type InterstitialNotificationForm_brand$key = {
  readonly " $data"?: InterstitialNotificationForm_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"InterstitialNotificationForm_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InterstitialNotificationForm_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deeplinkUrlScheme",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CDPAudienceSelectorCard_brand"
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "e2e6b971c1ae2efb805eda5c46ebca76";

export default node;
