import { Stack, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import * as yup from "yup";
import { WishlistCard_brand$key } from "./__generated__/WishlistCard_brand.graphql";
import CardSection from "../../../../components/CardSection";
import SaveButton, { SavedState } from "../../../../components/SaveButton";
import useBrandAppConfigUpdate2Mutation from "../../../../mutations/useBrandAppConfigUpdate2Mutation";
import { validateUrl } from "../../../../utils/validators";

const schema = yup
  .object({
    wishlistUrl: yup
      .string()
      .test("is-url-valid", "URL or deeplink is not valid", (value) => {
        if (!value) return true;

        return validateUrl(value);
      }),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const brandFragment = graphql`
  fragment WishlistCard_brand on BrandType {
    appConfig {
      id
      wishlistUrl
    }
  }
`;

type Props = {
  brand: WishlistCard_brand$key;
};

const WishlistCard = ({ brand: brandKey }: Props) => {
  const brand = useFragment<WishlistCard_brand$key>(brandFragment, brandKey);
  const appConfig = brand.appConfig;
  const wishlistUrl = appConfig.wishlistUrl ?? "";

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    wishlistUrl === "" ? SavedState.DISABLED : SavedState.SAVED
  );

  const [saveMutation, isMutationInFlight] = useBrandAppConfigUpdate2Mutation();

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      wishlistUrl,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const subscription = watch((value) => {
      saveButtonState !== SavedState.ENABLED &&
        setSaveButtonState(SavedState.ENABLED);
    });
    return () => subscription.unsubscribe();
  }, [watch, saveButtonState]);

  const onSubmit = (data: FormData) => {
    saveMutation(
      {
        id: appConfig.id,
        wishlistUrl: data.wishlistUrl,
      },
      () => {
        setSaveButtonState(SavedState.SAVED);
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <CardSection
        actions={
          <SaveButton
            useSubmit
            savedState={
              isMutationInFlight ? SavedState.DISABLED : saveButtonState
            }
          />
        }
        title={"Wishlist"}
        showIsOptional
        content={
          <Stack
            spacing={2}
            sx={{
              maxWidth: "650px",
            }}
          >
            <Typography variant="body2">
              To enable the wishlist button for your app, input the URL of your
              site's Wishlist page. Leaving the text box empty will disable the
              wishlist button.
            </Typography>
            <TextField
              {...register("wishlistUrl")}
              error={!!errors?.wishlistUrl}
              helperText={errors?.wishlistUrl?.message}
              autoComplete="off"
              margin="normal"
              id="outlined-basic"
              label={"Wishlist URL"}
              variant="outlined"
            />
          </Stack>
        }
      />
    </form>
  );
};

export default WishlistCard;
