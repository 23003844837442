/**
 * @generated SignedSource<<7591ededcd55d0bb84b7eb867b578585>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedPostAttachmentType = "CAROUSEL" | "LINK" | "MEDIA" | "NONE" | "POLL" | "%future added value";
export type FeedPostFormQuery$variables = {
  id: string;
  skip: boolean;
};
export type FeedPostFormQuery$data = {
  readonly feedPost?: {
    readonly id: string;
    readonly type: FeedPostAttachmentType;
    readonly " $fragmentSpreads": FragmentRefs<"FeedCarouselForm_feedPost" | "FeedPollForm_feedPost" | "FeedSinglePostForm_feedPost">;
  };
};
export type FeedPostFormQuery = {
  response: FeedPostFormQuery$data;
  variables: FeedPostFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverImageUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "GraphQLMedia",
  "kind": "LinkedField",
  "name": "media",
  "plural": false,
  "selections": [
    (v6/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedPostFormQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GraphQLFeedPost",
            "kind": "LinkedField",
            "name": "feedPost",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FeedSinglePostForm_feedPost"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FeedPollForm_feedPost"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FeedCarouselForm_feedPost"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeedPostFormQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GraphQLFeedPost",
            "kind": "LinkedField",
            "name": "feedPost",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLCDPAudience",
                "kind": "LinkedField",
                "name": "audiences",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "audienceId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "audienceLanguages",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "publishedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLLink",
                "kind": "LinkedField",
                "name": "link",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "externalMediaUrl",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphQLMedia",
                    "kind": "LinkedField",
                    "name": "media",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLNotification",
                "kind": "LinkedField",
                "name": "notification",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "body",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "utmCampaign",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLPoll",
                "kind": "LinkedField",
                "name": "poll",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customResponsesEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphQLPollOption",
                    "kind": "LinkedField",
                    "name": "pollOptions",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isCustomerCreatedOption",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isStatic",
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLCarousel",
                "kind": "LinkedField",
                "name": "carousel",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphQLCarouselItem",
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GraphQLMedia",
                        "kind": "LinkedField",
                        "name": "media",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v3/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "41b647dedd5d301b51ff75ea559c700c",
    "id": null,
    "metadata": {},
    "name": "FeedPostFormQuery",
    "operationKind": "query",
    "text": "query FeedPostFormQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  feedPost(id: $id) @skip(if: $skip) {\n    id\n    type\n    ...FeedSinglePostForm_feedPost\n    ...FeedPollForm_feedPost\n    ...FeedCarouselForm_feedPost\n  }\n}\n\nfragment FeedCarouselForm_feedPost on GraphQLFeedPost {\n  id\n  audiences {\n    audienceId\n    id\n  }\n  audienceLanguages\n  publishedAt\n  notification {\n    title\n    body\n    utmCampaign\n    id\n  }\n  carousel {\n    id\n    title\n    subtitle\n    items {\n      id\n      title\n      subtitle\n      url\n      media {\n        url\n        type\n        coverImageUrl\n      }\n    }\n  }\n}\n\nfragment FeedPollForm_feedPost on GraphQLFeedPost {\n  id\n  audiences {\n    audienceId\n    id\n  }\n  audienceLanguages\n  publishedAt\n  notification {\n    title\n    body\n    utmCampaign\n    id\n  }\n  poll {\n    id\n    title\n    media {\n      url\n    }\n    customResponsesEnabled\n    pollOptions {\n      id\n      title\n      isCustomerCreatedOption\n      isStatic\n      media {\n        url\n      }\n    }\n  }\n}\n\nfragment FeedSinglePostForm_feedPost on GraphQLFeedPost {\n  id\n  audiences {\n    audienceId\n    id\n  }\n  audienceLanguages\n  publishedAt\n  link {\n    externalMediaUrl\n    subtitle\n    title\n    url\n    media {\n      url\n      coverImageUrl\n      type\n    }\n    id\n  }\n  notification {\n    title\n    body\n    utmCampaign\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "836723c08660eb4a163ea8cc404040dd";

export default node;
