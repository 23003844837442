/**
 * @generated SignedSource<<7f5affddcac98b571efacfbb13cb3b32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppViewType = "ACCOUNT" | "CART" | "CHECKOUT" | "CHECKOUT_CONFIRMATION" | "COLLECTION" | "CREATE_ACCOUNT" | "FOR_YOU" | "HOME" | "HOME_LOGGED_IN" | "LOG_IN" | "LOG_OUT" | "PASSWORD_RESET" | "PDP" | "SEARCH" | "SHOPIFY_REDIRECTION" | "%future added value";
export type InterstitialNotificationTriggerType = "PAGE_TRIGGER" | "SESSION_DELAY" | "%future added value";
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InterstitialNotificationTableRow_notification$data = {
  readonly audienceLanguages: ReadonlyArray<KinnLanguageCode> | null;
  readonly audiences: ReadonlyArray<{
    readonly audienceName: string;
  }>;
  readonly callToActionDestination: string | null;
  readonly callToActionTitle: string;
  readonly frequencyCap: number;
  readonly id: string;
  readonly isArchived: boolean;
  readonly thumbnailUrl: string | null;
  readonly triggerAppViewType: AppViewType | null;
  readonly triggerDelaySeconds: number;
  readonly triggerPath: string | null;
  readonly triggerType: InterstitialNotificationTriggerType;
  readonly utmCampaign: string | null;
  readonly " $fragmentType": "InterstitialNotificationTableRow_notification";
};
export type InterstitialNotificationTableRow_notification$key = {
  readonly " $data"?: InterstitialNotificationTableRow_notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"InterstitialNotificationTableRow_notification">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InterstitialNotificationTableRow_notification",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLCDPAudience",
      "kind": "LinkedField",
      "name": "audiences",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "audienceName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "callToActionTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "callToActionDestination",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "frequencyCap",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "triggerType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "triggerPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "triggerAppViewType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "triggerDelaySeconds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "utmCampaign",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "audienceLanguages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    }
  ],
  "type": "GraphQLInterstitialNotification",
  "abstractKey": null
};

(node as any).hash = "ec31463cd6147d26310877d50eeaf9db";

export default node;
