import {
  Autocomplete,
  Chip,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import * as yup from "yup";
import { CartCard_brand$key } from "./__generated__/CartCard_brand.graphql";
import CardSection from "../../../../components/CardSection";
import LeftRight from "../../../../components/LeftRight";
import SaveButton, { SavedState } from "../../../../components/SaveButton";
import useBrandAppConfigUpdate2Mutation from "../../../../mutations/useBrandAppConfigUpdate2Mutation";

const schema = yup
  .object({
    openCartAfterUpdate: yup.boolean(),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const brandFragment = graphql`
  fragment CartCard_brand on BrandType {
    appConfig {
      id
      cartIgnoredProductIds
      openCartAfterUpdate
    }
  }
`;

type Props = {
  brand: CartCard_brand$key;
};

const CartCard = ({ brand: brandKey }: Props) => {
  const brand = useFragment<CartCard_brand$key>(brandFragment, brandKey);
  const appConfig = brand.appConfig;

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.SAVED
  );

  const [productIDs, setProductIDs] = useState<string>(
    (appConfig.cartIgnoredProductIds ?? []).join(",")
  );
  const [productIDsInput, setProductIDsInput] = useState<string>("");

  const [saveMutation, isMutationInFlight] = useBrandAppConfigUpdate2Mutation();

  const { handleSubmit, register, watch } = useForm<FormData>({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const subscription = watch((value) => {
      saveButtonState !== SavedState.ENABLED &&
        setSaveButtonState(SavedState.ENABLED);
    });
    return () => subscription.unsubscribe();
  }, [watch, saveButtonState]);

  const onSubmit = (data: FormData) => {
    saveMutation(
      {
        id: appConfig.id,
        openCartAfterUpdate: data.openCartAfterUpdate,
        cartIgnoredProductIds: !productIDs ? [] : productIDs.split(","),
      },
      () => {
        setSaveButtonState(SavedState.SAVED);
      }
    );
  };

  const defaultOptions: Array<string> = useMemo(() => [], []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <CardSection
        actions={
          <SaveButton
            useSubmit
            savedState={
              isMutationInFlight ? SavedState.DISABLED : saveButtonState
            }
          />
        }
        title={"Cart"}
        showIsOptional={true}
        content={
          <Stack
            spacing={1}
            sx={{
              maxWidth: "650px",
            }}
          >
            <LeftRight
              expandLeft={true}
              left={
                <Stack spacing={1} pb={3}>
                  <Typography variant="subtitle1">
                    Open Cart After Update
                  </Typography>
                  <Typography variant="body2">
                    Enable this feature to immediately open the cart after a
                    product is added to the cart.
                  </Typography>
                </Stack>
              }
              right={
                <Switch
                  {...register("openCartAfterUpdate")}
                  defaultChecked={appConfig.openCartAfterUpdate}
                />
              }
            />
            <Typography variant="subtitle1">
              Exclude Cart Items by ID
            </Typography>
            <Typography variant="body2">
              Use the text input field to specify IDs of objects you want to
              ignore in the cart.
            </Typography>
            <Autocomplete
              multiple
              options={defaultOptions}
              freeSolo
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => {
                  return (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  );
                })
              }
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Ignored Product IDs"
                    helperText="IDs must be numbers."
                  />
                );
              }}
              inputValue={productIDsInput}
              onInputChange={(event, value, reason) => {
                // Enter is only use in onChange
                if (reason === "input" && value === "\n") {
                  return;
                }

                if (value.length < productIDsInput.length) {
                  // If deleting, always update
                  setProductIDsInput(value);
                } else if (reason === "reset") {
                  setProductIDsInput("");
                } else if (
                  // Don't allow commas
                  reason === "input" &&
                  !value.includes(",")
                ) {
                  setProductIDsInput(value);
                }

                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
              value={
                productIDs.length === 0 ? defaultOptions : productIDs.split(",")
              }
              onChange={(event, newInputValue) => {
                // Validate input
                for (const value of newInputValue) {
                  if (!Number.isInteger(Number.parseInt(value))) {
                    return;
                  }
                }
                setProductIDs(newInputValue.join(","));
                saveButtonState !== SavedState.ENABLED &&
                  setSaveButtonState(SavedState.ENABLED);
              }}
            />
          </Stack>
        }
      />
    </form>
  );
};

export default CartCard;
