/**
 * @generated SignedSource<<051c32c10a40fc6e9ee403d5f43c1b1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NodeInput = {
  id: string;
};
export type DashboardShopifyRecurringSubscriptionMutation$variables = {
  input: NodeInput;
};
export type DashboardShopifyRecurringSubscriptionMutation$data = {
  readonly createShopifyRecurringSubscription: string;
};
export type DashboardShopifyRecurringSubscriptionMutation = {
  response: DashboardShopifyRecurringSubscriptionMutation$data;
  variables: DashboardShopifyRecurringSubscriptionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "createShopifyRecurringSubscription",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardShopifyRecurringSubscriptionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DashboardShopifyRecurringSubscriptionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3af51f5f3e45f34c8d29156848bf4a45",
    "id": null,
    "metadata": {},
    "name": "DashboardShopifyRecurringSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation DashboardShopifyRecurringSubscriptionMutation(\n  $input: NodeInput!\n) {\n  createShopifyRecurringSubscription(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "e3432d547e05f5ff63c5d9b1ba0103e1";

export default node;
