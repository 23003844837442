/**
 * @generated SignedSource<<ba3121ff3afde5a6602f571e9e252657>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NodeInput = {
  id: string;
};
export type FeedTabTableRowArchiveMutation$variables = {
  input: NodeInput;
};
export type FeedTabTableRowArchiveMutation$data = {
  readonly archiveFeedPost: {
    readonly id: string;
    readonly isArchived: boolean;
  };
};
export type FeedTabTableRowArchiveMutation = {
  response: FeedTabTableRowArchiveMutation$data;
  variables: FeedTabTableRowArchiveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GraphQLFeedPost",
    "kind": "LinkedField",
    "name": "archiveFeedPost",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isArchived",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedTabTableRowArchiveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeedTabTableRowArchiveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4707414cc899b2ff45872085cf6d584b",
    "id": null,
    "metadata": {},
    "name": "FeedTabTableRowArchiveMutation",
    "operationKind": "mutation",
    "text": "mutation FeedTabTableRowArchiveMutation(\n  $input: NodeInput!\n) {\n  archiveFeedPost(input: $input) {\n    id\n    isArchived\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e2ba42819d90340168ef4149557693f";

export default node;
