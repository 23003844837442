/**
 * @generated SignedSource<<7b97fb36103923752764639e4da9cae8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BuildContainerQuery$variables = {};
export type BuildContainerQuery$data = {
  readonly user: {
    readonly isInternal: boolean;
    readonly isStaff: boolean;
  } | null;
};
export type BuildContainerQuery = {
  response: BuildContainerQuery$data;
  variables: BuildContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInternal",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isStaff",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BuildContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7a3d083e88eef7c7a2cb99655a9c29f0",
    "id": null,
    "metadata": {},
    "name": "BuildContainerQuery",
    "operationKind": "query",
    "text": "query BuildContainerQuery {\n  user {\n    isInternal\n    isStaff\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "95207134b871b8a1bb84ffe6b1f19419";

export default node;
