import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useContext } from "react";
import { useMutation } from "react-relay";
import { InternBrandAndroidBuildsActionsPushBuildMutation } from "./__generated__/InternBrandAndroidBuildsActionsPushBuildMutation.graphql";
import CardSection from "../../components/CardSection";
import LeftRight from "../../components/LeftRight";
import SnackbarContext from "../../contexts/SnackbarContext";

const pushBuildMutation = graphql`
  mutation InternBrandAndroidBuildsActionsPushBuildMutation(
    $input: KickoffAndroidAppBuildInput!
  ) {
    kickoffAndroidAppBuild(input: $input) {
      success
      message
    }
  }
`;

const InternBrandAndroidBuildsActions = ({ brandID }: { brandID: string }) => {
  const isStagingEnvironment =
    window.location.hostname.includes("staging") ||
    window.location.hostname.includes("localhost");

  const [pushBuild, isPushBuildInFlight] =
    useMutation<InternBrandAndroidBuildsActionsPushBuildMutation>(
      pushBuildMutation
    );
  const snackbarContext = useContext(SnackbarContext);

  return (
    <CardSection
      title={"Actions"}
      content={
        <Stack spacing={1}>
          <LeftRight
            expandLeft={true}
            left={
              <Stack spacing={1} pb={3}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Build
                </Typography>
                <Typography variant="body2">
                  Trigger a build off the latest commit on the master branch and
                  push to Google Play Console.
                </Typography>
              </Stack>
            }
            right={
              <LoadingButton
                disabled={isPushBuildInFlight}
                variant="outlined"
                onClick={() => {
                  pushBuild({
                    variables: {
                      input: {
                        brandId: brandID,
                        versionBump: "BUILD",
                        env: isStagingEnvironment ? "STAGING" : "PROD",
                        track: "PRODUCTION",
                        releaseStatus: "COMPLETED"
                      },
                    },
                    onCompleted(data, errors) {
                      if (errors) {
                        snackbarContext?.openSnackbar("Build Failed", "error");
                      } else {
                        snackbarContext?.openSnackbar("Build Kicked Off", "success");
                      }
                    },
                  });
                }}
              >
                Push Build
              </LoadingButton>
            }
          />
        </Stack>
      }
      variant="outlined"
    />
  );
};

export default InternBrandAndroidBuildsActions;
