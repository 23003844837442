/**
 * @generated SignedSource<<01182d851c63e48ba93085245cd46f13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CookiesCardCustomInput_trackingConfig$data = {
  readonly consentLocalStorageSettings: ReadonlyArray<{
    readonly enabled: boolean;
    readonly name: string;
  }>;
  readonly consentSessionStorageSettings: ReadonlyArray<{
    readonly enabled: boolean;
    readonly name: string;
  }>;
  readonly consentSettings: ReadonlyArray<{
    readonly enabled: boolean;
    readonly name: string;
  }>;
  readonly nonConsentLocalStorageSettings: ReadonlyArray<{
    readonly enabled: boolean;
    readonly name: string;
  }>;
  readonly nonConsentSessionStorageSettings: ReadonlyArray<{
    readonly enabled: boolean;
    readonly name: string;
  }>;
  readonly nonConsentSettings: ReadonlyArray<{
    readonly enabled: boolean;
    readonly name: string;
  }>;
  readonly " $fragmentType": "CookiesCardCustomInput_trackingConfig";
};
export type CookiesCardCustomInput_trackingConfig$key = {
  readonly " $data"?: CookiesCardCustomInput_trackingConfig$data;
  readonly " $fragmentSpreads": FragmentRefs<"CookiesCardCustomInput_trackingConfig">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enabled",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CookiesCardCustomInput_trackingConfig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TrackingConfigSetting",
      "kind": "LinkedField",
      "name": "consentSettings",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TrackingConfigSetting",
      "kind": "LinkedField",
      "name": "nonConsentSettings",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TrackingConfigSetting",
      "kind": "LinkedField",
      "name": "consentSessionStorageSettings",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TrackingConfigSetting",
      "kind": "LinkedField",
      "name": "nonConsentSessionStorageSettings",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TrackingConfigSetting",
      "kind": "LinkedField",
      "name": "consentLocalStorageSettings",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TrackingConfigSetting",
      "kind": "LinkedField",
      "name": "nonConsentLocalStorageSettings",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "GraphQLBrandTrackingConfig",
  "abstractKey": null
};
})();

(node as any).hash = "2b0b1c702804764cef63586abc8e8a01";

export default node;
