import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  useFragment,
  useLazyLoadQuery,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import { useNavigate, useNavigationType, useParams } from "react-router-dom";
import {
  ConnectionHandler,
  PayloadError,
  UploadableMap,
  fetchQuery,
} from "relay-runtime";
import * as yup from "yup";
import {
  DailyDealFormCreateMutation,
  DiscountType,
  GraphQLCDPAudienceInput,
  GraphQLDailyDealInput,
} from "./__generated__/DailyDealFormCreateMutation.graphql";
import { DailyDealFormQuery } from "./__generated__/DailyDealFormQuery.graphql";
import {
  DailyDealFormShopifyDiscountCodeQuery,
  DailyDealFormShopifyDiscountCodeQuery$data,
} from "./__generated__/DailyDealFormShopifyDiscountCodeQuery.graphql";
import { DailyDealFormUpdateMutation } from "./__generated__/DailyDealFormUpdateMutation.graphql";
import { DailyDealForm_brand$key } from "./__generated__/DailyDealForm_brand.graphql";
import { CDPAudienceSelectorCard } from "../../../../components/CDPAudienceSelectorCard";
import CardSection from "../../../../components/CardSection";
import { UTMTextField } from "../../../../components/UTMTextField";
import UploadedFileCard from "../../../../components/UploadedFileCard";
import {
  PreviewType,
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../../contexts/MobilePreviewContext";
import SnackbarContext from "../../../../contexts/SnackbarContext";
import { KinnLanguageCode } from "../../../../utils/languageMap";
import { ENGAGE_DAILY_DEALS } from "../../../../utils/routes";
import { validateUrl } from "../../../../utils/validators";
import { DAILY_DEAL_TYPES_INFO_MAP } from "./DailyDealTableRow";


const schema = yup
  .object({
    discountType: yup.string().required("Discount type is required"),
    discountCode: yup.string().required("Discount code is required").trim(),
    startsAt: yup.date().required("Start date is required"),
    endsAt: yup
      .date()
      .required("End date is required")
      .min(yup.ref("startsAt"), "End date must be after start date")
      .min(new Date(), "End date must be in the future")
      .test(
        "is-between-1h-24h",
        "Daily deal period must be between 1h and 24h",
        function (value) {
          const startsAt = this.parent.startsAt;
          return (
            startsAt &&
            value &&
            dayjs(value).diff(dayjs(startsAt), "hours") <= 24 &&
            dayjs(value).diff(dayjs(startsAt), "minutes") >= 60
          );
        }
      ),
    title: yup.string().trim(),
    blurredHeadline: yup.string().required("Blurred headline is required"),
    blurredSubheadline: yup
      .string()
      .required("Blurred subheadline is required")
      .trim(),
    headline: yup.string().required("Headline is required").trim(),
    subheadline: yup.string().required("Subheadline is required").trim(),
    destinationUrl: yup
      .string()
      .required("Destination URL is required")
      .test("is-url-valid", "URL is not valid", (value) => {
        return validateUrl(value);
      })
      .test(
        "is-brand-url",
        "URL must be from the brand domain",
        (value, config) => {
          const brandDomain = config?.options?.context?.brand?.domain;
          return value && brandDomain && value.startsWith(brandDomain);
        }
      )
      .trim(),
    notifTitle: yup.string().required("Notification title is required").trim(),
    notifSubtitle: yup
      .string()
      .required("Notification subtitle is required")
      .trim(),
    notifSchedule: yup
      .date()
      .required("Notification trigger time is required")
      .min(yup.ref("startsAt"), "Trigger time must be after start time")
      .max(yup.ref("endsAt"), "Trigger time must be before end time")
      .test(
        "is-before-30m",
        "Trigger time must be at least 30 minutes before the end time",
        function (value) {
          const endsAt = this.parent.endsAt;
          return (
            endsAt && value && dayjs(endsAt).diff(dayjs(value), "minutes") >= 30
          );
        }
      ),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const query = graphql`
  query DailyDealFormQuery($id: ID!, $skip: Boolean!) {
    dailyDeal(id: $id) @skip(if: $skip) {
      id
      archivedAt
      audienceLanguages
      discountType
      discountCode
      discountUrl
      imageUrl
      startsAt
      endsAt
      title
      blurredHeadline
      blurredSubheadline
      headline
      subheadline
      audiences {
        audienceId
      }
      notification {
        title
        body
        utmCampaign
        timestamp
      }
    }
  }
`;

const brandFragment = graphql`
  fragment DailyDealForm_brand on BrandType {
    id
    domain
    shopifyConfig {
      shopifyUrl
    }
    ...CDPAudienceSelectorCard_brand
  }
`;

const shopifyDiscountCodeQuery = graphql`
  query DailyDealFormShopifyDiscountCodeQuery(
    $brandId: ID!
    $discountCode: String!
  ) {
    shopifyDiscountCode(brandId: $brandId, discountCode: $discountCode) {
      audiences {
        id
        name
      }
      code
      endsAt
      shareableUrl
      startsAt
      summary
      title
      type
    }
  }
`;

const createMutation = graphql`
  mutation DailyDealFormCreateMutation($input: GraphQLDailyDealInput!) {
    createDailyDeal(input: $input) {
      ... on GraphQLDailyDeal {
        id
        ...DailyDealTableRow_dailyDeal
        notification {
          title
          body
          utmCampaign
          timestamp
        }
      }
    }
  }
`;

const updateMutation = graphql`
  mutation DailyDealFormUpdateMutation($input: GraphQLDailyDealInputPartial!) {
    updateDailyDeal(input: $input) {
      ... on GraphQLDailyDeal {
        id
        ...DailyDealTableRow_dailyDeal
        notification {
          title
          body
          utmCampaign
          timestamp
        }
      }
    }
  }
`;

export default function DailyDealForm({
  brand: brandKey,
  onSaveComplete,
}: {
  brand: DailyDealForm_brand$key;
  onSaveComplete?: (dailyDealId: string | null) => void;
}) {
  const environment = useRelayEnvironment();
  const navType = useNavigationType();
  const navigate = useNavigate();
  const dispatch = useMobilePreviewDispatch();
  const snackbarContext = useContext(SnackbarContext);
  const dailyDealId = useParams().id;
  const isEditing = dailyDealId != null;
  const brand = useFragment(brandFragment, brandKey);
  const brandId = brand.id;
  const shopifySubdomain = brand.shopifyConfig?.shopifyUrl?.split(".")[0];
  const data = useLazyLoadQuery<DailyDealFormQuery>(query, {
    id: dailyDealId ?? "",
    skip: !isEditing,
  });
  const dailyDeal = data.dailyDeal;
  const notification = dailyDeal?.notification ?? null;
  const [utmCampaign, setUtmCampaign] = useState<string | null>(
    notification?.utmCampaign ?? null
  );
  const publishTime = notification?.timestamp ?? null;
  const isNotificationPublished =
    publishTime !== null && dayjs(publishTime).isBefore(dayjs());

  const [createDailyDeal, isCreateMutationInFlight] =
    useMutation<DailyDealFormCreateMutation>(createMutation);
  const [updateDailyDeal, isUpdateMutationInFlight] =
    useMutation<DailyDealFormUpdateMutation>(updateMutation);

  const [shopifyDiscountInfo, setShopifyDiscountInfo] = useState<
    DailyDealFormShopifyDiscountCodeQuery$data["shopifyDiscountCode"] | null
  >(null);
  const [isLoadingDiscountInfo, setIsLoadingDiscountInfo] = useState(false);
  const isInputDisabled =
    isLoadingDiscountInfo ||
    isCreateMutationInFlight ||
    isUpdateMutationInFlight ||
    shopifyDiscountInfo == null;

  const [imageUrl, setImageUrl] = useState<string | null>(
    dailyDeal?.imageUrl ?? null
  );
  const [imageUploadable, setImageUploadable] = useState<UploadableMap>({});

  // - AUDIENCES
  const [audienceIDs, setAudienceIDs] = useState<string[]>(
    (dailyDeal?.audiences ?? []).map((audience) => audience.audienceId)
  );

  const [audienceDatas, setAudienceDatas] = useState<
    GraphQLCDPAudienceInput[] | null
  >(null);
  const onAudiencesChange = useCallback(
    (audiences: GraphQLCDPAudienceInput[]) => {
      setAudienceIDs(audiences.map((audience) => audience.audienceId));
      setAudienceDatas(
        audiences.map((audience) => ({
          audienceId: audience.audienceId,
          audienceName: audience.audienceName,
          audienceType: audience.audienceType,
          cdpType: audience.cdpType,
        }))
      );
    },
    [setAudienceIDs, setAudienceDatas]
  );

  const [audienceLanguages, setAudienceLanguages] = useState<
    KinnLanguageCode[]
  >(dailyDeal?.audienceLanguages?.slice() ?? []);
  const onLanguageFiltersChange = (languages: KinnLanguageCode[]) => {
    setAudienceLanguages(languages);
  };

  // - FORM
  const defaultPreviewValues = {
    title: dailyDeal?.title ?? undefined,
    blurredHeadline:
      dailyDeal?.blurredHeadline ?? "Reveal the deal - and be ready to buy",
    blurredSubheadline:
      dailyDeal?.blurredSubheadline ??
      "Swipe to reveal to the deal, then get $time_left to act before it expires",
    headline: dailyDeal?.headline ?? "",
    subheadline: dailyDeal?.subheadline ?? "",
  };
  const formMethods = useForm<FormData>({
    defaultValues: {
      discountType: dailyDeal?.discountType ?? "",
      discountCode: dailyDeal?.discountCode ?? "",
      destinationUrl: dailyDeal?.discountUrl ?? "",
      startsAt: dailyDeal?.startsAt ?? null,
      endsAt: dailyDeal?.endsAt ?? null,
      notifTitle: notification?.title ?? "Reveal today’s daily deal",
      notifSubtitle:
        notification?.body ??
        "Swipe to reveal today’s daily deal and get $time_left to act on it",
      notifSchedule: notification?.timestamp ?? dailyDeal?.startsAt ?? null,
      ...defaultPreviewValues,
    },
    resolver: yupResolver(schema),
    context: { brand: brand },
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    resetField,
    watch,
  } = formMethods;

  const startsAt = watch("startsAt") ? dayjs(watch("startsAt")) : null;
  const endsAt = watch("endsAt") ? dayjs(watch("endsAt")) : null;
  const [minDateTime, setMinDateTime] = useState<Dayjs | null>(null);
  const [maxDateTime, setMaxDateTime] = useState<Dayjs | null>(null);
  const isEditable = !dailyDeal?.id || dayjs(dailyDeal.endsAt).isAfter(dayjs());

  // - FETCH DISCOUNT CODE INFO
  useEffect(() => {
    if (shopifyDiscountInfo == null) {
      return;
    }
    if (audienceIDs.length === 0 && !!shopifyDiscountInfo.audiences) {
      setAudienceIDs(
        shopifyDiscountInfo.audiences.map((audience) => audience.id)
      );
      setAudienceDatas(
        shopifyDiscountInfo.audiences.map((audience) => ({
          audienceId: audience.id,
          audienceName: audience.name,
          audienceType: "SEGMENT",
          cdpType: "SHOPIFY",
        }))
      );
    }

    resetField("discountType", { defaultValue: shopifyDiscountInfo.type });

    const shopifyStartsAt = dayjs(shopifyDiscountInfo.startsAt);
    if (!startsAt || startsAt.isBefore(shopifyStartsAt)) {
      resetField("startsAt", {
        defaultValue: shopifyDiscountInfo.startsAt,
      });
    }
    setMinDateTime(shopifyStartsAt);

    const shopifyEndsAt = dayjs(shopifyDiscountInfo.endsAt);
    if (!endsAt || endsAt.isAfter(shopifyEndsAt)) {
      resetField("endsAt", {
        defaultValue: shopifyDiscountInfo.endsAt,
      });
    }
    setMaxDateTime(shopifyEndsAt);

    const notifSchedule = watch("notifSchedule");
    if (
      !notifSchedule ||
      dayjs(notifSchedule).isAfter(shopifyDiscountInfo.endsAt) ||
      dayjs(notifSchedule).isBefore(shopifyDiscountInfo.startsAt)
    ) {
      resetField("notifSchedule", {
        defaultValue: shopifyDiscountInfo.startsAt,
      });
    }

    const headline = watch("headline");
    if (!headline) {
      resetField("headline", {
        defaultValue: shopifyDiscountInfo.summary,
      });
    }

    const destinationUrl = watch("destinationUrl");
    if (!destinationUrl) {
      resetField("destinationUrl", {
        defaultValue: shopifyDiscountInfo.shareableUrl,
      });
    }

    dispatch({
      type: ReducerAction.UPDATE_DAILY_DEAL,
      payload: {
        dailyDeal: {
          headline: shopifyDiscountInfo.summary ?? "",
        },
      },
    });
  }, [shopifyDiscountInfo]);

  const queryShopifyDiscountCode = useCallback(
    ({ brandId, discountCode }: { brandId: string; discountCode: string }) => {
      if (!discountCode) {
        return;
      }
      setIsLoadingDiscountInfo(true);
      fetchQuery<DailyDealFormShopifyDiscountCodeQuery>(
        environment,
        shopifyDiscountCodeQuery,
        {
          brandId: brandId,
          discountCode: discountCode,
        }
      ).subscribe({
        next: (response) => {
          setIsLoadingDiscountInfo(false);
          if (!response.shopifyDiscountCode.endsAt) {
            setDiscountFetchError("Discount code has no end date set");
            return;
          }
          const endsAt = dayjs(response.shopifyDiscountCode.endsAt);
          const startsAt = dayjs(response.shopifyDiscountCode.startsAt);
          if (endsAt.isAfter(startsAt.add(1, "day"))) {
            setDiscountFetchError("Discount code must be between 1h and 24h");
          }
          setShopifyDiscountInfo(response.shopifyDiscountCode);
        },
        error: (error: any) => {
          setIsLoadingDiscountInfo(false);
          console.error(error);
          if (
            "messageFormat" in error &&
            typeof error.messageFormat === "string"
          ) {
            const lines = error.messageFormat.split("\n");
            if (lines.length > 1) {
              snackbarContext?.openSnackbar(lines[1], "error");
            }
          }
          setDiscountFetchError(
            'Failed to retrieve the discount code info. If the code is correct, make sure to enable it for Kin in the "Sales channels" section of Shopify.'
          );
        },
      });
    },
    [environment]
  );

  useEffect(() => {
    if (!dailyDeal?.id || !dailyDeal.discountCode || !isEditable) {
      return;
    }
    queryShopifyDiscountCode({
      brandId: brandId,
      discountCode: dailyDeal.discountCode,
    });
  }, [dailyDeal?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const [discountFetchError, setDiscountFetchError] = useState<string | null>(
    null
  );
  const discountCodeError = errors?.discountCode?.message ?? discountFetchError;
  const onChangeDiscountCode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputCode = event.target.value;
      setDiscountFetchError(null);
      debounce(() => {
        if (event.target.value !== inputCode) {
          return;
        }
        queryShopifyDiscountCode({
          brandId: brandId,
          discountCode: inputCode,
        });
      }, 1000)();
    },
    [brandId, queryShopifyDiscountCode]
  );

  // - PREVIEW
  useEffect(() => {
    dispatch({
      type: ReducerAction.SET_PREVIEW_TYPE,
      payload: {
        previewType: PreviewType.DAILY_DEAL,
        dailyDeal: {
          ...defaultPreviewValues,
          imageUrl: imageUrl ?? "",
        },
      },
    });
    return () => {
      dispatch({ type: ReducerAction.RESET });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // - ACTIONS
  const onSubmit = (data: FormData) => {
    const onError = (error: Error) => {
      snackbarContext?.openSnackbar("Something went wrong", "error");
    };
    const onCompleted = (id: string, errors: PayloadError[] | null) => {
      if (errors) {
        snackbarContext?.openSnackbar("Something went wrong", "error");
        return;
      }
      setImageUploadable({});
      onSaveComplete?.(id);
      onBackClick();
      snackbarContext?.openSnackbar(
        isEditing ? "Daily Deal updated" : "Daily Deal created",
        "success"
      );
    };

    const commonData: Omit<GraphQLDailyDealInput, "brand"> = {
      discountType: data.discountType as DiscountType,
      discountCode: data.discountCode,
      discountUrl:
        data.destinationUrl ?? shopifyDiscountInfo?.shareableUrl ?? "",
      startsAt: data.startsAt,
      endsAt: data.endsAt,
      title: data.title,
      blurredHeadline: data.blurredHeadline,
      blurredSubheadline: data.blurredSubheadline,
      headline: data.headline,
      subheadline: data.subheadline,
      audiences: audienceDatas ?? undefined,
      audienceLanguages: audienceLanguages,
      isNotifEnabled: true,
      notifTitle: data.notifTitle,
      notifSubtitle: data.notifSubtitle,
      notifUtmCampaign: utmCampaign,
      notifTimestamp: data.notifSchedule,
      imageUploadable: imageUploadable["imageUploadable"],
    };

    if (isEditing) {
      updateDailyDeal({
        variables: {
          input: {
            id: dailyDealId,
            ...commonData,
          },
        },
        onCompleted: (data, errors) =>
          onCompleted(data.updateDailyDeal.id, errors),
        onError: onError,
        uploadables: imageUploadable,
      });
    } else {
      createDailyDeal({
        variables: {
          input: {
            brand: {
              id: brandId,
            },
            ...commonData,
          },
        },
        onCompleted: (data, errors) =>
          onCompleted(data.createDailyDeal.id, errors),
        onError: onError,
        updater: (store) => {
          const payload = store.getRootField("createDailyDeal");
          const brand = store.get(brandId);
          if (brand == null) {
            return;
          }
          const tableConnection = ConnectionHandler.getConnection(
            brand,
            "DailyDealsTable_dailyDeals",
            {
              filters: {
                audienceIds: [],
                audienceLanguages: [],
              },
            }
          );
          if (tableConnection == null) {
            console.log("*** tableConnection is null");
            return;
          }

          const newEdge = ConnectionHandler.createEdge(
            store,
            tableConnection,
            payload,
            "GraphQLDailyDealEdge"
          );
          ConnectionHandler.insertEdgeBefore(tableConnection, newEdge);

          // Increment total count
          const totalCount = tableConnection.getValue("totalCount");
          if (totalCount != null) {
            tableConnection.setValue(Number(totalCount) + 1, "totalCount");
          }
        },
        uploadables: imageUploadable,
      });
    }
  };

  const onBackClick = () => {
    if (navType === "PUSH") {
      navigate(-1);
    } else {
      navigate(ENGAGE_DAILY_DEALS);
    }
  };

  const promoDetailsCard = (
    <CardSection
      title={"Promotion Details"}
      lockedContent={isEditable ? undefined : "Daily deal has ended"}
      content={
        <Stack spacing={2} width="100%">
          <InputLabel>
            Generate your discount code on{" "}
            <a
              href={`https://admin.shopify.com/store/${shopifySubdomain}/discounts`}
              target="__blank"
            >
              Shopify
            </a>{" "}
            and paste it here:
          </InputLabel>
          <TextField
            {...register("discountCode")}
            error={!!discountCodeError}
            helperText={discountCodeError}
            margin="normal"
            label={"Discount Code"}
            variant="outlined"
            onChange={onChangeDiscountCode}
          />

          <Divider />

          <Controller
            render={({ field }) => {
              const { onChange, ...fieldProps } = field;
              return (
                <FormControl fullWidth error={!!errors?.discountType}>
                  <InputLabel>Discount Type</InputLabel>
                  <Select
                    {...fieldProps}
                    displayEmpty
                    label="Discount Type"
                    disabled
                    onChange={(event) => {
                      onChange(event);
                    }}
                    renderValue={(value) => {
                      return DAILY_DEAL_TYPES_INFO_MAP[
                        value as keyof typeof DAILY_DEAL_TYPES_INFO_MAP
                      ]?.label;
                    }}
                    error={!!errors?.discountType}
                  >
                    {Object.entries(DAILY_DEAL_TYPES_INFO_MAP).map(
                      ([type, info]) => {
                        if (type === "%future added value") {
                          return null;
                        }
                        return (
                          <MenuItem key={type} value={type}>
                            <Typography variant="body1">
                              {`${info.label} -`}&nbsp;
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {info.description}
                            </Typography>
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                  {!!errors?.discountType && (
                    <FormHelperText>
                      {errors.discountType?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              );
            }}
            control={control}
            name="discountType"
          />

          <Stack
            direction="row"
            sx={{
              alignItems: "center",
            }}
            spacing={2}
          >
            <Controller
              render={({ field }) => {
                const { onChange, value, disabled, ref, ...fieldProps } = field;
                const dayjsValue = value ? dayjs(value) : null;
                return (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Start At"
                      value={dayjsValue}
                      minDateTime={minDateTime}
                      maxDateTime={maxDateTime?.add(-1, "hour")}
                      disabled={disabled || isInputDisabled}
                      onChange={(newTime) => {
                        onChange(newTime?.toDate());
                      }}
                      slots={{
                        textField: (fieldProps) => (
                          <TextField
                            {...fieldProps}
                            error={!!errors?.startsAt}
                            helperText={errors?.startsAt?.message}
                            ref={ref}
                            variant="outlined"
                          />
                        ),
                      }}
                      {...fieldProps}
                    />
                  </LocalizationProvider>
                );
              }}
              control={control}
              name="startsAt"
            />
            <Typography>{" - "}</Typography>
            <Controller
              render={({ field }) => {
                const { onChange, value, disabled, ref, ...fieldProps } = field;
                const dayjsValue = value ? dayjs(value) : null;
                return (
                  <Stack
                    direction={"row"}
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        sx={{
                          mr: 1,
                        }}
                        label="End at"
                        value={dayjsValue}
                        minDateTime={startsAt}
                        maxDateTime={maxDateTime}
                        disabled={disabled || isInputDisabled}
                        onChange={(newTime) => {
                          onChange(newTime?.toDate());
                        }}
                        slots={{
                          textField: (fieldProps) => (
                            <TextField
                              {...fieldProps}
                              ref={ref}
                              error={!!errors?.endsAt}
                              helperText={errors?.endsAt?.message}
                              variant="outlined"
                            />
                          ),
                        }}
                        {...fieldProps}
                      />
                    </LocalizationProvider>
                    {dayjsValue?.format("z")}
                  </Stack>
                );
              }}
              control={control}
              name="endsAt"
            />
          </Stack>
        </Stack>
      }
    />
  );

  const promoAppearanceCard = (
    <CardSection
      title={"Promotion Appearance"}
      lockedContent={isEditable ? undefined : "Daily deal has ended"}
      content={
        <Stack spacing={2} width="100%">
          <UploadedFileCard
            fileURL={imageUrl}
            fileType=".svg, .png, .jpg"
            htmlLabel="daily-deal-asset"
            mediaType={"PHOTO"}
            inputLabel="Promotion Image"
            inputText={"Upload a photo"}
            onClose={() => {
              setImageUrl(null);
              setImageUploadable({});
              dispatch({
                type: ReducerAction.UPDATE_DAILY_DEAL,
                payload: {
                  dailyDeal: {
                    imageUrl: "",
                  },
                },
              });
            }}
            onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
              const file = event?.target?.files?.[0];
              if (file == null) {
                return;
              }
              const url = URL.createObjectURL(file);
              dispatch({
                type: ReducerAction.UPDATE_DAILY_DEAL,
                payload: {
                  dailyDeal: {
                    imageUrl: url,
                  },
                },
              });
              setImageUrl(url);
              const newUploadables: UploadableMap = {};
              newUploadables["imageUploadable"] = file;
              setImageUploadable(newUploadables);
            }}
          />

          <InputLabel>1. Title</InputLabel>
          <TextField
            {...register("title")}
            error={!!errors?.title}
            helperText={errors?.title?.message}
            margin="normal"
            variant="outlined"
            placeholder="Daily Deal"
            onChange={(e) => {
              dispatch({
                type: ReducerAction.UPDATE_DAILY_DEAL,
                payload: {
                  dailyDeal: {
                    title: e.target.value,
                  },
                },
              });
            }}
          />

          <InputLabel>2. Blurred Modal</InputLabel>
          <TextField
            {...register("blurredHeadline")}
            error={!!errors?.blurredHeadline}
            helperText={errors?.blurredHeadline?.message}
            margin="normal"
            label={"Headline"}
            variant="outlined"
            onChange={(e) => {
              dispatch({
                type: ReducerAction.UPDATE_DAILY_DEAL,
                payload: {
                  dailyDeal: {
                    blurredHeadline: e.target.value,
                  },
                },
              });
            }}
          />
          <TextField
            {...register("blurredSubheadline")}
            error={!!errors?.blurredSubheadline}
            helperText={errors?.blurredSubheadline?.message}
            margin="normal"
            label={"Sub-headline"}
            variant="outlined"
            onChange={(e) => {
              dispatch({
                type: ReducerAction.UPDATE_DAILY_DEAL,
                payload: {
                  dailyDeal: {
                    blurredSubheadline: e.target.value,
                  },
                },
              });
            }}
          />

          <InputLabel>3. Active Modal</InputLabel>
          <TextField
            {...register("headline")}
            error={!!errors?.headline}
            helperText={errors?.headline?.message}
            margin="normal"
            label="Headline"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              dispatch({
                type: ReducerAction.UPDATE_DAILY_DEAL,
                payload: {
                  dailyDeal: {
                    headline: e.target.value,
                  },
                },
              });
            }}
          />
          <TextField
            {...register("subheadline")}
            error={!!errors?.subheadline}
            helperText={errors?.subheadline?.message}
            margin="normal"
            label="Sub-headline"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              dispatch({
                type: ReducerAction.UPDATE_DAILY_DEAL,
                payload: {
                  dailyDeal: {
                    subheadline: e.target.value,
                  },
                },
              });
            }}
          />

          <TextField
            {...register("destinationUrl")}
            error={!!errors?.destinationUrl}
            helperText={errors?.destinationUrl?.message}
            margin="normal"
            label="Destination URL"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
      }
    />
  );

  // TODO: try reusing/updating AttachedNotification
  const notificationCard = (
    <CardSection
      title={"Notification Settings"}
      lockedContent={
        isNotificationPublished || !isEditable
          ? "Notification has already been sent"
          : undefined
      }
      content={
        <Stack spacing={2} width="100%">
          <Controller
            render={({ field }) => {
              const { onChange, value, disabled, ref, ...fieldProps } = field;
              const dayjsValue = value ? dayjs(value) : null;
              return (
                <Stack
                  direction={"row"}
                  sx={{
                    alignItems: "center",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      sx={{
                        mr: 1,
                      }}
                      minDateTime={startsAt}
                      maxDateTime={endsAt?.add(-30, "minutes")}
                      label="Publish time"
                      value={dayjsValue}
                      disabled={disabled || isInputDisabled}
                      onChange={(newTime) => {
                        onChange(newTime?.toDate());
                      }}
                      slots={{
                        textField: (fieldProps) => (
                          <TextField
                            {...fieldProps}
                            ref={ref}
                            error={!!errors?.notifSchedule}
                            helperText={errors?.notifSchedule?.message}
                            variant="outlined"
                          />
                        ),
                      }}
                      {...fieldProps}
                    />
                  </LocalizationProvider>
                  {dayjsValue?.format("z")}
                </Stack>
              );
            }}
            control={control}
            name="notifSchedule"
          />

          <TextField
            {...register("notifTitle")}
            error={!!errors?.notifTitle}
            helperText={errors?.notifTitle?.message}
            margin="normal"
            label="Title"
            variant="outlined"
          />
          <TextField
            {...register("notifSubtitle")}
            error={!!errors?.notifSubtitle}
            helperText={errors?.notifSubtitle?.message}
            margin="normal"
            label="Subtitle"
            variant="outlined"
          />
          <UTMTextField
            label="UTM Campaign"
            value={utmCampaign ?? undefined}
            onChange={setUtmCampaign}
          />
        </Stack>
      }
    />
  );

  const audienceCard = (
    <CDPAudienceSelectorCard
      audienceIDs={audienceIDs}
      brand={brand}
      subtitle={"Choose who you want to see the daily deal"}
      audienceLanguages={audienceLanguages}
      onAudiencesChange={onAudiencesChange}
      onLanguageFiltersChange={onLanguageFiltersChange}
      disabled={isInputDisabled || !isEditable}
    />
  );

  const saveButton = (
    <Box display="flex" justifyContent="flex-end">
      <Button
        type="submit"
        disabled={imageUrl == null || isInputDisabled}
        variant="contained"
        size="large"
        endIcon={<ArrowForwardIcon color="inherit" />}
      >
        {isEditing ? "Save Changes" : "Create Daily Deal"}
      </Button>
    </Box>
  );
  return (
    <FormProvider {...formMethods}>
      <Stack
        component={"form"}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        spacing={2}
        width="100%"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box display="flex" justifyContent="flex-start">
          <Button
            startIcon={<ArrowBackIcon />}
            variant="text"
            onClick={onBackClick}
          >
            Your Daily Discounts
          </Button>
        </Box>
        {promoDetailsCard}
        {promoAppearanceCard}
        {audienceCard}
        {notificationCard}
        {saveButton}
      </Stack>
    </FormProvider>
  );
}