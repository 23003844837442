import { Sidepane_brandFragment$data } from "../components/layout/__generated__/Sidepane_brandFragment.graphql";

export enum SectionType {
  ANALYTICS = "analytics",
  SETTINGS = "settings",
  LAUNCH_APP = "launch-app",
  BUILD = "build",
  ENGAGE = "engage",
  SETUP = "setup",
}

export const VIDEO_MIMETYPES = ["mp4", "mov"];
export const PHOTO_MIMETYPES = ["jpg", "png", "svg", "jpeg", "gif"];
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const SidepaneConfig: Array<{
  sectionType: SectionType;
  sectionName: string;
  isPublic: boolean;
  sections: Array<{
    title: string;
    pathname: string;
    isPublic: boolean;
    isDisabledPreOnboarded: boolean;
    isFeatureEnabled?: (
      brand: Pick<
        Sidepane_brandFragment$data,
        "featureFlags" | "isForumEnabled"
      >
    ) => boolean;
  }>;
}> = [
  {
    sectionType: SectionType.SETUP,
    sectionName: "Setup",
    isPublic: true,
    sections: [
      {
        title: "Get Started",
        pathname: "/get-started",
        isPublic: true,
        isDisabledPreOnboarded: false,
      },
    ],
  },
  {
    sectionType: SectionType.ANALYTICS,
    sectionName: "Analytics",
    isPublic: true,
    sections: [
      {
        title: "Overview",
        pathname: "/overview",
        isPublic: true,
        isDisabledPreOnboarded: false,
      },
      {
        title: "Notifications",
        pathname: "/notifications",
        isPublic: true,
        isDisabledPreOnboarded: false,
      },
    ],
  },
  {
    sectionType: SectionType.BUILD,
    sectionName: "Build",
    isPublic: true,
    sections: [
      {
        title: "Navigation",
        pathname: "/navigation",
        isPublic: true,
        isDisabledPreOnboarded: false,
      },
      {
        title: "Theme",
        pathname: "/theme",
        isPublic: true,
        isDisabledPreOnboarded: false,
      },
      {
        title: "Mapping",
        pathname: "/mapping",
        isPublic: true,
        isDisabledPreOnboarded: false,
      },
    ],
  },
  {
    sectionType: SectionType.ENGAGE,
    sectionName: "Engage",
    isPublic: true,
    sections: [
      {
        title: "Feed",
        pathname: "/feed",
        isPublic: true,
        isDisabledPreOnboarded: true,
      },
      {
        title: "Highlights",
        pathname: "/highlights",
        isPublic: true,
        isDisabledPreOnboarded: true,
      },
      {
        title: "Daily Deals",
        pathname: "/daily-deals",
        isPublic: true,
        isDisabledPreOnboarded: true,
        isFeatureEnabled: (brand) => brand.featureFlags.dailyDealEnabled,
      },
      {
        title: "Notifications",
        pathname: "/notifications",
        isPublic: true,
        isDisabledPreOnboarded: true,
      },
      {
        title: "Automation",
        pathname: "/automation",
        isPublic: true,
        isDisabledPreOnboarded: true,
      },
      {
        title: "Forum",
        pathname: "/forum",
        isPublic: true,
        isDisabledPreOnboarded: true,
      },
    ],
  },
  {
    sectionType: SectionType.LAUNCH_APP,
    sectionName: "Launch App",
    isPublic: true,
    sections: [
      {
        title: "App Listing",
        pathname: "/app-listing",
        isPublic: true,
        isDisabledPreOnboarded: false,
      },
    ],
  },
  {
    sectionType: SectionType.SETTINGS,
    sectionName: "Settings",
    isPublic: true,
    sections: [
      {
        title: "Admin",
        pathname: "/admin",
        isPublic: true,
        isDisabledPreOnboarded: false,
      },
      {
        title: "Billing",
        pathname: "/billing",
        isPublic: false,
        isDisabledPreOnboarded: false,
      },
      {
        title: "Organization",
        pathname: "/organization",
        isPublic: true,
        isDisabledPreOnboarded: false,
      },
      {
        title: "Shopify",
        pathname: "/shopify",
        isPublic: true,
        isDisabledPreOnboarded: false,
      },
      {
        title: "Meta",
        pathname: "/meta",
        isPublic: true,
        isDisabledPreOnboarded: false,
      },
      {
        title: "Integrations",
        pathname: "/integrations",
        isPublic: true,
        isDisabledPreOnboarded: false,
      },
    ],
  },
];
