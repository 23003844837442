import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Accordion,
  AccordionDetails,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { createRef, useContext, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import {
  fetchQuery,
  usePaginationFragment,
  useRelayEnvironment,
} from "react-relay";
import { InsightsTabNotificationsTablePaginationQuery } from "./__generated__/InsightsTabNotificationsTablePaginationQuery.graphql";
import { InsightsTabNotificationsTableQuery } from "./__generated__/InsightsTabNotificationsTableQuery.graphql";
import {
  InsightsTabNotificationsTable_brand$data,
  InsightsTabNotificationsTable_brand$key,
} from "./__generated__/InsightsTabNotificationsTable_brand.graphql";
import DebugContext from "../../../contexts/DebugContext";
import formatNumber from "../../../utils/formatNumber";

const ddogQuery = graphql`
  query InsightsTabNotificationsTableQuery(
    $brandId: ID!
    $notifUuids: [String!]!
  ) {
    attributedNotifMetrics(brandId: $brandId, notifUuids: $notifUuids) {
      notifUuid
      pushNotificationDeliveries
      pushNotificationTaps
    }
  }
`;

const brandFragment = graphql`
  fragment InsightsTabNotificationsTable_brand on BrandType
  @refetchable(queryName: "InsightsTabNotificationsTablePaginationQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 10 }
    cursor: { type: "String", defaultValue: "" }
  ) {
    id
    currencyCode
    displayName
    notifications(first: $count, after: $cursor, filters: { isPushed: true })
      @connection(key: "InsightsTabNotificationsTable_notifications") {
      edges {
        node {
          id
          uuid
          audiences {
            audienceName
          }
          body
          thumbnailUrl
          timestamp
          title
          attributedNotification {
            attributedSales
            attributedOrders
            pushNotificationDeliveries
            pushNotificationTaps
          }
        }
      }
      totalCount
    }
  }
`;

const InsightsTabNotificationsTableRow = ({
  notif,
  ddogMetrics,
  debug,
  isDdogFetching,
  currencyCode,
}: {
  notif: InsightsTabNotificationsTable_brand$data["notifications"]["edges"][0]["node"];
  ddogMetrics:
    | InsightsTabNotificationsTableQuery["response"]["attributedNotifMetrics"][0]
    | null;
  debug: boolean;
  isDdogFetching: boolean;
  currencyCode: string;
}) => {
  const theme = useTheme();
  const attributedMetrics = notif.attributedNotification;
  const shouldShowDdogLoadingState =
    attributedMetrics === null && isDdogFetching;
  const publishedTime = notif.timestamp;
  const isScheduled = dayjs(publishedTime).isAfter(dayjs());

  const notificationItem = (
    <ListItem>
      <ListItemAvatar>
        <Avatar
          alt=""
          src={notif?.thumbnailUrl ?? undefined}
          variant="rounded"
          sx={{ bgcolor: theme.palette.info.main, color: "black" }}
        >
          <NotificationsNoneIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            variant="body2"
            color={"black"}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {notif.title}
          </Typography>
        }
        secondary={
          <Typography
            variant="body2"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {notif.body}
          </Typography>
        }
      />
    </ListItem>
  );
  const taps = attributedMetrics
    ? attributedMetrics.pushNotificationTaps
    : ddogMetrics?.pushNotificationTaps ?? 0;
  const deliveries = attributedMetrics
    ? attributedMetrics.pushNotificationDeliveries
    : ddogMetrics?.pushNotificationDeliveries ?? 0;

  const datePublished = isScheduled
    ? dayjs(publishedTime).format("llll z")
    : dayjs().to(dayjs(publishedTime));

  let debugComponent = null;
  if (debug) {
    debugComponent = (
      <Box
        sx={{
          position: "absolute",
          zIndex: 1000000,
          left: -16,
          top: -16,
          bgcolor: "rgb(20,184,166, 0.4)",
          fontSize: "8px",
        }}
      >
        <div key={ddogMetrics?.notifUuid}>
          <div>
            <b>ID: {window.atob(notif.id).split(":")[1]}</b>
          </div>
          <div>
            <b>UUID: {ddogMetrics?.notifUuid ?? notif.uuid}</b>
          </div>
          <div>Ddog Deliveries: {ddogMetrics?.pushNotificationDeliveries}</div>
          <div>Ddog Taps: {ddogMetrics?.pushNotificationTaps}</div>
          <div>
            Attrib Deliveries: {attributedMetrics?.pushNotificationDeliveries}
          </div>
          <div>Attrib Taps: {attributedMetrics?.pushNotificationTaps}</div>
        </div>
      </Box>
    );
  }

  return (
    <TableRow
      key={notif.id}
      sx={{
        position: "relative",
      }}
    >
      <TableCell align="center">{notificationItem}</TableCell>
      <TableCell
        align="center"
        sx={{
          display: {
            xs: "none",
            md: "table-cell",
          },
        }}
      >
        <Tooltip
          title={dayjs(publishedTime).format("llll z")}
          placement="top"
          arrow
        >
          <Box>{datePublished}</Box>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Stack
          direction={"column"}
          alignItems={"flex-end"}
          position={"relative"}
        >
          {debugComponent}
          <Stack display={"inline-flex"} direction={"row"} spacing={1}>
            {shouldShowDdogLoadingState ? (
              <Skeleton width={"150px"} />
            ) : (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: "black",
                  }}
                >
                  {formatNumber(deliveries)} Deliveries
                </Typography>
                <Divider orientation="vertical" flexItem />
                <Typography
                  variant="body2"
                  sx={{
                    color: "black",
                  }}
                >
                  {formatNumber(taps)} Clicks
                </Typography>
                <Divider orientation="vertical" flexItem />
                <Typography
                  variant="body2"
                  sx={{
                    color: "black",
                  }}
                >
                  {formatNumber(taps / deliveries, "percent")} CTR
                </Typography>
              </>
            )}
          </Stack>
          <Box>
            {attributedMetrics === null ? (
              <Typography variant="body2">--</Typography>
            ) : (
              <Typography variant="body2">
                {formatNumber(
                  attributedMetrics.attributedSales,
                  "currency",
                  currencyCode
                )}{" "}
                Attributed Revenue
                {attributedMetrics?.attributedOrders
                  ? ` | ${formatNumber(
                      attributedMetrics?.attributedOrders
                    )} Orders`
                  : ""}
              </Typography>
            )}
          </Box>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

const InsightsTabNotificationsTable = ({
  brand: brandKey,
}: {
  brand: InsightsTabNotificationsTable_brand$key;
}) => {
  const environment = useRelayEnvironment();
  const { debug } = useContext(DebugContext);
  const csvLink = createRef<any>();

  const {
    data: brand,
    loadNext,
    isLoadingNext,
    hasNext,
  } = usePaginationFragment<
    InsightsTabNotificationsTablePaginationQuery,
    InsightsTabNotificationsTable_brand$key
  >(brandFragment, brandKey);
  const notifications = brand.notifications.edges;

  const [shouldFetch, setShouldFetch] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [ddogMetrics, setDdogMetrics] = useState<
    Map<
      string,
      {
        notifUuid: string;
        pushNotificationDeliveries: number;
        pushNotificationTaps: number;
      }
    >
  >(new Map());

  // Table Controls
  const [tablePage, setTablePage] = useState(0);
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage > tablePage) {
      loadNext(tableRowsPerPage);
    }
    setTablePage(newPage);
    setShouldFetch(true);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const difference = +event.target.value - tableRowsPerPage;
    if (difference > 0) {
      loadNext(difference);
    }
    setTableRowsPerPage(+event.target.value);
    setTablePage(0);
    setShouldFetch(true);
  };

  const [csvData, setCSVData] = useState<(string | number)[][] | null>(null);
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const ddogNotifUUIDs = useMemo(
    () =>
      notifications
        .slice(
          tablePage * tableRowsPerPage,
          tablePage * tableRowsPerPage + tableRowsPerPage
        )
        .filter((notif) => notif.node.attributedNotification === null)
        .map((notif) => notif.node.uuid),
    [tablePage, tableRowsPerPage, notifications]
  );

  const fetchDdogData = () => {
    fetchQuery<InsightsTabNotificationsTableQuery>(
      environment,
      ddogQuery,
      {
        notifUuids: ddogNotifUUIDs,
        brandId: brand.id,
      },
      {
        fetchPolicy: "store-or-network", // Ensures cached data is used if available
      }
    ).subscribe({
      start: () => {
        setShouldFetch(false);
        setIsFetching(true);
      },
      complete: () => {
        setIsFetching(false);
      },
      error: (_: Error) => {
        setIsFetching(false);
      },
      next: (data) => {
        const newDdogMetrics = new Map();
        data.attributedNotifMetrics.forEach((metric) => {
          const { notifUuid } = metric;
          newDdogMetrics.set(notifUuid, metric);
        });
        setDdogMetrics(newDdogMetrics);
      },
    });
  };

  useEffect(() => {
    fetchDdogData();
  }, []);

  useEffect(() => {
    if (!shouldFetch || ddogNotifUUIDs.length === 0) return;

    fetchDdogData();
  }, [shouldFetch]);

  useEffect(() => {
    if (!isDownloadingCSV) {
      setCSVData(null);
      return;
    }

    if (hasNext) {
      loadNext(10_000);
      setShouldFetch(true);
      return;
    }

    if (isFetching || isLoadingNext) {
      return;
    }

    let numFormatter = new Intl.NumberFormat(undefined, {
      maximumFractionDigits: 2,
      useGrouping: false,
    });

    const data = [
      [
        "Title",
        "Body",
        "Published",
        "Deliveries",
        "Clicks",
        "CTR",
        "Attributed Orders",
        "Attributed Sales",
        "Currency",
      ],
      ...brand.notifications.edges.map((notificationNode, index) => {
        const notif = notificationNode.node;
        const attributedMetrics = notif.attributedNotification;
        const ddogMetricsForNotif = ddogMetrics.get(notif.uuid);
        const taps = attributedMetrics
          ? attributedMetrics.pushNotificationTaps
          : ddogMetricsForNotif?.pushNotificationTaps ?? 0;
        const deliveries = attributedMetrics
          ? attributedMetrics.pushNotificationDeliveries
          : ddogMetricsForNotif?.pushNotificationDeliveries ?? 0;
        return [
          notif.title,
          notif.body,
          dayjs(notif.timestamp).format("YYYY-MM-DD hh:mm"),
          deliveries,
          taps,
          numFormatter.format(deliveries ? (taps / deliveries) * 100 : 0),
          numFormatter.format(attributedMetrics?.attributedOrders ?? 0),
          numFormatter.format(attributedMetrics?.attributedSales ?? 0),
          brand.currencyCode,
        ];
      }),
    ];

    setCSVData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadingCSV, isFetching, isLoadingNext, hasNext]);

  useEffect(() => {
    if (!csvData) {
      return;
    }
    csvLink.current?.link?.click();
    setIsDownloadingCSV(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvData]);

  return (
    <Accordion
      sx={{
        "&.Mui-disabled": {
          backgroundColor: "rgba(0,0,0,0)",
        },
      }}
      expanded={isExpanded}
      elevation={0}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <MuiAccordionSummary
        sx={{
          borderBottom: "1px solid #E5E7EB",
          borderRadius: "4px",
        }}
        expandIcon={<ExpandMoreIcon sx={{ color: "#9CA3AF" }} />}
      >
        <Typography variant="subtitle1">Pushed Notifications</Typography>
        <Button
          variant="text"
          disabled={isDownloadingCSV}
          onClick={(e) => {
            e.stopPropagation();
            setIsDownloadingCSV(true);
          }}
          size="small"
        >
          <FileDownloadOutlinedIcon />
        </Button>
        <CSVLink
          data={csvData ?? [[]]}
          hidden
          filename={`${brand.displayName
            .replace(/[^a-z0-9]/gi, "_")
            .toLowerCase()}_notifications_${dayjs().format("YYYYMMDD")}`}
          onClick={(e: any) => e.stopPropagation()}
          ref={csvLink}
        >
          Download CSV
        </CSVLink>
      </MuiAccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0",
        }}
      >
        <Paper sx={{ width: "100%", borderRadius: "8px" }} elevation={0}>
          <TableContainer>
            <Table
              sx={{ tableLayout: "fixed" }}
              aria-label="simple table"
              size="small"
            >
              <TableBody>
                {notifications
                  .slice(
                    tablePage * tableRowsPerPage,
                    tablePage * tableRowsPerPage + tableRowsPerPage
                  )
                  .map((notificationNode) => (
                    <InsightsTabNotificationsTableRow
                      key={notificationNode.node.id}
                      notif={notificationNode.node}
                      ddogMetrics={
                        ddogMetrics.get(notificationNode.node.uuid) ?? null
                      }
                      isDdogFetching={isFetching}
                      debug={debug}
                      currencyCode={brand.currencyCode}
                    />
                  ))}
                {isLoadingNext && hasNext
                  ? notifications.length <
                      (tablePage + 1) * tableRowsPerPage && (
                      <TableCell
                        colSpan={3}
                        align="center"
                        sx={{
                          padding: "24px 0",
                        }}
                      >
                        <CircularProgress color="primary" size={32} />
                      </TableCell>
                    )
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={brand.notifications.totalCount ?? notifications.length}
            rowsPerPage={tableRowsPerPage}
            page={tablePage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

export default InsightsTabNotificationsTable;
