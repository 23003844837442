/**
 * @generated SignedSource<<d557cfc7bbf122e53ca068280afa57b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopNavBar_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CurrentUser_userFragment">;
  readonly " $fragmentType": "TopNavBar_user";
};
export type TopNavBar_user$key = {
  readonly " $data"?: TopNavBar_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopNavBar_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopNavBar_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrentUser_userFragment"
    }
  ],
  "type": "UserType",
  "abstractKey": null
};

(node as any).hash = "f787e8e90e3fb49e321888e0551ea7b3";

export default node;
