import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import grey from "@mui/material/colors/grey";
import { graphql } from "babel-plugin-relay/macro";
import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { InternBrandQuery } from "./__generated__/InternBrandQuery.graphql";
import nullthrows from "../../utils/nullthrows";
import InternBrandAdsHealth from "./InternBrandAdsHealth";
import InternBrandAndroidBuilds from "./InternBrandAndroidBuilds";
import InternBrandBilling from "./InternBrandBilling";
import InternBrandOverview from "./InternBrandOverview";
import InternBrandiOSBuilds from "./InternBrandiOSBuilds";
import InternBrandAdsAnalysis from "./ads/InternBrandAdsAnalysis";

const query = graphql`
  query InternBrandQuery($id: ID!) {
    brand(id: $id) {
      id
      displayName
      appConfig {
        appState
      }
      ...InternBrandBilling_brand
      ...InternBrandiOSBuilds_brand
      ...InternBrandAndroidBuilds_brand
    }
  }
`;

const InternBrand = () => {
  const brandID = useParams().id;
  const navigate = useNavigate();
  const location = useLocation();

  const data = useLazyLoadQuery<InternBrandQuery>(query, {
    id: nullthrows(brandID),
  });
  const brand = data.brand;
  const appConfig = brand?.appConfig;
  const appState = appConfig.appState;

  const chipColor =
    appState === "PENDING_KINN_SETUP"
      ? "error"
      : appState === "PENDING_BRAND_SETUP"
      ? "warning"
      : "success";

  return (
    <Stack spacing={2}>
      <Box display="flex" justifyContent="flex-start">
        <Button
          startIcon={<ArrowBackIcon />}
          variant="text"
          onClick={() => navigate("/intern/brands/")}
        >
          Home
        </Button>
      </Box>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          borderBottom: `1px solid ${grey[200]}`,
          pb: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">{brand.displayName}</Typography>
          <Chip
            sx={{
              marginLeft: "16px",
            }}
            color={chipColor}
            label={appState}
            variant="outlined"
          />
        </Box>
        {appState === "PENDING_KINN_SETUP" && (
          <Button variant="contained" disableElevation>
            Unlock
          </Button>
        )}
      </Stack>

      <Tabs
        value={location.pathname}
        onChange={(_, value) => {
          navigate(value);
        }}
        defaultValue={`/intern/brands/${brandID}/overview`}
      >
        <Tab
          value={`/intern/brands/${brandID}/overview`}
          label={"Overview"}
          key={0}
        />
        <Tab
          value={`/intern/brands/${brandID}/billing`}
          label={"Billing"}
          key={1}
        />
        <Tab
          value={`/intern/brands/${brandID}/ios_builds`}
          label={"iOS Builds"}
          key={2}
        />
        <Tab
          value={`/intern/brands/${brandID}/android_builds`}
          label={"Android Builds"}
          key={3}
        />
        <Tab
          value={`/intern/brands/${brandID}/ads_health`}
          label={"[Beta] Ads Health"}
          key={4}
        />
        <Tab
          value={`/intern/brands/${brandID}/ads_analysis`}
          label={"[Beta] Ads Analysis"}
          key={4}
        />
      </Tabs>
      <Routes>
        <Route
          path="/overview"
          element={
            <Suspense fallback={null}>
              <InternBrandOverview />
            </Suspense>
          }
        />
        <Route
          path="/billing"
          element={
            <Suspense fallback={null}>
              <InternBrandBilling brand={brand} />
            </Suspense>
          }
        />
        <Route
          path="/ios_builds"
          element={
            <Suspense fallback={null}>
              <InternBrandiOSBuilds brand={brand} />
            </Suspense>
          }
        />
        <Route
          path="/android_builds"
          element={
            <Suspense fallback={null}>
              <InternBrandAndroidBuilds brand={brand} />
            </Suspense>
          }
        />
        <Route
          path="/ads_health"
          element={
            <Suspense
              fallback={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              }
            >
              <InternBrandAdsHealth brandID={nullthrows(brandID)} />
            </Suspense>
          }
        />
        <Route
          path="/ads_analysis"
          element={
            <Suspense
              fallback={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              }
            >
              <InternBrandAdsAnalysis brandID={nullthrows(brandID)} />
            </Suspense>
          }
        />
      </Routes>
    </Stack>
  );
};

export default InternBrand;
