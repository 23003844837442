import { Box, Button, Card, CardMedia, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useMobilePreviewState } from "../../contexts/MobilePreviewContext";

const CarouselPreview = () => {
  const { carousel } = useMobilePreviewState();
  const [activeIndex, setActiveIndex] = useState(0);
  const activeItem = carousel.items?.[activeIndex] ?? null;
  const mediaUrl = activeItem?.fallbackMediaUrl || activeItem?.mediaUrl || null;

  return (
    <Card
      sx={{
        borderRadius: "12px",
        position: "relative",
      }}
    >
      {mediaUrl == null ? (
        <div
          style={{
            background: "#C4C4C4",
            aspectRatio: "1 / 1",
          }}
        />
      ) : (
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Stack
            direction={"row"}
            spacing={1}
            p={1}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 10,
              color: "white",
            }}
          >
            {carousel.items.map((item, index) => {
              return (
                <Button
                  key={index}
                  sx={{
                    py: "8px",
                    cursor: "pointer",
                    background: "transparent",
                    "&:hover": {
                      background: "transparent",
                    },
                    "& .MuiTouchRipple-root": {
                      color: "white",
                    },
                    height: "2px",
                    width: "100%",
                  }}
                  onClick={() => {
                    setActiveIndex(index);
                  }}
                  color="inherit"
                >
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "100px",
                      height: "2px",
                      width: "100%",
                      "&:hover": {
                        backgroundColor: "white",
                        opacity: 1,
                      },
                      opacity: index === activeIndex ? 1 : 0.4,
                    }}
                  />
                </Button>
              );
            })}
          </Stack>
          <CardMedia
            sx={{
              background: "#C4C4C4",
              aspectRatio: "1",
            }}
            component={activeItem.mediaType === "VIDEO" ? "video" : "img"}
            image={mediaUrl ?? undefined}
          />
          <Box
            sx={{
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%)",
              position: "absolute",
              top: "50%",
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              padding: 2,
            }}
          >
            <Box>
              <Typography
                color="white"
                variant="header0"
                sx={{
                  overflow: "hidden",
                  lineHeight: "24px",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  verticalAlign: "bottom",
                }}
              >
                {activeItem.title || "Item Title"}
              </Typography>
              <Typography
                color="white"
                variant="body2"
                sx={{
                  lineHeight: "17px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {activeItem.subtitle || "Item Subtitle"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%)",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: "50%",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          padding: 2,
        }}
      >
        <Typography
          variant="header0"
          component={"div"}
          marginTop={"12px"}
          sx={{
            lineHeight: "24px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
          }}
        >
          {carousel.title || "Title: 1 line max"}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            lineHeight: "17px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
          }}
        >
          {carousel.subtitle || "Subtitle: 1 line max"}
        </Typography>
      </Box>
    </Card>
  );
};

export default CarouselPreview;
