/**
 * @generated SignedSource<<e9014532f2367e0293d2afc033d9d34b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InsightsLifetimeSection_brand$data = {
  readonly brandMetricSummary: {
    readonly latestDate: string;
    readonly summary: {
      readonly appOrders: number;
      readonly appPurchasers: number;
      readonly appRepeatPurchasers: number;
      readonly appSales: number;
      readonly appUsers: number;
    } | null;
    readonly summary1d: {
      readonly appOrders: number;
      readonly appPurchasers: number;
      readonly appRepeatPurchasers: number;
      readonly appSales: number;
      readonly appUsers: number;
    } | null;
    readonly summary30d: {
      readonly appOrders: number;
      readonly appPurchasers: number;
      readonly appRepeatPurchasers: number;
      readonly appSales: number;
      readonly appUsers: number;
    } | null;
    readonly summary7d: {
      readonly appOrders: number;
      readonly appPurchasers: number;
      readonly appRepeatPurchasers: number;
      readonly appSales: number;
      readonly appUsers: number;
    } | null;
  };
  readonly currencyCode: string;
  readonly " $fragmentType": "InsightsLifetimeSection_brand";
};
export type InsightsLifetimeSection_brand$key = {
  readonly " $data"?: InsightsLifetimeSection_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"InsightsLifetimeSection_brand">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "appSales",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "appOrders",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "appUsers",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "appPurchasers",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "appRepeatPurchasers",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InsightsLifetimeSection_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currencyCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLBrandMetricSummary",
      "kind": "LinkedField",
      "name": "brandMetricSummary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLBrandMetricLifetime",
          "kind": "LinkedField",
          "name": "summary",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLBrandMetricLifetime",
          "kind": "LinkedField",
          "name": "summary1d",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLBrandMetricLifetime",
          "kind": "LinkedField",
          "name": "summary7d",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLBrandMetricLifetime",
          "kind": "LinkedField",
          "name": "summary30d",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "5ca8729308cd3c64fed7abfa0188e8ae";

export default node;
