/**
 * @generated SignedSource<<e4dd5b9a5baa8ecb410b46036dc8d5a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationFormAIQuery$variables = {
  url: string;
};
export type NotificationFormAIQuery$data = {
  readonly aiGenNotifData: {
    readonly description: string;
    readonly imageUrl: string | null;
    readonly title: string;
  } | null;
};
export type NotificationFormAIQuery = {
  response: NotificationFormAIQuery$data;
  variables: NotificationFormAIQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "url"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "url",
        "variableName": "url"
      }
    ],
    "concreteType": "AINotifData",
    "kind": "LinkedField",
    "name": "aiGenNotifData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "imageUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationFormAIQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationFormAIQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1dc0a3bbfe9df8cfb2a3618502ebbdc8",
    "id": null,
    "metadata": {},
    "name": "NotificationFormAIQuery",
    "operationKind": "query",
    "text": "query NotificationFormAIQuery(\n  $url: String!\n) {\n  aiGenNotifData(url: $url) {\n    title\n    description\n    imageUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "da6543c648e05008e360fe815710c2da";

export default node;
