import { InputAdornment, TextField } from "@mui/material";
import { TextFieldProps } from "@shopify/polaris";
import { useState } from "react";

export const UTMTextField = ({
  value,
  label,
  maxLength = 100,
  onChange,
}: {
  maxLength?: number;
  onChange: (campaign: string | null) => void;
} & Pick<TextFieldProps, "value" | "label">) => {
  const [error, setError] = useState("");
  return (
    <TextField
      onChange={(event) => {
        const newValue = event.target.value;

        if (newValue.match(/^[a-zA-Z0-9_+-]*$/)) {
          setError("");
          onChange(newValue || null);
        } else {
          setError(
            "Can only enter alphanumeric characters, as well as _ (underscore), - (dash) and + (plus)."
          );
        }
      }}
      helperText={error}
      sx={{
        flexGrow: 1,
      }}
      margin="normal"
      label={label}
      variant="outlined"
      value={value ?? ""}
      inputProps={{
        maxLength: maxLength,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value != null ? value.length : 0}/{maxLength}
          </InputAdornment>
        ),
      }}
    />
  );
};
