import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import TableRowPlaceholder from "./TableRowPlaceholder";

const TablePlaceholder = () => {
  return (
    <Paper sx={{ width: "100%", mb: 2, borderRadius: "8px" }} elevation={0}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, tableLayout: "fixed" }}
          aria-label="simple table"
          size="small"
        >
          <TableBody>
            {[...Array(10).keys()].map((_, index) => (
              <TableRowPlaceholder key={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TablePlaceholder;
