import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { AppColorPalette } from "../views/dashboard/build_section/__generated__/ThemeTab_brand.graphql";
import getColorMapping from "../utils/getColorMapping";
import ThemeContext from "../views/dashboard/build_section/contexts/ThemeContext";

export type AppColorPaletteTransparent = AppColorPalette | "TRANSPARENT";

const ColorPalette: AppColorPalette[] = [
  "ACCENT_1",
  "ACCENT_2",
  "ACCENT_3",
  "PRIMARY",
  "BLACK",
  "WHITE",
  "GRAY",
];

const TransparentColorPalette: AppColorPaletteTransparent[] = [
  "TRANSPARENT",
  ...ColorPalette,
];

export enum InputType {
  "PRIMARY_BUTTON_BACKGROUND" = "Primary Button Background Color",
  "PRIMARY_BUTTON_FOREGROUND" = "Primary Button Foreground Color",
  "SECONDARY_OUTLINE" = "Secondary Button Outline/Icon Color",
  "PRIMARY_PILL_BACKGROUND" = "Primary Pill Background Color",
  "PRIMARY_PILL_FOREGROUND" = "Primary Pill Foreground Color",
  "SECONDARY_PILL_OUTLINE" = "Secondary Pill Outline/Icon Color",
  "PILL_CONTAINER_BACKGROUND" = "Pill Container Background Color",
  "BADGE_BACKGROUND" = "Badge Background Color",
  "BADGE_FOREGROUND" = "Badge Foreground Color",
  "NAVIGATION_BACKGROUND" = "Navigation Background Color",
  "NAVIGATION_FOREGROUND" = "Navigation Foreground Color",
  "LAUNCH_SCREEN_BACKGROUND_COLOR" = "Launch Screen Background Color",
  "ONBOARDING_BACKGROUND" = "Onboarding Background",
  "ONBOARDING_FOREGROUND" = "Onboarding Foreground",
}

const getPaletteNameMapping = (palette: AppColorPaletteTransparent) => {
  switch (palette) {
    case "PRIMARY":
      return "Primary";
    case "ACCENT_1":
      return "Accent 1";
    case "ACCENT_2":
      return "Accent 2";
    case "ACCENT_3":
      return "Accent 3";
    case "BLACK":
      return "Black";
    case "WHITE":
      return "White";
    case "GRAY":
      return "Gray";
    case "TRANSPARENT":
      return "Transparent";
  }
};

type Props = {
  defaultPalette: AppColorPalette;
  palette: AppColorPalette | null;
  inputType: InputType;
  onChange: (color: AppColorPalette | null) => void;
  transparentOptionEnabled?: Boolean;
};

const ColorDropdownSelector = (props: Props) => {
  const {
    defaultPalette,
    palette,
    inputType,
    onChange,
    transparentOptionEnabled,
  } = props;
  const {
    primaryPaletteLight,
    accent1PaletteLight,
    accent2PaletteLight,
    accent3PaletteLight,
  } = useContext(ThemeContext);
  // If 3 color palette set for the first time, set to default variant
  const isFullPaletteSet =
    primaryPaletteLight !== "" &&
    accent1PaletteLight !== "" &&
    accent2PaletteLight !== "" &&
    accent3PaletteLight;
  useEffect(() => {
    if (!transparentOptionEnabled && isFullPaletteSet && palette === null) {
      onChange(defaultPalette);
    }
  }, [
    onChange,
    palette,
    defaultPalette,
    isFullPaletteSet,
    transparentOptionEnabled,
  ]);

  const adjustedPalette: AppColorPaletteTransparent | null = useMemo(() => {
    if (transparentOptionEnabled && palette === null) {
      return "TRANSPARENT";
    }
    return palette;
  }, [palette, transparentOptionEnabled]);

  const menuItems = (
    transparentOptionEnabled ? TransparentColorPalette : ColorPalette
  ).map((palette: AppColorPaletteTransparent, index: number) => {
    const color = getColorMapping(
      palette,
      primaryPaletteLight,
      accent1PaletteLight,
      accent2PaletteLight,
      accent3PaletteLight
    );
    if (color === "") {
      return null;
    }
    const label = getPaletteNameMapping(palette);

    return (
      <MenuItem value={palette} key={index}>
        <Box
          sx={{
            bgcolor: color,
            height: 24,
            width: 24,
            borderRadius: "4px",
            border:
              color === "#FFFFFF"
                ? "0.5px solid rgba(0, 0, 0, 0.2)"
                : undefined,
            mr: 1,
          }}
        />
        {label}
      </MenuItem>
    );
  });

  const onSelectInputChange = useCallback(
    (event: SelectChangeEvent) => {
      const variant = event.target.value;
      if (variant === "TRANSPARENT") {
        onChange(null);
      } else {
        onChange(variant as AppColorPalette | null);
      }
    },
    [onChange]
  );

  return (
    <FormControl fullWidth>
      <InputLabel id={inputType}>{inputType}</InputLabel>
      <Tooltip
        title={!isFullPaletteSet ? "Setup your color palette above" : ""}
        placement="top-start"
        arrow
      >
        <Select
          sx={{ "& .MuiInputBase-input": { display: "flex" } }}
          disabled={!isFullPaletteSet}
          label={inputType}
          onChange={onSelectInputChange}
          value={adjustedPalette ?? ""}
        >
          {menuItems}
        </Select>
      </Tooltip>
    </FormControl>
  );
};

export default ColorDropdownSelector;
