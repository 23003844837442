/**
 * @generated SignedSource<<5d01f7d8b115b5e6e5a2e91c779eac33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type InsightsTabNotificationsTableQuery$variables = {
  brandId: string;
  notifUuids: ReadonlyArray<string>;
};
export type InsightsTabNotificationsTableQuery$data = {
  readonly attributedNotifMetrics: ReadonlyArray<{
    readonly notifUuid: string;
    readonly pushNotificationDeliveries: number;
    readonly pushNotificationTaps: number;
  }>;
};
export type InsightsTabNotificationsTableQuery = {
  response: InsightsTabNotificationsTableQuery$data;
  variables: InsightsTabNotificationsTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "notifUuids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "brandId",
        "variableName": "brandId"
      },
      {
        "kind": "Variable",
        "name": "notifUuids",
        "variableName": "notifUuids"
      }
    ],
    "concreteType": "GraphQLAttributedNotificationDdogMetric",
    "kind": "LinkedField",
    "name": "attributedNotifMetrics",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "notifUuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pushNotificationDeliveries",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pushNotificationTaps",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InsightsTabNotificationsTableQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InsightsTabNotificationsTableQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d4fcb607f6481d29f1d252dbe149a5c7",
    "id": null,
    "metadata": {},
    "name": "InsightsTabNotificationsTableQuery",
    "operationKind": "query",
    "text": "query InsightsTabNotificationsTableQuery(\n  $brandId: ID!\n  $notifUuids: [String!]!\n) {\n  attributedNotifMetrics(brandId: $brandId, notifUuids: $notifUuids) {\n    notifUuid\n    pushNotificationDeliveries\n    pushNotificationTaps\n  }\n}\n"
  }
};
})();

(node as any).hash = "c12e24d3b544b1da078eb254abac1022";

export default node;
