/**
 * @generated SignedSource<<fc4138114462c9797a261f7a8c407178>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PlanStatus = "ACTIVE" | "CANCELLED" | "DECLINED" | "EXPIRED" | "FROZEN" | "NO_PLAN" | "PENDING" | "%future added value";
export type GraphQLOneTimePurchaseChargeInputPartial = {
  billingConfig: NodeInput;
  id?: string | null;
  isBlocking?: boolean | null;
  name?: string | null;
  price: any;
  status: PlanStatus;
};
export type NodeInput = {
  id: string;
};
export type InternBrandBillingOneTimePurchaseCardMutation$variables = {
  input: GraphQLOneTimePurchaseChargeInputPartial;
};
export type InternBrandBillingOneTimePurchaseCardMutation$data = {
  readonly createOneTimePurchase: {
    readonly chargeId: string | null;
    readonly createdAt: any | null;
    readonly currency: string;
    readonly id: string;
    readonly isBlocking: boolean;
    readonly name: string | null;
    readonly price: any;
    readonly status: PlanStatus;
  };
};
export type InternBrandBillingOneTimePurchaseCardMutation = {
  response: InternBrandBillingOneTimePurchaseCardMutation$data;
  variables: InternBrandBillingOneTimePurchaseCardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "input"
      }
    ],
    "concreteType": "GraphQLOneTimePurchaseCharge",
    "kind": "LinkedField",
    "name": "createOneTimePurchase",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isBlocking",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "chargeId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InternBrandBillingOneTimePurchaseCardMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InternBrandBillingOneTimePurchaseCardMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0440e799ee901d8976eeaef6e6a79529",
    "id": null,
    "metadata": {},
    "name": "InternBrandBillingOneTimePurchaseCardMutation",
    "operationKind": "mutation",
    "text": "mutation InternBrandBillingOneTimePurchaseCardMutation(\n  $input: GraphQLOneTimePurchaseChargeInputPartial!\n) {\n  createOneTimePurchase(data: $input) {\n    id\n    isBlocking\n    status\n    currency\n    chargeId\n    price\n    createdAt\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "a29635c59e24ecec98022c5aabf8b9d3";

export default node;
