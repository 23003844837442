import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import { Suspense } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AutomationTab from "./AutomationTab";
import InterstitialNotificationsTab from "./automation/interstitial-notifications/InterstitialNotificationsTab";

type AutomationConfig = {
  title: string;
  pathname: string;
  element: (brandID: string) => React.ReactElement;
};

const automationConfig: AutomationConfig[] = [
  {
    title: "Push Notifications",
    pathname: "push-notifications",
    element(brandID) {
      return <AutomationTab brandID={brandID} key={brandID} />;
    },
  },
  {
    title: "Interstitial Notifications",
    pathname: "interstitial-notifications",
    element(brandID) {
      return <InterstitialNotificationsTab brandID={brandID} key={brandID} />;
    },
  },
];

const AutomationTabContainer = ({
  brandID,
  areInterstitialNotificationsEnabled,
}: {
  brandID: string;
  areInterstitialNotificationsEnabled: boolean;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathParts = location.pathname.split("/").filter((part) => part !== "");
  const path = "/" + pathParts.join("/");
  const basePath = "/" + pathParts.slice(0, 2).join("/");
  const subPath = pathParts.slice(2).join("/");

  if (!areInterstitialNotificationsEnabled) {
    return automationConfig[0].element(brandID);
  }

  return (
    <Box>
      <Box
        sx={{
          marginBottom: "32px",
          marginTop: "-32px",
          backgroundColor: "white",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={
            path === basePath
              ? `${basePath}/${automationConfig[0].pathname}`
              : basePath +
                "/" +
                automationConfig.find((config) =>
                  subPath.startsWith(config.pathname)
                )?.pathname
          }
          onChange={(event, value) => {
            navigate(value);
          }}
        >
          {automationConfig.map((config, index) => {
            return (
              <Tab
                label={config.title}
                value={`${basePath}/${config.pathname}`}
                key={config.pathname}
              />
            );
          })}
        </Tabs>
      </Box>
      <Routes>
        <Route
          path={"/"}
          element={<Navigate to={automationConfig[0].pathname} />}
        />

        {automationConfig.map((config, index) => {
          return (
            <Route
              path={`${config.pathname}/*`}
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  }
                >
                  {config.element(brandID)}
                </Suspense>
              }
              key={config.pathname}
            />
          );
        })}
      </Routes>
    </Box>
  );
};

export default AutomationTabContainer;
