import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import dayjs, { Dayjs } from "dayjs";
import { FocusEvent, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { useParams } from "react-router-dom";
import { Uploadable, UploadableMap } from "relay-runtime";
import * as yup from "yup";
import {
  GraphQLCDPAudienceInput,
  KinnLanguageCode,
} from "../__generated__/HighlightsTabCreateAlbumMutation.graphql";
import { FeedPollForm_brand$key } from "./__generated__/FeedPollForm_brand.graphql";
import { FeedPollForm_feedPost$key } from "./__generated__/FeedPollForm_feedPost.graphql";
import {
  AttachedNotification,
  isValidAttachedNotification,
} from "../../../../components/AttachedNotification";
import { CDPAudienceSelectorCard } from "../../../../components/CDPAudienceSelectorCard";
import CardSection from "../../../../components/CardSection";
import LeftRight from "../../../../components/LeftRight";
import RighthandDrawer from "../../../../components/RighthandDrawer";
import UploadedFileCard from "../../../../components/UploadedFileCard";
import {
  PreviewType,
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../../contexts/MobilePreviewContext";
import useFeedPostCreateMutation from "../../../../mutations/useFeedPostCreateMutation";
import useFeedPostUpdateMutation from "../../../../mutations/useFeedPostUpdateMutation";
import nullthrows from "../../../../utils/nullthrows";

const schema = yup
  .object({
    pollTitle: yup.string().required("Poll title is required").trim(),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const brandFragment = graphql`
  fragment FeedPollForm_brand on BrandType {
    id
    feed {
      id
    }
    ...useFeedPostCreateMutation_brand
    ...useFeedPostUpdateMutation_brand
    ...CDPAudienceSelectorCard_brand
  }
`;

const feedPostFragment = graphql`
  fragment FeedPollForm_feedPost on GraphQLFeedPost {
    id
    audiences {
      audienceId
    }
    audienceLanguages
    publishedAt
    notification {
      title
      body
      utmCampaign
    }
    poll {
      id
      title
      media {
        url
      }
      customResponsesEnabled
      pollOptions {
        id
        title
        isCustomerCreatedOption
        isStatic
        media {
          url
        }
      }
    }
  }
`;

const PollOption = ({
  isStatic,
  shouldHideDelete,
  title,
  onDelete,
  onEdit,
  onTitleChange,
}: {
  isStatic?: boolean;
  shouldHideDelete: boolean;
  title: string;
  onDelete: () => void;
  onEdit: () => void;
  onTitleChange: (event: FocusEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <TextField
        disabled={isStatic}
        required
        sx={{
          flexGrow: 1,
        }}
        margin="normal"
        id="outlined-basic"
        label={"Option Title"}
        variant="outlined"
        value={title}
        onChange={onTitleChange}
        inputProps={{
          maxLength: 60,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {title != null ? title.length : 0}/60
            </InputAdornment>
          ),
        }}
      />
      <Button
        size="small"
        variant="text"
        color={"primary"}
        onClick={onEdit}
        disabled={isStatic}
      >
        Edit
      </Button>
      {shouldHideDelete ? null : (
        <Button size="small" variant="text" color="error" onClick={onDelete}>
          Delete
        </Button>
      )}
    </Stack>
  );
};

const FeedPollForm = ({
  brand: brandKey,
  feedPost: feedPostKey,
  onBackClick,
  onUpdateComplete,
}: {
  brand: FeedPollForm_brand$key;
  feedPost: FeedPollForm_feedPost$key | null;
  onBackClick: () => void;
  onUpdateComplete: (postID?: string) => void;
}) => {
  const dispatch = useMobilePreviewDispatch();
  const postID = useParams().id;
  const isEditing = postID != null;

  // Data Loading
  const brand = useFragment(brandFragment, brandKey);
  const feedPost = useFragment(feedPostFragment, feedPostKey);
  const brandID = brand.id;
  const feedID = brand?.feed?.id ?? null;
  const poll = feedPost?.poll ?? null;
  const pollID = poll?.id ?? null;

  const [pollTitle, setPollTitle] = useState<string>(poll?.title ?? "");

  const addOption = () => {
    setPollOptions(
      pollOptions.concat([
        {
          title: "",
          thumbnail: null,
        },
      ])
    );

    dispatch({
      type: ReducerAction.UPDATE_POLL,
      payload: {
        poll: {
          options: pollOptions
            .map((item) => ({
              title: item.title,
              media: item.thumbnail ?? "",
            }))
            .concat([
              {
                title: "",
                media: "",
              },
            ]),
        },
      },
    });
  };

  const [mediaUrl, setMediaUrl] = useState<string | null>(
    poll?.media?.url ?? null
  );
  const [mediaUploadables, setMediaUploadables] = useState<UploadableMap>({});
  const [allowCustomInput, setAllowCustomInput] = useState(
    poll?.customResponsesEnabled ?? false
  );
  const onToggleCustomInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllowCustomInput(event.target.checked);
  };
  const options = poll?.pollOptions ?? [];
  const [pollOptions, setPollOptions] = useState(
    options.length === 0
      ? [
          {
            title: "",
            thumbnail: null,
          },
          {
            title: "",
            thumbnail: null,
          },
        ]
      : options.map(
          (
            option
          ): {
            id?: string;
            title: string;
            thumbnail: string | null;
            file?: Uploadable;
            isStatic?: boolean;
          } => {
            return {
              id: option.id,
              title: option?.title ?? "",
              thumbnail: option?.media?.url ?? null,
              isStatic: option?.isStatic,
            };
          }
        )
  );

  useEffect(() => {
    if (isEditing) {
      dispatch({
        type: ReducerAction.RESET,
      });
      dispatch({
        type: ReducerAction.SET_PREVIEW_TYPE,
        payload: {
          previewType: PreviewType.POLL,
        },
      });
      dispatch({
        type: ReducerAction.UPDATE_POLL,
        payload: {
          poll: {
            title: pollTitle,
            media: mediaUrl ?? "",
            customResponsesEnabled: allowCustomInput,
            options: pollOptions.map((item, index) => ({
              title: item.title,
              media: item.thumbnail ?? "",
            })),
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [audienceIDs, setAudienceIDs] = useState<string[]>(
    (feedPost?.audiences ?? []).map((audience) => audience.audienceId)
  );
  const [audienceDatas, setAudienceDatas] = useState<
    GraphQLCDPAudienceInput[] | null
  >(null);
  const onAudiencesChange = useCallback(
    (audiences: GraphQLCDPAudienceInput[]) => {
      setAudienceIDs(audiences.map((audience) => audience.audienceId));
      setAudienceDatas(
        audiences.map((audience) => ({
          audienceId: audience.audienceId,
          audienceName: audience.audienceName,
          audienceType: audience.audienceType,
          cdpType: audience.cdpType,
        }))
      );
    },
    [setAudienceIDs, setAudienceDatas]
  );
  const [audienceLanguages, setAudienceLanguages] = useState<
    KinnLanguageCode[]
  >(feedPost?.audienceLanguages?.slice() ?? []);
  const onLanguageFiltersChange = useCallback(
    (languages: KinnLanguageCode[]) => {
      setAudienceLanguages(languages);
    },
    [setAudienceLanguages]
  );

  // Publish Preferences
  const publishTime = feedPost?.publishedAt ?? null;
  const isPostPublished =
    publishTime !== null && dayjs(publishTime).isBefore(dayjs());
  const [publishLater, setPublishLater] = useState(
    isEditing && !isPostPublished
  );
  const onTogglePublishLater = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPublishLater(event.target.checked);
  };

  const initialTime = publishTime ? dayjs(publishTime) : dayjs().add(1, "day");
  const [dateTime, setDateTime] = useState<Dayjs | null>(initialTime);
  const minDateTime = dayjs().add(1, "hour");
  const maxDateTime = dayjs().add(3, "months");

  const [notification, setNotification] = useState(
    feedPost?.notification ?? null
  );
  const notifEnabled = notification !== null;

  // Right hand drawer props
  const [drawerPollIndex, setDrawerPollIndex] = useState<number>(-1);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [drawerMediaUrl, setDrawerMediaUrl] = useState<string | null>(null);
  const [drawerMediaFile, setDrawerMediaFile] = useState<Uploadable | null>(
    null
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const onDrawerOpen = useCallback(
    (index: number) => {
      setDrawerPollIndex(index);
      setDrawerTitle(pollOptions[index].title);
      setDrawerMediaUrl(pollOptions[index].thumbnail);
      setIsDrawerOpen(true);
    },
    [setDrawerPollIndex, setDrawerTitle, setIsDrawerOpen, pollOptions]
  );
  const onDrawerClose = useCallback(() => {
    setDrawerPollIndex(-1);
    setDrawerTitle("");
    setDrawerMediaUrl(null);
    setIsDrawerOpen(false);
  }, [setDrawerPollIndex, setDrawerTitle, setIsDrawerOpen]);

  const [createPoll, isCreating] = useFeedPostCreateMutation(brand);
  const [updatePoll, isUpdating] = useFeedPostUpdateMutation(brand);
  const isMutationInFlight = isCreating || isUpdating;

  // Form Validation Logic
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>({
    defaultValues: {
      pollTitle: pollTitle,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: FormData) => {
    const input = {
      audienceLanguages,
      isNotifEnabled: notifEnabled,
      notifTitle: notification?.title,
      notifSubtitle: notification?.body,
      notifUtmCampaign: notification?.utmCampaign,
    };
    if (isEditing) {
      updatePoll(
        {
          ...input,
          id: postID,
          publishedAt: isPostPublished
            ? undefined
            : publishLater
            ? dateTime
            : dayjs(),
          audiences:
            audienceDatas === null
              ? undefined
              : audienceDatas.map((audience) => ({
                  audienceId: audience.audienceId,
                  audienceName: audience.audienceName,
                  audienceType: audience.audienceType,
                  cdpType: audience.cdpType,
                })),
          poll: {
            id: nullthrows(pollID),
            brand: {
              id: brandID,
            },
            pollOptions: pollOptions.map((option, index) => ({
              title: option.title,
              id: option.id,
              // set null to remove, undefined to default to uploadables below
              mediaUploadable: option.thumbnail === null ? null : undefined,
              isStatic: option.isStatic,
            })),
            customResponsesEnabled: allowCustomInput,
            title: pollTitle,
          },
        },
        (data) => {
          setMediaUploadables({});
          onUpdateComplete(data.updateFeedPost.id);
          onBackClick();
        },
        {
          ...mediaUploadables,
          ...Object.fromEntries(
            pollOptions
              .map((option, index) => [
                `poll.pollOptions.${index}.mediaUploadable`,
                option.file ?? null,
              ])
              .filter((option) => option[1] !== null)
          ),
        }
      );
    } else {
      createPoll(
        {
          ...input,
          publishedAt: publishLater ? dateTime : dayjs(),
          audiences:
            audienceDatas === null
              ? []
              : audienceDatas.map((audience) => ({
                  audienceId: audience.audienceId,
                  audienceName: audience.audienceName,
                  audienceType: audience.audienceType,
                  cdpType: audience.cdpType,
                })),
          feed: {
            id: feedID,
          },
          poll: {
            customResponsesEnabled: allowCustomInput,
            brand: {
              id: brandID,
            },
            pollOptions: pollOptions.map((option, index) => ({
              title: option.title,
              isStatic: option.isStatic,
            })),
            title: pollTitle,
          },
        },
        (data) => {
          setMediaUploadables({});
          onUpdateComplete(data.createFeedPost.id);
          onBackClick();
        },
        {
          ...mediaUploadables,
          ...Object.fromEntries(
            pollOptions
              .map((option, index) => [
                `poll.pollOptions.${index}.mediaUploadable`,
                option.file ?? null,
              ])
              .filter((option) => option[1] !== null)
          ),
        }
      );
    }
  };

  const customizeCard = (
    <CardSection
      title={"Customize post"}
      content={
        <Stack spacing={1} width="100%">
          <Typography variant="overline">1. Add media</Typography>
          <UploadedFileCard
            fileURL={mediaUrl}
            fileType=".svg, .png, .jpg"
            htmlLabel="feed-post-poll-form"
            mediaType={"PHOTO"}
            inputLabel="Media Asset"
            inputText={"Upload a photo"}
            onClose={() => {
              setMediaUrl(null);
              delete mediaUploadables["poll.mediaUploadable"];

              dispatch({
                type: ReducerAction.UPDATE_POLL,
                payload: {
                  poll: {
                    media: "",
                  },
                },
              });
            }}
            onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
              const file = event?.target?.files?.[0];
              if (file == null) {
                return;
              }

              const url = URL.createObjectURL(file);
              setMediaUrl(url);
              setMediaUploadables({
                ...mediaUploadables,
                "poll.mediaUploadable": file,
              });

              dispatch({
                type: ReducerAction.UPDATE_POLL,
                payload: {
                  poll: {
                    media: url,
                  },
                },
              });
            }}
          />

          <Typography variant="overline">2. Customize Content</Typography>
          <TextField
            {...register("pollTitle", {
              onChange: (event: FocusEvent<HTMLInputElement>) => {
                setPollTitle(event.target.value);

                dispatch({
                  type: ReducerAction.UPDATE_POLL,
                  payload: {
                    poll: {
                      title: event.target.value,
                    },
                  },
                });
              },
            })}
            error={!!errors?.pollTitle}
            helperText={errors?.pollTitle?.message}
            margin="normal"
            label={"Poll Title"}
            variant="outlined"
            value={pollTitle}
            inputProps={{
              maxLength: 80,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {pollTitle != null ? pollTitle.length : 0}/80
                </InputAdornment>
              ),
            }}
          />

          <Typography variant="overline">
            3. Customize Options (2 Minimum)
          </Typography>
          <Stack>
            {pollOptions.map((option, index) => {
              const onTextChange = (event: FocusEvent<HTMLInputElement>) => {
                const newArray = Array.from(pollOptions);
                newArray[index] = {
                  ...newArray[index],
                  title: event.target.value,
                };
                setPollOptions(newArray);

                dispatch({
                  type: ReducerAction.UPDATE_POLL,
                  payload: {
                    poll: {
                      options: newArray.map((item, index) => ({
                        title: item.title,
                        media: item.thumbnail ?? "",
                      })),
                    },
                  },
                });
              };
              const onDelete = () => {
                const newArray = Array.from(pollOptions);
                newArray.splice(index, 1);
                setPollOptions(newArray);

                dispatch({
                  type: ReducerAction.UPDATE_POLL,
                  payload: {
                    poll: {
                      options: newArray.map((item) => ({
                        title: item.title,
                        media: item.thumbnail ?? "",
                      })),
                    },
                  },
                });
              };
              return (
                <PollOption
                  key={index}
                  isStatic={option.isStatic}
                  shouldHideDelete={pollOptions.length <= 2}
                  title={option.title}
                  onDelete={onDelete}
                  onEdit={() => onDrawerOpen(index)}
                  onTitleChange={onTextChange}
                />
              );
            })}
          </Stack>
          <Button
            disabled={false}
            fullWidth={true}
            size="large"
            variant="outlined"
            onClick={addOption}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} />
            Add Option
          </Button>
          <Button
            disabled={false}
            fullWidth={true}
            size="large"
            variant="outlined"
            onClick={() => {
              setPollOptions(
                pollOptions.concat([
                  {
                    title: "Other",
                    thumbnail: null,
                    isStatic: true,
                  },
                ])
              );

              dispatch({
                type: ReducerAction.UPDATE_POLL,
                payload: {
                  poll: {
                    options: pollOptions
                      .map((item) => ({
                        title: item.title,
                        media: item.thumbnail ?? "",
                        isStatic: item.isStatic,
                      }))
                      .concat([
                        {
                          title: "Other",
                          media: "",
                          isStatic: true,
                        },
                      ]),
                  },
                },
              });
            }}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} />
            Add "Other" Option
          </Button>
          <LeftRight
            right={
              <Typography variant="subtitle1">
                Allow respondents to add their own response
              </Typography>
            }
            left={
              <Switch
                checked={allowCustomInput}
                onChange={onToggleCustomInput}
              />
            }
          />
        </Stack>
      }
    />
  );

  const audienceCard = (
    <CDPAudienceSelectorCard
      audienceIDs={audienceIDs}
      brand={brand}
      subtitle={"Choose an audience to share your post with:"}
      audienceLanguages={audienceLanguages}
      onAudiencesChange={onAudiencesChange}
      onLanguageFiltersChange={onLanguageFiltersChange}
    />
  );

  const notifAndPublishCard = (
    <CardSection
      showIsOptional
      title={"Set publish preferences"}
      content={
        <Stack spacing={2} width={"100%"}>
          <LeftRight
            expandLeft={true}
            left={
              <Stack spacing={1}>
                <Typography variant="subtitle1">Publish later</Typography>
                <Typography variant="body2">
                  Schedule the highlight to publish anytime between an hour from
                  now to 3 months out
                </Typography>
              </Stack>
            }
            right={
              <Switch checked={publishLater} onChange={onTogglePublishLater} />
            }
          />
          {publishLater && (
            <Stack
              direction={"row"}
              component="form"
              sx={{
                alignItems: "center",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{
                    mr: 1,
                  }}
                  minDateTime={minDateTime}
                  maxDateTime={maxDateTime}
                  value={dateTime}
                  onChange={(newTime) => {
                    setDateTime(newTime);
                  }}
                />
              </LocalizationProvider>
              {dateTime?.format("z")}
            </Stack>
          )}

          <AttachedNotification
            notification={notification}
            onNotificationChange={setNotification}
            shouldShowUTM={false}
          />
        </Stack>
      }
    />
  );

  let saveButtonText = "Publish";
  if (isEditing) {
    saveButtonText = false ? "Saving" : "Save";
  } else {
    saveButtonText = isCreating ? "Publishing" : "Publish";
  }

  const isPublishButtonDisabled =
    mediaUrl === null ||
    pollOptions.length < 2 ||
    pollOptions.some((option) => option.title === "") ||
    (publishLater && (!dateTime?.isValid() || minDateTime.isAfter(dateTime))) ||
    !isValidAttachedNotification(notification);
  const publishButton = (
    <Box display="flex" justifyContent="flex-end">
      <Button
        type="submit"
        disabled={isPublishButtonDisabled || isMutationInFlight}
        variant="contained"
        startIcon={
          isMutationInFlight ? (
            <CircularProgress color="inherit" size={16} />
          ) : undefined
        }
      >
        {saveButtonText}
      </Button>
    </Box>
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      {customizeCard}
      {audienceCard}
      {isPostPublished ? null : notifAndPublishCard}
      {publishButton}
      <RighthandDrawer
        isOpen={isDrawerOpen}
        title="Edit Option"
        onClose={onDrawerClose}
      >
        <Stack
          sx={{
            padding: 2,
          }}
          spacing={3}
        >
          <TextField
            required
            sx={{
              flexGrow: 1,
            }}
            margin="normal"
            label={"Option Title"}
            variant="outlined"
            value={drawerTitle}
            onChange={(event: FocusEvent<HTMLInputElement>) => {
              setDrawerTitle(event.target.value);
            }}
            inputProps={{
              maxLength: 60,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {drawerTitle != null ? drawerTitle.length : 0}/60
                </InputAdornment>
              ),
            }}
          />
          <Stack spacing={0}>
            <Typography variant="overline">Add thumbnail (optional)</Typography>
            <Typography variant="body2">
              Consider attaching a thumbnail to the poll option to help your
              customers have more context on what they are voting on.
            </Typography>
          </Stack>
          <UploadedFileCard
            fileURL={drawerMediaUrl}
            fileType=".svg, .png, .jpg"
            htmlLabel="feed-post-poll-option-form"
            mediaType={"PHOTO"}
            inputLabel="Media Asset"
            inputText={"Upload a photo"}
            onClose={() => {
              setDrawerMediaUrl(null);
              setDrawerMediaFile(null);
            }}
            onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
              const file = event?.target?.files?.[0];
              if (file == null) {
                return;
              }

              setDrawerMediaUrl(URL.createObjectURL(file));
              setDrawerMediaFile(file);
            }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: 2,
          }}
        >
          <Button
            size="large"
            variant="outlined"
            color="error"
            onClick={onDrawerClose}
          >
            Cancel
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              const newArray = Array.from(pollOptions);
              newArray[drawerPollIndex] = {
                ...newArray[drawerPollIndex],
                title: drawerTitle,
                thumbnail: drawerMediaUrl,
                file: drawerMediaFile ?? undefined,
              };
              setPollOptions(newArray);
              dispatch({
                type: ReducerAction.UPDATE_POLL,
                payload: {
                  poll: {
                    options: newArray.map((item, index) => ({
                      title: item.title,
                      media: item.thumbnail ?? "",
                    })),
                  },
                },
              });
              onDrawerClose();
            }}
          >
            Save Changes
          </Button>
        </Stack>
      </RighthandDrawer>
    </form>
  );
};

export default FeedPollForm;
