import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { flushSync } from "react-dom";
import { useFieldArray, useForm } from "react-hook-form";
import {
  fetchQuery,
  useFragment,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import * as yup from "yup";
import { MenuCardMutation } from "./__generated__/MenuCardMutation.graphql";
import { MenuCardQuery } from "./__generated__/MenuCardQuery.graphql";
import { MenuCard_brand$key } from "./__generated__/MenuCard_brand.graphql";
import CardSection from "../../../../components/CardSection";
import LeftRight from "../../../../components/LeftRight";
import SaveButton, { SavedState } from "../../../../components/SaveButton";
import {
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../../contexts/MobilePreviewContext";
import SnackbarContext from "../../../../contexts/SnackbarContext";
import { validateUrl } from "../../../../utils/validators";

const importQuery = graphql`
  query MenuCardQuery($brandID: ID!) {
    menuItemsImported(brandId: $brandID) {
      title
      destinationUrl
      index
      children {
        title
        destinationUrl
        index
        children {
          title
          destinationUrl
          index
        }
      }
    }
  }
`;

const brandFragment = graphql`
  fragment MenuCard_brand on BrandType {
    id
    appConfig {
      id
      shouldLockMenus
      nestedMenuItems {
        title
        destinationUrl
        index
        children {
          title
          destinationUrl
          index
          children {
            title
            destinationUrl
            index
          }
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation MenuCardMutation($input: UpdateMenuItemsInput!) {
    updateMenuItems(input: $input) {
      ... on BrandType {
        ...MenuCard_brand
      }
    }
  }
`;

export type MenuItem = {
  destinationUrl?: string;
  title: string;
  items?: Array<MenuItem>;
};

const menuSchema: yup.ObjectSchema<MenuItem> = yup.object({
  title: yup.string().required("Required").trim(),
  destinationUrl: yup
    .string()
    .optional()
    .max(2083, "URL must be less than 2083 characters")
    .trim()
    .test("is-url-valid", "URL is not valid", (value) => {
      if (value == null) {
        return true;
      }
      return validateUrl(value);
    }),
  items: yup
    .array()
    .optional()
    .of(
      yup.object({
        title: yup.string().required("Required").trim(),
        destinationUrl: yup
          .string()
          .optional()
          .max(2083, "URL must be less than 2083 characters")
          .trim()
          .test("is-url-valid", "URL is not valid", (value) => {
            if (value == null) {
              return true;
            }
            return validateUrl(value);
          }),
        items: yup
          .array()
          .optional()
          .of(
            yup.object({
              title: yup.string().required("Required").trim(),
              destinationUrl: yup
                .string()
                .optional()
                .max(2083, "URL must be less than 2083 characters")
                .trim()
                .test("is-url-valid", "URL is not valid", (value) => {
                  if (value == null) {
                    return true;
                  }
                  return validateUrl(value);
                }),
            })
          ),
      })
    ),
});
const schema = yup
  .object({
    items: yup.array().of(menuSchema).required(),
  })
  .required();
type FormValues = yup.InferType<typeof schema>;

type Props = {
  brand: MenuCard_brand$key;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  paddingTop: "12px",
  // change background colour if dragging
  // background: "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const MenuItemRow = ({
  droppableIndex,
  sectionIndex,
  control,
  register,
  errors,
  isMenuItem,
  onDragEnd,
  baseArrayName,
}: {
  droppableIndex: string;
  sectionIndex: number;
  control: any;
  register: any;
  errors: any;
  isMenuItem: boolean;
  onDragEnd: (
    sectionIndex: string,
    reorderCallback: (from: number, to: number) => void
  ) => void;
  baseArrayName: string;
}) => {
  const depth = droppableIndex.split(".").length;

  const arrayName = `${baseArrayName}.${sectionIndex}.items`;
  const {
    fields,
    remove: removeItem,
    append: appendItem,
    move,
  } = useFieldArray({
    control,
    name: arrayName,
  });

  useEffect(() => {
    if (droppableIndex && !isMenuItem) {
      onDragEnd(droppableIndex, (from: number, to: number) => {
        move(from, to);
      });
    }
  }, [move, onDragEnd, droppableIndex, isMenuItem]);

  if (depth > 2) {
    return null;
  }

  return (
    <Stack ml={5} spacing={1}>
      <Droppable
        droppableId={"menu-item-droppable-" + droppableIndex}
        type={"CHILD-" + droppableIndex}
      >
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {fields.map((field, index) => {
              // @ts-ignore
              const insideIsMenuItem = field.destinationUrl != null;

              return (
                <Draggable key={field.id} draggableId={field.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="stretch"
                        sx={{
                          background: "#FDFDFE",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <DragIndicatorIcon />
                        </Box>
                        <TextField
                          {...register(`${arrayName}.${index}.title` as const, {
                            maxLength: 255,
                          })}
                          sx={{
                            flexGrow: 1,
                          }}
                          inputProps={{
                            maxLength: 255,
                          }}
                          margin="normal"
                          id="outlined-basic"
                          label={"Menu Title"}
                          variant="outlined"
                          error={!!errors?.items?.[index]?.title}
                          helperText={errors?.items?.[index]?.title?.message}
                        />
                        {insideIsMenuItem && (
                          <TextField
                            {...register(
                              `${arrayName}.${index}.destinationUrl` as const
                            )}
                            sx={{
                              flexGrow: 1,
                              "& .MuiInputBase-input": {
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              },
                            }}
                            margin="normal"
                            id="outlined-basic"
                            label="Destination Url"
                            variant="outlined"
                            error={!!errors?.items?.[index]?.destinationUrl}
                            helperText={
                              errors?.items?.[index]?.destinationUrl?.message
                            }
                          />
                        )}
                        {fields.length <= 1 ? null : (
                          <Button
                            disabled={false}
                            size="small"
                            variant="text"
                            color="error"
                            onClick={() => {
                              removeItem(index);
                            }}
                          >
                            Delete
                          </Button>
                        )}
                      </Stack>
                      <MenuItemRow
                        droppableIndex={`${droppableIndex}.${index}`}
                        sectionIndex={index}
                        control={control}
                        register={register}
                        errors={errors?.items?.[index]}
                        isMenuItem={insideIsMenuItem}
                        onDragEnd={onDragEnd}
                        baseArrayName={arrayName}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Stack direction={"row"} alignItems={"center"} alignContent={"center"}>
        {isMenuItem ? null : (
          <Button
            variant="text"
            onClick={() => {
              appendItem({
                destinationUrl: "",
                title: "",
              });
            }}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} />
            Add Menu Item
          </Button>
        )}
        {depth >= 2 || isMenuItem ? null : (
          <Button
            disabled={false}
            variant="text"
            onClick={() => {
              appendItem({
                title: "",
                items: [
                  {
                    title: "",
                    destinationUrl: "",
                  },
                ],
              });
            }}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} />
            Add Menu Section
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export type MenuItemGraphQL = {
  destinationUrl: string | null;
  index: number;
  title: string;
  children?: ReadonlyArray<MenuItemGraphQL>;
};

export const convertFromGraphQL = (
  menuItems: ReadonlyArray<MenuItemGraphQL>
): Array<MenuItem> => {
  return Array.from(menuItems)
    .sort((a, b) => a.index - b.index)
    .map((item) => {
      if (item.children == null || item.children.length === 0) {
        return {
          title: item.title,
          destinationUrl: item?.destinationUrl ?? "",
        };
      }
      return {
        title: item.title,
        items: convertFromGraphQL(item.children),
      };
    });
};

const convertFromLocal = (
  menuItems: ReadonlyArray<MenuItem>
): ReadonlyArray<MenuItemGraphQL> => {
  return Array.from(menuItems).map((item, index) => {
    const items = item?.items;
    if (items == null || items.length === 0) {
      return {
        index,
        title: item.title,
        destinationUrl: item.destinationUrl ?? "",
        children: [],
      };
    }
    return {
      index,
      title: item.title,
      destinationUrl: null,
      children: convertFromLocal(items),
    };
  });
};

const MenuCard = ({ brand: brandKey }: Props) => {
  const snackbarContext = useContext(SnackbarContext);
  const dispatch = useMobilePreviewDispatch();
  const environment = useRelayEnvironment();

  const brand = useFragment<MenuCard_brand$key>(brandFragment, brandKey);
  const appConfig = brand.appConfig;
  const shouldLockMenus = appConfig.shouldLockMenus;
  const items = useMemo(
    () => appConfig.nestedMenuItems ?? [],
    [appConfig.nestedMenuItems]
  );
  const [isImporting, setIsImporting] = useState(false);

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    items.length === 0 ? SavedState.DISABLED : SavedState.SAVED
  );

  const [saveMutation, isMutationInFlight] =
    useMutation<MenuCardMutation>(mutation);

  const {
    handleSubmit,
    formState: { errors, isDirty },
    register,
    control,
    reset,
    watch,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      items: convertFromGraphQL(items),
    },
    resolver: yupResolver(schema),
  });

  const {
    fields,
    append: appendSection,
    remove: removeSection,
    move,
  } = useFieldArray({
    control,
    name: "items",
  });

  useEffect(() => {
    const subscription = watch((value) => {
      dispatch({
        type: ReducerAction.UPDATE_NAVIGATION,
        payload: {
          navigation: {
            nestedMenuItems: getValues().items,
          },
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [watch, dispatch, getValues]);

  const childrenRef = useRef<{
    [sectionIndex: string]: (from: number, to: number) => void;
  }>({});
  const setReorder = useCallback(
    (
      sectionIndex: string,
      reorderCallback: (from: number, to: number) => void
    ) => {
      childrenRef.current[sectionIndex] = reorderCallback;
    },
    [childrenRef]
  );

  const onSubmit = (data: FormValues) => {
    setSaveButtonState(SavedState.SAVING);
    saveMutation({
      variables: {
        input: {
          appConfigId: brand.appConfig.id,
          menuItemsInput: convertFromLocal(data.items),
        },
      },
      onCompleted: (d, errors) => {
        if (errors) {
          snackbarContext?.openSnackbar("Update Failed", "error");
        } else {
          snackbarContext?.openSnackbar("Updated", "success");
          setSaveButtonState(SavedState.SAVED);
          reset(data);
        }
      },
      onError: () => {
        snackbarContext?.openSnackbar("Update Failed", "error");
        setSaveButtonState(SavedState.ENABLED);
      },
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <DragDropContext
        onDragEnd={(result) => {
          const { destination, source } = result;

          if (!destination) {
            return;
          }

          if (
            destination.droppableId !== source.droppableId &&
            destination.index === source.index
          ) {
            return;
          }

          /*
            NOTE: We use flushSync to ensure array state is finished updating after move is called after a drag and drop.
            This is possibly due to a few reasons: 
            - React 18 batches updates
            - Nested menu is heavy
            - Validation on top of that is heavy

            flushsync: https://react.dev/reference/react-dom/flushSync#flushing-updates-for-third-party-integrations
            Issue: https://github.com/react-hook-form/react-hook-form/issues/11050
            Another person's issue: https://github.com/atlassian/react-beautiful-dnd/issues/2475

            - TODO: figure out why saving rerenders 3 times instead of once
            - TODO: look at source code to see why move and dnd don't work together
          */
          if (result.type === "PARENT") {
            flushSync(() => move(source.index, destination.index));
          } else {
            let droppableInfo = destination.droppableId.split("-").pop() ?? "";
            const reorderCallback = childrenRef.current[droppableInfo];
            reorderCallback &&
              flushSync(() => reorderCallback(source.index, destination.index));
          }
        }}
      >
        <CardSection
          actions={
            <SaveButton
              savedState={
                isMutationInFlight || saveButtonState === SavedState.SAVING
                  ? SavedState.SAVING
                  : isDirty
                  ? SavedState.ENABLED
                  : saveButtonState
              }
              useSubmit={true}
            />
          }
          title={"Menu"}
          subtitle={
            "Menu items live in the menu icon in your app’s navigation bar."
          }
          lockedContent={
            shouldLockMenus
              ? "This can't be edited due to multilingual support"
              : undefined
          }
          content={
            <Stack spacing={1} width="100%">
              <Stack spacing={1}>
                <LeftRight
                  expandLeft
                  left={
                    <>
                      <Typography variant="subtitle1">
                        Customize your menu items
                      </Typography>
                      <Typography variant="body2">
                        You can import menu items from your Shopify website
                        first, or manually create and edit items yourself.
                      </Typography>
                    </>
                  }
                  right={
                    <Button
                      sx={{
                        boxShadow:
                          "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
                        borderRadius: "8px",
                        flexShrink: 0,
                      }}
                      startIcon={
                        isImporting ? (
                          <CircularProgress size={16} color="inherit" />
                        ) : null
                      }
                      disabled={isImporting}
                      size="medium"
                      variant="contained"
                      color="secondaryLight"
                      onClick={() => {
                        fetchQuery<MenuCardQuery>(environment, importQuery, {
                          brandID: brand.id,
                        }).subscribe({
                          start: () => {
                            setIsImporting(true);
                          },
                          complete: () => {
                            setIsImporting(false);
                          },
                          error: (_: Error) => {
                            setIsImporting(false);
                          },
                          next: (data) => {
                            reset({
                              items: convertFromGraphQL(data.menuItemsImported),
                            });
                            setSaveButtonState(SavedState.ENABLED);
                          },
                        });
                      }}
                    >
                      {isImporting ? "Importing" : "Import all"}
                    </Button>
                  }
                />
              </Stack>

              <Droppable droppableId={"menu-section-droppable"} type="PARENT">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {fields.map((field, sectionIndex) => {
                      const isSection = field.destinationUrl == null;
                      const menuSectionRow = (
                        <Stack width={"100%"} direction="row" spacing={1}>
                          <TextField
                            {...register(
                              `items.${sectionIndex}.title` as const,
                              {
                                maxLength: 255,
                              }
                            )}
                            error={!!errors?.items?.[sectionIndex]?.title}
                            helperText={
                              errors?.items?.[sectionIndex]?.title?.message
                            }
                            sx={{
                              flexGrow: 1,
                            }}
                            id="outlined-basic"
                            label={"Menu Section Title"}
                            variant="outlined"
                          />
                          {!isSection ? (
                            <TextField
                              {...register(
                                `items.${sectionIndex}.destinationUrl` as const
                              )}
                              error={
                                !!errors?.items?.[sectionIndex]?.destinationUrl
                              }
                              helperText={
                                errors?.items?.[sectionIndex]?.destinationUrl
                                  ?.message
                              }
                              sx={{
                                flexGrow: 1,
                              }}
                              id="outlined-basic"
                              label={"Destination Url"}
                              variant="outlined"
                            />
                          ) : null}
                          <Button
                            disabled={false}
                            size="small"
                            variant="text"
                            color="error"
                            onClick={() => {
                              removeSection(sectionIndex);
                            }}
                          >
                            Delete
                          </Button>
                        </Stack>
                      );

                      return (
                        <Draggable
                          key={field.id}
                          draggableId={field.id}
                          index={sectionIndex}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="stretch"
                              >
                                <DragIndicatorIcon
                                  sx={{
                                    marginTop: 2,
                                  }}
                                />
                                <Stack
                                  sx={{
                                    borderRadius: "8px",
                                    border: "1px solid #EEEFF8",
                                    background: "#FDFDFE",
                                    p: 2,
                                    width: "100%",
                                  }}
                                >
                                  {menuSectionRow}
                                  <MenuItemRow
                                    droppableIndex={"" + sectionIndex}
                                    sectionIndex={sectionIndex}
                                    control={control}
                                    register={register}
                                    errors={errors?.items?.[sectionIndex]}
                                    isMenuItem={!isSection}
                                    onDragEnd={setReorder}
                                    baseArrayName={"items"}
                                  />
                                </Stack>
                              </Stack>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <Stack direction={"row"} alignContent={"center"} spacing={1}>
                <Button
                  disabled={false}
                  variant="contained"
                  color="secondaryLight"
                  onClick={() => {
                    appendSection({
                      title: "",
                      destinationUrl: "",
                    });
                  }}
                >
                  <AddCircleOutlineIcon sx={{ mr: 1 }} />
                  Add Menu Item
                </Button>
                <Button
                  disabled={false}
                  variant="contained"
                  color="secondaryLight"
                  onClick={() => {
                    appendSection({
                      title: "",
                      items: [
                        {
                          title: "",
                          destinationUrl: "",
                        },
                      ],
                    });
                  }}
                >
                  <AddCircleOutlineIcon sx={{ mr: 1 }} />
                  Add Menu Section
                </Button>
              </Stack>
            </Stack>
          }
        />
      </DragDropContext>
    </form>
  );
};

export default MenuCard;
