import BakeryDiningIcon from "@mui/icons-material/BakeryDining";
import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import DebugContext from "../../contexts/DebugContext";
import LeftRight from "../LeftRight";

const DebugButton = () => {
  const { onToggleDebug } = useContext(DebugContext);
  const [isToggleEnabled, setIsToggleEnabled] = useState<boolean>(false);
  const onToggle = (enabled: boolean) => {
    setIsToggleEnabled(enabled);
    onToggleDebug(enabled);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton onClick={handleClick}>
        <BakeryDiningIcon />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem dense onClick={() => onToggle(!isToggleEnabled)}>
          <LeftRight
            expandLeft={true}
            left={
              <Stack spacing={1}>
                <Typography variant="body2">Debug</Typography>
              </Stack>
            }
            right={
              <Switch
                checked={isToggleEnabled}
                onChange={(
                  _event: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => {
                  onToggle(checked);
                }}
              />
            }
          />
        </MenuItem>
      </Menu>
    </>
  );
};

export default DebugButton;
