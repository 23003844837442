/**
 * @generated SignedSource<<f339094f9ac3e0f92b01cd0807f39364>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedPostForm_brand$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FeedCarouselForm_brand" | "FeedPollForm_brand" | "FeedSinglePostForm_brand">;
  readonly " $fragmentType": "FeedPostForm_brand";
};
export type FeedPostForm_brand$key = {
  readonly " $data"?: FeedPostForm_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedPostForm_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedPostForm_brand",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedSinglePostForm_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedPollForm_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedCarouselForm_brand"
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "8919493c8378fc3aaa95adc8c66fb6e7";

export default node;
