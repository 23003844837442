import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useContext } from "react";
import { useMutation } from "react-relay";
import { UploadableMap } from "relay-runtime";
import type {
  BrandAppConfigInputPartial,
  useBrandAppConfigUpdate2Mutation as useBrandAppConfigUpdate2MutationType,
} from "./__generated__/useBrandAppConfigUpdate2Mutation.graphql";
import SnackbarContext from "../contexts/SnackbarContext";

const mutation = graphql`
  mutation useBrandAppConfigUpdate2Mutation(
    $input: BrandAppConfigInputPartial!
  ) {
    updateBrandAppConfig(input: $input) {
      ... on BrandType {
        id
        ...OnboardingCard_brand
      }
    }
  }
`;

const useBrandAppConfigUpdate2Mutation = (): [
  (
    input: BrandAppConfigInputPartial,
    onCompleted?: () => void,
    uploadables?: UploadableMap
  ) => void,
  boolean
] => {
  const [saveMutation, isMutationInFlight] =
    useMutation<useBrandAppConfigUpdate2MutationType>(mutation);
  const snackbarContext = useContext(SnackbarContext);

  const onMutate = useCallback(
    (
      input: BrandAppConfigInputPartial,
      onCompleted?: () => void,
      uploadables?: UploadableMap
    ) => {
      saveMutation({
        variables: {
          input: {
            ...input,
          },
        },
        onCompleted: (data, errors) => {
          if (errors) {
            snackbarContext?.openSnackbar("Update Failed", "error");
          } else {
            onCompleted && onCompleted();
            snackbarContext?.openSnackbar("Update Successful", "success");
          }
        },
        onError: (error: Error) => {
          snackbarContext?.openSnackbar("Update Failed", "error");
        },
        uploadables,
      });
    },
    [snackbarContext, saveMutation]
  );

  return [onMutate, isMutationInFlight];
};

export default useBrandAppConfigUpdate2Mutation;
