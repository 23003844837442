/**
 * @generated SignedSource<<38619d1e6c18ad9f00c562360b4b98d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserTypeInputPartial = {
  activeBrand?: NodeInput | null;
  id: string;
};
export type NodeInput = {
  id: string;
};
export type CurrentUserUpdateActiveBrandMutation$variables = {
  input: UserTypeInputPartial;
};
export type CurrentUserUpdateActiveBrandMutation$data = {
  readonly updateActiveBrandProfile: {
    readonly activeBrand: {
      readonly id: string;
    } | null;
    readonly id: string;
  };
};
export type CurrentUserUpdateActiveBrandMutation = {
  response: CurrentUserUpdateActiveBrandMutation$data;
  variables: CurrentUserUpdateActiveBrandMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserType",
    "kind": "LinkedField",
    "name": "updateActiveBrandProfile",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "activeBrand",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentUserUpdateActiveBrandMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CurrentUserUpdateActiveBrandMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7faa3d937db042cbff0378d9b12c38af",
    "id": null,
    "metadata": {},
    "name": "CurrentUserUpdateActiveBrandMutation",
    "operationKind": "mutation",
    "text": "mutation CurrentUserUpdateActiveBrandMutation(\n  $input: UserTypeInputPartial!\n) {\n  updateActiveBrandProfile(input: $input) {\n    id\n    activeBrand {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "258caaa1ae1a01dc806e9a26fcb1fcec";

export default node;
