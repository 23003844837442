/**
 * @generated SignedSource<<44ce7382eab5e9d4100ea58ed525110a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DiscountType = "ORDER" | "PRODUCT" | "%future added value";
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
export type DailyDealFormQuery$variables = {
  id: string;
  skip: boolean;
};
export type DailyDealFormQuery$data = {
  readonly dailyDeal?: {
    readonly archivedAt: any | null;
    readonly audienceLanguages: ReadonlyArray<KinnLanguageCode> | null;
    readonly audiences: ReadonlyArray<{
      readonly audienceId: string;
    }>;
    readonly blurredHeadline: string;
    readonly blurredSubheadline: string;
    readonly discountCode: string;
    readonly discountType: DiscountType;
    readonly discountUrl: string;
    readonly endsAt: any;
    readonly headline: string;
    readonly id: string;
    readonly imageUrl: string | null;
    readonly notification: {
      readonly body: string;
      readonly timestamp: any;
      readonly title: string;
      readonly utmCampaign: string | null;
    } | null;
    readonly startsAt: any;
    readonly subheadline: string;
    readonly title: string | null;
  };
};
export type DailyDealFormQuery = {
  response: DailyDealFormQuery$data;
  variables: DailyDealFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archivedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "audienceLanguages",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountCode",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blurredHeadline",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blurredSubheadline",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "headline",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subheadline",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "audienceId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "utmCampaign",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timestamp",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DailyDealFormQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GraphQLDailyDeal",
            "kind": "LinkedField",
            "name": "dailyDeal",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLCDPAudience",
                "kind": "LinkedField",
                "name": "audiences",
                "plural": true,
                "selections": [
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLNotification",
                "kind": "LinkedField",
                "name": "notification",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DailyDealFormQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GraphQLDailyDeal",
            "kind": "LinkedField",
            "name": "dailyDeal",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLCDPAudience",
                "kind": "LinkedField",
                "name": "audiences",
                "plural": true,
                "selections": [
                  (v16/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLNotification",
                "kind": "LinkedField",
                "name": "notification",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "82c2e914a2806ff80480a7df7c2f0b17",
    "id": null,
    "metadata": {},
    "name": "DailyDealFormQuery",
    "operationKind": "query",
    "text": "query DailyDealFormQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  dailyDeal(id: $id) @skip(if: $skip) {\n    id\n    archivedAt\n    audienceLanguages\n    discountType\n    discountCode\n    discountUrl\n    imageUrl\n    startsAt\n    endsAt\n    title\n    blurredHeadline\n    blurredSubheadline\n    headline\n    subheadline\n    audiences {\n      audienceId\n      id\n    }\n    notification {\n      title\n      body\n      utmCampaign\n      timestamp\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "28005510b3e3b960cfab055f396b769c";

export default node;
