import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import dayjs, { Dayjs } from "dayjs";
import deepEqual from "deep-equal";
import { FocusEvent, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { useParams } from "react-router-dom";
import { Uploadable } from "relay-runtime";
import * as yup from "yup";
import { GraphQLCDPAudienceInput } from "../__generated__/HighlightsTabCreateAlbumMutation.graphql";
import { FeedCarouselForm_brand$key } from "./__generated__/FeedCarouselForm_brand.graphql";
import {
  FeedCarouselForm_feedPost$key,
  MediaType,
} from "./__generated__/FeedCarouselForm_feedPost.graphql";
import {
  AttachedNotification,
  isValidAttachedNotification,
} from "../../../../components/AttachedNotification";
import { CDPAudienceSelectorCard } from "../../../../components/CDPAudienceSelectorCard";
import CardSection from "../../../../components/CardSection";
import LeftRight from "../../../../components/LeftRight";
import RighthandDrawer from "../../../../components/RighthandDrawer";
import UploadedFileCard from "../../../../components/UploadedFileCard";
import {
  PreviewType,
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../../contexts/MobilePreviewContext";
import useFeedPostCreateMutation from "../../../../mutations/useFeedPostCreateMutation";
import useFeedPostUpdateMutation from "../../../../mutations/useFeedPostUpdateMutation";
import { KinnLanguageCode } from "../../../../utils/languageMap";
import { validateUrl } from "../../../../utils/validators";

const schema = yup
  .object({
    title: yup.string().required("Title is required").trim(),
    subtitle: yup.string().required("Subtitle is required").trim(),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const drawerSchema = yup
  .object({
    title: yup.string().required("Title is required").trim(),
    subtitle: yup.string().required("Subtitle is required").trim(),
    url: yup
      .string()
      .required("URL is required")
      .test("is-valid-url", "URL is invalid", (value) => {
        if (!value) return false;

        return validateUrl(value);
      }),
  })
  .required();
type DrawerFormData = yup.InferType<typeof drawerSchema>;

const brandFragment = graphql`
  fragment FeedCarouselForm_brand on BrandType {
    id
    feed {
      id
    }
    ...useFeedPostCreateMutation_brand
    ...useFeedPostUpdateMutation_brand
    ...CDPAudienceSelectorCard_brand
  }
`;

const feedPostFragment = graphql`
  fragment FeedCarouselForm_feedPost on GraphQLFeedPost {
    id
    audiences {
      audienceId
    }
    audienceLanguages
    publishedAt
    notification {
      title
      body
      utmCampaign
    }
    carousel {
      id
      title
      subtitle
      items {
        id
        title
        subtitle
        url
        media {
          url
          type
          coverImageUrl
        }
      }
    }
  }
`;

const Drawer = ({
  brandID,
  isDrawerOpen,
  onDrawerClose,
  carouselItems,
  setCarouselItems,
  itemIndex,
}: {
  brandID: string;
  isDrawerOpen: boolean;
  onDrawerClose: () => void;
  carouselItems: {
    title: string;
    subtitle: string;
    url: string;
    mediaUrl: string | null;
    mediaType?: MediaType;
    file?: Uploadable | null;
    muxVideoID?: string;
  }[];
  setCarouselItems: (
    carouselItems: {
      title: string;
      subtitle: string;
      url: string;
      mediaUrl: string | null;
      mediaType?: MediaType;
      file?: Uploadable | null;
      muxVideoID?: string;
    }[]
  ) => void;
  itemIndex: number;
}) => {
  const dispatch = useMobilePreviewDispatch();

  const [mediaUrl, setMediaUrl] = useState<string | null>(null);
  const [mediaType, setMediaType] = useState<MediaType | undefined>(undefined);
  const [mediaFile, setMediaFile] = useState<Uploadable | null>(null);
  const [muxVideoID, setMuxVideoID] = useState<string | null>(null);

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<DrawerFormData>({
    defaultValues: {
      title: "",
      subtitle: "",
      url: "",
    },
    resolver: yupResolver(drawerSchema),
  });

  useEffect(() => {
    const item = carouselItems[itemIndex];

    const carouselTitle = item?.title ?? "";
    const carouselSubtitle = item?.subtitle ?? "";
    const carouselUrl = item?.url ?? "";

    const carouselMediaUrl = item?.mediaUrl ?? null;
    const carouselMediaFile = item?.file ?? null;
    const carouselMediaType = item?.mediaType ?? undefined;

    setMediaUrl(carouselMediaUrl);
    setMediaType(carouselMediaType);
    setMediaFile(carouselMediaFile);
    setMuxVideoID(item?.muxVideoID ?? null);
    reset({
      title: carouselTitle,
      subtitle: carouselSubtitle,
      url: carouselUrl,
    });
  }, [itemIndex, carouselItems, reset]);

  const onSubmit = (data: DrawerFormData) => {
    const newArray = Array.from(carouselItems);
    newArray[itemIndex] = {
      ...newArray[itemIndex],
      title: data.title,
      subtitle: data.subtitle,
      url: data.url,
      mediaUrl: mediaUrl,
      mediaType: mediaType ?? undefined,
      file: mediaFile,
      muxVideoID: muxVideoID || undefined,
    };
    setCarouselItems(newArray);
    dispatch({
      type: ReducerAction.UPDATE_CAROUSEL,
      payload: {
        carousel: {
          items: newArray,
        },
      },
    });
    onDrawerClose();
  };

  return (
    <RighthandDrawer
      isOpen={isDrawerOpen}
      title="Edit Carousel Item"
      onClose={onDrawerClose}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Stack
          sx={{
            padding: 2,
          }}
          spacing={3}
        >
          <Typography variant="overline">1. Customize Content</Typography>
          <TextField
            {...register("title")}
            error={!!errors?.title}
            helperText={errors?.title?.message}
            sx={{
              flexGrow: 1,
            }}
            margin="normal"
            label={"Item Title"}
            variant="outlined"
          />
          <TextField
            {...register("subtitle")}
            error={!!errors?.subtitle}
            helperText={errors?.subtitle?.message}
            sx={{
              flexGrow: 1,
            }}
            margin="normal"
            label={"Item Subtitle"}
            variant="outlined"
          />
          <Typography variant="overline">2. Customize URL</Typography>
          <TextField
            {...register("url")}
            error={!!errors?.url}
            helperText={errors?.url?.message}
            sx={{
              flexGrow: 1,
            }}
            margin="normal"
            label={"URL"}
            variant="outlined"
          />
          <Typography variant="overline">3. Add media</Typography>
          <UploadedFileCard
            brandID={brandID}
            onUploadVideo={(videoID: string) => {
              setMuxVideoID(videoID);
            }}
            fileURL={mediaUrl}
            fileType=".svg, .png, .jpg, .mp4, .mov"
            htmlLabel="feed-post-carousel-item-option-form"
            mediaType={mediaType ?? "PHOTO"}
            inputLabel="Media Asset"
            inputText={"Upload media"}
            onClose={() => {
              setMediaUrl(null);
              setMediaFile(null);
              setMuxVideoID(null);
            }}
            onUpload={(
              event: React.ChangeEvent<HTMLInputElement>,
              mediaType?: MediaType // TODO: make this arg required
            ) => {
              const file = event?.target?.files?.[0];
              if (file == null) {
                return;
              }

              setMediaUrl(URL.createObjectURL(file));
              setMediaType(file.type.includes("video") ? "VIDEO" : "PHOTO");

              if (mediaType !== "WEB_MUX_VIDEO") {
                setMediaFile(file);
              }
            }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: 2,
          }}
        >
          <Button
            size="large"
            variant="outlined"
            color="error"
            onClick={onDrawerClose}
          >
            Cancel
          </Button>
          <Button
            disabled={mediaUrl === null}
            type="submit"
            size="large"
            variant="contained"
          >
            Save Changes
          </Button>
        </Stack>
      </form>
    </RighthandDrawer>
  );
};

const CarouselItem = ({
  shouldHideDelete,
  title,
  subtitle,
  thumbnail,
  onDelete,
  onEdit,
}: {
  shouldHideDelete: boolean;
  title: string;
  subtitle: string;
  thumbnail: string | null;
  onDelete: () => void;
  onEdit: () => void;
}) => {
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems="center">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          cursor: "pointer",
        }}
      >
        <DragIndicatorIcon />
      </Box>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            alt=""
            src={thumbnail ?? ""}
            variant="rounded"
            sx={{ bgcolor: theme.palette.info.main, color: "black" }}
          >
            <InsertPhotoIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              variant="body2"
              color={"black"}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              {title}
            </Typography>
          }
          secondary={
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              {subtitle}
            </Typography>
          }
        />
      </ListItem>

      <Button size="small" variant="text" color="primary" onClick={onEdit}>
        Edit
      </Button>
      {shouldHideDelete ? null : (
        <Button size="small" variant="text" color="error" onClick={onDelete}>
          Delete
        </Button>
      )}
    </Stack>
  );
};

const FeedCarouselForm = ({
  brand: brandKey,
  feedPost: feedPostKey,
  onBackClick,
  onUpdateComplete,
}: {
  brand: FeedCarouselForm_brand$key;
  feedPost: FeedCarouselForm_feedPost$key | null;
  onBackClick: () => void;
  onUpdateComplete: (postID?: string) => void;
}) => {
  const dispatch = useMobilePreviewDispatch();
  const postID = useParams().id;
  const isEditing = postID != null;

  // Data Loading
  const brand = useFragment(brandFragment, brandKey);
  const feedPost = useFragment(feedPostFragment, feedPostKey);
  const feedID = brand?.feed?.id ?? null;
  const carousel = feedPost?.carousel ?? null;
  const carouselTitle = carousel?.title ?? "";
  const carouselSubtitle = carousel?.subtitle ?? "";
  const items = carousel?.items ?? [];
  const [carouselItems, setCarouselItems] = useState<
    {
      id?: string;
      title: string;
      subtitle: string;
      url: string;
      mediaUrl: string | null;
      mediaType?: MediaType;
      fallbackMediaUrl?: string;
      muxVideoID?: string;
      file?: Uploadable | null;
    }[]
  >(
    items.map((item) => ({
      id: item.id,
      title: item.title,
      subtitle: item.subtitle ?? "",
      url: item.url ?? "",
      mediaUrl: item.media?.url ?? null,
      mediaType: item.media?.type ?? undefined,
      fallbackMediaUrl: item.media?.coverImageUrl ?? undefined,
    }))
  );
  const isItemsDirty = !deepEqual(carouselItems, items);

  useEffect(() => {
    dispatch({
      type: ReducerAction.SET_PREVIEW_TYPE,
      payload: {
        previewType: PreviewType.CAROUSEL,
        carousel: {
          title: carouselTitle,
          subtitle: carouselSubtitle,
          items: carouselItems,
        },
      },
    });
    return () => {
      dispatch({ type: ReducerAction.RESET });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [audienceIDs, setAudienceIDs] = useState<string[]>(
    (feedPost?.audiences ?? []).map((audience) => audience.audienceId)
  );
  const [audienceDatas, setAudienceDatas] = useState<
    GraphQLCDPAudienceInput[] | null
  >(null);
  const onAudiencesChange = useCallback(
    (audiences: GraphQLCDPAudienceInput[]) => {
      setAudienceIDs(audiences.map((audience) => audience.audienceId));
      setAudienceDatas(
        audiences.map((audience) => ({
          audienceId: audience.audienceId,
          audienceName: audience.audienceName,
          audienceType: audience.audienceType,
          cdpType: audience.cdpType,
        }))
      );
    },
    [setAudienceIDs, setAudienceDatas]
  );
  const [audienceLanguages, setAudienceLanguages] = useState<
    KinnLanguageCode[]
  >(feedPost?.audienceLanguages?.slice() ?? []);
  const onLanguageFiltersChange = useCallback(
    (languages: KinnLanguageCode[]) => {
      setAudienceLanguages(languages);
    },
    [setAudienceLanguages]
  );

  // Publish Preferences
  const publishTime = feedPost?.publishedAt ?? null;
  const isPostPublished =
    publishTime !== null && dayjs(publishTime).isBefore(dayjs());
  const [publishLater, setPublishLater] = useState(
    isEditing && !isPostPublished
  );
  const onTogglePublishLater = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPublishLater(event.target.checked);
  };

  const initialTime = publishTime ? dayjs(publishTime) : dayjs().add(1, "day");
  const [dateTime, setDateTime] = useState<Dayjs | null>(initialTime);
  const minDateTime = dayjs().add(1, "hour");
  const maxDateTime = dayjs().add(3, "months");

  const [notification, setNotification] = useState(
    feedPost?.notification ?? null
  );
  const notifEnabled = notification !== null;

  // Right hand drawer props
  const [drawerIndex, setDrawerIndex] = useState<number>(-1);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const onDrawerOpen = useCallback(
    (index: number) => {
      setDrawerIndex(index);
      setIsDrawerOpen(true);
    },
    [setDrawerIndex, setIsDrawerOpen]
  );
  const onDrawerClose = useCallback(() => {
    setDrawerIndex(-1);
    setIsDrawerOpen(false);
  }, [setDrawerIndex, setIsDrawerOpen]);

  const [createCarousel, isCreating] = useFeedPostCreateMutation(brand);
  const [updateCarousel, isUpdating] = useFeedPostUpdateMutation(brand);
  const isMutationInFlight = isCreating || isUpdating;

  // Form Validation Logic
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>({
    defaultValues: {
      title: carouselTitle,
      subtitle: carouselSubtitle,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: FormData) => {
    const input = {
      isNotifEnabled: notifEnabled,
      notifTitle: notification?.title,
      notifSubtitle: notification?.body,
      notifUtmCampaign: notification?.utmCampaign,
      audienceLanguages,
    };
    if (isEditing) {
      updateCarousel(
        {
          ...input,
          id: postID,
          publishedAt: isPostPublished
            ? undefined
            : publishLater
            ? dateTime
            : dayjs(),
          audiences:
            audienceDatas === null
              ? undefined
              : audienceDatas.map((audience) => ({
                  audienceId: audience.audienceId,
                  audienceName: audience.audienceName,
                  audienceType: audience.audienceType,
                  cdpType: audience.cdpType,
                })),
          carousel: {
            title: data.title,
            subtitle: data.subtitle,
            items: isItemsDirty
              ? carouselItems.map((item) => ({
                  id: item.id,
                  title: item.title,
                  subtitle: item.subtitle,
                  url: item.url,
                  muxVideoId: item.muxVideoID,
                }))
              : undefined,
          },
        },
        (data) => {
          onUpdateComplete(data.updateFeedPost.id);
          onBackClick();
        },
        {
          ...Object.fromEntries(
            carouselItems
              .map((item, index) => [
                `carousel.items.${index}.mediaUploadable`,
                item.file ?? null,
              ])
              .filter(([, file]) => file !== null) // TODO: shis should always be unset or have a vluee
          ),
        }
      );
    } else {
      createCarousel(
        {
          ...input,
          publishedAt: publishLater ? dateTime : dayjs(),
          audiences:
            audienceDatas === null
              ? []
              : audienceDatas.map((audience) => ({
                  audienceId: audience.audienceId,
                  audienceName: audience.audienceName,
                  audienceType: audience.audienceType,
                  cdpType: audience.cdpType,
                })),
          feed: {
            id: feedID,
          },
          carousel: {
            title: data.title,
            subtitle: data.subtitle,
            items: isItemsDirty
              ? carouselItems.map((item) => ({
                  title: item.title,
                  subtitle: item.subtitle,
                  url: item.url,
                  muxVideoId: item.muxVideoID,
                }))
              : undefined,
          },
        },
        (data) => {
          onUpdateComplete(data.createFeedPost.id);
          onBackClick();
        },
        {
          ...Object.fromEntries(
            carouselItems
              .map((item, index) => [
                `carousel.items.${index}.mediaUploadable`,
                item?.file ?? null,
              ])
              .filter(([, file]) => file !== null)
          ),
        }
      );
    }
  };

  useEffect(() => {
    dispatch({
      type: ReducerAction.SET_PREVIEW_TYPE,
      payload: {
        previewType: PreviewType.CAROUSEL,
        carousel: {
          title: carouselTitle,
          subtitle: carouselSubtitle,
          items: carouselItems,
        },
      },
    });
    return () => {
      dispatch({ type: ReducerAction.RESET });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const customizeCard = (
    <CardSection
      title={"Customize post"}
      content={
        <Stack spacing={1} width="100%">
          <Typography variant="overline">1. Customize Content</Typography>
          <TextField
            {...register("title", {
              onChange: (event: FocusEvent<HTMLInputElement>) => {
                dispatch({
                  type: ReducerAction.UPDATE_CAROUSEL,
                  payload: {
                    carousel: {
                      title: event.target.value,
                    },
                  },
                });
              },
            })}
            error={!!errors?.title}
            helperText={errors?.title?.message}
            margin="normal"
            label={"Carousel Title"}
            variant="outlined"
            inputProps={{
              maxLength: 80,
            }}
          />
          <TextField
            {...register("subtitle", {
              maxLength: 200,
              onChange: (event: FocusEvent<HTMLInputElement>) => {
                dispatch({
                  type: ReducerAction.UPDATE_CAROUSEL,
                  payload: {
                    carousel: {
                      subtitle: event.target.value,
                    },
                  },
                });
              },
            })}
            error={!!errors?.subtitle}
            helperText={errors?.subtitle?.message}
            sx={{
              flexGrow: 1,
            }}
            margin="normal"
            multiline
            label={"Carousel Subtitle"}
            variant="outlined"
            inputProps={{
              maxLength: 200,
            }}
          />

          <Typography variant="overline">
            3. Customize Items (2 Minimum)
          </Typography>
          <DragDropContext
            onDragEnd={(result) => {
              const { destination, source } = result;

              if (!destination) {
                return;
              }

              if (
                destination.droppableId !== source.droppableId &&
                destination.index === source.index
              ) {
                return;
              }

              const newItems = Array.from(carouselItems);
              const [removed] = newItems.splice(source.index, 1);
              newItems.splice(destination.index, 0, removed);
              setCarouselItems(newItems);

              dispatch({
                type: ReducerAction.UPDATE_CAROUSEL,
                payload: {
                  carousel: {
                    items: newItems,
                  },
                },
              });
            }}
          >
            <Droppable droppableId={"feed-carousel-droppable"} type={"PARENT"}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {carouselItems.map((option, index) => {
                    const onDelete = () => {
                      const newArray = Array.from(carouselItems);
                      newArray.splice(index, 1);
                      setCarouselItems(newArray);
                      dispatch({
                        type: ReducerAction.UPDATE_CAROUSEL,
                        payload: {
                          carousel: {
                            items: newArray,
                          },
                        },
                      });
                    };
                    return (
                      <Draggable
                        key={index}
                        draggableId={"draggable-" + index}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: "none",
                              marginTop: "8px",
                              background: "white",
                              ...provided.draggableProps.style,
                            }}
                          >
                            <CarouselItem
                              key={index}
                              shouldHideDelete={carouselItems.length <= 2}
                              title={option.title}
                              subtitle={option.subtitle}
                              thumbnail={
                                option?.fallbackMediaUrl ?? option.mediaUrl
                              }
                              onDelete={onDelete}
                              onEdit={() => onDrawerOpen(index)}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            disabled={false}
            fullWidth={true}
            size="large"
            variant="outlined"
            onClick={() => {
              onDrawerOpen(carouselItems.length);
            }}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} />
            Add Option
          </Button>
        </Stack>
      }
    />
  );

  const audienceCard = (
    <CDPAudienceSelectorCard
      audienceIDs={audienceIDs}
      brand={brand}
      subtitle={"Choose an audience to share your post with:"}
      audienceLanguages={audienceLanguages}
      onAudiencesChange={onAudiencesChange}
      onLanguageFiltersChange={onLanguageFiltersChange}
    />
  );

  const notifAndPublishCard = (
    <CardSection
      showIsOptional
      title={"Set publish preferences"}
      content={
        <Stack spacing={2} width={"100%"}>
          <LeftRight
            expandLeft={true}
            left={
              <Stack spacing={1}>
                <Typography variant="subtitle1">Publish later</Typography>
                <Typography variant="body2">
                  Schedule the highlight to publish anytime between an hour from
                  now to 3 months out
                </Typography>
              </Stack>
            }
            right={
              <Switch checked={publishLater} onChange={onTogglePublishLater} />
            }
          />
          {publishLater && (
            <Stack
              direction={"row"}
              component="form"
              sx={{
                alignItems: "center",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{
                    mr: 1,
                  }}
                  minDateTime={minDateTime}
                  maxDateTime={maxDateTime}
                  value={dateTime}
                  onChange={(newTime) => {
                    setDateTime(newTime);
                  }}
                />
              </LocalizationProvider>
              {dateTime?.format("z")}
            </Stack>
          )}

          <AttachedNotification
            notification={notification}
            onNotificationChange={setNotification}
          />
        </Stack>
      }
    />
  );

  let saveButtonText = "Publish";
  if (isEditing) {
    saveButtonText = false ? "Saving" : "Save";
  } else {
    saveButtonText = isCreating ? "Publishing" : "Publish";
  }

  const isPublishButtonDisabled =
    carouselItems.length < 2 ||
    (publishLater && (!dateTime?.isValid() || minDateTime.isAfter(dateTime))) ||
    !isValidAttachedNotification(notification);
  const publishButton = (
    <Box display="flex" justifyContent="flex-end">
      <Button
        type="submit"
        disabled={isMutationInFlight || isPublishButtonDisabled}
        variant="contained"
        startIcon={
          isMutationInFlight ? (
            <CircularProgress color="inherit" size={16} />
          ) : undefined
        }
      >
        {saveButtonText}
      </Button>
    </Box>
  );

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        {customizeCard}
        {audienceCard}
        {isPostPublished ? null : notifAndPublishCard}
        {publishButton}
      </form>
      <Drawer
        brandID={brand.id}
        isDrawerOpen={isDrawerOpen}
        onDrawerClose={onDrawerClose}
        carouselItems={carouselItems}
        setCarouselItems={setCarouselItems}
        itemIndex={drawerIndex}
      />
    </>
  );
};

export default FeedCarouselForm;
