import { Container } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { InternBrandBilling_brand$key } from "./__generated__/InternBrandBilling_brand.graphql";
import nullthrows from "../../utils/nullthrows";
import InternBrandBillingOneTimePurchaseCard from "./InternBrandBillingOneTimePurchaseCard";
import InternBrandBillingRecurringSubCard from "./InternBrandBillingRecurringSubCard";

const brandFragment = graphql`
  fragment InternBrandBilling_brand on BrandType {
    id
    billingConfig {
      ...InternBrandBillingRecurringSubCard_billingConfig
    }
    ...InternBrandBillingOneTimePurchaseCard_brand
  }
`;

const InternBrandBilling = ({
  brand: brandKey,
}: {
  brand: InternBrandBilling_brand$key;
}) => {
  const brand = useFragment(brandFragment, brandKey);
  const billingConfig = nullthrows(brand.billingConfig);

  return (
    <Container maxWidth="md">
      <InternBrandBillingRecurringSubCard
        billingConfig={billingConfig}
        brandID={brand.id}
      />
      <InternBrandBillingOneTimePurchaseCard brand={brand} />
    </Container>
  );
};

export default InternBrandBilling;
