import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./style/AppDownloadRouter.css";

const AppDownloadRouter = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [iosAppStoreUrl, setIosAppStoreUrl] = useState("");
  const [androidAppStoreUrl, setAndroidAppStoreUrl] = useState("");
  const [brandIconUrl, setBrandIconUrl] = useState("");
  const [brandDeeplinkSchema, setBrandDeeplinkSchema] = useState("");
  const [appDeeplinkUrl, setAppDeeplinkUrl] = useState<string | null>(null);
  const [brandName, setBrandName] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const slug = urlParams.get("id");
    fetch("/api/v1/brand/app-download-link", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ slug: slug }),
    })
      .then((res) => res.json())
      .then((data) => {
        setIosAppStoreUrl(data.ios_app_store_uri);
        setAndroidAppStoreUrl(data.android_app_store_uri);
        setBrandIconUrl(data.brand_icon);
        setBrandDeeplinkSchema(data.brand_deeplink_schema);
        setBrandName(data.brand_name);
        setIsLoading(false);
        setAppDeeplinkUrl(urlParams.get("url"));
      });
  }, [location]);

  const replacePageWithAppStore = (os: string) => {
    window.location.replace(
      os === "iOS"
        ? iosAppStoreUrl
        : os === "Android"
        ? androidAppStoreUrl
        : iosAppStoreUrl
    );
  };

  const openAppStore = (os: string) => {
    window.open(
      os === "iOS"
        ? iosAppStoreUrl
        : os === "Android"
        ? androidAppStoreUrl
        : iosAppStoreUrl,
      "_blank"
    );
  };

  const openDeeplink = () => {
    if (appDeeplinkUrl == null) {
      return;
    }

    const fullDeeplink =
      brandDeeplinkSchema + "://web?url=" + encodeURIComponent(appDeeplinkUrl);
    window.open(fullDeeplink);
  };

  if (isLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  const agent = window.navigator.userAgent;
  const os = /iPhone|iPad/.test(agent)
    ? "iOS"
    : /Android/.test(agent)
    ? "Android"
    : "Other";

  if (os === "Other" || appDeeplinkUrl == null) {
    replacePageWithAppStore(os);
    return <></>;
  }

  const deeplinkView = (
    <div id="main-container">
      <div id="app-icon">
        <img src={brandIconUrl} alt="App Icon" />
      </div>
      <div id="app-name">{brandName}</div>
      <div id="app-button-container">
        <button className="app-button open-app-button" onClick={openDeeplink}>
          Open in App
        </button>
        <button
          className="app-button download-app-button"
          onClick={() => openAppStore(os)}
        >
          Download App
        </button>
      </div>
      <div id="app-info">
        <p>
          1. If you already have our app installed, simply tap the "Open in App"
          button to continue your shopping session.
        </p>
        <p>
          2. If you don't have our app yet, tap "Download App" to get it from
          the App Store. Once you have the app, return to this page and use the
          "Open in App" button.
        </p>
      </div>
    </div>
  );

  return appDeeplinkUrl !== null ? deeplinkView : <></>;
};

export default AppDownloadRouter;
