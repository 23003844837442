import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import {
  Box,
  Card,
  CardActionArea,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useState } from "react";
import { useFragment } from "react-relay";
import {
  ButtonStyleCard_brand$key,
  ButtonTextStyleType,
  IconType,
} from "./__generated__/ButtonStyleCard_brand.graphql";
import CardSection from "../../../components/CardSection";
import SaveButton, { SavedState } from "../../../components/SaveButton";
import {
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../contexts/MobilePreviewContext";
import useBrandAppConfigUpdateMutation from "../../../mutations/useBrandAppConfigUpdateMutation";

const BUTTON_STYLES: ButtonTextStyleType[] = ["SENTENCE_CASE", "UPPERCASE"];
const ICON_TYPES: IconType[] = ["SHOPPING_CART", "SHOPPING_BAG"];

const brandFragment = graphql`
  fragment ButtonStyleCard_brand on BrandType {
    appConfig {
      id
      buildTheme {
        id
        buttonBorderThickness
        buttonCartIcon
        buttonCornerRadius
        buttonTextStyle
      }
    }
  }
`;

type Props = {
  brand: ButtonStyleCard_brand$key;
};

const ButtonStyleCard = ({ brand: brandKey }: Props) => {
  const dispatch = useMobilePreviewDispatch();

  const theme = useTheme();
  const brand = useFragment<ButtonStyleCard_brand$key>(brandFragment, brandKey);
  const buildTheme = brand?.appConfig?.buildTheme;
  const isUnset =
    buildTheme?.buttonCornerRadius == null ||
    buildTheme?.buttonBorderThickness == null ||
    buildTheme.buttonTextStyle == null ||
    buildTheme.buttonCartIcon == null;

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    isUnset ? SavedState.ENABLED : SavedState.SAVED
  );

  const [buttonCornerRadius, setButtonCornerRadius] = useState<number>(
    buildTheme?.buttonCornerRadius ?? 0
  );
  const [buttonBorderThickness, setButtonBorderThickness] = useState<number>(
    buildTheme?.buttonBorderThickness ?? 1
  );
  const [buttonTextStyle, setButtonTextStyle] = useState<ButtonTextStyleType>(
    buildTheme.buttonTextStyle ?? "SENTENCE_CASE"
  );
  const [buttonCartIcon, setButtonCartIcon] = useState<IconType>(
    buildTheme.buttonCartIcon ?? "SHOPPING_BAG"
  );

  const onButtonCornerRadiusChange = useCallback(
    (event: Event, value: number | number[], activeThumb: number) => {
      if (Array.isArray(value)) {
        return;
      }

      dispatch({
        type: ReducerAction.UPDATE_THEME,
        payload: {
          theme: {
            buttonCornerRadius: value,
          },
        },
      });
      setButtonCornerRadius(value);
      saveButtonState !== SavedState.ENABLED &&
        setSaveButtonState(SavedState.ENABLED);
    },
    [setButtonCornerRadius, saveButtonState, dispatch]
  );
  const onButtonBorderThicknessChange = useCallback(
    (event: Event, value: number | number[], activeThumb: number) => {
      if (Array.isArray(value)) {
        return;
      }
      setButtonBorderThickness(value);
      saveButtonState !== SavedState.ENABLED &&
        setSaveButtonState(SavedState.ENABLED);
    },
    [setButtonBorderThickness, saveButtonState]
  );
  const onButtonTextStyleChange = useCallback(
    (event: SelectChangeEvent) => {
      const newTextStyle = event.target.value as ButtonTextStyleType;
      setButtonTextStyle(newTextStyle);
      dispatch({
        type: ReducerAction.UPDATE_THEME,
        payload: {
          theme: {
            buttonTextStyle: newTextStyle,
          },
        },
      });
      saveButtonState !== SavedState.ENABLED &&
        setSaveButtonState(SavedState.ENABLED);
    },
    [setButtonTextStyle, saveButtonState, dispatch]
  );

  const [saveMutation, isMutationInFlight] = useBrandAppConfigUpdateMutation(
    {
      id: brand.appConfig.id,
      buildTheme: {
        id: brand.appConfig.buildTheme.id,
        buttonCornerRadius,
        buttonBorderThickness,
        buttonTextStyle: buttonTextStyle,
        buttonCartIcon,
      },
    },
    () => {
      setSaveButtonState(SavedState.SAVED);
    }
  );

  return (
    <CardSection
      actions={
        <SaveButton
          savedState={
            isMutationInFlight ? SavedState.DISABLED : saveButtonState
          }
          onClick={saveMutation}
        />
      }
      title={"Button Style"}
      content={
        <Stack
          spacing={2}
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            maxWidth: "650px",
          }}
        >
          <Typography variant="body1">Button Corner Radius</Typography>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <Slider
              aria-label="Button Corner Radius"
              value={buttonCornerRadius}
              step={1}
              min={0}
              max={100}
              onChange={onButtonCornerRadiusChange}
            />
            <Typography variant="body2">{buttonCornerRadius + "px"}</Typography>
          </Stack>
          <Typography variant="body1">Button Border Thickness</Typography>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <Slider
              aria-label="Button Border Thickness"
              value={buttonBorderThickness}
              step={1}
              min={0}
              max={5}
              onChange={onButtonBorderThicknessChange}
            />
            <Typography variant="body2">
              {buttonBorderThickness + "px"}
            </Typography>
          </Stack>
          <FormControl fullWidth>
            <InputLabel id={"Button Label Text Style"}>
              Button Label Text Style
            </InputLabel>
            <Select
              label="Button Label Text Style"
              value={buttonTextStyle}
              onChange={onButtonTextStyleChange}
            >
              {BUTTON_STYLES.map(
                (style: ButtonTextStyleType, index: number) => {
                  let text = null;
                  switch (style) {
                    case "SENTENCE_CASE":
                      text = "Sentence Case";
                      break;
                    case "UPPERCASE":
                      text = "Uppercase";
                      break;
                  }
                  return (
                    <MenuItem key={index} value={style}>
                      {text}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
          <Typography variant="body1">Cart Icon Button</Typography>
          <Typography variant="body2">
            Select a style for your cart icon. It will appear in navigation bars
            throughout the app.
          </Typography>
          <Stack direction="row" spacing={4}>
            {ICON_TYPES.map((iconType: IconType, index: number) => {
              let text = null;
              let icon = null;
              switch (iconType) {
                case "SHOPPING_CART":
                  icon = <ShoppingCartOutlinedIcon />;
                  text = "Cart";
                  break;
                case "SHOPPING_BAG":
                  icon = <ShoppingBagOutlinedIcon />;
                  text = "Bag";
                  break;
              }
              return (
                <Card
                  key={index}
                  sx={{
                    display: "flex",
                    height: "150px",
                    width: "177px",
                    boxShadow:
                      buttonCartIcon === iconType
                        ? "inset 0px 0px 0px 2px " + theme.palette.primary.main
                        : "inset 0px 0px 0px 1px #E6E8F0",
                    borderRadius: "8px",
                  }}
                  variant="outlined"
                >
                  <CardActionArea
                    sx={{ pl: 3, height: "100%", flexGrow: 1 }}
                    onClick={() => {
                      dispatch({
                        type: ReducerAction.UPDATE_THEME,
                        payload: {
                          theme: {
                            buttonCartIcon: iconType,
                          },
                        },
                      });
                      setButtonCartIcon(iconType);
                      saveButtonState !== SavedState.ENABLED &&
                        setSaveButtonState(SavedState.ENABLED);
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        height: 42,
                        width: 42,
                        border: "0.5px solid rgba(0, 0, 0, 0.5)",
                        borderRadius: "4px",
                        mb: 1,
                      }}
                    >
                      {icon}
                    </Box>
                    <Typography variant="h5">{text}</Typography>
                  </CardActionArea>
                </Card>
              );
            })}
          </Stack>
        </Stack>
      }
    />
  );
};

export default ButtonStyleCard;
