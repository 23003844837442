import React, { useState, useEffect } from "react";

import { Navigate } from "react-router-dom";

import { CircularProgress, Grid } from "@mui/material";

interface Props {
  children: JSX.Element;
}

const PrivateRoute = ({ children }: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("/api/v1/user/auth/verify")
      .then((res) => res.json())
      .then((data) => {
        setIsAuthenticated(data.is_authenticated);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  if (isAuthenticated) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
