import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FocusEvent } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useNavigate, useNavigationType, useParams } from "react-router-dom";
import { ConnectionHandler, PayloadError, UploadableMap } from "relay-runtime";
import * as yup from "yup";
import {
  AppViewType,
  GraphQLCDPAudienceInput,
  GraphQLInterstitialNotificationInput,
  InterstitialNotificationFormCreateMutation,
} from "./__generated__/InterstitialNotificationFormCreateMutation.graphql";
import {
  InterstitialNotificationFormQuery,
  InterstitialNotificationTriggerType,
} from "./__generated__/InterstitialNotificationFormQuery.graphql";
import { InterstitialNotificationFormUpdateMutation } from "./__generated__/InterstitialNotificationFormUpdateMutation.graphql";
import { InterstitialNotificationForm_brand$key } from "./__generated__/InterstitialNotificationForm_brand.graphql";
import {
  AppDestinationLink,
  LANDING_PATHS,
  LandingPath,
  TRIGGER_PATHS,
  appDestinationLinkSchema,
  appDestinationLinkSchemaOptional,
  convertLandingPathToType,
} from "../../../../../components/AppDestinationLink";
import { CDPAudienceSelectorCard } from "../../../../../components/CDPAudienceSelectorCard";
import CardSection from "../../../../../components/CardSection";
import { UTMCard } from "../../../../../components/UTMCard";
import UploadedFileCard from "../../../../../components/UploadedFileCard";
import {
  PreviewType,
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../../../contexts/MobilePreviewContext";
import SnackbarContext from "../../../../../contexts/SnackbarContext";
import { KinnLanguageCode } from "../../../../../utils/languageMap";
import { ENGAGE_AUTOMATIONS_INTERSTITIAL_NOTIFICATIONS } from "../../../../../utils/routes";

const schema = yup
  .object({
    callToActionTitle: yup
      .string()
      .required("Call-to-action title is required")
      .trim(),
    landingPath: yup.lazy(() => {
      return appDestinationLinkSchema(false);
    }),
    frequencyCap: yup
      .number()
      .min(1, "Frequency cap must be a positive number"),
    triggerType: yup.string().required("Trigger type is required"),
    triggerPath: appDestinationLinkSchema(
      true,
      "Trigger page is required"
    ).when("triggerType", {
      is: (triggerType: InterstitialNotificationTriggerType) => {
        return triggerType === "PAGE_TRIGGER";
      },
      then: (schema) => schema.required("Trigger path is required"),
      otherwise: () => appDestinationLinkSchemaOptional, // make subfields not-required
    }),
    triggerDelaySeconds: yup.number().when("triggerType", {
      is: (triggerType: InterstitialNotificationTriggerType) => {
        return triggerType === "SESSION_DELAY";
      },
      then: (schema) =>
        schema
          .required("Trigger delay is required")
          .min(0, "Must be a positive number")
          .max(60 * 60, "Should be under 1 hour (3600 seconds)"),
      otherwise: (schema) => schema,
    }),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

type TriggerTypeInfo = {
  label: string;
  description: string;
};

const TRIGGER_TYPES_INFO_MAP: {
  [key in Exclude<
    InterstitialNotificationTriggerType,
    "%future added value"
  >]: TriggerTypeInfo;
} = {
  PAGE_TRIGGER: {
    label: "Page Trigger",
    description: "Show notification when a user visits a specific page",
  },
  SESSION_DELAY: {
    label: "Session Delay",
    description:
      "Show notification after the user has spent a specified amount of seconds within the app",
  },
} as const;

const query = graphql`
  query InterstitialNotificationFormQuery($id: ID!, $skip: Boolean!) {
    interstitialNotification(id: $id) @skip(if: $skip) {
      id
      callToActionTitle
      callToActionDestination
      frequencyCap
      triggerType
      triggerPath
      triggerAppViewType
      triggerDelaySeconds
      utmCampaign
      audienceLanguages
      thumbnailUrl
      audiences {
        audienceId
      }
    }
  }
`;

const brandFragment = graphql`
  fragment InterstitialNotificationForm_brand on BrandType {
    id
    deeplinkUrlScheme
    ...CDPAudienceSelectorCard_brand
  }
`;

const createMutation = graphql`
  mutation InterstitialNotificationFormCreateMutation(
    $input: GraphQLInterstitialNotificationInput!
  ) {
    createInterstitialNotification(input: $input) {
      ... on GraphQLInterstitialNotification {
        id
        ...InterstitialNotificationTableRow_notification
      }
    }
  }
`;

const updateMutation = graphql`
  mutation InterstitialNotificationFormUpdateMutation(
    $input: GraphQLInterstitialNotificationInputPartial!
  ) {
    updateInterstitialNotification(input: $input) {
      ... on GraphQLInterstitialNotification {
        id
        ...InterstitialNotificationTableRow_notification
      }
    }
  }
`;

export default function InterstitialNotificationForm({
  brand: brandKey,
  onSaveComplete,
}: {
  brand: InterstitialNotificationForm_brand$key;
  onSaveComplete?: (interstitialNotificationId: string | null) => void;
}) {
  const navType = useNavigationType();
  const navigate = useNavigate();
  const dispatch = useMobilePreviewDispatch();
  const snackbarContext = useContext(SnackbarContext);
  const interstitialId = useParams().id;
  const isEditing = interstitialId != null;
  const brand = useFragment(brandFragment, brandKey);
  const brandId = brand.id;
  const deeplinkUrlScheme = brand.deeplinkUrlScheme;
  const data = useLazyLoadQuery<InterstitialNotificationFormQuery>(query, {
    id: interstitialId ?? "",
    skip: !isEditing,
  });
  const interstitialNotification = data.interstitialNotification;
  const [createInterstitialNotification, isCreateMutationInFlight] =
    useMutation<InterstitialNotificationFormCreateMutation>(createMutation);
  const [updateInterstitialNotification, isUpdateMutationInFlight] =
    useMutation<InterstitialNotificationFormUpdateMutation>(updateMutation);

  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(
    interstitialNotification?.thumbnailUrl ?? null
  );
  const [thumbnailUploadable, setThumbnailUploadable] = useState<UploadableMap>(
    {}
  );

  const [callToActionTitle, setCallToActionTitle] = useState<string>(
    interstitialNotification?.callToActionTitle ?? ""
  );
  const [audienceIDs, setAudienceIDs] = useState<string[]>(
    (interstitialNotification?.audiences ?? []).map(
      (audience) => audience.audienceId
    )
  );

  const [audienceDatas, setAudienceDatas] = useState<
    GraphQLCDPAudienceInput[] | null
  >(null);
  const onAudiencesChange = useCallback(
    (audiences: GraphQLCDPAudienceInput[]) => {
      setAudienceIDs(audiences.map((audience) => audience.audienceId));
      setAudienceDatas(
        audiences.map((audience) => ({
          audienceId: audience.audienceId,
          audienceName: audience.audienceName,
          audienceType: audience.audienceType,
          cdpType: audience.cdpType,
        }))
      );
    },
    [setAudienceIDs, setAudienceDatas]
  );

  const [audienceLanguages, setAudienceLanguages] = useState<
    KinnLanguageCode[]
  >(interstitialNotification?.audienceLanguages?.slice() ?? []);
  const onLanguageFiltersChange = (languages: KinnLanguageCode[]) => {
    setAudienceLanguages(languages);
  };
  const [utmCampaign, setUtmCampaign] = useState<string | null>(
    interstitialNotification?.utmCampaign ?? null
  );

  useEffect(() => {
    dispatch({
      type: ReducerAction.SET_PREVIEW_TYPE,
      payload: {
        previewType: PreviewType.INTERSTITIAL_NOTIFICATION,
        interstitialNotification: {
          callToActionTitle: callToActionTitle,
          media: thumbnailUrl ?? "",
        },
      },
    });
    return () => {
      dispatch({ type: ReducerAction.RESET });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const triggerPathType: LandingPath = useMemo(() => {
    if (
      !interstitialNotification ||
      interstitialNotification?.triggerType !== "PAGE_TRIGGER" ||
      !interstitialNotification.triggerAppViewType
    ) {
      return LandingPath.CUSTOM;
    }
    const type = interstitialNotification.triggerAppViewType as LandingPath;
    if (TRIGGER_PATHS[type] == null) {
      return LandingPath.CUSTOM;
    }
    return type;
  }, [interstitialNotification]);

  const formMethods = useForm<FormData>({
    defaultValues: {
      callToActionTitle: interstitialNotification?.callToActionTitle ?? "",
      landingPath: {
        customUrl: interstitialNotification?.callToActionDestination ?? "",
        pathType:
          convertLandingPathToType(
            interstitialNotification?.callToActionDestination ?? "",
            deeplinkUrlScheme
          ) ?? "",
      },
      frequencyCap: interstitialNotification?.frequencyCap ?? 1,
      triggerType: interstitialNotification?.triggerType ?? "SESSION_DELAY",
      triggerPath: {
        customUrl: interstitialNotification?.triggerPath ?? "",
        pathType: triggerPathType,
      },
      triggerDelaySeconds: interstitialNotification?.triggerDelaySeconds,
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    resetField,
    watch,
  } = formMethods;

  const onSubmit = (data: FormData) => {
    const onError = (error: Error) => {
      snackbarContext?.openSnackbar("Something went wrong", "error");
    };
    const onCompleted = (id: string, errors: PayloadError[] | null) => {
      if (errors) {
        snackbarContext?.openSnackbar("Something went wrong", "error");
        return;
      }
      setThumbnailUploadable({});
      onSaveComplete?.(id);
      onBackClick();
      snackbarContext?.openSnackbar(
        isEditing
          ? "Interstitial notification updated"
          : "Interstitial notification created",
        "success"
      );
    };

    const triggerType = data.triggerType as InterstitialNotificationTriggerType;
    const commonData: Omit<GraphQLInterstitialNotificationInput, "brand"> = {
      audiences:
        audienceDatas === null
          ? []
          : audienceDatas.map((audience) => ({
              audienceId: audience.audienceId,
              audienceName: audience.audienceName,
              audienceType: audience.audienceType,
              cdpType: audience.cdpType,
            })),
      audienceLanguages: audienceLanguages,
      callToActionTitle: callToActionTitle,
      callToActionDestination: data.landingPath.pathType
        ? LANDING_PATHS[data.landingPath.pathType as LandingPath]!.finalPath(
            data.landingPath.customUrl ?? "",
            deeplinkUrlScheme
          )
        : null,
      frequencyCap: data.frequencyCap,
      thumbnailUploadable: thumbnailUploadable["thumbnailUploadable"],
      triggerType: triggerType,
      triggerPath:
        triggerType === "PAGE_TRIGGER" && !!data.triggerPath.pathType
          ? TRIGGER_PATHS[data.triggerPath.pathType as LandingPath]?.finalPath(
              data.triggerPath?.customUrl ?? "",
              deeplinkUrlScheme
            ) || null
          : null,
      triggerAppViewType:
        triggerType === "PAGE_TRIGGER" &&
        !!data.triggerPath?.pathType &&
        data.triggerPath?.pathType !== LandingPath.CUSTOM
          ? (data.triggerPath?.pathType as AppViewType)
          : null,
      triggerDelaySeconds:
        triggerType === "SESSION_DELAY" ? data.triggerDelaySeconds : undefined,
      utmCampaign: utmCampaign,
    };

    if (isEditing) {
      updateInterstitialNotification({
        variables: {
          input: {
            id: interstitialId,
            ...commonData,
          },
        },
        onCompleted: (data, errors) =>
          onCompleted(data.updateInterstitialNotification.id, errors),
        onError: onError,
        uploadables: thumbnailUploadable,
      });
    } else {
      createInterstitialNotification({
        variables: {
          input: {
            brand: {
              id: brandId,
            },
            ...commonData,
          },
        },
        onCompleted: (data, errors) =>
          onCompleted(data.createInterstitialNotification.id, errors),
        onError: onError,
        updater: (store) => {
          const payload = store.getRootField("createInterstitialNotification");
          const brand = store.get(brandId);
          if (brand == null) {
            return;
          }
          const tableConnection = ConnectionHandler.getConnection(
            brand,
            "InterstitialNotificationTable_interstitialNotifications",
            {
              filters: {
                audienceIds: [],
                audienceLanguages: [],
                isArchived: false,
              },
            }
          );
          if (tableConnection == null) {
            console.log("*** tableConnection is null");
            return;
          }

          const newEdge = ConnectionHandler.createEdge(
            store,
            tableConnection,
            payload,
            "GraphQLInterstitialNotificationEdge"
          );
          ConnectionHandler.insertEdgeBefore(tableConnection, newEdge);

          // Increment total count
          const notifCount = tableConnection.getValue("totalCount");
          if (notifCount != null) {
            tableConnection.setValue(Number(notifCount) + 1, "totalCount");
          }
        },
        uploadables: thumbnailUploadable,
      });
    }
  };

  const onBackClick = () => {
    if (navType === "PUSH") {
      navigate(-1);
    } else {
      navigate(ENGAGE_AUTOMATIONS_INTERSTITIAL_NOTIFICATIONS);
    }
  };

  const triggerType = watch(
    "triggerType"
  ) as InterstitialNotificationTriggerType;

  const createCard = (
    <CardSection
      title={"Compose your interstitial notification"}
      content={
        <Stack spacing={2} width="100%">
          <UploadedFileCard
            fileURL={thumbnailUrl}
            fileType=".svg, .png, .jpg"
            htmlLabel="interstitial-asset"
            mediaType={"PHOTO"}
            inputLabel="Interstitial Asset"
            inputText={"Upload a photo"}
            onClose={() => {
              setThumbnailUrl(null);
              setThumbnailUploadable({});
              dispatch({
                type: ReducerAction.UPDATE_INTERSTITIAL_NOTIFICATION,
                payload: {
                  notification: {
                    media: "",
                  },
                },
              });
            }}
            onUpload={(event: React.ChangeEvent<HTMLInputElement>) => {
              const file = event?.target?.files?.[0];
              if (file == null) {
                return;
              }
              const url = URL.createObjectURL(file);
              dispatch({
                type: ReducerAction.UPDATE_INTERSTITIAL_NOTIFICATION,
                payload: {
                  interstitialNotification: {
                    media: url,
                  },
                },
              });
              setThumbnailUrl(url);
              const newUploadables: UploadableMap = {};
              newUploadables["thumbnailUploadable"] = file;
              setThumbnailUploadable(newUploadables);
            }}
          />

          <TextField
            {...register("callToActionTitle", {
              onChange: (event: FocusEvent<HTMLInputElement>) => {
                setCallToActionTitle(event.target.value);
                dispatch({
                  type: ReducerAction.UPDATE_INTERSTITIAL_NOTIFICATION,
                  payload: {
                    interstitialNotification: {
                      callToActionTitle: event.target.value,
                    },
                  },
                });
              },
            })}
            error={!!errors?.callToActionTitle}
            helperText={errors?.callToActionTitle?.message}
            sx={{
              flexGrow: 1,
            }}
            margin="normal"
            label="Call-to-Action Text"
            variant="outlined"
            value={callToActionTitle}
            inputProps={{
              maxLength: 150,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {callToActionTitle != null ? callToActionTitle.length : 0}/150
                </InputAdornment>
              ),
            }}
          />

          <Divider />
          <AppDestinationLink
            destinationTypeLabel="Call-to-Action Destination Type"
            customUrlLabel="Call-to-Action URL"
            landingPaths={LANDING_PATHS}
            fieldGroupName="landingPath"
            allowReset={true}
          />

          <Divider />
          <Controller
            render={({ field }) => {
              const { onChange, ...fieldProps } = field;
              return (
                <FormControl fullWidth error={!!errors?.triggerType}>
                  <InputLabel>Trigger Type</InputLabel>
                  <Select
                    {...fieldProps}
                    displayEmpty
                    label="Trigger Type"
                    onChange={(event) => {
                      onChange(event);
                      resetField("triggerPath", {
                        defaultValue: undefined,
                      });
                      resetField("triggerDelaySeconds", {
                        defaultValue: undefined,
                      });
                    }}
                    renderValue={(value) => {
                      return TRIGGER_TYPES_INFO_MAP[
                        value as keyof typeof TRIGGER_TYPES_INFO_MAP
                      ].label;
                    }}
                    error={!!errors?.triggerType}
                  >
                    {Object.entries(TRIGGER_TYPES_INFO_MAP).map(
                      ([type, info]) => {
                        return (
                          <MenuItem key={type} value={type}>
                            <Typography variant="body1">
                              {`${info.label} -`}&nbsp;
                            </Typography>
                            <span> </span>
                            <Typography variant="body2" color="text.secondary">
                              {info.description}
                            </Typography>
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                  {!!errors?.triggerType && (
                    <FormHelperText>
                      {errors.triggerType?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              );
            }}
            control={control}
            name="triggerType"
          />

          {triggerType === "PAGE_TRIGGER" && (
            <AppDestinationLink
              destinationTypeLabel="Trigger Page"
              customUrlLabel="Trigger Page URL"
              landingPaths={TRIGGER_PATHS}
              fieldGroupName="triggerPath"
            />
          )}

          {triggerType === "SESSION_DELAY" && (
            <TextField
              {...register("triggerDelaySeconds")}
              error={!!errors?.triggerDelaySeconds}
              helperText={errors?.triggerDelaySeconds?.message}
              margin="normal"
              label={"Notification Delay From Session Start (seconds)"}
              variant="outlined"
            />
          )}

          <Divider />
          <TextField
            {...register("frequencyCap")}
            error={!!errors?.frequencyCap}
            helperText={errors?.frequencyCap?.message}
            margin="normal"
            label={"Frequency Cap"}
            variant="outlined"
          />
        </Stack>
      }
    />
  );

  const utmCard = <UTMCard campaign={utmCampaign} onChange={setUtmCampaign} />;

  const audienceCard = (
    <CDPAudienceSelectorCard
      audienceIDs={audienceIDs}
      brand={brand}
      subtitle={"Choose who you want to see the interstitial"}
      audienceLanguages={audienceLanguages}
      onAudiencesChange={onAudiencesChange}
      onLanguageFiltersChange={onLanguageFiltersChange}
    />
  );

  const saveButton = (
    <Box display="flex" justifyContent="flex-end">
      <Button
        type="submit"
        disabled={
          thumbnailUrl == null ||
          isCreateMutationInFlight ||
          isUpdateMutationInFlight
        }
        variant="contained"
        size="large"
        endIcon={<ArrowForwardIcon color="inherit" />}
      >
        {isEditing ? "Save Changes" : "Create Interstitial Notification"}
      </Button>
    </Box>
  );

  return (
    <FormProvider {...formMethods}>
      <Stack
        component={"form"}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        spacing={2}
        width="100%"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box display="flex" justifyContent="flex-start">
          <Button
            startIcon={<ArrowBackIcon />}
            variant="text"
            onClick={onBackClick}
          >
            Your Interstitial Notifications
          </Button>
        </Box>
        {createCard}
        {audienceCard}
        {utmCard}
        {saveButton}
      </Stack>
    </FormProvider>
  );
}
