import { Container } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { NavigationTabQuery } from "./__generated__/NavigationTabQuery.graphql";
import { NavigationTab_brand$key } from "./__generated__/NavigationTab_brand.graphql";
import {
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../contexts/MobilePreviewContext";
import CartCard from "./navigation/CartCard";
import GorgiasCard from "./navigation/GorgiasCard";
import MenuCard from "./navigation/MenuCard";
import ShortcutsCard from "./navigation/ShortcutsCard";
import TabsCard2 from "./navigation/TabsCard2";
import WishlistCard from "./navigation/WishlistCard";

const query = graphql`
  query NavigationTabQuery($id: ID!) {
    brand(id: $id) {
      ...NavigationTab_brand
    }
  }
`;

const brandFragment = graphql`
  fragment NavigationTab_brand on BrandType {
    ...CartCard_brand
    ...GorgiasCard_brand
    ...MenuCard_brand
    ...ShortcutsCard_brand
    ...TabsCard2_brand
    ...WishlistCard_brand
  }
`;

const NavigationTab = ({ brandID }: { brandID: string }) => {
  const dispatch = useMobilePreviewDispatch();
  const data = useLazyLoadQuery<NavigationTabQuery>(query, {
    id: brandID,
  });
  const brand = useFragment<NavigationTab_brand$key>(brandFragment, data.brand);

  useEffect(() => {
    return () =>
      dispatch({
        type: ReducerAction.RESET_NAVIGATION,
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="md">
      <TabsCard2 brand={brand} />
      <ShortcutsCard brand={brand} />
      <MenuCard brand={brand} />
      <GorgiasCard brand={brand} />
      <WishlistCard brand={brand} />
      <CartCard brand={brand} />
    </Container>
  );
};

export default NavigationTab;
