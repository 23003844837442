import { Box, Card, CardActionArea, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { InternHome_user$key } from "./__generated__/InternHome_user.graphql";

const brandFragment = graphql`
  fragment InternHome_user on UserType {
    brandAdminProfiles {
      brand {
        id
        displayName
        appConfig {
          appState
        }
      }
    }
  }
`;

const InternHome = ({ user: userKey }: { user: InternHome_user$key }) => {
  const user = useFragment(brandFragment, userKey);
  const brandProfiles = user.brandAdminProfiles ?? [];
  const navigate = useNavigate();
  return (
    <Box>
      {brandProfiles.map((profile, index) => {
        const appState = profile.brand.appConfig.appState;
        const rowColor =
          appState === "PENDING_KINN_SETUP"
            ? "#ffb3ad"
            : "PENDING_BRAND_SETUP"
            ? "#ffeaac"
            : "#c2edc2";

        return (
          <Card
            key={index}
            onClick={() =>
              navigate(`/intern/brands/${profile.brand.id}/overview`)
            }
            sx={{
              borderBottom: `1px solid ${grey[300]}`,
            }}
            elevation={0}
          >
            <CardActionArea
              sx={{
                height: "48px",
              }}
            >
              <Stack
                direction="row"
                sx={{
                  height: "100%",
                }}
                alignItems={"center"}
                spacing={2}
              >
                <Box
                  sx={{
                    height: "100%",
                    backgroundColor: rowColor,
                    width: "12px",
                  }}
                />
                <Typography variant="body1">
                  {profile.brand.displayName}
                </Typography>
              </Stack>
            </CardActionArea>
          </Card>
        );
      })}
    </Box>
  );
};

export default InternHome;
