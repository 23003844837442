/**
 * @generated SignedSource<<68654984e8307ea120f78270066f18e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppDistributionCard_brand$data = {
  readonly appConfig: {
    readonly androidAppStoreLink: string | null;
    readonly iosAppStoreLink: string | null;
  };
  readonly finishedOnboarding: boolean;
  readonly id: string;
  readonly slug: string;
  readonly " $fragmentType": "AppDistributionCard_brand";
};
export type AppDistributionCard_brand$key = {
  readonly " $data"?: AppDistributionCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppDistributionCard_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppDistributionCard_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finishedOnboarding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "iosAppStoreLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "androidAppStoreLink",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "afe2c895e1cf4ac96eed2bdaed4913e4";

export default node;
