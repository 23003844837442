import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { graphql } from "babel-plugin-relay/macro";
import { FocusEvent, useCallback, useContext, useMemo, useState } from "react";
import {
  fetchQuery,
  useFragment,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import { ShortcutsCardNavigationItemsUpdateMutation } from "./__generated__/ShortcutsCardNavigationItemsUpdateMutation.graphql";
import { ShortcutsCardQuery } from "./__generated__/ShortcutsCardQuery.graphql";
import { ShortcutsCard_brand$key } from "./__generated__/ShortcutsCard_brand.graphql";
import CardSection from "../../../../components/CardSection";
import LeftRight from "../../../../components/LeftRight";
import SaveButton, { SavedState } from "../../../../components/SaveButton";
import {
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../../contexts/MobilePreviewContext";
import SnackbarContext from "../../../../contexts/SnackbarContext";
import DrawerContext from "../contexts/DrawerContext";
import ShortcutsCardDrawer from "./ShortcutsCardDrawer";

const parentKey = "shortcuts-card-";
const nestedKey = "shortcuts-card-nested-";
const parentDroppable = parentKey + "droppable";
const nestedDroppable = nestedKey + "droppable";

type NavigationItemGraphQL = {
  label: string;
  url: string;
  index: number;
  children?: ReadonlyArray<NavigationItemGraphQL>;
};

export type NavigationItem = {
  label: string;
  url: string;
  children: ReadonlyArray<NavigationItem>;
};

const importQuery = graphql`
  query ShortcutsCardQuery($brandID: ID!) {
    navigationItemsImported(brandId: $brandID) {
      index
      label
      url
      children {
        index
        label
        url
        children {
          index
          label
          url
        }
      }
    }
  }
`;

const updateMutation = graphql`
  mutation ShortcutsCardNavigationItemsUpdateMutation(
    $input: UpdateNavigationItemsInput!
  ) {
    updateNavigationItems(input: $input) {
      ... on BrandType {
        ...ShortcutsCard_brand
      }
    }
  }
`;

const brandFragment = graphql`
  fragment ShortcutsCard_brand on BrandType {
    id
    appConfig {
      id
      shouldLockMenus
      navigationItems {
        index
        label
        url
        children {
          index
          label
          url
          children {
            index
            label
            url
          }
        }
      }
    }
    shopifyConfig {
      isConnected
    }
  }
`;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  paddingTop: 16,

  // change background colour if dragging
  // background: "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const NestedShortcutItemRow = ({
  index,
  parentIndex,
  titleLabel,
  url,
  children,
  onDelete,
  onTextChange,
  onUrlChange,
}: {
  index: number;
  parentIndex: number;
  titleLabel: string;
  url: string;
  children: ReadonlyArray<NavigationItem>;
  onDelete: () => void;
  onTextChange: (childIndex: number, text: string) => void;
  onUrlChange: (childIndex: number, text: string) => void;
}) => {
  const {
    setIsDrawerOpen,
    setCurrentIndex,
    setParentIndex,
    setTitle,
    setChildren,
  } = useContext(DrawerContext);
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        background: "#FDFDFE",
      }}
    >
      <DragIndicatorIcon />
      <TextField
        sx={{
          flexGrow: 1,
        }}
        margin="normal"
        label={"Nested Shortcut Title"}
        variant="outlined"
        value={titleLabel}
        onChange={(event: FocusEvent<HTMLInputElement>) => {
          onTextChange(index, event.target.value);
        }}
        inputProps={{
          maxLength: 255,
        }}
      />
      <TextField
        sx={{
          flexGrow: 1,
          "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
        id="outlined-basic"
        label="Link"
        variant="outlined"
        value={url}
        onChange={(event: FocusEvent<HTMLInputElement>) => {
          onUrlChange(index, event.target.value);
        }}
      />
      <Stack direction="row">
        <Button
          disabled={false}
          size="small"
          variant="text"
          color="primary"
          onClick={() => {
            setParentIndex(parentIndex);
            setCurrentIndex(index);
            setTitle(titleLabel);
            setChildren(children);
            setIsDrawerOpen(true);
          }}
        >
          Edit
        </Button>
        <Button size="small" variant="text" color="error" onClick={onDelete}>
          Delete
        </Button>
      </Stack>
    </Stack>
  );
};

const ShortcutItemRow = ({
  parentIndex,
  titleLabel,
  url,
  nestedRows,
  onDelete,
  onAddNestedShortcut,
  onDeleteNestedShortcut,
  onTextChange,
  onUrlChange,
  onNestedTextChange,
  onNestedUrlChange,
}: {
  parentIndex: number;
  titleLabel: string;
  url: string;
  nestedRows: ReadonlyArray<NavigationItem>;
  onDelete: () => void;
  onAddNestedShortcut: () => void;
  onDeleteNestedShortcut: (index: number) => void;
  onTextChange: (event: FocusEvent<HTMLInputElement>) => void;
  onUrlChange: (event: FocusEvent<HTMLInputElement>) => void;
  onNestedTextChange: (childIndex: number, text: string) => void;
  onNestedUrlChange: (childIndex: number, text: string) => void;
}) => {
  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <DragIndicatorIcon
          sx={{
            marginTop: 2,
          }}
        />
        <Stack
          sx={{
            borderRadius: "8px",
            border: "1px solid #EEEFF8",
            background: "#FDFDFE",
            p: 2,
          }}
          width={"100%"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <TextField
              sx={{
                flexGrow: 1,
              }}
              inputProps={{
                maxLength: 255,
              }}
              // margin="normal"
              label={"Shortcut Title"}
              variant="outlined"
              value={titleLabel}
              onChange={onTextChange}
            />
            <TextField
              sx={{
                flexGrow: 1,
                "& .MuiInputBase-input": {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
              id="outlined-basic"
              label="Link"
              variant="outlined"
              value={url}
              onChange={onUrlChange}
            />
            <Button
              size="small"
              variant="text"
              color="error"
              onClick={onDelete}
            >
              Delete
            </Button>
          </Stack>
          <Box ml={5}>
            <Droppable
              droppableId={nestedDroppable + "-" + parentIndex}
              type={"NESTED-" + parentIndex}
            >
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {nestedRows.map((row, childIndex: number) => {
                    return (
                      <Draggable
                        key={childIndex}
                        draggableId={
                          nestedKey +
                          "-draggable-" +
                          parentIndex +
                          "-" +
                          childIndex
                        }
                        index={childIndex}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <NestedShortcutItemRow
                              index={childIndex}
                              parentIndex={parentIndex}
                              titleLabel={row.label}
                              url={row.url}
                              children={row.children}
                              onDelete={() =>
                                onDeleteNestedShortcut(childIndex)
                              }
                              onTextChange={onNestedTextChange}
                              onUrlChange={onNestedUrlChange}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Button
              sx={{
                mt: 2,
              }}
              disabled={false}
              variant="text"
              onClick={onAddNestedShortcut}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} />
              Add Nested Shortcut
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

const areInputsEmpty = (
  navigationItems: ReadonlyArray<NavigationItem>
): boolean => {
  return (
    navigationItems.find((shortcut) => {
      if (shortcut.children.length === 0) {
        return shortcut.label === "";
      }
      return shortcut.label === "" || areInputsEmpty(shortcut.children);
    }) !== undefined
  );
};

export const convertFromGraphQL = (
  navigationItems: ReadonlyArray<NavigationItemGraphQL>
): ReadonlyArray<NavigationItem> => {
  return Array.from(navigationItems)
    .sort((a, b) => a.index - b.index)
    .map((item) => {
      if (item.children == null || item.children.length === 0) {
        return {
          label: item.label,
          url: item.url,
          children: [],
        };
      }
      return {
        label: item.label,
        url: item.url,
        children: convertFromGraphQL(item.children),
      };
    });
};

const convertFromLocal = (
  navigationItems: ReadonlyArray<NavigationItem>
): ReadonlyArray<NavigationItemGraphQL> => {
  return Array.from(navigationItems).map((item, index) => {
    if (item.children.length === 0) {
      return {
        index,
        label: item.label,
        url: item.url,
        children: [],
      };
    }
    return {
      index,
      label: item.label,
      url: item.url,
      children: convertFromLocal(item.children),
    };
  });
};

const ShortcutsCard = ({
  brand: brandKey,
}: {
  brand: ShortcutsCard_brand$key;
}) => {
  const dispatch = useMobilePreviewDispatch();
  const snackbarContext = useContext(SnackbarContext);
  const environment = useRelayEnvironment();
  const brand = useFragment<ShortcutsCard_brand$key>(brandFragment, brandKey);
  const appConfig = brand.appConfig;
  const shouldLockMenus = appConfig.shouldLockMenus;
  const navigationItems = appConfig.navigationItems;
  const areShortcutsSet = navigationItems.length !== 0;
  const isShopifyConnected = brand?.shopifyConfig?.isConnected === true;

  const [shortcuts, setShortcuts] = useState(
    !areShortcutsSet
      ? [0].map((_, index) => ({
          label: "",
          url: "",
          children: [],
        }))
      : convertFromGraphQL(navigationItems)
  );

  // DRAWER CONTEXT
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [parentIndex, setParentIndex] = useState<number | null>(null);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [drawerChildren, setDrawerChildren] = useState<
    ReadonlyArray<NavigationItem>
  >([]);

  const [isImporting, setIsImporting] = useState(false);
  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    areShortcutsSet ? SavedState.SAVED : SavedState.ENABLED
  );
  const enableSaveButton = useCallback(() => {
    saveButtonState !== SavedState.ENABLED &&
      setSaveButtonState(SavedState.ENABLED);
  }, [saveButtonState, setSaveButtonState]);

  const [updateNavItems, isMutationInFlight] =
    useMutation<ShortcutsCardNavigationItemsUpdateMutation>(updateMutation);

  const toggle1 = (
    <Stack spacing={1}>
      <LeftRight
        expandLeft
        left={
          isShopifyConnected ? (
            <>
              <Typography variant="subtitle1">
                Customize or import your shortcuts
              </Typography>
              <Typography variant="body2">
                You can import shortcuts from your Shopify website first, or
                manually create and edit shortcuts yourself.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="subtitle1">
                Customize your shortcuts
              </Typography>
              <Typography variant="body2">
                Customize your shortcuts (e.g. include more direct shortcuts to
                categories, collections, and external links).
              </Typography>
            </>
          )
        }
        right={
          isShopifyConnected ? (
            <Button
              sx={{
                boxShadow:
                  "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
                borderRadius: "8px",
                flexShrink: 0,
              }}
              color="secondaryLight"
              startIcon={
                isImporting ? (
                  <CircularProgress size={16} color="inherit" />
                ) : null
              }
              disabled={isImporting}
              size="medium"
              variant="contained"
              onClick={() => {
                fetchQuery<ShortcutsCardQuery>(environment, importQuery, {
                  brandID: brand.id,
                }).subscribe({
                  start: () => {
                    setIsImporting(true);
                  },
                  complete: () => {
                    setIsImporting(false);
                  },
                  error: (_: Error) => {
                    setIsImporting(false);
                  },
                  next: (data) => {
                    setShortcuts(
                      convertFromGraphQL(data.navigationItemsImported)
                    );
                    enableSaveButton();
                  },
                });
              }}
            >
              {isImporting ? "Importing" : "Import all"}
            </Button>
          ) : null
        }
      />
    </Stack>
  );

  const customizationSection = (
    <DragDropContext
      onDragEnd={(result) => {
        const { destination, source } = result;

        if (!destination) {
          return;
        }

        if (
          destination.droppableId !== source.droppableId ||
          destination.index === source.index
        ) {
          return;
        }

        if (result.type === "PARENT") {
          const newRows = Array.from(shortcuts);
          const [removed] = newRows.splice(source.index, 1);
          newRows.splice(destination.index, 0, removed);
          setShortcuts(newRows);
          dispatch({
            type: ReducerAction.UPDATE_NAVIGATION,
            payload: {
              navigation: {
                shortcutsPills: newRows,
              },
            },
          });
        } else {
          // Children types are in the form of NESTED-{parentIndex}
          const parentIndex = parseInt(result.type.split("-")[1]);
          const parentObject = shortcuts[parentIndex];
          const children = parentObject.children;

          const newChildren = Array.from(children);
          const [removed] = newChildren.splice(source.index, 1);
          newChildren.splice(destination.index, 0, removed);

          const newRows = Array.from(shortcuts);
          newRows[parentIndex] = {
            label: parentObject.label,
            url: parentObject.url,
            children: newChildren,
          };

          setShortcuts(newRows);
          dispatch({
            type: ReducerAction.UPDATE_NAVIGATION,
            payload: {
              navigation: {
                shortcutsPills: newRows,
              },
            },
          });
        }
        enableSaveButton();
      }}
    >
      <Box
        // spacing={2}
        sx={{
          opacity: isImporting ? "40%" : undefined,
          pointerEvents: isImporting ? "none" : undefined,
        }}
      >
        <Droppable droppableId={parentDroppable} type="PARENT">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {shortcuts.map((shortcut, index) => {
                const onTextChange = (event: FocusEvent<HTMLInputElement>) => {
                  const newValue = {
                    ...shortcut,
                    label: event.target.value,
                  };
                  const newArray = Array.from(shortcuts);
                  newArray[index] = newValue;
                  dispatch({
                    type: ReducerAction.UPDATE_NAVIGATION,
                    payload: {
                      navigation: {
                        shortcutsPills: newArray,
                      },
                    },
                  });
                  setShortcuts(newArray);
                  enableSaveButton();
                };
                const onUrlChange = (event: FocusEvent<HTMLInputElement>) => {
                  const newValue = {
                    ...shortcut,
                    url: event.target.value,
                  };
                  const newArray = Array.from(shortcuts);
                  newArray[index] = newValue;

                  setShortcuts(newArray);
                  enableSaveButton();
                };

                const onNestedTextChange = (
                  childIndex: number,
                  text: string
                ) => {
                  const newChildren = Array.from(shortcut.children);
                  newChildren[childIndex] = {
                    ...newChildren[childIndex],
                    label: text,
                  };

                  const newRows = Array.from(shortcuts);
                  newRows[index] = {
                    ...shortcut,
                    children: newChildren,
                  };

                  dispatch({
                    type: ReducerAction.UPDATE_NAVIGATION,
                    payload: {
                      navigation: {
                        shortcutsPills: newRows,
                      },
                    },
                  });
                  setShortcuts(newRows);
                  enableSaveButton();
                };
                const onNestedUrlChange = (
                  childIndex: number,
                  text: string
                ) => {
                  const newChildren = Array.from(shortcut.children);
                  newChildren[childIndex] = {
                    ...newChildren[childIndex],
                    url: text,
                  };

                  const newRows = Array.from(shortcuts);
                  newRows[index] = {
                    ...shortcut,
                    children: newChildren,
                  };

                  setShortcuts(newRows);
                  enableSaveButton();
                };

                const onDelete = () => {
                  const test = Array.from(shortcuts);
                  test.splice(index, 1);
                  setShortcuts(test);
                  enableSaveButton();
                  dispatch({
                    type: ReducerAction.UPDATE_NAVIGATION,
                    payload: {
                      navigation: {
                        shortcutsPills: test,
                      },
                    },
                  });
                };

                const onAddNestedShortcut = () => {
                  const newRows = Array.from(shortcuts);
                  newRows[index] = {
                    ...shortcut,
                    children: shortcut.children.concat([
                      {
                        label: "",
                        url: "",
                        children: [],
                      },
                    ]),
                  };

                  setShortcuts(newRows);
                  enableSaveButton();
                };

                const onDeleteNestedShortcut = (childIndex: number) => {
                  const newChildren = Array.from(shortcut.children);
                  newChildren.splice(childIndex, 1);

                  const newRows = Array.from(shortcuts);
                  newRows[index] = {
                    ...shortcut,
                    children: newChildren,
                  };

                  setShortcuts(newRows);
                  enableSaveButton();
                };

                return (
                  <Draggable
                    key={index}
                    draggableId={parentKey + "-draggable-" + index}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <ShortcutItemRow
                          parentIndex={index}
                          titleLabel={shortcut.label}
                          url={shortcut.url}
                          onDelete={onDelete}
                          nestedRows={shortcut.children}
                          onTextChange={onTextChange}
                          onUrlChange={onUrlChange}
                          onAddNestedShortcut={onAddNestedShortcut}
                          onDeleteNestedShortcut={onDeleteNestedShortcut}
                          onNestedTextChange={onNestedTextChange}
                          onNestedUrlChange={onNestedUrlChange}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Button
          sx={{
            width: "auto",
            mt: 2,
          }}
          disabled={false}
          color="secondaryLight"
          variant="contained"
          onClick={() => {
            const newRow = shortcuts.concat([
              {
                label: "",
                url: "",
                children: [],
              },
            ]);
            dispatch({
              type: ReducerAction.UPDATE_NAVIGATION,
              payload: {
                navigation: {
                  shortcutsPills: newRow,
                },
              },
            });
            setShortcuts(newRow);
            enableSaveButton();
          }}
        >
          <AddCircleOutlineIcon sx={{ mr: 1 }} />
          Add Shortcut
        </Button>
      </Box>
    </DragDropContext>
  );

  const providerValue = useMemo(
    () => ({
      isDrawerOpen,
      setIsDrawerOpen,
      currentIndex,
      setCurrentIndex,
      parentIndex,
      setParentIndex,
      enableSaveButton,
      title: drawerTitle,
      children: drawerChildren,
      setTitle: setDrawerTitle,
      setChildren: setDrawerChildren,
    }),
    [
      isDrawerOpen,
      setIsDrawerOpen,
      currentIndex,
      setCurrentIndex,
      parentIndex,
      setParentIndex,
      enableSaveButton,
      drawerTitle,
      drawerChildren,
      setDrawerTitle,
      setDrawerChildren,
    ]
  );

  return (
    <DrawerContext.Provider value={providerValue}>
      <CardSection
        actions={
          <SaveButton
            savedState={
              isMutationInFlight || areInputsEmpty(shortcuts)
                ? SavedState.DISABLED
                : saveButtonState
            }
            onClick={() => {
              updateNavItems({
                variables: {
                  input: {
                    brandId: brand.id,
                    navigationItems: convertFromLocal(shortcuts),
                  },
                },
                onCompleted: (_, errors) => {
                  if (errors) {
                    snackbarContext?.openSnackbar("Update Failed", "error");
                  } else {
                    setSaveButtonState(SavedState.SAVED);
                    snackbarContext?.openSnackbar(
                      "Update Successful",
                      "success"
                    );
                  }
                },
                onError: (error: Error) => {
                  snackbarContext?.openSnackbar("Update Failed", "error");
                },
              });
            }}
          />
        }
        lockedContent={
          shouldLockMenus
            ? "This can't be edited due to multilingual support"
            : undefined
        }
        title="Shortcuts"
        subtitle="Shortcuts live on top of the app so your customers can quickly
              access new collections, categories, or links. You can either import shortcuts from the navigation bar of your
              Shopify website, or customize your own shortcuts."
        content={
          <Stack spacing={2}>
            {toggle1}
            {customizationSection}
          </Stack>
        }
      />
      <ShortcutsCardDrawer shortcuts={shortcuts} setShortcuts={setShortcuts} />
    </DrawerContext.Provider>
  );
};

export default ShortcutsCard;
