import { alpha, createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    secondaryLight: Palette["primary"];
  }

  interface PaletteOptions {
    secondaryLight?: PaletteOptions["primary"];
  }
}

// Update the Button's color options to include an ochre option
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    secondaryLight: true;
  }
}

export const PRIMARY_COLOR = "#FF6B6B";
let theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      light: "#FFCFC5",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#5A62B8",
      light: "#EDEEF7",
    },
    secondaryLight: {
      main: alpha("#5A62B8", 0.1),
      dark: alpha("#5A62B8", 0.2),
      light: alpha("#5A62B8", 0.05),
      contrastText: "#5A62B8",
    },
    info: {
      main: "#EDE3E3",
    },
  },
  typography: {
    h4: {
      color: "black", // TODO: Explicitly set due to Mobile Preview theme causing a weird override
      fontWeight: 700,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    subtitle1: {
      color: "#111827",
      fontWeight: 600,
    },
    body2: {
      color: "#6B7280",
      fontWeight: 400,
    },
    overline: {
      fontWeight: 600,
    },
    button: {
      textTransform: "none",
      fontWeight: 600,
      lineHeight: "26px",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 950,
      lg: 1200,
      xl: 1450,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        color: "secondary",
        style: {
          borderRadius: "8px",
        },
      },
    },
    MuiCard: {
      variants: [
        {
          props: { variant: "elevation" },
          style: {
            boxShadow:
              "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
            font-family: "GT-Flexa";
            src: url("https://kinntech.blob.core.windows.net/kinn-public-assets/GT-Flexa-Standard-Medium.ttf");
        }
      `,
    },
  },
});

// theme = createTheme(theme, {
//   palette: {
//     secondaryLight: theme.palette.augmentColor({
//       color: {
//         main: "#EDEEF7",
//       },
//       name: "secondaryLight",
//     }),
//   },
// });

export default theme;
