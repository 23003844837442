import { Box, TableCell, Tooltip, Typography } from "@mui/material";
import { KinnLanguageCode } from "../utils/languageMap";

export default function CDPAudienceInfoTableCell({
  item,
}: {
  readonly item: {
    audiences: readonly {
      audienceName: string;
    }[];
    audienceLanguages: readonly KinnLanguageCode[] | null;
  };
}) {
  const audienceName =
    (item?.audiences).map((audience) => audience.audienceName).join(", ") ||
    "All Customers";
  return (
    <TableCell align="center">
      <Tooltip title={audienceName} placement="top">
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
          }}
        >
          {audienceName}
          {!!item.audienceLanguages?.length && (
            <Box>
              <Typography variant="caption" color="text.secondary">
                {item.audienceLanguages.join(", ")}
              </Typography>
            </Box>
          )}
        </Box>
      </Tooltip>
    </TableCell>
  );
}
