import { Container } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { MappingTabQuery } from "./__generated__/MappingTabQuery.graphql";
import { MappingTab_brand$key } from "./__generated__/MappingTab_brand.graphql";
import AppLayoutCard from "./mapping/AppLayoutCard";
import HtmlConfigCard from "./mapping/HtmlConfigCard";

const query = graphql`
  query MappingTabQuery($id: ID!, $skip: Boolean!) {
    brand(id: $id) {
      ...MappingTab_brand
    }
  }
`;

const brandFragment = graphql`
  fragment MappingTab_brand on BrandType {
    id
    ...HtmlConfigCard_brand
    ...AppLayoutCard_brand @skip(if: $skip)
  }
`;

const MappingTab = ({
  brandID,
  isStaff,
}: {
  brandID: string;
  isStaff: boolean;
}) => {
  const data = useLazyLoadQuery<MappingTabQuery>(query, {
    id: brandID,
    skip: !isStaff,
  });
  const brand = useFragment<MappingTab_brand$key>(brandFragment, data.brand);

  return (
    <Container maxWidth="md">
      {isStaff && <AppLayoutCard brand={brand} />}

      <HtmlConfigCard brand={brand} />
    </Container>
  );
};

export default MappingTab;
