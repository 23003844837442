/**
 * @generated SignedSource<<5cd05a7bde18f3e66eccdb12732bed2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppTrackingConfig = "CONVERSION_BEAR" | "CUSTOM" | "ISENSE" | "ONE_TRUST" | "TERMLY" | "TRANSCEND" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CookiesCard_brand$data = {
  readonly appConfig: {
    readonly id: string;
    readonly trackingConfig: {
      readonly configType: AppTrackingConfig;
      readonly consentLocalStorageSettings: ReadonlyArray<{
        readonly enabled: boolean;
        readonly name: string;
      }>;
      readonly consentSessionStorageSettings: ReadonlyArray<{
        readonly enabled: boolean;
        readonly name: string;
      }>;
      readonly consentSettings: ReadonlyArray<{
        readonly enabled: boolean;
        readonly name: string;
      }>;
      readonly id: string;
      readonly nonConsentLocalStorageSettings: ReadonlyArray<{
        readonly enabled: boolean;
        readonly name: string;
      }>;
      readonly nonConsentSessionStorageSettings: ReadonlyArray<{
        readonly enabled: boolean;
        readonly name: string;
      }>;
      readonly nonConsentSettings: ReadonlyArray<{
        readonly enabled: boolean;
        readonly name: string;
      }>;
      readonly websiteId: string;
      readonly " $fragmentSpreads": FragmentRefs<"CookiesCardCustomInput_trackingConfig">;
    } | null;
  };
  readonly " $fragmentType": "CookiesCard_brand";
};
export type CookiesCard_brand$key = {
  readonly " $data"?: CookiesCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"CookiesCard_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enabled",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CookiesCard_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLBrandTrackingConfig",
          "kind": "LinkedField",
          "name": "trackingConfig",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CookiesCardCustomInput_trackingConfig"
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "configType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TrackingConfigSetting",
              "kind": "LinkedField",
              "name": "consentSettings",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TrackingConfigSetting",
              "kind": "LinkedField",
              "name": "nonConsentSettings",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TrackingConfigSetting",
              "kind": "LinkedField",
              "name": "consentSessionStorageSettings",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TrackingConfigSetting",
              "kind": "LinkedField",
              "name": "nonConsentSessionStorageSettings",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TrackingConfigSetting",
              "kind": "LinkedField",
              "name": "consentLocalStorageSettings",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TrackingConfigSetting",
              "kind": "LinkedField",
              "name": "nonConsentLocalStorageSettings",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "websiteId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "a295f16d6f3ed6d8323dfb94adf48271";

export default node;
