import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import TouchAppOutlinedIcon from "@mui/icons-material/TouchAppOutlined";
import { Box, Grid, Stack } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { Suspense, useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useGateWithExposureLoggingDisabled } from "statsig-react";
import { InsightsTabNotificationsQuery } from "./__generated__/InsightsTabNotificationsQuery.graphql";
import InsightsCard from "../../../components/InsightsCard";
import TablePlaceholder from "../../../components/placeholders/TablePlaceholder";
import DebugContext from "../../../contexts/DebugContext";
import formatNumber from "../../../utils/formatNumber";
import InsightsTabAutomatedNotificationsTable from "./InsightsTabAutomatedNotificationsTable";
import InsightsTabNotificationsTable from "./InsightsTabNotificationsTable";
import {
  KDateRange,
  getPreviousDateRangeWindow,
  getTimeWindowOptionForDateRange,
} from "./types/KDateRange";

const query = graphql`
  query InsightsTabNotificationsQuery(
    $brandId: ID!
    $endDate: Date
    $startDate: Date
    $comparisonEndDate: Date!
    $comparisonStartDate: Date!
  ) {
    brand(id: $brandId) {
      ...InsightsTabNotificationsTable_brand
      ...InsightsTabAutomatedNotificationsTable_brand
      id
      metricsDaily(
        filters: {
          date: { lte: $endDate, gte: $startDate }
          os: { isNull: true }
        }
      ) {
        edges {
          node {
            pushNotificationDeliveries
            pushNotificationTaps
          }
        }
      }
      comparisonMetrics: metricsDaily(
        filters: {
          date: { lte: $comparisonEndDate, gte: $comparisonStartDate }
          os: { isNull: true }
        }
      ) {
        edges {
          node {
            pushNotificationDeliveries
            pushNotificationTaps
          }
        }
      }
    }
  }
`;

type Props = {
  brandID: string;
  dateRange: KDateRange;
};

const InsightsTabNotifications = ({ brandID, dateRange }: Props) => {
  const { value: isAutomatedSectionEnabled } =
    useGateWithExposureLoggingDisabled("is_insights_automated_notifs_enabled");
  const { debug } = useContext(DebugContext);
  const comparisonDateRange = getPreviousDateRangeWindow(dateRange);
  const timeWindow = getTimeWindowOptionForDateRange(dateRange);

  const queryData = useLazyLoadQuery<InsightsTabNotificationsQuery>(query, {
    brandId: brandID,
    endDate: dateRange.end.format("YYYY-MM-DD"),
    startDate: dateRange.start.format("YYYY-MM-DD"),
    comparisonEndDate: comparisonDateRange.end.format("YYYY-MM-DD"),
    comparisonStartDate: comparisonDateRange.start.format("YYYY-MM-DD"),
  });
  const brand = queryData.brand;

  const metricsDaily = [...brand.metricsDaily.edges].reverse();
  const data = metricsDaily.reduce(
    (acc, obj) => {
      return {
        pushNotificationDeliveries:
          obj.node.pushNotificationDeliveries + acc.pushNotificationDeliveries,
        pushNotificationTaps:
          obj.node.pushNotificationTaps + acc.pushNotificationTaps,
      };
    },
    {
      pushNotificationDeliveries: 0,
      pushNotificationTaps: 0,
    }
  );

  const comparisonMetricsDaily = [...brand.comparisonMetrics.edges].reverse();
  const compareData = comparisonMetricsDaily.reduce(
    (acc, obj) => {
      return {
        pushNotificationDeliveries:
          obj.node.pushNotificationDeliveries + acc.pushNotificationDeliveries,
        pushNotificationTaps:
          obj.node.pushNotificationTaps + acc.pushNotificationTaps,
      };
    },
    {
      pushNotificationDeliveries: 0,
      pushNotificationTaps: 0,
    }
  );

  let debugComponent = null;
  if (debug) {
    debugComponent = (
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          position: "fixed",
          p: 2,
          zIndex: 1000000,
          top: 64,
          right: 24,
          bgcolor: "rgb(20,184,166, 0.4)",
          fontSize: "8px",
        }}
      >
        <Box>
          <div>
            <b>Day</b>: {timeWindow}
          </div>
          <div>Deliveries: {data.pushNotificationDeliveries} </div>
          <div>Taps: {data.pushNotificationTaps} </div>
        </Box>
        <Box>
          <div>
            <b>Comparison Data from {timeWindow} ago</b>
          </div>
          <div>Deliveries: {compareData.pushNotificationDeliveries} </div>
          <div>Taps: {compareData.pushNotificationTaps} </div>
        </Box>
      </Stack>
    );
  }
  return (
    <>
      {debugComponent}
      <Grid container columns={12} spacing={2} mt={1}>
        <Grid item xs={12} sm={6} md={4}>
          <InsightsCard
            Icon={NotificationsActiveOutlinedIcon}
            title="Notifications Delivered"
            data={formatNumber(data.pushNotificationDeliveries)}
            change={
              (data.pushNotificationDeliveries -
                compareData.pushNotificationDeliveries) /
              compareData.pushNotificationDeliveries
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InsightsCard
            Icon={TouchAppOutlinedIcon}
            title="Notifications Clicked"
            data={formatNumber(data.pushNotificationTaps)}
            change={
              (data.pushNotificationTaps - compareData.pushNotificationTaps) /
              compareData.pushNotificationTaps
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InsightsCard
            Icon={ShowChartOutlinedIcon}
            title="Click Through Rate"
            data={formatNumber(
              data.pushNotificationTaps / data.pushNotificationDeliveries,
              "percent"
            )}
            change={
              (data.pushNotificationTaps / data.pushNotificationDeliveries -
                compareData.pushNotificationTaps /
                  compareData.pushNotificationDeliveries) /
              (compareData.pushNotificationTaps /
                compareData.pushNotificationDeliveries)
            }
          />
        </Grid>

        {isAutomatedSectionEnabled && (
          <Grid item xs={12}>
            <Suspense fallback={<TablePlaceholder />}>
              <InsightsTabAutomatedNotificationsTable
                brand={brand}
                dateRange={dateRange}
              />
            </Suspense>
          </Grid>
        )}

        <Grid item xs={12}>
          <Suspense fallback={<TablePlaceholder />}>
            <InsightsTabNotificationsTable brand={brand} />
          </Suspense>
        </Grid>
      </Grid>
    </>
  );
};

export default InsightsTabNotifications;
