import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useEffect, useState } from "react";
import { useMutation, usePaginationFragment } from "react-relay";
import { InterstitialNotificationsTableArchiveMutation } from "./__generated__/InterstitialNotificationsTableArchiveMutation.graphql";
import { InterstitialNotificationsTablePaginationQuery } from "./__generated__/InterstitialNotificationsTablePaginationQuery.graphql";
import { InterstitialNotificationsTable_refetchableBrand$key } from "./__generated__/InterstitialNotificationsTable_refetchableBrand.graphql";
import { KinnLanguageCode } from "../../../../../utils/languageMap";
import nullthrows from "../../../../../utils/nullthrows";
import InterstitialNotificationTableRow from "./InterstitialNotificationTableRow";

const brandFragment = graphql`
  fragment InterstitialNotificationsTable_refetchableBrand on BrandType
  @refetchable(queryName: "InterstitialNotificationsTablePaginationQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 10 }
    cursor: { type: "String" }
    audienceFilter: { type: "[String!]", defaultValue: [] }
    languageFilter: { type: "[KinnLanguageCode!]", defaultValue: [] }
  ) {
    interstitialNotifications(
      first: $count
      after: $cursor
      filters: {
        audienceIds: $audienceFilter
        audienceLanguages: $languageFilter
        isArchived: false
      }
    )
      @connection(
        key: "InterstitialNotificationTable_interstitialNotifications"
      ) {
      edges {
        node {
          id
          ...InterstitialNotificationTableRow_notification
        }
      }
      totalCount
    }
  }
`;

const archiveMutation = graphql`
  mutation InterstitialNotificationsTableArchiveMutation($input: NodeInput!) {
    archiveInterstitialNotification(input: $input) {
      ... on GraphQLInterstitialNotification {
        isArchived
      }
    }
  }
`;

export default function IntestitialNotificationsTable({
  brandFragment: brandKey,
  tableSearchAudiences,
  tableSearchLanguages,
}: {
  brandFragment: InterstitialNotificationsTable_refetchableBrand$key;
  tableSearchAudiences: string[];
  tableSearchLanguages: KinnLanguageCode[];
}) {
  const {
    data: refetchableBrand,
    loadNext,
    isLoadingNext,
    refetch,
  } = usePaginationFragment<
    InterstitialNotificationsTablePaginationQuery,
    InterstitialNotificationsTable_refetchableBrand$key
  >(brandFragment, brandKey);
  const interstitialNotifications =
    refetchableBrand.interstitialNotifications.edges ?? [];

  const [tablePage, setTablePage] = useState(0);
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
  const handleChangePage = (_event: unknown, newPage: number) => {
    if (newPage > tablePage) {
      loadNext(tableRowsPerPage);
    }
    setTablePage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    refetch({
      count: +event.target.value,
      audienceFilter: tableSearchAudiences,
      languageFilter: tableSearchLanguages,
    });
    setTableRowsPerPage(+event.target.value);
    setTablePage(0);
  };

  useEffect(() => {
    refetch({
      audienceFilter: tableSearchAudiences,
      languageFilter: tableSearchLanguages,
    });
    setTablePage(0);
  }, [refetch, tableSearchAudiences, tableSearchLanguages]);

  const [archiveInterstitialNotification, isArchiving] =
    useMutation<InterstitialNotificationsTableArchiveMutation>(archiveMutation);
  const [dialogRowId, setDialogRowId] = useState<string | null>(null);
  const isDialogOpen = dialogRowId !== null;
  const onDialogClose = () => {
    setDialogRowId(null);
  };
  const onArchive = useCallback(
    (id: string) => {
      archiveInterstitialNotification({
        variables: {
          input: {
            id: id,
          },
        },
        onCompleted: (data, errors) => {
          if (errors) {
            console.log("Error: ", data, errors);
          } else {
            onDialogClose();
          }
        },
      });
    },
    [archiveInterstitialNotification]
  );

  const dialogView = isDialogOpen ? (
    <Dialog open={isDialogOpen} onClose={onDialogClose}>
      <DialogTitle>Are you sure you want to archive this?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your customers will no longer be able to trigger this interstitial
          notification after you archive it.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={isArchiving}
          onClick={() => onArchive(nullthrows(dialogRowId))}
          color="error"
        >
          {isArchiving ? "Archiving" : "Archive"}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, tableLayout: "fixed" }}
          aria-label="simple table"
          size="small"
        >
          <TableHead
            sx={{
              background: "#F3F4F6",
            }}
          >
            <TableRow>
              <TableCell width="30%">
                <Typography variant="overline">Notification</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="overline">Trigger</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="overline">Audience</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="overline">Campaign</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="overline">Frequency Cap</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="overline">Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {interstitialNotifications
              .slice(
                tablePage * tableRowsPerPage,
                tablePage * tableRowsPerPage + tableRowsPerPage
              )
              .map((notif) => (
                <InterstitialNotificationTableRow
                  key={notif.node.id}
                  notification={notif.node}
                  onArchive={() => setDialogRowId(notif.node.id)}
                />
              ))}
            {isLoadingNext &&
              interstitialNotifications.length <
                (refetchableBrand.interstitialNotifications.totalCount ?? 0) &&
              interstitialNotifications.length <=
                (tablePage + 1) * tableRowsPerPage && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                    sx={{
                      padding: "24px 0",
                    }}
                  >
                    <CircularProgress color="primary" size={32} />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={6}
                align="center"
                rowsPerPageOptions={[10, 25]}
                count={
                  refetchableBrand.interstitialNotifications.totalCount ?? 0
                }
                rowsPerPage={tableRowsPerPage}
                page={tablePage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {dialogView}
    </>
  );
}
