import {
  Autocomplete,
  Divider,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useFragment, useMutation } from "react-relay";
import { CookiesCardMutation } from "./__generated__/CookiesCardMutation.graphql";
import {
  AppTrackingConfig,
  CookiesCard_brand$key,
} from "./__generated__/CookiesCard_brand.graphql";
import CardSection from "../../../../components/CardSection";
import LeftRight from "../../../../components/LeftRight";
import SaveButton, { SavedState } from "../../../../components/SaveButton";
import SnackbarContext from "../../../../contexts/SnackbarContext";
import CookiesCardCustomInput from "./CookiesCardCustomInput";

const brandFragment = graphql`
  fragment CookiesCard_brand on BrandType {
    appConfig {
      id
      trackingConfig {
        ...CookiesCardCustomInput_trackingConfig
        id
        configType
        consentSettings {
          name
          enabled
        }
        nonConsentSettings {
          name
          enabled
        }
        consentSessionStorageSettings {
          name
          enabled
        }
        nonConsentSessionStorageSettings {
          name
          enabled
        }
        consentLocalStorageSettings {
          name
          enabled
        }
        nonConsentLocalStorageSettings {
          name
          enabled
        }
        websiteId
      }
    }
  }
`;

const mutation = graphql`
  mutation CookiesCardMutation($input: BrandAppConfigInputPartial!) {
    updateBrandAppConfig(input: $input) {
      ... on BrandType {
        id
        ...CookiesCard_brand
      }
    }
  }
`;

const configTypes: AppTrackingConfig[] = [
  "CONVERSION_BEAR",
  "CUSTOM",
  "ISENSE",
  "TERMLY",
  "ONE_TRUST",
  "TRANSCEND",
];

const getLabelMapping = (configType: AppTrackingConfig): string => {
  switch (configType) {
    case "CONVERSION_BEAR":
      return "Conversion Bear";
    case "CUSTOM":
      return "Custom";
    case "ISENSE":
      return "iSense";
    case "ONE_TRUST":
      return "OneTrust";
    case "TERMLY":
      return "Termly";
    case "TRANSCEND":
      return "Transcend";
    default:
      return "";
  }
};

const getDefaultSettings = (configType: AppTrackingConfig) => {
  switch (configType) {
    case "CONVERSION_BEAR":
      return {
        necessary: true,
        functional: null,
        performance: null,
        analytics: null,
        advertisement: null,
        other: null,
      };
    case "CUSTOM":
      return {};
    case "ISENSE":
      return {
        required: true,
        analytics: null,
        marketing: null,
        functionality: null,
      };
    case "ONE_TRUST":
      return {
        required: true,
        performance: null,
        targeting: null,
        functionality: null,
        social: null,
      };
    case "TERMLY":
      return {
        essential: true,
        analytics: null,
        advertising: null,
        performance: null,
        social_networking: null,
        unclassified: true,
      };
    case "TRANSCEND":
      return {
        sale_of_info: null,
        analytics: null,
        functional: null,
        advertising: null,
      };
    default:
      throw new Error("Invalid cookie provider");
  }
};

const getMutationConsentSettings = (
  configType: AppTrackingConfig,
  data: any
) => {
  switch (configType) {
    case "CONVERSION_BEAR":
      return [
        {
          name: "necessary",
          enabled: true,
        },
        {
          name: "functional",
          enabled: data.consentfunctional ?? false,
        },
        {
          name: "performance",
          enabled: data.consentperformance ?? false,
        },
        {
          name: "analytics",
          enabled: data.consentanalytics ?? false,
        },
        {
          name: "advertisement",
          enabled: data.consentadvertisement ?? false,
        },
        {
          name: "other",
          enabled: data.consentother ?? false,
        },
      ];
    case "CUSTOM":
      return [];
    case "ISENSE":
      return [
        {
          name: "required",
          enabled: true,
        },
        {
          name: "analytics",
          enabled: data.consentanalytics ?? false,
        },
        {
          name: "marketing",
          enabled: data.consentmarketing ?? false,
        },
        {
          name: "functionality",
          enabled: data.consentfunctionality ?? false,
        },
      ];
    case "ONE_TRUST":
      return [
        {
          name: "required",
          enabled: true,
        },
        {
          name: "performance",
          enabled: data.consentperformance ?? false,
        },
        {
          name: "targeting",
          enabled: data.consenttargeting ?? false,
        },
        {
          name: "functionality",
          enabled: data.consentfunctionality ?? false,
        },
        {
          name: "social",
          enabled: data.consentsocial ?? false,
        },
      ];
    case "TERMLY":
      return [
        {
          name: "essential",
          enabled: true,
        },
        {
          name: "unclassified",
          enabled: true,
        },
        {
          name: "analytics",
          enabled: data.consentanalytics ?? false,
        },
        {
          name: "advertising",
          enabled: data.consentadvertising ?? false,
        },
        {
          name: "performance",
          enabled: data.consentperformance ?? false,
        },
        {
          name: "social_networking",
          enabled: data.consentsocial_networking ?? false,
        },
      ];
    case "TRANSCEND":
      return [
        {
          name: "sale_of_info",
          enabled: data.consentsale_of_info ?? false,
        },
        {
          name: "analytics",
          enabled: data.consentanalytics ?? false,
        },
        {
          name: "functional",
          enabled: data.consentfunctional ?? false,
        },
        {
          name: "advertising",
          enabled: data.consentadvertising ?? false,
        },
      ];

    default:
      throw new Error("Invalid cookie provider");
  }
};

const getMutationNonconsentSettings = (
  configType: AppTrackingConfig,
  data: any
) => {
  switch (configType) {
    case "CONVERSION_BEAR":
      return [
        {
          name: "necessary",
          enabled: true,
        },
        {
          name: "functional",
          enabled: data.nonconsentfunctional ?? false,
        },
        {
          name: "performance",
          enabled: data.nonconsentperformance ?? false,
        },
        {
          name: "analytics",
          enabled: data.nonconsentanalytics ?? false,
        },
        {
          name: "advertisement",
          enabled: data.nonconsentadvertisement ?? false,
        },
        {
          name: "other",
          enabled: data.nonconsentother ?? false,
        },
      ];
    case "CUSTOM":
      return [];
    case "ISENSE":
      return [
        {
          name: "required",
          enabled: true,
        },
        {
          name: "analytics",
          enabled: data.nonconsentanalytics ?? false,
        },
        {
          name: "marketing",
          enabled: data.nonconsentmarketing ?? false,
        },
        {
          name: "functionality",
          enabled: data.nonconsentfunctionality ?? false,
        },
      ];
    case "ONE_TRUST":
      return [
        {
          name: "required",
          enabled: true,
        },
        {
          name: "performance",
          enabled: data.nonconsentperformance ?? false,
        },
        {
          name: "targeting",
          enabled: data.nonconsenttargeting ?? false,
        },
        {
          name: "functionality",
          enabled: data.nonconsentfunctionality ?? false,
        },
        {
          name: "social",
          enabled: data.nonconsentsocial ?? false,
        },
      ];
    case "TERMLY":
      return [
        {
          name: "essential",
          enabled: true,
        },
        {
          name: "unclassified",
          enabled: true,
        },
        {
          name: "analytics",
          enabled: data.nonconsentanalytics ?? false,
        },
        {
          name: "advertising",
          enabled: data.nonconsentadvertising ?? false,
        },
        {
          name: "performance",
          enabled: data.nonconsentperformance ?? false,
        },
        {
          name: "social_networking",
          enabled: data.nonconsentsocial_networking ?? false,
        },
      ];
    case "TRANSCEND":
      return [
        {
          name: "sale_of_info",
          enabled: data.nonconsentsale_of_info ?? false,
        },
        {
          name: "analytics",
          enabled: data.nonconsentanalytics ?? false,
        },
        {
          name: "functional",
          enabled: data.nonconsentfunctional ?? false,
        },
        {
          name: "advertising",
          enabled: data.nonconsentadvertising ?? false,
        },
      ];

    default:
      throw new Error("Invalid cookie provider");
  }
};

const CookiesCard = ({ brand: brandKey }: { brand: CookiesCard_brand$key }) => {
  const brand = useFragment(brandFragment, brandKey);
  const appConfig = brand.appConfig;
  const provider = appConfig.trackingConfig?.configType ?? null;
  const consentSettings = appConfig.trackingConfig?.consentSettings ?? [];
  const nonconsentSettings = appConfig.trackingConfig?.nonConsentSettings ?? [];
  const websiteId = appConfig.trackingConfig?.websiteId ?? "";

  const snackbarContext = useContext(SnackbarContext);

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    provider === null ? SavedState.DISABLED : SavedState.SAVED
  );

  const [cookieProvider, setCookieProvider] =
    useState<AppTrackingConfig | null>(provider);

  const [saveMutation, isMutationInFlight] =
    useMutation<CookiesCardMutation>(mutation);

  const methods = useForm();

  const onSubmit = (data: any) => {
    const customSettings =
      cookieProvider === "CUSTOM"
        ? {
            configType: cookieProvider,
            consentSettings: (data.customConsentSettings ?? [])
              .map((s: any) => {
                if (s.key === "" || s.key === null) {
                  return null;
                }
                return {
                  name: s.key,
                  enabled: s.value,
                };
              })
              .filter(Boolean),
            nonConsentSettings: (data.customNonConsentSettings ?? [])
              .map((s: any) => {
                if (s.key === "" || s.key === null) {
                  return null;
                }
                return {
                  name: s.key,
                  enabled: s.value,
                };
              })
              .filter(Boolean),
            consentSessionStorageSettings: (
              data.customConsentSessionStorageSettings ?? []
            )
              .map((s: any) => {
                if (s.key === "" || s.key === null) {
                  return null;
                }
                return {
                  name: s.key,
                  enabled: s.value,
                };
              })
              .filter(Boolean),
            nonConsentSessionStorageSettings: (
              data.customNonConsentSessionStorageSettings ?? []
            )
              .map((s: any) => {
                if (s.key === "" || s.key === null) {
                  return null;
                }
                return {
                  name: s.key,
                  enabled: s.value,
                };
              })
              .filter(Boolean),
            consentLocalStorageSettings: (
              data.customConsentLocalStorageSettings ?? []
            )
              .map((s: any) => {
                if (s.key === "" || s.key === null) {
                  return null;
                }
                return {
                  name: s.key,
                  enabled: s.value,
                };
              })
              .filter(Boolean),
            nonConsentLocalStorageSettings: (
              data.customNonConsentLocalStorageSettings ?? []
            )
              .map((s: any) => {
                if (s.key === "" || s.key === null) {
                  return null;
                }
                return {
                  name: s.key,
                  enabled: s.value,
                };
              })
              .filter(Boolean),
          }
        : null;

    saveMutation({
      variables: {
        input: {
          id: appConfig.id,
          trackingConfig:
            cookieProvider === null
              ? null // null to delete, undefined to ignore
              : customSettings !== null
              ? customSettings
              : {
                  configType: cookieProvider,
                  consentSettings: getMutationConsentSettings(
                    cookieProvider,
                    data
                  ),
                  nonConsentSettings: getMutationNonconsentSettings(
                    cookieProvider,
                    data
                  ),
                  websiteId:
                    cookieProvider === "TERMLY" ? data.textInput : undefined,
                },
        },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          snackbarContext?.openSnackbar("Update Failed", "error");
        } else {
          snackbarContext?.openSnackbar("Updated", "success");
          setSaveButtonState(SavedState.SAVED);
          setSaveButtonState(SavedState.SAVED);
          methods.reset({}, { keepValues: true });
        }
      },
      onError: () => {
        snackbarContext?.openSnackbar("Update Failed", "error");
      },
    });
  };

  const textInput =
    cookieProvider === "TERMLY" ? (
      <>
        <Typography variant="subtitle1">Website ID</Typography>
        <TextField
          {...methods.register("textInput", {
            onChange: (e) => {
              saveButtonState !== SavedState.ENABLED &&
                setSaveButtonState(SavedState.ENABLED);
            },
            required: "Website ID is required to setup Termly",
          })}
          defaultValue={websiteId}
          error={!!methods.formState.errors?.textInput}
          helperText={
            methods.formState.errors?.textInput?.message != null
              ? methods.formState.errors.textInput.message + ""
              : undefined
          }
          sx={{
            flexGrow: 1,
          }}
          margin="normal"
          id="outlined-basic"
          label={"Website ID"}
          variant="outlined"
        />
      </>
    ) : null;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CardSection
          actions={
            <SaveButton
              savedState={
                methods.formState.isDirty
                  ? SavedState.ENABLED
                  : isMutationInFlight
                  ? SavedState.DISABLED
                  : saveButtonState
              }
              useSubmit={true}
            />
          }
          title={"Cookies"}
          content={
            <Stack spacing={2}>
              <Autocomplete
                value={cookieProvider}
                options={configTypes}
                getOptionLabel={(option: AppTrackingConfig) =>
                  getLabelMapping(option)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Cookie Provider" />
                )}
                onChange={(event, newValue) => {
                  setCookieProvider(newValue);
                  saveButtonState !== SavedState.ENABLED &&
                    setSaveButtonState(SavedState.ENABLED);
                }}
              />

              {cookieProvider === "CUSTOM" ? (
                <CookiesCardCustomInput
                  configFragment={appConfig.trackingConfig}
                />
              ) : cookieProvider ? (
                <Grid container>
                  <Grid item xs>
                    <Typography
                      sx={{ paddingRight: "32px" }}
                      variant="subtitle1"
                    >
                      Consent Settings
                    </Typography>
                    <Stack>
                      {Object.entries(getDefaultSettings(cookieProvider)).map(
                        (setting, index) => {
                          const existingCheckedValue =
                            cookieProvider === provider
                              ? consentSettings?.find(
                                  (s) => s.name === setting[0]
                                )?.enabled
                              : null;

                          return (
                            <LeftRight
                              expandLeft={true}
                              left={<Typography>{setting[0]}</Typography>}
                              right={
                                <Switch
                                  {...methods.register("consent" + setting[0])}
                                  key={cookieProvider + index}
                                  defaultChecked={
                                    existingCheckedValue ?? setting[1] ?? false
                                  }
                                  disabled={setting[1] === true}
                                />
                              }
                            />
                          );
                        }
                      )}
                    </Stack>
                  </Grid>
                  <Divider
                    sx={{
                      m: 2,
                    }}
                    orientation="vertical"
                    flexItem
                  />
                  <Grid item xs>
                    <Typography
                      sx={{ paddingRight: "32px" }}
                      variant="subtitle1"
                    >
                      Non-consent Settings
                    </Typography>
                    {Object.entries(getDefaultSettings(cookieProvider)).map(
                      (setting, index) => {
                        const existingCheckedValue =
                          cookieProvider === provider
                            ? nonconsentSettings?.find(
                                (s) => s.name === setting[0]
                              )?.enabled
                            : null;

                        return (
                          <LeftRight
                            expandLeft={true}
                            left={<Typography>{setting[0]}</Typography>}
                            right={
                              <Switch
                                {...methods.register("nonconsent" + setting[0])}
                                key={cookieProvider + index}
                                defaultChecked={
                                  existingCheckedValue ?? setting[1] ?? false
                                }
                                disabled={setting[1] === true}
                              />
                            }
                          />
                        );
                      }
                    )}
                  </Grid>
                </Grid>
              ) : null}
              {textInput}
            </Stack>
          }
        />
      </form>
    </FormProvider>
  );
};

export default CookiesCard;
