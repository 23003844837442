import { Container } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useMemo, useState } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { ThemeTabQuery } from "./__generated__/ThemeTabQuery.graphql";
import {
  AppColorPalette,
  ThemeTab_brand$key,
} from "./__generated__/ThemeTab_brand.graphql";
import {
  ReducerAction,
  useMobilePreviewDispatch,
} from "../../../contexts/MobilePreviewContext";
import AccountCard from "./AccountCard";
import ButtonStyleCard from "./ButtonStyleCard";
import ColorsCard from "./ColorsCard";
import LaunchScreenCard from "./LaunchScreenCard";
import LogoCard from "./LogoCard";
import OnboardingCard from "./OnboardingCard";
import TypographyCard from "./TypographyCard";
import ThemeContext from "./contexts/ThemeContext";
import NotificationsCard from "./theme/NotificationsCard";

const query = graphql`
  query ThemeTabQuery($id: ID!) {
    brand(id: $id) {
      ...ThemeTab_brand
    }
  }
`;

const brandFragment = graphql`
  fragment ThemeTab_brand on BrandType {
    ...AccountCard_brand
    ...ButtonStyleCard_brand
    ...ColorsCard_brand
    ...LaunchScreenCard_brand
    ...OnboardingCard_brand
    ...NotificationsCard_brand
    ...LogoCard_brand
    ...TypographyCard_brand
    appConfig {
      id
      accent1PaletteLight
      accent2PaletteLight
      accent3PaletteLight
      primaryPaletteLight
      buildTheme {
        primaryButtonBackground
        primaryButtonForeground
        secondaryButtonOutline
        badgeBackground
        badgeForeground
        navigationBackground
        navigationForeground
        primaryPillBackground
        primaryPillForeground
        secondaryPillOutline
        pillContainerBackground
      }
    }
  }
`;

const ThemeTab = ({ brandID }: { brandID: string }) => {
  const dispatch = useMobilePreviewDispatch();

  const data = useLazyLoadQuery<ThemeTabQuery>(query, {
    id: brandID,
  });
  const brand = useFragment<ThemeTab_brand$key>(brandFragment, data.brand);
  const appConfig = brand.appConfig;
  const buildTheme = appConfig.buildTheme;

  const [primaryPaletteLight, setPrimaryPaletteLight] = useState<string>(
    appConfig.primaryPaletteLight
  );
  const [accent1PaletteLight, setAccent1PaletteLight] = useState<string>(
    appConfig.accent1PaletteLight
  );
  const [accent2PaletteLight, setAccent2PaletteLight] = useState<string>(
    appConfig.accent2PaletteLight
  );
  const [accent3PaletteLight, setAccent3PaletteLight] = useState<string>(
    appConfig.accent3PaletteLight
  );

  const [primaryButtonBackground, setPrimaryButtonBackground] =
    useState<AppColorPalette | null>(buildTheme.primaryButtonBackground);
  const [primaryButtonForeground, setPrimaryButtonForeground] =
    useState<AppColorPalette | null>(buildTheme.primaryButtonForeground);
  const [secondaryButtonOutline, setSecondaryButtonOutline] =
    useState<AppColorPalette | null>(buildTheme.secondaryButtonOutline);
  const [primaryPillBackground, setPrimaryPillBackground] =
    useState<AppColorPalette | null>(buildTheme.primaryPillBackground);
  const [primaryPillForeground, setPrimaryPillForeground] =
    useState<AppColorPalette | null>(buildTheme.primaryPillForeground);
  const [secondaryPillOutline, setSecondaryPillOutline] =
    useState<AppColorPalette | null>(buildTheme.secondaryPillOutline);
  const [pillContainerBackground, setPillContainerBackground] =
    useState<AppColorPalette | null>(buildTheme.pillContainerBackground);
  const [badgeBackground, setBadgeBackground] =
    useState<AppColorPalette | null>(buildTheme.badgeBackground);
  const [badgeForeground, setBadgeForeground] =
    useState<AppColorPalette | null>(buildTheme.badgeForeground);
  const [navigationBackground, setNavigationBackground] =
    useState<AppColorPalette | null>(buildTheme.navigationBackground);
  const [navigationForeground, setNavigationForeground] =
    useState<AppColorPalette | null>(buildTheme.navigationForeground);

  const themeContextValue = useMemo(
    () => ({
      primaryPaletteLight,
      setPrimaryPaletteLight,
      accent1PaletteLight,
      setAccent1PaletteLight,
      accent2PaletteLight,
      setAccent2PaletteLight,
      accent3PaletteLight,
      setAccent3PaletteLight,
      primaryButtonBackground,
      setPrimaryButtonBackground,
      primaryButtonForeground,
      setPrimaryButtonForeground,
      secondaryButtonOutline,
      setSecondaryButtonOutline,
      primaryPillBackground,
      setPrimaryPillBackground,
      primaryPillForeground,
      setPrimaryPillForeground,
      secondaryPillOutline,
      setSecondaryPillOutline,
      pillContainerBackground,
      setPillContainerBackground,
      badgeBackground,
      setBadgeBackground,
      badgeForeground,
      setBadgeForeground,
      navigationBackground,
      setNavigationBackground,
      navigationForeground,
      setNavigationForeground,
    }),
    [
      primaryPaletteLight,
      setPrimaryPaletteLight,
      accent1PaletteLight,
      setAccent1PaletteLight,
      accent2PaletteLight,
      setAccent2PaletteLight,
      accent3PaletteLight,
      setAccent3PaletteLight,
      primaryButtonBackground,
      setPrimaryButtonBackground,
      primaryButtonForeground,
      setPrimaryButtonForeground,
      secondaryButtonOutline,
      setSecondaryButtonOutline,
      primaryPillBackground,
      setPrimaryPillBackground,
      primaryPillForeground,
      setPrimaryPillForeground,
      secondaryPillOutline,
      setSecondaryPillOutline,
      pillContainerBackground,
      setPillContainerBackground,
      badgeBackground,
      setBadgeBackground,
      badgeForeground,
      setBadgeForeground,
      navigationBackground,
      setNavigationBackground,
      navigationForeground,
      setNavigationForeground,
    ]
  );

  useEffect(() => {
    return () =>
      dispatch({
        type: ReducerAction.RESET_THEME,
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="md">
      <ThemeContext.Provider value={themeContextValue}>
        <ColorsCard brand={brand} />
        <TypographyCard brand={brand} />
        <ButtonStyleCard brand={brand} />
        <LogoCard brand={brand} />
        <LaunchScreenCard brand={brand} />
        <NotificationsCard brand={brand} />
        <OnboardingCard brand={brand} />
        <AccountCard brand={brand} />
      </ThemeContext.Provider>
    </Container>
  );
};

export default ThemeTab;
