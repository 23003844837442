/**
 * @generated SignedSource<<35b2bab4f722850845e5801c2bf6bfd3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountCard_brand$data = {
  readonly appConfig: {
    readonly appContent: {
      readonly accountDeletionDialogSubtitle: string;
      readonly accountDeletionDialogTitle: string;
      readonly createAccountSubtitle: string;
      readonly createAccountTitle: string;
      readonly id: string;
      readonly signInSubtitle: string;
      readonly signInTitle: string;
    } | null;
    readonly collectNameOnRegistration: boolean;
    readonly id: string;
    readonly usePasswordlessLogin: boolean;
  };
  readonly " $fragmentType": "AccountCard_brand";
};
export type AccountCard_brand$key = {
  readonly " $data"?: AccountCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountCard_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountCard_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "collectNameOnRegistration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "usePasswordlessLogin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandAppConfigContentType",
          "kind": "LinkedField",
          "name": "appContent",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountDeletionDialogTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountDeletionDialogSubtitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createAccountTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createAccountSubtitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signInTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signInSubtitle",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "2d6e8bebb9c5b59ddf25195fc06a3d60";

export default node;
