import { graphql } from "babel-plugin-relay/macro";
import { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { Navigate } from "react-router-dom";
import { HomePageContainerQuery } from "./__generated__/HomePageContainerQuery.graphql";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import nullthrows from "../../../utils/nullthrows";

const query = graphql`
  query HomePageContainerQuery($id: ID!) {
    brand(id: $id) {
      appConfig {
        appLaunchDate
      }
    }
  }
`;

const HomePageContainer = () => {
  const currentUserContext = useContext(CurrentUserContext);
  const brandQuery = useLazyLoadQuery<HomePageContainerQuery>(query, {
    id: currentUserContext?.activeBrandID ?? "",
  });

  const brand = nullthrows(brandQuery.brand);
  const appLaunchDate = brand.appConfig?.appLaunchDate;

  if (appLaunchDate == null) {
    return <Navigate to="/setup/get-started" />;
  }

  return <Navigate to="/analytics/overview" />;
};

export default HomePageContainer;
