import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { CircularProgress, Grid } from "@mui/material";

const Kustomer = () => {
  const search = useLocation().search;
  const kustomerToken = new URLSearchParams(search).get("token");

  useEffect(() => {
    if (kustomerToken != null) {
      const baselineKustomer = document.createElement("script");
      baselineKustomer.src = "https://cdn.kustomerapp.com/chat-web/widget.js";
      baselineKustomer.setAttribute("data-kustomer-api-key", kustomerToken);
      let body = document.getElementsByTagName("body")[0];
      body.appendChild(baselineKustomer);

      window.addEventListener("kustomerLoaded", () => {
        const kustomerFullscreen = document.createElement("script");
        kustomerFullscreen.async = false;
        kustomerFullscreen.defer = true;
        // var scriptText = document.createTextNode("Kustomer.start({}, () => { Kustomer.open(function() { var minimizeButton = document.querySelector('#kustomer-ui-sdk-iframe')?.contentWindow.document.querySelector('[aria-label=\"minimize chat widget\"]'); setTimeout(function() { minimizeButton.remove(); }, 1000);  }); });");
        var scriptText = document.createTextNode(
          "Kustomer.start({}, () => { Kustomer.open(function() { var chatWindowHead = document.querySelector('#kustomer-ui-sdk-iframe')?.contentWindow.document.querySelector('head'); var chatStyle = document.createElement('style'); chatStyle.textContent = '[aria-label=\"minimize chat widget\"] {display: none !important;}'; chatWindowHead.appendChild(chatStyle); }); });"
        );
        kustomerFullscreen.appendChild(scriptText);
        document.head.appendChild(kustomerFullscreen);
      });
    }
    // Only want to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default Kustomer;
