import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

const filterArchivedOptions: { value: boolean | null; label: string }[] = [
  { value: null, label: "All" },
  { value: false, label: "Active" },
  { value: true, label: "Archived" },
];

export const ArchivedDropMenuFilter = ({
  searchArchived = null,
  onFilterChange,
}: {
  /** null = all, true = archived, false = not archived */
  searchArchived?: boolean | null;
  onFilterChange: (searchArchived: boolean | null) => void;
}) => {
  const [filterMenuEl, setFilterMenuEl] = useState<null | HTMLElement>(null);
  const isFilterMenuOpen = Boolean(filterMenuEl);
  const onFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterMenuEl(event.currentTarget);
  };
  const onFilterMenuClose = () => {
    setFilterMenuEl(null);
  };

  return (
    <>
      <IconButton
        sx={{
          color: "black",
        }}
        onClick={onFilterMenuClick}
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={filterMenuEl}
        open={isFilterMenuOpen}
        onClose={onFilterMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {filterArchivedOptions.map((option) => (
          <MenuItem
            key={`${option.value}`}
            onClick={() => {
              onFilterMenuClose();
              onFilterChange(option.value);
            }}
            selected={searchArchived === option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
