/**
 * @generated SignedSource<<a81c13cccecab2f0d02aaeee841c7ea6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InternBrandBilling_brand$data = {
  readonly billingConfig: {
    readonly " $fragmentSpreads": FragmentRefs<"InternBrandBillingRecurringSubCard_billingConfig">;
  } | null;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"InternBrandBillingOneTimePurchaseCard_brand">;
  readonly " $fragmentType": "InternBrandBilling_brand";
};
export type InternBrandBilling_brand$key = {
  readonly " $data"?: InternBrandBilling_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"InternBrandBilling_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternBrandBilling_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLBillingConfig",
      "kind": "LinkedField",
      "name": "billingConfig",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InternBrandBillingRecurringSubCard_billingConfig"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InternBrandBillingOneTimePurchaseCard_brand"
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "3bbdd806973fa619c73a71a1f0e894b3";

export default node;
