import { useMemo } from "react";
import { InsightsTabQuery$data } from "../__generated__/InsightsTabQuery.graphql";
import { useInsightsTabPlatformBreakdownData } from "./useInsightsTabPlatformBreakdownData";

export type InsightsTabDayData = {
  date: string;
  appOrders: number;
  totalOrders: number;
  appSales: number;
  totalSales: number;
  downloads: {
    iOS: number;
    android: number;
    total: number;
  };
  sessions: {
    iOS: number;
    android: number;
    total: number;
  };
  users: {
    returning: number;
    new: number;
    total: number;
  };
};
export type InsightsTabDataByDate = { [key: string]: InsightsTabDayData };
export type InsightsTabAggregates = {
  appSalesSum: number;
  salesSum: number;
  ordersSum: number;
  appOrdersSum: number;
  appSessionsSum: number;
  newAppUsersSum: number;
  loggedInSessionsSum: number;
  pushEnabledSessionsSum: number;
};
export type InsightsTabData = {
  daily: InsightsTabDataByDate;
  aggregates: InsightsTabAggregates;
  raw: {
    metricsDailyHashmap: {
      [
        key: string
      ]: InsightsTabQuery$data["brand"]["metricsDaily"]["edges"][number]["node"];
    };
    platformBreakdownData: ReturnType<
      typeof useInsightsTabPlatformBreakdownData
    >;
  };
};

export const useInsightsTabQueryDateRangeData = (
  brand: InsightsTabQuery$data["brand"],
  dateRange: string[]
): InsightsTabData => {
  const platformBreakdownData = useInsightsTabPlatformBreakdownData(
    brand,
    dateRange
  );

  return useMemo(() => {
    const metricsAggregate1DHashmap = brand.metricsAggregate1D.edges.reduce(
      (acc, obj) => {
        acc[obj.node.date] = obj.node;
        return acc;
      },
      {} as {
        [key: string]: {
          readonly date: any;
          readonly appUsers: number;
        };
      }
    );

    let appSalesSum = 0;
    let salesSum = 0;
    let ordersSum = 0;
    let appOrdersSum = 0;
    let appSessionsSum = 0;
    let newAppUsersSum = 0;
    let loggedInSessionsSum = 0;
    let pushEnabledSessionsSum = 0;
    const metricsDailyHashmap = brand.metricsDaily.edges.reduce(
      (acc, obj) => {
        acc[obj.node.date] = obj.node;
        appSalesSum += obj.node.appSales;
        salesSum += obj.node.sales;
        appOrdersSum += obj.node.appOrders;
        ordersSum += obj.node.orders;
        appSessionsSum += obj.node.appSessions;
        loggedInSessionsSum += obj.node.loggedInSessions;
        newAppUsersSum += obj.node.newAppUsers;
        pushEnabledSessionsSum += obj.node.pushEnabledSessions;
        return acc;
      },
      {} as {
        [
          key: string
        ]: InsightsTabQuery$data["brand"]["metricsDaily"]["edges"][number]["node"];
      }
    );

    const dailyData: InsightsTabDayData[] = dateRange.map((date) => {
      const metricsDaily = metricsDailyHashmap[date];
      const metricsAggregate1D = metricsAggregate1DHashmap[date];
      const platformBreakdown1D = platformBreakdownData[date];
      const iOSDownloadCount = platformBreakdown1D?.downloads?.data1 ?? 0;
      const androidDownloadCount = platformBreakdown1D?.downloads?.data2 ?? 0;
      const iOSSessionCount = platformBreakdown1D?.sessions?.data1 ?? 0;
      const androidSessionCount = platformBreakdown1D?.sessions?.data2 ?? 0;
      const totalAppUsers = metricsAggregate1D?.appUsers ?? 0;
      const newAppUsers = metricsDaily?.newAppUsers ?? 0;
      return {
        date,
        appOrders: metricsDaily?.appOrders ?? 0,
        totalOrders: metricsDaily?.orders ?? 0,
        appSales: metricsDaily?.appSales ?? 0,
        totalSales: metricsDaily?.sales ?? 0,
        downloads: {
          iOS: iOSDownloadCount,
          android: androidDownloadCount,
          total: iOSDownloadCount + androidDownloadCount,
        },
        sessions: {
          iOS: iOSSessionCount,
          android: androidSessionCount,
          total: iOSSessionCount + androidSessionCount,
        },
        users: {
          returning: Math.max(totalAppUsers - newAppUsers, 0),
          new: newAppUsers,
          total: totalAppUsers,
        },
      };
    });

    return {
      daily: dailyData.reduce((acc, obj) => {
        acc[obj.date] = obj;
        return acc;
      }, {} as InsightsTabDataByDate),
      aggregates: {
        appSalesSum,
        salesSum,
        ordersSum,
        appOrdersSum,
        appSessionsSum,
        newAppUsersSum,
        loggedInSessionsSum,
        pushEnabledSessionsSum,
      },
      raw: {
        metricsDailyHashmap,
        platformBreakdownData,
      },
    };
  }, [brand, dateRange, platformBreakdownData]);
};
