import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import AuthImage from "../../assets/auth-image.png";
import CompanyLogo from "../../components/CompanyLogo";
import Copyright from "../../components/copyright/Copyright";

const SplitScreenView = ({
  rightContent,
}: {
  rightContent: React.ReactNode;
}) => {
  const theme = useTheme();
  return (
    <Grid
      container
      columns={12}
      spacing={0}
      sx={{
        width: "100%",
        height: "100vh",
      }}
    >
      <Grid
        item
        lg={6}
        component={Stack}
        sx={{
          backgroundColor: theme.palette.primary.main,
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        }}
        p={0}
        direction="column"
        textAlign={"center"}
        alignItems="center"
        position={"relative"}
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            pt: 10,
            px: 8,
            fontFamily: '"GT-Flexa","Roboto","Helvetica","Arial",sans-serif',
            fontWeight: 600,
            fontSize: "60px",
            lineHeight: "110%",
            color: "white",
            letterSpacing: "-0.03em",
          }}
        >
          Your home for customer connection
        </Typography>
        <img src={AuthImage} alt="Welcome" width={"80%"} />
      </Grid>
      <Grid
        sx={{
          backgroundColor: "white",
        }}
        component={Stack}
        item
        paddingY={7}
        xs={12}
        lg={6}
        direction="column"
        justifyContent="center"
        alignItems="center"
        position={"relative"}
      >
        <Stack
          sx={{
            width: "70%",
            maxWidth: "500px",
            borderRadius: "16px",
            border: "1px solid rgba(0, 0, 0, 0.05)",
            boxShadow: "0px 4px 60px 0px rgba(47, 55, 146, 0.13)",
            padding: "48px 68px",
          }}
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={8}
        >
          <CompanyLogo height="56px" />

          {rightContent}
          <Copyright
            sx={{
              position: "absolute",
              bottom: 24,
              textDecorationColor: "rgba(255,255,255,0.5)",
            }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SplitScreenView;
