/**
 * @generated SignedSource<<2f9d44140f03dbbc507997da1ccce91a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NodeInput = {
  id: string;
};
export type InternBrandBillingOneTimePurchaseCardDeleteMutation$variables = {
  input: NodeInput;
};
export type InternBrandBillingOneTimePurchaseCardDeleteMutation$data = {
  readonly deleteOneTimePurchase: {
    readonly id: string;
  };
};
export type InternBrandBillingOneTimePurchaseCardDeleteMutation = {
  response: InternBrandBillingOneTimePurchaseCardDeleteMutation$data;
  variables: InternBrandBillingOneTimePurchaseCardDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "input"
      }
    ],
    "concreteType": "GraphQLOneTimePurchaseCharge",
    "kind": "LinkedField",
    "name": "deleteOneTimePurchase",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InternBrandBillingOneTimePurchaseCardDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InternBrandBillingOneTimePurchaseCardDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7e30e4a968d04bf3df33446c60b183ad",
    "id": null,
    "metadata": {},
    "name": "InternBrandBillingOneTimePurchaseCardDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation InternBrandBillingOneTimePurchaseCardDeleteMutation(\n  $input: NodeInput!\n) {\n  deleteOneTimePurchase(data: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "998479a389d8c39e7b692ce03deed345";

export default node;
