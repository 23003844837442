import { Box, Card, CardMedia, Typography } from "@mui/material";
import { useMobilePreviewState } from "../../contexts/MobilePreviewContext";

const SinglePostPreview = () => {
  const { singlePost } = useMobilePreviewState();
  return (
    <Card
      sx={{
        borderRadius: "12px",
        maxHeight: 420,
        minHeight: 189,
        position: "relative",
      }}
    >
      {singlePost.media === "" ? null : (
        <CardMedia
          sx={{
            width: "100%",
          }}
          component={singlePost.mediaType === "VIDEO" ? "video" : "img"}
          image={singlePost.media ?? undefined}
        />
      )}
      <Box
        sx={{
          padding: 2,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)",
          zIndex: 2,
        }}
      >
        <Typography
          color="white"
          variant="header0"
          sx={{
            overflow: "hidden",
            lineHeight: "24px",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
          }}
        >
          {singlePost.title || "Post title that can wrap to two lines maximum"}
        </Typography>
        <Typography
          color="white"
          variant="body2"
          sx={{
            lineHeight: "17px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
          }}
        >
          {singlePost.subtitle ||
            "Subtitle that wraps to 2 lines maximum with no orphan text"}
        </Typography>
      </Box>
    </Card>
  );
};

export default SinglePostPreview;
