/**
 * @generated SignedSource<<c756d236e16ef8630ff3972df325fd18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CDPAudienceType = "LIST" | "SEGMENT" | "%future added value";
export type CDPType = "KINN" | "KLAVIYO" | "OMETRIA" | "SHOPIFY" | "%future added value";
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
export type NotificationAudience = "EVERYONE" | "PERSONAL" | "SEGMENTED" | "%future added value";
export type GraphQLNotificationInputPartial = {
  audience: NotificationAudience;
  audienceLanguages?: ReadonlyArray<KinnLanguageCode> | null;
  audiences?: ReadonlyArray<GraphQLCDPAudienceInput> | null;
  automaticArchiveTimestamp?: any | null;
  body: string;
  externalThumbnailUri?: string | null;
  id: string;
  landingPath: string;
  thumbnailUploadable?: any | null;
  timestamp: any;
  title: string;
  utmCampaign?: string | null;
};
export type GraphQLCDPAudienceInput = {
  audienceId: string;
  audienceName: string;
  audienceType: CDPAudienceType;
  cdpType: CDPType;
};
export type NotificationFormUpdateMutation$variables = {
  input: GraphQLNotificationInputPartial;
};
export type NotificationFormUpdateMutation$data = {
  readonly updateNotification: {
    readonly id: string;
    readonly landingPath: string;
    readonly " $fragmentSpreads": FragmentRefs<"NotificationTableRow_notification">;
  };
};
export type NotificationFormUpdateMutation = {
  response: NotificationFormUpdateMutation$data;
  variables: NotificationFormUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "landingPath",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationFormUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLNotification",
        "kind": "LinkedField",
        "name": "updateNotification",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NotificationTableRow_notification"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationFormUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GraphQLNotification",
        "kind": "LinkedField",
        "name": "updateNotification",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "audienceLanguages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "automaticArchiveTimestamp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "body",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbnailUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalThumbnailUri",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isArchived",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timestamp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "utmCampaign",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLCDPAudience",
            "kind": "LinkedField",
            "name": "audiences",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "audienceName",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLDailyDeal",
            "kind": "LinkedField",
            "name": "dailyDeal",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLFeedPost",
            "kind": "LinkedField",
            "name": "feedPost",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLHighlight",
            "kind": "LinkedField",
            "name": "highlight",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLHighlightAlbum",
                "kind": "LinkedField",
                "name": "highlightAlbum",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e1637620fecda10807c754f577ef79a",
    "id": null,
    "metadata": {},
    "name": "NotificationFormUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationFormUpdateMutation(\n  $input: GraphQLNotificationInputPartial!\n) {\n  updateNotification(input: $input) {\n    id\n    landingPath\n    ...NotificationTableRow_notification\n  }\n}\n\nfragment NotificationTableRow_notification on GraphQLNotification {\n  id\n  uuid\n  audienceLanguages\n  automaticArchiveTimestamp\n  body\n  thumbnailUrl\n  externalThumbnailUri\n  isArchived\n  landingPath\n  timestamp\n  title\n  utmCampaign\n  audiences {\n    audienceName\n    id\n  }\n  dailyDeal {\n    id\n  }\n  feedPost {\n    id\n  }\n  highlight {\n    id\n    highlightAlbum {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dfa3a38a795c51e167c090034d31c007";

export default node;
