import { Stack, Switch, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import * as yup from "yup";
import { AccountCard_brand$key } from "./__generated__/AccountCard_brand.graphql";
import CardSection from "../../../components/CardSection";
import LeftRight from "../../../components/LeftRight";
import SaveButton, { SavedState } from "../../../components/SaveButton";
import useBrandAppConfigUpdate2Mutation from "../../../mutations/useBrandAppConfigUpdate2Mutation";
import nullthrows from "../../../utils/nullthrows";

const schema = yup
  .object({
    accountDeletionDialogTitle: yup.string().max(400),
    accountDeletionDialogSubtitle: yup.string().max(400),
    createAccountTitle: yup.string().max(400),
    createAccountSubtitle: yup.string().max(400),
    signInTitle: yup.string().max(400),
    signInSubtitle: yup.string().max(400),
    collectNameOnRegistration: yup.boolean(),
    usePasswordlessLogin: yup.boolean(),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const brandFragment = graphql`
  fragment AccountCard_brand on BrandType {
    appConfig {
      id
      collectNameOnRegistration
      usePasswordlessLogin
      appContent {
        id
        accountDeletionDialogTitle
        accountDeletionDialogSubtitle
        createAccountTitle
        createAccountSubtitle
        signInTitle
        signInSubtitle
      }
    }
  }
`;

type Props = {
  brand: AccountCard_brand$key;
};

const AccountCard = ({ brand: brandKey }: Props) => {
  const brand = useFragment<AccountCard_brand$key>(brandFragment, brandKey);
  const appConfig = brand.appConfig;
  const appContent = appConfig.appContent ?? null;

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.DISABLED
  );

  const [saveMutation, isMutationInFlight] = useBrandAppConfigUpdate2Mutation();

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      accountDeletionDialogTitle: appContent?.accountDeletionDialogTitle ?? "",
      accountDeletionDialogSubtitle:
        appContent?.accountDeletionDialogSubtitle ?? "",
      createAccountTitle: appContent?.createAccountTitle ?? "",
      createAccountSubtitle: appContent?.createAccountSubtitle ?? "",
      signInTitle: appContent?.signInTitle ?? "",
      signInSubtitle: appContent?.signInSubtitle ?? "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const subscription = watch((value) => {
      saveButtonState !== SavedState.ENABLED &&
        setSaveButtonState(SavedState.ENABLED);
    });
    return () => subscription.unsubscribe();
  }, [watch, saveButtonState]);

  const onSubmit = (data: FormData) => {
    saveMutation(
      {
        id: brand.appConfig.id,
        collectNameOnRegistration: data.collectNameOnRegistration,
        usePasswordlessLogin: data.usePasswordlessLogin,
        appContent: {
          id: nullthrows(brand.appConfig?.appContent?.id),
          accountDeletionDialogTitle: data.accountDeletionDialogTitle,
          accountDeletionDialogSubtitle: data.accountDeletionDialogSubtitle,
          createAccountTitle: data.createAccountTitle,
          createAccountSubtitle: data.createAccountSubtitle,
          signInTitle: data.signInTitle,
          signInSubtitle: data.signInSubtitle,
        },
      },
      () => {
        setSaveButtonState(SavedState.SAVED);
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <CardSection
        actions={
          <SaveButton
            savedState={
              isMutationInFlight ? SavedState.DISABLED : saveButtonState
            }
            useSubmit={true}
          />
        }
        title={"Account"}
        content={
          <Stack
            spacing={2}
            sx={{
              maxWidth: "650px",
            }}
          >
            <Stack>
              <LeftRight
                expandLeft={true}
                left={
                  <Stack>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Collect Name on Registration
                    </Typography>
                    <Typography sx={{ paddingBottom: "16px" }} variant="body2">
                      Enable this feature to collect users' names during the
                      registration process.
                    </Typography>
                  </Stack>
                }
                right={
                  <Switch
                    {...register("collectNameOnRegistration")}
                    defaultChecked={appConfig.collectNameOnRegistration}
                  />
                }
              />
              <LeftRight
                expandLeft={true}
                left={
                  <Stack>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Passwordless Login
                    </Typography>
                    <Typography sx={{ paddingBottom: "16px" }} variant="body2">
                      Enable this feature to offer a password-free login
                      experience to users.
                    </Typography>
                  </Stack>
                }
                right={
                  <Switch
                    {...register("usePasswordlessLogin")}
                    defaultChecked={appConfig.usePasswordlessLogin}
                  />
                }
              />
            </Stack>

            <Typography sx={{ paddingRight: "32px" }} variant="subtitle1">
              Text Customizations
            </Typography>
            <TextField
              {...register("accountDeletionDialogTitle")}
              error={!!errors?.accountDeletionDialogTitle}
              helperText={errors?.accountDeletionDialogTitle?.message}
              autoComplete="off"
              sx={{
                flexGrow: 1,
              }}
              margin="normal"
              id="outlined-basic"
              label={"Account Deletion Dialog Title"}
              variant="outlined"
            />
            <TextField
              {...register("accountDeletionDialogSubtitle")}
              error={!!errors?.accountDeletionDialogSubtitle}
              helperText={errors?.accountDeletionDialogSubtitle?.message}
              autoComplete="off"
              sx={{
                flexGrow: 1,
              }}
              margin="normal"
              id="outlined-basic"
              label={"Account Deletion Dialog Subtitle"}
              variant="outlined"
            />
            <TextField
              {...register("createAccountTitle")}
              error={!!errors?.createAccountTitle}
              helperText={errors?.createAccountTitle?.message}
              autoComplete="off"
              sx={{
                flexGrow: 1,
              }}
              margin="normal"
              id="outlined-basic"
              label={"Create Account Title"}
              variant="outlined"
            />
            <TextField
              {...register("createAccountSubtitle")}
              error={!!errors?.createAccountSubtitle}
              helperText={errors?.createAccountSubtitle?.message}
              autoComplete="off"
              sx={{
                flexGrow: 1,
              }}
              margin="normal"
              id="outlined-basic"
              label={"Create Account Subtitle"}
              variant="outlined"
            />
            <TextField
              {...register("signInTitle")}
              error={!!errors?.signInTitle}
              helperText={errors?.signInTitle?.message}
              autoComplete="off"
              sx={{
                flexGrow: 1,
              }}
              margin="normal"
              id="outlined-basic"
              label={"Sign In Title"}
              variant="outlined"
            />
            <TextField
              {...register("signInSubtitle")}
              error={!!errors?.signInSubtitle}
              helperText={errors?.signInSubtitle?.message}
              autoComplete="off"
              sx={{
                flexGrow: 1,
              }}
              margin="normal"
              id="outlined-basic"
              label={"Sign In Subtitle"}
              variant="outlined"
            />
          </Stack>
        }
      />
    </form>
  );
};

export default AccountCard;
