import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { graphql } from "babel-plugin-relay/macro";
import {
  FocusEvent,
  Suspense,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ConnectionHandler,
  Environment,
  commitLocalUpdate,
  useFragment,
  useLazyLoadQuery,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import { useLocation } from "react-router-dom";
import {
  GraphQLCDPAudienceInput,
  HighlightsTabCreateAlbumMutation,
} from "./__generated__/HighlightsTabCreateAlbumMutation.graphql";
import { HighlightsTabDeleteAlbumMutation } from "./__generated__/HighlightsTabDeleteAlbumMutation.graphql";
import { HighlightsTabQuery } from "./__generated__/HighlightsTabQuery.graphql";
import { HighlightsTabUpdateAlbumMutation } from "./__generated__/HighlightsTabUpdateAlbumMutation.graphql";
import { HighlightsTabUpdateAlbumOrderMutation } from "./__generated__/HighlightsTabUpdateAlbumOrderMutation.graphql";
import { HighlightsTab_brand$key } from "./__generated__/HighlightsTab_brand.graphql";
import { ArchivedDropMenuFilter } from "../../../components/ArchivedDropMenuFilter";
import ButtonWithTooltip from "../../../components/ButtonWithTooltip";
import { CDPAudienceSelectorCard } from "../../../components/CDPAudienceSelectorCard";
import CardSection from "../../../components/CardSection";
import LeftRight from "../../../components/LeftRight";
import RighthandDrawer from "../../../components/RighthandDrawer";
import SaveButton, { SavedState } from "../../../components/SaveButton";
import DebugContext from "../../../contexts/DebugContext";
import { KinnLanguageCode } from "../../../utils/languageMap";
import nullthrows from "../../../utils/nullthrows";
import HighlightsCard from "./highlights/HighlightsCard";

const CARD_WIDTH_PLUS_PADDING = 201;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: any,
  isArchived: boolean
) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  ...(isArchived ? { filter: "brightness(0.5)" } : {}),

  // styles we need to apply on draggables
  ...draggableStyle,
});

const createAlbumMutation = graphql`
  mutation HighlightsTabCreateAlbumMutation(
    $input: GraphQLHighlightAlbumInput!
  ) {
    createHighlightAlbum(input: $input) {
      ... on GraphQLHighlightAlbum {
        id
        audienceLanguages
        count
        coverImageUrl
        createdAt
        isArchived
        title
        audiences {
          audienceId
        }
      }
    }
  }
`;

const deleteAlbumMutation = graphql`
  mutation HighlightsTabDeleteAlbumMutation($input: NodeInput!) {
    deleteHighlightAlbum(input: $input) {
      ... on GraphQLHighlightAlbum {
        id
      }
    }
  }
`;

const updateAlbumMutation = graphql`
  mutation HighlightsTabUpdateAlbumMutation(
    $input: GraphQLHighlightAlbumInputPartial!
  ) {
    updateHighlightAlbum(input: $input) {
      ... on GraphQLHighlightAlbum {
        id
        archivedCount
        audienceLanguages
        count
        coverImageUrl
        createdAt
        isArchived
        title
        audiences {
          audienceId
        }
      }
    }
  }
`;

const updateAlbumOrderMutation = graphql`
  mutation HighlightsTabUpdateAlbumOrderMutation(
    $input: UpdateHighlightAlbumOrderInput!
  ) {
    updateHighlightAlbumOrder(input: $input) {
      ... on BrandType {
        id
        highlightAlbums {
          edges {
            node {
              id
              isArchived
              archivedCount
              count
              coverImageUrl
              createdAt
              publishedAt
              title
              audiences {
                audienceId
              }
              highlights {
                edges {
                  node {
                    id
                    archivedAt
                    caption
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const query = graphql`
  query HighlightsTabQuery(
    $id: ID!
    $audienceFilter: [String!]!
    $languageFilter: [KinnLanguageCode!]
    $archivedFilter: Boolean
  ) {
    brand(id: $id) {
      ...HighlightsTab_brand
        @arguments(
          audienceFilter: $audienceFilter
          languageFilter: $languageFilter
          archivedFilter: $archivedFilter
        )
    }
  }
`;

const fragment = graphql`
  fragment HighlightsTab_brand on BrandType
  @argumentDefinitions(
    audienceFilter: { type: "[String!]", defaultValue: [] }
    languageFilter: { type: "[KinnLanguageCode!]", defaultValue: [] }
    archivedFilter: { type: "Boolean", defaultValue: null }
  ) {
    ...CDPAudienceSelectorCard_brand
    id
    highlightAlbums(
      first: null
      filters: {
        audienceIds: $audienceFilter
        audienceLanguages: $languageFilter
        isArchived: $archivedFilter
      }
    ) @connection(key: "HighlightsTab_highlightAlbums") {
      edges {
        node {
          id
          isArchived
          isAutomatedAlbum
          audienceLanguages
          archivedCount
          count
          coverImageUrl
          createdAt
          publishedAt
          title
          audiences {
            audienceId
          }
        }
      }
    }
  }
`;

function orderAlbumsInRelayStore(
  environment: Environment,
  brandID: string,
  sourceIndex: number,
  destinationIndex: number
) {
  return commitLocalUpdate(environment, (store) => {
    const brandRecord = store.get(brandID);
    if (brandRecord == null) {
      return;
    }

    const albumsConnection = ConnectionHandler.getConnection(
      brandRecord,
      "HighlightsTab_highlightAlbums",
      {
        filters: {
          audienceIds: [],
          audienceLanguages: [],
          isArchived: null,
        },
      }
    );
    const highlightAlbumsRecords = albumsConnection?.getLinkedRecords("edges");
    if (albumsConnection == null || highlightAlbumsRecords == null) {
      return;
    }

    const albumsCopy = Array.from(highlightAlbumsRecords);
    const [removed] = albumsCopy.splice(sourceIndex, 1);
    albumsCopy.splice(destinationIndex, 0, removed);

    albumsConnection.setLinkedRecords(albumsCopy, "edges");
  });
}

const filterArchivedOptions: { value: boolean | null; label: string }[] = [
  { value: null, label: "All" },
  { value: false, label: "Active" },
  { value: true, label: "Archived" },
];

export type State = {
  albumID: string;
  highlightID: string;
};

const HighlightsTab = ({ brandID }: { brandID: string }) => {
  const theme = useTheme();
  const { debug } = useContext(DebugContext);

  const location = useLocation();
  const state = location?.state as State;

  // Filters
  // null = all, true = archived, false = not archived
  const [searchArchived, setSearchArchived] = useState<boolean | null>(null);

  // confirmation dialog
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const onConfirmDialogClosed = () => {
    setIsConfirmDialogOpen(false);
  };

  const environment = useRelayEnvironment();
  const data = useLazyLoadQuery<HighlightsTabQuery>(query, {
    id: brandID,
    audienceFilter: [],
    languageFilter: [],
  });
  const brand = useFragment<HighlightsTab_brand$key>(fragment, data.brand);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  // Highlights card
  const initFocusedAlbumID = state?.albumID ?? null;
  const [focusedAlbumID, setFocusedAlbumID] = useState<string | null>(
    initFocusedAlbumID
  );

  const highlightAlbums = useMemo(() => {
    const edges = brand.highlightAlbums?.edges ?? [];
    if (searchArchived === null) {
      return { edges };
    } else {
      return {
        edges: edges.filter(
          (edge) =>
            edge.node.isArchived === searchArchived ||
            edge.node.id === focusedAlbumID
        ),
      };
    }
  }, [brand.highlightAlbums, searchArchived, focusedAlbumID]);

  // Right hand drawer props
  const [drawerAlbumID, setDrawerAlbumID] = useState<string | null>(null);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [drawerAudienceIDs, setDrawerAudienceIDs] = useState<string[]>([]);
  const [drawerAlbumIsAutomated, setDrawerAlbumIsAutomated] =
    useState<boolean>(false);
  const [drawerAudienceDatas, setDrawerAudienceDatas] = useState<
    GraphQLCDPAudienceInput[] | null
  >(null);
  const [drawerAudienceLanguages, setDrawerAudienceLanguages] = useState<
    KinnLanguageCode[]
  >([]);
  const onAudiencesChange = useCallback(
    (audiences: GraphQLCDPAudienceInput[]) => {
      setDrawerAudienceIDs(audiences.map((audience) => audience.audienceId));
      setDrawerAudienceDatas(
        audiences.map((audience) => ({
          audienceId: audience.audienceId,
          audienceName: audience.audienceName,
          audienceType: audience.audienceType,
          cdpType: audience.cdpType,
        }))
      );
    },
    [setDrawerAudienceIDs, setDrawerAudienceDatas]
  );
  const onLanguageFiltersChange = useCallback(
    (languages: KinnLanguageCode[]) => {
      setDrawerAudienceLanguages(languages);
    },
    [setDrawerAudienceLanguages]
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const onDrawerClose = useCallback(() => {
    setDrawerAlbumID(null);
    setDrawerTitle("");
    setDrawerAudienceIDs([]);
    setDrawerAudienceDatas(null);
    setDrawerAudienceLanguages([]);
    setIsDrawerOpen(false);
    setSaveButtonState(SavedState.SAVED);
  }, [
    setDrawerAlbumID,
    setDrawerTitle,
    setDrawerAudienceIDs,
    setDrawerAudienceDatas,
    setDrawerAudienceLanguages,
    setIsDrawerOpen,
  ]);

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.SAVED
  );

  const [createAlbum, isCreateAlbumInFlight] =
    useMutation<HighlightsTabCreateAlbumMutation>(createAlbumMutation);
  const [deleteAlbum, isDeleteAlbumInFlight] =
    useMutation<HighlightsTabDeleteAlbumMutation>(deleteAlbumMutation);
  const [updateAlbum, isUpdateAlbumInFlight] =
    useMutation<HighlightsTabUpdateAlbumMutation>(updateAlbumMutation);
  const [updateAlbumOrder, isUpdateAlbumOrderInFlight] =
    useMutation<HighlightsTabUpdateAlbumOrderMutation>(
      updateAlbumOrderMutation
    );

  const addItem = (
    <Card
      sx={{
        display: "flex",
        flexShrink: 0,
        height: "325px",
        width: "185px",
        background: "#F3F4F6",
        border: "1px dashed #BDBDBD",
        borderRadius: "8px",
        marginLeft: 2,
        alignItems: "center",
      }}
      variant="outlined"
    >
      <CardContent sx={{ width: "100%" }}>
        <Button
          disabled={false}
          size="small"
          variant="outlined"
          onClick={() => setIsDrawerOpen(true)}
          fullWidth
        >
          <AddCircleOutlineIcon sx={{ mr: 1 }} />
          Add Album
        </Button>
      </CardContent>
    </Card>
  );

  const albumItems = highlightAlbums.edges.map(
    ({ node: highlightAlbum }, index) => {
      const albumID = highlightAlbum.id;
      const activeCount = highlightAlbum.count - highlightAlbum.archivedCount;
      const isArchived = highlightAlbum.isArchived;
      const image = highlightAlbum.coverImageUrl ? (
        <CardMedia
          component="img"
          alt=""
          height={"325px"}
          image={highlightAlbum.coverImageUrl ?? ""}
        />
      ) : null;
      return (
        <Draggable
          key={index}
          draggableId={"highglights-tab-draggable-" + index}
          index={index}
          disableInteractiveElementBlocking={true}
          isDragDisabled={isArchived}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style,
                isArchived
              )}
            >
              <Card
                sx={{
                  display: "flex",
                  position: "relative",
                  height: "325px",
                  width: "185px",
                  background: "#F3F4F6",
                  borderRadius: "8px",
                  alignItems: "center",
                  marginLeft: 2,
                  outline:
                    focusedAlbumID === albumID
                      ? "4px solid " + theme.palette.primary.main
                      : undefined,
                }}
                key={index}
              >
                <CardActionArea
                  sx={{
                    height: "100%",
                    width: "100%",
                  }}
                  disabled={isArchived}
                  onClick={() => {
                    if (focusedAlbumID === albumID) {
                      setFocusedAlbumID(null);
                    } else {
                      setFocusedAlbumID(albumID);
                    }
                  }}
                >
                  {image}
                </CardActionArea>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: "8px 32px 8px 12px",
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)",
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    color="white"
                    noWrap={true}
                  >
                    {highlightAlbum.title}
                  </Typography>
                  {isArchived && (
                    <>
                      <Typography variant="body2" color="white">
                        {highlightAlbum.count} highlight
                        {highlightAlbum.count !== 1 ? "s" : ""}
                      </Typography>
                      <Typography
                        variant="caption"
                        fontWeight={600}
                        color="white"
                      >
                        Archived
                      </Typography>
                    </>
                  )}
                  {!isArchived && (
                    <>
                      <Typography variant="body2" color="white">
                        {activeCount} highlight
                        {activeCount !== 1 ? "s" : ""}
                      </Typography>
                      {highlightAlbum.archivedCount > 0 && (
                        <Typography variant="body2" color="lightgray">
                          {highlightAlbum.archivedCount} archived
                        </Typography>
                      )}
                      {highlightAlbum.audienceLanguages?.length && (
                        <Typography variant="caption" color="white">
                          {highlightAlbum.audienceLanguages.join(", ")}
                        </Typography>
                      )}
                      <IconButton
                        sx={{
                          color: "white",
                          position: "absolute",
                          right: 4,
                          bottom: 8,
                        }}
                        onClick={() => {
                          setDrawerTitle(highlightAlbum.title);
                          setDrawerAudienceIDs(
                            highlightAlbum.audiences.map(
                              (audience) => audience.audienceId
                            )
                          );
                          setDrawerAudienceLanguages(
                            highlightAlbum.audienceLanguages?.slice() ?? []
                          );
                          setDrawerAlbumIsAutomated(
                            highlightAlbum.isAutomatedAlbum ?? false
                          );
                          setDrawerAlbumID(highlightAlbum.id);
                          setFocusedAlbumID(highlightAlbum.id);
                          setIsDrawerOpen(true);
                        }}
                      >
                        <MoreHorizIcon fontSize="small" />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Card>
            </div>
          )}
        </Draggable>
      );
    }
  );

  const onArchive = () => {
    if (!drawerAlbumID) {
      return;
    }
    updateAlbum({
      variables: {
        input: {
          id: drawerAlbumID,
          isArchived: true,
        },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          console.log("Error: ", errors);
        } else {
          onDrawerClose();
          setFocusedAlbumID(null);
        }
      },
    });
  };

  const onConfirmDeleteAlbum = () => {
    deleteAlbum({
      variables: {
        input: {
          id: nullthrows(drawerAlbumID),
        },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          console.log("Error: ", errors);
        } else {
          onDrawerClose();
          onConfirmDialogClosed();
          setFocusedAlbumID(null);
        }
      },
      updater: (store) => {
        const payload = store.getRootField("deleteHighlightAlbum");

        const brandRecord = store.get(brand.id);
        if (brandRecord == null) {
          return;
        }

        const connection = ConnectionHandler.getConnection(
          brandRecord,
          "HighlightsTab_highlightAlbums",
          {
            filters: {
              audienceIds: [],
              audienceLanguages: [],
              isArchived: null,
            },
          }
        );
        if (!connection) {
          return;
        }

        ConnectionHandler.deleteNode(connection, payload.getDataID());

        const count = connection.getValue("totalCount");
        if (count != null) {
          connection.setValue(Number(count) - 1, "totalCount");
        }
      },
    });
  };

  const destructiveTooltip = drawerAlbumIsAutomated
    ? "This album is synced from an external source and can't be deleted or archived"
    : null;
  const drawerArchiveButton = (
    <ButtonWithTooltip
      disabled={
        isDeleteAlbumInFlight || isUpdateAlbumInFlight || drawerAlbumIsAutomated
      }
      tooltip={destructiveTooltip}
      size="large"
      variant="outlined"
      color="warning"
      onClick={onArchive}
    >
      Archive Album
    </ButtonWithTooltip>
  );

  const drawerDeleteButton = (
    <ButtonWithTooltip
      disabled={
        isDeleteAlbumInFlight || isUpdateAlbumInFlight || drawerAlbumIsAutomated
      }
      tooltip={destructiveTooltip}
      size="large"
      variant="outlined"
      color="error"
      onClick={() => {
        setIsConfirmDialogOpen(true);
      }}
    >
      Delete Album
    </ButtonWithTooltip>
  );
  const drawerSaveButton = (
    <Button
      disabled={
        isCreateAlbumInFlight || isUpdateAlbumInFlight || drawerTitle === ""
      }
      size="large"
      variant="contained"
      onClick={() => {
        if (drawerAlbumID === null) {
          createAlbum({
            variables: {
              input: {
                index: 0,
                audiences:
                  drawerAudienceDatas === null
                    ? []
                    : drawerAudienceDatas.map((audience) => ({
                        audienceId: audience.audienceId,
                        audienceName: audience.audienceName,
                        audienceType: audience.audienceType,
                        cdpType: audience.cdpType,
                      })),
                audienceLanguages: drawerAudienceLanguages,
                title: drawerTitle,
                brand: {
                  id: brand.id,
                },
              },
            },
            onCompleted: (data, errors) => {
              if (errors) {
                console.log("Error: ", errors);
              } else {
                onDrawerClose();
                setFocusedAlbumID(data.createHighlightAlbum.id);
              }
            },
            updater: (store) => {
              const payload = store.getRootField("createHighlightAlbum");

              const brandRecord = store.get(brand.id);
              if (brandRecord == null) {
                return;
              }

              const connection = ConnectionHandler.getConnection(
                brandRecord,
                "HighlightsTab_highlightAlbums",
                {
                  filters: {
                    audienceIds: [],
                    audienceLanguages: [],
                    isArchived: null,
                  },
                }
              );
              if (connection == null) {
                return;
              }

              const newEdge = ConnectionHandler.createEdge(
                store,
                connection,
                payload,
                "GraphQLHighlightAlbumEdge"
              );
              ConnectionHandler.insertEdgeBefore(connection, newEdge);

              // Increment total conut
              const totalCount = connection.getValue("totalCount");
              if (totalCount != null) {
                connection.setValue(Number(totalCount) + 1, "totalCount");
              }
            },
          });
        } else {
          updateAlbum({
            variables: {
              input: {
                id: drawerAlbumID,
                audiences:
                  drawerAudienceDatas === null
                    ? undefined
                    : drawerAudienceDatas.map((audience) => ({
                        audienceId: audience.audienceId,
                        audienceName: audience.audienceName,
                        audienceType: audience.audienceType,
                        cdpType: audience.cdpType,
                      })),
                audienceLanguages: drawerAudienceLanguages,
                title: drawerTitle,
              },
            },
            onCompleted: (data, errors) => {
              if (errors) {
                console.log("Error: ", errors);
              } else {
                onDrawerClose();
              }
            },
          });
        }
      }}
    >
      Save Changes
    </Button>
  );

  const confirmationDialog = isConfirmDialogOpen ? (
    <Dialog open={isConfirmDialogOpen} onClose={onConfirmDialogClosed}>
      <DialogTitle>Are you sure you want to delete this album?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            Deleting the album will also delete all analytics associated with it
            and it's highlights.
          </Typography>
          <Typography>Consider archiving it instead.</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirmDialogClosed} color="primary">
          Cancel
        </Button>
        <Button
          disabled={isDeleteAlbumInFlight || isUpdateAlbumInFlight}
          onClick={onConfirmDeleteAlbum}
          color="error"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;

  const debugComponent = focusedAlbumID && debug && (
    <Box
      sx={{
        bgcolor: "rgb(20,184,166, 0.3)",
        fontSize: "8px",
      }}
    >
      {window.atob(focusedAlbumID).split(":")[1]}
    </Box>
  );

  const filterMenu = (
    <ArchivedDropMenuFilter
      searchArchived={searchArchived}
      onFilterChange={(searchArchived) => {
        setSearchArchived(searchArchived);
      }}
    />
  );

  return (
    <Container maxWidth="lg">
      <DragDropContext
        onDragEnd={(result) => {
          if (scrollRef.current) {
            scrollRef.current.removeAttribute("style");
          }

          const { destination, source } = result;

          if (!destination) {
            return;
          }

          if (
            destination.droppableId !== source.droppableId ||
            destination.index === source.index
          ) {
            return;
          }

          orderAlbumsInRelayStore(
            environment,
            brand.id,
            source.index,
            destination.index
          );

          saveButtonState !== SavedState.ENABLED &&
            setSaveButtonState(SavedState.ENABLED);
        }}
        onDragStart={() => {
          if (scrollRef.current) {
            scrollRef.current.setAttribute("style", "scroll-behavior: auto");
          }
        }}
      >
        <CardSection
          actions={
            <SaveButton
              savedState={
                isUpdateAlbumOrderInFlight
                  ? SavedState.DISABLED
                  : saveButtonState
              }
              onClick={() => {
                updateAlbumOrder({
                  variables: {
                    input: {
                      brandId: brand.id,
                      albumIds: highlightAlbums.edges.map(
                        ({ node: album }) => album.id
                      ),
                    },
                  },
                  onCompleted: (data, errors) => {
                    if (errors) {
                      console.log("Error: ", errors);
                    } else {
                      setSaveButtonState(SavedState.SAVED);
                    }
                  },
                });
              }}
            />
          }
          title={"Your albums"}
          titleActions={filterMenu}
          subtitle="Choose an album to insert a photo or video highlight in. You can also create, edit, or drag and drop to re-arrange your
                albums."
          content={
            <Stack display={"grid"} width={"100%"}>
              {debugComponent}
              <Box
                sx={{ scrollBehavior: "smooth" }}
                display={"flex"}
                overflow="auto"
                width={"100%"}
                paddingTop={2}
                paddingBottom={2}
                paddingRight={2}
                ref={scrollRef}
              >
                {addItem}
                <Droppable
                  droppableId={"highlights-tab-droppable"}
                  direction="horizontal"
                >
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{ display: "flex" }}
                    >
                      {albumItems}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Box>
              <LeftRight
                expandLeft={true}
                left={
                  <Button
                    disabled={false}
                    size="medium"
                    variant="outlined"
                    onClick={() => {
                      if (scrollRef.current) {
                        const currentSL = scrollRef.current.scrollLeft;
                        const newSL =
                          (Math.floor(currentSL / CARD_WIDTH_PLUS_PADDING) -
                            1) *
                          CARD_WIDTH_PLUS_PADDING;
                        scrollRef.current.scrollLeft = newSL;
                      }
                    }}
                  >
                    <ArrowBackIcon sx={{ mr: 1 }} fontSize="small" />
                    Back
                  </Button>
                }
                right={
                  <Button
                    disabled={false}
                    size="medium"
                    variant="outlined"
                    onClick={() => {
                      if (scrollRef.current) {
                        const currentSL = scrollRef.current.scrollLeft;
                        const newSL =
                          (Math.floor(currentSL / CARD_WIDTH_PLUS_PADDING) +
                            1) *
                          CARD_WIDTH_PLUS_PADDING;
                        scrollRef.current.scrollLeft = newSL;
                      }
                    }}
                  >
                    Next
                    <ArrowForwardIcon sx={{ ml: 1 }} fontSize="small" />
                  </Button>
                }
              />
            </Stack>
          }
        />
        {focusedAlbumID !== null && (
          <Suspense
            fallback={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CircularProgress />
              </div>
            }
          >
            <HighlightsCard
              albumID={focusedAlbumID}
              brandID={brand.id}
              key={focusedAlbumID}
            />
          </Suspense>
        )}
        <RighthandDrawer
          isOpen={isDrawerOpen}
          title={drawerAlbumID ? "Edit Album" : "Add Album"}
          onClose={onDrawerClose}
        >
          <Stack
            sx={{
              padding: 2,
            }}
            spacing={2}
          >
            <TextField
              sx={{
                flexGrow: 1,
              }}
              margin="normal"
              label={"Album Title"}
              variant="outlined"
              value={drawerTitle}
              onChange={(event: FocusEvent<HTMLInputElement>) => {
                setDrawerTitle(event.target.value);
              }}
            />
            <CDPAudienceSelectorCard
              audienceIDs={drawerAudienceIDs}
              brand={brand}
              isCompact
              audienceLanguages={drawerAudienceLanguages}
              onAudiencesChange={onAudiencesChange}
              onLanguageFiltersChange={onLanguageFiltersChange}
            />
          </Stack>
          {drawerAlbumID === null ? (
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                padding: 2,
              }}
            >
              {drawerSaveButton}
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                padding: 2,
              }}
            >
              <Stack direction="row" spacing={2}>
                {drawerDeleteButton}
                {drawerArchiveButton}
              </Stack>
              {drawerSaveButton}
            </Stack>
          )}
        </RighthandDrawer>
      </DragDropContext>
      {confirmationDialog}
    </Container>
  );
};

export default HighlightsTab;
