import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Box,
  Button,
  Chip,
  Container,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useContext } from "react";
import { useFragment, useMutation } from "react-relay";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  BrandInputPartial,
  GetStartedTabMutation,
} from "./__generated__/GetStartedTabMutation.graphql";
import {
  GetStartedTab_brand$data,
  GetStartedTab_brand$key,
} from "./__generated__/GetStartedTab_brand.graphql";
import Circle from "../../../components/Circle";
import ExpandableSection from "../../../components/ExpandableSection";
import SnackbarContext from "../../../contexts/SnackbarContext";
import {
  BUILD_NAVIGATION,
  BUILD_THEME,
  ENGAGE_FEED,
  ENGAGE_NOTIFICATIONS,
  SETTINGS_INTEGRATIONS,
} from "../../../utils/routes";

const brandFragment = graphql`
  fragment GetStartedTab_brand on BrandType {
    id
    finishedOnboarding
    hasCreatedFeedPost
    hasCreatedNotification

    onboardingConfig {
      isDeveloperAccountSetup
      isKinnAddedToDeveloperAccount
      isThirdPartyIntegrationSetup
      isNotificationsSetup
      isAppListingComplete
      isAppSubmitted
      isAppApproved
      isIncentivesSetup
      isMarketingSetup
    }

    # BUILD_APP Section
    appConfig {
      accent1PaletteLight
      accent2PaletteLight
      accent3PaletteLight
      primaryPaletteLight
      buildTheme {
        launchScreenOption
        logoUrl
      }
      moreMenuItems {
        __typename
      }
      navigationItems {
        __typename
      }
    }
  }
`;

const mutation = graphql`
  mutation GetStartedTabMutation($input: BrandInputPartial!) {
    updateBrand(input: $input) {
      ... on BrandType {
        ...GetStartedTab_brand
      }
    }
  }
`;

enum Section {
  APP_STORE_SETUP = "app_store_setup",
  BUILD_APP = "build_app",
  SUBMIT_APP = "submit_app",
  MARKET_APP = "market_app",
}

enum SectionStep {
  // APP_STORE_SETUP Section
  APP_STORE_SETUP_DEVELOPER_ACCOUNTS = "app_store_setup_developer_accounts",
  APP_STORE_SETUP_KINN_DEVELOPER_ACCOUNTS = "app_store_setup_kinn_developer_accounts",

  // BUILD_APP Section
  BUILD_APP_NAVIGATION = "build_app_navigation",
  BUILD_APP_BUILD_THEME = "build_app_build_theme",
  BUILD_APP_INTEGRATIONS = "build_app_integrations",
  BUILD_APP_CONTENT = "build_app_content",
  BUILD_APP_NOTIFICATIONS = "build_app_notifications",

  // SUBMIT_APP Section
  SUBMIT_APP_LISTING = "submit_app_listing",
  SUBMIT_APP_SUBMIT = "submit_app_submit",
  SUBMIT_APP_APPROVED = "submit_app_approved",

  // MARKET_APP Section
  MARKET_APP_INCENTIVES = "market_app_incentives",
  MARKET_APP_PROMOTE = "market_app_promote",
  MARKET_APP_NOTIFICATIONS = "market_app_notifications",
}

const OnboardingConfig = [
  {
    section: Section.APP_STORE_SETUP,
    title: "App Store Setup",
    Icon: SettingsOutlinedIcon,
    steps: [
      {
        type: SectionStep.APP_STORE_SETUP_DEVELOPER_ACCOUNTS,
        title: "1. Apply for Apple and Google Developer Accounts",
        subtitle:
          "Apply for both an iOS App Store account and an Android Play Store account. These accounts will allow you to distribute and manage your mobile application on the respective platforms. Please make sure your accounts are setup to avoid delays when launching.",
        primaryButtonText: "I've completed this step",
      },
      {
        type: SectionStep.APP_STORE_SETUP_KINN_DEVELOPER_ACCOUNTS,
        title:
          "2. Grant Kinn Administrative Access for Seamless App Submission",
        subtitle:
          "Once you have setup the iOS and Android app store accounts, add Kinn as an administrator to both platforms. This will enable us to assist in the app setup process, ensuring a smooth and efficient submission experience",
        primaryButtonText: "I've completed this step",
      },
    ],
  },
  {
    section: Section.BUILD_APP,
    title: "Build Your App",
    Icon: BuildOutlinedIcon,
    steps: [
      {
        type: SectionStep.BUILD_APP_NAVIGATION,
        title: "1. Design an Intuitive Navigation System for Your Mobile App",
        subtitle:
          "Lay the groundwork for your mobile application by creating a user-friendly navigation structure that enables seamless exploration and access to various app features",
        primaryButtonText: "Go to Navigation Tab",
      },
      {
        type: SectionStep.BUILD_APP_BUILD_THEME,
        title: "2. Customize Your App's Visual Components",
        subtitle:
          "Personalize your mobile app's appearance by selecting colors, logos, fonts, and other UI customizations. Create a visually appealing and cohesive user experience that reflects your brand identity.",
        primaryButtonText: "Go to Theme Tab",
      },
      {
        type: SectionStep.BUILD_APP_INTEGRATIONS,
        title: "3. Enhance Functionality with Third-Party Integrations",
        subtitle:
          "Connect your mobile app with third-party providers such as Shopify, Klaviyo, and more. Expand your app's capabilities, allowing it to seamlessly interact with external services and provide enhanced functionality for your users.",
        primaryButtonText: "Go to Integrations",
        secondaryButtonText: "I've completed this step",
      },
      {
        type: SectionStep.BUILD_APP_CONTENT,
        title: '4. Create Engaging Content for the "For You" Tab',
        subtitle:
          'Create unique and captivating content specifically tailored for the "For You" tab. Engage users with custom posts that provide personalized recommendations, making their app experience more meaningful and enjoyable',
        primaryButtonText: "Create a Feed Post",
      },
      {
        type: SectionStep.BUILD_APP_NOTIFICATIONS,
        title: "5. Empower Your App with Push Notifications",
        subtitle:
          "Configure your app to send push notifications effectively. Set up API keys, conduct testing, and ensure smooth communication with users. Keep them informed and engaged by delivering timely and relevant updates directly to their devices.",
        primaryButtonText: "I've completed this step",
      },
    ],
  },
  {
    section: Section.SUBMIT_APP,
    title: "Submit Your App",
    Icon: AppRegistrationOutlinedIcon,
    steps: [
      {
        type: SectionStep.SUBMIT_APP_LISTING,
        title: "1. Optimize Your App Listing for App Store Submission",
        subtitle:
          " Prepare your app for submission by providing accurate and compelling details such as app name, description, screenshots, keywords, and category. This step ensures that your app listing effectively communicates its features and benefits to potential users",
        primaryButtonText: "I've completed this step",
      },
      {
        type: SectionStep.SUBMIT_APP_SUBMIT,
        title: "2. Initiate the App Submission Process",
        subtitle:
          "Once you have completed the necessary app listing details, it's time to submit your app to both the Apple App Store and the Google Play Store. This step involves following the respective submission guidelines and uploading your app package for review.",
        primaryButtonText: "I've completed this step",
      },
      {
        type: SectionStep.SUBMIT_APP_APPROVED,
        title: "3. Launch Your App",
        subtitle:
          "After your app has been reviewed and approved by both app stores, it's time to make it available to users. Once approved, you can set a launch date and go live with your app, enabling users to download, install, and enjoy your mobile application. ",
        primaryButtonText: "I've completed this step",
      },
    ],
  },
  {
    section: Section.MARKET_APP,
    title: "Marketing",
    Icon: CampaignOutlinedIcon,
    steps: [
      {
        type: SectionStep.MARKET_APP_INCENTIVES,
        title: "1. Offer Exclusive In-App Incentives",
        subtitle:
          "Create incentives that are exclusively available within your mobile app. These can include special discounts, promotions, loyalty rewards, or exclusive content. By offering unique benefits, you encourage users to download and engage with your app.",
        primaryButtonText: "I've completed this step",
      },
      {
        type: SectionStep.MARKET_APP_PROMOTE,
        title: "2. Promote Your Mobile App",
        subtitle:
          "Implement a comprehensive marketing strategy, including social media campaigns, influencers, ads, emails, and cross-promotion, to increase app downloads.",
        primaryButtonText: "I've completed this step",
      },
      {
        type: SectionStep.MARKET_APP_NOTIFICATIONS,
        title: "3. Engage Users with Your First Push Notification",
        subtitle:
          "Craft a concise and relevant message to deliver valuable information, highlight features, provide recommendations, or offer exclusive incentives in your first push notification.",
        primaryButtonText: "Go to Notifications Tab",
      },
    ],
  },
];

const getCompletedSteps = (
  section: Section,
  brand: GetStartedTab_brand$data
): Array<SectionStep> => {
  const completedSteps: Array<SectionStep> = [];
  const appConfig = brand.appConfig;
  const buildTheme = appConfig?.buildTheme;
  const onboardingConfig = brand?.onboardingConfig;
  switch (section) {
    case Section.APP_STORE_SETUP:
      if (onboardingConfig?.isDeveloperAccountSetup) {
        completedSteps.push(SectionStep.APP_STORE_SETUP_DEVELOPER_ACCOUNTS);
      }
      if (onboardingConfig?.isKinnAddedToDeveloperAccount) {
        completedSteps.push(
          SectionStep.APP_STORE_SETUP_KINN_DEVELOPER_ACCOUNTS
        );
      }
      return completedSteps;
    case Section.BUILD_APP:
      if (
        appConfig?.navigationItems?.length !== 0 &&
        appConfig?.moreMenuItems?.length !== 0
      ) {
        completedSteps.push(SectionStep.BUILD_APP_NAVIGATION);
      }

      if (
        appConfig?.primaryPaletteLight &&
        appConfig?.accent1PaletteLight &&
        appConfig?.accent2PaletteLight &&
        appConfig?.accent3PaletteLight &&
        buildTheme?.launchScreenOption !== null &&
        buildTheme?.logoUrl !== null
      ) {
        completedSteps.push(SectionStep.BUILD_APP_BUILD_THEME);
      }

      if (onboardingConfig?.isThirdPartyIntegrationSetup) {
        completedSteps.push(SectionStep.BUILD_APP_INTEGRATIONS);
      }

      if (brand.hasCreatedFeedPost) {
        completedSteps.push(SectionStep.BUILD_APP_CONTENT);
      }

      if (onboardingConfig?.isNotificationsSetup) {
        completedSteps.push(SectionStep.BUILD_APP_NOTIFICATIONS);
      }
      return completedSteps;
    case Section.SUBMIT_APP:
      if (onboardingConfig?.isAppListingComplete) {
        completedSteps.push(SectionStep.SUBMIT_APP_LISTING);
      }
      if (onboardingConfig?.isAppSubmitted) {
        completedSteps.push(SectionStep.SUBMIT_APP_SUBMIT);
      }
      if (onboardingConfig?.isAppApproved) {
        completedSteps.push(SectionStep.SUBMIT_APP_APPROVED);
      }
      return completedSteps;
    case Section.MARKET_APP:
      if (onboardingConfig?.isIncentivesSetup) {
        completedSteps.push(SectionStep.MARKET_APP_INCENTIVES);
      }
      if (onboardingConfig?.isMarketingSetup) {
        completedSteps.push(SectionStep.MARKET_APP_PROMOTE);
      }
      if (brand.hasCreatedNotification) {
        completedSteps.push(SectionStep.MARKET_APP_NOTIFICATIONS);
      }
      return completedSteps;
    default:
      return completedSteps;
  }
};

const getPrimaryButtonActions = (
  sectionStep: SectionStep,
  navigate: NavigateFunction,
  brand: GetStartedTab_brand$data,
  updateBrand: (input: BrandInputPartial) => void
): (() => void) | null => {
  switch (sectionStep) {
    case SectionStep.APP_STORE_SETUP_DEVELOPER_ACCOUNTS:
      return () =>
        updateBrand({
          id: brand.id,
          onboardingConfig: {
            isDeveloperAccountSetup: true,
          },
        });
    case SectionStep.APP_STORE_SETUP_KINN_DEVELOPER_ACCOUNTS:
      return () =>
        updateBrand({
          id: brand.id,
          onboardingConfig: {
            isKinnAddedToDeveloperAccount: true,
          },
        });
    case SectionStep.BUILD_APP_NAVIGATION:
      return () => navigate(BUILD_NAVIGATION);
    case SectionStep.BUILD_APP_BUILD_THEME:
      return () => navigate(BUILD_THEME);
    case SectionStep.BUILD_APP_CONTENT:
      return brand.finishedOnboarding ? () => navigate(ENGAGE_FEED) : null;
    case SectionStep.BUILD_APP_INTEGRATIONS:
      return () => navigate(SETTINGS_INTEGRATIONS);
    case SectionStep.BUILD_APP_NOTIFICATIONS:
      return () =>
        updateBrand({
          id: brand.id,
          onboardingConfig: {
            isNotificationsSetup: true,
          },
        });
    case SectionStep.SUBMIT_APP_LISTING:
      return () =>
        updateBrand({
          id: brand.id,
          onboardingConfig: {
            isAppListingComplete: true,
          },
        });
    case SectionStep.SUBMIT_APP_SUBMIT:
      return () =>
        updateBrand({
          id: brand.id,
          onboardingConfig: {
            isAppSubmitted: true,
          },
        });
    case SectionStep.SUBMIT_APP_APPROVED:
      return () =>
        updateBrand({
          id: brand.id,
          onboardingConfig: {
            isAppApproved: true,
          },
        });
    case SectionStep.MARKET_APP_INCENTIVES:
      return () =>
        updateBrand({
          id: brand.id,
          onboardingConfig: {
            isIncentivesSetup: true,
          },
        });
    case SectionStep.MARKET_APP_PROMOTE:
      return () =>
        updateBrand({
          id: brand.id,
          onboardingConfig: {
            isMarketingSetup: true,
          },
        });
    case SectionStep.MARKET_APP_NOTIFICATIONS:
      return brand.finishedOnboarding
        ? () => navigate(ENGAGE_NOTIFICATIONS)
        : null;
    default:
      throw new Error("No action defined for this step: " + sectionStep);
  }
};

const getSecondaryButtonAction = (
  sectionStep: SectionStep,
  brand: GetStartedTab_brand$data,
  updateBrand: (input: BrandInputPartial) => void
): (() => void) | null => {
  switch (sectionStep) {
    case SectionStep.BUILD_APP_INTEGRATIONS:
      return () =>
        updateBrand({
          id: brand.id,
          onboardingConfig: {
            isThirdPartyIntegrationSetup: true,
          },
        });
    default:
      return null;
  }
};

const OnboardingStepItem = ({
  isCompleted,
  title,
  subtitle,
  primaryButtonText,
  secondaryButtonText,
  type,
  brand,
}: {
  isCompleted: boolean;
  title: string;
  subtitle: string;
  primaryButtonText: string | null;
  secondaryButtonText: string | null;
  type: SectionStep;
  brand: GetStartedTab_brand$data;
}) => {
  const snackbarContext = useContext(SnackbarContext);
  const navigate = useNavigate();

  const [saveMutation, isMutationInFlight] =
    useMutation<GetStartedTabMutation>(mutation);

  const updateBrand = useCallback(
    (input: BrandInputPartial) => {
      saveMutation({
        variables: {
          input: {
            ...input,
          },
        },
        onCompleted: (data, errors) => {
          if (errors) {
            snackbarContext?.openSnackbar("Update Failed", "error");
          } else {
            snackbarContext?.openSnackbar("Completed", "success");
          }
        },
        onError: () => {
          snackbarContext?.openSnackbar("Update Failed", "error");
        },
      });
    },
    [saveMutation, snackbarContext]
  );

  const completedPill = isCompleted ? (
    <Circle
      color={"#DCFDD7"}
      height="26px"
      component={
        <CheckIcon
          sx={{
            fontSize: "16px",
          }}
        />
      }
    />
  ) : (
    <Circle color={"rgba(0,0,0,0)"} height="26px" component={<div />} />
  );

  let buttons = null;
  if (!isCompleted) {
    const primaryButtonAction = getPrimaryButtonActions(
      type,
      navigate,
      brand,
      updateBrand
    );
    const primaryButton =
      primaryButtonAction === null ? (
        <Tooltip
          title={
            "You'll be able to complete this once you have finished onboarding."
          }
          placement="right"
          arrow
        >
          <span>
            <Button
              disabled
              variant="contained"
              size="medium"
              disableElevation
              sx={{ mr: 2 }}
            >
              {primaryButtonText}
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Button
          disabled={isMutationInFlight}
          variant="contained"
          size="medium"
          disableElevation
          sx={{ mr: 2 }}
          onClick={primaryButtonAction}
        >
          {primaryButtonText}
        </Button>
      );
    const secondaryButtonAction = getSecondaryButtonAction(
      type,
      brand,
      updateBrand
    );
    buttons = (
      <Box>
        {primaryButtonText && primaryButton}
        {secondaryButtonText && secondaryButtonAction && (
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            disableElevation
            onClick={secondaryButtonAction}
          >
            {secondaryButtonText}
          </Button>
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        pl: 4,
      }}
    >
      <Stack
        direction="row"
        justifyContent={"space-between"}
        spacing={5}
        alignItems={"center"}
        py={2}
        pr={2}
        sx={{
          borderRadius: "8px",
          position: "relative",
        }}
      >
        <Stack direction="row" alignItems={"center"} spacing={6}>
          {completedPill}
          <Stack spacing={1} direction="column" alignItems={"flex-start"}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "137.5%",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                color: "#6B7280",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "157%",
              }}
            >
              {subtitle}
            </Typography>
            {buttons}
          </Stack>
        </Stack>
        {/* <Box
          sx={{
            backgroundColor: "black",
            borderRadius: "8px",
            width: "100px",
            height: "70px",
            flexShrink: 0,
          }}
        ></Box> */}
      </Stack>
    </Box>
  );
};

const GetStartedTab = ({
  brand: brandKey,
}: {
  brand: GetStartedTab_brand$key;
}) => {
  const theme = useTheme();
  const brand = useFragment(brandFragment, brandKey);

  const completedSteps = OnboardingConfig.reduce((acc, curr) => {
    acc[curr.section] = getCompletedSteps(curr.section, brand);
    return acc;
  }, {} as Record<Section, Array<SectionStep>>);
  const completedStepCount = Object.values(completedSteps).reduce(
    (acc, curr) => acc + curr.length,
    0
  );
  const totalStepCount = OnboardingConfig.reduce(
    (count, current) => count + current.steps.length,
    0
  );

  const completedPercentage = completedStepCount / totalStepCount;
  return (
    <Container
      maxWidth="md"
      sx={{
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          p: 5,
        }}
      >
        <Box
          sx={{
            background: theme.palette.primary.main + "1D",
            paddingX: 8,
            paddingY: 4,
            borderRadius: "10px",
          }}
        >
          <Typography
            sx={{
              fontFamily: '"GT-Flexa","Roboto","Helvetica","Arial",sans-serif',
              borderRadius: "10px",
              fontWeight: 500,
              fontSize: "52px",
              letterSpacing: "-1px",
              lineHeight: "137.5%",
            }}
          >
            Welcome to Kinn
          </Typography>
          <Typography
            sx={{
              color: "black",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "157%",
            }}
          >
            Complete these steps to get your app up and running.
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mt: 1, mb: 1 }}>
            <Box sx={{ width: "100%", mr: 2 }}>
              <LinearProgress
                sx={{
                  borderRadius: "4px",
                  height: "10px",
                  backgroundColor: theme.palette.primary.main + "1D",
                  "& .MuiLinearProgress-barColorPrimary": {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                variant="determinate"
                value={completedPercentage * 100}
              />
            </Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {completedPercentage === 1 ? (
                <p>&#128640;</p>
              ) : (
                `${Math.round(completedPercentage * 100)}%`
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      {OnboardingConfig.map((onboardingStep, index) => {
        const stepStatus = completedSteps[onboardingStep.section];
        const completedCount = stepStatus.length;
        const totalCount = onboardingStep.steps.length;
        const isSectionCompleted =
          completedCount === onboardingStep.steps.length;

        const progress = (
          <Chip
            sx={{
              "& .MuiChip-icon": {
                color: "black",
              },

              backgroundColor: !isSectionCompleted ? "#EFEFEF" : "#DCFDD7",
              borderRadius: "4px",
              fontWeight: "bold",
            }}
            icon={
              !isSectionCompleted ? undefined : (
                <CheckIcon sx={{ fontSize: "16px" }} color="inherit" />
              )
            }
            label={
              !isSectionCompleted
                ? `${completedCount} of ${totalCount} completed`
                : "Completed"
            }
          />
        );

        return (
          <ExpandableSection
            key={index}
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#9CA3AF" }} fontSize="large" />
            }
            isExpandedByDefault={!isSectionCompleted}
            title={
              <Stack direction="row" spacing={4} alignItems={"center"}>
                <Circle
                  color={"#EFEFEF"}
                  height="52px"
                  component={<onboardingStep.Icon />}
                />
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "137.5%",
                  }}
                >
                  {onboardingStep.title}
                </Typography>
                {progress}
              </Stack>
            }
          >
            {onboardingStep.steps.map((step, index) => {
              return (
                <OnboardingStepItem
                  key={index}
                  isCompleted={stepStatus.includes(step?.type)}
                  title={step.title}
                  subtitle={step.subtitle}
                  primaryButtonText={step?.primaryButtonText ?? null}
                  secondaryButtonText={step?.secondaryButtonText ?? null}
                  type={step.type}
                  brand={brand}
                />
              );
            })}
          </ExpandableSection>
        );
      })}
    </Container>
  );
};

export default GetStartedTab;
