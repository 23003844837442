import { Box, Button, CardMedia, Stack, useTheme } from "@mui/material";
import { useMobilePreviewState } from "../../contexts/MobilePreviewContext";

const InterstitialNotificationPreview = () => {
  const theme = useTheme();
  const { interstitialNotification } = useMobilePreviewState();

  return (
    <Box
      sx={{
        borderRadius: "12px",
        border: "3.75px solid #DBDCDD",
        justifyContent: "center",
        overflow: "hidden",
        alignContent: "end",
        height: "650px",
        alignItems: "center",
        backgroundColor: "white",
        mt: 2,
        position: "relative",
      }}
      id="drawer-container"
    >
      <Stack
        sx={{
          backgroundColor: theme.palette.onboardingBackground.main,
          color: theme.palette.onboardingForeground.main,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          boxShadow: "0px -4px 3px rgba(50, 50, 50, 0.15)",
          paddingTop: "52px",
        }}
      >
        <Stack
          sx={{
            paddingBottom: "16px",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
          spacing={2}
        >
          {interstitialNotification.media === "" ? null : (
            <CardMedia
              sx={{
                maxHeight: "400px",
                margin: "0 auto",
                objectFit: "contain",
              }}
              component="img"
              image={interstitialNotification.media ?? undefined}
            />
          )}
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.onboardingForeground.main,
              color: theme.palette.onboardingBackground.main,
              "&:hover": {
                background: theme.palette.onboardingForeground.main,
              },
              padding: "16px",
            }}
            style={{
              marginLeft: "16px",
              marginRight: "16px",
              width: "calc(100% - 32px)",
            }}
          >
            {interstitialNotification?.callToActionTitle || "Call to action"}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default InterstitialNotificationPreview;
