import { Alert, AlertTitle } from "@mui/material";

const MissingDataAlert = () => {
  return (
    <Alert severity="info">
      <AlertTitle sx={{ fontWeight: "bold" }}>
        No Analytics Data Available
      </AlertTitle>
      We apologize for the inconvenience, but it appears that there is no data
      available at the moment. This could be due to various factors, such as the
      absence of data for the selected time period or the absence of data for
      the specific metrics being monitored. If you require any assistance or
      have inquiries regarding the analytics dashboard or your data, our support
      team is ready to help. Please don't hesitate to contact us as we are more
      than happy to assist you in making the most out of our analytics features.
    </Alert>
  );
};

export default MissingDataAlert;
