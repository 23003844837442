import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React from "react";
import ReactDOM from "react-dom/client";
import AppRoot from "./App";
import "./index.css";

dayjs.extend(utc); // required when using DatePicker along with AdapterDayjs

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppRoot />
  </React.StrictMode>
);
