import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { Suspense, useCallback, useState } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { InterstitialNotificationsTabQuery } from "./__generated__/InterstitialNotificationsTabQuery.graphql";
import { InterstitialNotificationsTab_brand$key } from "./__generated__/InterstitialNotificationsTab_brand.graphql";
import CDPAudienceFilters from "../../../../../components/CDPAudienceFilters";
import { KinnLanguageCode } from "../../../../../utils/languageMap";
import InterstitialNotificationForm from "./InterstitialNotificationForm";
import InterstitialNotificationsTable from "./InterstitialNotificationsTable";

const query = graphql`
  query InterstitialNotificationsTabQuery($id: ID!) {
    brand(id: $id) {
      ...InterstitialNotificationsTab_brand
    }
  }
`;

const brandFragment = graphql`
  fragment InterstitialNotificationsTab_brand on BrandType {
    id
    ...CDPAudienceFilters_brand
    ...InterstitialNotificationsTable_refetchableBrand
    ...InterstitialNotificationForm_brand
  }
`;

export default function InterstitialNotificationsTab({
  brandID,
}: {
  brandID: string;
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const data = useLazyLoadQuery<InterstitialNotificationsTabQuery>(query, {
    id: brandID,
  });
  const brand = useFragment<InterstitialNotificationsTab_brand$key>(
    brandFragment,
    data.brand
  );

  const [searchAudiences, setSearchAudiences] = useState<string[]>([]);
  const onAudienceChange = (ids: string[]) => {
    setSearchAudiences(ids);
  };
  const [searchAudienceLanguages, setSearchAudienceLanguages] = useState<
    KinnLanguageCode[]
  >([]);
  const onAudienceLanguagesChange = useCallback(
    (languages: KinnLanguageCode[]) => {
      setSearchAudienceLanguages(languages);
    },
    [setSearchAudienceLanguages]
  );

  const tabContainer = (
    <Stack spacing={2} direction="column" alignItems="flex-end">
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        sx={{
          display: "flex",
        }}
        onClick={() => {
          navigate(location.pathname + "/create");
        }}
      >
        Create Interstitial Notification
      </Button>

      <Paper sx={{ width: "100%", mb: 2 }}>
        <Suspense
          fallback={
            <Box
              sx={{
                padding: "16px 16px",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Autocomplete
                sx={{ width: "400px" }}
                disabled
                options={[]}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <Typography variant="body2">
                        Filter by Audience
                      </Typography>
                    }
                    placeholder="Search"
                  />
                )}
              />
            </Box>
          }
        ></Suspense>

        <CDPAudienceFilters
          audienceLanguages={searchAudienceLanguages}
          brand={brand}
          onAudienceChange={onAudienceChange}
          onLanguageFiltersChange={onAudienceLanguagesChange}
        />
        <InterstitialNotificationsTable
          brandFragment={brand}
          tableSearchAudiences={searchAudiences}
          tableSearchLanguages={searchAudienceLanguages}
        />
      </Paper>
    </Stack>
  );

  return (
    <Routes>
      <Route
        path="/"
        element={<Container maxWidth="lg">{tabContainer}</Container>}
      />
      <Route
        path="/create"
        element={
          <Container maxWidth="md">
            <InterstitialNotificationForm brand={brand} />
          </Container>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <Container maxWidth="md">
            <InterstitialNotificationForm brand={brand} />
          </Container>
        }
      />
    </Routes>
  );
}
