/**
 * @generated SignedSource<<7f1fa8fb052f9cea84909a6e7bad286b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PlanInterval = "EVERY_30_DAYS" | "EVERY_365_DAYS" | "%future added value";
export type PlanStatus = "ACTIVE" | "CANCELLED" | "DECLINED" | "EXPIRED" | "FROZEN" | "NO_PLAN" | "PENDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InternBrandBillingRecurringSubCard_billingConfig$data = {
  readonly id: string;
  readonly pendingBillingConfig: {
    readonly createdAt: any | null;
    readonly currency: string;
    readonly id: string;
    readonly interval: PlanInterval;
    readonly isBlocking: boolean;
    readonly isImmediate: boolean;
    readonly price: any | null;
    readonly takeRate: any | null;
    readonly takeRateMinUsage: any;
    readonly takeRateTotalCap: any | null;
    readonly trialDays: number;
  } | null;
  readonly planCreatedAt: any | null;
  readonly planCurrency: string;
  readonly planId: string | null;
  readonly planInterval: PlanInterval;
  readonly planPrice: any | null;
  readonly planStatus: PlanStatus;
  readonly planTrialDays: number;
  readonly shopifyBillingConfig: {
    readonly appSubscriptionLineItemId: string;
  } | null;
  readonly startDate: any | null;
  readonly takeRate: any | null;
  readonly takeRateMinUsage: any;
  readonly takeRateTotalCap: any | null;
  readonly " $fragmentType": "InternBrandBillingRecurringSubCard_billingConfig";
};
export type InternBrandBillingRecurringSubCard_billingConfig$key = {
  readonly " $data"?: InternBrandBillingRecurringSubCard_billingConfig$data;
  readonly " $fragmentSpreads": FragmentRefs<"InternBrandBillingRecurringSubCard_billingConfig">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "takeRate",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "takeRateTotalCap",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "takeRateMinUsage",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternBrandBillingRecurringSubCard_billingConfig",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planCreatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planTrialDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planInterval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLPendingBillingConfig",
      "kind": "LinkedField",
      "name": "pendingBillingConfig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isBlocking",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isImmediate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "interval",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trialDays",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLShopifyBillingConfig",
      "kind": "LinkedField",
      "name": "shopifyBillingConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appSubscriptionLineItemId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GraphQLBillingConfig",
  "abstractKey": null
};
})();

(node as any).hash = "5e2bf502696244c3dcee29e052be296f";

export default node;
