/**
 * @generated SignedSource<<ca398aeaa11082390c07b0a818172a12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CartCard_brand$data = {
  readonly appConfig: {
    readonly cartIgnoredProductIds: ReadonlyArray<string> | null;
    readonly id: string;
    readonly openCartAfterUpdate: boolean;
  };
  readonly " $fragmentType": "CartCard_brand";
};
export type CartCard_brand$key = {
  readonly " $data"?: CartCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"CartCard_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartCard_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cartIgnoredProductIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "openCartAfterUpdate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "e889b14fb101bb868a9b324c52db1f08";

export default node;
