import { createContext } from "react";
import { AppColorPalette } from "../__generated__/ThemeTab_brand.graphql";

type ThemeContext = {
  // Palette
  primaryPaletteLight: string;
  setPrimaryPaletteLight: (color: string) => void;
  accent1PaletteLight: string;
  setAccent1PaletteLight: (color: string) => void;
  accent2PaletteLight: string;
  setAccent2PaletteLight: (color: string) => void;
  accent3PaletteLight: string;
  setAccent3PaletteLight: (color: string) => void;

  // Color Overrides
  //Button
  primaryButtonBackground: AppColorPalette | null;
  setPrimaryButtonBackground: (palette: AppColorPalette | null) => void;
  primaryButtonForeground: AppColorPalette | null;
  setPrimaryButtonForeground: (color: AppColorPalette | null) => void;
  secondaryButtonOutline: AppColorPalette | null;
  setSecondaryButtonOutline: (color: AppColorPalette | null) => void;

  //Pill
  primaryPillBackground: AppColorPalette | null;
  setPrimaryPillBackground: (palette: AppColorPalette | null) => void;
  primaryPillForeground: AppColorPalette | null;
  setPrimaryPillForeground: (color: AppColorPalette | null) => void;
  secondaryPillOutline: AppColorPalette | null;
  setSecondaryPillOutline: (color: AppColorPalette | null) => void;
  pillContainerBackground: AppColorPalette | null;
  setPillContainerBackground: (color: AppColorPalette | null) => void;

  //Badge
  badgeBackground: AppColorPalette | null;
  setBadgeBackground: (color: AppColorPalette | null) => void;
  badgeForeground: AppColorPalette | null;
  setBadgeForeground: (color: AppColorPalette | null) => void;

  //Navigation
  navigationBackground: AppColorPalette | null;
  setNavigationBackground: (color: AppColorPalette | null) => void;
  navigationForeground: AppColorPalette | null;
  setNavigationForeground: (color: AppColorPalette | null) => void;
};

export default createContext<ThemeContext>({
  primaryPaletteLight: "",
  setPrimaryPaletteLight: () => {},
  accent1PaletteLight: "",
  setAccent1PaletteLight: () => {},
  accent2PaletteLight: "",
  setAccent2PaletteLight: () => {},
  accent3PaletteLight: "",
  setAccent3PaletteLight: () => {},

  primaryButtonBackground: null,
  setPrimaryButtonBackground: () => {},
  primaryButtonForeground: null,
  setPrimaryButtonForeground: () => {},
  secondaryButtonOutline: null,
  setSecondaryButtonOutline: () => {},
  primaryPillBackground: null,
  setPrimaryPillBackground: () => {},
  primaryPillForeground: null,
  setPrimaryPillForeground: () => {},
  secondaryPillOutline: null,
  setSecondaryPillOutline: () => {},
  badgeBackground: null,
  setPillContainerBackground: () => {},
  pillContainerBackground: null,
  setBadgeBackground: () => {},
  badgeForeground: null,
  setBadgeForeground: () => {},
  navigationBackground: null,
  setNavigationBackground: () => {},
  navigationForeground: null,
  setNavigationForeground: () => {},
});
