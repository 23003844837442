import * as FeatherIcons from "react-feather";
import { IconType } from "../views/dashboard/build_section/navigation/__generated__/TabsCard2_brand.graphql";
import CustomIcon from "../components/icons/CustomIcon";
import ForYouIcon from "../components/icons/ForYouIcon";
import { ReactComponent as CustomCart } from "../components/icons/custom_cart.svg";
import { ReactComponent as CustomConcierge } from "../components/icons/custom_concierge.svg";
import { ReactComponent as CustomExperiences } from "../components/icons/custom_experiences.svg";
import { ReactComponent as CustomFeed } from "../components/icons/custom_feed.svg";
import { ReactComponent as CustomProfile } from "../components/icons/custom_profile.svg";
import { ReactComponent as CustomShop } from "../components/icons/custom_shop.svg";


export const CustomPublicBrandIcons: Partial<
  Record<IconType, FeatherIcons.Icon>
> = {
  FOR_YOU: ForYouIcon,
};

export const CustomRedanBrandIcons: Partial<
  Record<IconType, FeatherIcons.Icon>
> = {
  CUSTOM_CART: (props) => {
    return (
      <CustomIcon
        SVG={CustomCart}
        stroke={props.color ?? "currentColor"}
        {...props}
      />
    );
  },
  CUSTOM_CONCIERGE: (props) => {
    return <CustomIcon SVG={CustomConcierge} {...props} />;
  },
  CUSTOM_EXPERIENCES: (props) => {
    return <CustomIcon SVG={CustomExperiences} {...props} />;
  },
  CUSTOM_FEED: (props) => {
    return <CustomIcon SVG={CustomFeed} {...props} />;
  },
  CUSTOM_PROFILE: (props) => {
    return <CustomIcon SVG={CustomProfile} {...props} />;
  },
  CUSTOM_SHOP: (props) => {
    return <CustomIcon SVG={CustomShop} {...props} />;
  },
};

// only show brand specific icons to the correct brand, otherwise we'd have check the app versions when using new icons
const CustomPrivateBrandIcons: Record<
  string,
  Partial<Record<IconType, FeatherIcons.Icon>>
> = {
  "QnJhbmRUeXBlOjM4NTYyNjQwLWViMDctNDNlZS1iYzczLTliMzFiNzdjY2IxZQ==":
    CustomRedanBrandIcons,
  "QnJhbmRUeXBlOjdiNjJhZWMwLWYzZWQtNDE2Yi05YTVkLTE5YTkxMDRkNjFjNA==":
    CustomRedanBrandIcons,
  "QnJhbmRUeXBlOjQyNmQ4ZDJmLWUxMGEtNGEzZC1iYTFmLTZjN2IwZmI5MTZjNw==":
    CustomRedanBrandIcons,
  "QnJhbmRUeXBlOjZiNjAwOTU1LTY2ZjItNDMxMy1hODRiLWM0ODI3MjhhOGNiZQ==":
    CustomRedanBrandIcons,
};

export const customBrandIconTypes = (brandId: string) => {
  return [
    ...Object.keys(CustomPublicBrandIcons),
    ...Object.keys(CustomPrivateBrandIcons[brandId] ?? {}),
  ] as IconType[];
};
export type IconName = keyof typeof FeatherIcons;

export const convertToIconName = (icon: IconType): IconName => {
  return icon
    .split("_")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("") as IconName;
};

export const convertToIconType = (iconName: IconName): IconType => {
  return iconName // Need to manually convert from camelCase to snake_case
    .replace(/[A-Z0-9]/g, (match) => `_${match}`)
    .slice(1)
    .toUpperCase() as IconType;
};

export const getBrandIcon = (icon: IconType) => {
  return (
    CustomPublicBrandIcons[icon] ??
    CustomRedanBrandIcons[icon] ??
    FeatherIcons[convertToIconName(icon)]
  );
};