import LockIcon from "@mui/icons-material/Lock";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItemButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment } from "react-relay/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { Sidepane_brandFragment$key } from "./__generated__/Sidepane_brandFragment.graphql";
import { Sidepane_userFragment$key } from "./__generated__/Sidepane_userFragment.graphql";
import { SidepaneConfig } from "../../utils/constants";
import CompanyLogo from "../CompanyLogo";
import ExpandableSection from "../ExpandableSection";
import DebugButton from "../internal/DebugButton";

const drawerWidth: number = 280;

const userFragment = graphql`
  fragment Sidepane_userFragment on UserType {
    isInternal
    isStaff
  }
`;

const brandFragment = graphql`
  fragment Sidepane_brandFragment on BrandType {
    isForumEnabled
    featureFlags {
      dailyDealEnabled
    }
  }
`;

type Props = {
  user: Sidepane_userFragment$key;
  brand: Sidepane_brandFragment$key;
  brandFinishedOnboarding: boolean;
  disabled: boolean;
  isXsSidepaneOpen: boolean;
  onDrawerClose: () => void;
};

export default function Sidepane({
  user,
  brand,
  brandFinishedOnboarding,
  disabled,
  isXsSidepaneOpen,
  onDrawerClose,
}: Props): React.ReactElement {
  const theme = useTheme();
  const userData = useFragment(userFragment, user);
  const brandData = useFragment(brandFragment, brand);
  const isInternalUser = userData.isInternal;
  const isStaffUser = userData.isStaff;
  const navigate = useNavigate();
  const location = useLocation();

  const sections = SidepaneConfig.filter((section) => {
    return section.isPublic || isInternalUser;
  }).map((config, index) => {
    return (
      <ExpandableSection
        key={index}
        color="black"
        disabled={disabled}
        isExpandedByDefault={!disabled}
        title={
          <Typography
            sx={{
              color: "#9CA3AF",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "250%",
              letterSpacing: "0.5px",
              textTransform: "uppercase",
            }}
          >
            {config.sectionName}
          </Typography>
        }
      >
        <List
          sx={{
            margin: "0 0 0 28px",
            borderLeft: "1px solid rgba(255, 221, 213, 0.30)",
          }}
          component="nav"
        >
          {config.sections
            .filter((section) => {
              if (section.isFeatureEnabled?.(brandData) === false) {
                return false;
              }
              if (section.pathname === "/forum") {
                return (
                  (section.isPublic && brandData.isForumEnabled) ||
                  isInternalUser
                );
              }
              return section.isPublic || isInternalUser;
            })
            .map((section, index) => {
              if (disabled) {
                return null;
              }
              const isDisabled =
                section.isDisabledPreOnboarded && !brandFinishedOnboarding;
              const pathname = "/" + config.sectionType + section.pathname;
              const selected = location.pathname.includes(pathname);
              return (
                <Tooltip
                  title={
                    isDisabled
                      ? "You'll be able to visit this section once you have finished onboarding."
                      : ""
                  }
                  placement="top-start"
                  arrow
                  key={index}
                >
                  <span>
                    <ListItemButton
                      selected={selected}
                      onClick={() => {
                        navigate(pathname);
                        onDrawerClose();
                      }}
                      disabled={isDisabled}
                      sx={{
                        backgroundColor: "black",
                        paddingY: 1,
                        paddingX: 3,
                        mb: 1,

                        "&.Mui-selected": {
                          backgroundColor: "#292322",
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: "#292322",
                        },
                        "&:hover": {
                          background: "#292322",
                        },
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          width: "4px",
                          backgroundColor: selected
                            ? theme.palette.primary.main
                            : "transparent",
                          borderRadius: "0 4px 4px 0",
                        }}
                      />
                      <Stack
                        sx={{
                          color: theme.palette.primary.main,
                        }}
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        {isDisabled ? <LockIcon fontSize="small" /> : null}
                        <Typography
                          sx={{
                            color: selected
                              ? theme.palette.primary.main
                              : "white",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "24px",
                          }}
                        >
                          {section.title}
                        </Typography>
                      </Stack>
                    </ListItemButton>
                  </span>
                </Tooltip>
              );
            })}
        </List>
      </ExpandableSection>
    );
  });

  const content = (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        width: drawerWidth,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "black",
        zIndex: 1200,
      }}
    >
      <Box
        sx={{
          py: 3,
          width: "100%",
          px: 2,
          position: "relative",
          flexShrink: 0,
        }}
      >
        <Stack
          px={2}
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <CompanyLogo color={"white"} />
          {isStaffUser && <DebugButton />}
        </Stack>
      </Box>
      <Divider sx={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }} />
      <Box
        sx={{
          overflowY: "auto",
          flexGrow: 1,
        }}
      >
        {sections}
      </Box>
    </Box>
  );

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          display: { xs: "none", md: "block" },

          "& .MuiDrawer-paper": {
            // display: "block",
            boxSizing: "border-box",
            width: drawerWidth,
            // flex: "0 0 auto",
          },
        }}
        open
      >
        {content}
      </Drawer>

      <Drawer
        variant="temporary"
        open={isXsSidepaneOpen}
        onClose={onDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {content}
      </Drawer>
    </>
  );
}
