import { createContext } from "react";

interface DebugInterface {
  debug: boolean;
  onToggleDebug: (show: boolean) => void;
}

export default createContext<DebugInterface>({
  debug: false,
  onToggleDebug: () => {},
});
