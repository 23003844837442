/**
 * @generated SignedSource<<6fa2facd7fdcb3568cf98cbcfbc98783>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FontType = "BODY_BOLD" | "BODY_REGULAR" | "HEADING_BOLD" | "HEADING_REGULAR" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TypographyCard_brand$data = {
  readonly appConfig: {
    readonly buildTheme: {
      readonly fonts: ReadonlyArray<{
        readonly name: string;
        readonly type: FontType;
      }>;
      readonly id: string;
      readonly shouldUseHeaderFontForBody: boolean;
    };
    readonly id: string;
  };
  readonly " $fragmentType": "TypographyCard_brand";
};
export type TypographyCard_brand$key = {
  readonly " $data"?: TypographyCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"TypographyCard_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TypographyCard_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandAppConfigBuildThemeType",
          "kind": "LinkedField",
          "name": "buildTheme",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "BrandAppConfigBuildThemeFontType",
              "kind": "LinkedField",
              "name": "fonts",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shouldUseHeaderFontForBody",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "aa16a7eaac6010b0f20bb1d576971f7d";

export default node;
