import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  Suspense,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Environment,
  commitLocalUpdate,
  useLazyLoadQuery,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import { useLocation } from "react-router-dom";
import { MediaType } from "./__generated__/HighlightManageFormUpdateMutation.graphql";
import { HighlightsCardQuery } from "./__generated__/HighlightsCardQuery.graphql";
import { HighlightsCardUpdateHighlightOrderMutation } from "./__generated__/HighlightsCardUpdateHighlightOrderMutation.graphql";
import { ArchivedDropMenuFilter } from "../../../../components/ArchivedDropMenuFilter";
import CardSection from "../../../../components/CardSection";
import LeftRight from "../../../../components/LeftRight";
import SaveButton, { SavedState } from "../../../../components/SaveButton";
import DebugContext from "../../../../contexts/DebugContext";
import { State } from "../HighlightsTab";
import HighlightManageForm from "./HighlightManageForm";

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: any,
  isArchived: boolean
): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  ...(isArchived ? { filter: "brightness(0.5)" } : {}),

  // styles we need to apply on draggables
  ...draggableStyle,
});
const CARD_WIDTH_PLUS_PADDING = 201;

dayjs.extend(relativeTime);

export type GraphQLHighlight = {
  id: string;
  caption: string;
  publishedAt: string;
  destinationUrl: string | null;
  media: {
    type: MediaType;
    url: string;
  } | null;
};

export enum EditState {
  CREATE = "create",
  DRAFT = "draft",
  PUBLISHED = "published",
  NONE = "none",
}

const updateHighlightOrderMutation = graphql`
  mutation HighlightsCardUpdateHighlightOrderMutation(
    $input: UpdateHighlightOrderInput!
  ) {
    updateHighlightOrder(input: $input) {
      ... on GraphQLHighlightAlbum {
        id
        coverImageUrl
        highlights {
          edges {
            node {
              id
              publishedAt
              media {
                coverImageUrl
                isProcessing
              }
            }
          }
        }
      }
    }
  }
`;

const query = graphql`
  query HighlightsCardQuery($id: ID!, $isArchived: Boolean) {
    highlightAlbum(id: $id) {
      id
      isAutomatedAlbum
      highlights(filters: { isArchived: $isArchived }) {
        edges {
          node {
            id
            archivedAt
            publishedAt
            media {
              coverImageUrl
              isProcessing
            }
            engagementData {
              taps
              impressions
            }
          }
        }
      }
    }
  }
`;

function orderHighlightsInAlbumInRelayStore(
  environment: Environment,
  albumID: string,
  sourceIndex: number,
  destinationIndex: number,
  filters: { isArchived: boolean | null } = { isArchived: null }
) {
  return commitLocalUpdate(environment, (store) => {
    const albumRecord = store.get(albumID);
    if (albumRecord == null) {
      return;
    }

    const highlightsRootRecord = albumRecord.getLinkedRecord("highlights", {
      filters: filters,
    });
    const highlightsRecords = highlightsRootRecord?.getLinkedRecords("edges");
    if (highlightsRootRecord == null || highlightsRecords == null) {
      return;
    }

    const highlightsCopy = Array.from(highlightsRecords);
    const [removed] = highlightsCopy.splice(sourceIndex, 1);
    highlightsCopy.splice(destinationIndex, 0, removed);

    highlightsRootRecord.setLinkedRecords(highlightsCopy, "edges");
  });
}

const HighlightsCard = ({
  albumID,
  brandID,
}: {
  albumID: string;
  brandID: string;
}) => {
  const theme = useTheme();
  const { debug } = useContext(DebugContext);

  const environment = useRelayEnvironment();
  // null = all, true = archived, false = not archived
  const [searchArchived, setSearchArchived] = useState<boolean | null>(null);

  const [updateHighlightOrder, isUpdateMutationInFlight] =
    useMutation<HighlightsCardUpdateHighlightOrderMutation>(
      updateHighlightOrderMutation
    );
  const data = useLazyLoadQuery<HighlightsCardQuery>(query, {
    id: albumID,
    isArchived: null,
  });
  const highlights = useMemo(() => {
    // handle filters here instead of query for now, as it's easier to update the relay store
    if (searchArchived === null) {
      return data.highlightAlbum.highlights;
    }
    const filteredEdges = data.highlightAlbum.highlights.edges.filter(
      ({ node }) => !node.archivedAt === !searchArchived
    );
    return {
      edges: filteredEdges,
    };
  }, [data.highlightAlbum.highlights, searchArchived]);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const state = location?.state as State;
  const [activeHighlightID, setActiveHighlightID] = useState<string | null>(
    state?.highlightID ?? null
  );

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.SAVED
  );
  const [editState, setEditState] = useState<EditState>(
    state?.highlightID != null ? EditState.DRAFT : EditState.NONE
  );

  const onEditComplete = useCallback(() => {
    setActiveHighlightID(null);
    setEditState(EditState.NONE);
  }, [setActiveHighlightID, setEditState]);

  const emptyItem = (
    <Card
      sx={{
        display: "flex",
        flexShrink: 0,
        height: "325px",
        width: "185px",
        background: "#F3F4F6",
        border: "1px dashed #BDBDBD",
        borderRadius: "8px",
        alignItems: "center",
        marginLeft: 2,
        outline:
          editState === EditState.CREATE
            ? "4px solid " + theme.palette.primary.main
            : undefined,
      }}
      variant="outlined"
    >
      <CardContent>
        <Button
          disabled={false}
          size="small"
          variant="outlined"
          onClick={() => {
            setActiveHighlightID("");
            if (editState === EditState.CREATE) {
              setEditState(EditState.NONE);
            } else {
              setEditState(EditState.CREATE);
            }
          }}
        >
          <AddCircleOutlineIcon sx={{ mr: 1 }} />
          Add Highlight
        </Button>
      </CardContent>
    </Card>
  );

  const filterMenu = (
    <ArchivedDropMenuFilter
      searchArchived={searchArchived}
      onFilterChange={(searchArchived) => {
        setSearchArchived(searchArchived);
      }}
    />
  );

  const highlightDraggables = highlights.edges.map(
    ({ node: highlight }, index) => {
      const isProcessing = highlight?.media?.isProcessing ?? false;
      const isScheduled = dayjs(highlight.publishedAt).isAfter(dayjs());
      const isArchived = !!highlight.archivedAt;
      const taps = highlight.engagementData?.taps ?? 0;
      const impressions = highlight.engagementData?.impressions ?? 0;
      const image = isProcessing ? (
        <Box
          sx={{
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.5)",
            color: "white",
            height: "325px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <VideoFileIcon color="inherit" fontSize="large" />
        </Box>
      ) : (
        <CardMedia
          component="img"
          alt=""
          height={"325px"}
          image={highlight?.media?.coverImageUrl ?? ""}
        />
      );
      return (
        <Draggable
          key={"highglights-card-draggable-" + albumID + index}
          draggableId={"highglights-card-draggable-" + albumID + index}
          index={index}
          disableInteractiveElementBlocking={true}
          isDragDisabled={isArchived}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style,
                isArchived
              )}
            >
              <Card
                sx={{
                  display: "flex",
                  position: "relative",
                  height: "325px",
                  width: "185px",
                  background: "#F3F4F6",
                  borderRadius: "8px",
                  alignItems: "center",
                  marginLeft: 2,
                  outline:
                    activeHighlightID === highlight.id
                      ? "4px solid " + theme.palette.primary.main
                      : undefined,
                }}
                key={index}
              >
                <CardActionArea
                  sx={{
                    height: "100%",
                    width: "100%",
                  }}
                  disabled={isProcessing || isArchived}
                  onClick={() => {
                    if (activeHighlightID === highlight.id) {
                      onEditComplete();
                    } else {
                      setActiveHighlightID(highlight.id);
                      isScheduled
                        ? setEditState(EditState.DRAFT)
                        : setEditState(EditState.PUBLISHED);
                    }
                  }}
                >
                  {image}
                </CardActionArea>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: "8px 32px 8px 12px",
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)",
                  }}
                >
                  {isArchived && (
                    <Typography
                      color="white"
                      variant="body2"
                      sx={{ fontWeight: "600" }}
                    >
                      Archived
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                    }}
                    variant="body2"
                    color="white"
                  >
                    {isProcessing
                      ? "Processing"
                      : (isScheduled ? "Scheduled " : "Posted ") +
                        dayjs().to(dayjs(highlight.publishedAt))}
                  </Typography>
                  {!!highlight.engagementData?.impressions && (
                    <Typography color="white" variant="body2">
                      {impressions} view{impressions !== 1 ? "s" : ""} | {taps}{" "}
                      click{taps !== 1 ? "s" : ""}
                    </Typography>
                  )}
                </Box>
              </Card>
            </div>
          )}
        </Draggable>
      );
    }
  );

  let highlightEditSection = null;
  if (editState !== EditState.NONE && activeHighlightID !== null) {
    highlightEditSection = (
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: 700,
              width: "100%",
            }}
          >
            <CircularProgress />
          </div>
        }
      >
        <HighlightManageForm
          key={activeHighlightID}
          albumID={albumID}
          brandID={brandID}
          editState={editState}
          highlightID={activeHighlightID}
          isAutomatedAlbum={data.highlightAlbum.isAutomatedAlbum}
          onEditComplete={onEditComplete}
        />
      </Suspense>
    );
  }

  const debugComponent = activeHighlightID && debug && (
    <Box
      sx={{
        bgcolor: "rgb(20,184,166, 0.3)",
        fontSize: "8px",
      }}
    >
      {window.atob(activeHighlightID).split(":")[1]}
    </Box>
  );

  return (
    <DragDropContext
      onDragEnd={(result) => {
        if (scrollRef.current) {
          scrollRef.current.removeAttribute("style");
        }

        const { destination, source } = result;

        if (!destination) {
          return;
        }

        if (
          destination.droppableId !== source.droppableId ||
          destination.index === source.index
        ) {
          return;
        }

        orderHighlightsInAlbumInRelayStore(
          environment,
          albumID,
          source.index,
          destination.index
        );

        saveButtonState !== SavedState.ENABLED &&
          setSaveButtonState(SavedState.ENABLED);
      }}
      onDragStart={() => {
        if (scrollRef.current) {
          scrollRef.current.setAttribute("style", "scroll-behavior: auto");
        }
      }}
    >
      <CardSection
        title="Create and manage highlights in your album"
        titleActions={filterMenu}
        subtitle="Drag and drop to upload multiple highlights, or re-arrange, edit,
              or delete existing highlights in your album"
        content={
          <Stack display={"grid"} width={"100%"}>
            {debugComponent}
            <Box
              sx={{ scrollBehavior: "smooth" }}
              display={"flex"}
              overflow="auto"
              width={"100%"}
              paddingTop={2}
              paddingBottom={2}
              paddingRight={2}
              ref={scrollRef}
            >
              {emptyItem}
              <Droppable
                droppableId={"highlights-card-droppable" + albumID}
                direction="horizontal"
              >
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ display: "flex" }}
                  >
                    {highlightDraggables}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Box>
            <LeftRight
              expandLeft={true}
              left={
                <Button
                  disabled={false}
                  size="medium"
                  variant="outlined"
                  onClick={() => {
                    if (scrollRef.current) {
                      const currentSL = scrollRef.current.scrollLeft;
                      const newSL =
                        (Math.floor(currentSL / CARD_WIDTH_PLUS_PADDING) - 1) *
                        CARD_WIDTH_PLUS_PADDING;
                      scrollRef.current.scrollLeft = newSL;
                    }
                  }}
                >
                  <ArrowBackIcon sx={{ mr: 1 }} fontSize="small" />
                  Back
                </Button>
              }
              right={
                <Button
                  disabled={false}
                  size="medium"
                  variant="outlined"
                  onClick={() => {
                    if (scrollRef.current) {
                      const currentSL = scrollRef.current.scrollLeft;
                      const newSL =
                        (Math.floor(currentSL / CARD_WIDTH_PLUS_PADDING) + 1) *
                        CARD_WIDTH_PLUS_PADDING;
                      scrollRef.current.scrollLeft = newSL;
                    }
                  }}
                >
                  Next
                  <ArrowForwardIcon sx={{ ml: 1 }} fontSize="small" />
                </Button>
              }
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: 2,
                paddingBottom: 2,
              }}
            >
              <SaveButton
                savedState={
                  isUpdateMutationInFlight
                    ? SavedState.DISABLED
                    : saveButtonState
                }
                onClick={() => {
                  updateHighlightOrder({
                    variables: {
                      input: {
                        albumId: albumID,
                        highlightIds: highlights.edges.map(
                          ({ node: highlight }) => highlight.id
                        ),
                      },
                    },
                    onCompleted: (data, errors) => {
                      if (errors) {
                        console.log("Error: ", errors);
                      } else {
                        setSaveButtonState(SavedState.SAVED);
                      }
                    },
                  });
                }}
              />
            </Box>
          </Stack>
        }
      />
      {highlightEditSection}
    </DragDropContext>
  );
};

export default HighlightsCard;
