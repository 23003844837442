import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  let navigate = useNavigate();

  useEffect(() => {
    fetch("/api/v1/user/auth/verify")
      .then((res) => res.json())
      .then((data) => {
        if (data.is_authenticated) {
          fetch("/api/v1/user/auth/logout/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((res) => res.json())
            .then((_) => {
              navigate("/login");
            });
        } else {
          navigate("/login");
        }
      });
  }, [navigate]);

  return <></>;
};

export default Logout;
