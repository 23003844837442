/**
 * @generated SignedSource<<c5829d13b7ec3ff3ac5471aca6091ecb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InterstitialNotificationsTab_brand$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CDPAudienceFilters_brand" | "InterstitialNotificationForm_brand" | "InterstitialNotificationsTable_refetchableBrand">;
  readonly " $fragmentType": "InterstitialNotificationsTab_brand";
};
export type InterstitialNotificationsTab_brand$key = {
  readonly " $data"?: InterstitialNotificationsTab_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"InterstitialNotificationsTab_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InterstitialNotificationsTab_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CDPAudienceFilters_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InterstitialNotificationsTable_refetchableBrand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InterstitialNotificationForm_brand"
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "790e8a30f8d4235d44e2a1e043dc5959";

export default node;
