/**
 * @generated SignedSource<<ab4b855c8a43ddc34e8d3c7bfed7daee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FacebookTab_brand$data = {
  readonly facebookConfig: {
    readonly facebookPages: ReadonlyArray<{
      readonly id: any;
      readonly isSelected: boolean;
      readonly name: string;
    }>;
    readonly id: string;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "FacebookTab_brand";
};
export type FacebookTab_brand$key = {
  readonly " $data"?: FacebookTab_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"FacebookTab_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FacebookTab_brand",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLFacebookClientConfig",
      "kind": "LinkedField",
      "name": "facebookConfig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLFacebookPage",
          "kind": "LinkedField",
          "name": "facebookPages",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isSelected",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "0b1feaa1ca20ddc2475e02a5a598c2c8";

export default node;
