/**
 * @generated SignedSource<<61670194cc0bc82f1b09ee738a9de52f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ShortcutsCardQuery$variables = {
  brandID: string;
};
export type ShortcutsCardQuery$data = {
  readonly navigationItemsImported: ReadonlyArray<{
    readonly children: ReadonlyArray<{
      readonly children: ReadonlyArray<{
        readonly index: number;
        readonly label: string;
        readonly url: string;
      }>;
      readonly index: number;
      readonly label: string;
      readonly url: string;
    }>;
    readonly index: number;
    readonly label: string;
    readonly url: string;
  }>;
};
export type ShortcutsCardQuery = {
  response: ShortcutsCardQuery$data;
  variables: ShortcutsCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "brandId",
        "variableName": "brandID"
      }
    ],
    "concreteType": "NavigationItemType",
    "kind": "LinkedField",
    "name": "navigationItemsImported",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "NavigationItemType",
        "kind": "LinkedField",
        "name": "children",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NavigationItemType",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShortcutsCardQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShortcutsCardQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "1cbd8596617158386e22ed97875311f7",
    "id": null,
    "metadata": {},
    "name": "ShortcutsCardQuery",
    "operationKind": "query",
    "text": "query ShortcutsCardQuery(\n  $brandID: ID!\n) {\n  navigationItemsImported(brandId: $brandID) {\n    index\n    label\n    url\n    children {\n      index\n      label\n      url\n      children {\n        index\n        label\n        url\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3c79093a41e81c459c2435cfa27a09ef";

export default node;
