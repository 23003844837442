import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { usePaginationFragment } from "react-relay";
import { BrandAdminTablePaginationQuery } from "./__generated__/BrandAdminTablePaginationQuery.graphql";
import { BrandAdminTable_refetchableBrand$key } from "./__generated__/BrandAdminTable_refetchableBrand.graphql";
import BrandAdminTableRow from "./BrandAdminTableRow";

const refetchableFragment = graphql`
  fragment BrandAdminTable_refetchableBrand on BrandType
  @refetchable(queryName: "BrandAdminTablePaginationQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 10 }
    cursor: { type: "String" }
  ) {
    id
    admins(first: $count, after: $cursor)
      @connection(key: "BrandAdminTable_admins") {
      edges {
        node {
          ...BrandAdminTableRow_brandAdminProfile
          id
          user {
            id
            email
            firstName
          }
        }
      }
      totalCount
    }
  }
`;

const BrandAdminTable = ({
  brand: refetchableBrandKey,
  onSetDialogData: setDialogData,
}: {
  brand: BrandAdminTable_refetchableBrand$key;
  onSetDialogData: (data: {
    profileID: string;
    name: string;
    userID: string;
  }) => void;
}) => {
  const {
    data: refetchableBrand,
    loadNext,
    // isLoadingNext,
    refetch,
  } = usePaginationFragment<
    BrandAdminTablePaginationQuery,
    BrandAdminTable_refetchableBrand$key
  >(refetchableFragment, refetchableBrandKey);
  const admins = refetchableBrand.admins.edges ?? [];

  const [tablePage, setTablePage] = useState(0);
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
  const handleChangePage = (_event: unknown, newPage: number) => {
    if (newPage > tablePage) {
      loadNext(tableRowsPerPage);
    }
    setTablePage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    refetch({
      count: +event.target.value,
    });
    setTableRowsPerPage(+event.target.value);
    setTablePage(0);
  };

  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead
            sx={{
              background: "#F3F4F6",
            }}
          >
            <TableRow>
              <TableCell width={"50%"}>
                <Typography variant="overline">Users</Typography>
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {admins
              .slice(
                tablePage * tableRowsPerPage,
                tablePage * tableRowsPerPage + tableRowsPerPage
              )
              .map((admin, index) => (
                <BrandAdminTableRow
                  key={index}
                  isActionButtonDisabled={
                    refetchableBrand.admins.totalCount === 1
                  }
                  profile={admin.node}
                  onDelete={() =>
                    setDialogData({
                      profileID: admin.node.id,
                      name: admin.node.user.firstName || admin.node.user.email,
                      userID: admin.node.user.id,
                    })
                  }
                />
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={3}
                align="center"
                rowsPerPageOptions={[10, 25]}
                count={refetchableBrand.admins.totalCount ?? 0}
                rowsPerPage={tableRowsPerPage}
                page={tablePage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default BrandAdminTable;
