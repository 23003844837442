import {
  Button,
  ButtonProps,
  Tooltip,
  TooltipProps,
  withStyles,
} from "@mui/material";

const ButtonWithTooltip = ({
  tooltip,
  disabled,
  sx,
  onClick,
  ...other
}: ButtonProps & { tooltip: TooltipProps["title"] }) => {
  const adjustedButtonProps = {
    disabled: disabled,
    component: disabled ? "div" : undefined,
    onClick: disabled ? undefined : onClick,
  };
  return (
    <Tooltip title={tooltip}>
      <Button
        {...other}
        {...adjustedButtonProps}
        sx={{
          ...sx,
          "&.Mui-disabled": {
            pointerEvents: "auto",
          },
        }}
      />
    </Tooltip>
  );
};

export default ButtonWithTooltip;
