/**
 * @generated SignedSource<<c2f2cb6a5b447bfbcd88e69a7fa19aa6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NotificationTableRow_notification$data = {
  readonly audienceLanguages: ReadonlyArray<KinnLanguageCode> | null;
  readonly audiences: ReadonlyArray<{
    readonly audienceName: string;
  }>;
  readonly automaticArchiveTimestamp: any | null;
  readonly body: string;
  readonly dailyDeal: {
    readonly id: string;
  } | null;
  readonly externalThumbnailUri: string | null;
  readonly feedPost: {
    readonly id: string;
  } | null;
  readonly highlight: {
    readonly highlightAlbum: {
      readonly id: string;
    };
    readonly id: string;
  } | null;
  readonly id: string;
  readonly isArchived: boolean;
  readonly landingPath: string;
  readonly thumbnailUrl: string | null;
  readonly timestamp: any;
  readonly title: string;
  readonly utmCampaign: string | null;
  readonly uuid: any;
  readonly " $fragmentType": "NotificationTableRow_notification";
};
export type NotificationTableRow_notification$key = {
  readonly " $data"?: NotificationTableRow_notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationTableRow_notification">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationTableRow_notification",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "audienceLanguages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "automaticArchiveTimestamp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalThumbnailUri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "landingPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "utmCampaign",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLCDPAudience",
      "kind": "LinkedField",
      "name": "audiences",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "audienceName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLDailyDeal",
      "kind": "LinkedField",
      "name": "dailyDeal",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLFeedPost",
      "kind": "LinkedField",
      "name": "feedPost",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLHighlight",
      "kind": "LinkedField",
      "name": "highlight",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLHighlightAlbum",
          "kind": "LinkedField",
          "name": "highlightAlbum",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GraphQLNotification",
  "abstractKey": null
};
})();

(node as any).hash = "2db2928128ff544a578aae241db9f154";

export default node;
