/**
 * @generated SignedSource<<2e42d07208b177eda72046f50b8dd190>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useBrandUpdateMutation_brand$data = {
  readonly id: string;
  readonly " $fragmentType": "useBrandUpdateMutation_brand";
};
export type useBrandUpdateMutation_brand$key = {
  readonly " $data"?: useBrandUpdateMutation_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"useBrandUpdateMutation_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useBrandUpdateMutation_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "ec30ded1fd649a2d8304690d10098a33";

export default node;
