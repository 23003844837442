import { Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { Suspense, useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { EngageContainerQuery } from "./__generated__/EngageContainerQuery.graphql";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { SectionType, SidepaneConfig } from "../../../utils/constants";
import nullthrows from "../../../utils/nullthrows";
import AutomationTabContainer from "./AutomationTabContainer";
import DailyDealsTab from "./DailyDealsTab";
import FeedTab from "./FeedTab";
import ForumTab from "./ForumTab";
import HighlightsTab from "./HighlightsTab";
import NotificationsTab from "./NotificationsTab";

const query = graphql`
  query EngageContainerQuery($id: ID!) {
    user {
      isInternal
    }
    brand(id: $id) {
      finishedOnboarding
      isForumEnabled
      featureFlags {
        interstitialNotificationsEnabled
        dailyDealEnabled
      }
    }
  }
`;

const config = SidepaneConfig.find(
  (config) => config.sectionType === SectionType.ENGAGE
);

const EngageContainer = () => {
  const currentUserContext = useContext(CurrentUserContext);
  const data = useLazyLoadQuery<EngageContainerQuery>(query, {
    id: currentUserContext?.activeBrandID ?? "",
  });
  const user = data.user;
  const brandFinishedOnboarding = data.brand.finishedOnboarding === true;
  const activeBrandID = nullthrows(currentUserContext?.activeBrandID);

  const location = useLocation();
  const navigate = useNavigate();

  const tabs = config?.sections
    .filter((config) => {
      if (config.isFeatureEnabled?.(data.brand) === false) {
        return false;
      }
      if (config.pathname === "/forum") {
        return (
          (config.isPublic && data.brand.isForumEnabled) ||
          user?.isInternal === true
        );
      }
      return config.isPublic || user?.isInternal === true;
    })
    .map((config, index) => (
      <Tab
        disabled={config.isDisabledPreOnboarded && !brandFinishedOnboarding}
        value={"/" + SectionType.ENGAGE + config.pathname}
        label={config.title}
        key={index}
      />
    ));

  const pathSplit = location.pathname.split("/");
  return (
    <Box>
      <Typography variant="h4">Engage</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider", margin: "32px 0" }}>
        <Tabs
          value={"/" + pathSplit[1] + "/" + pathSplit[2]}
          onChange={(_, value) => {
            navigate(value);
          }}
        >
          {tabs}
        </Tabs>
      </Box>
      <Routes>
        {config?.sections
          .filter((config) => {
            return config.isPublic || user?.isInternal === true;
          })
          .map((config, index) => {
            if (config.isDisabledPreOnboarded && !brandFinishedOnboarding) {
              return null;
            }

            let element = null;

            switch (config.pathname) {
              case "/feed":
                element = (
                  <FeedTab brandID={activeBrandID} key={activeBrandID} />
                );
                break;
              case "/highlights":
                element = (
                  <HighlightsTab brandID={activeBrandID} key={activeBrandID} />
                );
                break;
              case "/daily-deals":
                if (!data.brand.featureFlags.dailyDealEnabled) {
                  break;
                }
                element = (
                  <DailyDealsTab brandID={activeBrandID} key={activeBrandID} />
                );
                break;
              case "/notifications":
                element = (
                  <NotificationsTab
                    brandID={activeBrandID}
                    key={activeBrandID}
                  />
                );
                break;
              case "/automation":
                element = (
                  <AutomationTabContainer
                    brandID={activeBrandID}
                    key={activeBrandID}
                    areInterstitialNotificationsEnabled={
                      data.brand.featureFlags.interstitialNotificationsEnabled
                    }
                  />
                );
                break;
              case "/forum":
                element = (
                  <ForumTab brandID={activeBrandID} key={activeBrandID} />
                );
                break;
            }
            return (
              <Route
                path={config.pathname + "/*"}
                element={
                  <Suspense
                    fallback={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    }
                  >
                    {element}
                  </Suspense>
                }
                key={index}
              />
            );
          })}
      </Routes>
    </Box>
  );
};

export default EngageContainer;
