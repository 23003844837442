/**
 * @generated SignedSource<<f8b525d8e539906e2b9e61e615393cd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppColorPalette = "ACCENT_1" | "ACCENT_2" | "ACCENT_3" | "BACKGROUND_1" | "BACKGROUND_2" | "BLACK" | "GRAY" | "PRIMARY" | "WHITE" | "%future added value";
export type LaunchScreenOption = "CUSTOM" | "SIMPLE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type LaunchScreenCard_brand$data = {
  readonly appConfig: {
    readonly buildTheme: {
      readonly id: string;
      readonly launchScreenAssetUrl: string | null;
      readonly launchScreenBackground: AppColorPalette | null;
      readonly launchScreenLogoUrl: string | null;
      readonly launchScreenOption: LaunchScreenOption | null;
    };
    readonly id: string;
  };
  readonly " $fragmentType": "LaunchScreenCard_brand";
};
export type LaunchScreenCard_brand$key = {
  readonly " $data"?: LaunchScreenCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"LaunchScreenCard_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LaunchScreenCard_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandAppConfigBuildThemeType",
          "kind": "LinkedField",
          "name": "buildTheme",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "launchScreenOption",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "launchScreenBackground",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "launchScreenLogoUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "launchScreenAssetUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "4a92c8f47e0d292923006dea5956a672";

export default node;
