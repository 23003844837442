import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import {
  CartesianGrid,
  LineChart as Chart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import formatNumber from "../../utils/formatNumber";
import LeftRight from "../LeftRight";

const CustomizedXTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fontSize={12} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedYTick = (props: any) => {
  const { x, y, payload } = props;
  const code = props?.currencyCode ?? undefined;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-24}
        dy={4}
        fontSize={12}
        textAnchor="middle"
        fill="#666"
      >
        {formatNumber(payload.value, props.yAxisUnit, code).split(".")[0]}
      </text>
    </g>
  );
};

const CustomTooltip = ({
  active,
  payload,
  label,
  yAxisUnit,
  currencyCode,
}: {
  active?: boolean;
  payload?: any[];
  label?: string;
  yAxisUnit?: "percent" | "currency";
  currencyCode?: string;
}) => {
  if (active && payload && payload.length) {
    const line1 = payload[0];
    const line2 = payload[1];
    const code = currencyCode ?? undefined;

    const line1Info = (
      <Box>
        {line1.name}: {formatNumber(line1.value, yAxisUnit, code)}
      </Box>
    );
    const line2Info = line2 && (
      <Box>
        {line2.name}: {formatNumber(line2.value, yAxisUnit, code)}
      </Box>
    );
    const percentage = line2 && (
      <Box>{formatNumber(line1.value / line2.value, "percent")}</Box>
    );
    return (
      <Stack
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          p: 2,
          color: "black",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "120%",
        }}
        spacing={1}
      >
        <Box>
          <b>{line1.payload.name}</b>
        </Box>
        {line2Info}
        {line1Info}
        {percentage}
      </Stack>
    );
  }

  return null;
};

const LineChart = ({
  data,
  line1Name,
  line2Name,
  title,
  yAxisUnit,
  currencyCode,
  showAverage = false,
}: {
  data: {
    name: string;
    line1DataKey: number;
    line2DataKey?: number;
  }[];
  line1Name: string;
  line2Name?: string;
  title?: string;
  yAxisUnit?: "currency" | "percent";
  currencyCode?: string;
  showAverage?: boolean;
}) => {
  const [isLine2Active, setIsLine2Active] = useState(false);

  return (
    <>
      <LeftRight
        expandLeft
        left={
          title && (
            <Typography
              sx={{
                color: "black",
                fontSize: "24px",
                fontWeight: 600,
                lineHeight: "24px",
                pb: 2,
              }}
            >
              {title}
            </Typography>
          )
        }
        right={
          showAverage && (
            <Typography>
              Average:{" "}
              {formatNumber(
                data.reduce((total, b) => total + b.line1DataKey, 0) /
                  data.length,
                yAxisUnit,
                currencyCode
              )}
            </Typography>
          )
        }
      />
      <Box
        sx={{
          height: 300,
        }}
      >
        <ResponsiveContainer>
          <Chart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" tickLine={false} tick={<CustomizedXTick />} />
            <YAxis
              allowDecimals={false}
              axisLine={false}
              tickLine={false}
              tick={
                <CustomizedYTick
                  yAxisUnit={yAxisUnit}
                  currencyCode={currencyCode}
                />
              }
            />
            <Tooltip
              content={(params) => (
                <CustomTooltip
                  {...params}
                  yAxisUnit={yAxisUnit}
                  currencyCode={currencyCode}
                />
              )}
            />
            <Legend
              formatter={(value, entry, index) => {
                return (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "120%",
                    }}
                  >
                    {value}
                  </Typography>
                );
              }}
              onClick={() => {
                setIsLine2Active(!isLine2Active);
              }}
            />
            <Line
              connectNulls
              name={line1Name}
              type="monotone"
              dataKey="line1DataKey"
              stroke="#5048E5"
              activeDot={{ r: 8 }}
              isAnimationActive={false}
            />
            <Line
              connectNulls
              name={line2Name}
              type="monotone"
              dataKey="line2DataKey"
              stroke="#bdbdbd"
              activeDot={{ r: 8 }}
              isAnimationActive={false}
              hide={!isLine2Active}
            />
          </Chart>
        </ResponsiveContainer>
      </Box>
    </>
  );
};

export default LineChart;
