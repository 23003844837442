import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { CircularProgress, Grid } from "@mui/material";

const Gorgias = () => {
  const search = useLocation().search;
  const gorgiasID = new URLSearchParams(search).get("token");

  useEffect(() => {
    if (gorgiasID != null) {
      const baselineGorgias = document.createElement("script");
      baselineGorgias.id = "gorgias-chat-widget-install-v2";
      baselineGorgias.src =
        "https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=" +
        gorgiasID;
      baselineGorgias.async = true;
      document.head.appendChild(baselineGorgias);

      baselineGorgias.addEventListener("load", () => {
        const gorgiasFullscreen = document.createElement("script");
        gorgiasFullscreen.async = false;
        gorgiasFullscreen.defer = true;
        var scriptText = document.createTextNode(
          "GorgiasChat.init().then(function() { GorgiasChat.on('widget:opened', function () { var chatWindowHead = document.querySelector('#gorgias-chat-container')?.querySelector('#chat-window')?.contentWindow.document.querySelector('head'); var chatStyle = document.createElement('style'); chatStyle.textContent = '.close-handle {display: none !important;}'; chatWindowHead.appendChild(chatStyle); }); setTimeout(function() { GorgiasChat.open(); }, 300); })"
        );
        gorgiasFullscreen.appendChild(scriptText);
        document.head.appendChild(gorgiasFullscreen);
      });
    }
    // Only want to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default Gorgias;
