/**
 * @generated SignedSource<<62e3215f74a739891429c37e2ce7e199>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HighlightsCardQuery$variables = {
  id: string;
  isArchived?: boolean | null;
};
export type HighlightsCardQuery$data = {
  readonly highlightAlbum: {
    readonly highlights: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly archivedAt: any | null;
          readonly engagementData: {
            readonly impressions: number | null;
            readonly taps: number | null;
          } | null;
          readonly id: string;
          readonly media: {
            readonly coverImageUrl: string | null;
            readonly isProcessing: boolean;
          } | null;
          readonly publishedAt: any;
        };
      }>;
    };
    readonly id: string;
    readonly isAutomatedAlbum: boolean | null;
  };
};
export type HighlightsCardQuery = {
  response: HighlightsCardQuery$data;
  variables: HighlightsCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isArchived"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "GraphQLHighlightAlbum",
    "kind": "LinkedField",
    "name": "highlightAlbum",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isAutomatedAlbum",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "isArchived",
                "variableName": "isArchived"
              }
            ],
            "kind": "ObjectValue",
            "name": "filters"
          }
        ],
        "concreteType": "GraphQLHighlightConnection",
        "kind": "LinkedField",
        "name": "highlights",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLHighlightEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLHighlight",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archivedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publishedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphQLMedia",
                    "kind": "LinkedField",
                    "name": "media",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "coverImageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isProcessing",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphqlAttributedHighlight",
                    "kind": "LinkedField",
                    "name": "engagementData",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "taps",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "impressions",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HighlightsCardQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HighlightsCardQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "06c5b0fe85e4fc1b06c7a72fb541fbd8",
    "id": null,
    "metadata": {},
    "name": "HighlightsCardQuery",
    "operationKind": "query",
    "text": "query HighlightsCardQuery(\n  $id: ID!\n  $isArchived: Boolean\n) {\n  highlightAlbum(id: $id) {\n    id\n    isAutomatedAlbum\n    highlights(filters: {isArchived: $isArchived}) {\n      edges {\n        node {\n          id\n          archivedAt\n          publishedAt\n          media {\n            coverImageUrl\n            isProcessing\n          }\n          engagementData {\n            taps\n            impressions\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "075ecf471dea40f1031184d6a06771b5";

export default node;
