import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CardActions,
  Container,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { ChangeEvent, FocusEvent, useContext, useState } from "react";
import { useFragment } from "react-relay";
import { ShopifyTab_brand$key } from "./__generated__/ShopifyTab_brand.graphql";
import CardSection from "../../../components/CardSection";
import CopyButton from "../../../components/CopyButton";
import SaveButton, { SavedState } from "../../../components/SaveButton";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import SnackbarContext from "../../../contexts/SnackbarContext";
import useBrandUpdateMutation from "../../../mutations/useBrandUpdateMutation";

const brandFragment = graphql`
  fragment ShopifyTab_brand on BrandType {
    ...useBrandUpdateMutation_brand
    shopifyConfig {
      appEmbedUrl
      shopifyUrl
      isConnected
      multipassSecret
    }
    appConfig {
      appIconUrl
      iosAppStoreLink
      androidAppStoreLink
    }
  }
`;

type Props = {
  brand: ShopifyTab_brand$key;
};

// TODO: Only show button if shopify API key is missing
const ShopifyTab = ({ brand: brandKey }: Props) => {
  const currentUserContext = useContext(CurrentUserContext);
  const snackbarContext = useContext(SnackbarContext);

  const brand = useFragment<ShopifyTab_brand$key>(brandFragment, brandKey);
  const [updateBrand, isBrandMutationInFlight] = useBrandUpdateMutation(brand);

  let existingShopifyUrl = null;
  const shopifyConfig = brand.shopifyConfig;
  if (shopifyConfig !== null) {
    if (shopifyConfig.shopifyUrl !== null && shopifyConfig.isConnected) {
      existingShopifyUrl = shopifyConfig.shopifyUrl;
    }
  }

  const [shopifyStore, setShopifyStore] = useState("");
  const [multipassSecret, setMultipassSecret] = useState<string>(
    brand?.shopifyConfig?.multipassSecret ?? ""
  );
  const [showMultipassSecret, setShowMultipassSecret] = useState(false);
  const handleShowMultipassSecret = () =>
    setShowMultipassSecret(!showMultipassSecret);

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    multipassSecret === "" ? SavedState.DISABLED : SavedState.SAVED
  );

  const onSubmit = () => {
    var newWindow = window.open();
    fetch("/api/v1/shopify/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        brand_id: currentUserContext?.activeBrandID,
        shop: shopifyStore,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        const redirect = data.redirect_url;
        if (redirect) {
          if (newWindow) {
            newWindow.location = redirect;
          } else {
            snackbarContext?.openSnackbar(
              "Shopify integration failed, missing redirect URL",
              "error"
            );
          }
        } else {
          newWindow?.close();
          snackbarContext?.openSnackbar(
            "Shopify integration failed, missing redirect URL",
            "error"
          );
        }
      });
  };

  const isConnectedToShopify =
    existingShopifyUrl !== null && existingShopifyUrl !== "";
  let alreadyConnectedText = null;
  if (isConnectedToShopify) {
    alreadyConnectedText = (
      <div>
        Your Kinn account is already connected to{" "}
        <b>
          <em>{existingShopifyUrl}</em>
        </b>
        . If you'd like to connect to a different Shopify store, please connect
        it below. This will break the connection to the existing Shopify
        storefront.
      </div>
    );
  }

  const appEmbedUrl = shopifyConfig?.appEmbedUrl || null;
  const appIconUrl = brand.appConfig.appIconUrl || null;
  const appleUrl = brand.appConfig.iosAppStoreLink || null;
  const androidUrl = brand.appConfig.androidAppStoreLink || null;

  const isBannerShowable =
    appEmbedUrl !== null &&
    appIconUrl !== null &&
    appleUrl !== null &&
    androidUrl !== null;
  let bannerCopySteps = !isBannerShowable ? (
    <Typography fontWeight={700}>
      Please speak to your Kinn contact to make sure your mobile applications
      are fully setup
    </Typography>
  ) : (
    <div>
      <Typography variant="subtitle1" paddingTop={"24px"}>
        Steps to show the mobile download banner in your storefront
      </Typography>
      <ol
        style={{
          lineHeight: 2,
        }}
      >
        <li>
          Click{" "}
          <Link href={shopifyConfig?.appEmbedUrl ?? ""} target="_blank">
            here
          </Link>{" "}
          to enable the mobile download banner
        </li>
        <li>
          <CopyButton textToCopy={appleUrl ?? ""} /> and paste the Apple App
          Store URL into the <b>Apple App Store Url</b> input
        </li>
        <li>
          <CopyButton textToCopy={androidUrl ?? ""} /> and paste the Android
          Play Store URL into the <b>Android Play Store Url</b> input
        </li>
        <li>
          <CopyButton textToCopy={appIconUrl ?? ""} />
          and paste the app icon url into the <b>Icon Url</b> input
        </li>
        <li>
          Click <b>Save</b> on the top right corner of the Shopify theme config
          page to start showing the banner to your customers
        </li>
        <li>
          Navigate to your storefront on a mobile device to see it in action!
        </li>
      </ol>
    </div>
  );

  const bannerPreview = (
    <Stack
      direction={"row"}
      justifyContent="space-between"
      alignItems={"center"}
      sx={{
        background: "white",
        border: "0.5px solid rgba(0, 0, 0, 0.2)",
        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
        padding: "12px",
        position: "relative",
        width: 350,
      }}
    >
      <img
        id="kinn-banner-image"
        alt=""
        src={appIconUrl ?? ""}
        style={{
          borderRadius: "4px",
          objectFit: "contain",
        }}
        height={40}
        width={40}
      />
      <Box
        sx={{
          fontSize: "14px",
          margin: "0 12px",
          lineHeight: "17px",
          letterSpacing: "-0.02em",
          flexGrow: 1,
          textAlign: "left",
        }}
      >
        <b>Download our app</b>
        <Box>Get exclusive offers and content</Box>
      </Box>
      <Box>
        <button
          style={{
            background: "black",
            borderRadius: "4px",
            cursor: "pointer",
            fontWeight: "700",
            color: "white",
            border: "none",
            padding: "10px",
          }}
        >
          GET
        </button>
      </Box>
      <button
        style={{
          background: "white",
          borderRadius: "50%",
          cursor: "pointer",
          position: "absolute",
          width: "24px",
          height: "24px",
          border: "0.5px solid rgba(0, 0, 0, 0.2)",
          top: "-8px",
          right: "-8px",
        }}
      >
        &#x2715;
      </button>
    </Stack>
  );

  return (
    <Container maxWidth="md">
      <Stack spacing={2}>
        <CardSection
          title={"Connect"}
          content={
            <Stack spacing={1}>
              {alreadyConnectedText}
              <Box sx={{ display: "flex" }}>
                <TextField
                  sx={{
                    flexGrow: 1,
                  }}
                  margin="normal"
                  label="Shopify Store"
                  placeholder="store-name.myshopify.com"
                  variant="outlined"
                  value={shopifyStore}
                  onChange={(event: FocusEvent<HTMLInputElement>) => {
                    setShopifyStore(event.target.value);
                  }}
                />
                <Button
                  sx={{ margin: "16px" }}
                  onClick={onSubmit}
                  variant="contained"
                  size="medium"
                >
                  Connect Shopify Store
                </Button>
              </Box>
            </Stack>
          }
        />
        {isConnectedToShopify ? (
          <CardSection
            title={"Multipass"}
            content={
              <Stack spacing={1}>
                <div>
                  Multipass is needed to enable passwordless login within your
                  mobile application. Please{" "}
                  <Link
                    href={
                      "https://shopify.dev/docs/api/multipass#1-enable-multipass-login-in-the-shopify-admin"
                    }
                    target="_blank"
                  >
                    follow the instructions
                  </Link>{" "}
                  in the Shopify documentation to enable Multipass and fetch
                  your secret key.
                </div>
                <Box sx={{ display: "flex" }}>
                  <TextField
                    sx={{
                      flexGrow: 1,
                    }}
                    margin="normal"
                    label="Multipass Secret Key"
                    placeholder="2195a-secrets-5d491"
                    variant="outlined"
                    type={showMultipassSecret ? "text" : "password"}
                    value={multipassSecret}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setMultipassSecret(event.target.value);
                      setSaveButtonState(SavedState.ENABLED);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle Multipass Secret visibility"
                            onClick={handleShowMultipassSecret}
                            onMouseDown={handleShowMultipassSecret}
                          >
                            {showMultipassSecret ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CardActions
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <SaveButton
                      savedState={
                        isBrandMutationInFlight
                          ? SavedState.DISABLED
                          : saveButtonState
                      }
                      onClick={() => {
                        updateBrand(
                          {
                            shopifyConfig: {
                              multipassSecret,
                            },
                          },
                          (_) => {
                            setSaveButtonState(SavedState.SAVED);
                          }
                        );
                      }}
                    />
                  </CardActions>
                </Box>
              </Stack>
            }
          />
        ) : null}
        {isConnectedToShopify ? (
          <CardSection
            title={"Banner"}
            content={
              <Stack spacing={2}>
                {bannerPreview}
                {bannerCopySteps}
              </Stack>
            }
          />
        ) : null}
      </Stack>
    </Container>
  );
};

export default ShopifyTab;
