/**
 * @generated SignedSource<<b3d0ca7454e9b3d6c64f867f51fb5d36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CDPAudienceLanguageSelector_brand$data = {
  readonly appConfig: {
    readonly enableLanguageSpecificContent: boolean;
    readonly supportedLanguages: ReadonlyArray<string> | null;
  };
  readonly " $fragmentType": "CDPAudienceLanguageSelector_brand";
};
export type CDPAudienceLanguageSelector_brand$key = {
  readonly " $data"?: CDPAudienceLanguageSelector_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"CDPAudienceLanguageSelector_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CDPAudienceLanguageSelector_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supportedLanguages",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enableLanguageSpecificContent",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "06a5df1d85f3c661a2b329f4828a1793";

export default node;
