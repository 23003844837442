import { TableCell, TableRow, Typography } from "@mui/material";
import { FeedTabTableRow_feedPost$data } from "./__generated__/FeedTabTableRow_feedPost.graphql";
import formatNumber from "../../../../utils/formatNumber";

const FeedTabPollTableRowDropdown = ({
  poll,
  isOpen,
  isNew,
}: {
  poll: FeedTabTableRow_feedPost$data["poll"];
  isOpen: boolean;
  isNew: boolean;
}) => {
  const options = poll?.pollOptions;
  const totalResponses = options
    ?.map((option) => option.voteCount)
    .reduce((total, current) => total + current, 0);
  return isOpen && options ? (
    <TableRow
      key={poll.title}
      sx={{
        background: isNew ? "rgba(0,0,0, 0.08)" : "white",
        transition: "background .2s ease-in",
      }}
    >
      <TableCell
        align="left"
        sx={{
          maxWidth: "100px",
          overflow: "visible",
          padding: "16px 0px 16px 16px",
        }}
      >
        {options.map((option) => (
          <div
            style={{
              width: "40vw",
              flexWrap: "wrap",
              display: "flex",
              flexDirection: "row",
              alignContent: "space-between",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                marginLeft: "16px",
                lineHeight: "24px",
                fontSize: "14px",
                color: option.isCustomerCreatedOption ? "grey" : "black",
              }}
            >
              {option.title}
            </Typography>
            <Typography
              sx={{
                marginRight: "8px",
                lineHeight: "24px",
                whiteSpace: "nowrap",
                fontSize: "14px",
              }}
            >{`${option.voteCount} responses (${formatNumber(
              option.voteCount / (totalResponses ?? 0),
              "percent"
            )})`}</Typography>
          </div>
        ))}
        <div
          style={{
            width: "40vw",
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
            alignContent: "space-between",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              marginLeft: "16px",
              lineHeight: "32px",
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            Total Poll Responses
          </Typography>
          <Typography
            sx={{
              marginRight: "8px",
              lineHeight: "32px",
              whiteSpace: "nowrap",
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            {totalResponses} responses
          </Typography>
        </div>
      </TableCell>
      {Array(5).fill(<TableCell />)}
    </TableRow>
  ) : (
    <></>
  );
};
export default FeedTabPollTableRowDropdown;
