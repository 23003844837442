import { useLocation } from "react-router-dom";

import { Provider } from "@shopify/app-bridge-react";
import "@shopify/polaris/build/esm/styles.css";
import { AppProvider, Page, Card } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";

const ShopifyEmbedded = () => {
  const search = useLocation().search;
  const host = new URLSearchParams(search).get("host") || "";
  const config = {
    apiKey: "f9983a1b823dc9db23775b6a213a489b",
    host: host,
    forceRedirect: false,
  };
  return (
    <Provider config={config}>
      <AppProvider i18n={enTranslations}>
        <Page>
          <Card
            sectioned
            title="Kinn Account"
            primaryFooterAction={{
              content: "Go to your Kinn Dashboard",
              url: "https://app.getkinn.com/",
              external: true,
            }}
          >
            <p>
              Log into your Kinn account to manage your mobile application,
              create and manage content, and send push notifications.
            </p>
          </Card>
        </Page>
      </AppProvider>
    </Provider>
  );
};

export default ShopifyEmbedded;
