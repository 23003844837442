/**
 * @generated SignedSource<<c092164b02063e54a00f8e3a30307a1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PlanStatus = "ACTIVE" | "CANCELLED" | "DECLINED" | "EXPIRED" | "FROZEN" | "NO_PLAN" | "PENDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InternBrandBillingOneTimePurchaseCard_brand$data = {
  readonly billingConfig: {
    readonly id: string;
    readonly oneTimePurchaseCharges: ReadonlyArray<{
      readonly chargeId: string | null;
      readonly createdAt: any | null;
      readonly currency: string;
      readonly id: string;
      readonly isBlocking: boolean;
      readonly name: string | null;
      readonly price: any;
      readonly status: PlanStatus;
    }>;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "InternBrandBillingOneTimePurchaseCard_brand";
};
export type InternBrandBillingOneTimePurchaseCard_brand$key = {
  readonly " $data"?: InternBrandBillingOneTimePurchaseCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"InternBrandBillingOneTimePurchaseCard_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternBrandBillingOneTimePurchaseCard_brand",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLBillingConfig",
      "kind": "LinkedField",
      "name": "billingConfig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GraphQLOneTimePurchaseCharge",
          "kind": "LinkedField",
          "name": "oneTimePurchaseCharges",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isBlocking",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chargeId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "ea2ccfecd3ee42934f46415e8b8c5e8b";

export default node;
