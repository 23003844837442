/**
 * @generated SignedSource<<d953bdd7719abcaa8256506c0dcbaa6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CDPAudienceSelectorCard_brand$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CDPAudienceLanguageSelector_brand" | "CDPAudienceSelector_brand">;
  readonly " $fragmentType": "CDPAudienceSelectorCard_brand";
};
export type CDPAudienceSelectorCard_brand$key = {
  readonly " $data"?: CDPAudienceSelectorCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"CDPAudienceSelectorCard_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CDPAudienceSelectorCard_brand",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CDPAudienceSelector_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CDPAudienceLanguageSelector_brand"
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "2c84160b17ac34cb8550ad225422bf33";

export default node;
