/**
 * @generated SignedSource<<b54c25db0a89fd1dc8065501ce9c4288>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateNavigationItemsInput = {
  brandId: string;
  navigationItems?: ReadonlyArray<NavigationItemInputPartial> | null;
};
export type NavigationItemInputPartial = {
  children?: ReadonlyArray<NavigationItemInputPartial> | null;
  id?: string | null;
  index: number;
  label: string;
  url: string;
};
export type ShortcutsCardNavigationItemsUpdateMutation$variables = {
  input: UpdateNavigationItemsInput;
};
export type ShortcutsCardNavigationItemsUpdateMutation$data = {
  readonly updateNavigationItems: {
    readonly " $fragmentSpreads": FragmentRefs<"ShortcutsCard_brand">;
  };
};
export type ShortcutsCardNavigationItemsUpdateMutation = {
  response: ShortcutsCardNavigationItemsUpdateMutation$data;
  variables: ShortcutsCardNavigationItemsUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShortcutsCardNavigationItemsUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "updateNavigationItems",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ShortcutsCard_brand"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShortcutsCardNavigationItemsUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "updateNavigationItems",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandAppConfigType",
            "kind": "LinkedField",
            "name": "appConfig",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shouldLockMenus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NavigationItemType",
                "kind": "LinkedField",
                "name": "navigationItems",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NavigationItemType",
                    "kind": "LinkedField",
                    "name": "children",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NavigationItemType",
                        "kind": "LinkedField",
                        "name": "children",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandShopifyConfigType",
            "kind": "LinkedField",
            "name": "shopifyConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isConnected",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "005a60a22efa58faf407345cdd61d601",
    "id": null,
    "metadata": {},
    "name": "ShortcutsCardNavigationItemsUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ShortcutsCardNavigationItemsUpdateMutation(\n  $input: UpdateNavigationItemsInput!\n) {\n  updateNavigationItems(input: $input) {\n    ...ShortcutsCard_brand\n    id\n  }\n}\n\nfragment ShortcutsCard_brand on BrandType {\n  id\n  appConfig {\n    id\n    shouldLockMenus\n    navigationItems {\n      index\n      label\n      url\n      children {\n        index\n        label\n        url\n        children {\n          index\n          label\n          url\n        }\n      }\n    }\n  }\n  shopifyConfig {\n    isConnected\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "88fd4f6e08b1c57f0d0fd0cbe190b6e0";

export default node;
