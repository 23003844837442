import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { Suspense, useCallback, useState } from "react";
import {
  ConnectionHandler,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from "react-relay";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { FeedTabDeleteLinkMutation } from "./__generated__/FeedTabDeleteLinkMutation.graphql";
import { FeedTabQuery } from "./__generated__/FeedTabQuery.graphql";
import { FeedTabTableRowArchiveMutation } from "./__generated__/FeedTabTableRowArchiveMutation.graphql";
import { FeedTab_brand$key } from "./__generated__/FeedTab_brand.graphql";
import CDPAudienceFilters from "../../../components/CDPAudienceFilters";
import { KinnLanguageCode } from "../../../utils/languageMap";
import nullthrows from "../../../utils/nullthrows";
import FeedPostForm from "./feed/FeedPostForm";
import FeedTabTable from "./feed/FeedTabTable";

const archiveFeedPostMutation = graphql`
  mutation FeedTabTableRowArchiveMutation($input: NodeInput!) {
    archiveFeedPost(input: $input) {
      ... on GraphQLFeedPost {
        id
        isArchived
      }
    }
  }
`;

const deleteLinkMutation = graphql`
  mutation FeedTabDeleteLinkMutation($input: NodeInput!) {
    deleteFeedPost(input: $input) {
      ... on GraphQLFeedPost {
        id
      }
    }
  }
`;

const query = graphql`
  query FeedTabQuery($id: ID!) {
    brand(id: $id) {
      ...FeedTab_brand
    }
  }
`;

const brandFragment = graphql`
  fragment FeedTab_brand on BrandType {
    ...FeedPostForm_brand
    ...FeedTabTable_refetchableBrand
    ...CDPAudienceFilters_brand
    id
  }
`;

const FeedTab = ({ brandID }: { brandID: string }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const data = useLazyLoadQuery<FeedTabQuery>(query, {
    id: brandID,
  });
  const brand = useFragment<FeedTab_brand$key>(brandFragment, data.brand);

  const [searchAudiences, setSearchAudiences] = useState<string[]>([]);
  const onAudienceChange = (ids: string[]) => {
    setSearchAudiences(ids);
  };
  const [searchAudienceLanguages, setSearchAudienceLanguages] = useState<
    KinnLanguageCode[]
  >([]);
  const onAudienceLanguagesChange = useCallback(
    (languages: KinnLanguageCode[]) => {
      setSearchAudienceLanguages(languages);
    },
    [setSearchAudienceLanguages]
  );

  // Archive Dialog State
  const [archiveDialogPostID, setArchiveDialogPostID] = useState<string | null>(
    null
  );
  const isArchiveDialogOpen = archiveDialogPostID !== null;
  const onArchiveDialogClose = () => setArchiveDialogPostID(null);

  // Delete Dialog State
  const [deleteDialogPostID, setDeleteDialogPostID] = useState<string | null>(
    null
  );
  const isDeleteDialogOpen = deleteDialogPostID !== null;
  const onDeleteDialogClose = () => setDeleteDialogPostID(null);

  // Publish/Edit
  const [justPublishedPostID, setJustPublishedPostID] = useState<string | null>(
    null
  );
  const onUpdateComplete = useCallback(
    (postID?: string) => {
      if (postID) {
        setJustPublishedPostID(postID);
        setTimeout(() => {
          setJustPublishedPostID(null);
        }, 3000);
      }
    },
    [setJustPublishedPostID]
  );

  const deleteNotifications = useCallback(
    (mutation: string, store: any) => {
      const brand = store.get(brandID);
      if (brand == null) {
        return;
      }
      const payload = store.getRootField(mutation);
      if (!payload) {
        return null;
      }
      const feedPostID = payload.getDataID();
      const feedPost = store.get(feedPostID);
      const postConnection = ConnectionHandler.getConnection(
        brand,
        "FeedTabTable_feedPosts",
        {
          filters: {
            audienceIds: [],
            audienceLanguages: [],
          },
        }
      );
      if (postConnection == null) {
        return null;
      }

      // Delete the associated notif if it exists
      const existingNotifRecord = feedPost
        ?.getLinkedRecord("notification")
        ?.getDataID();
      const notifConnection = ConnectionHandler.getConnection(
        brand,
        "NotificationsTab_notifications"
      );
      if (
        feedPost != null &&
        notifConnection != null &&
        existingNotifRecord != null
      ) {
        ConnectionHandler.deleteNode(notifConnection, existingNotifRecord);
        const notifCount = notifConnection.getValue("totalCount");
        if (notifCount != null) {
          notifConnection.setValue(Number(notifCount) - 1, "totalCount");
        }
      }
      return { postConnection, payload };
    },
    [brandID]
  );

  const [archiveFeedPost, isArchiving] =
    useMutation<FeedTabTableRowArchiveMutation>(archiveFeedPostMutation);
  const onArchive = useCallback(
    (id: string) => {
      archiveFeedPost({
        variables: {
          input: {
            id: id,
          },
        },
        onCompleted: (data, errors) => {
          if (errors) {
            console.log("Error: ", data, errors);
          } else {
            onArchiveDialogClose();
          }
        },
        updater: (store) => {
          deleteNotifications("archiveFeedPost", store);
        },
      });
    },

    [archiveFeedPost, deleteNotifications]
  );

  const [deleteLink, isDeleteLinkMutationInFlight] =
    useMutation<FeedTabDeleteLinkMutation>(deleteLinkMutation);

  const onDelete = useCallback(
    (id: string) => {
      deleteLink({
        variables: {
          input: {
            id: id,
          },
        },
        onCompleted: (data, errors) => {
          if (errors) {
            console.log("Error: ", data, errors);
          } else {
            onDeleteDialogClose();
          }
        },
        updater: (store) => {
          const result = deleteNotifications("deleteFeedPost", store);
          if (!result) {
            return;
          }
          const { postConnection, payload } = result;
          // Delete the feed post node
          ConnectionHandler.deleteNode(postConnection, payload.getDataID());
          const feedCount = postConnection.getValue("totalCount");
          if (feedCount != null) {
            postConnection.setValue(Number(feedCount) - 1, "totalCount");
          }
        },
      });
    },
    [deleteLink, deleteNotifications]
  );
  const tableView = (
    <>
      <Stack spacing={2} direction="column" alignItems="flex-end">
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          sx={{
            display: "flex",
          }}
          onClick={() => {
            setSearchAudiences([]);
            setSearchAudienceLanguages([]);
            navigate(location.pathname + "/create", {
              state: {
                from: "feed_table",
              },
            });
          }}
        >
          Create Feed Post
        </Button>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Suspense
            fallback={
              <Box
                sx={{
                  padding: "16px 16px",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Autocomplete
                  sx={{ width: "400px" }}
                  disabled
                  options={[]}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <Typography variant="body2">
                          Filter by Audience
                        </Typography>
                      }
                      placeholder="Search"
                    />
                  )}
                />
              </Box>
            }
          >
            <CDPAudienceFilters
              audienceLanguages={searchAudienceLanguages}
              brand={brand}
              onAudienceChange={onAudienceChange}
              onLanguageFiltersChange={onAudienceLanguagesChange}
            />
          </Suspense>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead
                sx={{
                  background: "#F3F4F6",
                }}
              >
                <TableRow>
                  <TableCell width={"40%"}>
                    <Typography variant="overline">Feed Post</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="overline">Format</Typography>
                  </TableCell>
                  <TableCell align="center" width={"15%"}>
                    <Typography variant="overline">Audience</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="overline">Status</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="overline">Date Published</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="overline">Actions</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <Suspense
                fallback={
                  <TableBody>
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        sx={{
                          padding: "128px 0",
                        }}
                      >
                        <CircularProgress color="primary" size={32} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                }
              >
                <FeedTabTable
                  refetchableBrand={brand}
                  justPublishedPostID={justPublishedPostID}
                  tableSearchAudiences={searchAudiences}
                  tableSearchLanguages={searchAudienceLanguages}
                  setArchiveDialogPostID={setArchiveDialogPostID}
                  setDeleteDialogPostID={setDeleteDialogPostID}
                  onEditClick={() => {
                    setSearchAudiences([]);
                    setSearchAudienceLanguages([]);
                  }}
                />
              </Suspense>
            </Table>
          </TableContainer>
        </Paper>
      </Stack>
      <Dialog
        open={isArchiveDialogOpen}
        onClose={onArchiveDialogClose}
        aria-labelledby="link-post-deletion-dialog"
      >
        <DialogTitle>Are you sure you want to archive this post?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your customers won’t be able to view your post after you archive it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onArchiveDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={isArchiving}
            onClick={() => onArchive(nullthrows(archiveDialogPostID))}
            color="error"
          >
            Archive
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={onDeleteDialogClose}
        aria-labelledby="link-post-deletion-dialog"
      >
        <DialogTitle>Are you sure you want to delete this post?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your customers won’t be able to view your post after you delete it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={isDeleteLinkMutationInFlight}
            onClick={() => onDelete(nullthrows(deleteDialogPostID))}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  return (
    <Routes>
      <Route
        path="/"
        element={<Container maxWidth="lg">{tableView}</Container>}
      />
      <Route
        path="/create"
        element={
          <Container maxWidth="md">
            <FeedPostForm onUpdateComplete={onUpdateComplete} brand={brand} />
          </Container>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <Container maxWidth="md">
            <FeedPostForm onUpdateComplete={onUpdateComplete} brand={brand} />
          </Container>
        }
      />
    </Routes>
  );
};
export default FeedTab;
