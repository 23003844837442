import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Accordion,
  AccordionDetails,
  Avatar,
  Box,
  Button,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useState } from "react";
import { CSVLink } from "react-csv";
import { useFragment } from "react-relay";
import { AutomatedNotificationType } from "../engage/__generated__/AutomationTab_brand.graphql";
import { InsightsTabAutomatedNotificationsTable_brand$key } from "./__generated__/InsightsTabAutomatedNotificationsTable_brand.graphql";
import formatNumber from "../../../utils/formatNumber";
import { KDateRange, formatDateRange } from "./types/KDateRange";

const brandFragment = graphql`
  fragment InsightsTabAutomatedNotificationsTable_brand on BrandType {
    id
    currencyCode
    displayName
    automationMetricsDaily(
      filters: { date: { lte: $endDate, gte: $startDate } }
    ) {
      edges {
        node {
          notificationType
          notificationPosition
          pushNotificationDeliveries
          pushNotificationTaps
          attributedSales
        }
      }
    }
    appConfig {
      automatedNotifications(filters: { isEnabled: true }) {
        edges {
          node {
            id
            notifType
            title
            message
            delaySeconds
          }
        }
      }
    }
  }
`;
const InsightsTabAutomatedNotificationsTableRow = ({
  notifType,
  delayMinutes,
  title,
  message,
  metrics,
  currencyCode,
}: {
  notifType: AutomatedNotificationType;
  delayMinutes: number;
  title: string;
  message: string;
  metrics: {
    deliveries: number;
    taps: number;
    attributedSales: number;
  };
  currencyCode: string;
}) => {
  const theme = useTheme();
  const notificationItem = (
    <ListItem>
      <ListItemAvatar>
        <Avatar
          alt=""
          src={undefined}
          variant="rounded"
          sx={{ bgcolor: theme.palette.info.main, color: "black" }}
        >
          <NotificationsNoneIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            variant="body2"
            color={"black"}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {title}
          </Typography>
        }
        secondary={
          <Typography
            variant="body2"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {message}
          </Typography>
        }
      />
    </ListItem>
  );
  const taps = metrics.taps;
  const deliveries = metrics.deliveries;
  const sales = metrics.attributedSales;
  const notifTypeF = notifType.replace("_", " ");

  return (
    <TableRow
      sx={{
        position: "relative",
        ":last-child td": {
          borderBottom: "none",
        },
      }}
    >
      <TableCell align="center" width={"40%"}>
        {notificationItem}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          display: {
            xs: "none",
            md: "table-cell",
          },
        }}
      >
        {notifTypeF.charAt(0) + notifTypeF.slice(1).toLowerCase()}
        <Typography
          variant="body2"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
          }}
        >
          {delayMinutes} minute delay
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Stack
          direction={"column"}
          alignItems={"flex-end"}
          position={"relative"}
        >
          <Stack display={"inline-flex"} direction={"row"} spacing={1}>
            <Typography
              variant="body2"
              sx={{
                color: "black",
              }}
            >
              {formatNumber(deliveries)} Deliveries
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography
              variant="body2"
              sx={{
                color: "black",
              }}
            >
              {formatNumber(taps)} Clicks
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography
              variant="body2"
              sx={{
                color: "black",
              }}
            >
              {formatNumber(taps / deliveries, "percent")} CTR
            </Typography>
          </Stack>
          <Box>
            {sales === 0 ? (
              <Typography variant="body2">--</Typography>
            ) : (
              <Typography variant="body2">
                {formatNumber(sales, "currency", currencyCode)} Attributed
                Revenue
              </Typography>
            )}
          </Box>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

const InsightsTabAutomatedAutomatedNotificationsTable = ({
  brand: brandKey,
  dateRange,
}: {
  brand: InsightsTabAutomatedNotificationsTable_brand$key;
  dateRange: KDateRange;
}) => {
  const brand = useFragment(brandFragment, brandKey);
  const metrics = brand.automationMetricsDaily.edges;
  const notifs = brand.appConfig.automatedNotifications.edges;

  const [isExpanded, setIsExpanded] = useState(true);

  let totalDeliveries = 0;
  let totalTaps = 0;

  const metricsHashmap = metrics.reduce(
    (acc, curr) => {
      totalDeliveries += curr.node.pushNotificationDeliveries;
      totalTaps += curr.node.pushNotificationTaps;

      const type = curr.node.notificationType as AutomatedNotificationType;
      const index = type + curr.node.notificationPosition;
      const item = acc.get(index);

      if (item) {
        acc.set(index, {
          deliveries: item["deliveries"] + curr.node.pushNotificationDeliveries,
          taps: item["taps"] + curr.node.pushNotificationTaps,
          attributedSales: item["attributedSales"] + curr.node.attributedSales,
        });
      } else {
        acc.set(index, {
          deliveries: curr.node.pushNotificationDeliveries,
          taps: curr.node.pushNotificationTaps,
          attributedSales: curr.node.attributedSales,
        });
      }
      return acc;
    },
    new Map() as Map<
      string,
      {
        deliveries: number;
        taps: number;
        attributedSales: number;
      }
    >
  );

  let position = -1;
  let currType = notifs?.[0]?.node?.notifType;

  const newNotifs = notifs.map((notif) => {
    if (notif.node.notifType !== currType) {
      position = 0;
      currType = notif.node.notifType;
    } else {
      position++;
    }

    const metricsIndex = notif.node.notifType + position;
    const metricsItem = metricsHashmap.get(metricsIndex);

    return {
      notifType: notif.node.notifType,
      position: position,
      delayMinutes: notif.node.delaySeconds / 60,
      title: notif.node.title,
      message: notif.node.message,
      deliveries: metricsItem?.deliveries || 0,
      taps: metricsItem?.taps || 0,
      attributedSales: metricsItem?.attributedSales || 0,
    };
  });

  const getCsvData = useCallback(() => {
    let numFormatter = new Intl.NumberFormat(undefined, {
      maximumFractionDigits: 2,
      useGrouping: false,
    });
    const data = newNotifs.map((notif) => {
      let ctr = !!notif.deliveries
        ? numFormatter.format((notif.taps / notif.deliveries) * 100)
        : null;
      return {
        Title: notif.title,
        Deliveries: notif.deliveries,
        Taps: notif.taps,
        CTR: ctr,
        "Attributed Sales": numFormatter.format(notif.attributedSales),
        Currency: brand.currencyCode,
      };
    });

    return data;
  }, [newNotifs, brand.currencyCode]);

  return (
    <Accordion
      sx={{
        "&.Mui-disabled": {
          backgroundColor: "rgba(0,0,0,0)",
        },
      }}
      expanded={isExpanded}
      elevation={0}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <MuiAccordionSummary
        sx={{
          borderBottom: "1px solid #E5E7EB",
          borderRadius: "4px",
        }}
        expandIcon={<ExpandMoreIcon sx={{ color: "#9CA3AF" }} />}
      >
        <Stack
          direction={"row"}
          spacing={1}
          width={"100%"}
          alignItems={"center"}
          sx={{
            pr: 4,
          }}
        >
          <Stack
            flexDirection={"row"}
            sx={{
              flexGrow: 1,
            }}
          >
            <Typography variant="subtitle1" sx={{ width: "fit-content" }}>
              Automated Notifications
            </Typography>
            <CSVLink
              data={getCsvData()}
              filename={`${brand.displayName
                .replace(/[^a-z0-9]/gi, "_")
                .toLowerCase()}_automated_notifications_${formatDateRange(
                dateRange,
                "YYYYMMDD",
                "_"
              )}`}
            >
              <Button
                variant="text"
                onClick={(e) => e.stopPropagation()}
                size="small"
              >
                <FileDownloadOutlinedIcon />
              </Button>
            </CSVLink>
          </Stack>

          <Typography
            variant="body2"
            sx={{
              color: "black",
            }}
          >
            {formatNumber(totalDeliveries)} Total Deliveries
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography
            variant="body2"
            sx={{
              color: "black",
            }}
          >
            {formatNumber(totalTaps)} Total Clicks
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography
            variant="body2"
            sx={{
              color: "black",
            }}
          >
            {formatNumber(totalTaps / totalDeliveries, "percent")} CTR
          </Typography>
        </Stack>
      </MuiAccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0",
        }}
      >
        <Paper sx={{ width: "100%", borderRadius: "8px" }} elevation={0}>
          <TableContainer>
            <Table
              sx={{ tableLayout: "fixed" }}
              aria-label="simple table"
              size="small"
            >
              <TableBody>
                {newNotifs.map((notif, index) => {
                  return (
                    <InsightsTabAutomatedNotificationsTableRow
                      key={index}
                      delayMinutes={notif.delayMinutes}
                      notifType={notif.notifType}
                      title={notif.title}
                      message={notif.message}
                      metrics={{
                        deliveries: notif.deliveries,
                        taps: notif.taps,
                        attributedSales: notif.attributedSales,
                      }}
                      currencyCode={brand.currencyCode}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

export default InsightsTabAutomatedAutomatedNotificationsTable;
