import {
  Box,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker,
  DatePickerSlotsComponentsProps,
} from "@mui/x-date-pickers/DatePicker";
import { graphql } from "babel-plugin-relay/macro";
import dayjs, { Dayjs } from "dayjs";
import minMax from "dayjs/plugin/minMax";
import { Suspense, useContext, useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { InsightsContainerQuery } from "./__generated__/InsightsContainerQuery.graphql";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { SectionType, SidepaneConfig } from "../../../utils/constants";
import InsightsLifetimeSection from "./InsightsLifetimeSection";
import InsightsTab from "./InsightsTab";
import InsightsTabNotifications from "./InsightsTabNotifications";
import MissingDataAlert from "./MissingDataAlert";
import {
  KDateRange,
  TimeWindowOption,
  dateRangeFromTimeWindowOption,
  formatDateRange,
  getPreviousDateRangeWindow,
  getTimeWindowOptionForDateRange,
} from "./types/KDateRange";

dayjs.extend(minMax);

export type TimeWindowOptionDetails = {
  title: string;
};

type TimeWindowOptionVisible = Exclude<TimeWindowOption, "CUSTOM">;
const TimeWindowOptionsMap: {
  [key in TimeWindowOptionVisible]: TimeWindowOptionDetails;
} = {
  ONE_DAY: { title: "Today" },
  SEVEN_DAYS: { title: "Last 7 Days" },
  THIRTY_DAYS: { title: "Last 30 Days" },
};
const TimeWindows: TimeWindowOptionVisible[] = [
  "ONE_DAY",
  "SEVEN_DAYS",
  "THIRTY_DAYS",
];

const query = graphql`
  query InsightsContainerQuery($id: ID!) {
    user {
      isInternal
    }
    brand(id: $id) {
      ...InsightsLifetimeSection_brand
      id
      currencyCode
      appConfig {
        appState
        appLaunchDate
      }
      finishedOnboarding
      brandMetricSummary {
        latestDate
      }
    }
  }
`;

const config = SidepaneConfig.find(
  (config) => config.sectionType === SectionType.ANALYTICS
);

const InsightsContainer = () => {
  const currentUserContext = useContext(CurrentUserContext);
  const [isFromDateOpen, setIsFromDateOpen] = useState<boolean>(false);
  const [isToDateOpen, setIsToDateOpen] = useState<boolean>(false);

  const data = useLazyLoadQuery<InsightsContainerQuery>(query, {
    id: currentUserContext?.activeBrandID ?? "",
  });
  const brand = data.brand;
  const user = data.user;
  const metricSummary = brand.brandMetricSummary;
  const appLaunchDate = brand.appConfig?.appLaunchDate;

  // TODO: update to use appstate
  const brandFinishedOnboarding = data.brand.finishedOnboarding === true;

  const location = useLocation();
  const navigate = useNavigate();

  // Date Picker
  const minDate = dayjs(appLaunchDate);
  const maxDate =
    metricSummary.latestDate === ""
      ? dayjs().subtract(1, "day")
      : dayjs(metricSummary.latestDate);
  const [dateRange, setDateRange] = useState<KDateRange>(
    dateRangeFromTimeWindowOption("SEVEN_DAYS", maxDate)
  );
  const timeWindow = getTimeWindowOptionForDateRange(dateRange);
  const handleChangeStartDate = (newValue: Dayjs | null) => {
    if (newValue == null || !newValue?.isValid()) {
      return;
    }

    var endDate: Dayjs = newValue.isAfter(dateRange.end)
      ? newValue
      : dateRange.end;
    setDateRange({
      start: newValue,
      end: endDate,
    });
  };
  const handleChangeEndDate = (newValue: Dayjs | null) => {
    if (newValue == null || !newValue?.isValid()) {
      return;
    }

    var startDate: Dayjs = dateRange.start.isAfter(newValue)
      ? newValue
      : dateRange.start;
    setDateRange({
      start: startDate,
      end: newValue,
    });
  };

  const slotProps: DatePickerSlotsComponentsProps<Dayjs> = useMemo(() => {
    return {
      shortcuts: {
        items: TimeWindows.map((option) => {
          let shortcut = TimeWindowOptionsMap[option];
          return {
            label: shortcut.title,
            getValue: () =>
              dateRangeFromTimeWindowOption(option, maxDate).start,
          };
        }),
        changeImportance: "accept",
        isValid: (value) => !!value && !value.isBefore(minDate),
      },
      layout: {
        onSelectShortcut(newValue, changeImportance) {
          if (newValue === null || !newValue?.isValid()) {
            return;
          }
          setDateRange({
            start: newValue,
            end: maxDate,
          });
          if (changeImportance === "accept") {
            setIsFromDateOpen(false);
            setIsToDateOpen(false);
          }
        },
      },
    };
  }, [minDate, maxDate]);

  if (appLaunchDate == null || metricSummary.latestDate === "") {
    return <MissingDataAlert />;
  }

  const tabs = config?.sections
    .filter((config) => config.isPublic || user?.isInternal === true)
    .map((config, index) => (
      <Tab
        disabled={config.isDisabledPreOnboarded && !brandFinishedOnboarding}
        value={"/" + SectionType.ANALYTICS + config.pathname}
        label={config.title}
        key={index}
      />
    ));

  const pathSplit = location.pathname.split("/");

  const lookbackTabs = (
    <Stack direction={"row"} spacing={2} alignItems={"center"}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={dateRange.start}
          minDate={minDate}
          maxDate={maxDate}
          open={isFromDateOpen}
          onOpen={() => setIsFromDateOpen(true)}
          onClose={() => setIsFromDateOpen(false)}
          closeOnSelect={true}
          onChange={handleChangeStartDate}
          slotProps={slotProps}
          sx={{
            "& .MuiOutlinedInput-input": {
              fontSize: "12px",
              padding: "8px 12px",
            },
          }}
        />
        <Typography fontSize={"12px"}>{" to "}</Typography>
        <DatePicker
          value={dateRange.end}
          minDate={minDate}
          maxDate={maxDate}
          open={isToDateOpen}
          onOpen={() => setIsToDateOpen(true)}
          onClose={() => setIsToDateOpen(false)}
          onChange={handleChangeEndDate}
          closeOnSelect={true}
          slotProps={slotProps}
          sx={{
            "& .MuiOutlinedInput-input": {
              fontSize: "12px",
              padding: "8px 12px",
            },
          }}
        />
      </LocalizationProvider>
    </Stack>
  );

  return (
    <Box>
      <InsightsLifetimeSection
        brand={brand}
        timeWindow={dateRange.end.isSame(maxDate) ? timeWindow : "CUSTOM"}
      />
      <Box mt={2}>{lookbackTabs}</Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider", margin: "16px 0" }}>
        <Tabs
          value={"/" + pathSplit[1] + "/" + pathSplit[2]}
          onChange={(_, value) => {
            navigate(value);
          }}
        >
          {tabs}
        </Tabs>
      </Box>
      <Routes>
        {config?.sections
          .filter((config) => {
            return config.isPublic || user?.isInternal === true;
          })
          .map((config, index) => {
            if (config.isDisabledPreOnboarded && !brandFinishedOnboarding) {
              return null;
            }

            let element = null;

            switch (config.pathname) {
              case "/notifications":
                element = (
                  <InsightsTabNotifications
                    brandID={brand.id}
                    dateRange={dateRange}
                    key={currentUserContext?.activeBrandID}
                  />
                );
                break;
              case "/overview":
                element = (
                  <InsightsTab
                    brandID={brand.id}
                    dateRange={dateRange}
                    key={currentUserContext?.activeBrandID}
                  />
                );
                break;
            }
            return (
              <Route
                path={config.pathname + "/*"}
                element={
                  <Suspense
                    fallback={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    }
                  >
                    <Box width="fit-content" display="inline-block">
                      <Typography
                        sx={{
                          color: "#6B7280",
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "120%",
                          mb: 1,
                        }}
                      >
                        {formatDateRange(dateRange)}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#6B7280",
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "120%",
                          mb: 1,
                        }}
                      >
                        {`Compared to ${formatDateRange(
                          getPreviousDateRangeWindow(dateRange)
                        )}`}
                      </Typography>
                    </Box>
                    {element}
                  </Suspense>
                }
                key={index}
              />
            );
          })}
      </Routes>
    </Box>
  );
};

export default InsightsContainer;
