import { CssBaseline, Stack, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import theme from "../../styles/Theme";
import SplitScreenView from "./SplitScreenView";

const schema = yup
  .object({
    email: yup
      .string()
      .required("E-mail is required")
      .email("E-mail is invalid"),
    password: yup.string().required("Password is required"),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const Login = () => {
  let navigate = useNavigate();

  const [emailErrors, setEmailErrors] = useState("");
  const [passwordErrors, setPasswordErrors] = useState("");
  const [nonFormErrors, setNonFormErrors] = useState("");

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    fetch("/api/v1/user/auth/verify")
      .then((res) => res.json())
      .then((data) => {
        if (data.is_authenticated) {
          navigate("/");
        }
      });
  }, [navigate]);

  const openForgotPassword = () => {
    navigate("/password-reset");
  };

  const onSubmit = (data: FormData) => {
    const user = {
      email: data.email,
      password: data.password,
    };
    fetch("/api/v1/user/auth/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        setEmailErrors("");
        setPasswordErrors("");
        setNonFormErrors("");
        if (data.user) {
          navigate("/");
        } else {
          if ("email" in data) {
            setEmailErrors(data.email.join(","));
          }

          if ("password" in data) {
            setPasswordErrors(data.password.join(","));
          }

          if ("non_field_errors" in data) {
            setNonFormErrors(data.non_field_errors.join(","));
          }
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <form onSubmit={handleSubmit(onSubmit)}>
        <SplitScreenView
          rightContent={
            <Stack
              sx={{
                width: "100%",
              }}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                sx={{
                  color: "black",
                  fontFamily:
                    '"GT-Flexa","Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: 600,
                  fontSize: "28px",
                  marginBottom: 2,
                }}
              >
                Sign In
              </Typography>
              {nonFormErrors ? (
                <Box>
                  <Typography
                    component="h5"
                    align="center"
                    sx={{ color: "red" }}
                  >
                    {nonFormErrors}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
              <TextField
                {...register("email")}
                fullWidth
                error={!!errors?.email || !!emailErrors}
                helperText={errors?.email?.message || emailErrors}
                id="email"
                label="Email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                {...register("password")}
                fullWidth
                error={!!errors?.password || !!passwordErrors}
                helperText={errors?.password?.message || passwordErrors}
                label="Password"
                type="password"
                margin="normal"
                id="password"
                autoComplete="current-password"
              />
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{
                  mt: 1,
                  mb: 2,
                }}
                color="secondary"
              >
                Sign In
              </Button>
              <Link
                sx={{
                  color: "#5A62B8",
                  textDecorationColor: "#5A62B8",
                }}
                onClick={openForgotPassword}
                variant="body2"
                component="button"
              >
                Forgot password?
              </Link>
            </Stack>
          }
        />
      </form>
    </ThemeProvider>
  );
};

export default Login;
