/**
 * @generated SignedSource<<a0fc6c01fbcc47442fbd5954531d95d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateHighlightOrderInput = {
  albumId: string;
  highlightIds: ReadonlyArray<string>;
};
export type HighlightsCardUpdateHighlightOrderMutation$variables = {
  input: UpdateHighlightOrderInput;
};
export type HighlightsCardUpdateHighlightOrderMutation$data = {
  readonly updateHighlightOrder: {
    readonly coverImageUrl: string | null;
    readonly highlights: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly media: {
            readonly coverImageUrl: string | null;
            readonly isProcessing: boolean;
          } | null;
          readonly publishedAt: any;
        };
      }>;
    };
    readonly id: string;
  };
};
export type HighlightsCardUpdateHighlightOrderMutation = {
  response: HighlightsCardUpdateHighlightOrderMutation$data;
  variables: HighlightsCardUpdateHighlightOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverImageUrl",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GraphQLHighlightAlbum",
    "kind": "LinkedField",
    "name": "updateHighlightOrder",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "GraphQLHighlightConnection",
        "kind": "LinkedField",
        "name": "highlights",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphQLHighlightEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GraphQLHighlight",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publishedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GraphQLMedia",
                    "kind": "LinkedField",
                    "name": "media",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isProcessing",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HighlightsCardUpdateHighlightOrderMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HighlightsCardUpdateHighlightOrderMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "68e18891818de871fee5140b12fcee38",
    "id": null,
    "metadata": {},
    "name": "HighlightsCardUpdateHighlightOrderMutation",
    "operationKind": "mutation",
    "text": "mutation HighlightsCardUpdateHighlightOrderMutation(\n  $input: UpdateHighlightOrderInput!\n) {\n  updateHighlightOrder(input: $input) {\n    id\n    coverImageUrl\n    highlights {\n      edges {\n        node {\n          id\n          publishedAt\n          media {\n            coverImageUrl\n            isProcessing\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "524ef4f46ff8a7aa413d6a38176996cb";

export default node;
