import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import {
  ConnectionHandler,
  Environment,
  commitLocalUpdate,
  useLazyLoadQuery,
  useMutation,
  usePaginationFragment,
  useRelayEnvironment,
} from "react-relay";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { NotificationsTabArchiveMutation } from "./__generated__/NotificationsTabArchiveMutation.graphql";
import { NotificationsTabDeleteMutation } from "./__generated__/NotificationsTabDeleteMutation.graphql";
import { NotificationsTabPaginationQuery } from "./__generated__/NotificationsTabPaginationQuery.graphql";
import { NotificationsTabQuery } from "./__generated__/NotificationsTabQuery.graphql";
import { NotificationsTab_brand$key } from "./__generated__/NotificationsTab_brand.graphql";
import nullthrows from "../../../utils/nullthrows";
import NotificationForm from "./notifications/NotificationForm";
import NotificationTableRow from "./notifications/NotificationTableRow";

export enum DialogType {
  ARCHIVE = "archive",
  DELETE = "delete",
  NONE = "none",
}

const archiveNotifMutation = graphql`
  mutation NotificationsTabArchiveMutation($input: NodeInput!) {
    archiveNotification(input: $input) {
      ... on GraphQLNotification {
        isArchived
      }
    }
  }
`;

const deleteNotifMutation = graphql`
  mutation NotificationsTabDeleteMutation($input: NodeInput!) {
    deleteNotification(input: $input) {
      ... on GraphQLNotification {
        id
      }
    }
  }
`;

const query = graphql`
  query NotificationsTabQuery($id: ID!) {
    brand(id: $id) {
      ...NotificationsTab_brand
    }
  }
`;

const brandFragment = graphql`
  fragment NotificationsTab_brand on BrandType
  @refetchable(queryName: "NotificationsTabPaginationQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 10 }
    cursor: { type: "String", defaultValue: "" }
  ) {
    ...NotificationForm_brand
    id
    notifications(first: $count, after: $cursor)
      @connection(key: "NotificationsTab_notifications") {
      edges {
        node {
          id
          ...NotificationTableRow_notification
        }
      }
      totalCount
    }
  }
`;

function orderNotifsInRelayStore(environment: Environment, brandID: string) {
  return commitLocalUpdate(environment, (store) => {
    const brandRecord = store.get(brandID);
    if (brandRecord == null) {
      return;
    }

    const notificationRecord = brandRecord.getLinkedRecord("notifications");
    if (notificationRecord == null) {
      return;
    }

    const sortedEdges = notificationRecord
      .getLinkedRecords("edges")
      ?.sort((a, b) => {
        const aTime = dayjs(
          a.getLinkedRecord("node")?.getValue("timestamp") + ""
        ).unix();
        const bTime = dayjs(
          b.getLinkedRecord("node")?.getValue("timestamp") + ""
        ).unix();

        return bTime - aTime;
      });
    if (sortedEdges == null) {
      return;
    }
    notificationRecord.setLinkedRecords(sortedEdges, "edges");
  });
}

const NotificationsTab = ({ brandID }: { brandID: string }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const environment = useRelayEnvironment();

  const data = useLazyLoadQuery<NotificationsTabQuery>(query, {
    id: brandID,
  });
  const {
    data: brand,
    loadNext,
    isLoadingNext,
    hasNext,
  } = usePaginationFragment<
    NotificationsTabPaginationQuery,
    NotificationsTab_brand$key
  >(brandFragment, data.brand);

  const notifications = brand.notifications.edges;

  const [archiveNotification, isArchiving] =
    useMutation<NotificationsTabArchiveMutation>(archiveNotifMutation);
  const [deleteNotification, isDeleting] =
    useMutation<NotificationsTabDeleteMutation>(deleteNotifMutation);

  const [justUpdatedNotifID, setJustUpdatedNotifID] = useState<string | null>(
    null
  );
  const onUpdateComplete = (notifID: string | null) => {
    if (notifID) {
      setJustUpdatedNotifID(notifID);
      setTimeout(() => {
        setJustUpdatedNotifID(null);
      }, 2000);
    }

    orderNotifsInRelayStore(environment, brandID);
  };

  // Dialog State
  const [dialogNotifID, setDialogNotifID] = useState<string | null>(null);
  const [dialogType, setDialogType] = useState<DialogType>(DialogType.NONE);
  const isDialogOpen = dialogNotifID !== null;
  const onDialogClose = () => {
    setDialogNotifID(null);
    setDialogType(DialogType.NONE);
  };

  // Table Controls
  const [tablePage, setTablePage] = useState(0);
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage > tablePage) {
      loadNext(tableRowsPerPage);
    }
    setTablePage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const difference = +event.target.value - tableRowsPerPage;
    if (difference > 0) {
      loadNext(difference);
    }
    setTableRowsPerPage(+event.target.value);
    setTablePage(0);
  };

  const onArchive = useCallback(
    (id: string) => {
      archiveNotification({
        variables: {
          input: {
            id: id,
          },
        },
        onCompleted: (data, errors) => {
          if (errors) {
            console.log("Error: ", data, errors);
          } else {
            onDialogClose();
          }
        },
      });
    },
    [archiveNotification]
  );

  const onDelete = useCallback(
    (id: string) => {
      deleteNotification({
        variables: {
          input: {
            id: id,
          },
        },
        onCompleted: (data, errors) => {
          if (errors) {
            console.log("Error: ", data, errors);
          } else {
            onDialogClose();
          }
        },
        updater: (store) => {
          const payload = store.getRootField("deleteNotification");

          const brand = store.get(brandID);
          if (brand == null) {
            return;
          }

          const notifConnection = ConnectionHandler.getConnection(
            brand,
            "NotificationsTab_notifications"
          );
          if (notifConnection == null) {
            return;
          }

          ConnectionHandler.deleteNode(notifConnection, payload.getDataID());

          const notifCount = notifConnection.getValue("totalCount");
          if (notifCount != null) {
            notifConnection.setValue(Number(notifCount) - 1, "totalCount");
          }
        },
      });
    },
    [brandID, deleteNotification]
  );

  // Dialog State
  let dialogTitle = "Are you sure you want to archive this notification?";
  let dialogText =
    "Your customers won’t be able to view your notification after you archive it.";
  let dialogButtonText = isArchiving ? "Archiving" : "Archive";
  let onDialogClick = () => onArchive(nullthrows(dialogNotifID));

  if (dialogType === DialogType.DELETE) {
    dialogTitle = "Are you sure you want to delete this notification?";
    dialogText =
      "Your customers won’t be able to view your notification after you delete it.";
    dialogButtonText = isDeleting ? "Deleting" : "Delete";
    onDialogClick = () => onDelete(nullthrows(dialogNotifID));
  }
  const dialogView = isDialogOpen ? (
    <Dialog open={isDialogOpen} onClose={onDialogClose}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={isArchiving || isDeleting}
          onClick={onDialogClick}
          color="error"
        >
          {dialogButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;

  const tableView = (
    <>
      <Stack spacing={2} direction="column" alignItems="flex-end">
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          sx={{
            display: "flex",
          }}
          onClick={() => {
            navigate(location.pathname + "/create");
          }}
        >
          Create Notification
        </Button>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 650, tableLayout: "fixed" }}
              aria-label="simple table"
              size="small"
            >
              <TableHead
                sx={{
                  background: "#F3F4F6",
                }}
              >
                <TableRow>
                  <TableCell width={"40%"}>
                    <Typography variant="overline">Notification</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="overline">Audience</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="overline">Campaign</Typography>
                  </TableCell>
                  <TableCell align="center" width={"15%"}>
                    <Typography variant="overline">Status</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="overline" whiteSpace={"nowrap"}>
                      Publish Time
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="overline">Actions</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notifications
                  .slice(
                    tablePage * tableRowsPerPage,
                    tablePage * tableRowsPerPage + tableRowsPerPage
                  )
                  .map((notificationNode) => (
                    <NotificationTableRow
                      key={notificationNode.node.id}
                      isNew={justUpdatedNotifID === notificationNode.node.id}
                      notification={notificationNode.node}
                      onOpenDialog={(dialogType: DialogType) => {
                        setDialogType(dialogType);
                        setDialogNotifID(notificationNode.node.id);
                      }}
                    />
                  ))}
                {isLoadingNext && hasNext
                  ? notifications.length <
                      (tablePage + 1) * tableRowsPerPage && (
                      <TableCell
                        colSpan={5}
                        align="center"
                        sx={{
                          padding: "24px 0",
                        }}
                      >
                        <CircularProgress color="primary" size={32} />
                      </TableCell>
                    )
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={brand.notifications.totalCount ?? notifications.length}
            rowsPerPage={tableRowsPerPage}
            page={tablePage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Stack>
      {dialogView}
    </>
  );

  return (
    <Routes>
      <Route
        path="/"
        element={<Container maxWidth="lg">{tableView}</Container>}
      />
      <Route
        path="/create"
        element={
          <Container maxWidth="md">
            <NotificationForm
              brand={brand}
              onUpdateComplete={onUpdateComplete}
            />
          </Container>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <Container maxWidth="md">
            <NotificationForm
              brand={brand}
              onUpdateComplete={onUpdateComplete}
            />
          </Container>
        }
      />
    </Routes>
  );
};

export default NotificationsTab;
