import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SignInLink = () => {
  const navigate = useNavigate();

  const openLogin = () => {
    navigate("/login");
  };
  return (
    <Link
      sx={{
        color: "#5A62B8",
        textDecorationColor: "#5A62B8",
      }}
      onClick={openLogin}
      variant="body2"
      component="button"
    >
      Sign In
    </Link>
  );
};

export default SignInLink;
