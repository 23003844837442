/**
 * @generated SignedSource<<7acb39442bf218ed0d782a39e9d348ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppBuildState = "DISABLED" | "PENDING_BRAND_SETUP" | "PENDING_KINN_SETUP" | "PRE_PRODUCTION" | "PRODUCTION" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InternHome_user$data = {
  readonly brandAdminProfiles: ReadonlyArray<{
    readonly brand: {
      readonly appConfig: {
        readonly appState: AppBuildState;
      };
      readonly displayName: string;
      readonly id: string;
    };
  }>;
  readonly " $fragmentType": "InternHome_user";
};
export type InternHome_user$key = {
  readonly " $data"?: InternHome_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"InternHome_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternHome_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GraphQLBrandAdminProfile",
      "kind": "LinkedField",
      "name": "brandAdminProfiles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandType",
          "kind": "LinkedField",
          "name": "brand",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BrandAppConfigType",
              "kind": "LinkedField",
              "name": "appConfig",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "appState",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserType",
  "abstractKey": null
};

(node as any).hash = "16ef2da99d058a92b7ee82da447182d7";

export default node;
